import { useEffect } from 'react';
import {
    useLocation,
    useNavigate,
    useParams
  } from "react-router-dom";

export const withBottleDefaultRoute = (Component) =>  {
    const WithBottleDefaultRouteWrapper = (props) => {
		const location = useLocation();
		const navigate = useNavigate();
		const params = useParams();
		const match = {
			path: location.pathname
		};
		
		useEffect(() => {
			// if the default page has been requested
			var defaultRegex = /\/reuse\/bottles\/(([1-9][0-9]*)|new)$/i;
			if (defaultRegex.test(match.path)) {
				navigate(match.path + '/Chemical', { replace: true });
			}
		});
		
		return (
			<Component
				location={location}
				navigate={navigate}
				params={params}
				match={match}
				{...props}
			/>
		);
    }
    return WithBottleDefaultRouteWrapper;
}
