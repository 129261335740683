import 'bootstrap/dist/css/bootstrap.min.css';
import ChemComponent from './ChemComponent';
import ChemEdit from './ChemEdit';
import QueryTable from './QueryTable';
import { Button, Form, Row, Col } from 'react-bootstrap';

class Identity extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			renderKey: 0,
			identitySelf: true,
			identityNewUser: false,
			identitySelectList: false,
			newUser: {
				FNAME: '',
				LNAME: ''
			},
			selectedPerson: {
				displayName: '(Click on a row in the user list)'
			},
			peopleSearch: {
				LNAME: '',
				FNAME: '',
				IS_DELEGATE: false,
				IS_FSA: false,
				IS_PI: false,
				IS_SAO: false,
				IS_STOREMGR: false,
				IS_WORKSHOPMGR: false,
				IS_SUPERVISOR: false
			},
			peopleAuth: this.mergeObject(peopleAuth, { query: { search: null } })
		}
	}
	
	rowSelected(row) {
		var selectedPerson = this.copyObject(row.values);
		selectedPerson.displayName = selectedPerson.LNAME + ', ' + selectedPerson.FNAME + (selectedPerson.MNAME ? (' ' + selectedPerson.MNAME) : '');
		
		this.mergeState({
			selectedPerson: selectedPerson
		});
	}
	
	selectIdentity(identitySelf, identityNewUser, identitySelectList) {
		this.mergeState({
			identitySelf: identitySelf,
			identityNewUser: identityNewUser,
			identitySelectList: identitySelectList
		});
	}
	
	assumeIdentity(event) {
		var self = this;
		event.preventDefault();
		
		var uid = this.props.user.originalUID;
		var assumeuid = this.state.identitySelf ? 'Self' : (this.state.identityNewUser ? 'New' : this.state.selectedPerson.CAL_UID);
		var firstname = this.state.identityNewUser ? this.state.newUser.FNAME : null;
		var lastname = this.state.identityNewUser ? this.state.newUser.LNAME : null;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Home/AssumeIdentity',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, uid: uid, assumeuid: assumeuid, firstname: firstname, lastname: lastname }
		}).done(function (data) {
			if (data.Success) {
				self.props.exitIdentity();
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	onChangeSearch(accessor, value) {
		var peopleSearch = this.copyObject(this.state.peopleSearch);
		this.setByAccessor(peopleSearch, accessor, value);
		this.mergeState({ peopleSearch: peopleSearch });
	}
	
	onSubmitSearch(event) {
		// construct search terms
		var search = {
			Operator: 'and',
			children: []
		};
		
		var data = this.state.peopleSearch;

		if (!this.isEmpty(data.LNAME)) search.children.push({ Attribute: 'LNAME', Operator: 'like', StringValue: '%' + data.LNAME + '%' });
		if (!this.isEmpty(data.FNAME)) search.children.push({ Attribute: 'FNAME', Operator: 'like', StringValue: '%' + data.FNAME + '%' });
		if (data.IS_DELEGATE) search.children.push({ Attribute: 'DELEGATE', Operator: '=', StringValue: 'Y' });
		if (data.IS_FSA) search.children.push({ Attribute: 'FSA', Operator: '=', StringValue: 'Y' });
		if (data.IS_PI) search.children.push({ Attribute: 'PI', Operator: '=', StringValue: 'Y' });
		if (data.IS_SAO) search.children.push({ Attribute: 'SAO', Operator: '=', StringValue: 'Y' });
		if (data.IS_STOREMGR) search.children.push({ Attribute: 'STOREMGR', Operator: '=', StringValue: 'Y' });
		if (data.IS_WORKSHOPMGR) search.children.push({ Attribute: 'WORKSHOPMGR', Operator: '=', StringValue: 'Y' });
		if (data.IS_SUPERVISOR) search.children.push({ Attribute: 'SUPERVISOR', Operator: '=', StringValue: 'Y' });
		
		// if nothing was selected, forget it
		if (search.children.length === 0) search = null;
			
		this.mergeState({
			renderKey: this.state.renderKey + 1,
			peopleAuth: {
				query: {
					search: search	
				},
				renderKey: this.state.peopleAuth.renderKey + 1
			}
		}, () => {
			if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
		});	
	}
	
	renderNewUserLabel() {
		return (<>
			New User: First Name&nbsp;&nbsp;<input type="text" value={this.state.newUser.FNAME} onChange={(event) => this.mergeState({ newUser: { FNAME: event.target.value }})} /> 
			&nbsp;&nbsp;Last Name&nbsp;&nbsp;<input type="text" value={this.state.newUser.LNAME} onChange={(event) => this.mergeState({ newUser: { LNAME: event.target.value }})} />
		</>);
	}
	
	render() {
		return (
			<div style={style.box}>
				<h3 style={{ fontSize: '1.17em', fontWeight: 'bold', marginTop: 16, marginBottom: 18 }}>Identity</h3>
				For the purpose of testing, this site allows you to assume the identity of another user.<br />
				Once you have chosen an alternate identity, you must log out of the site and log in again.<br />
				This feature is only available on the test site.<br />
				<br />
				Assume the following identity:<br />
				<br />
				<Form onSubmit={(event) => this.assumeIdentity(event)}>
					<Form.Check type='radio' id='rdoMyself' label='Myself' checked={this.state.identitySelf} onChange={() => this.selectIdentity(true, false, false)} />
					<Form.Check type='radio' id='rdoNewUser' label={this.renderNewUserLabel()} checked={this.state.identityNewUser} onChange={() => this.selectIdentity(false, true, false)} />
					<Form.Check type='radio' id='rdoFromList' label={this.state.selectedPerson.displayName} checked={this.state.identitySelectList} onChange={() => this.selectIdentity(false, false, true)} />
					<br />
					<Button variant='warning' type='submit'>Save</Button>&nbsp;&nbsp;
					<Button variant='warning' type='button' onClick={() => this.props.exitIdentity()}>Cancel</Button>
				</Form>
				<br />
				<span style={{ fontSize: '18px', fontWeight: 'bold' }}>User List</span><br />
				Enter information and click "Update" to filter the user list.
				<ChemEdit parent={this} columns={peopleSearch} data={this.state.peopleSearch} renderKey={this.state.renderKey} 
					onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={12}>
							<Button id="update" variant="warning" type="submit" className="float-end">Update</Button>
						</Col>
					</Form.Group>
				</ChemEdit>
				<QueryTable renderKey={this.state.peopleAuth.renderKey} parent={this} name='Identity' table={this.state.peopleAuth} offsetHeight='230px'
					rowSelected={(row) => this.rowSelected(row)}/>
			</div>
		);
	}
}

const style = {
	box: {
		border: '1px solid #ccc',
		width: '80%',
		marginRight: '0px',
		marginLeft: '0px',
		paddingLeft: '10px',
		paddingBottom: '18px',
		backgroundColor: '#FEFEFE',
		float: 'right',
		fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif'
	}
};

const peopleSearch = [
	{ Header: 'Last Name', accessor: 'LNAME', row: 0, col: 0 },
	{ Header: 'First Name', accessor: 'FNAME', row: 0, col: 1 },
	{ Header: 'Delegate', accessor: 'IS_DELEGATE', row: 0, col: 2, type: 'checkbox' },
	{ Header: 'FSA', accessor: 'IS_FSA', row: 1, col: 0, type: 'checkbox' },
	{ Header: 'PI', accessor: 'IS_PI', row: 1, col: 1, type: 'checkbox' },
	{ Header: 'SAO', accessor: 'IS_SAO', row: 1, col: 2, type: 'checkbox' },
	{ Header: 'Store Manager', accessor: 'IS_STOREMGR', row: 2, col: 0, type: 'checkbox' },
	{ Header: 'Workshop Manager', accessor: 'IS_WORKSHOPMGR', row: 2, col: 1, type: 'checkbox' },
	{ Header: 'Supervisor', accessor: 'IS_SUPERVISOR', row: 2, col: 2, type: 'checkbox' }
];

const peopleAuth = {
	renderKey: 0,
	pageSize: 10,
	query: { entity: 'PEOPLE_AUTH_GRID_VW', order: ['LNAME', 'FNAME', 'MNAME'] },
	columns: [
		{ Header: 'PERSON_ID', accessor: 'PERSON_ID', show: false },
		{ Header: 'Cal UID', accessor: 'CAL_UID', show: false },
		{ Header: 'Last Name', accessor: 'LNAME' },
		{ Header: 'First Name', accessor: 'FNAME' },
		{ Header: 'Middle Name', accessor: 'MNAME' },
		{ Header: 'Delegate', accessor: 'DELEGATE' },
		{ Header: 'FSA', accessor: 'FSA' },
		{ Header: 'PI', accessor: 'PI' },
		{ Header: 'SAO', accessor: 'SAO' },
		{ Header: 'Store Mgr', accessor: 'STOREMGR' },
		{ Header: 'Workshop Mgr', accessor: 'WORKSHOPMGR' },
		{ Header: 'Supervisor', accessor: 'SUPERVISOR' }
	]
};


export default Identity;