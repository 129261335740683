import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ChemComponent from '../ChemComponent';

class Help extends ChemComponent {

	render() {
		return(
			<Container fluid>
				<Row>
					<Col xs={12} style={{ paddingTop: '20px', paddingBottom: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Help</span><br />
						<br />
						<h6>CRS Handbook</h6>
						<br />
  <ul>
  <li class="labelcell_black"><Form style={{ marginLeft: '-12px', marginTop: '-14px', marginBottom: '-8px'}} action={this.getConfig().host + '/CRS/GetHandbookAdminAccess'} method='POST'>
							<input type='hidden' name='__RequestVerificationToken' value={this.getUser().antiForgeryToken} />
							<Button type='submit' variant='link'>Administrative Access</Button>
						</Form></li>
         <li><Form style={{ marginLeft: '-12px', marginTop: '-14px', marginBottom: '-8px'}} action={this.getConfig().host + '/CRS/GetHandbookCustUserAccess'} method='POST'>
							<input type='hidden' name='__RequestVerificationToken' value={this.getUser().antiForgeryToken} />
							<Button type='submit' variant='link'>Customer or User Access</Button>
						</Form></li>
         <li><Form style={{ marginLeft: '-12px', marginTop: '-14px', marginBottom: '-8px'}} action={this.getConfig().host + '/CRS/GetHandbookRAAccess'} method='POST'>
							<input type='hidden' name='__RequestVerificationToken' value={this.getUser().antiForgeryToken} />
							<Button type='submit' variant='link'>Research Assistant Access</Button>
						</Form></li>
</ul>
						Please contact <a href="mailto:jborland@berkeley.edu">John Borland</a> (510-643-1706) if you have any questions or issues related to CRS.

					</Col>
				</Row>
			</Container>
		);
	}
}

export default Help;