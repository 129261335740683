import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';

class DeliveryAdmin extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			ln2chem: {
				DATE: null,
				AMOUNT: '',
				COMMENTS: ''
			},
			ln2physics: {
				DATE: null,
				AMOUNT: '',
				COMMENTS: ''
			},
			oxygen: {
				DATE: null,
				AMOUNT: '',
				COMMENTS: ''
			},				
			renderKey: 0
		};
	}
	
	onChange(accessor, value) {
		var state = this.copyObject(this.state);
		this.setByAccessor(state, accessor, value);
		this.mergeState(state);
	}

	onSubmitLN2Chem(event) {
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}

	onSubmitLN2Physics(event) {
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSubmitOxygen(event) {
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	render() {
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold', marginRight: '10px' }}>Liquid Nitrogen Deliveries - Chemistry</span>
						<ChemEdit parent={this} user={this.props.user} columns={ln2ChemColumns} data={this.state} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmitLN2Chem(event)}>
							<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
							  <Col xs={12}>
								<Button id="submit" variant="warning" type="submit" className="float-end">Submit</Button>
							  </Col>
							</Form.Group>
						</ChemEdit>
					  </Col>
					</Row>
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold', marginRight: '10px' }}>Liquid Nitrogen Deliveries - Physics</span>
						<ChemEdit parent={this} user={this.props.user} columns={ln2PhysicsColumns} data={this.state} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmitLN2Physics(event)}>
							<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
							  <Col xs={12}>
								<Button id="submit" variant="warning" type="submit" className="float-end">Submit</Button>
							  </Col>
							</Form.Group>
						</ChemEdit>
					  </Col>
					</Row>
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold', marginRight: '10px' }}>Liquid Oxygen Deliveries</span>
						<ChemEdit parent={this} user={this.props.user} columns={oxygenColumns} data={this.state} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmitOxygen(event)}>
							<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
							  <Col xs={12}>
								<Button id="submit" variant="warning" type="submit" className="float-end">Submit</Button>
							  </Col>
							</Form.Group>
						</ChemEdit>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const ln2ChemColumns = [
	{ Header: 'Date', accessor: 'ln2chem.DATE', type: 'date', row: 0, col: 0 },
	{ Header: 'Amount', accessor: 'ln2chem.AMOUNT', row: 0, col: 1 },
	{ Header: 'Comments', accessor: 'ln2chem.COMMENTS', type: 'textarea', row: 0, col: 2 }
];

const ln2PhysicsColumns = [
	{ Header: 'Date', accessor: 'ln2physics.DATE', type: 'date', row: 0, col: 0 },
	{ Header: 'Amount', accessor: 'ln2physics.AMOUNT', row: 0, col: 1 },
	{ Header: 'Comments', accessor: 'ln2physics.COMMENTS', type: 'textarea', row: 0, col: 2 }
];

const oxygenColumns = [
	{ Header: 'Date', accessor: 'oxygen.DATE', type: 'date', row: 0, col: 0 },
	{ Header: 'Amount', accessor: 'oxygen.AMOUNT', row: 0, col: 1 },
	{ Header: 'Comments', accessor: 'oxygen.COMMENTS', type: 'textarea', row: 0, col: 2 }
];

export default DeliveryAdmin;
