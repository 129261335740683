import { Fragment } from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import EditTable from '../EditTable';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class CylTransaction extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			editable: props.editable,
			trans: {
				SALES_ID: '',
				lines: []
			},
			transColumns: this.copyColumns(transColumns),
			lineEditColumns: this.copyColumns(lineEditColumns),
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadTransaction();
	}

	loadTransaction(event) {
		var self = this;
		
		if (this.props.params.transId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/HeLAD/GetCylTransaction',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					salesid: this.props.params.transId
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var trans = data.Data[0];
						self.mvc2js(trans, transColumns);
						self.mvc2js(trans.lines, lineEditColumns);
						
						// if there is a customer, make sure it is in the customer drop down
						var newTransColumns = self.copyColumns(transColumns);
						var newLineEditColumns = self.copyColumns(lineEditColumns);
						if (!self.isEmpty(trans.CUSTOMER_ID)) {
							var userColIdx = self.findColumnByAccessor(newTransColumns, 'CUSTOMER_ID');
							newTransColumns[userColIdx].options.search.Children.push({ Attribute: 'PERSON_ID', Operator: '=', LongValue: trans.CUSTOMER_ID });
						}
						var returnUserIds = self.collectReturnUserIds(trans);
						var returnUserColIdx = self.findColumnByAccessor(newLineEditColumns, 'RETURN_USER');
						for (var i = 0; i < returnUserIds.length; i++) {
							newLineEditColumns[returnUserColIdx].options.search.Children.push({ Attribute: 'PERSON_ID', Operator: '=', LongValue: returnUserIds[i] });
						}
						
						// add date boundaries to cost center end_valid_date
						if (!self.isEmpty(trans.SALE_DATE) && !self.isEmpty(trans.DATE_BILLED)) {
							var piColIdx = self.findColumnByAccessor(newTransColumns, 'PI_NAME');
							newTransColumns[piColIdx].options.search.Children[1].Children.push(
								{ Operator: 'and', Children: [
									{ Attribute: 'END_VALID_DATE', Operator: '>=', DateValue: self.dateTimeToMVC(self.getDate(trans.SALE_DATE)) },
									{ Attribute: 'END_VALID_DATE', Operator: '<=', DateValue: self.dateTimeToMVC(self.getDate(trans.DATE_BILLED)) }
								]}
							);
						}
						
						self.mergeState({
							trans: trans,
							transColumns: newTransColumns,
							lineEditColumns: newLineEditColumns,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Transaction Error', data.Data.length + ' Transactions found for ID ' + self.props.params.transId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				trans: {
					SALES_ID: '(new)',
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
	
	collectReturnUserIds(trans) {
		var returnUserIds = [];
		if (trans.lines && Array.isArray(trans.lines)) {
			for (var i = 0; i < trans.lines.length; i++) {
				if (!this.isEmpty(trans.lines[i].RETURN_USER)) {
					returnUserIds.push(trans.lines[i].RETURN_USER);
				}
			}
		}
		return returnUserIds;
	}
	
	onEdit(accessor, editorIsOpen) {
	}
		
	onChange(accessor, value) {
		var trans = this.copyObject(this.state.trans);
		this.setByAccessor(trans, accessor, value);
		this.mergeState({ trans: trans, renderKey: this.state.renderKey + 1 });
	}
		
	onSubmit(event) {
		var self = this;

		var trans = this.copyObject(this.state.trans);
		this.js2mvc(trans, transColumns);
		this.js2mvc(trans.lines, lineEditColumns);

		trans.MODIF_DATE = this.dateTimeToMVC(new Date());
		for (var i = 0; i < trans.lines.length; i++) {
			trans.lines[i].MODIF_BY = this.props.user.name;
			trans.lines[i].MODIF_DATE = trans.MODIF_DATE;
		}
			
		if (!this.props.params.transId) {
			trans.SALES_ID = 'seq_sales.nextval';
			trans.CREATED_BY = this.props.user.name;
		} else {
			trans.SAL_MODIF_BY = this.props.user.name;
		}
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/HeLAD/UpdateCylTransaction',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, transaction: trans }
		}).done(function (data) {
			if (data.Success) {
				var transId = self.props.params.transId || data.ID;
				var verb = self.props.params.transId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Transaction <Link to={'/HeLAD/CylTransactions/' + transId}>{transId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Transaction {this.state.trans.SALES_ID}&nbsp;&nbsp;
							{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
							  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
						  </div>
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={this.state.transColumns} data={this.state.trans} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} editable={this.state.editable} />
					  </Col>
					</Row>
					<Row>
					  <Col>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Line Items
						  </div>
					  </Col>
					</Row>
					<Row style={{ paddingTop: 5 }}>
					  <Col>
						<EditTable parent={this} user={this.props.user} renderKey={this.state.renderKey} newRecord={emptyLine} data={this.state.trans.lines}
						  tableColumns={lineTableColumns} editColumns={this.state.lineEditColumns} onEdit={(editorIsOpen) => this.onEdit('lines', editorIsOpen)}
						  onChange={(data) => this.onChange('lines', data)} tableName='HeLAD_CylTransaction_Lines' order='LINE_ID' editable={this.state.editable} />
					  </Col>
					</Row>
					<Row style={{ paddingTop: 5 }}>
					  <Col>
						{this.state.editable && <Button id="save" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmit(event)}>Save</Button>}
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const transColumns = [
	{ Header: 'Sale ID', accessor: 'SALES_ID', row: 0, col: 0, editable: false },
	{ Header: 'Date', accessor: 'SALE_DATE', type: 'date', row: 0, col: 1 },
	{ Header: 'User Name', accessor: 'CUSTOMER_ID', type: 'select', row: 0, col: 2,
		options: { value: 'PERSON_ID', label: 'USER_NAME', entity: 'LIQUID_AIR.CYL_USER_SELECT_VW', order: 'USER_NAME', 
			search: { Operator: 'or', Children: [{ Attribute: 'CYL_HE', Operator: '=', StringValue: 'Y' }]}}},
	{ Header: 'Speedtype', accessor: 'COST_CENTER', row: 1, col: 0 },
	{ Header: 'PI', accessor: 'COST_CENTER', labelAccessor: 'PI_NAME', type: 'select', row: 1, col: 1, editable: false,
		options: { value: 'BFS_SPEED_TYPE', label: 'PI_NAME', entity: 'LIQUID_AIR.CYL_PI_SELECT_VW', order: 'PI_NAME', distinct: 'true',
			search: { Operator: 'and', Children: [
				{ Attribute: 'BFS_SPEED_TYPE', Operator: '=', StringAccessor: 'COST_CENTER' },
				{ Operator: 'or', Children: [{ Attribute: 'END_VALID_DATE', Operator: 'is null' }]}
			]}}},
	{ Header: 'Comments', accessor: 'SALE_DESC', type: 'textarea', row: 1, col: 2 }
];

const emptyLine = {
	SALES_ID: null,
	LINE_ID: null,
	CYL_ID: null,
	LINE_TOTAL: '',
	COLLEGE_ID: '',
	PRODUCT_ID: null,
	PRODUCT: '',
	SALE_DESC: '',
	DATE_BILLED: null,
	DATE_BILLED_DISPLAY: '',
	MODIF_ACTION: '',
	MODIF_BY: '',
	MODIF_DATE: null,
	RETURN_USER: null,
	RETURN_USER_NAME: '', 
	RETURN_DATE: null,
	RETURN_DATE_DISPLAY: ''
};

const lineTableColumns = [
	{ Header: 'Cyl College ID', accessor: 'COLLEGE_ID' },
	{ Header: 'Product', accessor: 'PRODUCT' },
	{ Header: 'Price', accessor: 'LINE_TOTAL' },
	{ Header: 'Return User', accessor: 'RETURN_USER_NAME' },
	{ Header: 'Return Date', accessor: 'RETURN_DATE',
		Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
	{ Header: 'Date Billed', accessor: 'DATE_BILLED',
		Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
	{ Header: 'Comments', accessor: 'SALE_DESC' }	
];

const lineEditColumns = [
	{ Header: 'Cyl College ID', accessor: 'CYL_ID', labelAccessor: 'COLLEGE_ID', type: 'select', row: 0, col: 0,
		options: { value: 'CONTAINER_ID', label: 'CONTAINER_DESC', entity: 'LIQUID_AIR.CONTAINER_SELECT_VW', order: 'CONTAINER_ID' }},
	{ Header: 'Product', accessor: 'PRODUCT_ID', labelAccessor: 'PRODUCT', type: 'select', row: 0, col: 1,
		options: { value: 'PRODUCT_ID', label: 'PRODUCT', entity: 'LIQUID_AIR.CYL_PRODUCT_SELECT_VW', order: 'PRODUCT' }},
	{ Header: 'Price Charged', accessor: 'LINE_TOTAL', row: 0, col: 2 },
	{ Header: 'Modified', accessor: 'MODIF_DATE', type: 'date', row: 0, col: 3, editable: false },
	{ Header: 'Return User', accessor: 'RETURN_USER', labelAccessor: 'RETURN_USER_NAME', type: 'select', row: 1, col: 0,
		options: { value: 'PERSON_ID', label: 'USER_NAME', entity: 'LIQUID_AIR.CYL_USER_SELECT_VW', order: 'USER_NAME', 
			search: { Operator: 'or', Children: [{ Attribute: 'CYL_HE', Operator: '=', StringValue: 'Y' }]}}},
	{ Header: 'Return Date', accessor: 'RETURN_DATE', type: 'date', row: 1, col: 1 },
	{ Header: 'Date Billed', accessor: 'DATE_BILLED', type: 'date', row: 1, col: 2 },
	{ Header: 'Modified By', accessor: 'MODIF_BY', row: 1, col: 3, editable: false },
	{ Header: 'Comments', accessor: 'SALE_DESC', type: 'textarea', row: 2, col: 2 },
	{ Header: 'Modif Action', accessor: 'MODIF_ACTION', type: 'textarea', row: 2, col: 3 }
];

export default withRouter(CylTransaction);
