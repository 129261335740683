import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ChemComponent from '../ChemComponent';

class Links extends ChemComponent
{
	render() {
		return(
			<Container>
				<Row>
					<Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}> 

<div id="contentInside" align="center">
  <h2>Useful Links</h2>
  <div class="comment_font">Note: some sites have restricted access and you may not be able to login</div>
<table width="1200" cellpadding="5">
<tr>
<td valign="top"><h3>CRS Admin</h3>
<br />
  <ul>
		 <li class="header"><a href='https://calanswers-bi.berkeley.edu:9503/analytics/saw.dll?Dashboard' target="_blank" rel="noreferrer"><span class="accordion">CalAnswers</span></a></li>
    	 <li class="header"><a href='https://bfs.is.berkeley.edu/psp/bfsprod/EMPLOYEE/ERP/?cmd=logout' target="_blank" rel="noreferrer"><span class="accordion">BFS</span></a></li>
         <li><a href="https://blu.is.berkeley.edu/psp/blupd91/EMPLOYEE/EMPL/h/?tab=PAPP_GUEST" target="_blank" rel="noreferrer"><span class="accordion">BearBuy</span></a></li>
         <li><a href='https://blu.is.berkeley.edu/psp/blupd91/EMPLOYEE/EMPL/h/?tab=DEFAULT' target="_blank" rel="noreferrer"><span class="accordion">Blu</span></a></li>
         <li><a href='https://citrix.berkeley.edu/vpn/index.html' target="_blank" rel="noreferrer"><span class="accordion">Citrix</span></a></li>
         <li><a href='https://bfs92.is.berkeley.edu/psc/uc_coa/EMPLOYEE/ERP/c/UC_COMPONENTS.UC_COA_CHK.GBL' target="_blank" rel="noreferrer"><span class="accordion">COA Validation</span></a></li>
         <li><a href='http://www.fedex.com/us/' target="_blank" rel="noreferrer"><span class="accordion">FedEx</span></a></li>
         <li><a href='https://controller.berkeley.edu/accounting-and-controls/financial-reporting/bfs-general-ledger-monthly-close-schedule' target="_blank" rel="noreferrer"><span class="accordion">BFS General Ledger Monthly Close Schedule</span></a></li>
         <li><a href='http://campuspol.chance.berkeley.edu/Home/AtoZPolicies.cfm?long_page=yes' target="_blank" rel="noreferrer"><span class="accordion">Campus Policies and Procedures</span></a></li>
  </ul>
<br />
<h3>College of Chemistry Systems</h3>
<br />
  <ul>
  <li class="labelcell_black"><a href="https://chemnet.berkeley.edu/BuildingAccess" target="_blank" rel="noreferrer"><span class="accordion">College Access System</span></a></li>
         <li><a href="https://chemapps.berkeley.edu/xnet/FAS/Chemistry/fas/index.cfm?action=main.alogon" target="_blank" rel="noreferrer"><span class="accordion">FAS</span></a></li>
         <li><a href="https://chemapps.berkeley.edu/inet/CF_Shops/login.cfm" target="_blank" rel="noreferrer"><span class="accordion">Jobtrack</span></a></li>
         <li class='last'><a href='https://chemapps.berkeley.edu/inet/CF_Liquid_air/login.cfm' target="_blank" rel="noreferrer"><span class="accordion">Liquid Air Plant</span></a></li>
</ul>
<br />
<h3>CRS Handbook</h3>
<br />
  <ul>
  <li class="labelcell_black"><Form style={{ marginLeft: '-12px', marginTop: '-14px', marginBottom: '-8px'}} action={this.getConfig().host + '/CRS/GetHandbookAdminAccess'} method='POST'>
							<input type='hidden' name='__RequestVerificationToken' value={this.getUser().antiForgeryToken} />
							<Button type='submit' variant='link'>Administrative Access</Button>
						</Form></li>
         <li><Form style={{ marginLeft: '-12px', marginTop: '-14px', marginBottom: '-8px'}} action={this.getConfig().host + '/CRS/GetHandbookCustUserAccess'} method='POST'>
							<input type='hidden' name='__RequestVerificationToken' value={this.getUser().antiForgeryToken} />
							<Button type='submit' variant='link'>Customer or User Access</Button>
						</Form></li>
         <li><Form style={{ marginLeft: '-12px', marginTop: '-14px', marginBottom: '-8px'}} action={this.getConfig().host + '/CRS/GetHandbookRAAccess'} method='POST'>
							<input type='hidden' name='__RequestVerificationToken' value={this.getUser().antiForgeryToken} />
							<Button type='submit' variant='link'>Research Assistant Access</Button>
						</Form></li>
</ul>
</td>
<td valign="top"><h3>Non-College of Chemistry Recharges</h3>
<br />
<ul>
  <li class="labelcell_black">
  <a href="http://budget.berkeley.edu/recharge-information" target="_blank" rel="noreferrer"><span class="accordion">Campus Budget Office - Recharge Information</span></a></li>


    <li><a href="http://realestate.berkeley.edu/fs-customer-service-center" target="_blank" rel="noreferrer">Berkeley Facility Services (formerly PPCS)</a></li>

  <li><a href="http://qb3.berkeley.edu/qb3/bnc/contact.cfm" target="_blank" rel="noreferrer">BNC (Biomolecular Nanotechnology Center)</a><br />contacts: Christine Owen, Troy Williams, Paul Lum</li>

  <li><a href="https://mcb.berkeley.edu/barker/dnaseq/home" target="_blank" rel="noreferrer">DNA Sequencing Facility</a><br />contact: Brian McCarthy (<a href="mailto:dnaseq@berkeley.edu">dnaseq@berkeley.edu</a>)</li>

  <li><a href="http://ehs.berkeley.edu/recharge.html" target="_blank" rel="noreferrer">EH&S (Environment, Health & Safety)</a></li>
  <li><a href="https://em-lab.berkeley.edu/EML/index.php" target="_blank" rel="noreferrer">EML (Electron Microscope Lab)</a><br />contact: <a href="mailto:ucbeml@gmail.com">ucbeml@gmail.com</a></li>
  <li><a href="https://nanolab.berkeley.edu/public/general/contactus.shtml" target="_blank" rel="noreferrer">ERSO Nanolab</a><br />contact: Neal Melton (<a href="mailto:nmelton@eecs.berkeley.edu">nmelton@eecs.berkeley.edu</a>)</li>
  <li><a href="http://qb3.berkeley.edu/qb3/fgl/contacts.cfm" target="_blank" rel="noreferrer">FGL (Functional Genomics Laboratory)</a><br />contact: Rommel Mapaye (<a href="mailto:rmapaye@berkeley.edu">rmapaye@berkeley.edu</a>)</li>
  <li><a href="http://ist.berkeley.edu/" target="_blank" rel="noreferrer">IST (Information Services and Technology)</a></li>
  <li><a href="http://mailservices.berkeley.edu/" target="_blank" rel="noreferrer">Mail Services</a> contact: Durwin Wong</li>
  <li><a href="https://bds.berkeley.edu/barker" target="_blank" rel="noreferrer">MCB Barker Hall</a> contact: 642-1095</li>
  <li><a href="https://bds.berkeley.edu/scientific-facilities" target="_blank" rel="noreferrer">MCB Equipment Repair</a><br />contact: <a href="mailto:ib_mcb_recharge@berkeley.edu">ib_mcb_recharge@berkeley.edu</a></li>
  <li><a href="https://mcb.berkeley.edu/lsa/storeroom/index.html" target="_blank" rel="noreferrer">MCB Stockrooms</a></li>
  <li><a href="http://res2.berkeley.edu/people-finder/staff" target="_blank" rel="noreferrer">MSF (Mass. Spec. Facility)</a><br />contacts: Christine Owen, Troy Williams</li>
  <li><a href="http://www.olac.berkeley.edu/business" target="_blank" rel="noreferrer">OLAC (Office of Laboratory Animal Care)</a><br />contact: <a href="mailto:billing_olac@lists.berkeley.edu">billing_olac@lists.berkeley.edu</a></li>
  <li><a href="https://property.berkeley.edu/fleet-services" target="_blank" rel="noreferrer">Property Management / Fleet Services</a></li>
<li><a href="https://bds.berkeley.edu/directory/results/Departmental+Accounting/" target="_blank" rel="noreferrer">Tissue Culture</a><br />contact: Ann Fischer (<a href="mailto:afischer@berkeley.edu">afischer@berkeley.edu</a>)</li>
<li><a href="http://campuslifeservices.ucsf.edu/documentsmedia/services/print_management" target="_blank" rel="noreferrer">UCSF Printing Recharges</a><br />contact: Lisa Gee (<a href="mailto:lisa.gee@ucsf.edu">lisa.gee@ucsf.edu</a>)</li>
<li><a href="https://phoebe.rac.berkeley.edu" target="_blank" rel="noreferrer">Phoebe Proposal and Award</a></li>
<li><a href="https://calanswers-bi.berkeley.edu" target="_blank" rel="noreferrer">PI Portfolio</a></li>

</ul>
</td>
</tr>
</table>
</div>

					</Col>
				</Row>
			</Container>
		);
	}
}

export default Links;