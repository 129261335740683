import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class BlockGrant extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			blockGrant: {
				GRANT_ID: '' 
			},
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadBlockGrant();
	}

	loadBlockGrant(event) {
		var self = this;
		
		if (this.props.params.grantId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/GradOffice/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'BLOCK_GRANT',
					search: { Attribute: 'GRANT_ID', Operator: '=', LongValue: this.props.params.grantId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {		
						var blockGrant = data.Data[0];
						
						self.mvc2js(blockGrant, blockGrantColumns);
						
						self.mergeState({
							blockGrant: data.Data[0],
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Block Grant Error', data.Data.length + ' Block Grants found for ID ' + self.props.params.grantId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				blockGrant: {
					GRANT_ID: '(new)',
					AMOUNT: '',
					ACADEMIC_YEAR: '',
					LAST_MODIF: null,
					CURRENT_GRANT: true,
					DEPT_CODE: 1
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var blockGrant = this.copyObject(this.state.blockGrant);
		this.setByAccessor(blockGrant, accessor, value);
		this.mergeState({ blockGrant: blockGrant, renderKey: this.state.renderKey + 1 });
	}
		
	onSubmit(event) {
		var self = this;		
		var url = null;
		
		// make copy of data
		var blockGrant = this.copyObject(this.state.blockGrant);
		
		blockGrant.LAST_MODIF = this.dateTimeToMVC(new Date());
		this.js2mvc(blockGrant, blockGrantColumns);
		
		// if this is a new block grant
		if (!this.props.params.grantId) {
			// set ID to sequence name
			blockGrant.GRANT_ID = 'grant_id_seq.nextval';
			url = '/Home/Insert';
		} else {
			url = '/Home/Update';
		}
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'ADMISSIONS.BLOCK_GRANT', data: blockGrant }
		}).done(function (data) {
			if (data.Success) {
				var grantId = self.props.params.grantId || data.ID;
				var verb = self.props.params.grantId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Block Grant <Link to={'/GradOffice/BlockGrants/' + grantId}>{grantId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Block Grant {this.state.blockGrant.GRANT_ID}</div>
				  <Container fluid>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={blockGrantColumns} data={this.state.blockGrant} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
						  <Form.Group>
							<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
							{this.props.params.blockGrantId && <Button id="delete" variant="warning" type="button" className="float-end"
							  style={{ marginRight: '20px' }} onClick={(event) => this.deleteBlockGrant(event)}>Delete</Button>} 
						  </Form.Group>
						</ChemEdit>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const blockGrantColumns = [
	{ Header: 'Academic Year', accessor: 'ACADEMIC_YEAR', row: 0, col: 0 },
	{ Header: 'Amount', accessor: 'AMOUNT', row: 0, col: 1 },
	{ Header: 'Current', accessor: 'CURRENT_GRANT', type: 'checkbox', row: 1, col: 0 },
	{ Header: 'Modified', accessor: 'LAST_MODIF', row: 1, col: 1, editable: false,
		Cell: props => props.parent.dateTimeToString(props.parent.getDate(props.value)) }
];

export default withRouter(BlockGrant);
