import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import { Link } from 'react-router-dom';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import Tabs from '../Tabs';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';


class EmailContacts extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			EMAIL: '',
			REC_NAME: ''
		};
		
		// initialize cycle dates list
		var initialEmailContactsList = this.copyTable(emailContactsList);
		initialEmailContactsList.query.search = this.constructSearch(search);
		
		// remove add/edit column if not authorized
		if (props.user.crs.userAccessID !== 1) {
			initialEmailContactsList.columns.splice(0, 1);
		}
		
		this.state = {
			renderKey: 1,
			emailContactsList: initialEmailContactsList,
			search: search,
			searchCount: null,
			activeTab: 'Recharge Process Announcements',
		};
	}
	
	componentDidMount() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/GetCycleEmails',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken }
		}).done(function (data) {
			if (data.Success) {
				self.mergeState({
					dataRequest: {
						to: data.DataRequest.To,
						cc: data.DataRequest.CC,
						subject: data.DataRequest.Subject,
						body: data.DataRequest.Body
					},
					prelimInfo: {
						to: data.PrelimInfo.To,
						cc: data.PrelimInfo.CC,
						subject: data.PrelimInfo.Subject,
						body: data.PrelimInfo.Body
					},
					reportAvailability: {
						to: data.ReportAvailability.To,
						cc: data.ReportAvailability.CC,
						subject: data.ReportAvailability.Subject,
						body: data.ReportAvailability.Body
					}
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newEmailContactsList = this.copyTable(emailContactsList);
		newEmailContactsList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			emailContactsList: newEmailContactsList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.EMAIL)) search.Children.push({ Attribute: 'EMAIL', Operator: 'like', StringValue: '%' + data.EMAIL + '%' });
		if (!this.isEmpty(data.REC_NAME)) search.Children.push({ Attribute: 'REC_NAME', Operator: 'like', StringValue: '%' + data.REC_NAME + '%' });		

		if (search.Children.length === 0) return null;
		return search;
	}
	
	addEmailContact() {
		this.props.navigate('/CRS/EmailContacts/new');
	}
	
	editEmailContact(billingId) {
		this.props.navigate('/CRS/EmailContacts/' + billingId);
	}
	
	onClickTabItem(tab) {
		this.mergeState({ activeTab: tab });
	}

	render() {

		var exportDataRPA = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Email Contacts',
			columns: exportColumns,
			entity: 'RECHARGES.EMAIL_CONTACTS_VW',
			search: this.constructSearch(this.state.search),
			order: 'EMAIL'
		});
		
		var exportDataServices = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Service Email Contacts',
			columns: emailExportColumns,
			entity: 'RECHARGES.SERVICE_EMAIL_LIST_VW',
			search: null,
			order: 'EMAIL'
		});

		var exportDataRA = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'RA Email Contacts',
			columns: emailExportColumns,
			entity: 'RECHARGES.RA_EMAIL_LIST_VW',
			search: null,
			order: 'EMAIL'
		});

		var exportDataFSA = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'FSA Email Contacts',
			columns: emailExportColumns,
			entity: 'RECHARGES.FSA_EMAIL_LIST_VW',
			search: null,
			order: 'EMAIL'
		});

		var exportDataPI = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'PI Email Contacts',
			columns: emailExportColumns,
			entity: 'RECHARGES.PI_EMAIL_LIST_VW',
			search: null,
			order: 'EMAIL'
		});

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Email Contacts</span>
				    <Tabs onClickTabItem={(tab) => this.onClickTabItem(tab)}>
					  <div label='Recharge Process Announcements'>
						<ChemEdit parent={this} columns={emailContactsSearch} data={this.state.search} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
						  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
							<Col xs={4} style={{ marginTop: 8}}>
								{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
							</Col>
							<Col xs={1} style={{ marginTop: 6}}>
								<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
									{exportDataRPA.map((excelFormField, index) => {
										return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
									})}
									<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
									  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
									</OverlayTrigger>
								</form>
							</Col>
							<Col xs={1} style={{ marginTop: 6}}>
								<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
									{exportDataRPA.map((pdfFormField, index) => {
										return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
									})}
									<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
									  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
									</OverlayTrigger>
								</form>
							</Col>
							<Col xs={6}>
							  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
							</Col>
						  </Form.Group>
						</ChemEdit>
				      </div>
					  <div label='Service'>
					    <Container fluid>
						  <Row style={{ borderTop: '1px solid #eee', borderBottom: '1px solid #eee' }}>
						    <Col style={{ marginTop: 12 }}>
						      <ul>
						        <li>Service emails can be edited in <Link to='/CRS/Services'>Services</Link>.</li>
						      </ul>
							</Col>
						  </Row>
						  <Row>
							<Col xs={4}>
							</Col>
							<Col xs={1} style={{ marginTop: 16, marginBottom: 12 }}>
								<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
									{exportDataServices.map((excelFormField, index) => {
										return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
									})}
									<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
									  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
									</OverlayTrigger>
								</form>
							</Col>
							<Col xs={1} style={{ marginTop: 16, marginBottom: 12 }}>
								<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
									{exportDataServices.map((pdfFormField, index) => {
										return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
									})}
									<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
									  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
									</OverlayTrigger>
								</form>
							</Col>
						  </Row>
						</Container>
					  </div>
					  <div label='RA'>
					    <Container fluid>
						  <Row style={{ borderTop: '1px solid #eee', borderBottom: '1px solid #eee' }}>
						    <Col style={{ marginTop: 12 }}>
						      <ul>
						        <li>RA emails can be edited in <Link to='/CRS/RAs'>RAs</Link>.</li>
						      </ul>
							</Col>
						  </Row>
						  <Row>
							<Col xs={4}>
							</Col>
							<Col xs={1} style={{ marginTop: 16, marginBottom: 12 }}>
								<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
									{exportDataRA.map((excelFormField, index) => {
										return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
									})}
									<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
									  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
									</OverlayTrigger>
								</form>
							</Col>
							<Col xs={1} style={{ marginTop: 16, marginBottom: 12 }}>
								<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
									{exportDataRA.map((pdfFormField, index) => {
										return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
									})}
									<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
									  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
									</OverlayTrigger>
								</form>
							</Col>
						  </Row>
						</Container>
					  </div>
					  <div label='FSA'>
					    <Container fluid>
						  <Row style={{ borderTop: '1px solid #eee', borderBottom: '1px solid #eee' }}>
						    <Col style={{ marginTop: 12 }}>
						      <ul>
						        <li>FSA emails can be edited in <Link to='/BuildingAccess/People'>People Search</Link>.</li>
						      </ul>
							</Col>
						  </Row>
						  <Row>
							<Col xs={4}>
							</Col>
							<Col xs={1} style={{ marginTop: 16, marginBottom: 12 }}>
								<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
									{exportDataFSA.map((excelFormField, index) => {
										return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
									})}
									<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
									  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
									</OverlayTrigger>
								</form>
							</Col>
							<Col xs={1} style={{ marginTop: 16, marginBottom: 12 }}>
								<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
									{exportDataFSA.map((pdfFormField, index) => {
										return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
									})}
									<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
									  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
									</OverlayTrigger>
								</form>
							</Col>
						  </Row>
						</Container>
					  </div>
					  <div label='Faculty'>
					    <Container fluid>
						  <Row style={{ borderTop: '1px solid #eee', borderBottom: '1px solid #eee' }}>
						    <Col style={{ marginTop: 12 }}>
						      <ul>
						        <li>PI emails can be edited in <Link to='/CRS/PIs'>PIs</Link>.</li>
						      </ul>
							</Col>
						  </Row>
						  <Row>
							<Col xs={4}>
							</Col>
							<Col xs={1} style={{ marginTop: 16, marginBottom: 12 }}>
								<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
									{exportDataPI.map((excelFormField, index) => {
										return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
									})}
									<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
									  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
									</OverlayTrigger>
								</form>
							</Col>
							<Col xs={1} style={{ marginTop: 16, marginBottom: 12 }}>
								<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
									{exportDataPI.map((pdfFormField, index) => {
										return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
									})}
									<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
									  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
									</OverlayTrigger>
								</form>
							</Col>
						  </Row>
						</Container>
					  </div>
					</Tabs>
				  </Col>
				</Row>
				{this.state.activeTab === 'Recharge Process Announcements' && <Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_EmailContacts' table={this.state.emailContactsList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='250px' />
					</Col>
				</Row>}
				{this.state.activeTab === 'Service' && <Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_ServiceEmailContacts' table={serviceList} offsetHeight='250px' />
					</Col>
				</Row>}
				{this.state.activeTab === 'RA' && <Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_RAEmailContacts' table={raList} offsetHeight='250px' />
					</Col>
				</Row>}
				{this.state.activeTab === 'FSA' && <Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_FSAEmailContacts' table={fsaList} offsetHeight='250px' />
					</Col>
				</Row>}
				{this.state.activeTab === 'Faculty' && <Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_PIEmailContacts' table={piList} offsetHeight='250px' />
					</Col>
				</Row>}
			</Container>
		</>);
	}
}

const emailContactsSearch = [
	{ Header: 'Email', accessor: 'EMAIL', row: 0, col: 0 },
	{ Header: 'Name', accessor: 'REC_NAME', row: 0, col: 1 }
];

const emailContactsList = {
	query: { entity: 'RECHARGES.EMAIL_CONTACTS_VW', search: null, order: 'EMAIL' },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addEmailContact()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editEmailContact(props.row.values.EMAIL_ID)} />, width: 25 },
		{ Header: 'ID', accessor: 'EMAIL_ID', width: 60 },
		{ Header: 'Email', accessor: 'EMAIL', width: 225 },
		{ Header: 'Contact Name', accessor: 'REC_NAME', width: 225 },
		{ Header: () => <>Outside<br />Contact</>, accessor: 'OUTSIDE_FLAG', width: 80 },
		{ Header: 'Comments', accessor: 'COMMENTS', width: 400 },
		{ Header: 'Last Modif', accessor: 'MODIFIED', width: 300 },
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'EMAIL_ID', width: 12 },
	{ Header: 'Email', accessor: 'EMAIL', width: 45 },
	{ Header: 'Contact Name', accessor: 'REC_NAME', width: 45 },
	{ Header: 'Outside Contact', accessor: 'OUTSIDE_FLAG', width: 16 },
	{ Header: 'Comments', accessor: 'COMMENTS', width: 80 },
	{ Header: 'Last Modif', accessor: 'MODIFIED', width: 60 },
];

const serviceList = {
	query: { entity: 'RECHARGES.SERVICE_EMAIL_LIST_VW', search: null, order: 'EMAIL' },
	pageSize: 20,
	columns: [
		{ Header: 'Email', accessor: 'EMAIL', width: 500 }
	]
};

const raList = {
	query: { entity: 'RECHARGES.RA_EMAIL_LIST_VW', search: null, order: 'EMAIL' },
	pageSize: 20,
	columns: [
		{ Header: 'Email', accessor: 'EMAIL', width: 500 }
	]
};

const fsaList = {
	query: { entity: 'RECHARGES.FSA_EMAIL_LIST_VW', search: null, order: 'EMAIL' },
	pageSize: 20,
	columns: [
		{ Header: 'Email', accessor: 'EMAIL', width: 500 }
	]
};

const piList = {
	query: { entity: 'RECHARGES.PI_EMAIL_LIST_VW', search: null, order: 'EMAIL' },
	pageSize: 20,
	columns: [
		{ Header: 'Email', accessor: 'EMAIL', width: 500 }
	]
};

const emailExportColumns = [
	{ Header: 'Email', accessor: 'EMAIL', width: 100 }
];

export default withRouter(EmailContacts);