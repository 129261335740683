import React, { useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import ChemComponent from './ChemComponent';
import { Link } from 'react-router-dom';

const emptyStringArray = function(length) {
	var stringArray = [];
	for (var i = 0; i < length; i++) stringArray.push('');
	return stringArray;
}

// forwardRef is important!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy, defaultOption, compare }, ref) => {
    const [value, setValue] = useState(emptyStringArray(defaultOption.label.length));

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
		<table>
		  <tr>
		    {defaultOption.label.map((label, key) => {
			  return (
				<td key={key}>
				  <Form.Control
					className="mx-1 my-1 w-auto"
					placeholder="Type to filter..."
					onChange={(e) => {
						var newState = JSON.parse(JSON.stringify(value));
						newState[key] = e.target.value;
						setValue(newState);
					}}
					value={value[key]}
				  />
				</td>
			  );
			})}
		  </tr>
		
          {React.Children.toArray(children).filter(
            (child) => {
			  var retval = true;
			  if (compare === 'indexOf') {
				for (let i = 0; i < value.length; i++) {
				  retval = retval && (!value[i] || (child.props.option.label[i] && child.props.option.label[i].toLowerCase().indexOf(value[i].toLowerCase()) >= 0));
				}
			  } else {
				for (let i = 0; i < value.length; i++) {
				  retval = retval && (!value[i] || (child.props.option.label[i] && child.props.option.label[i].toLowerCase().startsWith(value[i].toLowerCase())));
				}
			  }
			  return retval;
			}
          )}
        </table>
      </div>
    );
  },
);

class MultiColumn extends ChemComponent
{
	render() {
		var optionalToggleProp = {};
		if (this.props.as === 'STOCK_NUMBER') optionalToggleProp.as = 'span';
		return (
		  <Dropdown>
		    {this.props.as === 'STOCK_NUMBER' ?
				<><Link to={'/Stores/Catalog/' + this.props.defaultOption.value}>{this.props.defaultOption.label[0]}</Link>&nbsp;</> :
				<Form.Control type='text' value={this.props.defaultOption.label[0]} disabled />}
			<Dropdown.Toggle title='' align='end' show={this.props.editable} {...optionalToggleProp} />
			<Dropdown.Menu as={CustomMenu} defaultOption={this.props.defaultOption} compare={this.props.compare}>
			  {this.props.options.map((option, dropdownKey) => {
				  return (
					<Dropdown.Item as='tr' bsPrefix='mc-dropdown-item' key={dropdownKey} eventKey={dropdownKey} option={option}
					  onClick={() => this.props.onChange(option.value, option.label)}>
					   {option.label.map((label, tdKey) => {
						  return (<td key={tdKey}><div style={{ marginLeft: '4px' }}>{label}</div></td>); 
					   })}
				    </Dropdown.Item>
				  );
			  })}
			</Dropdown.Menu>
		  </Dropdown>
		);
	}
}

export default MultiColumn;
