import React from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Header extends ChemComponent {
	constructor(props) {
		super(props);
		
		var initHeaderColumns = this.copyColumns(headerColumns);
		if (this.props.user.crs.serviceMgr.length > 0) {
			// restrict services to those available to user
			initHeaderColumns[0].options.search = { Attribute: 'SERVICE_UNIT', Operator: 'in', LongList: this.props.user.crs.serviceMgr };
		}
		
		this.state = {
			header: {
				HEADER_ID: null,
				SERVICE_ID: null,
				HEADER_NAME: '',
				COMMENTS: '',
				DATE_CREATED: null,
				CREATED_BY: '',
				DATE_MODIF: null,
				MODIF_BY: '',
				DELETE_FLAG: null,
				CREATED: '',
				MODIFIED: ''
			},
			headerColumns: initHeaderColumns,
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadHeader();
	}

	loadHeader(event) {
		var self = this;
		
		if (this.props.params.headerId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/CRS/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'UPLOAD_HEADER',
					search: { Attribute: 'HEADER_ID', Operator: '=', LongValue: this.props.params.headerId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var header = data.Data[0];
						
						// convert flags
						header.DELETE_FLAG = header.DELETE_FLAG === 'Y' ? true : false;
						
						// convert dates
						header.DATE_CREATED = self.dateTimeToMVC(self.getDate(header.DATE_CREATED));
						header.DATE_MODIF = self.dateTimeToMVC(self.getDate(header.DATE_MODIF));
						
						// compose created/modified
						header.CREATED = self.composeChangeInfo(header.CREATED_BY, header.DATE_CREATED);
						header.MODIFIED = self.composeChangeInfo(header.MODIF_BY, header.DATE_MODIF);
						
						self.mergeState({
							header: header,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Header Error', data.Data.length + ' Headers found for ID ' + self.props.params.headerId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				header: {
					HEADER_ID: '(new)',
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var header = this.copyObject(this.state.header);
		this.setByAccessor(header, accessor, value);
		this.mergeState({ header: header, renderKey: this.state.renderKey + 1 });
	}
		
	deleteHeader(event) {
		var self = this;
		
		// assemble user object to update inactive flag
		var header = {
			HEADER_ID: this.props.params.headerId,
			DELETE_FLAG: 'Y',
			DATE_MODIF: this.dateTimeToMVC(new Date()),
			MODIF_BY: this.props.user.name
		};
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/Home/Update',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'RECHARGES.UPLOAD_HEADER', data: header }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Header {self.props.params.headerId} deleted.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	validateHeader(event, columns, data) {
		return this.validate(columns, data);
	}
	
	editColumns(event) {
		this.props.navigate('/CRS/Headers/' + this.props.params.headerId + '/Columns');
	}
		
	onSubmit(event) {
		var self = this;		
		
		// make copy of data
		var header = this.copyObject(this.state.header);

		// if this is a new user
		var url;
		if (this.props.params.headerId) {
			url = this.getConfig().host + '/Home/Update';
			header.DATE_MODIF = this.dateTimeToMVC(new Date());
			header.MODIF_BY = this.props.user.name;
			header.DATE_CREATED = this.dateTimeToMVC(this.getDate(header.DATE_CREATED));
		} else {
			url = this.getConfig().host + '/Home/Insert';
			header.DATE_CREATED = this.dateTimeToMVC(new Date());
			header.CREATED_BY = this.props.user.name;
			// set ID to null so it will use max+1
			header.HEADER_ID = null;
		}
		
		// convert flags
		header.DELETE_FLAG = header.DELETE_FLAG ? 'Y' : null;
		
		this.ajax({
			type: 'POST',
			url: url,
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'RECHARGES.UPLOAD_HEADER', data: header }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Header <Link to={'/CRS/Headers/' + (self.props.params.headerId || data.ID)}>{self.props.params.headerId || data.ID}</Link> updated.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	renderEditColumns() {
		if (this.props.params.headerId) {
			return (<Button id='columns' variant='warning' type='button' onClick={(event) => this.editColumns(event)}>Edit</Button>);
		} else {
			return (<i>Click save button to proceed to column editor</i>);
		}
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Header {this.state.header.HEADER_ID}</div>
				<ChemEdit parent={this} columns={this.state.headerColumns} data={this.state.header} user={this.props.user} renderKey={this.state.renderKey} 
				  onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}
				  validate={(event, columns, data) => this.validateHeader(event, columns, data)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					  {this.props.params.headerId && <Button id="delete" variant="warning" type="button" className="float-end" style={{ marginRight: '10px' }} onClick={(event) => this.deleteHeader(event)}>Delete</Button>}
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const headerColumns = [
	{ Header: 'Service', accessor: 'SERVICE_ID', type: 'select', row: 0, col: 0, required: true,
		options: { value: 'SERVICE_ID', label: 'SERVICE_NAME', entity: 'RECHARGES.UPLOAD_SERVICES_VW', order: ['SERVICE_UNIT', 'SERVICE_NAME']}},
	{ Header: 'Name', accessor: 'HEADER_NAME', row: 0, col: 1, required: true },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 1, col: 0 },
	{ Header: 'Columns', accessor: 'COLUMNS', row: 1, col: 1, Cell: (props) => props.parent.renderEditColumns() },
	{ Header: 'Created', accessor: 'CREATED', row: 2, col: 0, editable: false },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 2, col: 1, editable: false },
];

export default withRouter(Header);
