import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import ChemTable from '../ChemTable';

import { ArrowDownUp, GripHorizontal, PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class Tasks extends ChemComponent {
  	constructor(props) {
		super(props);
		
		this.state = {
			renderKey: 1,
			tasks: [],
			pageNumber: 1,
			pageSize: 100,
			pageCount: 1,
			moveTask: 0,
			moveSteps: 0,
			search: {
				NAME: '',
				DESCRIPTION: '',
				DATE_FROM: '',
				DATE_TO: '',
				OPEN: 'Yes'
			}
		};
	}

	componentDidMount() {
		this.loadTasks();
	}
	
	loadTasks(pageNumber, pageSize, searchdata, event) {
		var self = this;
		
		if (!pageNumber) pageNumber = this.state.pageNumber;
		if (!pageSize) pageSize = this.state.pageSize;
		if (!searchdata) searchdata = this.state.search;

		// construct search terms
		var search = {
			Operator: 'and',
			children: []
		};

		if (!this.isEmpty(searchdata.NAME)) search.children.push({ Attribute: 'NAME', Operator: '=', StringValue: searchdata.NAME });
		if (!this.isEmpty(searchdata.DESCRIPTION)) search.children.push({ Attribute: 'DESCRIPTION', Operator: '=', StringValue: searchdata.DESCRIPTION });
		if (!this.isEmpty(searchdata.DATE_FROM)) search.children.push({ Attribute: 'ASSIGN_DATE', Operator: '>=', DateValue: searchdata.DATE_FROM });
		if (!this.isEmpty(searchdata.DATE_TO)) search.children.push({ Attribute: 'ASSIGN_DATE', Operator: '<=', DateValue: searchdata.DATE_TO });
		if (searchdata.OPEN === 'Yes') {
			search.children.push({ Attribute: 'COMPLETE_DATE', Operator: 'is null' });
		} else if (searchdata.OPEN === 'No') {
			search.children.push({ Attribute: 'COMPLETE_DATE', Operator: 'is not null' });
		}
		
		// if nothing was selected, forget it
		if (search.children.length === 0) search = [];
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Home/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'TASK',
				search: search,
				order: 'TASK_ORDER',
				pageNumber: pageNumber,
				pageSize: pageSize
			}
		}).done(function (data) {
			if (data.Success) {
				// for each task
				for (var i = 0; i < data.Data.length; i++) {
					// add an id to the row
					data.Data[i].id = i;
					data.Data[i].drag = i;
				}
			
				self.mergeState({
					renderKey: self.state.renderKey + 1,
					tasks: data.Data,
					pageNumber: data.PageNumber,
					pageCount: data.PageCount,
					pageSize: data.PageSize
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
		
	addTask() {
		this.props.navigate('/WebAdmin/Tasks/new');
	}
	
	editTask(rowId) {
		// look for taskID in data
		var taskId = -1;
		for (var i = 0; i < this.state.tasks.length; i++) {
			if (this.state.tasks[i].id === rowId) {
				taskId = this.state.tasks[i].TASK_ID;
				break;
			}
		}
		if (taskId !== -1) {
			this.props.navigate('/WebAdmin/Tasks/' + taskId);
		} else {
			this.showAlert('ID not found', 'Could not find a task ID for row ' + rowId);
		}
	}
	
	moveRow(dragId, nSteps) {
		// look for taskID in data
		var taskId = -1;
		for (var i = 0; i < this.state.tasks.length; i++) {
			if (this.state.tasks[i].drag === dragId) {
				taskId = this.state.tasks[i].TASK_ID;
				break;
			}
		}
		
		if (taskId !== -1) {
			this.mergeState({ moveTask: taskId, moveSteps: this.state.moveSteps - nSteps });
		} else {
			this.showAlert('ID not found', 'Could not find a task ID for row ' + dragId);
		}
	}
	
	dropRow() {
		var self = this;
		var moveTask = {
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			taskid: this.state.moveTask,
			nsteps: this.state.moveSteps
		};
		this.mergeState({ moveTask: 0, moveSteps: 0 }, () => {
			if (moveTask.nsteps !== 0) {
				this.ajax({
					type: 'post',
					url: this.getConfig().host + '/Admin/MoveTask',
					data: moveTask
				}).done(function (data) {
					if (data.Success) {
						self.loadTasks(self.state.pageNumber, self.state.pageSize);
					} else {
						self.showAlert('Move Task Error', data.Message);
					}
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
	
	firstPage() {
		if (this.state.pageNumber !== 1) {
			this.loadTasks(1, this.state.pageSize);
		}
	}
	
	previousPage() {
		if (this.state.pageNumber > 1) {
			this.loadTasks(this.state.pageNumber - 1, this.state.pageSize);
		}		
	}
	
	nextPage() {
		// server will correct if page number is too big
		this.loadTasks(this.state.pageNumber + 1, this.state.pageSize);		
	}
	
	lastPage() {
		// use -1 to indicate last page since we don't 
		// necessarily know how many pages there are now
		this.loadTasks(-1, this.state.pageSize);
	}
	
	setPageSize(pageSize) {
		if (pageSize !== this.state.pageSize) {
			this.loadTasks(this.state.pageNumber, pageSize);
		}
	}
	
	setPageNumber(pageNumber) {
		if (pageNumber !== this.state.pageNumber) {
			this.loadTasks(pageNumber, this.state.pageSize);
		}
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event, data)
	{			
		this.loadTasks(this.state.pageNumber, this.state.pageSize, this.state.search, event);
	}
	
	render() {
		
		const search = [
			{ Header: 'Name', accessor: 'NAME', row: 0, col: 0 },
			{ Header: 'Description', accessor: 'DESCRIPTION', row: 0, col: 1 },
			{ Header: 'From', accessor: 'DATE_FROM', row: 1, col: 0, type: 'datetime' },
			{ Header: 'To', accessor: 'DATE_TO', row: 1, col: 1, type: 'datetime' },
			{ Header: 'Open', accessor: 'OPEN', row: 2, col: 0, type: 'select',
				options: [ { value: 'All', label: 'All' }, { value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' } ] }
 		];

		const columns = [
			{ Header: <ArrowDownUp />, accessor: 'drag', drag: true, width: 25,
				Cell: props => <GripHorizontal style={{ cursor: 'pointer' }} /> },
			{ Header: <PlusSquare style={{ cursor: 'pointer' }} onClick={() => this.addTask()} />, accessor: 'id', 
				Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => this.editTask(props.value)} />, width: 25 },
			{ Header: 'ID', accessor: 'TASK_ID', width: 75, Cell: (props) => <Link to={'/WebAdmin/Tasks/' + props.value}>{props.value}</Link> },
			// for debugging { Header: 'Order', accessor: 'TASK_ORDER', width: 75 },
			{ Header: 'Name', accessor: 'NAME', width: 150 },
			{ Header: 'Description', accessor: 'DESCRIPTION', width: 600 },
			{ Header: 'For', accessor: 'REQUEST_BY', width: 150 },
			{ Header: 'Created', accessor: 'ASSIGN_DATE', width: 150,
				Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) }
		];

		return (<>
			<Container fluid>
				<Row>
					<Col>
					  <Container>
					    <Row>
						  <Col xs={10} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
							<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Web Application Tasks</span>
					        <ChemEdit parent={this} columns={search} data={this.state.search} renderKey={this.state.renderKey} 
						      onChange={(accessor, value) => this.onChangeSearch(accessor,value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
							  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						        <Col xs={12}>
					              <Button id="update" variant="warning" type="submit" className="float-end">Update</Button>
								</Col>
							  </Form.Group>
					        </ChemEdit>
					      </Col>
						</Row>
					  </Container>
					</Col>
				</Row>
				<Row>
					<Col>
						<ChemTable renderKey={this.state.renderKey} parent={this} name='WebAdmin_Tasks' columns={columns} data={this.state.tasks} offsetHeight='330px'
						  pageNumber={this.state.pageNumber} pageSize={this.state.pageSize} pageCount={this.state.pageCount}/>
					</Col>
				</Row>
			</Container>
		</>);
	}
}

export default withRouter(Tasks);