import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../../ChemComponent';
import ChemEdit from '../../ChemEdit';
import QueryTable from '../../QueryTable';
import { Link } from 'react-router-dom';

class KeyHolders extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var sevenYearsAgo = new Date();
		sevenYearsAgo.setYear(sevenYearsAgo.getFullYear() - 7);
		
		var search = {
			KEY_TYPE: [],
			ROOM_ID: [],
			BUILDING_ID: [],
			STATUS_ID: [],
			OUT_DATE_FROM: this.dateTimeToMVC(sevenYearsAgo),
			OUT_DATE_TO: null,
			TYPE_ID: [],
			PAYMENT_TYPE: [],
			INCLUDE_RETURNED_KEYS: false
		};
		
		var initialKeyHolderList = this.copyKeyHolderList();
		initialKeyHolderList.query.search = this.constructSearch(search);
		
		this.state = {
			renderKey: 1,
			keyHolderList: initialKeyHolderList,
			search: search,
			searchCount: null
		};
	}
	
	copyKeyHolderList() {
		var keyHolderListCopy = this.copyObject(keyHolderList);
		for (var i = 0; i < keyHolderList.columns.length; i++) {
			if (keyHolderList.columns[i].Cell) {
				keyHolderListCopy.columns[i].Cell = keyHolderList.columns[i].Cell;
			}
		}
		return keyHolderListCopy;
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newKeyHolderList = this.copyKeyHolderList();
		newKeyHolderList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			keyHolderList: newKeyHolderList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	generateInList(name, list) {
		var orClause = { Operator: 'or', Children: [] };
		
		for (var i = 0; i < list.length; i++) {
			orClause.Children.push({ Attribute: name, Operator: 'like', StringValue: '%|' + list[i] + '|%' });
		}
		
		return orClause;
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (data.KEY_TYPE.length > 0) search.Children.push({ Attribute: 'KEY_TYPE', Operator: 'in', LongList: data.KEY_TYPE });
		if (data.ROOM_ID.length > 0) search.Children.push(this.generateInList('ROOM_ID', data.ROOM_ID));
		if (data.BUILDING_ID.length > 0) search.Children.push(this.generateInList('BUILDING_ID', data.BUILDING_ID));
		if (data.STATUS_ID.length > 0) {
			var statusOrClause = { Operator: 'or', Children: [] };
			
			for (var i = 0; i < data.STATUS_ID.length; i++) {
				if (data.STATUS_ID[i] === '0') {
					statusOrClause.Children.push({ Attribute: 'STATUS_ID', Operator: 'is null' });
				} else {
					statusOrClause.Children.push({ Attribute: 'STATUS_ID', Operator: '=', LongValue: data.STATUS_ID[i] });
				}
			}
			
			search.Children.push(statusOrClause);
		}
		if (!this.isEmpty(data.OUT_DATE_FROM)) {
			search.Children.push({ Attribute: 'OUT_DATE_CMP', Operator: '>=', DateValue: data.OUT_DATE_FROM });
		}
		if (!this.isEmpty(data.OUT_DATE_TO)) {
			search.Children.push({ Attribute: 'OUT_DATE_CMP', Operator: '<=', DateValue: data.OUT_DATE_TO });
		}
		if (data.TYPE_ID.length > 0) search.Children.push({ Attribute: 'TYPE_ID', Operator: 'in', LongList: data.TYPE_ID });
		if (data.PAYMENT_TYPE.length > 0) search.Children.push({ Attribute: 'PAYMENT_TYPE', Operator: 'in', StringList: data.PAYMENT_TYPE });
		if (!data.INCLUDE_RETURNED_KEYS) search.Children.push({ Attribute: 'RETURN_DATE_CMP', Operator: 'is null' });
		
		// if nothing was selected, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	onTableSort(newOrder) {
		var newKeyHolderList = this.copyTable(this.state.keyHolderList);
		newKeyHolderList.query.order = newOrder;
		
		this.mergeState({
			keyHolderList: newKeyHolderList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
	}

	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Key Holders Report',
			columns: exportColumns,
			entity: 'KEY_HOLDERS_VW',
			search: this.constructSearch(this.state.search),
			order: ['KEY_ID', 'PERSON_NAME', 'PI_NAME']
		});

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Key Holders Report</span>
					<ChemEdit parent={this} columns={keyHolderSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={3} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_KeyHolders' table={this.state.keyHolderList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='405px' onTableSort={(newOrder) => this.onTableSort(newOrder)} />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const keyHolderSearch = [
	{ Header: 'Key Series', accessor: 'KEY_TYPE', type: 'list', row: 0, col: 0,
		options: { value: 'TYPE_ID', label: 'TYPE_NAME', entity: 'KEY_HOLDER_TYPES_VW', order: 'TYPE_NAME' } },
	{ Header: 'Location', accessor: 'ROOM_ID', type: 'list', row: 0, col: 1,
		options: { value: 'ROOM_ID', label: 'LOCATION', entity: 'KEY_HOLDER_ROOMS_VW', order: 'LOCATION' } },
	{ Header: 'Building', accessor: 'BUILDING_ID', type: 'list', row: 0, col: 2,
		options: { value: 'BUILDING_ID', label: 'BUILDING_NAME', entity: 'BUILDING', order: 'BUILDING_NAME',
			search: { Operator: 'and', Children: [
				{ Attribute: 'DELETE_FLAG', Operator: 'is null' },
				{ Attribute: 'CHEMISTRY_FLAG', Operator: '=', StringValue: 'Y' }]}}},
	{ Header: 'Status', accessor: 'STATUS_ID', type: 'list', row: 1, col: 0,
		options: { value: 'STATUS_ID', label: 'STATUS_NAME', entity: 'KEY_STATUS_SELECT_VW', order: 'STATUS_ID' }},
	{ Header: 'Appointment', accessor: 'TYPE_ID', type: 'list', row: 1, col: 1,
		options: { value: 'TYPE_ID', label: 'TYPE_NAME', entity: 'ROLE_TYPES', order: 'TYPE_NAME',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Payment Type', accessor: 'PAYMENT_TYPE', type: 'list', row: 1, col: 2,
		options: [{ value: 'Personal Check', label: 'Personal Check' },{ value: 'Money Order', label: 'Money Order' },{ value: 'Other', label: 'Other' }]},	
	{ Header: 'Out Date From', accessor: 'OUT_DATE_FROM', type: 'date', row: 2, col: 0 },
	{ Header: 'Out Date To', accessor: 'OUT_DATE_TO', type: 'date', row: 2, col: 1 },
	{ Header: 'Include Returned Keys', accessor: 'INCLUDE_RETURNED_KEYS', type: 'checkbox', row: 2, col: 2 }
];

const keyHolderList = {
	query: { entity: 'KEY_HOLDERS_VW', search: null, order: ['KEY_ID', 'PERSON_NAME', 'PI_NAME'] },
	pageSize: 20,
	columns: [
		{ Header: 'ID', accessor: 'KEY_ID', 
			Cell: props => <Link to={'/BuildingAccess/Keys/' + props.value}>{props.value}</Link> },
		{ Header: 'Key Series', accessor: 'TYPE_NAME' },
		{ Header: 'PERSON_ID', accessor: 'PERSON_ID', show: false },
		{ Header: 'Name', accessor: 'PERSON_NAME',
			Cell: props => <Link to={'/BuildingAccess/People/' + props.row.values.PERSON_ID}>{props.value}</Link> },
		{ Header: 'PI', accessor: 'PI_NAME' },
		{ Header: 'Status', accessor: 'PERSON_STATUS' },
		{ Header: 'Issued Date', accessor: 'OUT_DATE' },
		{ Header: 'Date Left', accessor: 'DATE_LEFT' },
		{ Header: 'Key Status', accessor: 'KEY_STATUS' },
		{ Header: 'Returned', accessor: 'RETURN_DATE' },
		{ Header: 'Deposit Paid', accessor: 'DEPOSIT' },
		{ Header: 'Payment Type', accessor: 'PAYMENT_TYPE' },
		{ Header: 'Deposit Returned', accessor: 'RETURN_DEPOSIT' },
		{ Header: 'Comments', accessor: 'COMMENTS' }
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'KEY_ADMIN_ID', width: 10 },
	{ Header: 'Key Series', accessor: 'TYPE_NAME', width: 20 },
	{ Header: 'Name', accessor: 'PERSON_NAME', width: 20 },
	{ Header: 'PI', accessor: 'PI_NAME', width: 20 },
	{ Header: 'Status', accessor: 'PERSON_STATUS', width: 15 },
	{ Header: 'Issued Date', accessor: 'OUT_DATE', width: 15 },
	{ Header: 'Date Left', accessor: 'DATE_LEFT', width: 15 },
	{ Header: 'Key Status', accessor: 'KEY_STATUS', width: 20 },
	{ Header: 'Returned', accessor: 'RETURN_DATE', width: 15 },
	{ Header: 'Deposit Paid', accessor: 'DEPOSIT', width: 10 },
	{ Header: 'Payment Type', accessor: 'PAYMENT_TYPE', width: 10 },
	{ Header: 'Deposit Returned', accessor: 'RETURN_DEPOSIT', width: 15 },
	{ Header: 'Comments', accessor: 'COMMENTS', width: 20 }
];

export default KeyHolders;