import { Col, Container, Row } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';
import { withRouter } from '../withRouter';

class ErrorDetail extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			error: {
				ERROR_ID: this.props.params.errorId
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Home/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'ERROR_LOG_VW',
				search: { Attribute: 'ERROR_ID', Operator: '=', LongValue: this.props.params.errorId },
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {			
				self.mergeState({
					renderKey: self.state.renderKey + 1,
					error: data.Data[0]
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
			
	render() {		
		return (<>
			<Container fluid>
			  <Row>
			    <Col md={12} lg={11} xl={10} style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Error {this.state.error.ERROR_ID}</span>
			      <ChemEdit parent={this} columns={errorColumns} data={this.state.error} user={this.props.user}
					labelColWidth={3} valueColWidth={9} renderKey={this.state.renderKey} />
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const errorColumns = [
	{ Header: 'Cal UID', accessor: 'CAL_UID', editable: false },
	{ Header: 'Person ID', accessor: 'PERSON_ID', editable: false },
	{ Header: 'Name', accessor: 'NAME', editable: false },
	{ Header: 'Date', accessor: 'ERROR_DATE', editable: false,
		Cell: props => props.parent.dateTimeToString(props.parent.getDate(props.value)) },
	{ Header: 'Raw URL', accessor: 'RAW_URL', editable: false },
	{ Header: 'User Host Name', accessor: 'USER_HOST_NAME', editable: false },
	{ Header: 'User Host Address', accessor: 'USER_HOST_ADDR', editable: false },
	{ Header: 'Server Machine Name', accessor: 'SERVER_MACHINE_NAME', editable: false },
	{ Header: 'Exception', accessor: 'EXCEPTION', type: 'textarea', rows: 10, editable: false },
];

export default withRouter(ErrorDetail);