import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';

class LDAP extends ChemComponent {
  	constructor(props) {
		super(props);
					
		this.state = {
			renderKey: 1,
			search: {
				uid: '',
				result: ''
			}
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Admin/UIDLookup',
			data: { uid: this.state.search.uid }
		}).done(function (data) {
			if (data.Success) {
				self.mergeState({
					search: {
						result: data.Message
					}
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	

		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	render() {
		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Rooms</span>
					<ChemEdit parent={this} columns={ldapSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
			</Container>
		</>);
	}
}

const ldapSearch = [
	{ Header: 'UID', accessor: 'uid', row: 0, col: 0 },
	{ Header: 'Result', accessor: 'result', row: 0, col: 1, editable: false }
];

export default withRouter(LDAP);