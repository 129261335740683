import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import Tabs from '../Tabs';
import { PencilSquare, InfoCircle } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';


class SearchPrelim extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var prelimSearch = {
			TRANS_DATE_FROM: null,
			TRANS_DATE_TO: null,
			PI_NAME: '',
			USER_NAME: '',
			SPEEDTYPE: '',
			SERVICE_ID: []
		};
		
		// initialize prelim list
		var initialPrelimList = this.copyTable(prelimList);
		if (this.props.params.serviceId) {
			initialPrelimList.query.search = { Attribute: 'SERVICE_ID', Operator: '=', LongValue: this.props.params.serviceId };
		} else {
			initialPrelimList.query.search = this.constructPrelimSearch(prelimSearch);
		}
		
		// remove add/edit column if not authorized
		if (props.user.crs.userAccessID !== 1) {
			initialPrelimList.columns.splice(0, 1);
		}

		var surchargeSearch = {
			TRANS_DATE_FROM: null,
			TRANS_DATE_TO: null,
			PI_NAME: '',
			USER_NAME: '',
			SPEEDTYPE: '',
			SERVICE_ID: []
		};
		
		// initialize surcharge list
		var initialSurchargeList = this.copyTable(surchargeList);
		initialSurchargeList.query.search = this.constructSurchargeSearch(surchargeSearch);

		this.state = {
			prelimList: initialPrelimList,
			prelimSearch: prelimSearch,
			prelimSearchCount: null,
			surchargeList: initialSurchargeList,
			surchargeSearch: surchargeSearch,
			surchargeSearchCount: null,
			activeTab: 'Preliminary Journal',
			begDate: null,
			endDate: null,
			prelimEnd: null,
			prelimEndRA: null,
			renderKey: 1
		};
	}
	
	componentDidMount() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'CURRENT_CYCLE_VW',
				search: null,
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				if (data.Data.length > 0 && !self.isEmpty(data.Data[0].END_DATE)) {
					var cycle = data.Data[0];
																	
					self.mergeState({
						begDate: self.getDate(cycle.BEG_DATE),
						endDate: self.getDate(cycle.END_DATE),
						prelimEnd: self.getDate(cycle.PRELIM_END),
						prelimEndRA: self.getDate(cycle.NEXT_WEEKDAY),
						renderKey: self.state.renderKey + 1
					});
				} else {
					self.showAlert('Current Cycle Error', 'Current cycle end date not found.');
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	onChangePrelimSearch(accessor, value) {
		var prelimSearch = this.copyObject(this.state.prelimSearch);
		this.setByAccessor(prelimSearch, accessor, value);
		this.mergeState({ prelimSearch: prelimSearch });
	}
	
	onSubmitPrelimSearch(event) {
		var newPrelimList = this.copyTable(prelimList);
		newPrelimList.query.search = this.constructPrelimSearch(this.state.prelimSearch);
		this.mergeState({ 
			prelimList: newPrelimList,
			prelimSearchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onPrelimSearchResult(data) {
		this.mergeState({
			prelimSearchCount: data.ResultCount
		});
	}
	
	constructPrelimSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.TRANS_DATE_FROM)) search.Children.push({ Attribute: 'TRANS_DATE', Operator: '>=', StringValue: this.formatDateForCompare(this.getDate(data.TRANS_DATE_FROM)) });
		if (!this.isEmpty(data.TRANS_DATE_TO)) search.Children.push({ Attribute: 'TRANS_DATE', Operator: '<=', StringValue: this.formatDateForCompare(this.getDate(data.TRANS_DATE_TO)) });
		if (!this.isEmpty(data.PI_NAME)) search.Children.push({ Operator: 'or', Children: [
			{ Attribute: 'PI_NAME', Operator: 'like', StringValue: data.PI_NAME },
			{ Attribute: 'SPEEDTYPE_PI', Operator: 'like', StringValue: data.PI_NAME }
		]});
		if (!this.isEmpty(data.USER_NAME)) search.Children.push({ Attribute: 'USER_NAME', Operator: 'like', StringValue: data.USER_NAME });
		if (!this.isEmpty(data.SPEEDTYPE)) search.Children.push({ Attribute: 'SPEEDTYPE', Operator: 'like', StringValue: data.SPEEDTYPE });
		if (data.SERVICE_ID.length > 0) {
			// combine all lists into one and then split
			search.Children.push({ Attribute: 'SERVICE_ID', Operator: 'in', LongList: data.SERVICE_ID.join(',').split(',') });
		}
		
		// if nothing was specified, forget it
		if (search.Children.length === 0) return null;
		return search;
	}
	
	formatDateForCompare(date) {
		var d = date.getDate();
		var m = date.getMonth() + 1; //Month from 0 to 11
		var y = date.getFullYear();
		return y.toString() + '/' + (m <= 9 ? ('0' + m) : m) + '/' + (d <= 9 ? ('0' + d) : d);
	}
	
	editTransaction(serviceId, jobId) {
		this.props.navigate('/CRS/Transaction/' + serviceId + '/' + jobId);
	}
	
	onChangeSurchargeSearch(accessor, value) {
		var surchargeSearch = this.copyObject(this.state.surchargeSearch);
		this.setByAccessor(surchargeSearch, accessor, value);
		this.mergeState({ surchargeSearch: surchargeSearch });
	}
	
	onSubmitSurchargeSearch(event) {
		var newSurchargeList = this.copyTable(surchargeList);
		newSurchargeList.query.search = this.constructSurchargeSearch(this.state.surchargeSearch);
		this.mergeState({ 
			surchargeList: newSurchargeList,
			surchargeSearchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSurchargeSearchResult(data) {
		this.mergeState({
			surchargeSearchCount: data.ResultCount
		});
	}
	
	constructSurchargeSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.TRANS_DATE_FROM)) search.Children.push({ Attribute: 'TRANS_DATE', Operator: '>=', StringValue: this.formatDateForCompare(this.getDate(data.TRANS_DATE_FROM)) });
		if (!this.isEmpty(data.TRANS_DATE_TO)) search.Children.push({ Attribute: 'TRANS_DATE', Operator: '<=', StringValue: this.formatDateForCompare(this.getDate(data.TRANS_DATE_TO)) });
		if (!this.isEmpty(data.PI_NAME)) search.Children.push({ Operator: 'or', Children: [
			{ Attribute: 'PI_NAME', Operator: 'like', StringValue: data.PI_NAME },
			{ Attribute: 'SPEEDTYPE_PI', Operator: 'like', StringValue: data.PI_NAME }
		]});
		if (!this.isEmpty(data.USER_NAME)) search.Children.push({ Attribute: 'USER_NAME', Operator: 'like', StringValue: data.USER_NAME });
		if (!this.isEmpty(data.SPEEDTYPE)) search.Children.push({ Attribute: 'SPEEDTYPE', Operator: 'like', StringValue: data.SPEEDTYPE });
		if (data.SERVICE_ID.length > 0) {
			// combine all lists into one and then split
			search.Children.push({ Attribute: 'SERVICE_ID', Operator: 'in', LongList: data.SERVICE_ID.join(',').split(',') });
		}
		
		// if nothing was specified, forget it
		if (search.Children.length === 0) return null;
		return search;
	}

	onClickTabItem(tab) {
		this.mergeState({ activeTab: tab });
	}
	
	renderClosingDates() {
		if (this.props.user.crs.userAccessID === 1) {
			return (<>
				<li>Customers Closes {this.state.prelimEnd && (this.state.prelimEnd.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' }) + ' at 2:00 pm')}</li>
				<li>RAs Closes {this.state.prelimEndRA && (this.state.prelimEndRA.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' }) + ' at 12:00 pm')}</li>
			</>);
		} else if (this.props.user.crs.raFlag) {
			return (
				<li>Prelim Closes {this.state.prelimEndRA && (this.state.prelimEndRA.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' }) + ' at 12:00 pm')}</li>
			);
		} else {
			return (
				<li>Prelim Closes {this.state.prelimEnd && (this.state.prelimEnd.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' }) + ' at 2:00 pm')}</li>
			);
		}		
	}

	render() {

		var exportPrelimData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Preliminary Journal',
			columns: prelimExportColumns,
			entity: 'RECHARGES.PRELIM_JOURNAL_DISPLAY_VW',
			search: this.constructPrelimSearch(this.state.prelimSearch),
			order: ['SERVICE_NAME', 'SPEEDTYPE_PI', 'PI_NAME', 'SPEEDTYPE', 'TRANS_DATE', 'USER_NAME']
		});			

		var exportSurchargeData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Surcharge Journal',
			columns: surchargeExportColumns,
			entity: 'RECHARGES.SURCHARGE_JOURNAL_VW',
			search: this.constructSurchargeSearch(this.state.surchargeSearch),
			order: 'SPEEDTYPE'
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				    <Tabs onClickTabItem={(tab) => this.onClickTabItem(tab)}>
				      <div label='Preliminary Journal'>
					    <div style={{ marginBottom: '10px' }}>Preliminary journal for the current billing recharge cycle of {this.state.endDate && this.state.endDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}&nbsp;
						  <OverlayTrigger trigger='hover' placement='right' overlay={
							<Popover>
							  <Popover.Body>
						        <ul>
								  <li>{this.state.endDate && (this.state.endDate.toLocaleDateString('en-US', { month: 'long' }) + ' ')}
								  Billing Month
								  ({this.state.begDate && this.state.begDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' })}
								  &nbsp;-&nbsp; 
								  {this.state.endDate && this.state.endDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' })})</li>
								  {this.renderClosingDates()}
								</ul>
								<strong>Note:</strong> Only transactions to be billed for the current period show. If the speedtype pi and the total labor (if applicable) are missing, it means that the speedtype is invalid for recharges and will be corrected during the recharge process.
						      </Popover.Body>
							</Popover>
						  }>
							<InfoCircle />
						  </OverlayTrigger>
						</div>
						<ChemEdit parent={this} columns={prelimSearch} data={this.state.prelimSearch} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChangePrelimSearch(accessor, value)} onSubmit={(event) => this.onSubmitPrelimSearch(event)} {...this.props}>
						  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
							<Col xs={4} style={{ marginTop: 8}}>
								{this.isEmpty(this.state.prelimSearchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.prelimSearchCount} records found.
							</Col>
							<Col xs={1} style={{ marginTop: 6}}>
								<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
									{exportPrelimData.map((excelFormField, index) => {
										return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
									})}
									<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
									  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
									</OverlayTrigger>
								</form>
							</Col>
							<Col xs={1} style={{ marginTop: 6}}>
								<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
									{exportPrelimData.map((pdfFormField, index) => {
										return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
									})}
									<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
									  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
									</OverlayTrigger>
								</form>
							</Col>
							<Col xs={6}>
							  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
							</Col>
						  </Form.Group>
						</ChemEdit>
					  </div>
					  <div label='Surcharge Journal'>
					    <div style={{ marginBottom: '10px' }}>Surcharge journal for the current billing recharge cycle of {this.state.endDate && this.state.endDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}&nbsp;
						  <OverlayTrigger trigger='hover' placement='right' overlay={
							<Popover>
							  <Popover.Body>
						        <ul>
								  <li>{this.state.endDate && (this.state.endDate.toLocaleDateString('en-US', { month: 'long' }) + ' ')}
								  Billing Month
								  ({this.state.begDate && this.state.begDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' })}
								  &nbsp;-&nbsp; 
								  {this.state.endDate && this.state.endDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' })})</li>
								  {this.renderClosingDates()}
								</ul>
						      </Popover.Body>
							</Popover>
						  }>
							<InfoCircle />
						  </OverlayTrigger>
						</div>
					    <ChemEdit parent={this} columns={surchargeSearch} data={this.state.surchargeSearch} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChangeSurchargeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSurchargeSearch(event)} {...this.props}>
						  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						    <Col xs={4} style={{ marginTop: 8}}>
							  {this.isEmpty(this.state.surchargeSearchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.surchargeSearchCount} records found.
						    </Col>
						    <Col xs={1} style={{ marginTop: 6}}>
							  <form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								  {exportSurchargeData.map((excelFormField, index) => {
									  return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								  })}
								  <OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
									<input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
								  </OverlayTrigger>
							  </form>
						    </Col>
						    <Col xs={1} style={{ marginTop: 6}}>
							  <form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								  {exportSurchargeData.map((pdfFormField, index) => {
									  return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								  })}
								  <OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
									<input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
								  </OverlayTrigger>
							  </form>
						    </Col>
						    <Col xs={6}>
							  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						    </Col>
						  </Form.Group>
					    </ChemEdit>
					  </div>
					</Tabs>
				  </Col>
				</Row>
				<Row>
				  <Col>
				    {this.state.activeTab === 'Preliminary Journal' && <QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_PreliminaryJournal' table={this.state.prelimList}
					  onResult={(data) => this.onPrelimSearchResult(data)} offsetHeight='370px' />}
					{this.state.activeTab === 'Surcharge Journal' && <QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_SurchargeJournal' table={this.state.surchargeList}
					  onResult={(data) => this.onSurchargeSearchResult(data)} offsetHeight='370px' />}
				  </Col>
				</Row>
			</Container>
		</>);
	}
}

const prelimSearch = [
	{ Header: 'Date From', accessor: 'TRANS_DATE_FROM', type: 'date', row: 0, col: 0 },
	{ Header: 'Date To', accessor: 'TRANS_DATE_TO', type: 'date', row: 0, col: 1 },
	{ Header: 'PI Name', accessor: 'PI_NAME', type: 'dropdown', row: 0, col: 2,
		options: { value: 'PI_NAME', label: 'PI_NAME', entity: 'RECHARGES.PRELIM_PI_SELECT_VW', order: 'PI_NAME' }},
	{ Header: 'User Name', accessor: 'USER_NAME', row: 1, col: 0, subscript: '(last name, first name) Use % for wild card' },
	{ Header: 'Speedtype', accessor: 'SPEEDTYPE', row: 1, col: 1, subscript: 'Use % for wild card' },
	{ Header: 'Service(s)', accessor: 'SERVICE_ID', type: 'list', row: 1, col: 2,
		options: { value: 'SERVICE_ID_LIST', label: 'RCH_NAME', entity: 'RECHARGES.PRELIM_SERVICE_SELECT_VW', order: 'RCH_NAME' }}
];

const prelimList = {
	query: { entity: 'RECHARGES.PRELIM_JOURNAL_DISPLAY_VW', search: null, order: ['SERVICE_NAME', 'SPEEDTYPE_PI', 'PI_NAME', 'SPEEDTYPE', 'TRANS_DATE', 'USER_NAME'] },
	pageSize: 20,
	columns: [
		{ Header: '', accessor: 'id', width: 25,
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editTransaction(props.row.values.SERVICE_ID, props.row.values.ID)} /> },
		{ Header: '', accessor: 'SERVICE_ID', show: false },
		{ Header: 'Service', accessor: 'SERVICE_NAME', width: 250 },
		{ Header: 'Job ID', accessor: 'ID', width: 80,
			Cell: props => <Link to={'/CRS/Jobs/' + props.row.values.SERVICE_ID + '/' + props.value}>{props.value}</Link> },
		{ Header: 'Date', accessor: 'TRANS_DATE', width: 100 },
		{ Header: 'Description', accessor: 'DESCRIPTION', width: 400 },
		{ Header: 'User', accessor: 'USER_NAME', width: 200 },
		{ Header: 'PI', accessor: 'PI_NAME', width: 200 },
		{ Header: 'Speedtype', accessor: 'SPEEDTYPE', width: 125 },
		{ Header: 'Outside', accessor: 'OUTSIDE_FLAG', width: 75 },
		{ Header: 'PO', accessor: 'PO_NUM', width: 60 },
		{ Header: 'Labor Hrs', accessor: 'LABOR_HOURS', width: 80 },
		{ Header: '(Disc.)', accessor: 'DISCOUNT_AMOUNT', width: 80 },
		{ Header: 'Total Labor', accessor: 'LABOR_TOTAL', width: 80 },
		{ Header: 'Material', accessor: 'MATERIAL_COST', width: 80 },
		{ Header: 'Total Material', accessor: 'MATERIAL_TOTAL', width: 80 },
		{ Header: 'Total', accessor: 'LINE_TOTAL', width: 80 }
	]
};

const prelimExportColumns = [
	{ Header: 'Service', accessor: 'SERVICE_NAME', width: 50 },
	{ Header: 'Job ID', accessor: 'ID', width: 16 },
	{ Header: 'Date', accessor: 'TRANS_DATE', width: 20, format: 'yyyy/mm/dd' },
	{ Header: 'Description', accessor: 'DESCRIPTION', width: 80 },
	{ Header: 'User', accessor: 'USER_NAME', width: 40 },
	{ Header: 'PI', accessor: 'PI_NAME', width: 40 },
	{ Header: 'Speedtype', accessor: 'SPEEDTYPE', width: 25 },
	{ Header: 'Outside', accessor: 'OUTSIDE_FLAG', width: 15 },
	{ Header: 'PO', accessor: 'PO_NUM', width: 12 },
	{ Header: 'Labor Hrs', accessor: 'LABOR_HOURS', width: 16, format: '#,##0.00' },
	{ Header: '(Disc.)', accessor: 'DISCOUNT_AMOUNT', width: 16, format: '#,##0.00' },
	{ Header: 'Total Labor', accessor: 'LABOR_TOTAL', width: 16, format: '#,##0.00' },
	{ Header: 'Material', accessor: 'MATERIAL_COST', width: 16, format: '#,##0.00' },
	{ Header: 'Total Material', accessor: 'MATERIAL_TOTAL', width: 16, format: '#,##0.00' },
	{ Header: 'Total', accessor: 'LINE_TOTAL', width: 16, format: '#,##0.00' }
];

const surchargeSearch = [
	{ Header: 'Date From', accessor: 'TRANS_DATE_FROM', type: 'date', row: 0, col: 0 },
	{ Header: 'Date To', accessor: 'TRANS_DATE_TO', type: 'date', row: 0, col: 1 },
	{ Header: 'PI Name', accessor: 'PI_NAME', type: 'dropdown', row: 0, col: 2,
		options: { value: 'PI_NAME', label: 'PI_NAME', entity: 'RECHARGES.PRELIM_PI_SELECT_VW', order: 'PI_NAME' }},
	{ Header: 'User Name', accessor: 'USER_NAME', row: 1, col: 0, subscript: '(last name, first name) Use % for wild card' },
	{ Header: 'Speedtype', accessor: 'SPEEDTYPE', row: 1, col: 1, subscript: 'Use % for wild card' },
	{ Header: 'Service(s)', accessor: 'SERVICE_ID', type: 'list', row: 1, col: 2,
		options: { value: 'SERVICE_ID_LIST', label: 'RCH_NAME', entity: 'RECHARGES.PRELIM_SERVICE_SELECT_VW', order: 'RCH_NAME' }}
];

const surchargeList = {
	query: { entity: 'RECHARGES.SURCHARGE_JOURNAL_VW', search: null, order: 'SPEEDTYPE' },
	pageSize: 20,
	columns: [
		{ Header: '', accessor: 'SERVICE_ID', show: false },
		{ Header: 'Trans Date', accessor: 'TRANS_DATE', width: 100 },
		{ Header: 'Speedtype', accessor: 'SPEEDTYPE', width: 125 },
		{ Header: 'Job No', accessor: 'JOBNO', width: 80 },
		{ Header: 'Service Name', accessor: 'SERVICE_NAME', width: 250 },
		{ Header: 'Service Title', accessor: 'SERVICE_TITLE', width: 250 },
		{ Header: 'Description', accessor: 'DESCRIPTION', width: 400 },
		{ Header: 'User Name', accessor: 'USER_NAME', width: 200 },
		{ Header: 'PI Name', accessor: 'PI_NAME', width: 200 },
		{ Header: 'Labor Hours', accessor: 'LABOR_HOURS', width: 80 },
		{ Header: 'Material Cost', accessor: 'MATERIAL_COST', width: 80 },
		{ Header: 'Material Rate', accessor: 'MATERIAL_RATE', width: 80 },
		{ Header: 'Outside Material', accessor: 'OUTSIDE_MATERIAL', width: 80 },
		{ Header: 'Outside Rate', accessor: 'OUTSIDE_RATE', width: 80 },
		{ Header: 'Labor Rate', accessor: 'LABOR_RATE', width: 80 },
		{ Header: 'Outside Labor Rate', accessor: 'OUTSIDE_LABOR_RATE', width: 80 },
		{ Header: 'Charge Total', accessor: 'CHARGE_TOTAL', width: 80 },
		{ Header: 'CoC Charge Total', accessor: 'COC_CHARGE_TOTAL', width: 80 },
		{ Header: 'Surcharge', accessor: 'SURCHARGE', width: 80 },
		{ Header: 'PO Num', accessor: 'PO_NUM', width: 80 },
		{ Header: 'Outside Flag', accessor: 'OUTSIDE_FLAG', width: 80 }
	]
};

const surchargeExportColumns = [
	{ Header: 'Trans Date', accessor: 'TRANS_DATE', width: 20, format: 'yyyy/mm/dd' },
	{ Header: 'Speedtype', accessor: 'SPEEDTYPE', width: 25 },
	{ Header: 'Job No', accessor: 'JOBNO', width: 16 },
	{ Header: 'Service Name', accessor: 'SERVICE_NAME', width: 50 },
	{ Header: 'Service Title', accessor: 'SERVICE_TITLE', width: 50 },
	{ Header: 'Description', accessor: 'DESCRIPTION', width: 80 },
	{ Header: 'User Name', accessor: 'USER_NAME', width: 40 },
	{ Header: 'PI Name', accessor: 'PI_NAME', width: 40 },
	{ Header: 'Labor Hours', accessor: 'LABOR_HOURS', width: 16 },
	{ Header: 'Material Cost', accessor: 'MATERIAL_COST', width: 16 },
	{ Header: 'Material Rate', accessor: 'MATERIAL_RATE', width: 16 },
	{ Header: 'Outside Material', accessor: 'OUTSIDE_MATERIAL', width: 16 },
	{ Header: 'Outside Rate', accessor: 'OUTSIDE_RATE', width: 16 },
	{ Header: 'Labor Rate', accessor: 'LABOR_RATE', width: 16 },
	{ Header: 'Outside Labor Rate', accessor: 'OUTSIDE_LABOR_RATE', width: 16 },
	{ Header: 'Charge Total', accessor: 'CHARGE_TOTAL', width: 16 },
	{ Header: 'CoC Charge Total', accessor: 'COC_CHARGE_TOTAL', width: 16 },
	{ Header: 'Surcharge', accessor: 'SURCHARGE', width: 16 },
	{ Header: 'PO Num', accessor: 'PO_NUM', width: 16 },
	{ Header: 'Outside Flag', accessor: 'OUTSIDE_FLAG', width: 16 }
];

export default withRouter(SearchPrelim);