import ChemComponent from './ChemComponent';
import { Link } from 'react-router-dom';

class ChemMenuBox extends ChemComponent
{
	render() {
		return (
			<div style={style.menuBox}>
				<table border="0" width="100%" cellPadding="5" cellSpacing="0">
					<tbody>
						{this.props.menuItems.map((menuItem, index) => {
							if (menuItem.href) {
								return (
									<tr key={index} style={style.boxFont}>
										<td style={style.leftMenuItem}><a href={menuItem.href} title={menuItem.title} rel={menuItem.rel} style={style.jqueryCollapse}>{menuItem.text}</a></td>
									</tr>
								);
							} else if (menuItem.route) {
								return (
									<tr key={index} style={style.boxFont}>
										<td style={style.leftMenuItem}><Link to={menuItem.route} title={menuItem.title} style={style.jqueryCollapse}>{menuItem.text}</Link></td>
									</tr>
								);
							} else {
								return (
									<tr key={index} style={style.boxFont}>
										<td style={style.leftMenuItem}><Link to="#" onClick={(event) => { event.preventDefault(); menuItem.onClick(event); }} title={menuItem.title} style={style.jqueryCollapse}>{menuItem.text}</Link></td>
									</tr>
								);							
							}
						})}
					</tbody>
				</table>
			</div>
		);
	}
}		

const style = {
	boxFont: {
		fontSize: '12px',
		color: '333'
	},
	jqueryCollapse: {
		textDecoration: 'none',
		color: '#347A9E'
	},
	leftMenuItem: {
		fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
		lineHeight: 'normal',
		borderBottom: '1px solid #d4d4d4',
		padding: '10px 20px',
		verticalAlign: 'top'		
	},
	menuBox: {
		border: '0px',
		width: '180px',
		float: 'left',
		marginLeft: '5px',
		fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
		fontSize: '12px'
	}
};

export default ChemMenuBox;
