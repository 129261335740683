import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import Tabs from '../Tabs';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class GraphicsUsers extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			USER_NAME: '',
			LNAME: '',
			FNAME: ''
		};
		
		// initialize ra list
		var initialGraphicsUserList = this.copyGraphicsUserList();
		initialGraphicsUserList.query.search = this.constructSearch(search);
		
		// remove add/edit column if not authorized
		if (props.user.crs.userAccessID !== 1) {
			initialGraphicsUserList.columns.splice(0, 1);
		}
		
		this.state = {
			renderKey: 1,
			graphicsUserList: initialGraphicsUserList,
			search: search,
			upload: {
				FILE: null
			},
			searchCount: null
		};
	}
	
	copyGraphicsUserList() {
		var graphicsUserListCopy = this.copyObject(graphicsUserList);
		for (var i = 0; i < graphicsUserList.columns.length; i++) {
			if (graphicsUserList.columns[i].Header) {
				graphicsUserListCopy.columns[i].Header = graphicsUserList.columns[i].Header;
			}
			if (graphicsUserList.columns[i].Cell) {
				graphicsUserListCopy.columns[i].Cell = graphicsUserList.columns[i].Cell;
			}
		}
		return graphicsUserListCopy;
	}
	
	onChangeUpload(accessor, value) {
		var upload = this.copyObject(this.state.upload);
		this.setByAccessor(upload, accessor, value);
		this.mergeState({ upload: upload });
	}
	
	onSubmitUpload(event) {
		var self = this;
		// if there is a file
		if (this.state.upload.FILE && this.state.upload.FILE.name) {
			// Create an object of formData
			const formData = new FormData();

			// add the token to the formData
			formData.append("__RequestVerificationToken", this.props.user.antiForgeryToken);
		
			// add the file information
			formData.append("graphicsUsers", this.state.upload.FILE, this.state.upload.FILE.name);
			
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/CRS/UploadGraphicsUsers',
				overlay: true,
				data: formData,
				contentType: false,
				processData: false
			}).done(function (data) {
				if (data.Success) {
					self.showConfirmation('Successfully uploaded ' + data.LinesUploaded + ' graphics users.');
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		} else {
			self.showAlert('No File Selected', 'Please select a file to upload');
		}
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newGraphicsUserList = this.copyGraphicsUserList();
		newGraphicsUserList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			graphicsUserList: newGraphicsUserList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: [{ Attribute: 'INACTIVE_FLAG', Operator: 'is null' }]
		};

		if (!this.isEmpty(data.USER_NAME)) search.Children.push({ Attribute: 'USER_NAME', Operator: 'like', StringValue: '%' + data.USER_NAME + '%' });
		if (!this.isEmpty(data.LNAME)) search.Children.push({ Attribute: 'LNAME', Operator: 'like', StringValue: '%' + data.LNAME + '%' });		
		if (!this.isEmpty(data.FNAME)) search.Children.push({ Attribute: 'FNAME', Operator: 'like', StringValue: '%' + data.FNAME + '%' });		

		return search;
	}
	
	addGraphicsUser() {
		this.props.navigate('/CRS/GraphicsUsers/new');
	}
	
	editGraphicsUser(userId) {
		this.props.navigate('/CRS/GraphicsUsers/' + userId);
	}
	
	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'GraphicsUsers',
			columns: exportColumns,
			entity: 'RECHARGES.GRAPHIC_USERS',
			search: this.constructSearch(this.state.search),
			order: 'USER_NAME'
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Graphics Users</span>
				    <Tabs>
					  <div label='Search'>
					    <ChemEdit parent={this} columns={graphicsUserSearch} data={this.state.search} renderKey={this.state.renderKey} 
					      onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					      <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						    <Col xs={4} style={{ marginTop: 8}}>
							    {this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						    </Col>
						    <Col xs={1} style={{ marginTop: 6}}>
							    <form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								    {exportData.map((excelFormField, index) => {
									    return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								    })}
								    <OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								      <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							        </OverlayTrigger>
							    </form>
						    </Col>
						    <Col xs={1} style={{ marginTop: 6}}>
							    <form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								    {exportData.map((pdfFormField, index) => {
									    return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								    })}
								    <OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								      <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							        </OverlayTrigger>
							    </form>
						    </Col>
						    <Col xs={6}>
						      <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						    </Col>
					      </Form.Group>
					    </ChemEdit>
				      </div>
					  <div label='Upload'>
					    <ChemEdit parent={this} columns={uploadColumns} data={this.state.upload} renderKey={this.state.renderKey} 
					      onChange={(accessor, value) => this.onChangeUpload(accessor, value)} onSubmit={(event) => this.onSubmitUpload(event)} {...this.props}>
					      <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					        <Col xs={11}>
						      No Headers: Column A (Username), B (Speedtype), C (PI Name), D (Last Name), E (First Name), F (Email)
						    </Col>
						    <Col xs={1}>
						      <Button id="update" variant="warning" type="submit" className="float-end">Upload</Button>
						    </Col>
					      </Form.Group>
					    </ChemEdit>
					  </div>
					</Tabs>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_GraphicsUsers' table={this.state.graphicsUserList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='245px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const uploadColumns = [
	{ Header: 'Upload User File <i>(ccinfo in xlsx format)</i>', accessor: 'FILE', type: 'file', required: true }
];

const graphicsUserSearch = [
	{ Header: 'User Name', accessor: 'USER_NAME', row: 0, col: 0 },
	{ Header: 'Last Name', accessor: 'LNAME', row: 0, col: 1 },
	{ Header: 'First Name', accessor: 'FNAME', row: 0, col: 2 }
];

const graphicsUserList = {
	query: { entity: 'RECHARGES.GRAPHIC_USERS', search: null, order: 'USER_NAME' },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addGraphicsUser()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editGraphicsUser(props.row.values.USER_ID)} />, width: 25 },
		{ Header: 'User ID', accessor: 'USER_ID', width: 50 },
		{ Header: 'User Name', accessor: 'USER_NAME', width: 125 },
		{ Header: 'Last Name', accessor: 'LNAME', width: 125 },
		{ Header: 'First Name', accessor: 'FNAME', width: 75 },
		{ Header: 'SpeedType', accessor: 'COST_CENTER', width: 100 },
		{ Header: 'PI', accessor: 'PI_NAME', width: 200 },
		{ Header: 'Email', accessor: 'EMAIL', width: 200 },
		{ Header: 'Recharge', accessor: 'RECHARGE', width: 75 }
	]
};

const exportColumns = [
	{ Header: 'User ID', accessor: 'USER_ID', width: 10 },
	{ Header: 'User Name', accessor: 'USER_NAME', width: 20 },
	{ Header: 'Last Name', accessor: 'LNAME', width: 20 },
	{ Header: 'First Name', accessor: 'FNAME', width: 20 },
	{ Header: 'SpeedType', accessor: 'COST_CENTER', width: 10 },
	{ Header: 'PI', accessor: 'PI_NAME', width: 30 },
	{ Header: 'Email', accessor: 'EMAIL', width: 30 },
	{ Header: 'Recharge', accessor: 'RECHARGE', width: 10 }
];

export default withRouter(GraphicsUsers);