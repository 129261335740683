import {
    useLocation,
    useNavigate,
    useParams
  } from "react-router-dom";

export const withRouter = (Component) =>  {
    const WithRouterWrapper = (props) => {
		const location = useLocation();
		const navigate = useNavigate();
		const params = useParams();
		const match = {
			path: location.pathname
		};
		return (
			<Component
				location={location}
				navigate={navigate}
				params={params}
				match={match}
				{...props}
			/>
		);
    }
    return WithRouterWrapper;
}
