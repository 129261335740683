import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import ChemTable from '../ChemTable';
import { InfoCircle } from 'react-bootstrap-icons';


class Preliminary extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var prelimSearch = {
			PI_ROLE_ID: [],
			SPEEDTYPE: '',
			SERVICE_ID: []
		};
		
		// construct search for PI list
		var initialPrelimSearchColumns = this.copyColumns(prelimSearchColumns);
		initialPrelimSearchColumns[0].options.search = 
			(props.user.crs.raRoleIDs && props.user.crs.raRoleIDs.length > 0) ?
			{
				// user is an RA - separate out their PI's at the top of the list
				Operator: 'or',
				Children: [
					// top of list: PI's that this user is responsible for
					{ Operator: 'and', Children: [
						{ Attribute: 'SORT_ORDER', Operator: '=', IntValue: 1 },
						{ Attribute: 'ROLE_ID', Operator: 'in', LongList: props.user.crs.raRoleIDs }
					]},
					// separator
					{ Attribute: 'SORT_ORDER', Operator: '=', IntValue: 2 },
					// bottom of list: PI's that this user is not responsible for
					{ Operator: 'and', Children: [
						{ Attribute: 'SORT_ORDER', Operator: '=', IntValue: 3 },
						{ Attribute: 'ROLE_ID', Operator: 'not in', LongList: props.user.crs.raRoleIDs }
					]}
				]
			} :
			{
				// user is not an RA, list all PI's in alphabetical order
				Attribute: 'SORT_ORDER', Operator: '=', IntValue: 1
			};

		this.state = {
			prelimSearchColumns: initialPrelimSearchColumns, 
			prelimSearch: prelimSearch,
			displayStatement: false,
			PIs: [],
			speedtypeChange: [],
			begDate: null,
			endDate: null,
			prelimEnd: null,
			prelimEndRA: null,
			renderKey: 1
		};
	}
	
	componentDidMount() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'CURRENT_CYCLE_VW',
				search: null,
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				if (data.Data.length > 0 && !self.isEmpty(data.Data[0].END_DATE)) {
					var cycle = data.Data[0];
																	
					self.mergeState({
						begDate: self.getDate(cycle.BEG_DATE),
						endDate: self.getDate(cycle.END_DATE),
						prelimEnd: self.getDate(cycle.PRELIM_END),
						prelimEndRA: self.getDate(cycle.NEXT_WEEKDAY),
						renderKey: self.state.renderKey + 1
					});
				} else {
					self.showAlert('Current Cycle Error', 'Current cycle end date not found.');
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	onChangePrelimSearch(accessor, value) {
		var prelimSearch = this.copyObject(this.state.prelimSearch);
		this.setByAccessor(prelimSearch, accessor, value);
		this.mergeState({ prelimSearch: prelimSearch });
	}
	
	onSubmitPrelimSearch(event, callback) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/GetPreliminaryJournalData',
			overlay: true,
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				pis: this.state.prelimSearch.PI_ROLE_ID,
				services: this.state.prelimSearch.SERVICE_ID ? this.state.prelimSearch.SERVICE_ID.join(',').split(',') : null,
				speedtype: this.state.prelimSearch.SPEEDTYPE
			}
		}).done(function (data) {
			if (data.Success) {
				
				// create array for speedtype change state
				var speedtypeChange = [];
				for (var i = 0; i < data.PIs.length; i++) {
					speedtypeChange.push({
						OLD_SPEEDTYPE: null,
						NEW_SPEEDTYPE: null,
						USER_NAME: '',
						SERVICES: []
					});
				}
				
				self.mergeState({
					PIs: data.PIs,
					speedtypeChange: speedtypeChange,
					displayStatement: true,
					renderKey: self.state.renderKey + 1
				}, () => {
					if (callback) callback();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		

		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onChangeSpeedtypeChange(accessor, value, piIdx) {
		var speedtypeChange = this.copyObject(this.state.speedtypeChange);
		this.setByAccessor(speedtypeChange[piIdx], accessor, value);
		this.mergeState({ speedtypeChange: speedtypeChange });
	}
	
	onSubmitSpeedtypeChange(event, piIdx) {
		var self=this;
		
		// flatten service list
		var speedtypeChange = this.copyObject(this.state.speedtypeChange[piIdx]);
		if (speedtypeChange.SERVICES && speedtypeChange.SERVICES.length > 0) {
			speedtypeChange.SERVICES = speedtypeChange.SERVICES.join(',');
		} else {
			speedtypeChange.SERVICES = "";
		}
		
		// save the role ID of the PI so we can find it when data is refreshed
		var piRoleID = this.state.PIs[piIdx].Data.ROLE_ID;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/ChangeSpeedtypes',
			overlay: true,
			data: {
				data: speedtypeChange
			}
		}).done(function (speedtypeChangeResult) {
			if (speedtypeChangeResult.Success) {
				// refresh data
				self.onSubmitPrelimSearch(null, () => {
					// for each PI
					for (var i = 0; i < self.state.PIs.length; i++) {
						// if this is the PI that changed speedtypes
						if (self.state.PIs[i].Data.ROLE_ID === piRoleID) {
							// copy the array of PIs
							var newPIs = self.copyObject(self.state.PIs);
							// add the change results to the PI
							newPIs[i].speedtypeChangeResult = speedtypeChangeResult.Data;
							self.mergeState({ PIs: newPIs });
							break;
						}
					}
				});
			} else {
				self.showAlert('Server Error', speedtypeChangeResult.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	

		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();		
	}
		
	renderClosingDates() {
		if (this.props.user.crs.userAccessID === 1) {
			return (<>
				<li>Customers Closes {this.state.prelimEnd && (this.state.prelimEnd.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' }) + ' at 2:00 pm')}</li>
				<li>RAs Closes {this.state.prelimEndRA && (this.state.prelimEndRA.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' }) + ' at 12:00 pm')}</li>
			</>);
		} else if (this.props.user.crs.raFlag) {
			return (
				<li>Prelim Closes {this.state.prelimEndRA && (this.state.prelimEndRA.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' }) + ' at 12:00 pm')}</li>
			);
		} else {
			return (
				<li>Prelim Closes {this.state.prelimEnd && (this.state.prelimEnd.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' }) + ' at 2:00 pm')}</li>
			);
		}		
	}
	
	renderClosingDatesCycleHeader() {
		if (this.props.user.crs.userAccessID === 1) {
			return (<>
				<div style={styles.cycleHeader}>Customers Closes {this.state.prelimEnd && (this.state.prelimEnd.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' }) + ' at 2:00 pm')}</div>
				<div style={styles.cycleHeader}>RAs Closes {this.state.prelimEndRA && (this.state.prelimEndRA.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' }) + ' at 12:00 pm')}</div>
			</>);
		} else if (this.props.user.crs.raFlag) {
			return (
				<div style={styles.cycleHeader}>Prelim Closes {this.state.prelimEndRA && (this.state.prelimEndRA.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' }) + ' at 12:00 pm')}</div>
			);
		} else {
			return (
				<div style={styles.cycleHeader}>Prelim Closes {this.state.prelimEnd && (this.state.prelimEnd.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' }) + ' at 2:00 pm')}</div>
			);
		}		
	}

	renderPreliminaryJournal() {
		return (<>
			{this.state.PIs.map((pi, piIdx) => {
				// create copy of change speedtype columns
				var piChangeSpeedtypeColumns = this.copyColumns(changeSpeedtypeColumns);

				// assign "from" and "to" speedtype options
				piChangeSpeedtypeColumns[0].options = pi.FromSpeedtypes;
				piChangeSpeedtypeColumns[1].options = pi.ToSpeedtypes;
				
				// create parameters to print a pdf just for this PI
				var exportPrelimData = this.createHiddenFormData({
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					pis: [pi.Data.ROLE_ID],
					speedtype: this.state.prelimSearch.SPEEDTYPE,
					services: this.state.prelimSearch.SERVICE_ID ? this.state.prelimSearch.SERVICE_ID.join(',').split(',') : null
				});			
				
				return (<Row key={piIdx}>
				  <Col style={{border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE', marginBottom: '20px'}}>
					<h2 style={styles.piHeader}>Preliminary journal for {pi.Data.PI_NAME} {pi.Data.TYPE_NAME}</h2>
					<div style={styles.cycleHeader}>{this.state.endDate && (this.state.endDate.toLocaleDateString('en-US', { month: 'long' }) + ' ')}
					Billing Month
					({this.state.begDate && this.state.begDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' })}
					&nbsp;-&nbsp; 
					{this.state.endDate && this.state.endDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' })})</div>
					{this.renderClosingDatesCycleHeader()}
					{this.props.user.crs.raFlag ? <>
						<div style={{ textAlign: 'center' }}><strong>Change Speedtype</strong></div>
						<ChemEdit parent={this} columns={piChangeSpeedtypeColumns} data={this.state.speedtypeChange[piIdx]} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChangeSpeedtypeChange(accessor, value, piIdx)} onSubmit={(event) => this.onSubmitSpeedtypeChange(event, piIdx)} {...this.props}>
						  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
							<Col xs={4} style={{ marginTop: 8}}>
							</Col>
							<Col xs={1} style={{ marginTop: 6}}>
								<form action={this.getConfig().host + '/CRS/PreliminaryJournalPDF'} method='POST' style={{ textAlign: 'center' }}>
									{exportPrelimData.map((pdfFormField, index) => {
										return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
									})}
									<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export {pi.Data.PI_NAME} to PDF</Popover.Header></Popover>}>
									  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
									</OverlayTrigger>
								</form>
							</Col>
							<Col xs={6}>
							  <Button id={'speedtypeChange' + piIdx} variant="warning" type="submit" className="float-end">Save</Button>
							</Col>
						  </Form.Group>
						</ChemEdit>
						{pi.speedtypeChangeResult && <>
							<ChemTable renderKey={this.state.renderKey} parent={this} name={'CRS_PJChangeSpeedtypes' + piIdx} columns={changeResultColumns} data={pi.speedtypeChangeResult} />
						</>}
					</>:
					<form action={this.getConfig().host + '/CRS/PreliminaryJournalPDF'} method='POST' style={{ textAlign: 'center' }}>
						{exportPrelimData.map((pdfFormField, index) => {
							return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
						})}
						<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export {pi.Data.PI_NAME} to PDF</Popover.Header></Popover>}>
						  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
						</OverlayTrigger>
					</form>}
					<table style={styles.alumniForm}>
						{pi.Speedtypes.map((speedtype, spIdx) => { return (<React.Fragment key={spIdx}>
							<tr style={styles.labelcellHead}>
								<td>{pi.Data.PI_NAME}</td>
								<td colspan={11}>
									{speedtype.Data.SPEEDTYPE} ({speedtype.Data.FUNDCODE} {speedtype.Data.DEPT_ID} {speedtype.Data.PROGRAM} {speedtype.Data.CHARTFIELD1} {speedtype.Data.CHARTFIELD2})
								</td>
							</tr>
							{speedtype.Services.map((service, svIdx) => { return (<React.Fragment key={svIdx}>
								<tr>
									<td colspan={10} style={styles.labelcellSubHeadLeft}>{service.Data.SERVICE_NAME}</td>
								</tr>
								<tr>
									<td style={styles.labelcellLeft}>Date</td>
									<td style={styles.labelcellLeft}>ID</td>
									<td style={styles.labelcellLeft}>Name</td>
									<td style={styles.labelcellLeft}>Description</td>
									{service.HasLaborHours && <>
										<td style={styles.labelcell}>Labor<br />Hr</td>
										<td style={styles.labelcell}>$/Hr</td>
										<td style={styles.labelcell}>Labor<br />Total</td>
										<td style={styles.labelcell}>Disc.</td>
									</>}
									{service.HasRate && <>
										<td style={styles.labelcell}>&nbsp;</td>
										<td style={styles.labelcell}>&nbsp;</td>
										<td style={styles.labelcell}>Rate</td>
										<td style={styles.labelcell}>Amount</td>
										<td style={styles.labelcell}>Disc.</td>
										<td style={styles.labelcell}>Total</td>
									</>}
									{service.HasMaterialRate && <>
										<td style={styles.labelcell}>&nbsp;</td>
										<td style={styles.labelcell}>&nbsp;</td>
										<td style={styles.labelcell}>Unit</td>
										<td style={styles.labelcell}>Qty</td>
										<td style={styles.labelcell}>Unit<br />Cost</td>
										<td style={styles.labelcell}>Total</td>
									</>}
									{service.HasLaborHours && <>
										<td style={styles.labelcell}>Material<br />Total</td>
										<td style={styles.labelcell}>Total</td>
									</>}
								</tr>
								{service.Transactions.map((transaction, trIdx) => { return (
									<tr key={trIdx}>
										<td style={styles.fieldcellpostingLeft}>{transaction.Data.TRANS_DATE}&nbsp;</td>
										<td style={styles.fieldcellpostingLeft}>{transaction.Data.JOBNO}&nbsp;</td>
										<td style={styles.fieldcellpostingLeft}>{transaction.Data.USER_NAME}&nbsp;</td>
										<td style={styles.fieldcellpostingLeft}>{transaction.Data.DESCRIPTION}&nbsp;</td>
										{service.HasLaborHours && <>
											<td style={styles.fieldcellposting}>{transaction.Data.LABOR_HOURS !== 0 && <>{this.formatCurrency(transaction.Data.LABOR_HOURS)}</>}&nbsp;</td>
											<td style={styles.fieldcellposting}>{transaction.Data.LABOR_HOURS !== 0 && <>{transaction.Data.COSTPERH}</>}&nbsp;</td>
											<td style={styles.fieldcellposting}>{transaction.Data.LABOR_HOURS !== 0 && <>{this.formatCurrency(transaction.Data.GROSS_LABOR)}</>}&nbsp;</td>
											<td style={styles.fieldcellposting}>{transaction.Data.LABOR_HOURS !== 0 && <>{this.formatCurrency(-transaction.Data.LABOR_TOTAL_DISCOUNT)}</>}&nbsp;</td>
										</>}
										{service.HasRate && <>
											<td style={styles.fieldcellposting}>&nbsp;</td>
											<td style={styles.fieldcellposting}>&nbsp;</td>
											<td style={styles.fieldcellposting}>{transaction.Data.RATE}/{transaction.Data.RCH_UNIT}&nbsp;</td>
											<td style={styles.fieldcellposting}>{transaction.Data.GROSS_TOTAL}&nbsp;</td>
											<td style={styles.fieldcellposting}>{transaction.Data.LABOR_TOTAL_DISCOUNT}&nbsp;</td>
											<td style={styles.fieldcellposting}>{transaction.Data.TOTAL}&nbsp;</td>
										</>}
										{service.HasMaterialRate && <>
											<td style={styles.fieldcellposting}>&nbsp;</td>
											<td style={styles.fieldcellposting}>&nbsp;</td>
											<td style={styles.fieldcellposting}>{transaction.Data.RCH_UNIT}&nbsp;</td>
											<td style={styles.fieldcellposting}>{transaction.Data.MATERIAL_TOTAL !== 0 && <>{this.formatCurrency(transaction.Data.MATERIAL_COST)}</>}&nbsp;</td>
											<td style={styles.fieldcellposting}>{transaction.Data.MATERIAL_TOTAL !== 0 && <>{transaction.Data.MATERIAL_RATE}</>}&nbsp;</td>
											<td style={styles.fieldcellposting}>{transaction.Data.MATERIAL_TOTAL !== 0 && <>{this.formatCurrency(transaction.Data.MATERIAL_TOTAL)}</>}&nbsp;</td>
										</>}
										{service.HasLaborHours && <>
											<td style={styles.fieldcellposting}>{this.formatCurrency(transaction.Data.MATERIAL_TOTAL)}&nbsp;</td>
											<td style={styles.fieldcellposting}>{this.formatCurrency(transaction.Data.TOTAL)}&nbsp;</td>
										</>}
									</tr>);  
								})}
								<tr style={styles.labelcell}>
									<td colspan={10}>Total for {service.Data.SERVICE_NAME}: ${this.formatCurrency(service.Data.SUB_TOTAL)}</td>
								</tr>
							</React.Fragment>); })}
							<tr style={styles.labelcell}>
								<td colspan={10} style={styles.labelcell}><strong>Total for {speedtype.Data.SPEEDTYPE}: ${this.formatCurrency(speedtype.Data.SUB_TOTAL)}</strong></td>
							</tr>
						</React.Fragment>); })}
					</table>
					<div style={styles.labelcellgrandtotal}>
						Total For {pi.Data.PI_NAME} {pi.Data.TYPE_NAME}: ${this.formatCurrency(pi.Data.GRAND_TOTAL)} &nbsp;&nbsp;&nbsp;
					</div>
				  </Col>
				</Row>);
			})}
		</>);
	}

	render() {

		var exportPrelimData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			pis: this.state.prelimSearch.PI_ROLE_ID,
			speedtype: this.state.prelimSearch.SPEEDTYPE,
			services: this.state.prelimSearch.SERVICE_ID ? this.state.prelimSearch.SERVICE_ID.join(',').split(',') : null
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<div style={{ marginBottom: '10px' }}>Preliminary journal for the current billing recharge cycle of {this.state.endDate && this.state.endDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}&nbsp;
					  <OverlayTrigger trigger='hover' placement='right' overlay={
						<Popover>
						  <Popover.Body>
							<ul>
							  <li>{this.state.endDate && (this.state.endDate.toLocaleDateString('en-US', { month: 'long' }) + ' ')}
							  Billing Month
							  ({this.state.begDate && this.state.begDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' })}
							  &nbsp;-&nbsp; 
							  {this.state.endDate && this.state.endDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' })})</li>
							  {this.renderClosingDates()}
							</ul>
						  </Popover.Body>
						</Popover>
					  }>
						<InfoCircle />
					  </OverlayTrigger>
					</div>
					<ChemEdit parent={this} columns={this.state.prelimSearchColumns} data={this.state.prelimSearch} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangePrelimSearch(accessor, value)} onSubmit={(event) => this.onSubmitPrelimSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={4} style={{ marginTop: 8}}>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/CRS/PreliminaryJournalPDF'} method='POST'>
								{exportPrelimData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export All to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				{this.state.displayStatement && <>
					{this.state.PIs.length === 0 ? <Row>
						<Col style={{border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE'}}>
							<div style={{ textAlign: 'center', color: '#800000', font: '16px Verdana, Geneva, Arial, Helvetica, sans-serif', marginTop: '20px', marginBottom: '20px' }}>
								NO PRELIMINARY DATA IS AVAILABLE AT THIS TIME.
							</div>
						</Col>
					</Row> : this.renderPreliminaryJournal()}
				</>}
			</Container>
		</>);
	}
}

const prelimSearchColumns = [
	{ Header: 'PI(s)', accessor: 'PI_ROLE_ID', type: 'list', row: 0, col: 0, required: true,
		options: { value: 'ROLE_ID', label: 'PI_NAME', entity: 'RECHARGES.PRELIM_CI_PI_SELECT_VW', order: ['SORT_ORDER','PI_NAME'] }},
	{ Header: 'Service(s)', accessor: 'SERVICE_ID', type: 'list', row: 0, col: 1,
		options: { value: 'SERVICE_ID_LIST', label: 'RCH_NAME', entity: 'RECHARGES.PRELIM_SERVICE_SELECT_VW', order: 'RCH_NAME' }},
	{ Header: 'Speedtype', accessor: 'SPEEDTYPE', row: 0, col: 2 },
];

const servicesOptions = [
	{ value: 'C1', label: 'Industrial Gas/Demurrage' },
	{ value: 'C2', label: 'Dry Ice' },
	{ value: 'C3', label: 'Gas N2 Meters' },
	{ value: 'C4', label: 'Helium -Gas (Cylinder)' },
	{ value: 'C5', label: 'Helium Recovery Credit' },
	{ value: 'C6', label: 'Helium -Liquid (Dewars)' },
	{ value: 'C7', label: 'Liquid N2 (Dispenser)' },
	{ value: 'C8', label: 'Chem Storeroom' },
	{ value: 'C9', label: 'Glass Shop' },
	{ value: 'C10', label: 'Machine Shop' },
	{ value: 'C11', label: 'RSS/Carpentry/Electrical' },
	{ value: 'C12', label: 'Molecular Graphics' },
	{ value: 'C13', label: 'MicroAnalytical' },
	{ value: 'C14', label: 'NMR' },
	{ value: 'C15', label: 'Pitzer Research Computer Support' },
	{ value: 'C16', label: 'X-ray Crystallographic Facility' }
];

const changeSpeedtypeColumns = [
	{ Header: 'From Speedtype', accessor: 'OLD_SPEEDTYPE', type: 'select', row: 0, col: 0, required: true },
	{ Header: 'To Speedtype', accessor: 'NEW_SPEEDTYPE', type: 'select', row: 0, col: 1, required: true },
	{ Header: 'User Name', accessor: 'USER_NAME', row: 0, col: 2, subscript: '(last, first) (use % for wild card)' },
	{ Header: 'Transaction ID<br /><i>Note: several services may share the same ID. Make sure to select the correct service.</i>',
		accessor: 'TRANS_ID', row: 1, col: 0, subscript: '(separate IDs with a comma)' },	
	{ Header: 'Services', accessor: 'SERVICES', type: 'list', row: 1, col: 1, options: servicesOptions }
];

const changeResultColumns = [
	{ Header: 'Service Name', accessor: 'SERVICE_ID', width: 400 },
	{ Header: 'Former Speedtype', accessor: 'FORMER_S', width: 125 },
	{ Header: 'New Speedtype', accessor: 'NEW_S', width: 125 },
	{ Header: 'User Name', accessor: 'USER_NAME', width: 125 },
	{ Header: 'Line modified', accessor: 'LINES_TOTAL', width: 125,
		Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>{props.value}</div> },
	{ Header: 'Trans ID', accessor: 'REC_ID', width: 125 },
	{ Header: '$ Amount', accessor: 'AMOUNT_TOTAL', width: 125,
		Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>{props.parent.formatCurrency(props.value)}</div> }
];

const styles = {
	alumniForm: {
		backgroundColor: '#F9FBFD',
		color: '#000000',
		width: '1200px',
		border: '1px solid #D7E5F2',
		borderCollapse: 'collapse'		
	},
	cycleHeader: {
		fontSize: '16px',
		textAlign: 'center'
	},	
	fieldcellposting: {
		font: '10px Verdana, Geneva, Arial, Helvetica, sans-serif',
		backgroundColor: '#F2F7FB',
		color: '#102132',
		border: '1px solid #D7E5F2',
		textAlign: 'right',
		marginRight: '0px',
		paddingLeft: '4px',
		paddingTop: '2px',
		paddingBottom: '2px',
		verticalAlign: 'top'
	},	
	fieldcellpostingLeft: {
		font: '10px Verdana, Geneva, Arial, Helvetica, sans-serif',
		backgroundColor: '#F2F7FB',
		color: '#102132',
		border: '1px solid #D7E5F2',
		marginRight: '0px',
		paddingLeft: '4px',
		paddingTop: '2px',
		paddingBottom: '2px',
		verticalAlign: 'top'
	},	
	labelcell: {
		font: '12px Verdana, Geneva, Arial, Helvetica, sans-serif',
		color: '#3670A7',
		backgroundColor: 'transparent',
		textAlign: 'right',
		border: '1px solid #D7E5F2',
		marginRight: '4px',
		paddingRight: '4px',
		paddingLeft: '4px',
		paddingTop: '2px',
		paddingBottom: '2px',
		verticalAlign: 'top'
	},
	labelcellgrandtotal: {
		font: '12px Verdana, Geneva, Arial, Helvetica, sans-serif',
		backgroundColor: 'transparent',
		textAlign: 'right',
		paddingBottom: '20px',
		paddingLeft: '4px',
		paddingTop: '10px',
		verticalAlign: 'top'
	},
	labelcellHead: {
		font: '14px Verdana, Geneva, Arial, Helvetica, sans-serif',
		color: '#093',
		backgroundColor: 'transparent',
		textAlign: 'center',
		border: '1px solid #D7E5F2',
		paddingLeft: '4px',
		paddingTop: '2px',
		paddingBottom: '2px',
		verticalAlign: 'top'
	},
	labelcellLeft: {
		color: '#3670A7',
		font: '12px Verdana, Geneva, Arial, Helvetica, sans-serif',
		backgroundColor: 'transparent',
		textAlign: 'left',
		border: '1px solid #D7E5F2',
		paddingLeft: '4px',
		paddingTop: '2px',
		paddingBottom: '2px',
		verticalAlign: 'top'
	},
	labelcellSubHeadLeft: {
		color: '#3670A7',
		font: '12px Verdana, Geneva, Arial, Helvetica, sans-serif',
		backgroundColor: 'transparent',
		textAlign: 'left',
		fontWeight: 'bold',
		border: '1px solid #D7E5F2',
		paddingLeft: '4px',
		paddingTop: '2px',
		paddingBottom: '2px',
		verticalAlign: 'top'
	},
	piHeader: {
		font: 'normal 26px Arial, sans-serif',
		padding: '20px 0',
		margin: '0 0 10px 0',
		color: '#367087',
		backgroundColor: 'transparent',
		textAlign: 'center'
	}
};


export default withRouter(Preliminary);