import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class CylTransactions extends ChemComponent {
	constructor(props) {
		super(props);

		var search = {
			SALES_ID: '',
			CYL_ID: '',
			SALE_DATE_FROM: null,
			SALE_DATE_TO: null,
			USER_NAME: '',
			PI_NAME: '',
			DATE_BILLED_FROM: null,
			DATE_BILLED_TO: null,
			COST_CENTER: '',
			RETURN_USER: '',
			RETURN_DATE_FROM: null,
			RETURN_DATE_TO: null
		};
		
		var transListInit = this.copyTable(transList);
		transListInit.query.search = this.constructSearch(search);

		this.state = {
			transList: transListInit,
			search: search,
			searchCount: null,
			renderKey: 0
		};
	}
	
	componentDidMount() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'CURRENT_CYCLE_VW',
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				if (data.Data.length > 0) {
					var search = self.copyObject(self.state.search);
					search.SALE_DATE_FROM = self.dateTimeToMVC(self.getDate(data.Data[0].BEG_DATE));	
					var newTransList = self.copyTable(transList);
					newTransList.query.search = self.constructSearch(search);
					newTransList.hold = false;
					self.mergeState({ 
						transList: newTransList,
						search: search,
						searchCount: null,
						renderKey: self.state.renderKey + 1
					});
				} else {
					self.showAlert('Current Cycle Error', data.Data.length + ' cycles marked as current.');
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
																			
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newTransList = this.copyTable(transList);
		newTransList.query.search = this.constructSearch(this.state.search);
		newTransList.hold = false;
		this.mergeState({ 
			transList: newTransList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.SALES_ID)) search.Children.push({ Attribute: 'SALES_ID', Operator: '=', LongValue: data.SALES_ID });
		if (!this.isEmpty(data.CYL_ID)) search.Children.push({ Attribute: 'CYL_ID', Operator: '=', LongValue: data.CYL_ID });
		if (!this.isEmpty(data.SALE_DATE_FROM)) search.Children.push({ Attribute: 'SALE_DATE', Operator: '>=', DateValue: this.dateTimeToMVC(this.getDate(data.SALE_DATE_FROM)) });
		if (!this.isEmpty(data.SALE_DATE_TO)) search.Children.push({ Attribute: 'SALE_DATE', Operator: '<=', DateValue: this.dateTimeToMVC(this.getDate(data.SALE_DATE_TO)) });
		if (!this.isEmpty(data.USER_NAME)) search.Children.push({ Attribute: 'USER_NAME_UPPERCASE', Operator: 'like', StringValue: '%' + data.USER_NAME.toUpperCase() + '%' });
		if (!this.isEmpty(data.PI_NAME)) search.Children.push({ Attribute: 'PI_NAME_UPPERCASE', Operator: 'like', LongValue: '%' + data.PI_NAME.toUpperCase() + '%' });
		if (!this.isEmpty(data.DATE_BILLED_FROM)) search.Children.push({ Attribute: 'DATE_BILLED', Operator: '>=', DateValue: this.dateTimeToMVC(this.getDate(data.DATE_BILLED_FROM)) });
		if (!this.isEmpty(data.DATE_BILLED_TO)) search.Children.push({ Attribute: 'DATE_BILLED', Operator: '<=', DateValue: this.dateTimeToMVC(this.getDate(data.DATE_BILLED_TO)) });
		if (!this.isEmpty(data.COST_CENTER)) search.Children.push({ Attribute: 'COST_CENTER', Operator: 'like', StringValue: data.COST_CENTER + '%' });
		if (!this.isEmpty(data.RETURN_USER)) search.Children.push({ Attribute: 'RETURN_USER_UPPERCASE', Operator: 'like', LongValue: '%' + data.RETURN_USER.toUpperCase() + '%' });
		if (!this.isEmpty(data.RETURN_DATE_FROM)) search.Children.push({ Attribute: 'RETURN_DATE', Operator: '>=', DateValue: this.dateTimeToMVC(this.getDate(data.RETURN_DATE_FROM)) });
		if (!this.isEmpty(data.RETURN_DATE_TO)) search.Children.push({ Attribute: 'RETURN_DATE', Operator: '<=', DateValue: this.dateTimeToMVC(this.getDate(data.RETURN_DATE_TO)) });

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	addTransaction() {
		this.props.navigate('/HeLAD/CylTransactions/new');
	}
	
	editTransaction(transId) {
		this.props.setCylTransactionEditable(true);
		this.props.navigate('/HeLAD/CylTransactions/' + transId);		
	}

	viewTransaction(transId) {
		this.props.setCylTransactionEditable(false);
		this.props.navigate('/HeLAD/CylTransactions/' + transId);		
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Cylinder Transactions',
			columns: exportColumns,
			entity: 'LIQUID_AIR.CYL_TRANSACTIONS_VW',
			search: this.constructSearch(this.state.search)
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Cyl Transactions</span>
						<ChemEdit parent={this} user={this.props.user} columns={transSearch} data={this.state.search}
						  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)}/>
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='HeLAD_CylTransactions' table={this.state.transList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='300px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const transSearch = [
	{ Header: 'Sale ID', accessor: 'SALES_ID', row: 0, col: 0 },
	{ Header: 'Cylinder ID', accessor: 'CYL_ID', row: 0, col: 1 },
	{ Header: 'Trans Date From', accessor: 'SALE_DATE_FROM', type: 'date', row: 0, col: 2 },
	{ Header: 'Trans Date To', accessor: 'SALE_DATE_TO', type: 'date', row: 0, col: 3 },
	{ Header: 'User Name', accessor: 'USER_NAME', row: 1, col: 0 },
	{ Header: 'PI Name', accessor: 'PI_NAME', row: 1, col: 1 },
	{ Header: 'Bill Date From', accessor: 'DATE_BILLED_FROM', type: 'date', row: 1, col: 2 },
	{ Header: 'Bill Date To', accessor: 'DATE_BILLED_TO', type: 'date', row: 1, col: 3 },
	{ Header: 'Speedtype', accessor: 'COST_CENTER', row: 2, col: 0 },
	{ Header: 'Returned By', accessor: 'RETURN_USER', row: 2, col: 1 },
	{ Header: 'Bill Date From', accessor: 'RETURN_DATE_FROM', type: 'date', row: 2, col: 2 },
	{ Header: 'Bill Date To', accessor: 'RETURN_DATE_TO', type: 'date', row: 2, col: 3 }
];

const transList = {
	query: { entity: 'LIQUID_AIR.CYL_TRANSACTIONS_VW', search: null },
	pageSize: 20,
	hold: true,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addTransaction()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editTransaction(props.row.values.SALES_ID)} />, width: 25 },
		{ Header: 'Sale ID', accessor: 'SALES_ID', width: 60,
			Cell: props => <HashLink onClick={() => props.parent.viewTransaction(props.value)}>{props.value}</HashLink> },
		{ Header: 'Transaction Date', accessor: 'SALE_DATE_DISPLAY', width: 125 },
		{ Header: 'User', accessor: 'USER_NAME', width: 200 },
		{ Header: 'Charge', accessor: 'LINE_TOTAL', width: 65 },
		{ Header: 'Speedtype', accessor: 'COST_CENTER', width: 125 },
		{ Header: 'PI', accessor: 'PI_NAME', width: 200 },
		{ Header: 'Cyl ID', accessor: 'CYL_ID', width: 60 },
		{ Header: 'Date Billed', accessor: 'DATE_BILLED_DISPLAY', width: 90 },
		{ Header: 'Returned', accessor: 'RETURN_DATE_DISPLAY', width: 80 },
		{ Header: 'Returned By', accessor: 'RETURN_USER', width: 200 }
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'TRANS_ID', width: 13 },
	{ Header: 'Date', accessor: 'TRANS_DATE_DISPLAY', width: 25 },
	{ Header: 'User', accessor: 'NAME', width: 40 },
	{ Header: 'Speedtype', accessor: 'COST_CENTER', width: 25 },
	{ Header: 'PI', accessor: 'PI_NAME', width: 30 },
	{ Header: 'Card ID', accessor: 'CARD_ID', width: 20 },
	{ Header: 'Station', accessor: 'STATION', width: 13 },
	{ Header: 'Liters', accessor: 'VOLUME_TO_BILL', width: 12 },
	{ Header: 'Type', accessor: 'CATEGORY', width: 11 },
	{ Header: 'Comments', accessor: 'MODIF_ACTION', width: 80 },
	{ Header: 'Billed', accessor: 'DATE_BILLED_DISPLAY', width: 16 }
];

export default withRouter(CylTransactions);
