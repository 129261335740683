import { Fragment } from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class Service extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			editable: props.editable,
			service: {
				SERVICE_ID: ''
			},
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadService();
	}

	loadService(event) {
		var self = this;
		
		if (this.props.params.serviceId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/HeLAD/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'SERVICES',
					search: { Attribute: 'SERVICE_ID', Operator: '=', LongValue: this.props.params.serviceId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var service = data.Data[0];
						self.mvc2js(service, serviceColumns);
							
						self.mergeState({
							service: service,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Service Error', data.Data.length + ' Services found for ID ' + self.props.params.serviceId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				service: {
					SERVICE_ID: '(new)',
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var service = this.copyObject(this.state.service);
		this.setByAccessor(service, accessor, value);
		this.mergeState({ service: service, renderKey: this.state.renderKey + 1 });
	}
		
	deleteService(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Service', 'Delete Service ' + this.props.params.serviceId + '?', (okClicked) => {
			if (okClicked) {
				// prepare service for deletion		
				var service = {
					SERVICE_ID: self.props.params.serviceId,
					ACTIVE_SERVICE: 'N'
				};
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'LIQUID_AIR.SERVICES', data: service }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Service {self.props.params.serviceId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
		
	onSubmit(event) {
		var self = this;
		var url;
		
		// make copy of data
		var service = this.copyObject(this.state.service);
		this.js2mvc(service, serviceColumns);
		service.ACTIVE_SERVICE = 'Y';
		
		// if this is a new service
		if (!this.props.params.serviceId) {
			// set ID to sequence name
			service.SERVICE_ID = 'seq_services.nextval';
			url = '/Home/Insert';
		} else {
			url = '/Home/Update';
		}
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + url,
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'LIQUID_AIR.SERVICES', data: service }
		}).done(function (data) {
			if (data.Success) {
				var serviceId = self.props.params.serviceId || data.ID;
				var verb = self.props.params.serviceId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Service <Link to={'/HeLAD/Services/' + serviceId}>{serviceId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Service {this.state.service.SERVICE_ID}&nbsp;&nbsp;
							{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
							  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
						  </div>
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={serviceColumns} data={this.state.service} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} editable={this.state.editable} />
					  </Col>
					</Row>
					<Row style={{ paddingTop: 5 }}>
					  <Col>
						{this.state.editable && <Button id="save" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmit(event)}>Save</Button>}
						{this.state.editable && this.props.params.serviceId && <Button id="delete" variant="warning" type="button" className="float-end"
						  style={{ marginRight: '20px' }} onClick={(event) => this.deleteService(event)}>Delete</Button>} 
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const serviceColumns = [
	{ Header: 'Name', accessor: 'SERVICE_NAME', row: 0, col: 0 },
	{ Header: 'Description', accessor: 'SERVICE_DESC', type: 'textarea', row: 0, col: 1 },
	{ Header: 'Price', accessor: 'SERVICE_PRICE', row: 0, col: 2 }
];

export default withRouter(Service);
