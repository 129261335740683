import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import PODetail from './PODetail';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class PO extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			editable: props.editable,
			po: {
				purchase_order: {
					PO_ID: ''
				},
				po_details: [],
				po_vendor: {}
			},
			emptyPODetail: this.copyObject(emptyPODetail),
			editorsOpen: [],
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadPO();
	}

	loadPO(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Stores/GetPO',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, poid: this.props.params.poId }
		}).done(function (data) {
			if (data.Success) {
				var po = data.Data;
				if (!self.props.params.poId) po.purchase_order.PO_ID = '(new)';
				self.mvc2js(po.purchase_order, poColumns);
				self.mvc2js(po.po_details, detailEditColumns);
				po.purchase_order.ENTERED = self.composeChangeInfo(po.purchase_order.CREATED_BY, po.purchase_order.DATE_CREATED);
				po.purchase_order.MODIFIED = self.composeChangeInfo(po.purchase_order.MODIF_BY, po.purchase_order.DATE_MODIF);

				po.po_vendor.CITY_STATE_ZIP = po.po_vendor.CITY + ' ' + po.po_vendor.STATE + ' ' + po.po_vendor.ZIP;
				po.po_vendor.PHONE = po.po_vendor.PHONE1 + ' ' + po.po_vendor.PHONE2;
				
				// add vendor ID and status ID to line items so stock lookup and delete/update buttons will work
				var newEmptyPODetail = self.copyObject(emptyPODetail);
				newEmptyPODetail.PO_ID = po.purchase_order.PO_ID;
				newEmptyPODetail.VENDOR_ID = po.purchase_order.VENDOR_ID;
				newEmptyPODetail.STATUS_ID = po.purchase_order.STATUS_ID;
				newEmptyPODetail.PO_NUMBER = po.purchase_order.PO_NUMBER;
				newEmptyPODetail.DATE_PLACED = po.purchase_order.DATE_PLACED;
				newEmptyPODetail.CART_ID = po.purchase_order.CART_ID;
				newEmptyPODetail.TAX_PCT = po.tax_rate.TAX_PCT;
				for (var lineIdx = 0; lineIdx < po.po_details.length; lineIdx++) {
					po.po_details[lineIdx].VENDOR_ID = po.purchase_order.VENDOR_ID;
					po.po_details[lineIdx].STATUS_ID = po.purchase_order.STATUS_ID;
					po.po_details[lineIdx].PO_NUMBER = po.purchase_order.PO_NUMBER;
					po.po_details[lineIdx].DATE_PLACED  = po.purchase_order.DATE_PLACED;
					po.po_details[lineIdx].CART_ID = po.purchase_order.CART_ID;
					po.po_details[lineIdx].UNSAVED_CHANGES = false;
					
					// if subtotal is missing, calculate it
					if (!self.isEmpty(po.po_details[lineIdx].ORD_QTY) && !self.isEmpty(po.po_details[lineIdx].UNIT_PRICE)
						&& self.isEmpty(po.po_details[lineIdx].SUB_TOTAL)) {
						po.po_details[lineIdx].SUB_TOTAL = po.po_details[lineIdx].ORD_QTY * po.po_details[lineIdx].UNIT_PRICE;
					}
				}

				self.mergeState({
					po: po,
					emptyPODetail: newEmptyPODetail,
					renderKey: self.state.renderKey + 1
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
	
	onDuplicate() {
		var newPO = {
			purchase_order: {
				PO_ID: '(new)',
				VENDOR_ID: this.state.po.purchase_order.VENDOR_ID,
				PO_NUMBER: '',
				CART_ID: '',
				REFERENCE: '',
				DATE_PLACED: new Date(),
				STATUS_ID: 1,
				LAST_ACTION: '',
				BLANKET_FLAG: false,
				ENTERED: '',
				MODIFIED: '',
				COMMENTS: ''
			},
			po_vendor: this.copyObject(this.state.po.po_vendor),
			po_details: this.copyObject(this.state.po.po_details)
		};
		this.mergeState({ po: newPO });
		this.props.navigate('/Stores/POs/new');
	}
		
	onEdit(accessor, editorIsOpen) {
		this.mergeState({
			editorsOpen: this.setEditorStatus(accessor, editorIsOpen),
			renderKey: this.state.renderKey + 1
		});
	}
	
	setEditorStatus(accessor, editorIsOpen) {
		var editorsOpen = this.copyObject(this.state.editorsOpen);
		if (editorIsOpen) {
			// add to list if it isn't already present
			if (!editorsOpen.includes(accessor)) editorsOpen.push(accessor);
		} else {			
			// remove from list if it is present
			if (editorsOpen.includes(accessor)) editorsOpen.splice(editorsOpen.indexOf(accessor), 1);
		}
		return editorsOpen;
	}		

	onChange(accessor, value) {
		var self = this;
		var i;
		
		// copy entire state object since we may be merging in an array and that is not handled well in mergeObject
		var newState = this.copyObject(this.state);
		newState.renderKey = this.state.renderKey + 1;
		this.setByAccessor(newState.po, accessor, value);
		
		// handle values that have to be propagated to detail lines
		if (accessor === 'purchase_order.STATUS_ID') {
			this.setByAccessor(newState, 'emptyPODetail.STATUS_ID', value);
			for (i = 0; i < newState.po.po_details.length; i++) newState.po.po_details[i].STATUS_ID = value;
		}
		if (accessor === 'purchase_order.VENDOR_ID') {
			this.setByAccessor(newState, 'emptyPODetail.VENDOR_ID', value);
			for (i = 0; i < newState.po.po_details.length; i++) newState.po.po_details[i].VENDOR_ID = value;

			// update vendor information
			if (!self.isEmpty(value)) {
				// look up remaining vendor information
				self.ajax({
					type: 'post',
					url: self.getConfig().host + '/Stores/Search',
					data: { 
						__RequestVerificationToken: this.props.user.antiForgeryToken,
						entity: 'PO_VENDOR',
						search: { Attribute: 'VENDOR_ID', Operator: '=', LongValue: value },
						pageNumber: -1,
						pageSize: -1
					}
				}).done(function (data) {
					if (data.Success) {
						if (data.Data.length > 0) {
							var po_vendor = data.Data[0];
							newState.po.po_vendor.CITY_STATE_ZIP = po_vendor.CITY + ' ' + po_vendor.STATE + ' ' + po_vendor.ZIP;
							newState.po.po_vendor.PHONE = po_vendor.PHONE1 + ' ' + po_vendor.PHONE2;
							self.setState(newState);
						} else {
							newState.po.po_vendor = {
								VENDORNAME: '',
								ADDRESS: '',
								CITY_STATE_ZIP: '',
								PHONE: '',
								CONTACT: ''
							};
							self.setState(newState);
						}
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
			else 	
			{
				newState.po.po_vendor = {
					VENDORNAME: '',
					ADDRESS: '',
					CITY_STATE_ZIP: '',
					PHONE: '',
					CONTACT: ''
				};
				self.setState(newState);
			}
		} else {
			this.setState(newState);
		}
	}
	
	onChangePODetail(po_detail, accessor, value) {
		if (!Array.isArray(accessor) && ['ORD_QTY','UNIT_PRICE','SUB_TOTAL','SHIPPING','HANDLING','TOTAL'].includes(accessor)) {
			// values that can be entered by user
			var ordQty = this.getNumeric(accessor === 'ORD_QTY' ? value : this.getByAccessor(po_detail, 'ORD_QTY'), 0);
			var unitPrice = this.getNumeric(accessor === 'UNIT_PRICE' ? value : this.getByAccessor(po_detail, 'UNIT_PRICE'), 0);
			var subTotal = this.getNumeric(accessor === 'SUB_TOTAL' ? value : this.getByAccessor(po_detail, 'SUB_TOTAL'), 0);
			var shipping = this.getNumeric(accessor === 'SHIPPING' ? value : this.getByAccessor(po_detail, 'SHIPPING'), 0);
			var handling = this.getNumeric(accessor === 'HANDLING' ? value : this.getByAccessor(po_detail, 'HANDLING'), 0);

			// values not entered by user
			var recQty = this.getNumeric(this.getByAccessor(po_detail, 'REC_QTY'), 0);
			var taxPct = this.getNumeric(this.state.po.tax_rate.TAX_PCT, 0);
			var markupRate = this.getNumeric(this.state.po.mark_up_rate.RATE_PCT, 1);
			
			// determine if we are back-calculating the unit price
			var calculateUnitPrice = accessor === 'SUB_TOTAL' && this.isEmpty(this.getByAccessor(po_detail, 'UNIT_PRICE')) && ordQty !== 0;

			// process entered values
			if (accessor === 'ORD_QTY') {
				this.setByAccessor(po_detail, 'ON_ORDER', Math.max(ordQty-recQty, 0).toFixed(2));
			}
			if (calculateUnitPrice) {
				unitPrice = subTotal/ordQty;
				this.setByAccessor(po_detail, 'UNIT_PRICE', unitPrice.toFixed(2));
			}
			if (accessor === 'UNIT_PRICE' || calculateUnitPrice) {
				this.setByAccessor(po_detail, 'CALC_PRICE', (unitPrice*((taxPct/100)+1)*markupRate).toFixed(2));
			}
			if (['ORD_QTY','UNIT_PRICE'].includes(accessor)) {
				this.setByAccessor(po_detail, 'SUB_TOTAL', (unitPrice*ordQty).toFixed(2));
			}
			if (['ORD_QTY','UNIT_PRICE','SUB_TOTAL'].includes(accessor)) {
				this.setByAccessor(po_detail, 'TAX', (unitPrice*((taxPct/100)+1)*ordQty).toFixed(2));
			}				
			if (['ORD_QTY','UNIT_PRICE','SUB_TOTAL','SHIPPING','HANDLING'].includes(accessor)) {
				this.setByAccessor(po_detail, 'TOTAL', (unitPrice*((taxPct/100)+1)*ordQty + shipping + handling).toFixed(2));
			}
		}
	}
		
	onSubmit(event) {
		var self = this;		
		
		if (this.state.editorsOpen.length > 0) {
			this.showAlert('Editor Open', 'Please close the line item editor before saving.');
		} else {
			// make copy of data
			var dtNow = new Date();
			var po = this.copyObject(this.state.po);
			this.js2mvc(po.purchase_order, poColumns);
			this.js2mvc(po.po_details, detailEditColumns);
			
			if (this.props.params.poId) {
				// convert dates
				po.purchase_order.DATE_CREATED = this.dateTimeToMVC(this.getDate(po.purchase_order.DATE_CREATED));
				po.purchase_order.DATE_PLACED = this.dateTimeToMVC(this.getDate(po.purchase_order.DATE_PLACED));
			} else {
				po.purchase_order.DATE_CREATED = this.dateTimeToMVC(dtNow);
				po.purchase_order.DATE_PLACED = this.dateTimeToMVC(dtNow);			
			}
			
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/Stores/UpdatePO',
				overlay: true,
				data: { __RequestVerificationToken: this.props.user.antiForgeryToken, po: po }
			}).done(function (data) {
				if (data.Success) {
					var poId = self.props.params.poId || data.ID;
					var verb = self.props.params.poId ? 'updated' : 'inserted';
					self.props.parent.showConfirmation(() => {
						return (<>
							PO <Link to={'/Stores/POs/' + poId}>{poId}</Link> {verb}.
						</>);
					});
				} else {
					self.showAlert('Server Error', data.Message);
				}
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		}
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Purchase Order {this.state.po.purchase_order.PO_ID}&nbsp;&nbsp;
							{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
							  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}&nbsp;&nbsp;
							{this.props.params.poId && <><Button id="duplicate" variant="warning" type="button" onClick={(event) => this.onDuplicate()}>Duplicate</Button>&nbsp;
								<Button id="receive" variant="warning" type="button" onClick={(event) => this.props.navigate('/Stores/Receive/' + this.props.params.poId)}>Receive</Button></>}
						  </div>
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={poColumns} data={this.state.po} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)} editable={this.state.editable} />
					  </Col>
					</Row>
					<Row>
					  <Col style={{ paddingBottom: '10px' }}>
						<h6>Line Items</h6>
						<PODetail parent={this} user={this.props.user} renderKey={this.state.renderKey} newRecord={this.state.emptyPODetail} data={this.state.po.po_details}
						  tableColumns={detailTableColumns} editColumns={detailEditColumns}
						  onChange={(data) => this.onChange('po_details', data)} tableName='Stores_PO_Detail' order='LINE_ID' editable={this.state.editable}
						  onChangeInternal={(data, accessor, value) => this.onChangePODetail(data, accessor, value)} />
					  </Col>
					</Row>
					<Row>
					  <Col>
						{this.state.editable && <Button id="save" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmit(event)}>Save</Button>}
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const poColumns = [
	{ Header: 'Vendor', accessor: 'purchase_order.VENDOR_ID', type: 'select', row: 0, col: 0, required: true,
		options: { value: 'VENDOR_ID', label: 'VENDORNAME', entity: 'STORES.PO_VENDOR', order: 'VENDORNAME' }},
	{ Header: 'Bear Buy PO #', accessor: 'purchase_order.PO_NUMBER', row: 0, col: 1 },
	{ Header: 'Vendor Addr', accessor: 'po_vendor.ADDRESS', row: 1, col: 0, editable: false }, 
	{ Header: 'Requisition/Cart ID', accessor: 'purchase_order.CART_ID', row: 1, col: 1 },
	{ Header: 'Vendor City/State', accessor: 'po_vendor.CITY_STATE_ZIP', row: 2, col: 0, editable: false },
	{ Header: 'Ref #', accessor: 'purchase_order.REFERENCE', row: 2, col: 1 },
	{ Header: 'Vendor Phone', accessor: 'po_vendor.PHONE', row: 3, col: 0, editable: false },
	{ Header: 'Date Initialized', accessor: 'purchase_order.DATE_PLACED', type: 'date', row: 3, col: 1 },
	{ Header: 'Contact', accessor: 'po_vendor.CONTACT', row: 4, col: 0, editable: false },
	{ Header: 'Order Status', accessor: 'purchase_order.STATUS_ID', type: 'select', row: 4, col: 1,
		options: { value: 'STATUS_ID', label: 'STATUS_NAME', entity: 'STORES.PO_STATUS',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Last Action', accessor: 'purchase_order.LAST_ACTION', row: 5, col: 0, editable: false },
	{ Header: 'Blanket Order', accessor: 'purchase_order.BLANKET_FLAG', type: 'checkbox', row: 5, col: 1, editable: false },
	{ Header: 'Entered', accessor: 'purchase_order.ENTERED', row: 6, col: 0, editable: false }, 
	{ Header: 'Modified', accessor: 'purchase_order.MODIFIED', row: 6, col: 1, editable: false },
	{ Header: 'PO Comments', accessor: 'purchase_order.COMMENTS', type: 'textarea', row: 7, col: 0 }
];

const emptyPODetail = {
	PO_ID: null,
	LINE_ID: null,
	PO_NUMBER: '',
	STOCK_NUMBER: '',
	CAT_NUMBER: '',
	ORD_QTY: '',
	REC_QTY: null,
	BACK_ORDER: null,
	UNIT_SIZE: '',
	UNIT_PRICE: '',
	TAX: null,
	SHIPPING: '',
	HANDLING: '',
	LINE_COMMENT: '',
	LINE_STATUS: 1,
	DATE_RECEIVED: null,
	DATE_MODIF: null,
	MODIF_BY: '',
	LAST_ACTION: '',
	LINE_TOTAL: null,
	SUB_TOTAL: '',
	CART_LINE_ID: '',
	ORD_DATE: null,
	INTER_REF: '',
	ITEM_ID: null,
	CALC_PRICE: null,
	ON_ORDER: null,
	TOTAL: '',
	DESCRIPTION: '',
	UNIT_COST: null,
	STOCK_NO: '',
	VENDOR_ID: null
}

const detailTableColumns = [
	{ Header: '', accessor: 'ITEM_ID', show: false },
	{ Header: 'Line', accessor: 'LINE_ID', width: 50 },
	{ Header: 'Stock Number', accessor: 'STOCK_NUMBER', width: 110,
		Cell: props => <Link to={'/Stores/Catalog/' + props.row.values.ITEM_ID}>{props.value}</Link> },
	{ Header: 'Ord Date', accessor: 'ORD_DATE', width: 80, Cell: props => props.parent.dateToString(props.value) },
	{ Header: 'Ord Qty', accessor: 'ORD_QTY', width: 70, align: 'right' },
	{ Header: 'Catalog Number', accessor: 'CAT_NUMBER', width: 120 },
	{ Header: 'Description', accessor: 'DESCRIPTION', width: 300 },
	{ Header: "Rec'd Qty", accessor: 'REC_QTY', width: 80, align: 'right' },
	{ Header: "Date Rec'd", accessor: 'DATE_RECEIVED', width: 80,
		Cell: props => props.parent.dateToString(props.value) },
	{ Header: 'Qty on Order', accessor: 'ON_ORDER', width: 70, align: 'right' },
	{ Header: 'Unit Size', accessor: 'UNIT_SIZE', width: 70 },
	{ Header: 'Unit Price', accessor: 'UNIT_PRICE', width: 80, align: 'right', Cell: props => props.parent.formatCurrency(props.value) },
	{ Header: 'Sub Total', accessor: 'SUB_TOTAL', width: 80, align: 'right', Cell: props => props.parent.formatCurrency(props.value) },
	{ Header: 'Sub Total +Tax', accessor: 'TAX', width: 80, align: 'right', Cell: props => props.parent.formatCurrency(props.value) },
	{ Header: 'Current Stores Pricing', accessor: 'UNIT_COST', width: 120, align: 'right', Cell: props => props.parent.formatCurrency(props.value) },
	{ Header: 'Vendor Price +Tax +Markup', accessor: 'CALC_PRICE', width: 120, align: 'right', Cell: props => props.parent.formatCurrency(props.value) },
	{ Header: 'Shipping', accessor: 'SHIPPING', width: 90, align: 'right', Cell: props => props.parent.formatCurrency(props.value) },
	{ Header: 'Handling', accessor: 'HANDLING', width: 90, align: 'right', Cell: props => props.parent.formatCurrency(props.value) },
	{ Header: 'Total', accessor: 'TOTAL', width: 100, align: 'right', Cell: props => props.parent.formatCurrency(props.value) },
	{ Header: 'Comments', accessor: 'LINE_COMMENT', width: 300 },
	{ Header: 'Reference', accessor: 'INTER_REF', width: 200 }
];

const detailEditColumns = [
	{ Header: 'Line', accessor: 'LINE_ID', row: 0, col: 0, editable: false },
	{ Header: 'Stock Number', accessor: 'ITEM_ID', labelAccessor: ['STOCK_NUMBER','CAT_NUMBER','DESCRIPTION'], type: 'multicolumn', row: 0, col: 1,
		options: { value: 'ITEM_ID', label: ['STOCK_NO','CATALOG_NO','DESCRIPTION'], entity: 'STORES.STOCK_CAT_DESC_VW', order: 'STOCK_NO',
			search: { Operator: 'or', Children: [
				{ Attribute: 'VENDOR_ID', Operator: '=', LongAccessor: 'VENDOR_ID' },
				{ Attribute: 'VENDOR_ID', Operator: 'is null' }
	]}}},
	{ Header: 'Catalog Number', accessor: 'CAT_NUMBER', row: 0, col: 2 },
	{ Header: 'Description', accessor: 'DESCRIPTION', row: 0, col: 3, editable: false },
	{ Header: 'Ord Date', accessor: 'ORD_DATE', type: 'date', row: 1, col: 0 },
	{ Header: 'Ord Qty', accessor: 'ORD_QTY', row: 1, col: 1 },
	{ Header: "Rec'd Qty", accessor: 'REC_QTY', row: 1, col: 2, editable: false },
	{ Header: "Date Rec'd", accessor: 'DATE_RECEIVED', type: 'date', row: 1, col: 3, editable: false },
	{ Header: 'Qty on Order', accessor: 'ON_ORDER', row: 2, col: 0, editable: false },
	{ Header: 'Unit Size', accessor: 'UNIT_SIZE', row: 2, col: 1 },
	{ Header: 'Unit Price', accessor: 'UNIT_PRICE', row: 2, col: 2 },
	{ Header: 'Sub Total', accessor: 'SUB_TOTAL', row: 2, col: 3 },
	{ Header: 'Sub Total +Tax', accessor: 'TAX', row: 3, col: 0, editable: false },
	{ Header: 'Current Stores Pricing', accessor: 'UNIT_COST', row: 3, col: 1 },
	{ Header: 'Vendor Price +Tax +Markup', accessor: 'CALC_PRICE', row: 3, col: 2, editable: false },
	{ Header: 'Shipping', accessor: 'SHIPPING', row: 3, col: 3 },
	{ Header: 'Handling', accessor: 'HANDLING', row: 4, col: 0 },
	{ Header: 'Total', accessor: 'TOTAL', row: 4, col: 1, editable: false },
	{ Header: 'Comments', accessor: 'LINE_COMMENT', type: 'textarea', row: 4, col: 2 },
	{ Header: 'Reference', accessor: 'INTER_REF', type: 'textarea', row: 4, col: 3 }
];

export default withRouter(PO);
