import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { Link } from 'react-router-dom';


class StoresAccess extends ChemComponent {
  	constructor(props) {
		super(props);
		
		this.state = {
			renderKey: 1,
			storesAccess: this.mergeObject(storesAccess, { query: { search: null } }),
			search: {
				LNAME: '',
				FNAME: '',
			}
		};
	}

	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event)
	{
		// construct search terms
		var search = null;

		if (!this.isEmpty(this.state.search.REQ_NAME)) search = { Attribute: 'REQ_NAME', Operator: 'like', StringValue: '%' + this.state.search.REQ_NAME + '%' };

		// update state
		this.mergeState({
			storesAccess: {
				renderKey: this.state.storesAccess.renderKey + 1,
				query: {
					search: search
				}
			}
		}, () => {
			if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
		});
	}
	
	updateStoreroomAccess(props, approve, door) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/BuildingAccess/UpdateStoreroomAccess',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				approval: props.row.values,
				approve: approve,
				door: door
			}
		}).done(function (data) {
			if (data.Success) {
				// refresh approval list
				self.mergeState({
					storesAccess: {
						renderKey: self.state.storesAccess.renderKey + 1
					}
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	render() {

		return (<>
			<Container fluid>
				<Row>
					<Col>
					  <Container>
					    <Row>
						  <Col xs={10} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
							<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Stores Check Out Authorizations</span>
					        <ChemEdit parent={this} columns={storesAccessSearchColumns} data={this.state.search} renderKey={this.state.key} 
						      onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
							  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						        <Col xs={12}>
					              <Button id="update" variant="warning" type="submit" className="float-end">Update</Button>
								</Col>
							  </Form.Group>
					        </ChemEdit>
					      </Col>
						</Row>
					  </Container>
					</Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.storesAccess.renderKey} parent={this} name='BuildingAccess_StoresAccess' table={this.state.storesAccess} offsetHeight='230px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const storesAccessSearchColumns = [
	{ Header: 'Name', accessor: 'REQ_NAME' }
];

const storesAccess = {
	renderKey: 0,
	pageSize: 10,
	query: { entity: 'STORES_APPROVAL_LIST_VW', order: 'REQ_NAME' },
	columns: [
		{ Header: 'PERSON_ID', accessor: 'PERSON_ID', show: false },
		{ Header: 'Name', accessor: 'REQ_NAME',
			Cell: props => { 
				if (props.row.values.PERSON_ID > 0) {
					var route = '/BuildingAccess/People/' + props.row.values.PERSON_ID;
					return (<Link to={route}>{props.value}</Link>);
				} else {
					return props.value;
				}
			}
		},
		{ Header: 'Status', accessor: 'ACCESS_STATUS' },
		{ Header: 'Last Updated', accessor: 'LAST_UPDATED' },
		{ Header: 'Last Updated By', accessor: 'MODIF_BY' }
	]
};

export default StoresAccess;
