import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import HashLink from '../HashLink';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ChemComponent from '../ChemComponent';
import { Link } from 'react-router-dom';
import { PencilSquare, PlusSquare, Trash, HandThumbsUp, HandThumbsDown } from 'react-bootstrap-icons';

class Help extends ChemComponent {

	render() {
		var helpPath;
		if (this.props.location && this.props.location.state && this.props.location.state.helpPath) {
			helpPath = this.props.location.state.helpPath;
		} else {
			helpPath = '';
		}
		
		if (/\/BuildingAccess\/People\/\d+/.test(helpPath)) {
			return this.renderPerson();
		} else {
			return this.renderGeneral();
		}
	}
	
	renderPerson() {
		return(
			<Container fluid>
				<Row>
					<Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Help</span><br />
<br />
<h5>Person</h5>
The person screen is divided into five tabs:
<ul>
<li><HashLink hash='person_general'>General Info</HashLink></li>
<li><HashLink hash='person_appointments'>Appointments</HashLink></li>
<li><HashLink hash='person_addresses'>Home Address Info</HashLink></li>
<li><HashLink hash='person_access'>Card/Access Info</HashLink></li>
<li><HashLink hash='person_keys'>Keys Info</HashLink></li>
</ul>

<h6 id='person_general'>General Info</h6>
Contains general information. Note that for "Directory Photo" and "Safety Certificate" files, the files are not actually uploaded until the "Save" button is pressed.<br />
<br />
<h6 id='person_appointments'>Appointments</h6>
Displays a list of appointments.<br />
<PlusSquare /> - Add a new appointment<br />
<PencilSquare /> - Edit an appointment<br />
<br />
<h6 id='person_addresses'>Home Address Info</h6>
Contains local and forwarding addresses.<br />
<br />
<h6 id='person_access'>Card/Access Info</h6>
Contains card, ethanol, and electronic access information.<br />
<span style={{ fontWeight: 'bold' }}>Card</span><br />
Each person can have at most one card. Once card information is saved with the "Save" button, then a "Delete" button will appear next to the "Save" button. At this point, the card can either be updated or deleted.<br />
<span style={{ fontWeight: 'bold' }}>Storeroom Ethanol Authorization</span><br />
<PlusSquare /> - Add storeroom ethanol authorization with the specified PI<br />
<Trash /> - Remove storeroom ethanol authorization with the specified PI<br />
<span style={{ fontWeight: 'bold' }}>Authorized Electronic Access</span><br />
<HandThumbsDown /> - Request removal of electronic access<br />
<Trash /> - Remove electronic access<br />
<span style={{ fontWeight: 'bold' }}>Pending Electronic Access Request and Special Keys</span><br />
To request access, select the desired access next to "Request Door Access" and click "Request".<br />
<Trash /> - Remove electronic access request<br />
<HandThumbsUp /> - Approve electronic access request<br />
<br />
<h6 id='person_keys'>Keys Info</h6>
Keys can be returned all at once by entering a date next to "Return all keys as of", and pressing "Return Keys".<br />
<span style={{ fontWeight: 'bold' }}>Keys Checked Out</span><br />
<PencilSquare /> - Edit key - use this to return a single key.<br />
<span style={{ fontWeight: 'bold' }}>Approved Room Keys to be picked up</span><br />
To request a key, select a key in the dropdown next to "Request Key", and click "Save Request".<br />
<Trash /> - Delete key request<br />
<PencilSquare /> - Edit key request - use this to check out a key.<br />
<br />
					</Col>
				</Row>
			</Container>
		);
	}
		
	renderGeneral() {
		return(
			<Container fluid>
				<Row>
					<Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Help</span><br />
<br />

For questions on how general users can maintain their information and gain keys and access, download:
<Form action={this.getConfig().host + '/BuildingAccess/GetFAQ'} method='POST'>
	<input type='hidden' name='__RequestVerificationToken' value={this.getUser().antiForgeryToken} />
	<Button type='submit' variant='link'>Frequently Asked Questions</Button>
</Form>

The remainder of this document is intended for administrators.<br />
<br />

<h5>Some General Notes</h5>

<font size="3">Report Bugs and Problems to the <strong>Technical Contact</strong> (link at the bottom of every page).
The purpose of this application is to manage the people, buildings and keys for all the individuals associated with the College of Chemistry, Department of Chemistry and Department of Chemical Engineering.<br />
The information, even though it is sometimes labelled as "Deleted", is never actually deleted.  If you want to reverse the deleted status of something, use the Ad Hoc reporting tool and select inactive or deleted information. Once retrieved, you should be able to modify the status.  For lists (such as buildings, departments...), ask the technical contact of the application to change the status of a particular element.
However, the information can be overwritten by new information.  A history table keeps a history of changes made on an individual's personal information.  Contact the technical contact if you need to review that information.
<br /><br />
<h5>Technical Notes</h5>
When navigating on a page, use the <strong>Tab Key</strong> to move from field to field.  Pressing the <strong>Enter/Carriage Return  key</strong> will submit the page.
The <strong>Reset</strong> button will reset the information in the fields the way it was since the last time the submit button was clicked.
If the submit/Change/Update button is not clicked, the changes will not be recorded.<br />
Sometimes, a page won't display properly, use the <strong>Refresh Button</strong> on your browser menu or <strong>F5</strong> key on your keyboard to refresh the page.</font><br />
<strong>Drop down menu navigation:</strong> use the scroll bar to move from one set of records to the next and the down or upper arrow on the scroll bar to move one record at the time.  You can also use the up and down arrow on your keyboard to move one record at the time.  
Type the first letter of the word you are looking for and the cursor will be moved to the first word starting by that letter.  If you are using the Firefox browser, you can type more than one letter to reach your selection.
Do not enter dates that do not exist such as 00/00/00, the system won't accept them.<br />
<br />

<h5>Menu Page Description</h5>

Click on the links to get detailed descriptions of different pages in the application.
<br /><br />
  
<h5>People Information: links to all the pages to manage individuals' information</h5>

<h6>1. Add a new Person &nbsp;&nbsp;&nbsp;  <HashLink hash='new_person'>Details</HashLink></h6>
New people to the College should request an appointment through the Building Access Application. For exception, this page allows to add a new person.  It contains the minimum information necessary to add a person to the database.  Once the person is added you will be taken to the detail page for that person and you will be able to enter additional information such as home address, keys...

<h6>2. View/Update a person &nbsp;&nbsp;&nbsp; <HashLink hash='view_person'>Details</HashLink></h6>
Menu that lets you choose a person from a drop down menu and view that person's record upon submission or do a quick search by selecting a few criteria.  It also has links to add a person and advanced search.

<h6>3. Advanced Search &nbsp;&nbsp;&nbsp;  <Link to='/BuildingAccess/People'>Page Link</Link></h6>
Lets you search for individuals by selecting most of the available search criteria. 

<h6>4. Ad Hoc Report &nbsp;&nbsp;&nbsp;  <HashLink hash='ad_hoc_person'>Details</HashLink></h6>
Allows you to create your own reports and export them to Excel.  It can be used to retrieve "deleted" people.
<br /><br />
  
<h5>Rooms Information: links to all the pages to manage rooms' information</h5>

<h6>1. Add a new Room &nbsp;&nbsp;&nbsp; <Link to='/BuildingAccess/Rooms/new'>Page Link</Link></h6>
Takes you directly to a page where the Building Manager can add a room.

<h6>2. View/Update a Room &nbsp;&nbsp;&nbsp; <Link to='/BuildingAccess/Rooms'>Page Link</Link></h6>
Menu that lets you choose a room from a drop down menu and view that room's info upon submission or do a basic search.  It also has links to add a room and Ad Hoc Report.
<br /><br />
  
<h5>Keys Inventory: links to all the pages to manage key' information</h5>
<h6>1. Add a new key &nbsp;&nbsp;&nbsp; <Link to='/BuildingAccess/Keys/new'>Page Link</Link></h6>
This is where a key is added to the inventory so it can later be checked out.

<h6>2. View/Update a key &nbsp;&nbsp;&nbsp; <Link to='/BuildingAccess/Keys'>Page Link</Link></h6>
Menu that lets you choose a particular key from a drop down menu and view that key's info upon submission or do a basic search.  It also has a link to add a key.
<br /><br />
  
<h5>Report: to generate special reports</h5>

<h6>1. Dean's List &nbsp;&nbsp;&nbsp; <Link to='/BuildingAccess/Reports/DeansList'>Page Link</Link></h6>
This is the report requested by the Dean's office to review all the faculty members, their department affiliation, their personnel, the status and location of their personnel, the rooms, the function, and the area square footage of the rooms they occupy.  It can be directly exported to excel or view in a grid format first.

<h6>2. Emergency Room List &nbsp;&nbsp;&nbsp; <Link to='/BuildingAccess/Reports/EmergencyRoomList'>Page Link</Link></h6>
This report shows the essential data needed for emergency (Name, Phone, Rooms, Utility List), showing who is the contact person for a particular room and when this person is to be called in case of an emergency.

<h6>3. Personnel Reports &nbsp;&nbsp;&nbsp; <Link to='/BuildingAccess/Reports/LocationList'>Page Link</Link></h6>
This link takes you to the report menu page where you can choose one of four personnel reports.  Each report for Graduate Students, Staff, Faculty and Postdocs (including visiting Researchers and visiting Scholars) will show individuals' name, department, PI, Status, Location, and Phone.  You can either view the report in a grid format, in the browser or export it directly to MS Excel. An Add/Delete report has been added.
<br /><br />
  
<h5>Administration</h5>
<p>In that rubric, most of the lists used for drop down menus are available for review and modification. </p>
<p>The unit and work title list are essential to the information on the College Directory and for the email generator.</p>
<br /><br />

<h5>Web Application Access &nbsp;&nbsp;&nbsp; <Link to='/BuildingAccess/Authorizations'>Page Link</Link></h5>
<p>For some applications, access is contingent upon the unit a person belongs to, such as FSA, Stores, Financial Services... and therefore is automatically given when a person's profile has the correct information.<br />
For other applications, the technical contact adds the authorization for a person using the <strong>Add Authorization</strong> link from the Home page. Only people who are on the College system can be added.<br />
The list of Web Applications is displayed under View/Update Application on the Home page. Clicking on the application ID will open a detail page. Information from that detail page is used for the Single Sign On application.</p>
<hr />
<br />

<h5>Page Details</h5>

<table id="new_person">
<tr>
<td width="200"><h6>Add a new Person</h6></td>
<td width="300"><Link to='/BuildingAccess/People/new'><h6>Page Link</h6></Link></td>
</tr>
</table>

<table border="1" cellpadding="3">
<tr bgcolor="#c0c0c0"><td><h6>Field Name</h6></td>
<td><h6>Field Description</h6></td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Start Date</td>
<td>By default, today's date.  It can be changed.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Department</td>
<td>A department has to be selected.  By default, it is set to Chemistry.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Card ID</td>
<td>Enter the Campus card ID or the College Card ID if the person cannot get a Campus ID.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Default Speedtype</td>
<td>Enter the default speedtype the person will use at the points of sale.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Last Name, First Name, Middle Name</td>
<td>self explanatory</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Preferred Name</td>
<td>For people who go by a different first name</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Work Phone</td>
<td>Required phone number.  More numbers can be added after submission</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Email</td>
<td>Self explanatory. Field is validated against email address structure (name@location.domain). </td>
</tr>
<tr>
  <td bgcolor="#B0B6D0">Location</td>
  <td>Room/building of person's location. If the person has more than one location, you can enter them after submission.  If a choice is not available, enter the last item in the menu: Outside Location</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Payroll Title</td>
<td>Select a title.  By default, NA is selected</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Casual Appt. or Appt. Status</td>
<td>Check the box if the person has a casual Appointment.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Appt. Type</td>
<td>Select the type of appointment a person has.  If the person has more than one appointment, you will be able to enter them upon submission of this page.</td>
</tr>
<tr>
  <td bgcolor="#B0B6D0">Work Title </td>
  <td>Title that will be displayed in the College Directory (mainly for staff positions). Title list is managed under Administration on the Index page.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">PI</td>
<td>The person who is responsible for the cost center.  It might be the direct supervisor or the supervisor's PI.</td>
</tr>
</table>
Press the Add Person button to submit your entry.  If a person by the same name is already in the database, you will be sent back to the Add a Person page with a message saying so.  The data you entered won't be lost but it won't be submitted either.  A link to the person already entered in the system will be available.  When you click on it, a new page will open so you can check whether the two entries are for the same person or not.  You can close the new window when you are done.  If the two people are different, enter a middle name/initial for the new person, then press the add a person button again.  A new record should be created.  If it is the same person, simply go back to the people menu and go on with your next task.
<hr />

<table id='view_person'>
<tr>
<td width="300"><h5>Person Add/Find/Update/View</h5></td>
<td width="300"><Link to='/BuildingAccess/People'><h6>Page Link</h6></Link></td>
</tr>
</table>

<table border="1" cellpadding="3">
<tr bgcolor="#c0c0c0"><td><h6>Field Name</h6></td>
<td><h6>Field Description</h6></td>
</tr>
<tr>
	<td bgcolor="#B0B6D0">Add a person</td>
	<td>Click on it to get to the add a person page.</td>
</tr>
<tr>
	<td bgcolor="#B0B6D0">Retrieve an Individual</td>
	<td>If you know the name of the individual you want to retrieve, click on "Select a name" and the drop down menu will open. Scroll down using the menu bar on the side of the menu or type the first letter(s)of the name.  Once you found it, select it and click on retrieve.  The record for that person will be retrieved and you can navigate it by using the button menu at the top.  If the name is not in the drop down menu, it is either because the record has been marked as inactive (do a quick search on inactive records) or because the person is not in the database</td>
</tr>
<tr>
	<td bgcolor="#B0B6D0">Advanced Search Go</td>
	<td>This is a link to a search page that has more fields to search by.</td>
</tr>
<tr>
	<td bgcolor="#B0B6D0">Quick Search</td>
	<td>Select the fields you want to search by. When you press submit, it takes you to a search result page that displays all the records retrieved for that search.  For example, if you type M in the last name field, all the people whose last name starts with an M will be retrieved.  Then you can click on the name you want to retrieve an individual record.  By default, it searches active records but you can change the Active flag to N to search inactive records.</td>
</tr>
</table>
<hr />

<table>
<tr>
<td width="200"><h5>General Info</h5></td>
<td>This page is reached after selecting a person.</td>
</tr>
</table>

<table border="1" cellpadding="3">
<tr bgcolor="#c0c0c0"><td><h6>Field Name</h6></td>
<td><h6>Field Description</h6></td>
</tr>
<tr>
	<td bgcolor="#B0B6D0">ID</td>
	<td>ID in the database. That number is unique and differentiate records.</td>
</tr>
<tr>
	<td bgcolor="#B0B6D0">Cal UID</td>
	<td>Unique identifier in the campus database. This number is the one that is returned when somebody CalNet authenticates.  It is used to authorize access to applications.</td>
</tr>
<tr>
	<td bgcolor="#B0B6D0">Title</td>
	<td>This is the salutation title such as Mr, Dr, Ms...</td>
</tr>
<tr>
	<td bgcolor="#B0B6D0">Start Date</td>
	<td>Date a person started his/her association with the College of Chemistry.</td>
</tr>
<tr>
	<td bgcolor="#B0B6D0">Planned Departure</td>
	<td>Date a person is leaving or has left.</td>
</tr>
<tr>
	<td bgcolor="#B0B6D0">Department</td>
	<td>The department a person belongs to.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Last Name, First Name, Middle Name</td>
<td>self explanatory</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Preferred Name</td>
<td>For people who go by a different first name</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Safety training</td>
<td>If the person is required to take the safety training, check the box and/or enter the date the safety lecture was taken. A pdf of the certificate can be uploaded.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Floor Safety Monitor</td>
<td>Check if the person is a safety monitor.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0"><p>Emergency Contact Name, Emergency Contact Phone<br />
</p></td>
<td>Self explanatory.</td>
</tr>
<tr>
  <td bgcolor="#B0B6D0">Lab Emergency Phones (For PI)</td>
  <td>Phone number a PI wants to be reached at in case of emergency in a lab.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Comments</td>
<td>General comments about the individual.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Active</td>
<td>Shows the status of the person. When set to NO, the person is "deleted".  Please enter a comment before changing the status if the planned departure fields is not filled (i.e. double entry...)</td>
</tr>
</table>

<hr />

<table>
<tr>
<td width="200"><h5>Appt Info</h5></td>
<td>This page is reached only when navigating through the record of an existing person (after submitting a new person or selecting a person).  It cannot be bookmarked.</td>
</tr>
</table>

<table border="1" cellpadding="3">
<tr>
<td colspan="2"><h6>
  <div align="center">APPOINTMENTS</div></h6></td>
</tr>
<tr bgcolor="#c0c0c0"><td><h6>Field Name</h6></td>
<td><h6>Field Description</h6></td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Start Date</td>
<td>Date that appointment started.</td>
</tr>
<tr>
  <td bgcolor="#B0B6D0">End Date</td>
  <td>Entering an end date terminates the appointment in the CoC systems, even if the date is in the future. Enter only to end an appointment.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Payroll Title</td>
<td>If NA is showing, it means that none are available.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">PI</td>
<td>The person who is financially responsible.  It might be the direct supervisor or the supervisor's PI. Name under which speedtypes are set.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Supervisor</td>
<td>Person's direct supervisor.  Could be PI or other person.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Default SpeedType</td>
<td>Pre-populated with the selected PI's speedtypes.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Appt. Type</td>
<td>Shows the type of appointment a person has. Selection can give automatic access to applications.</td>
</tr>
<tr>
  <td bgcolor="#B0B6D0">Work Tile </td>
  <td>Title that will be displayed in the College Directory (mainly for staff positions). Title list is managed under Administration on the Index page. </td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Unit Assigned to</td>
<td>Unit to which a person is assigned to, such as Shops... Unit list is managed under Administration on the Index page. Selection of the unit can give automatic access to applications.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Comments</td>
<td>Self Explanatory.</td>
</tr>
</table>
<hr />

<table>
<tr>
<td width="200"><h5>Card/Access</h5></td>
<td>This page is reached only when navigating through the record of an existing person (after submitting a new person or selecting a person).  It cannot be bookmarked.</td>
</tr>
</table>

<table border="1" cellpadding="3">
<tr>
<td colspan="2"><h6>
  <div align="center">CARD/ACCESS</div></h6></td>
</tr>
<tr bgcolor="#c0c0c0"><td><h6>Field Name</h6></td>
<td><h6>Field Description</h6></td>
</tr>
<tr>
  <td bgcolor="#B0B6D0">Cal 1 Card ID</td>
<td>The number as on the Cal1 card. The card ID is unique in the system and cannot be entered for more than one record.</td>
</tr>
<tr>
  <td bgcolor="#B0B6D0">Cal 1 Card Proximity Number</td>
  <td>The series of alpha numericals on the bottom right on the back of the Cal1 Card. It changes for each card issued.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Current Access/<br />
  Pending Access Requests</td>
<td><p>The check boxes represent electronic access granted to the person. It drives the checkout system in the storeroom and the cylinder cages. Door access is controlled through the UCPD system and there is no check through the College Admin system to see if the access has been granted.</p>
  <p>When a person has an access request approved, the  tab will show (pending) and access will be pre-selected in Pending Access Requests.</p></td>
</tr>
<tr>
  <td bgcolor="#B0B6D0">Comments</td>
  <td>Self Explanatory.</td>
</tr>
</table>
<hr />

<table>
<tr>
<td width="200"><h5>Key Info</h5></td>
<td>This page is reached only when navigating through the record of an existing person (after submitting a new person or selecting a person).  It cannot be bookmarked.</td>
</tr>
</table>


<table border="1" cellpadding="3">
<tr>
<td colspan="2"><h6><div align="center">ADD A KEY</div></h6></td>
</tr>
<tr bgcolor="#c0c0c0"><td><h6>Field Name</h6></td>
<td><h6>Field Description</h6></td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Select a Key from</td>
<td><p>If the PI key drop down menu appears, the key you want to add should be in the list.  If the menu is not available or the key isn't in the list, choose the All Keys menu. All available keys should be in that list.  In case the key isn't in any list, then add it to the system using the key menu.</p>
  <p>When a person has a key request approved, the key tab will show (pending) and keys will be pre-selected.</p></td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Check Out Date</td>
<td>Enter the date the person has received the key.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Authorized by</td>
<td>Enter the name of the person who has authorized the key check out.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Deposit Paid</td>
<td>For people who need to pay a deposit to get their keys, enter the amount paid.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Comments</td>
<td>Self explanatory</td>
</tr>
</table>

<table border="1" cellpadding="3">
<tr>
<td colspan="2"><h6><div align="center">VIEW/EDIT A KEY</div></h6></td>
</tr>
<tr bgcolor="#c0c0c0"><td><h6>Field Name</h6></td>
<td><h6>Field Description</h6></td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Return all keys as of</td>
<td>Instead of returning keys one by one when a person leaves or changes jobs, you can return all of them at the same time by entering the date they were returned.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Key Number</td>
<td>Show the Checked Out key</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Key Name</td>
<td>If the key has a name, displays it.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">View Rooms</td>
<td>Link to view the rooms that can be opened with the key.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Change Key</td>
<td>Removed from page Feb. 2009.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Check Out Date</td>
<td>Enter the date the person has received the key.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Authorized by</td>
<td>Enter the name of the person who has authorized the key check out.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Return Date</td>
<td>Enter the date the person has returned the key. This is also the way to "delete" a key if you entered one that you don't want by accident. In that case, add a comment in the Comments box such as "Wrong key".</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Deposit Paid /Returned</td>
<td>For people who need to pay a deposit to get their keys, enter the amount paid.  In the returned box, enter the date you returned the deposit.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Letter Sent</td>
<td>For people who have left and not returned their keys, you can enter the date you sent them a letter.</td>
</tr>
<tr>
  <td bgcolor="#B0B6D0">Date Lost </td>
  <td>Enter the date a key has been lost. </td>
</tr>
<tr>
  <td bgcolor="#B0B6D0">Lost box </td>
  <td>Check the box if the key has been lost so that the key status can be updated to lost. If you need to change the status of the key to something else (i.e. key has been found), go to the Key Menu, select the key and change its status. </td>
</tr>
<tr>
  <td bgcolor="#B0B6D0">Police Report </td>
  <td>Enter the police report number. </td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Comments</td>
<td>Self explanatory</td>
</tr>
</table>
<hr />

<table id='ad_hoc_person'>
<tr>
<td width="300"><h5>Submitted Request</h5></td>
<td width="200"><Link to='/BuildingAccess/Reports/SubmittedNewRequests'><h5>Page Link</h5></Link></td>
</tr>
</table>
Building access requests that have been approved show on this page, with a link to the person's record.
As long as there's a pending access request (such as stores) or key request, the record shows on the page.
<hr />

<table>
<tr>
<td width="300"><h5>People Ad Hoc Reports</h5></td>
<td><Link to='/BuildingAccess/Reports/AdHocPeople'><h5>Page Link</h5></Link></td>
</tr>
</table>
The report page is composed of 2 sections.  In the first one, called Selection Criteria, you select the parameters for the report.  In the second one, called fields to show, you select which columns the report is going to show.
<table border="1" cellpadding="3">
<tr>
<td colspan="2"><h6><div align="center">1. SELECTION CRITERIA</div></h6></td>
</tr>
<tr bgcolor="#c0c0c0"><td><h6>Field Name</h6></td>
<td><h6>Field Description</h6></td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Appointment Type</td>
<td>Change the drop down menu if you want to limit the report to one type of appointment only, i.e. Graduate Students.  By default, the Select All (all appointments) is chosen.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Appointment Status (Casual Flag)</td>
<td>The Select All option will select casual and career people.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Payroll Title</td>
<td>Change the drop down menu if you want to limit the report to one payroll title</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">PI</td>
<td>Change the drop down menu if you want to limit the report to one PI</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Supervisor</td>
<td>Change the drop down menu if you want to limit the report to one Supervisor.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Unit Assigned to</td>
<td>Change the drop down menu if you want to limit the report to one unit.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Building</td>
<td>Change the drop down menu if you want to select only the people who are located in a certain building.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Room</td>
<td>Removed from page Feb. 2009. </td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Floor</td>
<td>Change the drop down menu if you want to select only the people who are located on a specific floor.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Department</td>
<td>To select a department.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Status</td>
<td>By default, will retrieve only the active records.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Start Date</td>
<td>Date the person started his/her appointment.  Use it to find all the new people for example.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">End Date</td>
<td>Date a person left.</td>
</tr>
<tr>
<td bgcolor="#B0B6D0">Update Date</td>
<td>Last time a person's appointment was updated.</td>
</tr>
</table>
The second part of the page, Fields to Show, is pretty much self explanatory.  Check the boxes of the fields you want to see.  To export the report to excel, check Export to Excel.  If you want to make create an email list, check generate email list.  Once the email list is generated, copy and paste it into your email.

					</Col>
				</Row>
			</Container>
		);
	}
}

export default Help;