import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';
import Tabs from '../Tabs';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class Students extends ChemComponent {
	constructor(props) {
		super(props);

		var search = this.copyObject(defaultSearch);
		
		// get list of FLD_ keys since they will disappear when object is copied
		var fldKeys = [];
		Object.keys(search).forEach(function(key) {
			if (key.length > 4 && key.substring(0, 4) === 'FLD_') {
				fldKeys.push(key);
			}
		});
		
		var initStudentList = this.copyTable(studentList);
		var searchCount = 0;
		var showSearch = true;
		
		if (props.search) {
			var propsSearch = this.copyObject(props.search);
			this.mergeObject(search, propsSearch);
			search = propsSearch;
		} else if (typeof(Storage) !== 'undefined') {
			var storedSearch = localStorage.getItem('GradOffice_Students_search');
			if (storedSearch) {
				storedSearch = JSON.parse(storedSearch);
				this.mergeObject(search, storedSearch);
				search = storedSearch;
				initStudentList.ajax.data = { search: this.copyObject(search) };
				this.quoteStringArrays(initStudentList.ajax.data.search);
				initStudentList.hold = false;
				this.removeUnusedColumns(initStudentList.ajax.data.search, initStudentList.columns);
				searchCount = null;
				showSearch = false;
			}
		}
		
		this.state = {
			studentList: initStudentList,
			search: search,
			fldKeys: fldKeys,
			searchCount: searchCount,
			showSearch: showSearch,
			activeTab: 'Personal',
			renderKey: 0
		};
	}
	
	componentDidMount() {
		if (this.props.search) this.onSubmitSearch();
	}
	
	onClickTabItem(tab) {
		this.mergeState({
			activeTab: tab
		});
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		
		// if searching by res director
		if (accessor === 'RES_DIRECTOR') {
			// add res director columns
			this.setByAccessor(search, 'FLD_ACADEMIC_YEAR', true);
			this.setByAccessor(search, 'FLD_STUDENT_NAME', true);
			this.setByAccessor(search, 'FLD_REGISTRATION_STATUS', true);
			this.setByAccessor(search, 'FLD_RESEARCH_DIRECTOR', true);
			this.setByAccessor(search, 'FLD_STUDENT_CARD', true);
		}
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newStudentList = this.copyTable(studentList);
		newStudentList.ajax.data = {
			search: this.copyObject(this.state.search)
		};
		this.quoteStringArrays(newStudentList.ajax.data.search);
		newStudentList.hold = false;
		this.removeUnusedColumns(newStudentList.ajax.data.search, newStudentList.columns);
		this.mergeState({ 
			studentList: newStudentList,
			searchCount: null,
			renderKey: this.state.renderKey + 1,
			activeTab: 'Personal',
			showSearch: false
		}, () => this.props.clear());
		if (typeof(Storage) !== 'undefined') {
			localStorage.setItem('GradOffice_Students_search', JSON.stringify(this.state.search));
		}
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onNewSearch(event) {
		this.mergeState({
			search: this.copyObject(defaultSearch),
			showSearch: true,
			renderKey: this.state.renderKey + 1
		});
	}

	onExpandSearch(event) {
		this.mergeState({
			showSearch: true			
		});
	}
	
	getFieldList(search)
	{
		var fields = [];
		Object.keys(search).forEach(function(key) {
			if (key.length > 4 && key.substring(0, 4) === 'FLD_' && search[key]) {
				fields.push(key.substring(4));
			}
		});
		return fields;
	}
	
	removeUnusedColumns(search, columns) {
		var fields = this.getFieldList(search);
		var alreadyIncluded = [];
		
		for (var i = columns.length - 1; i >= 0; i--) {
			if (columns[i].accessor !== 'id' && columns[i].accessor !== 'STUDENT_ID') {
				if (columns[i].includedby) {
					if (fields.includes(columns[i].includedby)) {
						if (alreadyIncluded.includes(columns[i].accessor)) {
							columns.splice(i, 1);
						} else {
							alreadyIncluded.push(columns[i].accessor);
						}
					} else {
						columns.splice(i, 1);
					}
				} else {
					if (fields.includes(columns[i].accessor)) {
						if (alreadyIncluded.includes(columns[i].accessor)) {
							columns.splice(i, 1);
						} else {
							alreadyIncluded.push(columns[i].accessor);
						}
					} else {
						columns.splice(i, 1);
					}
				}
			}
		}
	}

	quoteStringArrays(search) {
		this.quoteStringArray(search['RES_DIRECTOR']);
		this.quoteStringArray(search['FELLOWSHIP_YEAR']);
		this.quoteStringArray(search['REGISTRATION']);
		this.quoteStringArray(search['ACADEMIC_YEAR']);
		this.quoteStringArray(search['DIVISION']);
		this.quoteStringArray(search['VISA_NAME']);
	}
	
	selectAll(event) {
		var search = this.copyObject(this.state.search);
		for (var i = 0; i < this.state.fldKeys.length; i++) {
			this.setByAccessor(search, this.state.fldKeys[i], true);
		}
		this.mergeState({
			search: search,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}

	deselectAll(event) {
		var search = this.copyObject(this.state.search);
		for (var i = 0; i < this.state.fldKeys.length; i++) {
			this.setByAccessor(search, this.state.fldKeys[i], false);
		}
		this.mergeState({
			search: search,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	addStudent() {
		this.props.navigate('/GradOffice/Students/new');
	}
	
	editStudent(studentId) {
		this.props.setStudentEditable(true);
		this.props.navigate('/GradOffice/Students/' + studentId);		
	}
	
	viewStudent(studentId) {
		this.props.setStudentEditable(false);
		this.props.navigate('/GradOffice/Students/' + studentId);
	}

	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		var exportColumnsInUse = this.copyColumns(exportColumns);
		this.removeUnusedColumns(this.state.search, exportColumnsInUse);
		var search = this.copyObject(this.state.search);
		this.quoteStringArrays(search);

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			Action: 'Students',
			columns: exportColumnsInUse,
			search: search
		});			
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Students</span>
						{this.state.showSearch && <Tabs onClickTabItem={(tab) => this.onClickTabItem(tab)}>
						  <div label='Personal'>
							<ChemEdit parent={this} columns={studentPersonal} data={this.state.search} renderKey={this.state.renderKey} 
							  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} user={this.props.user} autoSubmit={true}
							  onSubmit={(event) => this.onSubmitSearch(event)} />
						  </div>
						  <div label='Enrollment'>
							<ChemEdit parent={this} columns={studentEnroll} data={this.state.search} renderKey={this.state.renderKey} 
							  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} user={this.props.user} autoSubmit={true}
							  onSubmit={(event) => this.onSubmitSearch(event)} />
						  </div>
						  <div label='Academic'>
							<ChemEdit parent={this} columns={studentAcademic} data={this.state.search} renderKey={this.state.renderKey} 
							  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} user={this.props.user} autoSubmit={true}
							  onSubmit={(event) => this.onSubmitSearch(event)} />
						  </div>
						  <div label='Fields'>
							<ChemEdit parent={this} columns={studentFields} data={this.state.search} renderKey={this.state.renderKey} 
							  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} user={this.props.user} autoSubmit={true}
							  onSubmit={(event) => this.onSubmitSearch(event)} labelColWidth={10} valueColWidth={2} />
						  </div>
						</Tabs>}
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/GradOffice/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/GradOffice/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						  {this.state.showSearch ?
							<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button> :
							<Button id="expand" variant="warning" type="button" className="float-end" onClick={(event) => this.onExpandSearch(event)}>Modify Search</Button>
						  }
						  <Button id="newSearch" style={{ marginRight: '10px' }} variant="warning" type="button" className="float-end" onClick={(event) => this.onNewSearch(event)}>New Search</Button> 
						  {this.state.showSearch && this.state.activeTab === 'Fields' && <>
							  <Button id="deselectAll" style={{ marginRight: '10px' }} variant="warning" type="button" className="float-end" onClick={(event) => this.deselectAll(event)}>Deselect All</Button>
							  <Button id="selectAll" style={{ marginRight: '10px' }} variant="warning" type="button" className="float-end" onClick={(event) => this.selectAll(event)}>Select All</Button>
						  </>}
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='GradOffice_Students' table={this.state.studentList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='140px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const defaultSearch = {
	STUDENT_CARD: '',
	LNAME: '',
	FNAME: '',
	GENDER_TYPE: [],
	ETHNICITY: [],
	URM: undefined,
	HISPANIC: undefined,
	CA_RESIDENT: 'ALL',
	CITIZENSHIP: null,
	VISA_NAME: [],
	ACADEMIC_YEAR: [],
	SEMESTER_ENROLL: null,
	YEAR_ENROLL: null,
	FELLOWSHIP_YEAR: [],
	GSI_YEAR: [],
	CBGP: 'ALL',
	DIVISION: [],
	SUBDIVISION: [],
	CONCENTRATION: [],
	PROGRAM: 'ALL',
	REGISTRATION: [],
	UG_INSTITUTION: '',
	FIRST_YEAR_ADV: [],
	SEMINAR_DATE_FROM: null,
	SEMINAR_DATE_TO: null,	
	RES_INTEREST: '',
	RANK_FROM: '',
	RANK_TO: '',
	RES_DIRECTOR: [],
	CANDIDACY_FROM: null,
	CANDIDACY_TO: null,
	QE: 'ALL',
	FLD_ACADEMIC_YEAR: true,
	FLD_ADDRESS_CURRENT: false,
	FLD_ADDRESS_END_DATE: false,
	FLD_ADDRESS_PERMANENT: false,
	FLD_ADVANCEMENT_DATE: false,
	FLD_ASSOCIATE_ADVISOR: false,
	FLD_CA_RESIDENCY: false,
	FLD_CAMPUS_ADDRESS: false,
	FLD_CAMPUS_PHONE: false,
	FLD_CANDIDACY_DATE: false,
	FLD_CHEM_BIO_GP: false,
	FLD_CITIZENSHIP: false,
	FLD_CONCENTRATION: false,
	FLD_DEGREE: false,
	FLD_DEGREE_COMMENTS: false,
	FLD_DEGREE_DATE: false,
	FLD_DIVISION: false,
	FLD_EMAIL: false,
	FLD_EMPHASIS: false,
	FLD_ETHNICITY: false,
	FLD_FELLOWSHIPS_INFO: false,
	FLD_FIRST_ADVISING_APPT: false,
	FLD_FIRST_YEAR_ADVISOR: false,
	FLD_GENDER: false,
	FLD_GRE_PERCENTILES: false,
	FLD_GRE_PERCENTILES_BUNDLED: false,
	FLD_GRE_SCORES: false,
	FLD_GRE_SCORES_BUNDLED: false,
	FLD_GSI_ASSIGNMENTS: false,
	FLD_OUTSIDE_READER_DEPT: false,
	FLD_OUTSIDE_READER_NAME: false,
	FLD_PREVIOUS_MS: false,
	FLD_QE_EXAM_COMMITTEE: false,
	FLD_QE_EXAM_RESULT: false,
	FLD_RANK: false,
	FLD_REGISTRATION_STATUS: true,
	FLD_RESEARCH_DIRECTOR: true,
	FLD_RESEARCH_STUDY_AREA: false,
	FLD_RESIDENT_STATE_ZIP: false,
	FLD_SEMINAR_DATE: false,
	FLD_STUDENT_CARD: true,
	FLD_STUDENT_NAME: true,
	FLD_SUBDIVISION: false,
	FLD_THESIS_COMMITTEE: false,
	FLD_THESIS_TITLE: false,
	FLD_UG_DEGREE: false,
	FLD_UG_DEGREE_DATE: false,
	FLD_UG_INSTITUTION: false,
	FLD_UNDERGRAD_GPA: false,
	FLD_VISA: false
};

const studentPersonal = [
	{ Header: 'Student ID', accessor: 'STUDENT_CARD', row: 0, col: 0 },
	{ Header: 'Last Name', accessor: 'LNAME', row: 1, col: 0 },
	{ Header: 'First Name', accessor: 'FNAME', row: 1, col: 1 },
	{ Header: 'Gender', accessor: 'GENDER_TYPE', type: 'list', row: 1, col: 2,
		options: { value: 'GENDER_TYPE_ID', label: 'DESCRIPTION', entity: 'ADMISSIONS.GENDER_TYPE', order: 'DESCRIPTION' }},
	{ Header: 'Ethnicity', accessor: 'ETHNICITY', type: 'list', row: 2, col: 0,
		options: { value: 'ETHNICITY_ID', label: 'ETHNICITY_NAME', entity: 'ADMISSIONS.ETHNICITY', order: 'ETHNICITY_NAME' }},
	{ Header: 'URM', accessor: 'URM', type: 'checkbox', row: 2, col: 1 },
	{ Header: 'Hispanic', accessor: 'HISPANIC', type: 'checkbox', row: 2, col: 2 },
	{ Header: 'CA Resident', accessor: 'CA_RESIDENT', type: 'select', row: 3, col: 0,
		options: [{ value: 'Y', label: 'Yes' }, { value: 'N', label: 'No' }, { value: 'ALL', label: 'All' }]},
	{ Header: 'Citizenship', accessor: 'CITIZENSHIP', type: 'select', row: 3, col: 1,
		options: { value: 'CITIZENSHIP', label: 'CITIZENSHIP', entity: 'ADMISSIONS.CITIZENSHIP_SELECT_VW', order: 'CITIZENSHIP' }},
	{ Header: 'Visa', accessor: 'VISA_NAME', type: 'list', row: 3, col: 2,
		options: { value: 'VISA_NAME', label: 'VISA_NAME', entity: 'ADMISSIONS.VISA', order: 'VISA_NAME' }},
];

const studentEnroll = [
	{ Header: 'Academic Year Entered', accessor: 'ACADEMIC_YEAR', type: 'list', row: 0, col: 0,
		options: { value: 'ACAD_YEAR', label: 'ACAD_YEAR', entity: 'ADMISSIONS.STUDENT_YEAR_SELECT_VW', order: ['YEAR_ORDER','ACAD_YEAR desc'] }},
	{ Header: 'Enrolled Semester', accessor: 'SEMESTER_ENROLL', type: 'select', row: 0, col: 1,
		options: [{ value: null, label: 'Select...' }, { value: 'Fall', label: 'Fall' }, { value: 'Spring', label: 'Spring' }]},
	{ Header: 'Enrolled Year', accessor: 'YEAR_ENROLL', type: 'select', row: 0, col: 2,
		options: { value: 'ACADEMIC_YEAR', label: 'ACADEMIC_YEAR_DISPLAY', entity: 'ADMISSIONS.ACADEMIC_YEAR_SELECT_VW', order: 'ACADEMIC_YEAR desc' }},
	{ Header: 'Fellowship Year', accessor: 'FELLOWSHIP_YEAR', type: 'list', row: 1, col: 0,
		options: { value: 'FELLOWSHIP_YEAR', label: 'FELLOWSHIP_YEAR', entity: 'ADMISSIONS.FELLOWSHIP_YEAR_SELECT_VW', order: ['YEAR_ORDER','FELLOWSHIP_YEAR desc'] }},
	{ Header: 'GSI Year', accessor: 'GSI_YEAR', type: 'list', row: 1, col: 1,
		options: { value: 'GSI_YEAR', label: 'GSI_YEAR', entity: 'ADMISSIONS.GSI_YEAR_SELECT_VW', order: ['YEAR_ORDER','GSI_YEAR desc'] }},
	{ Header: 'Chem Bio GP', accessor: 'CBGP', type: 'select', row: 1, col: 2,
		options: [{ value: 'CBGP', label: 'Only CBGP' }, { value: 'ALL', label: 'All' }]},
	{ Header: 'Divison', accessor: 'DIVISION', type: 'list', row: 2, col: 0,
		options: { value: 'DIVISION', label: 'DIVISION', entity: 'ADMISSIONS.DIVISIONS', order: 'DIVISION' }},
	{ Header: 'Subdivison', accessor: 'SUBDIVISION', type: 'list', row: 2, col: 1,
		options: { value: 'SUBDIVISION', label: 'SUBDIVISION', entity: 'ADMISSIONS.STUDENTS', order: 'SUBDIVISION', distinct: true }},
	{ Header: 'Concentration', accessor: 'CONCENTRATION', type: 'list', row: 2, col: 2,
		options: { value: 'CONCENTRATION', label: 'CONCENTRATION', entity: 'ADMISSIONS.STUDENTS', order: 'CONCENTRATION', distinct: true }},
	{ Header: 'Program', accessor: 'PROGRAM', type: 'select', row: 3, col: 0,
		options: [{ value: 'PHD', label: 'PhD' }, { value: 'MS', label: 'MS' }, { value: 'ALL', label: 'All' }]},
	{ Header: 'Registration', accessor: 'REGISTRATION', type: 'list', row: 3, col: 1,
		options: { value: 'REG_STATUS_NAME', label: 'REG_STATUS_NAME', entity: 'ADMISSIONS.REG_STATUS_SELECT_VW', order: 'REG_STATUS_NAME' }},
	{ Header: 'UG Institution', accessor: 'UG_INSTITUTION', row: 3, col: 2 }
];

const studentAcademic = [
	{ Header: '1st Year Advisor', accessor: 'FIRST_YEAR_ADV', type: 'list', row: 0, col: 0,
		options: { value: 'FIRST_YEAR_ADV', label: 'ADV_NAME', entity: 'ADMISSIONS.FIRST_YEAR_ADV_SELECT_VW', order: 'ADV_NAME' }},
	{ Header: 'Seminar Date From', accessor: 'SEMINAR_DATE_FROM', type: 'date', row: 0, col: 1 },
	{ Header: 'Seminar Date To', accessor: 'SEMINAR_DATE_TO', type: 'date', row: 0, col: 2 },		
	{ Header: 'Research Interests', accessor: 'RES_INTEREST', row: 1, col: 0 },
	{ Header: 'Rank From', accessor: 'RANK_FROM', row: 1, col: 1 },
	{ Header: 'Rank To', accessor: 'RANK_TO', row: 1, col: 2 },
	{ Header: 'Research Director', accessor: 'RES_DIRECTOR', type: 'list', row: 2, col: 0,
		options: { value: 'RES_DIRECTOR_NAME', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.RESEARCH_DIRECTOR_SELECT_VW', order: 'RES_DIRECTOR_NAME' }},
	{ Header: 'Candidacy From', accessor: 'CANDIDACY_FROM', type: 'date', row: 2, col: 1 },
	{ Header: 'Candidacy To', accessor: 'CANDIDACY_TO', type: 'date', row: 2, col: 2 },
	{ Header: 'QE', accessor: 'QE', type: 'select', row: 3, col: 0,
		options: [{ value: 'P', label: 'P' }, { value: 'F', label: 'F' }, { value: 'ALL', label: 'All' }]},	
];

const studentFields = [
	{ Header: 'Academic Year', accessor: 'FLD_ACADEMIC_YEAR', type: 'checkbox', row: 0, col: 0 },
	{ Header: 'Academic Senate Rep', accessor: 'FLD_OUTSIDE_READER_NAME', type: 'checkbox', row: 1, col: 0 },
	{ Header: 'Academic Senate Rep Dept', accessor: 'FLD_OUTSIDE_READER_DEPT', type: 'checkbox', row: 2, col: 0 },
	{ Header: 'Address - Current', accessor: 'FLD_ADDRESS_CURRENT', type: 'checkbox', row: 3, col: 0 },
	{ Header: 'Address - End Date', accessor: 'FLD_ADDRESS_END_DATE', type: 'checkbox', row: 4, col: 0 },
	{ Header: 'Address - Permanent', accessor: 'FLD_ADDRESS_PERMANENT', type: 'checkbox', row: 5, col: 0 },
	{ Header: 'Advancement Date', accessor: 'FLD_ADVANCEMENT_DATE', type: 'checkbox', row: 6, col: 0 },
	{ Header: 'Associate Advisor', accessor: 'FLD_ASSOCIATE_ADVISOR', type: 'checkbox', row: 7, col: 0 },
	{ Header: 'CA Residency', accessor: 'FLD_CA_RESIDENCY', type: 'checkbox', row: 8, col: 0 },
	{ Header: 'Campus Address', accessor: 'FLD_CAMPUS_ADDRESS', type: 'checkbox', row: 0, col: 1 },
	{ Header: 'Campus Phone', accessor: 'FLD_CAMPUS_PHONE', type: 'checkbox', row: 1, col: 1 },
	{ Header: 'Candidacy Date', accessor: 'FLD_CANDIDACY_DATE', type: 'checkbox', row: 2, col: 1 },
	{ Header: 'Chem Bio GP', accessor: 'FLD_CHEM_BIO_GP', type: 'checkbox', row: 3, col: 1 },
	{ Header: 'Citizenship', accessor: 'FLD_CITIZENSHIP', type: 'checkbox', row: 4, col: 1 },
	{ Header: 'Concentration', accessor: 'FLD_CONCENTRATION', type: 'checkbox', row: 5, col: 1 },
	{ Header: 'Degree', accessor: 'FLD_DEGREE', type: 'checkbox', row: 6, col: 1 },
	{ Header: 'Degree Comments', accessor: 'FLD_DEGREE_COMMENTS', type: 'checkbox', row: 7, col: 1 },
	{ Header: 'Degree Date', accessor: 'FLD_DEGREE_DATE', type: 'checkbox', row: 8, col: 1 },
	{ Header: 'Division', accessor: 'FLD_DIVISION', type: 'checkbox', row: 0, col: 2 },
	{ Header: 'Email', accessor: 'FLD_EMAIL', type: 'checkbox', row: 1, col: 2 },
	{ Header: 'Emphasis', accessor: 'FLD_EMPHASIS', type: 'checkbox', row: 2, col: 2 },
	{ Header: 'Ethnicity', accessor: 'FLD_ETHNICITY', type: 'checkbox', row: 3, col: 2 },
	{ Header: 'Fellowships Info', accessor: 'FLD_FELLOWSHIPS_INFO', type: 'checkbox', row: 4, col: 2 },
	{ Header: 'First Advising Appt.', accessor: 'FLD_FIRST_ADVISING_APPT', type: 'checkbox', row: 5, col: 2 },
	{ Header: 'First Year Advisor', accessor: 'FLD_FIRST_YEAR_ADVISOR', type: 'checkbox', row: 6, col: 2 },
	{ Header: 'Gender', accessor: 'FLD_GENDER', type: 'checkbox', row: 7, col: 2 },
	{ Header: 'GRE %iles', accessor: 'FLD_GRE_PERCENTILES', type: 'checkbox', row: 0, col: 3 },
	{ Header: 'GRE %iles Bundled', accessor: 'FLD_GRE_PERCENTILES_BUNDLED', type: 'checkbox', row: 1, col: 3 },
	{ Header: 'GRE Scores', accessor: 'FLD_GRE_SCORES', type: 'checkbox', row: 2, col: 3 },
	{ Header: 'GRE Scores Bundled', accessor: 'FLD_GRE_SCORES_BUNDLED', type: 'checkbox', row: 3, col: 3 },
	{ Header: 'GSI Assignments', accessor: 'FLD_GSI_ASSIGNMENTS', type: 'checkbox', row: 4, col: 3 },
	{ Header: 'Previous MS', accessor: 'FLD_PREVIOUS_MS', type: 'checkbox', row: 5, col: 3 },
	{ Header: 'QE Exam Committee', accessor: 'FLD_QE_EXAM_COMMITTEE', type: 'checkbox', row: 6, col: 3 },
	{ Header: 'QE Exam Result', accessor: 'FLD_QE_EXAM_RESULT', type: 'checkbox', row: 7, col: 3 },
	{ Header: 'Rank', accessor: 'FLD_RANK', type: 'checkbox', row: 0, col: 4 },
	{ Header: 'Registration Status', accessor: 'FLD_REGISTRATION_STATUS', type: 'checkbox', row: 1, col: 4 },
	{ Header: 'Research Director', accessor: 'FLD_RESEARCH_DIRECTOR', type: 'checkbox', row: 2, col: 4 },
	{ Header: 'Research/Study Area', accessor: 'FLD_RESEARCH_STUDY_AREA', type: 'checkbox', row: 3, col: 4 },
	{ Header: 'Resident State/Zip', accessor: 'FLD_RESIDENT_STATE_ZIP', type: 'checkbox', row: 4, col: 4 },
	{ Header: 'Seminar Date', accessor: 'FLD_SEMINAR_DATE', type: 'checkbox', row: 5, col: 4 },
	{ Header: 'Student ID', accessor: 'FLD_STUDENT_CARD', type: 'checkbox', row: 6, col: 4 },
	{ Header: 'Student Name', accessor: 'FLD_STUDENT_NAME', type: 'checkbox', row: 7, col: 4 },
	{ Header: 'Subdivision', accessor: 'FLD_SUBDIVISION', type: 'checkbox', row: 0, col: 5 },
	{ Header: 'Thesis Committee', accessor: 'FLD_THESIS_COMMITTEE', type: 'checkbox', row: 1, col: 5 },
	{ Header: 'Thesis Title', accessor: 'FLD_THESIS_TITLE', type: 'checkbox', row: 2, col: 5 },
	{ Header: 'UG Degree', accessor: 'FLD_UG_DEGREE', type: 'checkbox', row: 3, col: 5 },
	{ Header: 'UG Degree Date', accessor: 'FLD_UG_DEGREE_DATE', type: 'checkbox', row: 4, col: 5 },
	{ Header: 'UG institution', accessor: 'FLD_UG_INSTITUTION', type: 'checkbox', row: 5, col: 5 },
	{ Header: 'Undergrad GPA', accessor: 'FLD_UNDERGRAD_GPA', type: 'checkbox', row: 6, col: 5 },
	{ Header: 'Visa', accessor: 'FLD_VISA', type: 'checkbox', row: 7, col: 5 }
];

const studentList = {
	ajax: { 
		url: '/GradOffice/GetStudents',
		data: null,
		accessor: 'Data'
	},
	pageSize: 20,
	hold: true,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addStudent()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editStudent(props.row.values.STUDENT_ID)} />, width: 25 },
		{ Header: '', accessor: 'STUDENT_ID', show: false },
		{ Header: 'Year', accessor: 'ACADEMIC_YEAR', width: 60 },
		{ Header: 'Name', accessor: 'STUDENT_NAME', width: 200, Cell: props => <HashLink onClick={() => props.parent.viewStudent(props.row.values.STUDENT_ID)}>{props.value}</HashLink> },
		{ Header: 'Visa', accessor: 'VISA', width: 200 },
		{ Header: 'UG Institution', accessor: 'UG_INSTITUTION', width: 200 },
		{ Header: 'UG Degree', accessor: 'UG_DEGREE', width: 150 },
		{ Header: 'UG Degree Date', accessor: 'UG_DEGREE_DATE', width: 100 },
		{ Header: 'Prev. MS', accessor: 'PREVIOUS_MS', width: 100 },
		{ Header: 'Ethnicity', accessor: 'ETHNICITY', width: 200 },
		{ Header: 'Gender', accessor: 'GENDER', width: 100 },
		{ Header: 'Reg. Status', accessor: 'REGISTRATION_STATUS', width: 150 },
		{ Header: 'Email', accessor: 'EMAIL', width: 250 },
		{ Header: 'Res. Director', accessor: 'RESEARCH_DIRECTOR', width: 200, Cell: props => props.parent.getCellFragment(props.value) },
		{ Header: 'Citizenship', accessor: 'CITIZENSHIP', width: 150 },
		{ Header: 'Research Interest 1', accessor: 'RES_INTEREST1', includedby: 'RESEARCH_STUDY_AREA', width: 200 },
		{ Header: 'Research Interest 2', accessor: 'RES_INTEREST2', includedby: 'RESEARCH_STUDY_AREA', width: 200 },
		{ Header: 'Research Interest 3', accessor: 'RES_INTEREST3', includedby: 'RESEARCH_STUDY_AREA', width: 200 },
		{ Header: 'Emphasis', accessor: 'EMPHASIS', width: 100 },
		{ Header: 'Division', accessor: 'DIVISION', width: 100 },
		{ Header: 'Subdivision', accessor: 'SUBDIVISION', width: 100 },
		{ Header: 'Concentration', accessor: 'CONCENTRATION', width: 110 },
		{ Header: 'CBGP', accessor: 'CHEM_BIO_GP', width: 50 },
		{ Header: 'Fellowships', accessor: 'FELLOWSHIPS_INFO', width: 400, Cell: props => props.parent.getCellFragment(props.value) },
		{ Header: 'GSI', accessor: 'GSI_ASSIGNMENTS', width: 200, Cell: props => props.parent.getCellFragment(props.value) },
		{ Header: 'CA Residency', accessor: 'CA_RESIDENCY', width: 100 },
		{ Header: 'Permanent Street 1', accessor: 'STREET1', includedby: 'ADDRESS_PERMANENT', width: 200 },
		{ Header: 'Permanent Street 1', accessor: 'STREET2', includedby: 'ADDRESS_PERMANENT', width: 200 },
		{ Header: 'Permanent City', accessor: 'CITY', includedby: 'ADDRESS_PERMANENT', width: 100 },
		{ Header: 'Permanent State', accessor: 'STATE', includedby: 'ADDRESS_PERMANENT', width: 100 },
		{ Header: 'Permanent Zip', accessor: 'ZIP', includedby: 'ADDRESS_PERMANENT', width: 100 },
		{ Header: 'Permanent Country', accessor: 'COUNTRY', includedby: 'ADDRESS_PERMANENT', width: 100 },
		{ Header: 'Temp Street 1', accessor: 'TEMP_STREET1', includedby: 'ADDRESS_CURRENT', width: 200 },
		{ Header: 'Temp Street 1', accessor: 'TEMP_STREET2', includedby: 'ADDRESS_CURRENT', width: 200 },
		{ Header: 'Temp City', accessor: 'TEMP_CITY', includedby: 'ADDRESS_CURRENT', width: 100 },
		{ Header: 'Temp State', accessor: 'TEMP_STATE', includedby: 'ADDRESS_CURRENT', width: 100 },
		{ Header: 'Temp Zip', accessor: 'TEMP_ZIP', includedby: 'ADDRESS_CURRENT', width: 100 },
		{ Header: 'Temp Country', accessor: 'TEMP_COUNTRY', includedby: 'ADDRESS_CURRENT', width: 100 },
		{ Header: 'Temp Address End Date', accessor: 'ADDRESS_END_DATE', width: 125 },
		{ Header: 'State', accessor: 'RESIDENT_STATE', includedby: 'RESIDENT_STATE_ZIP', width: 100 },
		{ Header: 'Zip', accessor: 'RESIDENT_ZIP', includedby: 'RESIDENT_STATE_ZIP', width: 60 },
		{ Header: 'Undergrad GPA', accessor: 'UNDERGRAD_GPA', width: 100 },
		{ Header: 'GRE1 %', accessor: 'GRE1_SCORE_PCT', includedby: 'GRE_PERCENTILES_BUNDLED', width: 150 },
		{ Header: 'GRE2 %', accessor: 'GRE2_SCORE_PCT', includedby: 'GRE_PERCENTILES_BUNDLED', width: 150 },
		{ Header: 'GRE1 V%', accessor: 'PCT_GRE1_V', includedby: 'GRE_PERCENTILES', width: 80 },
		{ Header: 'GRE1 Q%', accessor: 'PCT_GRE1_Q', includedby: 'GRE_PERCENTILES', width: 80 },
		{ Header: 'GRE1 A%', accessor: 'PCT_GRE1_A', includedby: 'GRE_PERCENTILES', width: 80 },
		{ Header: 'GRE1 S%', accessor: 'PCT_GRE1_SUBJ', includedby: 'GRE_PERCENTILES', width: 80 },
		{ Header: 'GRE2 V%', accessor: 'PCT_GRE2_V', includedby: 'GRE_PERCENTILES', width: 80 },
		{ Header: 'GRE2 Q%', accessor: 'PCT_GRE2_Q', includedby: 'GRE_PERCENTILES', width: 80 },
		{ Header: 'GRE2 A%', accessor: 'PCT_GRE2_A', includedby: 'GRE_PERCENTILES', width: 80 },
		{ Header: 'GRE2 S%', accessor: 'PCT_GRE2_SUBJ', includedby: 'GRE_PERCENTILES', width: 80 },
		{ Header: 'GRE1 Scores', accessor: 'GRE1_SCORES', includedby: 'GRE_SCORES_BUNDLED', width: 150 },
		{ Header: 'GRE2 Scores', accessor: 'GRE2_SCORES', includedby: 'GRE_SCORES_BUNDLED', width: 150 },
		{ Header: 'GRE1 V', accessor: 'GRE1_V', includedby: 'GRE_SCORES', width: 80 },
		{ Header: 'GRE1 Q', accessor: 'GRE1_Q', includedby: 'GRE_SCORES', width: 80 },
		{ Header: 'GRE1 A', accessor: 'GRE1_A', includedby: 'GRE_SCORES', width: 80 },
		{ Header: 'GRE1 S', accessor: 'GRE1_SUBJ', includedby: 'GRE_SCORES', width: 80 },
		{ Header: 'GRE2 V', accessor: 'GRE2_V', includedby: 'GRE_SCORES', width: 80 },
		{ Header: 'GRE2 Q', accessor: 'GRE2_Q', includedby: 'GRE_SCORES', width: 80 },
		{ Header: 'GRE2 A', accessor: 'GRE2_A', includedby: 'GRE_SCORES', width: 80 },
		{ Header: 'GRE2 S', accessor: 'GRE2_SUBJ', includedby: 'GRE_SCORES', width: 80 },
		{ Header: 'Rank', accessor: 'RANK', width: 60 },
		{ Header: '1st Yr Advisor', accessor: 'FIRST_YEAR_ADVISOR', width: 200 },
		{ Header: 'Advising Appt.', accessor: 'FIRST_ADVISING_APPT', width: 200 },
		{ Header: 'Assoc. Adv.', accessor: 'ASSOCIATE_ADVISOR', width: 200 },
		{ Header: 'Thesis Title', accessor: 'THESIS_TITLE', width: 400 },
		{ Header: 'Degree', accessor: 'DEGREE', width: 100 },
		{ Header: 'Degree Date', accessor: 'DEGREE_DATE', width: 100 },
		{ Header: 'Seminar Date', accessor: 'SEMINAR_DATE', width: 100 },
		{ Header: 'Outside Reader', accessor: 'OUTSIDE_READER_NAME', width: 200 },
		{ Header: 'Outside Dept.', accessor: 'OUTSIDE_READER_DEPT', width: 100 },
		{ Header: 'Candidacy', accessor: 'CANDIDACY_DATE', width: 100 },
		{ Header: 'Student ID', accessor: 'STUDENT_CARD', width: 100 },
		{ Header: 'Campus Address', accessor: 'CAMPUS_ADDRESS', width: 150 },
		{ Header: 'Campus Phone', accessor: 'CAMPUS_PHONE', width: 100 },
		{ Header: 'Comments', accessor: 'DEGREE_COMMENTS', width: 300 },
		{ Header: 'Thesis Committee Chair', accessor: 'THESIS_CHAIR', includedby: 'THESIS_COMMITTEE', width: 200 },
		{ Header: 'Thesis Inside Reader', accessor: 'INSIDE_READER', includedby: 'THESIS_COMMITTEE', width: 200 },
		{ Header: 'Thesis Outside Reader', accessor: 'OUTSIDE_READER', includedby: 'THESIS_COMMITTEE', width: 200 },
		{ Header: 'Thesis Additional Reader', accessor: 'ADD_READER', includedby: 'THESIS_COMMITTEE', width: 200 },
		{ Header: 'QE Result 1', accessor: 'RESULT1', includedby: 'QE_EXAM_RESULT', width: 125 },
		{ Header: 'QE Result 2', accessor: 'RESULT2', includedby: 'QE_EXAM_RESULT', width: 125 },
		{ Header: 'QE Chair', accessor: 'CHAIR', includedby: 'QE_EXAM_COMMITTEE', width: 150 },
		{ Header: 'QE Member 1', accessor: 'MEMBER1', includedby: 'QE_EXAM_COMMITTEE', width: 150 },
		{ Header: 'QE Member 2', accessor: 'MEMBER2', includedby: 'QE_EXAM_COMMITTEE', width: 150 },
		{ Header: 'QE Date 1', accessor: 'EXAM_DATE1', includedby: 'QE_EXAM_COMMITTEE', width: 125 },
		{ Header: 'QE Result 1', accessor: 'RESULT1', includedby: 'QE_EXAM_COMMITTEE', width: 125 },
		{ Header: 'QE Date 2', accessor: 'EXAM_DATE2', includedby: 'QE_EXAM_COMMITTEE', width: 125 },
		{ Header: 'QE Result 2', accessor: 'RESULT2', includedby: 'QE_EXAM_COMMITTEE', width: 125 }
	]
};

const exportColumns = [
	{ Header: 'Year', accessor: 'ACADEMIC_YEAR', width: 12 },
	{ Header: 'Name', accessor: 'STUDENT_NAME', width: 40 },
	{ Header: 'Visa', accessor: 'VISA', width: 40 },
	{ Header: 'UG Institution', accessor: 'UG_INSTITUTION', width: 40 },
	{ Header: 'UG Degree', accessor: 'UG_DEGREE', width: 30 },
	{ Header: 'UG Degree Date', accessor: 'UG_DEGREE_DATE', width: 20 },
	{ Header: 'Prev. MS', accessor: 'PREVIOUS_MS', width: 20 },
	{ Header: 'Ethnicity', accessor: 'ETHNICITY', width: 40 },
	{ Header: 'Gender', accessor: 'GENDER', width: 20 },
	{ Header: 'Reg. Status', accessor: 'REGISTRATION_STATUS', width: 30 },
	{ Header: 'Email', accessor: 'EMAIL', width: 50 },
	{ Header: 'Res. Director', accessor: 'RESEARCH_DIRECTOR', width: 40 },
	{ Header: 'Citizenship', accessor: 'CITIZENSHIP', width: 30 },
	{ Header: 'Research Interest 1', accessor: 'RES_INTEREST1', includedby: 'RESEARCH_STUDY_AREA', width: 40 },
	{ Header: 'Research Interest 2', accessor: 'RES_INTEREST2', includedby: 'RESEARCH_STUDY_AREA', width: 40 },
	{ Header: 'Research Interest 3', accessor: 'RES_INTEREST3', includedby: 'RESEARCH_STUDY_AREA', width: 40 },
	{ Header: 'Emphasis', accessor: 'EMPHASIS', width: 20 },
	{ Header: 'Division', accessor: 'DIVISION', width: 20 },
	{ Header: 'Subdivision', accessor: 'SUBDIVISION', width: 20 },
	{ Header: 'Concentration', accessor: 'CONCENTRATION', width: 22 },
	{ Header: 'CBGP', accessor: 'CHEM_BIO_GP', width: 10 },
	{ Header: 'Fellowships', accessor: 'FELLOWSHIPS_INFO', width: 80 },
	{ Header: 'GSI', accessor: 'GSI_ASSIGNMENTS', width: 40 },
	{ Header: 'CA Residency', accessor: 'CA_RESIDENCY', width: 20 },
	{ Header: 'Permanent Street 1', accessor: 'STREET1', includedby: 'ADDRESS_PERMANENT', width: 40 },
	{ Header: 'Permanent Street 1', accessor: 'STREET2', includedby: 'ADDRESS_PERMANENT', width: 40 },
	{ Header: 'Permanent City', accessor: 'CITY', includedby: 'ADDRESS_PERMANENT', width: 20 },
	{ Header: 'Permanent State', accessor: 'STATE', includedby: 'ADDRESS_PERMANENT', width: 20 },
	{ Header: 'Permanent Zip', accessor: 'ZIP', includedby: 'ADDRESS_PERMANENT', width: 20 },
	{ Header: 'Permanent Country', accessor: 'COUNTRY', includedby: 'ADDRESS_PERMANENT', width: 20 },
	{ Header: 'Temp Street 1', accessor: 'TEMP_STREET1', includedby: 'ADDRESS_CURRENT', width: 40 },
	{ Header: 'Temp Street 1', accessor: 'TEMP_STREET2', includedby: 'ADDRESS_CURRENT', width: 40 },
	{ Header: 'Temp City', accessor: 'TEMP_CITY', includedby: 'ADDRESS_CURRENT', width: 20 },
	{ Header: 'Temp State', accessor: 'TEMP_STATE', includedby: 'ADDRESS_CURRENT', width: 20 },
	{ Header: 'Temp Zip', accessor: 'TEMP_ZIP', includedby: 'ADDRESS_CURRENT', width: 20 },
	{ Header: 'Temp Country', accessor: 'TEMP_COUNTRY', includedby: 'ADDRESS_CURRENT', width: 20 },
	{ Header: 'Temp Address End Date', accessor: 'ADDRESS_END_DATE', width: 25 },
	{ Header: 'State', accessor: 'RESIDENT_STATE', includedby: 'RESIDENT_STATE_ZIP', width: 20 },
	{ Header: 'Zip', accessor: 'RESIDENT_ZIP', includedby: 'RESIDENT_STATE_ZIP', width: 12 },
	{ Header: 'Undergrad GPA', accessor: 'UNDERGRAD_GPA', width: 20 },
	{ Header: 'GRE1 %', accessor: 'GRE1_SCORE_PCT', includedby: 'GRE_PERCENTILES_BUNDLED', width: 30 },
	{ Header: 'GRE2 %', accessor: 'GRE2_SCORE_PCT', includedby: 'GRE_PERCENTILES_BUNDLED', width: 30 },
	{ Header: 'GRE1 V%', accessor: 'PCT_GRE1_V', includedby: 'GRE_PERCENTILES', width: 16 },
	{ Header: 'GRE1 Q%', accessor: 'PCT_GRE1_Q', includedby: 'GRE_PERCENTILES', width: 16 },
	{ Header: 'GRE1 A%', accessor: 'PCT_GRE1_A', includedby: 'GRE_PERCENTILES', width: 16 },
	{ Header: 'GRE1 S%', accessor: 'PCT_GRE1_SUBJ', includedby: 'GRE_PERCENTILES', width: 16 },
	{ Header: 'GRE2 V%', accessor: 'PCT_GRE2_V', includedby: 'GRE_PERCENTILES', width: 16 },
	{ Header: 'GRE2 Q%', accessor: 'PCT_GRE2_Q', includedby: 'GRE_PERCENTILES', width: 16 },
	{ Header: 'GRE2 A%', accessor: 'PCT_GRE2_A', includedby: 'GRE_PERCENTILES', width: 16 },
	{ Header: 'GRE2 S%', accessor: 'PCT_GRE2_SUBJ', includedby: 'GRE_PERCENTILES', width: 16 },
	{ Header: 'GRE1 Scores', accessor: 'GRE1_SCORES', includedby: 'GRE_SCORES_BUNDLED', width: 30 },
	{ Header: 'GRE2 Scores', accessor: 'GRE2_SCORES', includedby: 'GRE_SCORES_BUNDLED', width: 30 },
	{ Header: 'GRE1 V', accessor: 'GRE1_V', includedby: 'GRE_SCORES', width: 16 },
	{ Header: 'GRE1 Q', accessor: 'GRE1_Q', includedby: 'GRE_SCORES', width: 16 },
	{ Header: 'GRE1 A', accessor: 'GRE1_A', includedby: 'GRE_SCORES', width: 16 },
	{ Header: 'GRE1 S', accessor: 'GRE1_SUBJ', includedby: 'GRE_SCORES', width: 16 },
	{ Header: 'GRE2 V', accessor: 'GRE2_V', includedby: 'GRE_SCORES', width: 16 },
	{ Header: 'GRE2 Q', accessor: 'GRE2_Q', includedby: 'GRE_SCORES', width: 16 },
	{ Header: 'GRE2 A', accessor: 'GRE2_A', includedby: 'GRE_SCORES', width: 16 },
	{ Header: 'GRE2 S', accessor: 'GRE2_SUBJ', includedby: 'GRE_SCORES', width: 16 },
	{ Header: 'Rank', accessor: 'RANK', width: 12 },
	{ Header: '1st Yr Advisor', accessor: 'FIRST_YEAR_ADVISOR', width: 40 },
	{ Header: 'Advising Appt.', accessor: 'FIRST_ADVISING_APPT', width: 40 },
	{ Header: 'Assoc. Adv.', accessor: 'ASSOCIATE_ADVISOR', width: 40 },
	{ Header: 'Thesis Title', accessor: 'THESIS_TITLE', width: 80 },
	{ Header: 'Degree', accessor: 'DEGREE', width: 20 },
	{ Header: 'Degree Date', accessor: 'DEGREE_DATE', width: 20 },
	{ Header: 'Seminar Date', accessor: 'SEMINAR_DATE', width: 20 },
	{ Header: 'Outside Reader', accessor: 'OUTSIDE_READER_NAME', width: 40 },
	{ Header: 'Outside Dept.', accessor: 'OUTSIDE_READER_DEPT', width: 20 },
	{ Header: 'Candidacy', accessor: 'CANDIDACY_DATE', width: 20 },
	{ Header: 'Student ID', accessor: 'STUDENT_CARD', width: 20 },
	{ Header: 'Campus Address', accessor: 'CAMPUS_ADDRESS', width: 30 },
	{ Header: 'Campus Phone', accessor: 'CAMPUS_PHONE', width: 20 },
	{ Header: 'Comments', accessor: 'DEGREE_COMMENTS', width: 60 },
	{ Header: 'Thesis Committee Chair', accessor: 'THESIS_CHAIR', includedby: 'THESIS_COMMITTEE', width: 40 },
	{ Header: 'Thesis Inside Reader', accessor: 'INSIDE_READER', includedby: 'THESIS_COMMITTEE', width: 40 },
	{ Header: 'Thesis Outside Reader', accessor: 'OUTSIDE_READER', includedby: 'THESIS_COMMITTEE', width: 40 },
	{ Header: 'Thesis Additional Reader', accessor: 'ADD_READER', includedby: 'THESIS_COMMITTEE', width: 40 },
	{ Header: 'QE Result', accessor: 'QE_EXAM_RESULT', width: 18 },
	{ Header: 'QE Chair', accessor: 'CHAIR', includedby: 'QE_EXAM_COMMITTEE', width: 30 },
	{ Header: 'QE Member 1', accessor: 'MEMBER1', includedby: 'QE_EXAM_COMMITTEE', width: 30 },
	{ Header: 'QE Member 2', accessor: 'MEMBER2', includedby: 'QE_EXAM_COMMITTEE', width: 30 },
	{ Header: 'QE Date 1', accessor: 'EXAM_DATE1', includedby: 'QE_EXAM_COMMITTEE', width: 25 },
	{ Header: 'QE Result 1', accessor: 'RESULT1', includedby: 'QE_EXAM_COMMITTEE', width: 25 },
	{ Header: 'QE Date 2', accessor: 'EXAM_DATE2', includedby: 'QE_EXAM_COMMITTEE', width: 25 },
	{ Header: 'QE Result 2', accessor: 'RESULT2', includedby: 'QE_EXAM_COMMITTEE', width: 25 }
];

export default withRouter(Students)
