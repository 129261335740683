import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';


class FundSourceData extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			BEG_FISCAL_YEAR: null,
			SERVICE_ID: null
		};

		this.state = {
			fundSourceDataList: this.copyTable(fundSourceDataList),
			search: search,
			searchCount: 0,
			renderKey: 1
		};
	}
	
	componentDidMount() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/Search',
			data: {
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'CURRENT_CYCLE_VW',
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				if (data.Data.length === 1) {
					self.mergeState({ 
						cycleBegDate: self.getDate(data.Data[0].BEG_DATE).toLocaleDateString('en-US', {year:'2-digit', month:'2-digit', day:'2-digit'}),
						cycleEndDate: self.getDate(data.Data[0].END_DATE).toLocaleDateString('en-US', {year:'2-digit', month:'2-digit', day:'2-digit'}),
						renderKey: self.state.renderKey + 1
					});
				} else {
					self.showAlert('Cycle Error', 'Expected 1 current billing cycle but found ' + data.Data.length);
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}

	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		this.mergeState({
			fundSourceDataList: {
				ajax: {
					data: {
						criteria: {
							BEG_FISCAL_YEAR: this.dateTimeToMVC(this.getDate(this.state.search.BEG_FISCAL_YEAR)),
							SERVICE_ID: this.state.search.SERVICE_ID ? this.state.search.SERVICE_ID.join(',') : null
						}
					}
				},
				hold: false
			},
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {

		var exportFundSourceDataData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			action: 'FundSourceData',
			columns: fundSourceDataExportColumns,
			criteria: {
				BEG_FISCAL_YEAR: this.dateTimeToMVC(this.getDate(this.state.search.BEG_FISCAL_YEAR)),
				SERVICE_ID: this.state.search.SERVICE_ID ? this.state.search.SERVICE_ID.join(',') : null
			}
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Fund Source Data Report</span>
					<div style={{ paddingBottom: '10px' }}>Current billing cycle {this.state.cycleBegDate} - {this.state.cycleEndDate}</div>
					<ChemEdit parent={this} columns={fundSourceDataSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={4} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/CRS/Excel'} method='POST'>
								{exportFundSourceDataData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/CRS/Pdf'} method='POST'>
								{exportFundSourceDataData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
				  <Col>
				    <QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_FundSourceData' table={this.state.fundSourceDataList}
					  onResult={(data) => this.onSearchResult(data)} offsetHeight='280px' />
				  </Col>
				</Row>
			</Container>
		</>);
	}
}

const fundSourceDataSearch = [
	{ Header: 'Fiscal Year', accessor: 'BEG_FISCAL_YEAR', type: 'select', row: 0, col: 0, required: true,
		options: { value: 'BEG_FISCAL_YEAR', label: 'FISCAL_YEAR', entity: 'RECHARGES.FISCAL_YEAR_SELECT_VW', order: 'BEG_FISCAL_YEAR desc' }},
	{ Header: 'Service<br /><i>Demurrage will show in statement after 06/30/16</i>', accessor: 'SERVICE_ID', type: 'list', row: 0, col: 1,
		options: { value: 'SERVICE_ID_LIST', label: 'RCH_NAME', entity: 'RECHARGES.STATEMENT_SERVICE_SELECT_VW', order: 'RCH_NAME' }},
];

const fundSourceDataList = {
	ajax: {
		url: '/CRS/FundSourceData',
		accessor: 'Data',
		data: {
			criteria: {
				BEG_FISCAL_YEAR: null,
				SERVICE_ID: null
			}
		}
	},
	pageSize: 20,
	hold: true,
	columns: [
		{ Header: 'Service Name', accessor: 'SERVICE_NAME', width: 250 },
		{ Header: 'Fund Code', accessor: 'FUNDCODE', width: 70 },
		{ Header: 'Dept ID', accessor: 'DEPT_ID', width: 60 },
		{ Header: 'Program', accessor: 'PROGRAM', width: 70 },
		{ Header: 'Chart Field 1', accessor: 'CHARTFIELD1', width: 80 },
		{ Header: 'Chart Field 2', accessor: 'CHARTFIELD2', width: 80 },
		{ Header: 'Fund Group Desc', accessor: 'FUND_GROUP_DESCR', width: 350 },
		{ Header: 'Total Charged', accessor: 'TOTAL_CHARGED', width: 90,
			Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>{props.value}</div> },
		{ Header: 'Date Billed', accessor: 'DATE_BILLED', width: 100 }
	]
};

const fundSourceDataExportColumns = [
	{ Header: 'Service Name', accessor: 'SERVICE_NAME', width: 50 },
	{ Header: 'Fund Code', accessor: 'FUNDCODE', width: 14 },
	{ Header: 'Dept ID', accessor: 'DEPT_ID', width: 12 },
	{ Header: 'Program', accessor: 'PROGRAM', width: 14 },
	{ Header: 'Chart Field 1', accessor: 'CHARTFIELD1', width: 16 },
	{ Header: 'Chart Field 2', accessor: 'CHARTFIELD2', width: 16 },
	{ Header: 'Fund Group Desc', accessor: 'FUND_GROUP_DESCR', width: 70 },
	{ Header: 'Total Charged', accessor: 'TOTAL_CHARGED', width: 18, format: '#,##0.00' },
	{ Header: 'Date Billed', accessor: 'DATE_BILLED', width: 20, format: 'yyyy-mm-dd' }
];

export default withRouter(FundSourceData);