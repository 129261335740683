import React from 'react';
import ChemComponent from '../ChemComponent';
import ChemTable from '../ChemTable';
import DatePicker from 'react-datepicker';
import { Container, Row, Col } from 'react-bootstrap';
import { ArrowUpSquare, ArrowDownSquare, Floppy, PlusSquare, XSquare } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class ReceiveDetail extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			catalog: []
		};
	}
	
	isRecoverableOrdering() {
		var i;
		var histogram = [];
		
		// first pass: make histogram of all valid line ID's
		for (i = 0; i < this.props.data.length; i++) {
			var lineID = this.props.data[i].LINE_ID;
			if (this.isIndex(lineID)) {
				if (lineID >= histogram.length || !this.isIndex(histogram[lineID])) {
					histogram[lineID] = 1;
				} else {
					histogram[lineID]++;
				}
			}
		}
		
		// if any of the line IDs are zero, the ordering is unrecoverable since the ordering must start with 1
		if (this.isIndex(histogram[0]) && histogram[0] > 0) return false;
		
		// second pass: if any index is not represented exactly once, the ordering is unrecoverable
		for (i = 1; i < histogram.length; i++) {
			if (histogram[i] !== 1) return false;
		}
		
		return true;
	}

	confirmAssignOrdering(data) {
		if (this.props.order) {
			if (this.isRecoverableOrdering()) {
				// no need to confirm since user can undo it
				this.assignOrdering(data);
			} else {
				// confirm since user can't undo it
				this.showOKCancel('Confirm Reordering', "Previous ordering won't be recoverable. Proceed?", (okClicked) => {
					if (okClicked) this.assignOrdering(data);
				});
			}
		}
	}
	
	assignOrdering(new_po_details) {
		var self = this;
				
		// assign new ordering
		var newOrdering = 1;
		for (var i = 0; i < new_po_details.length; i++) {
			// if item is not being deleted
			if (!(+new_po_details[i].LINE_STATUS === 0)) {
				this.setByAccessor(new_po_details[i], this.props.order, newOrdering++);
			}
		}
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Stores/OrderPODetails',
			overlay: true,
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				po_details: new_po_details
			}
		}).done(function (data) {
			if (data.Success) {
				var i;
				
				// create map of line IDs to row IDs
				var rowid_map = [];
				for (i = 0; i < data.Data.po_details.length; i++) {
					rowid_map[+data.Data.po_details[i].LINE_ID] = data.Data.po_details.ROWID;
				}
				
				// remove any deleted rows from data and add any new row IDs
				for (i = new_po_details.length - 1; i >= 0; i--) {
					if (+new_po_details[i].LINE_STATUS === 0) {
						new_po_details.splice(i, 1);
					} else if (self.isEmpty(new_po_details[i].ROWID)) {
						new_po_details[i].ROWID = rowid_map[new_po_details[i].LINE_ID];
					}
				}
				self.props.onChange(new_po_details);
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	moveRecordUp(idx) {
		var data = this.copyDataWithFiles();
		var temp = data[idx];
		data[idx] = data[idx - 1];
		data[idx - 1] = temp;
		this.confirmAssignOrdering(data);
	}

	moveRecordDown(idx) {
		var data = this.copyDataWithFiles();
		var temp = data[idx];
		data[idx] = data[idx + 1];
		data[idx + 1] = temp;
		this.confirmAssignOrdering(data);
	}
	
	deleteRecord(idx) {
		this.showOKCancel('Confirm Delete', 'Are you sure you want to delete this record?', (okClicked) => {
			if (okClicked) {
				var data = this.copyDataWithFiles();
				data[idx].LINE_STATUS = 0;
				this.confirmAssignOrdering(data);
			}
		});
	}

	addRecord(idx) {
		var data = this.copyDataWithFiles();
		data.splice(idx, 0, this.copyObject(this.props.newRecord));
		this.confirmAssignOrdering(data);
	}
	
	saveRecord(idx) {
		var self = this;
		
		var po_detail = this.copyObject(this.props.data[idx]);
		po_detail.DATE_RECEIVED = this.dateTimeToMVC(this.getDate(po_detail.DATE_RECEIVED));
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Stores/ReceivePODetails',
			overlay: true,
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				po_detail: po_detail
			}
		}).done(function (data) {
			if (data.Success) {
				self.props.ReloadPO(idx);
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	copyDataWithFiles() {
		var dataCopy = this.copyObject(this.props.data);
		
		for (var d = 0; d < dataCopy.length; d++) {
			for (var c = 0; c < this.props.editColumns.length; c++) {
				if (this.props.editColumns[c].type && this.props.editColumns[c].type === 'file') {
					var file = this.getByAccessor(this.props.data[d], this.props.editColumns[c].accessor);
					this.setByAccessor(dataCopy[d], this.props.editColumns[c].accessor, file);
				}
			}
		}
		
		return dataCopy;
	}
	
	onChange(idx, accessor, value) {
		var data = this.copyDataWithFiles();
		data[idx].UNSAVED_CHANGES = true;
		if (Array.isArray(accessor)) {
			for (var a = 0; a < accessor.length; a++) {
				this.setByAccessor(data[idx], accessor[a], value[a]);
			}
		} else {
			this.setByAccessor(data[idx], accessor, value);
		}
		if (this.props.onChangeInternal) this.props.onChangeInternal(data[idx], accessor, value);
		this.props.onChange(data);
	}

	render() {
	  // calculate totals
	  var sub_total = 0;
	  var grand_total = 0;
	  for (var i = 0; i < this.props.data.length; i++) {
		sub_total += this.getNumeric(this.props.data[i].SUB_TOTAL, 0);
		grand_total += this.getNumeric(this.props.data[i].TOTAL, 0);
	  }
		
	  if (this.props.editable) {
		return(<Container fluid>
			{this.props.title && <Row>
				<Col>
					<div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>{this.props.title}</div>
				</Col>
			</Row>}
			<table className='table-po-detail'>
			  <tbody>
			  <tr style={labelcellLeft}>
				<td>Stock <br />
				  Number</td>
				<td>Catalog <br />
				  Number</td>
				<td>Description</td> 
			   <td>Ord <br />
				 Qty</td>
			   <td>Rec'd<br />
				 Qty</td>
			   <td>Add'l<br />Qty <br />
				 Rec'd</td>
			   <td>Date Rec'd</td>
			   <td>Qty on Order</td>
			   <td>Unit Size</td>
			   <td>Unit <br />
				 Price</td>
			   <td>Sub <br />
				 Total</td>
			   <td>Tax Total</td>
			   <td>Shipping</td>
			   <td>Handling</td>
			   <td>Total</td>
			   <td>Action</td>   
			  </tr>
			{this.props.data.map((line, idx) => { 
				
				let floppyStyle = { cursor: 'pointer', margin: '4px 2px 4px 4px' };
				if (line.UNSAVED_CHANGES) floppyStyle.backgroundColor = 'yellow';
								
				return (
					<React.Fragment key={idx}>
					  <tr>
						<td colSpan="16">
							<strong>{line.LINE_ID}</strong>&nbsp;&nbsp;
							{idx > 0 && <ArrowUpSquare style={{ cursor: 'pointer', margin: '4px' }} onClick={() => this.moveRecordUp(idx)} />}
							{idx < this.props.data.length - 1 && <ArrowDownSquare style={{ cursor: 'pointer', margin: '4px' }} onClick={() => this.moveRecordDown(idx)} />}
							{!this.isEmpty(line.STOCK_NUMBER) && <Floppy style={floppyStyle} onClick={() => this.saveRecord(idx)} />}
							{<XSquare style={{ cursor: 'pointer', margin: '4px 2px 4px 5px' }} onClick={() => this.deleteRecord(idx)} />}
							<PlusSquare style={{ cursor: 'pointer', margin: '4px 3px 4px 6px' }} onClick={() => this.addRecord(idx)} />
						</td>
					  </tr>
					  <tr style={fieldcellposting}>
						<td><Link to={'/Stores/Catalog/' + line.ITEM_ID}>{line.STOCK_NUMBER}</Link></td>
						<td>{line.CAT_NUMBER}</td>
						<td>{line.DESCRIPTION}&nbsp;</td>
						<td>{line.ORD_QTY}</td>
						<td>{(this.getNumeric(line.REC_QTY, 0) === 0 && !line.REC_ALL) ? <input name={'NEW_REC_QTY_' + idx} type="text" size="5" maxLength="5" validate="float" message="Please enter the quantity received."
							value={line.NEW_REC_QTY} onChange={(event) => this.onChange(idx, 'NEW_REC_QTY', event.target.value)} /> : <>{line.REC_ALL ? line.NEW_REC_QTY : line.REC_QTY}</>}</td>
						<td>{(this.getNumeric(line.REC_QTY, 0) !== 0) ? <input name={'ADD_REC_QTY_' + idx} type="text" size="5" maxLength="5" validate="float" 
							message="Please enter the additional quantity received." value={line.ADD_REC_QTY} onChange={(event) => this.onChange(idx, 'ADD_REC_QTY', event.target.value)} /> : <>&nbsp;</>}</td>
						<td><DatePicker
							selected={this.getDate(this.getByAccessor(line, 'DATE_RECEIVED'))}
							onChange={(date) => this.onChange(idx, 'DATE_RECEIVED', date === null ? null : this.dateTimeToMVC(date))}
							dateFormat='MM/dd/yy'
						  /></td>
						<td><input name={'ON_ORDER_' + idx} type="text" size="5" maxLength="5" validate="float" message="Please enter the quantity on order."
							value={line.ON_ORDER} onChange={(event) => this.onChange(idx, 'ON_ORDER', event.target.value)} /></td>
						<td>{line.UNIT_SIZE}</td>
						<td>{line.UNIT_PRICE}</td>
						<td>{line.SUB_TOTAL}</td>
						<td>{line.TAX}</td>
						<td>{line.SHIPPING}</td>
						<td>{line.HANDLING}</td>
						<td>{line.TOTAL}</td>
						<td rowSpan="3">{this.isEmpty(line.ITEM_ID) ? <>No Stock Number</> : this.getNumeric(line.REC_QTY, 0) === 0 &&  
							<><input type="checkbox" name={'REC_ALL_' + idx} value="REC_ALL" checked={line.REC_ALL} onChange={(event) => this.onChange(idx, 'REC_ALL', !line.REC_ALL)} /> FULL QTY</>}
						</td>  
					  </tr>
					  <tr>
						<td style={labelcellLeft}>Comments</td>
						<td colSpan="3" style={fieldcellposting}><textarea rows="3" name={'LINE_COMMENT_' + idx} message="Please enter a comment before deleting a line." 
							value={line.LINE_COMMENT} cols="50" maxlength="250" onChange={(event) => this.onChange(idx, 'LINE_COMMENT', event.target.value)} /></td>
						<td rowSpan="2" style={labelcellLeft}>Current Sale Price</td>
						<td rowSpan="2" style={fieldcellposting}>{line.UNIT_COST}</td>
						<td rowSpan="2" style={labelcellLeft}>Update Price*</td>
						<td colSpan="8" rowSpan="2" style={fieldcellposting}><input type="radio" name={'NEW_PRICE_' + idx} value='CALC_PRICE' checked={!line.NEW_PRICE}
							onChange={(event) => this.onChange(idx, 'NEW_PRICE', !line.NEW_PRICE)} /> {line.CALC_PRICE} (Vendor Price+ Tax+Markup) <br />
						  <input type="radio" name={'NEW_PRICE_' + idx} value='CUSTOM_PRICE' checked={line.NEW_PRICE} onChange={(event) => this.onChange(idx, 'NEW_PRICE', !line.NEW_PRICE)} />
						  <input name={'CUSTOM_PRICE_' + idx} type="text" id={'CUSTOM_PRICE_' + idx} size="10" maxlength="10" value={line.CUSTOM_PRICE} onChange={(event) => this.onChange(idx, 'CUSTOM_PRICE', event.target.value)} />
						  (Custom Price) </td>
					  </tr>
					  <tr>
						<td colSpan="3" style={labelcellLeft}>{!this.isEmpty(line.DATE_MODIF) && <>Last updated {line.DATE_MODIF} by {line.MODIF_BY}</>}</td>
					  </tr>					  
					</React.Fragment>
				);
			})}
			<tr>
			  <td colSpan="16">
			    <PlusSquare style={{ cursor: 'pointer', margin: '4px' }} onClick={() => this.addRecord(this.props.data.length)} />
			  </td>
			</tr>
			<tr>
			  <td colSpan="15" align="right">
				Sub Total
			  </td>
			  <td align="right">
			    {sub_total.toFixed(2)}
			  </td>
			</tr>
			<tr>
			  <td colSpan="15" align="right">
			    <span style={{ fontWeight: 'bold' }}>Total</span>
			  </td>
			  <td align="right">
			    <span style={{ fontWeight: 'bold' }}>{grand_total.toFixed(2)}</span>
			  </td>
			</tr>
			</tbody>
			</table>
		</Container>);
	  } else {
		  return (<Container fluid>
			{this.props.title && <Row>
				<Col>
					<div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>{this.props.title}</div>
				</Col>
			</Row>}
			<ChemTable renderKey={this.props.renderKey} parent={this} data={this.props.data} columns={this.props.tableColumns} name={this.props.tableName} />
			<table className='table-po-detail' width='100%'>
			  <tbody>
				<tr>
				  <td width='90%' align="right">
					Sub Total
				  </td>
				  <td align="right">
					{sub_total.toFixed(2)}
				  </td>
				</tr>
				<tr>
				  <td width='10%' align="right">
					<span style={{ fontWeight: 'bold' }}>Total</span>
				  </td>
				  <td align="right">
					<span style={{ fontWeight: 'bold' }}>{grand_total.toFixed(2)}</span>
				  </td>
				</tr>
			  </tbody>
			</table>
		  </Container>);
	  }
	}
}



const labelcellLeft = {
	font: '11px Verdana, Geneva, Arial, Helvetica, sans-serif',
	backgroundColor: '#F2F7FB',
	textAlign: 'left'    
};

const fieldcellposting = {
	font: '12px Verdana, Geneva, Arial, Helvetica, sans-serif',    
	backgroundColor: '#F2F7FB',
	marginRight: '0px'
};

export default ReceiveDetail;
