import React from 'react';
import ChemComponent from '../ChemComponent';
import ChemTable from '../ChemTable';
import MultiColumn from '../MultiColumn';
import DatePicker from 'react-datepicker';
import { Container, Row, Col } from 'react-bootstrap';
import { ArrowUpSquare, ArrowDownSquare, Floppy, PlusSquare, XSquare } from 'react-bootstrap-icons';

class PODetail extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			catalog: []
		};
	}
	
	componentDidMount() {
		this.loadCatalog();
	}
	
	componentDidUpdate(prevProps) {
		// if the vendor has changed
		if (this.props.newRecord.VENDOR_ID !== prevProps.newRecord.VENDOR_ID) {
			// reload the catalog
			this.loadCatalog();
		}
	}
	
	loadCatalog() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Stores/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'STOCK_CAT_DESC_VW',
				search: { Operator: 'or', Children: [
					{ Attribute: 'VENDOR_ID', Operator: '=', LongValue: this.props.newRecord.VENDOR_ID },
					{ Attribute: 'VENDOR_ID', Operator: 'is null' }
				]},
				order: 'STOCK_NO',
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				var catalog = [];
				for (var i = 0; i < data.Data.length; i++) {
					catalog.push({
						value: data.Data[i].ITEM_ID,
						label: [data.Data[i].STOCK_NO, data.Data[i].CATALOG_NO, data.Data[i].DESCRIPTION, data.Data[i].UNIT_COST]
					});
				}
				self.mergeState({ catalog: catalog });
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	isRecoverableOrdering() {
		var i;
		var histogram = [];
		
		// first pass: make histogram of all valid line ID's
		for (i = 0; i < this.props.data.length; i++) {
			var lineID = this.props.data[i].LINE_ID;
			if (this.isIndex(lineID)) {
				if (lineID >= histogram.length || !this.isIndex(histogram[lineID])) {
					histogram[lineID] = 1;
				} else {
					histogram[lineID]++;
				}
			}
		}
		
		// if any of the line IDs are zero, the ordering is unrecoverable since the ordering must start with 1
		if (this.isIndex(histogram[0]) && histogram[0] > 0) return false;
		
		// second pass: if any index is not represented exactly once, the ordering is unrecoverable
		for (i = 1; i < histogram.length; i++) {
			if (histogram[i] !== 1) return false;
		}
		
		return true;
	}

	confirmAssignOrdering(data) {
		if (this.props.order) {
			if (this.isRecoverableOrdering()) {
				// no need to confirm since user can undo it
				this.assignOrdering(data);
			} else {
				// confirm since user can't undo it
				this.showOKCancel('Confirm Reordering', "Previous ordering won't be recoverable. Proceed?", (okClicked) => {
					if (okClicked) this.assignOrdering(data);
				});
			}
		}
	}
	
	assignOrdering(new_po_details) {
		var self = this;
				
		// assign new ordering
		var newOrdering = 1;
		for (var i = 0; i < new_po_details.length; i++) {
			// if item is not being deleted
			if (!(+new_po_details[i].LINE_STATUS === 0)) {
				this.setByAccessor(new_po_details[i], this.props.order, newOrdering++);
			}
		}
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Stores/OrderPODetails',
			overlay: true,
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				po_details: new_po_details
			}
		}).done(function (data) {
			if (data.Success) {
				var i;
				
				// create map of line IDs to row IDs
				var rowid_map = [];
				for (i = 0; i < data.Data.po_details.length; i++) {
					rowid_map[+data.Data.po_details[i].LINE_ID] = data.Data.po_details[i].ROWID;
				}
				
				// remove any deleted rows from data and add any new row IDs
				for (i = new_po_details.length - 1; i >= 0; i--) {
					if (+new_po_details[i].LINE_STATUS === 0) {
						new_po_details.splice(i, 1);
					} else if (self.isEmpty(new_po_details[i].ROWID)) {
						new_po_details[i].ROWID = rowid_map[+new_po_details[i].LINE_ID];
					}
				}
				self.props.onChange(new_po_details);
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	moveRecordUp(idx) {
		var data = this.copyDataWithFiles();
		var temp = data[idx];
		data[idx] = data[idx - 1];
		data[idx - 1] = temp;
		this.confirmAssignOrdering(data);
	}

	moveRecordDown(idx) {
		var data = this.copyDataWithFiles();
		var temp = data[idx];
		data[idx] = data[idx + 1];
		data[idx + 1] = temp;
		this.confirmAssignOrdering(data);
	}
	
	deleteRecord(idx) {
		this.showOKCancel('Confirm Delete', 'Are you sure you want to delete this record?', (okClicked) => {
			if (okClicked) {
				var data = this.copyDataWithFiles();
				data[idx].LINE_STATUS = 0;
				this.confirmAssignOrdering(data);
			}
		});
	}

	addRecord(idx) {
		var data = this.copyDataWithFiles();
		data.splice(idx, 0, this.copyObject(this.props.newRecord));
		this.confirmAssignOrdering(data);
	}
	
	saveRecord(idx) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Stores/UpdatePODetails',
			overlay: true,
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				po_details: this.props.data[idx]
			}
		}).done(function (data) {
			if (data.Success) {
				var po_details = self.copyObject(self.props.data);
				po_details[idx].UNSAVED_CHANGES = false;
				self.props.onChange(po_details);
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	copyDataWithFiles() {
		var dataCopy = this.copyObject(this.props.data);
		
		for (var d = 0; d < dataCopy.length; d++) {
			for (var c = 0; c < this.props.editColumns.length; c++) {
				if (this.props.editColumns[c].type && this.props.editColumns[c].type === 'file') {
					var file = this.getByAccessor(this.props.data[d], this.props.editColumns[c].accessor);
					this.setByAccessor(dataCopy[d], this.props.editColumns[c].accessor, file);
				}
			}
		}
		
		return dataCopy;
	}
	
	onChange(idx, accessor, value) {
		var data = this.copyDataWithFiles();
		data[idx].UNSAVED_CHANGES = true;
		if (Array.isArray(accessor)) {
			for (var a = 0; a < accessor.length; a++) {
				this.setByAccessor(data[idx], accessor[a], value[a]);
			}
		} else {
			this.setByAccessor(data[idx], accessor, value);
		}
		if (this.props.onChangeInternal) this.props.onChangeInternal(data[idx], accessor, value);
		this.props.onChange(data);
	}

	render() {
	  // calculate totals
	  var sub_total = 0;
	  var grand_total = 0;
	  for (var i = 0; i < this.props.data.length; i++) {
		sub_total += this.getNumeric(this.props.data[i].SUB_TOTAL, 0);
		grand_total += this.getNumeric(this.props.data[i].TOTAL, 0);
	  }
		
	  if (this.props.editable) {
		return(<Container fluid>
			{this.props.title && <Row>
				<Col>
					<div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>{this.props.title}</div>
				</Col>
			</Row>}
			<table className='table-po-detail'>
			  <tbody>
			  <tr>
				<td align='center' colSpan={18}>ITEM LIST<br />
Click on the stock number to open item card. To delete a line, first enter a line comment then press <XSquare />.<br />
To change prices: enter the subtotal and leave the unit price blank, the unit price is calculated upon update submission. Or, enter the unit price and leave the subtotal blank, the subtotal is calculated upon update submission.</td>
			  </tr>
			  <tr style={labelcellLeft}>
				<td>Line</td>
				<td>Stock <br />
				  Number</td>
				<td>Ord Date</td>
				<td>Ord <br />
				 Qty</td>
				<td>Catalog <br />
				  Number</td>
				<td>Description</td>    
			    <td>Rec'd <br />
				 Qty</td>
			    <td>Date <br />
				 Rec'd</td>
			    <td>Qty on Order</td>
			    <td>Unit Size</td>
			    <td>Unit Price</td>
			    <td>Sub Total</td>
			    <td>Sub Total +Tax</td>
			    <td>Current<br />
				 Stores<br />
				 Pricing</td>
			    <td>Vendor Price<br />
				 + Tax<br />
				 +Markup</td>
			    <td>Shipping</td>
			    <td>Handling</td>
			    <td>Total</td>  
			  </tr>
			{this.props.data.map((line, idx) => { 
				// create default option for multicolumn selector
				var defaultOption = {
					value: line.ITEM_ID,
					label: [line.STOCK_NUMBER, line.CAT_NUMBER, line.DESCRIPTION]
				};
				
				let floppyStyle = { cursor: 'pointer', margin: '4px 2px 4px 4px' };
				if (line.UNSAVED_CHANGES) floppyStyle.backgroundColor = 'yellow';
								
				return (
					<React.Fragment key={idx}>
					  <tr>
						<td colSpan="18">
							<strong>{line.LINE_ID}</strong>&nbsp;&nbsp;
							{idx > 0 && <ArrowUpSquare style={{ cursor: 'pointer', margin: '4px' }} onClick={() => this.moveRecordUp(idx)} />}
							{idx < this.props.data.length - 1 && <ArrowDownSquare style={{ cursor: 'pointer', margin: '4px' }} onClick={() => this.moveRecordDown(idx)} />}
							{line.STATUS_ID < 5 && <Floppy style={floppyStyle} onClick={() => this.saveRecord(idx)} />}
							{<XSquare style={{ cursor: 'pointer', margin: '4px 2px 4px 5px' }} onClick={() => this.deleteRecord(idx)} />}
							<PlusSquare style={{ cursor: 'pointer', margin: '4px 3px 4px 6px' }} onClick={() => this.addRecord(idx)} />
						</td>
					  </tr>
					  <tr style={fieldcellposting}>
						<td rowSpan="2"><span style={comment_font}>Updated {line.DATE_MODIF} by {line.MODIF_BY}</span></td>
						<td>
							<MultiColumn defaultOption={defaultOption} options={this.state.catalog} as='STOCK_NUMBER'
							  onChange={(value, labels) => {
								var accessors = [colStockNo.accessor];
								for (var accessorIdx = 0; accessorIdx < colStockNo.labelAccessor.length; accessorIdx++) {
									accessors.push(colStockNo.labelAccessor[accessorIdx]);
								}
								var values = [value];
								for (var valueIdx = 0; valueIdx < labels.length; valueIdx++) {
									values.push(labels[valueIdx]);
								}
								this.onChange(idx, accessors, values);
							  }}
							/>
						</td>
						<td>
						  <DatePicker
							selected={this.getDate(this.getByAccessor(line, 'ORD_DATE'))}
							onChange={(date) => this.onChange(idx, 'ORD_DATE', date === null ? null : this.dateTimeToMVC(date))}
							dateFormat='MM/dd/yy'
						  />
						</td>
						<td><input name={'ORD_QTY_' + idx} type="text" size="5" maxLength="5" validate="float" required="yes" message="Please enter the quantity ordered."
							value={line.ORD_QTY} onChange={(event) => this.onChange(idx, 'ORD_QTY', event.target.value)} /></td>
						<td><input name={'CAT_NUMBER_' + idx} type="text" size="15" maxLength="25" required="yes" message="Please enter the catalog number."
							value={line.CAT_NUMBER} onChange={(event) => this.onChange(idx, 'CAT_NUMBER', event.target.value)} /></td>
						<td>{line.DESCRIPTION}&nbsp;</td>    
						<td>{line.REC_QTY}</td>
						<td>{line.DATE_RECEIVED}</td>
						<td>{line.ON_ORDER}</td>
						<td><input name={'UNIT_SIZE_' + idx} type="text" size="5" maxLength="8" required="yes" message="Please enter the unit size."
							value={line.UNIT_SIZE} onChange={(event) => this.onChange(idx, 'UNIT_SIZE', event.target.value)} /></td>
						<td><input name={'UNIT_PRICE_' + idx} type="text" size="5" maxLength="8" validate="float" value={line.UNIT_PRICE}
							onChange={(event) => this.onChange(idx, 'UNIT_PRICE', event.target.value)} /></td>
						<td><input name={'SUB_TOTAL_' + idx} type="text" size="5" maxLength="8" validate="float" value={line.SUB_TOTAL}
							onChange={(event) => this.onChange(idx, 'SUB_TOTAL', event.target.value)} /></td>
						<td>{line.TAX}</td>
						<td>{line.UNIT_COST}</td>
						<td>{line.CALC_PRICE}</td>
						<td><input name={'SHIPPING_' + idx} type="text" size="5" maxLength="8" validate="float" value={line.SHIPPING}
							onChange={(event) => this.onChange(idx, 'SHIPPING', event.target.value)} /></td>
						<td><input name={'HANDLING_' + idx} type="text" size="5" maxLength="8" validate="float" value={line.HANDLING}
							onChange={(event) => this.onChange(idx, 'HANDLING', event.target.value)} /></td>
						<td><input name={'TOTAL_' + idx} type="text" size="5" maxLength="8" validate="float" value={line.TOTAL}
							onChange={(event) => this.onChange(idx, 'TOTAL', event.target.value)} /></td>
					  </tr>
					  <tr>
						<td height="21" style={labelcellLeft}>Comments&nbsp;</td>
						<td height="21" colSpan="11" style={fieldcellposting}><input type="text" name={'LINE_COMMENT_' + idx} message="Please enter a comment before deleting a line."
							value={line.LINE_COMMENT} onChange={(event) => this.onChange(idx, 'LINE_COMMENT', event.target.value)} size="100" maxLength="250" /> 
						  &nbsp;<span style={comment_font}>(250 chars. max)</span></td>
						<td style={labelcellLeft}>Reference&nbsp;</td>
						<td colSpan="4" style={fieldcellposting}><input name={'INTER_REF_' + idx} type="text" id="inter_ref"
							value={line.INTER_REF} onChange={(event) => this.onChange(idx, 'INTER_REF', event.target.value)} size="40" maxLength="100" /></td>
					  </tr>
					</React.Fragment>
				);
			})}
			<tr>
			  <td colSpan="18">
			    <PlusSquare style={{ cursor: 'pointer', margin: '4px' }} onClick={() => this.addRecord(this.props.data.length)} />
			  </td>
			</tr>
			<tr>
			  <td colSpan="17" align="right">
				Sub Total
			  </td>
			  <td align="right">
			    {sub_total.toFixed(2)}
			  </td>
			</tr>
			<tr>
			  <td colSpan="17" align="right">
			    <span style={{ fontWeight: 'bold' }}>Total</span>
			  </td>
			  <td align="right">
			    <span style={{ fontWeight: 'bold' }}>{grand_total.toFixed(2)}</span>
			  </td>
			</tr>
			</tbody>
			</table>
		</Container>);
	  } else {
		  return (<Container fluid>
			{this.props.title && <Row>
				<Col>
					<div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>{this.props.title}</div>
				</Col>
			</Row>}
			<ChemTable renderKey={this.props.renderKey} parent={this} data={this.props.data} columns={this.props.tableColumns} name={this.props.tableName} />
			<table className='table-po-detail' width='100%'>
			  <tbody>
				<tr>
				  <td width='90%' align="right">
					Sub Total
				  </td>
				  <td align="right">
					{sub_total.toFixed(2)}
				  </td>
				</tr>
				<tr>
				  <td width='10%' align="right">
					<span style={{ fontWeight: 'bold' }}>Total</span>
				  </td>
				  <td align="right">
					<span style={{ fontWeight: 'bold' }}>{grand_total.toFixed(2)}</span>
				  </td>
				</tr>
			  </tbody>
			</table>
		  </Container>);
	  }
	}
}

const colStockNo = {
	accessor: 'ITEM_ID',
	labelAccessor: ['STOCK_NUMBER','CAT_NUMBER','DESCRIPTION','UNIT_COST']
};

const labelcellLeft = {
	font: '11px Verdana, Geneva, Arial, Helvetica, sans-serif',
	backgroundColor: '#F2F7FB',
	textAlign: 'left'    
};

const fieldcellposting = {
	font: '12px Verdana, Geneva, Arial, Helvetica, sans-serif',    
	backgroundColor: '#F2F7FB',
	marginRight: '0px'
};

const comment_font = {
	font: '9px Verdana, Geneva, Arial, Helvetica, sans-serif',
	fontStyle: 'italic',
};

export default PODetail;
