import React from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class CycleDate extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			cycleDate: {
				BILLING_ID: null,
				BEG_DATE: null,
				END_DATE: null,
				CUSTOM_FLAG: undefined,
				LAST_MODIF: null,
				MODIF_BY: '',
				CURRENT_FLAG: undefined,
				COMMENTS: '',
				BEG_FISCAL_YEAR: null,
				PRELIM_START: null,
				PRELIM_END: null,
				// calculated fields
				MODIFIED: '',
				BEG_MONTH: '',
				END_MONTH: '',
				END_FISCAL_YEAR: '',
				BILL_DATE: ''
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadCycleDate();
	}

	loadCycleDate(event) {
		var self = this;
		
		if (this.props.params.billingId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/CRS/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'BILLING_CYCLE',
					search: { Attribute: 'BILLING_ID', Operator: '=', LongValue: this.props.params.billingId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var cycleDate = data.Data[0];
						
						// convert flags
						cycleDate.CUSTOM_FLAG = cycleDate.CUSTOM_FLAG === 'Y' ? true : false;
						cycleDate.CURRENT_FLAG = cycleDate.CURRENT_FLAG === 'C' ? true : false;
						
						// convert dates
						cycleDate.BEG_DATE = self.dateTimeToMVC(self.getDate(cycleDate.BEG_DATE));
						cycleDate.END_DATE = self.dateTimeToMVC(self.getDate(cycleDate.END_DATE));
						cycleDate.PRELIM_START = self.dateTimeToMVC(self.getDate(cycleDate.PRELIM_START));
						cycleDate.PRELIM_END = self.dateTimeToMVC(self.getDate(cycleDate.PRELIM_END));
						cycleDate.BEG_FISCAL_YEAR = self.dateTimeToMVC(self.getDate(cycleDate.BEG_FISCAL_YEAR));
						cycleDate.LAST_MODIF = self.dateTimeToMVC(self.getDate(cycleDate.LAST_MODIF));
						
						// compose modified
						cycleDate.MODIFIED = self.composeChangeInfo(cycleDate.MODIF_BY, cycleDate.LAST_MODIF);
						
						self.calculateDates(['BEG_DATE', 'END_DATE', 'BEG_FISCAL_YEAR'], cycleDate, (calculatedDates) => {
							cycleDate.BEG_MONTH = calculatedDates.BEG_MONTH;
							cycleDate.END_MONTH = calculatedDates.END_MONTH;
							cycleDate.END_FISCAL_YEAR = calculatedDates.END_FISCAL_YEAR;
							cycleDate.BILL_DATE = calculatedDates.BILL_DATE;
							
							self.mergeState({
								cycleDate: cycleDate,
								renderKey: self.state.renderKey + 1
							}, () => {
								if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
							});
						});
					} else {
						self.showAlert('Cycle Date Error', data.Data.length + ' Cycle Dates found for ID ' + self.props.params.billingId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				cycleDate: {
					BILLING_ID: '(new)',
					CUSTOM_FLAG: false,
					CURRENT_FLAG: true
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var self = this;
		var cycleDate = this.copyObject(this.state.cycleDate);
		this.setByAccessor(cycleDate, accessor, value);
		var cycleDateChanged = {};
		this.setByAccessor(cycleDateChanged, accessor, value);
		var accessors = [];
		accessors.push(accessor);
		this.calculateDates(accessors, cycleDateChanged, (calculatedDates) => {
			if (calculatedDates.BEG_MONTH !== undefined) cycleDate.BEG_MONTH = calculatedDates.BEG_MONTH;
			if (calculatedDates.END_MONTH !== undefined) cycleDate.END_MONTH = calculatedDates.END_MONTH;
			if (calculatedDates.END_FISCAL_YEAR !== undefined) cycleDate.END_FISCAL_YEAR = calculatedDates.END_FISCAL_YEAR;
			if (calculatedDates.BILL_DATE !== undefined) cycleDate.BILL_DATE = calculatedDates.BILL_DATE;
			self.mergeState({ cycleDate: cycleDate, renderKey: self.state.renderKey + 1 });
		});
	}
	
	calculateDates(accessors, cycleDateChanged, callback) {
		var calculatedDates = {};
		var year, month, day;
		if (accessors.includes('BEG_DATE')) {
			// if there is a beginning date
			if (!this.isEmpty(cycleDateChanged.BEG_DATE)) {
				// calculate first and last day of month
				var begDate = this.getDate(cycleDateChanged.BEG_DATE);
				year = begDate.getFullYear();
				month = begDate.getMonth();
				calculatedDates.BEG_MONTH = this.dateToString(new Date(year, month, 1));
				calculatedDates.END_MONTH = this.dateToString(new Date(year, month + 1, 0));
			} else {
				// beginning date is empty, so month begin/end are also empty
				calculatedDates.BEG_MONTH = '';
				calculatedDates.END_MONTH = '';
			}
		}
		if (accessors.includes('BEG_FISCAL_YEAR')) {
			// if there is a beginning date
			if (!this.isEmpty(cycleDateChanged.BEG_FISCAL_YEAR)) {
				// calculate end of fiscal year
				var begFiscalYear = this.getDate(cycleDateChanged.BEG_FISCAL_YEAR);
				year = begFiscalYear.getFullYear();
				month = begFiscalYear.getMonth();
				day = begFiscalYear.getDate();
				calculatedDates.END_FISCAL_YEAR = this.dateToString(new Date(year + 1, month, day - 1));
			} else {
				// beginning date is empty, so fiscal year end is also empty
				calculatedDates.END_FISCAL_YEAR = '';
			}
		}
		if (accessors.includes('END_DATE')) {
			if (!this.isEmpty(cycleDateChanged.END_DATE)) {
				calculatedDates.BILL_DATE = this.dateToString(this.getDate(cycleDateChanged.END_DATE));
			} else {
				calculatedDates.BILL_DATE = '';
			}
		}
		
		callback(calculatedDates);
	}
	
	validateCycleDate(event, columns, data) {
		return this.validate(columns, data);
	}
		
	onSubmit(event) {
		var self = this;		
		
		// make copy of data
		var cycleDate = this.copyObject(this.state.cycleDate);

		// if this is a new user
		if (!this.props.params.billingId) {
			// set ID sequence
			cycleDate.BILLING_ID = 'billing_id_seq.nextval';
		}

		// set modified info
		cycleDate.LAST_MODIF = this.dateTimeToMVC(new Date());
		cycleDate.MODIF_BY = this.props.user.name;

		// convert flags
		cycleDate.CUSTOM_FLAG = cycleDate.CUSTOM_FLAG ? 'Y' : null;
		cycleDate.CURRENT_FLAG = cycleDate.CURRENT_FLAG ? 'C' : null;
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/CRS/UpdateCycle',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, cycle: cycleDate }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Cycle Date <Link to={'/CRS/CycleDates/' + (self.props.params.billingId || data.ID)}>{self.props.params.billingId || data.ID}</Link> updated.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Cycle Date {this.state.cycleDate.BILLING_ID}</div>
				<ChemEdit parent={this} columns={cycleDateColumns} data={this.state.cycleDate} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}
				   validate={(event, columns, data) => this.validateCycleDate(event, columns, data)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const cycleDateColumns = [
	{ Header: 'Beginning Date', accessor: 'BEG_DATE', type: 'date', row: 0, col: 0, required: true },
	{ Header: 'End Date', accessor: 'END_DATE', type: 'date', row: 0, col: 1, required: true },
	{ Header: 'Prelim Start', accessor: 'PRELIM_START', type: 'date', row: 1, col: 0 },
	{ Header: 'Prelim Closes', accessor: 'PRELIM_END', type: 'date', row: 1, col: 1 },
	{ Header: 'Month Begin', accessor: 'BEG_MONTH', row: 2, col: 0, editable: false },
	{ Header: 'Month End', accessor: 'END_MONTH', row: 2, col: 1, editable: false },
	{ Header: 'Fiscal Year Start', accessor: 'BEG_FISCAL_YEAR', type: 'date', row: 3, col: 0, required: true },
	{ Header: 'Fiscal Year End', accessor: 'END_FISCAL_YEAR', row: 3, col: 1, editable: false },
	{ Header: 'Current', accessor: 'CURRENT_FLAG', type: 'checkbox', row: 4, col: 0 },
	{ Header: 'Custom', accessor: 'CUSTOM_FLAG', type: 'checkbox', row: 4, col: 1 },
	{ Header: 'Billed Date', accessor: 'BILL_DATE', row: 5, col: 0, editable: false },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 5, col: 1 },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 6, col: 0, editable: false }
];

export default withRouter(CycleDate);
