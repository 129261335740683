import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';
import { withRouter } from '../withRouter';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';

class AdHocReport extends ChemComponent {
	constructor(props) {
		super(props);

		this.state = {
			search: {
				DATE_PLACED_FROM: null,
				DATE_PLACED_TO: null,
				DATE_RECEIVED_FROM: null,
				DATE_RECEIVED_TO: null,
				STATUS_ID: null,
				STOCK_NO_1: '',
				STOCK_NO_2: '',
				STOCK_NO_3: '',
				VENDOR_ID: [],
				DESCRIPTION: '',
				LOCATION: '',
				CATEGORY: '',
				SALE_DATE_FROM: null,
				SALE_DATE_TO: null,
				RETIRE_FLAG: false
			},
			adHocList: this.copyTable(adHocList),
			searchCount: 0,
			renderKey: 0
		};
	}

	viewItem(itemId) {
		this.props.setItemEditable(false);
		this.props.navigate('/Stores/Catalog/' + itemId);		
	}

	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newAdHocList = this.copyTable(adHocList);
		var search = this.copyObject(this.state.search);
		newAdHocList.ajax.data = { search: search };
		this.removeUnusedColumns(search, newAdHocList.columns);
		newAdHocList.hold = false;
		this.mergeState({ 
			adHocList: newAdHocList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	removeUnusedColumns(search, columns) {
		var saleDate = !this.isEmpty(search.SALE_DATE_FROM) || !this.isEmpty(search.SALE_DATE_TO);
		var received = !this.isEmpty(search.DATE_PLACED_FROM) || !this.isEmpty(search.DATE_PLACED_TO) ||
			!this.isEmpty(search.DATE_RECEIVED_FROM) || !this.isEmpty(search.DATE_RECEIVED_TO) ||
			!this.isEmpty(search.STATUS_ID) || search.VENDOR_ID.length > 0;
		for (var i = columns.length - 1; i >= 0; i--) {
			if ((columns[i].includedBy === 'SALE_DATE' && !saleDate) ||
			    (columns[i].includedBy === 'RECEIVED' && !received)) {
				columns.splice(i, 1);
			}
		}
		return columns;
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			Action: 'AdHoc',
			columns: this.removeUnusedColumns(this.state.search, this.copyColumns(exportColumns)),
			search: this.state.search
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>AdHoc Report</span>
						<ChemEdit parent={this} user={this.props.user} columns={adHocSearch} data={this.state.search} autoSubmit={true}
						  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)}
						  onSubmit={(event) => this.onSubmitSearch(event)} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Stores/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Stores/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='Stores_AdHoc' table={this.state.adHocList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='415px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const adHocSearch = [
	{ Header: 'PO Placed From', accessor: 'DATE_PLACED_FROM', type: 'date', row: 0, col: 0 },
	{ Header: 'PO Placed To', accessor: 'DATE_PLACED_TO', type: 'date', row: 0, col: 1 },
	{ Header: 'PO Received From', accessor: 'DATE_RECEIVED_FROM', type: 'date', row: 0, col: 2 },
	{ Header: 'PO Received To', accessor: 'DATE_RECEIVED_TO', type: 'date', row: 0, col: 3 },
	{ Header: 'PO Status', accessor: 'STATUS_ID', type: 'select', row: 1, col: 0,
		options: { value: 'STATUS_ID', label: 'STATUS_NAME', entity: 'STORES.STATUS_SELECT_VW' }},
	{ Header: 'Stock Number 1', accessor: 'STOCK_NO_1', row: 1, col: 1 },
	{ Header: 'Stock Number 2', accessor: 'STOCK_NO_2', row: 1, col: 2 },
	{ Header: 'Stock Number 3', accessor: 'STOCK_NO_3', row: 1, col: 3 },
	{ Header: 'Vendors', accessor: 'VENDOR_ID', type: 'list', row: 2, col: 0,
		options: { value: 'VENDOR_ID', label: 'VENDORNAME', entity: 'STORES.PO_VENDOR', order: 'VENDORNAME' ,
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Description', accessor: 'DESCRIPTION', row: 2, col: 1 },
	{ Header: 'Location', accessor: 'LOCATION', row: 2, col: 2 },
	{ Header: 'Category', accessor: 'CATEGORY', row: 2, col: 3 },
	{ Header: 'Sale Date From', accessor: 'SALE_DATE_FROM', type: 'date', row: 3, col: 0 },
	{ Header: 'Sale Date To', accessor: 'SALE_DATE_TO', type: 'date', row: 3, col: 1 },
	{ Header: 'Show Retired Items', accessor: 'RETIRE_FLAG', type: 'checkbox', row: 3, col: 2 }
];

const adHocList = {
	ajax: { 
		url: '/Stores/AdHocReport',
		data: null,
		accessor: 'Data'
	},
	pageSize: 20,
	hold: true,
	columns: [
		{ Header: '', accessor: 'ITEM_ID', show: false },
		{ Header: 'Stock Number', accessor: 'STOCK_NUMBER', width: 100,
			Cell: props => <HashLink onClick={() => props.parent.viewItem(props.row.values.ITEM_ID)}>{props.value}</HashLink> },
		{ Header: 'Description', accessor: 'DESCRIPTION', width: 350, },
		{ Header: 'Current Store Price', accessor: 'CURRENT_SALE_PRICE', width: 70, align: 'right' },
		{ Header: 'Re-order Qty', accessor: 'REORDER_QTY', width: 80, align: 'right' },
		{ Header: 'Minimum Qty', accessor: 'MINIMUM_QTY', width: 80, align: 'right' },
		{ Header: 'Beg Balance', accessor: 'BEG_BALANCE', width: 70, align: 'right' },
		{ Header: 'Inventory Date', accessor: 'INVENTORY_DATE', width: 100 },
		{ Header: 'Retire Flag', accessor: 'RETIRE_FLAG', width: 60 },
		{ Header: 'Comments', accessor: 'COMMENTS', width: 350 },
		{ Header: 'Current UOM', accessor: 'UNIT_OF_MEASURE', width: 80, align: 'right' },
		{ Header: 'Qty on Hand', accessor: 'CURRENT_STOCK', width: 70, align: 'right' },
		{ Header: 'Conversion', accessor: 'CONVERSION', width: 100, align: 'right' },
		{ Header: 'Location', accessor: 'LOCATION', width: 100 },
		{ Header: 'Category', accessor: 'ACCOUNT_DESC', width: 250 },
		{ Header: 'Sale UOM', accessor: 'TRANS_UOM', includedBy: 'SALE_DATE', width: 60 },
		{ Header: 'Sale Qty', accessor: 'QTY_SOLD', includedBy: 'SALE_DATE', width: 60, align: 'right' },
		{ Header: 'Sale Price', accessor: 'PRICE_SOLD', includedBy: 'SALE_DATE', width: 70, align: 'right' },
		{ Header: 'Sale Date', accessor: 'TRANS_DATE', includedBy: 'SALE_DATE', width: 100 },
		{ Header: 'Vendor Price', accessor: 'VENDOR_PRICE', includedBy: 'RECEIVED', width: 80, align: 'right' },
		{ Header: 'Vendor UOM', accessor: 'VENDOR_UOM', includedBy: 'RECEIVED', width: 80 },
		{ Header: 'Cat Number', accessor: 'CAT_NUMBER', includedBy: 'RECEIVED', width: 200 },
		{ Header: 'Tax', accessor: 'TAX', includedBy: 'RECEIVED', width: 70, align: 'right' },
		{ Header: 'Vendor Name', accessor: 'VENDORNAME', includedBy: 'RECEIVED', width: 250 },
		{ Header: 'Date Placed', accessor: 'DATE_PLACED', includedBy: 'RECEIVED', width: 100 },
		{ Header: 'Date Received', accessor: 'DATE_RECEIVED', includedBy: 'RECEIVED', width: 100 },
		{ Header: 'Rec Qty', accessor: 'REC_QTY', includedBy: 'RECEIVED', width: 70, align: 'right' }
	]
};

const exportColumns = [
	{ Header: 'Stock Number', accessor: 'STOCK_NUMBER', width: 20 },
	{ Header: 'Description', accessor: 'DESCRIPTION', width: 70, },
	{ Header: 'Current Store Price', accessor: 'CURRENT_SALE_PRICE', width: 14 },
	{ Header: 'Re-order Qty', accessor: 'REORDER_QTY', width: 16 },
	{ Header: 'Minimum Qty', accessor: 'MINIMUM_QTY', width: 16 },
	{ Header: 'Beg Balance', accessor: 'BEG_BALANCE', width: 14 },
	{ Header: 'Inventory Date', accessor: 'INVENTORY_DATE', width: 20 },
	{ Header: 'Retire Flag', accessor: 'RETIRE_FLAG', width: 12 },
	{ Header: 'Comments', accessor: 'COMMENTS', width: 70 },
	{ Header: 'Current UOM', accessor: 'UNIT_OF_MEASURE', width: 16 },
	{ Header: 'Qty on Hand', accessor: 'CURRENT_STOCK', width: 14 },
	{ Header: 'Conversion', accessor: 'CONVERSION', width: 20 },
	{ Header: 'Location', accessor: 'LOCATION', width: 20 },
	{ Header: 'Category', accessor: 'ACCOUNT_DESC', width: 50 },
	{ Header: 'Sale UOM', accessor: 'TRANS_UOM', includedBy: 'SALE_DATE', width: 12 },
	{ Header: 'Sale Qty', accessor: 'QTY_SOLD', includedBy: 'SALE_DATE', width: 12 },
	{ Header: 'Sale Price', accessor: 'PRICE_SOLD', includedBy: 'SALE_DATE', width: 14 },
	{ Header: 'Sale Date', accessor: 'TRANS_DATE', includedBy: 'SALE_DATE', width: 20 },
	{ Header: 'Vendor Price', accessor: 'VENDOR_PRICE', includedBy: 'RECEIVED', width: 16 },
	{ Header: 'Vendor UOM', accessor: 'VENDOR_UOM', includedBy: 'RECEIVED', width: 16 },
	{ Header: 'Cat Number', accessor: 'CAT_NUMBER', includedBy: 'RECEIVED', width: 40 },
	{ Header: 'Tax', accessor: 'TAX', includedBy: 'RECEIVED', width: 14 },
	{ Header: 'Vendor Name', accessor: 'VENDORNAME', includedBy: 'RECEIVED', width: 50 },
	{ Header: 'Date Placed', accessor: 'DATE_PLACED', includedBy: 'RECEIVED', width: 20 },
	{ Header: 'Date Received', accessor: 'DATE_RECEIVED', includedBy: 'RECEIVED', width: 20 },
	{ Header: 'Rec Qty', accessor: 'REC_QTY', includedBy: 'RECEIVED', width: 14 }
];

export default withRouter(AdHocReport);
