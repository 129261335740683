import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import Tabs from '../Tabs';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';


class CycleDates extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			DATE_SEARCH: null
		};
		
		// initialize cycle dates list
		var initialCycleDatesList = this.copyTable(cycleDatesList);
		initialCycleDatesList.query.search = this.constructSearch(search);
		
		// remove add/edit column if not authorized
		if (props.user.crs.userAccessID !== 1) {
			initialCycleDatesList.columns.splice(0, 1);
		}
		
		this.state = {
			renderKey: 1,
			cycleDatesList: initialCycleDatesList,
			search: search,
			searchCount: null,
			activeTab: 'Billing Cycles',
			dataRequest: {
				to: '',
				cc: '',
				subject: '',
				body: ''
			},
			prelimInfo: {
				to: '',
				cc: '',
				subject: '',
				body: ''
			},
			reportAvailability: {
				to: '',
				cc: '',
				subject: '',
				body: ''
			}
		};
	}
	
	componentDidMount() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/GetCycleEmails',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken }
		}).done(function (data) {
			if (data.Success) {
				self.mergeState({
					dataRequest: {
						to: data.DataRequest.To,
						cc: data.DataRequest.CC,
						subject: data.DataRequest.Subject,
						body: data.DataRequest.Body
					},
					prelimInfo: {
						to: data.PrelimInfo.To,
						cc: data.PrelimInfo.CC,
						subject: data.PrelimInfo.Subject,
						body: data.PrelimInfo.Body
					},
					reportAvailability: {
						to: data.ReportAvailability.To,
						cc: data.ReportAvailability.CC,
						subject: data.ReportAvailability.Subject,
						body: data.ReportAvailability.Body
					}
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newCycleDatesList = this.copyTable(cycleDatesList);
		newCycleDatesList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			cycleDatesList: newCycleDatesList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		if (this.isEmpty(data.DATE_SEARCH)) {
			return null;
		} else {
			return { Operator: 'and', Children: [
				{ Attribute: 'BEG_DATE_SEARCH', Operator: '<=', DateValue: this.dateTimeToMVC(this.getDate(data.DATE_SEARCH)) },
				{ Attribute: 'END_DATE_SEARCH', Operator: '>=', DateValue: this.dateTimeToMVC(this.getDate(data.DATE_SEARCH)) }
			]};		
		}
	}
	
	addCycleDate() {
		this.props.navigate('/CRS/CycleDates/new');
	}
	
	editCycleDate(billingId) {
		this.props.navigate('/CRS/CycleDates/' + billingId);
	}
	
	onClickTabItem(tab) {
		this.mergeState({ activeTab: tab });
	}
	
	onChangeDataRequest(accessor, value) {
		var dataRequest = this.copyObject(this.state.dataRequest);
		this.setByAccessor(dataRequest, accessor, value);
		this.mergeState({ dataRequest: dataRequest });
	}
	
	onSubmitDataRequest(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/SendCycleEmail',
			overlay: true,
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				to: this.state.dataRequest.to,
				cc: this.state.dataRequest.cc,
				subject: this.state.dataRequest.subject,
				body: this.state.dataRequest.body
			}
		}).done(function (data) {
			if (data.Success) {
				self.showConfirmation('Data Request email sent.');
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	onChangePrelimInfo(accessor, value) {
		var prelimInfo = this.copyObject(this.state.prelimInfo);
		this.setByAccessor(prelimInfo, accessor, value);
		this.mergeState({ prelimInfo: prelimInfo });
	}
	
	onSubmitPrelimInfo(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/SendCycleEmail',
			overlay: true,
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				to: this.state.prelimInfo.to,
				cc: this.state.prelimInfo.cc,
				subject: this.state.prelimInfo.subject,
				body: this.state.prelimInfo.body
			}
		}).done(function (data) {
			if (data.Success) {
				self.showConfirmation('Prelim Info email sent.');
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}

	onChangeReportAvailability(accessor, value) {
		var reportAvailability = this.copyObject(this.state.reportAvailability);
		this.setByAccessor(reportAvailability, accessor, value);
		this.mergeState({ reportAvailability: reportAvailability });
	}
	
	onSubmitReportAvailability(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/SendCycleEmail',
			overlay: true,
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				to: this.state.reportAvailability.to,
				cc: this.state.reportAvailability.cc,
				subject: this.state.reportAvailability.subject,
				body: this.state.reportAvailability.body
			}
		}).done(function (data) {
			if (data.Success) {
				self.showConfirmation('Report Availability email sent.');
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}

	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'CycleDates',
			columns: exportColumns,
			entity: 'RECHARGES.CYCLE_DATES_VW',
			search: this.constructSearch(this.state.search),
			order: 'BEG_DATE_SEARCH desc'
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Cycle Dates</span>
				    <Tabs onClickTabItem={(tab) => this.onClickTabItem(tab)}>
					  <div label='Billing Cycles'>
						<ChemEdit parent={this} columns={cycleDatesSearch} data={this.state.search} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
						  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
							<Col xs={4} style={{ marginTop: 8}}>
								{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
							</Col>
							<Col xs={1} style={{ marginTop: 6}}>
								<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
									{exportData.map((excelFormField, index) => {
										return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
									})}
									<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
									  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
									</OverlayTrigger>
								</form>
							</Col>
							<Col xs={1} style={{ marginTop: 6}}>
								<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
									{exportData.map((pdfFormField, index) => {
										return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
									})}
									<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
									  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
									</OverlayTrigger>
								</form>
							</Col>
							<Col xs={6}>
							  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
							</Col>
						  </Form.Group>
						</ChemEdit>
				      </div>
					  <div label='Email Data Request'>
						<ChemEdit parent={this} columns={emailColumns} data={this.state.dataRequest} renderKey={this.state.renderKey} labelColWidth={1} valueColWidth={11}
						  onChange={(accessor, value) => this.onChangeDataRequest(accessor, value)} onSubmit={(event) => this.onSubmitDataRequest(event)} {...this.props}>
						  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
							<Col xs={12}>
							  <Button id="sendDataRequest" variant="warning" type="submit" className="float-end">Send</Button>
							</Col>
						  </Form.Group>
						</ChemEdit>
					  </div>
					  <div label='Email Prelim Info'>
						<ChemEdit parent={this} columns={emailColumns} data={this.state.prelimInfo} renderKey={this.state.renderKey} labelColWidth={1} valueColWidth={11}
						  onChange={(accessor, value) => this.onChangePrelimInfo(accessor, value)} onSubmit={(event) => this.onSubmitPrelimInfo(event)} {...this.props}>
						  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
							<Col xs={12}>
							  <Button id="sendPrelimInfo" variant="warning" type="submit" className="float-end">Send</Button>
							</Col>
						  </Form.Group>
						</ChemEdit>
					  </div>
					  <div label='Email Report Availability'>
						<ChemEdit parent={this} columns={emailColumns} data={this.state.reportAvailability} renderKey={this.state.renderKey} labelColWidth={1} valueColWidth={11}
						  onChange={(accessor, value) => this.onChangeReportAvailability(accessor, value)} onSubmit={(event) => this.onSubmitReportAvailability(event)} {...this.props}>
						  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
							<Col xs={12}>
							  <Button id="sendReportAvailability" variant="warning" type="submit" className="float-end">Send</Button>
							</Col>
						  </Form.Group>
						</ChemEdit>
					  </div>
					</Tabs>
				  </Col>
				</Row>
				{this.state.activeTab === 'Billing Cycles' && <Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_CycleDates' table={this.state.cycleDatesList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='240px' />
					</Col>
				</Row>}
			</Container>
		</>);
	}
}

const cycleDatesSearch = [
	{ Header: 'Date', accessor: 'DATE_SEARCH', type: 'date' }
];

const cycleDatesList = {
	query: { entity: 'RECHARGES.CYCLE_DATES_VW', search: null, order: 'BEG_DATE_SEARCH desc' },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addCycleDate()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editCycleDate(props.row.values.BILLING_ID)} />, width: 25 },
		{ Header: 'ID', accessor: 'BILLING_ID', width: 60 },
		{ Header: 'Cur', accessor: 'CURRENT_FLAG', width: 40 },
		{ Header: 'Beg Date', accessor: 'BEG_DATE', width: 100 },
		{ Header: 'End Date', accessor: 'END_DATE', width: 100 },
		{ Header: 'Monthly Beg', accessor: 'MONTHLY_BEG', width: 125 },
		{ Header: 'Monthly End', accessor: 'MONTHLY_END', width: 125 },
		{ Header: 'Prelim Start', accessor: 'PRELIM_START', width: 125 },
		{ Header: 'Prelim End', accessor: 'PRELIM_END', width: 125 },
		{ Header: 'Beg Fiscal Year', accessor: 'BEG_FISCAL_YEAR', width: 125 },
		{ Header: 'Comments', accessor: 'COMMENTS', width: 400 },
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'BILLING_ID', width: 12 },
	{ Header: 'Cur', accessor: 'CURRENT_FLAG', width: 8 },
	{ Header: 'Beg Date', accessor: 'BEG_DATE', width: 20, format: 'mm/dd/yy' },
	{ Header: 'End Date', accessor: 'END_DATE', width: 20, format: 'mm/dd/yy' },
	{ Header: 'Monthly Beg', accessor: 'MONTHLY_BEG', width: 25, format: 'mm/dd/yy' },
	{ Header: 'Monthly End', accessor: 'MONTHLY_END', width: 25, format: 'mm/dd/yy' },
	{ Header: 'Prelim Start', accessor: 'PRELIM_START', width: 25, format: 'mm/dd/yy' },
	{ Header: 'Prelim End', accessor: 'PRELIM_END', width: 25, format: 'mm/dd/yy' },
	{ Header: 'Beg Fiscal Year', accessor: 'BEG_FISCAL_YEAR', width: 25, format: 'mm/dd/yy' },
	{ Header: 'Comments', accessor: 'COMMENTS', width: 80 },
];

const emailColumns = [
	{ Header: 'To', accessor: 'to', type: 'textarea', required: true, rows: 5 },
	{ Header: 'CC', accessor: 'cc' },
	{ Header: 'Subject', accessor: 'subject' },
	{ Header: 'Body', accessor: 'body', type: 'textarea', rows: 10 }
];

export default withRouter(CycleDates);