import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row } from 'react-bootstrap';
import ChemComponent from '../../ChemComponent';
import QueryTable from '../../QueryTable';
import { Link } from 'react-router-dom';

class SubmittedNewRequests extends ChemComponent {
  	constructor(props) {
		super(props);
		
		this.state = {
			renderKey: 1,
			searchCount: null
		};
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
		
	render() {			
		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Submitted New Requests People Report</span>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_SubmittedNewRequests' table={newRequestList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='100px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const newRequestList = {
	query: { entity: 'SUBMITTED_NEW_REQUESTS_VW', order: ['REQ_ROLE_ID desc'] },
	pageSize: 10,
	columns: [
		{ Header: 'Req ID', accessor: 'REQ_ROLE_ID' },
		{ Header: 'Appointment', accessor: 'TYPE_NAME' },
		{ Header: 'Name', accessor: 'PERSON_NAME', 
			Cell: props => <Link to={'/BuildingAccess/People/' + props.row.values.PERSON_ID}>{props.value}</Link> },
		{ Header: 'PI', accessor: 'PI_NAME' },
		{ Header: 'Supervisor', accessor: 'SUP_NAME' },
		{ Header: 'Keys Requested', accessor: 'LOCATION', Cell: props => <span dangerouslySetInnerHTML={{__html: props.value}} /> },
		{ Header: 'Access Requested', accessor: 'ACCESS', Cell: props => <span dangerouslySetInnerHTML={{__html: props.value}} /> },
		{ Header: 'Cal1 Card ID', accessor: 'CARD_ID' },
		{ Header: 'Proximity Number', accessor: 'PROX_NUM' },
		{ Header: 'Department', accessor: 'DEPT_NAME' },
		{ Header: 'Appt. Start', accessor: 'START_DATE', Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
		{ Header: 'Appt. End', accessor: 'END_DATE', Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
		{ Header: '', accessor: 'PERSON_ID', show: false }
	]
};

export default SubmittedNewRequests;