import ChemComponent from '../ChemComponent';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';

class BearBuyUpload extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			req: {
				file: null
			},
			pol: {
				file: null
			},
			pof: {
				file: null
			},
			renderKey: 0
		}
	}
		
	onChange(uploadName, accessor, value) {	
		this.mergeState(this.setByAccessor({}, uploadName, this.setByAccessor({}, accessor, value)));
	}

	onSubmitReq(event) {
		var self = this;
		// if there is a file
		if (this.state.req.file && this.state.req.file.name) {
			// Create an object of formData
			const formData = new FormData();

			// add the token to the formData
			formData.append("__RequestVerificationToken", this.props.user.antiForgeryToken);
		
			// add the file information
			formData.append("req", this.state.req.file, this.state.req.file.name);
			
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/Stores/UploadBearBuyReq',
				overlay: true,
				data: formData,
				contentType: false,
				processData: false
			}).done(function (data) {
				if (data.Success) {
					self.showConfirmation(data.Message);
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		} else {
			self.showAlert('No File Selected', 'Please select a file to upload');
		}
	}
	
	onSubmitPOL(event) {
		var self = this;
		// if there is a file
		if (this.state.pol.file && this.state.pol.file.name) {
			// Create an object of formData
			const formData = new FormData();

			// add the token to the formData
			formData.append("__RequestVerificationToken", this.props.user.antiForgeryToken);
		
			// add the file information
			formData.append("pol", this.state.pol.file, this.state.pol.file.name);
			
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/Stores/UploadBearBuyPOL',
				overlay: true,
				data: formData,
				contentType: false,
				processData: false
			}).done(function (data) {
				if (data.Success) {
					self.showConfirmation(data.Message);
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		} else {
			self.showAlert('No File Selected', 'Please select a file to upload');
		}
	}

	onSubmitPOF(event) {
		var self = this;
		// if there is a file
		if (this.state.pof.file && this.state.pof.file.name) {
			// Create an object of formData
			const formData = new FormData();

			// add the token to the formData
			formData.append("__RequestVerificationToken", this.props.user.antiForgeryToken);
		
			// add the file information
			formData.append("pof", this.state.pof.file, this.state.pof.file.name);
			
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/Stores/UploadBearBuyPOF',
				overlay: true,
				data: formData,
				contentType: false,
				processData: false
			}).done(function (data) {
				if (data.Success) {
					self.showConfirmation(data.Message);
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		} else {
			self.showAlert('No File Selected', 'Please select a file to upload');
		}
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col md={12} lg={11} xl={10} style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>1. Upload BearBuy Requisition Full Export</span>
			      <ChemEdit parent={this} columns={reqColumns} data={this.state.req} user={this.props.user} renderKey={this.state.renderKey}
					onChange={(accessor, value) => this.onChange('req', accessor, value)} onSubmit={(event) => this.onSubmitReq(event)}>
				    <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={12}>
					      <Button id="savereq" variant="warning" type="submit" className="float-end">Upload</Button>
						</Col>
					</Form.Group>
				  </ChemEdit>
				</Col>
			  </Row>
			  <Row>
			    <Col md={12} lg={11} xl={10} style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>2. Upload BearBuy PO Line Item notes report before Uploading POs</span><br />
				  <span>(matches Cart ID and PO #)</span>
			      <ChemEdit parent={this} columns={polColumns} data={this.state.pol} user={this.props.user} renderKey={this.state.renderKey}
					onChange={(accessor, value) => this.onChange('pol', accessor, value)} onSubmit={(event) => this.onSubmitPOL(event)}>
				    <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={12}>
					      <Button id="savepol" variant="warning" type="submit" className="float-end">Upload</Button>
						</Col>
					</Form.Group>
				  </ChemEdit>
				</Col>
			  </Row>
			  <Row>
			    <Col md={12} lg={11} xl={10} style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>3. Upload BearBuy PO Full export report</span>
			      <ChemEdit parent={this} columns={pofColumns} data={this.state.pof} user={this.props.user} renderKey={this.state.renderKey}
					onChange={(accessor, value) => this.onChange('pof', accessor, value)} onSubmit={(event) => this.onSubmitPOF(event)}>
				    <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={12}>
					      <Button id="savepof" variant="warning" type="submit" className="float-end">Upload</Button>
						</Col>
					</Form.Group>
				  </ChemEdit>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const reqColumns = [
	{ Header: 'File', accessor: 'file', type: 'file' }
];

const polColumns = [
	{ Header: 'File', accessor: 'file', type: 'file' }
];

const pofColumns = [
	{ Header: 'File', accessor: 'file', type: 'file' }
];

export default BearBuyUpload;
