import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemTable from '../ChemTable';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';


class MarkBilled extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			SERVICES: []
		};

		this.state = {
			search: search,
			result: [],
			renderKey: 1
		};
	}
	
	componentDidMount() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/Search',
			data: {
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'CURRENT_CYCLE_VW',
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				if (data.Data.length === 1) {
					self.mergeState({ 
						cycleBegDate: self.getDate(data.Data[0].BEG_DATE).toLocaleDateString('en-US', {year:'2-digit', month:'2-digit', day:'2-digit'}),
						cycleEndDate: self.getDate(data.Data[0].END_DATE).toLocaleDateString('en-US', {year:'2-digit', month:'2-digit', day:'2-digit'}),
						cycleActive: data.Data[0].ACTIVE_FLAG === 1,
						renderKey: self.state.renderKey + 1
					});
				} else {
					self.showAlert('Cycle Error', 'Expected 1 current billing cycle but found ' + data.Data.length);
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}

	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var self=this;
		
		// flatten service list
		var search = this.copyObject(this.state.search);
		if (this.state.search.SERVICES && this.state.search.SERVICES.length > 0) {
			search.SERVICES = this.state.search.SERVICES.join(',');
		} else {
			search.SERVICES = "";
		}
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/MarkBilled',
			overlay: true,
			data: {
				data: search
			}
		}).done(function (data) {
			if (data.Success) {
				self.mergeState({ 
					result: data.Data,
					renderKey: self.state.renderKey + 1
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	

		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}

	render() {

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Mark Recharges as Billed</span>
					{this.state.cycleActive !== undefined && <>
					  {this.state.cycleActive ?
					    <div style={{ color: 'red', fontWeight: 'bold', paddingBottom: '10px' }}>NOTE: Mark as Billed Restricted. Billing Session active until {this.state.cycleEndDate}</div>:
						<>
						  <div>For the Cycle: {this.state.cycleBegDate} - {this.state.cycleEndDate}</div>
						  <div style={{ paddingBottom: '4px' }}>NOTE: This will only affect entries that are not marked as billed and are in the current cycle.</div>
					      <ChemEdit parent={this} columns={billedSearch} data={this.state.search} renderKey={this.state.renderKey} 
					        onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					        <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						      <Col xs={12}>
						        <Button id="change" variant="warning" type="submit" className="float-end">Submit</Button>
						      </Col>
					        </Form.Group>
					      </ChemEdit>
						</>
					  }
					</>}
				  </Col>
				</Row>
				<Row>
				  <Col>
				    <ChemTable renderKey={this.state.renderKey} parent={this} name='CRS_ChangeSpeedtypes' columns={billedColumns} data={this.state.result} />
				  </Col>
				</Row>
			</Container>
		</>);
	}
}

const servicesOptions = [
	{ value: 'C1', label: 'Demurrage' },
	{ value: 'C2', label: 'Dry Ice' },
	{ value: 'C3', label: 'Gaseous Nitrogen' },
	{ value: 'C4', label: 'Helium Gas Cylinder' },
	{ value: 'C5', label: 'Helium Recovery' },
	{ value: 'C6', label: 'Dewar Cool Down' },
	{ value: 'C7', label: 'Helium Liquid Dewars' },
	{ value: 'C8', label: 'Liquid Nitrogen Dispenser' },
	{ value: 'C9', label: 'Storeroom' },
	{ value: 'C10', label: 'Glass Shop' },
	{ value: 'C11', label: 'Machine Shop' },
	{ value: 'C12', label: 'RSS Shops Including Carpentry (Wood) and Electric Shop' },
	{ value: 'C13', label: 'Graphics Lab' },
	{ value: 'C14', label: 'MicroAnalytical' },
	{ value: 'C15', label: 'NMR' },
	{ value: 'C16', label: 'Pitzer' },
	{ value: 'C17', label: 'Posters' },
	{ value: 'C18', label: 'X-Ray' }
];

const billedSearch = [
	{ Header: 'Services', accessor: 'SERVICES', type: 'list', options: servicesOptions }
];

const billedColumns = [
	{ Header: 'Service Name', accessor: 'SERVICE_NAME', width: 400, Cell: props => props.parent.getCellFragment(props.value) },
	{ Header: 'Date Billed', accessor: 'DATE_BILLED', width: 200 },
	{ Header: 'Line(s) Billed', accessor: 'LINES_TOTAL', width: 150 }
];

export default withRouter(MarkBilled);