import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';

class Helium extends ChemComponent {
	constructor(props) {
		super(props);

		var request = {
			FNAME: '',
			LNAME: '',
			EMAIL: '',
			PHONE: '',
			PI_ID: null,
			PI_NAME: '',
			SPEEDTYPE: '',
			CONT_TYPE_ID1: null,
			CONT_DESC1: '',
			CONT_TYPE_ID2: null,
			CONT_DESC2: '',
			DATE_REQUESTED: null,
			REMARKS: ''
		};
		
		this.state = {
			requestColumns: this.copyObject(requestColumns),
			request: request,
			renderKey: 0
		};
	}
	
	componentDidMount() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/Search',
			data: {
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'PI_SPEEDTYPE_VW',
				search: { Attribute: 'PERSON_ID', Operator: '=', LongValue: this.props.user.personID },
				pageNumber: -1,
				pageSize: -1
			},
			overlay: true
		}).done(function (data) {
			if (data.Success) {
				if (data.Data.length > 0) {
					// get values for request
					var request = self.copyObject(self.state.request);
					request.PI_ID = data.Data[0].PERSON_ID;
					request.PI_NAME = data.Data[0].PI_NAME;
					if (!self.isEmpty(data.Data[0].BFS_SPEED_TYPE)) {
						var speedtypes = data.Data[0].BFS_SPEED_TYPE.split(',');
						request.SPEEDTYPE = speedtypes[0];
					}

					// add PI(s) to select list
					var piIDs = [];
					for (var i = 0; i < data.Data.length; i++) piIDs.push(data.Data[i].PERSON_ID);
					var newRequestColumns = self.copyObject(requestColumns);
					var piIdx = self.findColumnByAccessor(newRequestColumns, 'PI_ID');
					newRequestColumns[piIdx].options.search.Children.push({ Attribute: 'PERSON_ID', Operator: 'in', LongList: piIDs });
					
					self.mergeState({
						requestColumns: newRequestColumns,
						request: request,
						renderKey: self.state.renderKey + 1
					});
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	onChangeRequest(accessor, value) {
		var request = this.copyObject(this.state.request);
		if (Array.isArray(accessor)) {
			for (var i = 0; i < accessor.length; i++) {
				this.setByAccessor(request, accessor[i], value[i]);
			}
		} else {
			this.setByAccessor(request, accessor, value);
		}
		this.mergeState({ request: request });
	}
	
	generateConfirmationHTML() {
		return '<table>' +
			this.generateConfirmationRow("Requestor's Name", this.state.request.FNAME + ' ' + this.state.request.LNAME) +
			this.generateConfirmationRow('Research Director', this.state.request.PI_NAME) +
			this.generateConfirmationRow('Dewar 1 Size', this.state.request.CONT_DESC1) +
			(this.isEmpty(this.state.request.CONT_TYPE_ID2) ? '' :
				this.generateConfirmationRow('Dewar 2 Size', this.state.request.CONT_DESC2)) +
			this.generateConfirmationRow('Pick Up Date', this.dateToString(this.getDate(this.state.request.DATE_REQUESTED))) +
			this.generateConfirmationRow('Speedtype Charged', this.state.request.SPEEDTYPE) +
			this.generateConfirmationRow('Email Address', this.state.request.EMAIL) +
			this.generateConfirmationRow('Phone', this.state.request.PHONE) +
			this.generateConfirmationRow('Department', 'College of Chemistry') +
			this.generateConfirmationRow('Remarks', this.state.request.REMARKS) +
			'</table>';
	}
	
	generateConfirmationRow(label, value) {
		return '<tr><td style="text-align: right;">' + label + '</td><td>&nbsp;&nbsp;&nbsp;</td><td style="font-weight: bold;">' + value + '</td></tr>';
	}

	onSubmitRequest(event) {
		var self = this;
		
		this.showOKCancel('Confirm Request', this.generateConfirmationHTML(), (okClicked) => {
			if (okClicked) {				
				var request = self.copyObject(self.state.request);
				self.js2mvc(request, requestColumns);
				
				self.ajax({
					type: 'POST',
					url: self.getConfig().host + '/HeLAD/RequestHeliumDewars',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, request: request }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(data.Message);
					} else {
						self.showAlert('Server Error', data.Message);
					}
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
		
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}

	render() {
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col md={12} lg={10} xl={8} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Liquid Helium Request</span>
						<ChemEdit parent={this} columns={this.state.requestColumns} data={this.state.request} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChangeRequest(accessor, value)}
						  onSubmit={(event) => this.onSubmitRequest(event)} {...this.props}>
						  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						    <Col xs={12}>
							  <Button id="submit" variant="warning" type="submit" className="float-end" onClick={(event) => this.onSubmitRequest(event)}>Submit</Button>
							</Col>
						  </Form.Group>
						</ChemEdit>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const requestColumns = [
	{ Header: 'First Name', accessor: 'FNAME', required: true, maxlength: 16 },
	{ Header: 'Last Name', accessor: 'LNAME', required: true, maxlength: 32 },
	{ Header: 'Email', accessor: 'EMAIL', required: true, maxlength: 40 },
	{ Header: 'Phone', accessor: 'PHONE', maxlength: 15 },
	{ Header: 'Research Director', accessor: 'PI_ID', labelAccessor: 'PI_NAME', type: 'select', required: true,
		options: { value: 'PERSON_ID', label: 'PI_NAME', entity: 'COLLEGE.HEREQ_PI_SELECT_VW', order: 'PI_NAME',
			search: { Operator: 'or', Children: [
				{ Attribute: 'DEFAULT_INCLUDE_FLAG', Operator: '=', IntValue: 1 }
			]}}},
	{ Header: 'Speedtype', accessor: 'SPEEDTYPE', required: true, maxlength: 10 },
	{ Header: 'Dewar 1 Volume', accessor: 'CONT_TYPE_ID1', labelAccessor: 'CONT_DESC1', type: 'select', required: true,
		options: { value: 'CONT_TYPE_ID', label: 'CONT_TYPE', entity: 'LIQUID_AIR.CONTAINER_TYPES',
			search: { Attribute: 'CONT_DESC', Operator: 'like', StringValue: 'for Liq He' }}},
	{ Header: 'Dewar 2 Volume', accessor: 'CONT_TYPE_ID2', labelAccessor: 'CONT_DESC2', type: 'select',
		options: { value: 'CONT_TYPE_ID', label: 'CONT_TYPE', entity: 'LIQUID_AIR.CONTAINER_TYPES',
			search: { Attribute: 'CONT_DESC', Operator: 'like', StringValue: 'for Liq He' }}},
	{ Header: 'Date for Pick Up', accessor: 'DATE_REQUESTED', type: 'date', required: true },
	{ Header: 'Remarks', accessor: 'REMARKS', type: 'textarea', maxlength: 250 }
];

export default Helium;
