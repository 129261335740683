import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../../ChemComponent';
import ChemEdit from '../../ChemEdit';
import QueryTable from '../../QueryTable';

class AddsDeletes extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var dtNow = new Date();
		var dtOneYearAgo = new Date();
		dtOneYearAgo.setFullYear(dtOneYearAgo.getFullYear() - 1);
		
		var search = {
			ADDS_DELETES: 'Adds',
			FROM_DATE: this.dateTimeToMVC(dtOneYearAgo),
			TO_DATE: this.dateTimeToMVC(dtNow)
		};
		
		var initialAddsDeletesList = this.copyAddsDeletesList(search.ADDS_DELETES);
		initialAddsDeletesList.query.search = this.constructSearch(search);
		
		this.state = {
			renderKey: 1,
			addsDeletesList: initialAddsDeletesList,
			search: search,
			searchCount: null
		};
	}
	
	copyAddsDeletesList(adds_deletes) {
		var i;
		if (adds_deletes === 'Adds') {
			var addsListCopy = this.copyObject(addsList);
			for (i = 0; i < addsList.columns.length; i++) {
				if (addsList.columns[i].Cell) {
					addsListCopy.columns[i].Cell = addsList.columns[i].Cell;
				}
			}
			return addsListCopy;
		} else {
			var deletesListCopy = this.copyObject(deletesList);
			for (i = 0; i < deletesList.columns.length; i++) {
				if (deletesList.columns[i].Cell) {
					deletesListCopy.columns[i].Cell = deletesList.columns[i].Cell;
				}
			}
			return deletesListCopy;
		}			
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newAddsDeletesList = this.copyAddsDeletesList(this.state.search.ADDS_DELETES);
		newAddsDeletesList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			addsDeletesList: newAddsDeletesList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};
		
		var searchColumn = (data.ADDS_DELETES === 'Adds') ? 'START_DATE' : 'END_DATE';
		if (data.FROM_DATE != null) search.Children.push({ Attribute: searchColumn, Operator: '>=', DateValue: this.dateTimeToMVC(this.getDate(data.FROM_DATE)) });
		if (data.TO_DATE != null) search.Children.push({ Attribute: searchColumn, Operator: '<=', DateValue: this.dateTimeToMVC(this.getDate(data.TO_DATE)) });
		
		// if nothing was selected, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	render() {
		var adds = this.state.search.ADDS_DELETES === 'Adds';

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: this.state.search.ADDS_DELETES + ' Report',
			columns: adds ? addsExportColumns : deletesExportColumns,
			entity: adds ? 'ADDS_VW' : 'DELETES_VW',
			search: this.constructSearch(this.state.search),
			order: ['LNAME', 'FNAME']
		});

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Adds/Deletes Report</span>
					<ChemEdit parent={this} columns={addsDeletesSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={3} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_AddsDeletes' table={this.state.addsDeletesList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='245px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const addsDeletesSearch = [
	{ Header: 'Adds/Deletes', accessor: 'ADDS_DELETES', type: 'select', row: 0, col: 0,
		options: [ { value: 'Adds', label: 'Adds' }, { value: 'Deletes', label: 'Deletes' } ] },
	{ Header: 'From', accessor: 'FROM_DATE', type: 'date', row: 0, col: 1 },
	{ Header: 'To', accessor: 'TO_DATE', type: 'date', row: 0, col: 2 }
];

const addsList = {
	query: { entity: 'ADDS_VW', search: null, order: ['FNAME', 'LNAME'] },
	pageSize: 20,
	columns: [
		{ Header: 'Last Name', accessor: 'LNAME' },
		{ Header: 'First Name', accessor: 'FNAME' },
		{ Header: 'Department', accessor: 'DEPT1' },
		{ Header: 'Location', accessor: 'LOCATION' },
		{ Header: 'Phone', accessor: 'PHONE' },
		{ Header: 'PI', accessor: 'PI_NAME' },
		{ Header: 'Appointment Type', accessor: 'TYPE_NAME' },
		{ Header: 'Start Date', accessor: 'START_DATE', Cell: props => props.value ? props.parent.dateToString(props.parent.getDate(props.value)) : null },
	]
};

const addsExportColumns = [
	{ Header: 'Last Name', accessor: 'LNAME', width: 20 },
	{ Header: 'First Name', accessor: 'FNAME', width: 20 },
	{ Header: 'Department', accessor: 'DEPT1', width: 20 },
	{ Header: 'Location', accessor: 'LOCATION', width: 20 },
	{ Header: 'Phone', accessor: 'PHONE', width: 20 },
	{ Header: 'PI', accessor: 'PI_NAME', width: 20 },
	{ Header: 'Appointment Type', accessor: 'TYPE_NAME', width: 20 },
	{ Header: 'Start Date', accessor: 'START_DATE', width: 20 },
];

const deletesList = {
	query: { entity: 'DELETES_VW', search: null, order: ['FNAME', 'LNAME'] },
	pageSize: 20,
	columns: [
		{ Header: 'Last Name', accessor: 'LNAME' },
		{ Header: 'First Name', accessor: 'FNAME' },
		{ Header: 'PI', accessor: 'PI_NAME' },
		{ Header: 'Department', accessor: 'DEPT1' },
		{ Header: 'Appointment Type', accessor: 'TYPE_NAME' },
		{ Header: 'Address', accessor: 'STREET2' },
		{ Header: 'City', accessor: 'CITY_STATE2' },
		{ Header: 'Country', accessor: 'COUNTRY' },
		{ Header: 'End Date', accessor: 'END_DATE', Cell: props => props.value ? props.parent.dateToString(props.parent.getDate(props.value)) : null },
	]
};

const deletesExportColumns = [
	{ Header: 'Last Name', accessor: 'LNAME', width: 20 },
	{ Header: 'First Name', accessor: 'FNAME', width: 20 },
	{ Header: 'PI', accessor: 'PI_NAME', width: 20 },
	{ Header: 'Department', accessor: 'DEPT1', width: 20 },
	{ Header: 'Appointment Type', accessor: 'TYPE_NAME', width: 20 },
	{ Header: 'Address', accessor: 'STREET2', width: 20 },
	{ Header: 'City', accessor: 'CITY_STATE2', width: 20 },
	{ Header: 'Country', accessor: 'COUNTRY', width: 20 },
	{ Header: 'End Date', accessor: 'END_DATE', width: 20 },
];

export default AddsDeletes;