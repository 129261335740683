import React from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Service extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			service: {
				SERVICE_ID: null,
				SERVICE_NAME: '',
				SERVICE_DESC: '',
				SERVICE_TITLE: '',
				SERVICE_EMAIL: '',
				SERVICE_URL: '',
				SERVICE_LOC: '',
				SERVICE_PHONE: '',
				SERVICE_UNIT: null,
				SERVICE_CONTACT: '',
				CONTACT_UID: null,
				CONTACT2_UID: null,
				CONTACT3_UID: null,
				RCH_TABLE: '',
				RCH_CODE: '',
				INCOME_ACCT: '',
				RECHARGE_ACCT: '',
				INC_SPEEDTYPE: '',
				SUBS_SPEEDTYPE: '',
				ACTIVE_FLAG: undefined,
				UPLOAD_FLAG: undefined,
				COMMENTS: '',
				DATE_CREATED: null,
				CREATED_BY: '',
				DATE_MODIF: null,
				MODIF_BY: '',
				DELETE_FLAG: undefined,
				REG_SERVICE: '',
				OUTSIDE_PCT: '',
				OUTSIDE_FLAG: '',
				CREATED: '',
				MODIFIED: ''
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadService();
	}

	loadService(event) {
		var self = this;
		
		if (this.props.params.serviceId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/CRS/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'SERVICE_LIST',
					search: { Attribute: 'SERVICE_ID', Operator: '=', LongValue: this.props.params.serviceId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var service = data.Data[0];
						
						// convert flags
						service.ACTIVE_FLAG = service.ACTIVE_FLAG === 'Y' ? true : false;
						service.UPLOAD_FLAG = service.UPLOAD_FLAG === 'Y' ? true : false;
						service.DELETE_FLAG = service.DELETE_FLAG === 'Y' ? true : false;
						
						// convert dates
						service.DATE_CREATED = self.dateTimeToMVC(self.getDate(service.DATE_CREATED));
						service.DATE_MODIF = self.dateTimeToMVC(self.getDate(service.DATE_MODIF));
						
						// compose created/modified
						service.CREATED = self.composeChangeInfo(service.CREATED_BY, service.DATE_CREATED);
						service.MODIFIED = self.composeChangeInfo(service.MODIF_BY, service.DATE_MODIF);
						if (self.isEmpty(service.MODIFIED)) service.MODIFIED = service.CREATED;
						
						self.mergeState({
							service: service,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Service Error', data.Data.length + ' Services found for ID ' + self.props.params.serviceId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				service: {
					SERVICE_ID: '(new)',
					ACTIVE_FLAG: true,
					UPLOAD_FLAG: false,
					DELETE_FLAG: false
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var service = this.copyObject(this.state.service);
		this.setByAccessor(service, accessor, value);
		this.mergeState({ service: service, renderKey: this.state.renderKey + 1 });
	}
		
	deleteService(event) {
		var self = this;
		
		// assemble user object to update inactive flag
		var service = {
			SERVICE_ID: this.props.params.serviceId,
			ACTIVE_FLAG: null,
			DELETE_FLAG: 'Y',
			DATE_MODIF: this.dateTimeToMVC(new Date()),
			MODIF_BY: this.props.user.name
		};
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/Home/Update',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'RECHARGES.SERVICE_LIST', data: service }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Service {self.props.params.serviceId} deleted.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	validateService(event, columns, data) {
		return this.validate(columns, data);
	}
		
	onSubmit(event) {
		var self = this;		
		
		// make copy of data
		var service = this.copyObject(this.state.service);

		// if this is a new user
		var url;
		if (this.props.params.serviceId) {
			url = this.getConfig().host + '/Home/Update';
			service.DATE_MODIF = this.dateTimeToMVC(new Date());
			service.MODIF_BY = this.props.user.name;
			service.DATE_CREATED = this.dateTimeToMVC(this.getDate(service.DATE_CREATED));
		} else {
			url = this.getConfig().host + '/Home/Insert';
			service.DATE_CREATED = this.dateTimeToMVC(new Date());
			service.CREATED_BY = this.props.user.name;
			// set ID sequence
			service.SERVICE_ID = 'service_id_seq.nextval';
		}
		
		// convert flags
		service.ACTIVE_FLAG = service.ACTIVE_FLAG ? 'Y' : null;
		service.DELETE_FLAG = service.DELETE_FLAG ? 'Y' : null;
		service.UPLOAD_FLAG = service.UPLOAD_FLAG ? 'Y' : null;
		
		this.ajax({
			type: 'POST',
			url: url,
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'RECHARGES.SERVICE_LIST', data: service }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Service <Link to={'/CRS/Services/' + (self.props.params.serviceId || data.ID)}>{self.props.params.serviceId || data.ID}</Link> updated.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Service {this.state.service.SERVICE_ID}</div>
				<ChemEdit parent={this} columns={serviceColumns} data={this.state.service} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}
				   validate={(event, columns, data) => this.validateService(event, columns, data)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					{this.props.params.serviceId && <Button id="delete" variant="warning" type="button" className="float-end" style={{ marginRight: '10px' }} onClick={(event) => this.deleteService(event)}>Delete</Button>}
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const serviceColumns = [
	{ Header: 'Name', accessor: 'SERVICE_NAME', row: 0, col: 0, required: true },
	{ Header: 'Report Label', accessor: 'SERVICE_TITLE', row: 0, col: 1 },
	{ Header: 'Description', accessor: 'SERVICE_DESC', row: 0, col: 2 },
	{ Header: 'Unit', accessor: 'SERVICE_UNIT', type: 'select', row: 0, col: 3, required: true,
		options: { value: 'UNIT_ID', label: 'RCH_NAME', entity: 'ORG_UNIT', order: 'RCH_NAME',
			search: { Operator: 'and', Children: [
				{ Attribute: 'DELETE_FLAG', Operator: 'is null' },
				{ Attribute: 'RCH_NAME', Operator: 'is not null' }
			]}}},
	{ Header: 'Email', accessor: 'SERVICE_EMAIL', row: 1, col: 0 },
	{ Header: 'Phone', accessor: 'SERVICE_PHONE', row: 1, col: 1 },
	{ Header: 'Web Site', accessor: 'SERVICE_URL', row: 1, col: 2 },
	{ Header: 'Location', accessor: 'SERVICE_LOC', row: 1, col: 3 },
	{ Header: 'Contact 1', accessor: 'CONTACT_UID', type: 'select', row: 2, col: 0,
		options: { value: 'CAL_UID', label: 'PERSON_NAME', entity: 'SERVICE_CONTACT_VW', order: ['LNAME', 'FNAME']}},
	{ Header: 'Contact 2', accessor: 'CONTACT2_UID', type: 'select', row: 2, col: 1,
		options: { value: 'CAL_UID', label: 'PERSON_NAME', entity: 'SERVICE_CONTACT_VW', order: ['LNAME', 'FNAME']}},
	{ Header: 'Contact 3', accessor: 'CONTACT3_UID', type: 'select', row: 2, col: 2,
		options: { value: 'CAL_UID', label: 'PERSON_NAME', entity: 'SERVICE_CONTACT_VW', order: ['LNAME', 'FNAME']}},
	{ Header: 'Recharge Code', accessor: 'RCH_CODE', row: 2, col: 3, subscript: '(separate with commas for multiple recharge codes)' },
	{ Header: 'Income Account', accessor: 'INCOME_ACCT', row: 3, col: 0 },
	{ Header: 'Recharge Account', accessor: 'RECHARGE_ACCT', row: 3, col: 1 },
	{ Header: 'Income Speedtype', accessor: 'INC_SPEEDTYPE', row: 3, col: 2 },
	{ Header: 'Subsidy Speedtype', accessor: 'SUBS_SPEEDTYPE', row: 3, col: 3 },
	{ Header: 'Monthly Upload Flag', accessor: 'UPLOAD_FLAG', type: 'checkbox', row: 4, col: 0, subscript: '(for services that need data to be uploaded)' },
	{ Header: 'Recharge Table', accessor: 'RCH_TABLE', row: 4, col: 1 },	
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 4, col: 2 },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 4, col: 3, editable: false },
];

export default withRouter(Service);
