import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
	padding: PropTypes.string
  }

  constructor(props) {
    super(props);

    this.state = {
      activeTab: props.activeTab || props.children[0].props.label
    };
  }
  
  componentDidUpdate(prevProps) {
	if (this.props.activeTab && this.props.activeTab !== this.state.activeTab) {
	  this.setState({
		activeTab: this.props.activeTab
	  });
	}	  
  }

  onClickTabItem = (tab) => {
	var self = this;
	// ask parent permission to click tab item, if necessary
	var canClick = this.props.canClickTabItem ? this.props.canClickTabItem(tab) : true;
	
	// if permission was given
	if (canClick) {
		this.setState({ activeTab: tab }, () => {
			if (self.props.onClickTabItem) self.props.onClickTabItem(tab);
		});
	}
  }

  render() {
    const {
      onClickTabItem,
      props: {
        children,
		padding
      },
      state: {
        activeTab,
      }
    } = this;

    return (
      <div className="tabs">
        <ol style={styles.tabList}>
          {children.map((child) => {
            const { label } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
				padding={padding}
              />
            );
          })}
        </ol>
        <div>
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

const styles = {
	tabList: {
		borderBottom: '1px solid #000',
		paddingLeft: 0
	}
};


export default Tabs;