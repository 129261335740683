import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';
import Tabs from '../Tabs';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class Fellowships extends ChemComponent {
	constructor(props) {
		super(props);

		var search = this.copyObject(defaultSearch);
		
		// get list of FLD_ keys since they will disappear when object is copied
		var fldKeys = [];
		Object.keys(search).forEach(function(key) {
			if (key.length > 4 && key.substring(0, 4) === 'FLD_') {
				fldKeys.push(key);
			}
		});	
		
		var initFellowshipList = this.copyTable(fellowshipList);
		var searchCount = 0;
		var showSearch = true;

		if (typeof(Storage) !== 'undefined') {
			var storedSearch = localStorage.getItem('GradOffice_Fellowships_search');
			if (storedSearch) {
				storedSearch = JSON.parse(storedSearch);
				this.mergeObject(search, storedSearch);
				search = storedSearch;
				initFellowshipList.ajax.data = { search: this.copyObject(search) };
				this.quoteStringArrays(initFellowshipList.ajax.data.search);
				initFellowshipList.hold = false;
				this.removeUnusedColumns(initFellowshipList.ajax.data.search, initFellowshipList.columns);
				searchCount = null;
				showSearch = false;
			}
		}

		this.state = {
			fellowshipList: initFellowshipList,
			search: search,
			fldKeys: fldKeys,
			searchCount: searchCount,
			showSearch: showSearch,
			activeTab: 'Personal',
			renderKey: 0
		};
	}
	
	onClickTabItem(tab) {
		this.mergeState({
			activeTab: tab
		});
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newFellowshipList = this.copyTable(fellowshipList);
		newFellowshipList.ajax.data = {
			search: this.copyObject(this.state.search)
		};
		//this.quoteStringArrays(newFellowshipList.ajax.data.search);
		newFellowshipList.hold = false;
		this.removeUnusedColumns(newFellowshipList.ajax.data.search, newFellowshipList.columns);
		this.mergeState({ 
			fellowshipList: newFellowshipList,
			searchCount: null,
			renderKey: this.state.renderKey + 1,
			activeTab: 'Personal',
			showSearch: false
		});
		if (typeof(Storage) !== 'undefined') {
			localStorage.setItem('GradOffice_Fellowships_search', JSON.stringify(this.state.search));
		}
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onNewSearch(event) {
		this.mergeState({
			search: this.copyObject(defaultSearch),
			showSearch: true,
			renderKey: this.state.renderKey + 1
		});
	}

	onExpandSearch(event) {
		this.mergeState({
			showSearch: true			
		});
	}
	
	getFieldList(search)
	{
		var fields = [];
		Object.keys(search).forEach(function(key) {
			if (key.length > 4 && key.substring(0, 4) === 'FLD_' && search[key]) {
				fields.push(key.substring(4));
			}
		});
		return fields;
	}
	
	removeUnusedColumns(search, columns) {
		var fields = this.getFieldList(search);
		
		for (var i = columns.length - 1; i >= 0; i--) {
			if (columns[i].accessor !== 'id' && columns[i].accessor !== 'STUDENT_ID' && columns[i].accessor !== 'FELLOWSHIP_ID') {
				if (columns[i].includedby) {
					if (!fields.includes(columns[i].includedby)) {
						columns.splice(i, 1);
					}
				} else if (!fields.includes(columns[i].accessor)) {
					columns.splice(i, 1);
				}
			}
		}
	}

	quoteStringArrays(search) {
		this.quoteStringArray(search['FELLOWSHIP_YEAR']);
		this.quoteStringArray(search['REGISTRATION']);
		this.quoteStringArray(search['ACADEMIC_YEAR']);
		this.quoteStringArray(search['DIVISION']);
		this.quoteStringArray(search['VISA_NAME']);
	}
	
	selectAll(event) {
		var search = this.copyObject(this.state.search);
		for (var i = 0; i < this.state.fldKeys.length; i++) {
			this.setByAccessor(search, this.state.fldKeys[i], true);
		}
		this.mergeState({
			search: search,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}

	deselectAll(event) {
		var search = this.copyObject(this.state.search);
		for (var i = 0; i < this.state.fldKeys.length; i++) {
			this.setByAccessor(search, this.state.fldKeys[i], false);
		}
		this.mergeState({
			search: search,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	addFellowship() {
		this.props.navigate('/GradOffice/Fellowships/new');
	}
	
	editFellowship(fellowshipId) {
		this.props.setFellowshipEditable(true);
		this.props.navigate('/GradOffice/Fellowships/' + fellowshipId);		
	}
	
	viewFellowship(fellowshipId) {
		this.props.setFellowshipEditable(false);
		this.props.navigate('/GradOffice/Fellowships/' + fellowshipId);
	}

	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		var exportColumnsInUse = this.copyColumns(exportColumns);
		this.removeUnusedColumns(this.state.search, exportColumnsInUse);
		var search = this.copyObject(this.state.search);
		//this.quoteStringArrays(search);

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			Action: 'Fellowships',
			columns: exportColumnsInUse,
			search: search
		});			
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Fellowships</span>
						{this.state.showSearch && <Tabs onClickTabItem={(tab) => this.onClickTabItem(tab)}>
						  <div label='Search'>
							<ChemEdit parent={this} columns={fellowshipSearch} data={this.state.search} renderKey={this.state.renderKey} 
							  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} {...this.props} />
						  </div>
						  <div label='Fields'>
							<ChemEdit parent={this} columns={fellowshipFields} data={this.state.search} renderKey={this.state.renderKey} 
							  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} {...this.props} labelColWidth={10} valueColWidth={2} />
						  </div>
						</Tabs>}
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/GradOffice/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/GradOffice/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						  {this.state.showSearch ?
							<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button> :
							<Button id="expand" variant="warning" type="button" className="float-end" onClick={(event) => this.onExpandSearch(event)}>Modify Search</Button>
						  }
						  <Button id="newSearch" style={{ marginRight: '10px' }} variant="warning" type="button" className="float-end" onClick={(event) => this.onNewSearch(event)}>New Search</Button> 
						  {this.state.showSearch && this.state.activeTab === 'Fields' && <>
							  <Button id="deselectAll" style={{ marginRight: '10px' }} variant="warning" type="button" className="float-end" onClick={(event) => this.deselectAll(event)}>Deselect All</Button>
							  <Button id="selectAll" style={{ marginRight: '10px' }} variant="warning" type="button" className="float-end" onClick={(event) => this.selectAll(event)}>Select All</Button>
						  </>}
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='GradOffice_Fellowships' table={this.state.fellowshipList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='140px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const defaultSearch = {
	BLOCK_GRANT_FLAG: 'ALL',
	OUTSIDE: undefined,
	FELLOWSHIP_YEAR: null,
	STUDENT_STATUS: 'ALL',
	FELLOW_TYPE: 'ALL',
	FUND: '',
	REG_STATUS: null,
	START_DATE_FROM: null,
	START_DATE_TO: null,
	END_DATE_FROM: null,
	END_DATE_TO: null,
	FLD_ACADEMIC_YEAR: undefined,
	FLD_FELLOWSHIP_YEAR: undefined,
	FLD_REG_STATUS: undefined,
	FLD_STUDENT_CARD: undefined,
	FLD_YTD_TOTAL: undefined,
	FLD_FORM_COMMENTS: undefined,
	FLD_RES_DIRECTOR: undefined,
	FLD_TUITION: undefined,
	FLD_CA_RESIDENT: undefined,
	FLD_FUND: undefined,
	FLD_FELLOW_TYPE: undefined,
	FLD_VISA: undefined,
	FLD_CITIZENSHIP: undefined,
	FLD_FUND_NUMBER: undefined,
	FLD_SEMESTER: true,
	FLD_YTD_STIPEND: undefined,
	FLD_DIVISION: undefined,
	FLD_LAST_MODIF: undefined,
	FLD_STUDENT_NAME: true,
	FLD_YEAR: undefined,
	FLD_END_DATE: undefined,
	FLD_LIST_COMMENTS: undefined,
	FLD_START_DATE: undefined,
	FLD_FEES: undefined,
	FLD_STUDENT_STATUS: undefined,
	FLD_STIPEND: undefined
};

const fellowshipSearch = [
	{ Header: 'Block Grant', accessor: 'BLOCK_GRANT_FLAG', type: 'select', row: 0, col: 0,
		options: [{ value: 'Y', label: 'Yes' }, { value: 'N', label: 'No' }, { value: 'ALL', label: 'All' }]},
	{ Header: 'Outside Students', accessor: 'OUTSIDE', type: 'checkbox', row: 0, col: 1 },
	{ Header: 'Fellowship Year', accessor: 'FELLOWSHIP_YEAR', type: 'select', row: 0, col: 2,
		options: { value: 'ACADEMIC_YEAR_DISPLAY', label: 'ACADEMIC_YEAR_DISPLAY', entity: 'ADMISSIONS.ACADEMIC_YEAR_SELECT_VW', order: 'ACADEMIC_YEAR desc' }},
	{ Header: 'Fellowship Status', accessor: 'STUDENT_STATUS', type: 'select', row: 0, col: 3,
		options: [{ value: 0, label: 'Continuing' }, { value: 1, label: 'New' }, { value: 'ALL', label: 'All' }]},
	{ Header: 'Fellowship Type', accessor: 'FELLOW_TYPE', type: 'select', row: 1, col: 0,
		options: [{ value: 1, label: 'Resident' }, { value: 0, label: 'Travel' }, { value: 'ALL', label: 'All' }]},
	{ Header: 'Fund Name', accessor: 'FUND', row: 1, col: 1 },
	{ Header: 'Registration Status', accessor: 'REG_STATUS', type: 'select', row: 1, col: 2,
		options: { value: 'REG_STATUS_NAME', label: 'REG_STATUS_NAME', entity: 'ADMISSIONS.REG_STATUS' }},
	{ Header: 'Start Date From', accessor: 'START_DATE_FROM', type: 'date', row: 2, col: 0 },
	{ Header: 'Start Date To', accessor: 'START_DATE_TO', type: 'date', row: 2, col: 1 },
	{ Header: 'End Date From', accessor: 'END_DATE_FROM', type: 'date', row: 2, col: 2 },
	{ Header: 'End Date To', accessor: 'END_DATE_TO', type: 'date', row: 2, col: 3 }
];

const fellowshipFields = [
	{ Header: 'Academic Year', accessor: 'FLD_ACADEMIC_YEAR', type: 'checkbox', row: 0, col: 0 },
	{ Header: 'Fellowship Year', accessor: 'FLD_FELLOWSHIP_YEAR', type: 'checkbox', row: 0, col: 1 },
	{ Header: 'Registration Status', accessor: 'FLD_REG_STATUS', type: 'checkbox', row: 0, col: 2 },
	{ Header: 'Student ID', accessor: 'FLD_STUDENT_CARD', type: 'checkbox', row: 0, col: 3 },
	{ Header: 'Amount Year Total', accessor: 'FLD_YTD_TOTAL', type: 'checkbox', row: 1, col: 0 },
	{ Header: 'Form Comments', accessor: 'FLD_FORM_COMMENTS', type: 'checkbox', row: 1, col: 1 },
	{ Header: 'Research Director', accessor: 'FLD_RES_DIRECTOR', type: 'checkbox', row: 1, col: 2 },
	{ Header: 'Tuition', accessor: 'FLD_TUITION', type: 'checkbox', row: 1, col: 3 },
	{ Header: 'CA resident', accessor: 'FLD_CA_RESIDENT', type: 'checkbox', row: 2, col: 0 },
	{ Header: 'Fund', accessor: 'FLD_FUND', type: 'checkbox', row: 2, col: 1 },
	{ Header: 'Res/trav', accessor: 'FLD_FELLOW_TYPE', type: 'checkbox', row: 2, col: 2 },
	{ Header: 'Visa', accessor: 'FLD_VISA', type: 'checkbox', row: 2, col: 3 },
	{ Header: 'Citizenship', accessor: 'FLD_CITIZENSHIP', type: 'checkbox', row: 3, col: 0 },
	{ Header: 'Fund Number', accessor: 'FLD_FUND_NUMBER', type: 'checkbox', row: 3, col: 1 },
	{ Header: 'Semester', accessor: 'FLD_SEMESTER', type: 'checkbox', row: 3, col: 2 },
	{ Header: 'YTD Stipend', accessor: 'FLD_YTD_STIPEND', type: 'checkbox', row: 3, col: 3 },
	{ Header: 'Division', accessor: 'FLD_DIVISION', type: 'checkbox', row: 4, col: 0 },
	{ Header: 'Last Modified', accessor: 'FLD_LAST_MODIF', type: 'checkbox', row: 4, col: 1 },
	{ Header: 'Student Name', accessor: 'FLD_STUDENT_NAME', type: 'checkbox', row: 4, col: 2 },
	{ Header: 'Year of Payment', accessor: 'FLD_YEAR', type: 'checkbox', row: 4, col: 3 },
	{ Header: 'End Date', accessor: 'FLD_END_DATE', type: 'checkbox', row: 5, col: 0 },
	{ Header: 'List Comments', accessor: 'FLD_LIST_COMMENTS', type: 'checkbox', row: 5, col: 1 },
	{ Header: 'Start Date', accessor: 'FLD_START_DATE', type: 'checkbox', row: 5, col: 2 },
	{ Header: 'Fees', accessor: 'FLD_FEES', type: 'checkbox', row: 6, col: 1 },
	{ Header: 'New/Continuing', accessor: 'FLD_STUDENT_STATUS', type: 'checkbox', row: 6, col: 2 },
	{ Header: 'Stipend/Semester', accessor: 'FLD_STIPEND', type: 'checkbox', row: 6, col: 3 }
];

const fellowshipList = {
	ajax: { 
		url: '/GradOffice/GetFellowships',
		data: null,
		accessor: 'Data'
	},
	pageSize: 20,
	hold: true,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addFellowship()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editFellowship(props.row.values.FELLOWSHIP_ID)} />, width: 25 },
		{ Header: '', accessor: 'STUDENT_ID', show: false },
		{ Header: 'ID', accessor: 'FELLOWSHIP_ID' },	
		{ Header: 'Year Entered', accessor: 'YEAR' },
		{ Header: 'Fellowship Year', accessor: 'FELLOWSHIP_YEAR' },
		{ Header: 'Name', accessor: 'STUDENT_NAME', Cell: props => <HashLink onClick={() => props.parent.viewFellowship(props.row.values.FELLOWSHIP_ID)}>{props.value}</HashLink> },
		{ Header: 'C/N', accessor: 'STUDENT_STATUS' },
		{ Header: 'Division', accessor: 'DIVISION' },
		{ Header: 'Fund', accessor: 'FUND', },
		{ Header: 'YTD Stipend', accessor: 'YTD_STIPEND' },
		{ Header: 'Start Date', accessor: 'START_DATE' },
		{ Header: 'End Date', accessor: 'END_DATE' },
		{ Header: 'Student ID', accessor: 'STUDENT_CARD' },
		{ Header: 'Form Comments', accessor: 'FORM_COMMENTS' },
		{ Header: 'List Comments', accessor: 'LIST_COMMENTS' },
		{ Header: 'Citizenship', accessor: 'CITIZENSHIP' },
		{ Header: 'Fund Number', accessor: 'FUND_NUMBER' },
		{ Header: 'YTD Total', accessor: 'YTD_TOTAL' },
		{ Header: 'Research Director', accessor: 'RESEARCH_DIRECTOR' },
		{ Header: 'CA Resident', accessor: 'CA_RESIDENT' },
		{ Header: 'Registration Status', accessor: 'REG_STATUS' },
		{ Header: 'Stipend', accessor: 'STIPEND' },
		{ Header: 'Fees', accessor: 'FEES' },
		{ Header: 'Tuition', accessor: 'TUITION' },
		{ Header: 'Semester', accessor: 'SEMESTER' }		
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'FELLOWSHIP_ID' },	
	{ Header: 'Year Entered', accessor: 'YEAR' },
	{ Header: 'Fellowship Year', accessor: 'FELLOWSHIP_YEAR' },
	{ Header: 'Name', accessor: 'STUDENT_NAME' },
	{ Header: 'C/N', accessor: 'STUDENT_STATUS' },
	{ Header: 'Division', accessor: 'DIVISION' },
	{ Header: 'Fund', accessor: 'FUND', },
	{ Header: 'YTD Stipend', accessor: 'YTD_STIPEND' },
	{ Header: 'Start Date', accessor: 'START_DATE' },
	{ Header: 'End Date', accessor: 'END_DATE' },
	{ Header: 'Student ID', accessor: 'STUDENT_CARD' },
	{ Header: 'Form Comments', accessor: 'FORM_COMMENTS' },
	{ Header: 'List Comments', accessor: 'LIST_COMMENTS' },
	{ Header: 'Citizenship', accessor: 'CITIZENSHIP' },
	{ Header: 'Fund Number', accessor: 'FUND_NUMBER' },
	{ Header: 'YTD Total', accessor: 'YTD_TOTAL' },
	{ Header: 'Research Director', accessor: 'RESEARCH_DIRECTOR' },
	{ Header: 'CA Resident', accessor: 'CA_RESIDENT' },
	{ Header: 'Registration Status', accessor: 'REG_STATUS' },
	{ Header: 'Stipend', accessor: 'STIPEND' },
	{ Header: 'Fees', accessor: 'FEES' },
	{ Header: 'Tuition', accessor: 'TUITION' },
	{ Header: 'Semester', accessor: 'SEMESTER' }		
];

export default withRouter(Fellowships)
