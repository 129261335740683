import React from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class DryIceSale extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			dryIceSale: {
				TRANS_ID: null,
				USER_NAME: '',
				COST_CENTER: null,
				PI_ID: null,
				QTY: '',
				MATERIAL_RATE: '(none)',
				RCH_UNIT: '(none)',
				TRANS_DATE: null,
				DATE_BILLED: null,
				COMMENTS: '',
				MODIF_BY: '',
				MODIF_DATE: null,
				MODIF_ACTION: null,
				MODIFIED: '',
				OLD_CC: null,
				CC_ID: null,
				RCH_ID: null,
				SERVICE_ID: 10,
				DELETE_FLAG: null				
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadDryIceSale();
	}

	loadDryIceSale(event) {
		var self = this;
		
		if (this.props.params.transId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/CRS/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'DRY_ICE',
					search: { Attribute: 'TRANS_ID', Operator: '=', LongValue: this.props.params.transId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var dryIceSale = data.Data[0];
						
						// convert transaction date
						dryIceSale.TRANS_DATE = self.dateTimeToMVC(self.getDate(dryIceSale.TRANS_DATE));
						
						// compose created/modified
						dryIceSale.MODIFIED = self.composeChangeInfo(dryIceSale.MODIF_BY, dryIceSale.MODIF_DATE);
						
						// load rate/units for transaction date
						self.checkTransDate('TRANS_DATE', self.getDate(dryIceSale.TRANS_DATE), (shopsRchCodes) => {
							dryIceSale.MATERIAL_RATE = shopsRchCodes.MATERIAL_RATE;
							dryIceSale.RCH_UNIT = shopsRchCodes.RCH_UNIT;
							self.mergeState({
								dryIceSale: dryIceSale,
								renderKey: self.state.renderKey + 1
							}, () => {
								if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
							});
						});
					} else {
						self.showAlert('Dry Ice Error', data.Data.length + ' Dry Ice sales found for ID ' + self.props.params.transId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				dryIceSale: {
					TRANS_ID: '(new)'
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var self = this;
		var dryIceSale = this.copyObject(this.state.dryIceSale);
		this.setByAccessor(dryIceSale, accessor, value);
		this.checkTransDate(accessor, value, (shopsRchCodes) => {
			if (shopsRchCodes) {
				dryIceSale.MATERIAL_RATE = shopsRchCodes.MATERIAL_RATE;
				dryIceSale.RCH_UNIT = shopsRchCodes.RCH_UNIT;
			}
			self.mergeState({ dryIceSale: dryIceSale, renderKey: this.state.renderKey + 1 });
		});
	}
	
	checkTransDate(accessor, value, callback) {
		var noRate = {
			MATERIAL_RATE: '(none)',
			RCH_UNIT: '(none)'
		};
		
		if (accessor === 'TRANS_DATE') {
			if (!this.isEmpty(value)) {
				var self = this;
				
				// rate/units must be active and valid for transaction date
				this.ajax({
					type: 'POST',
					url: this.getConfig().host + '/CRS/Search',
					data: {
						__RequestVerificationToken: this.props.user.antiForgeryToken,
						entity: 'SHOPS_RCH_CODES2',
						search: { Operator: 'and', Children: [
							{ Attribute: 'SERVICE_ID', Operator: '=', LongValue: 10 },
							{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
							{ Attribute: 'START_VALID', Operator: '<=', DateValue: this.dateTimeToMVC(value) },
							{ Operator: 'or', Children: [
								{ Attribute: 'END_VALID', Operator: 'is null' },
								{ Attribute: 'END_VALID', Operator: '>=', DateValue: this.dateTimeToMVC(value) }
							]}
						]},
						pageNumber: -1,
						pageSize: -1
					}
				}).done(function (data) {
					if (data.Success) {
						if (data.Data.length > 0) {
							callback(data.Data[0]);
						} else {
							callback(noRate);
						}
					} else {
						self.showAlert('Server Error', data.Message);
						callback(noRate);
					}
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
					callback(noRate);
				});			
			} else {
				callback(noRate);
			}
		} else {
			callback(null);
		}
	}
	
	deleteDryIceSale(event) {
		var self = this;
		
		// assemble user object to update inactive flag
		var dryIceSale = {
			TRANS_ID: this.props.params.transId,
			DELETE_FLAG: 'Y',
			MODIF_DATE: this.dateTimeToMVC(new Date()),
			MODIF_BY: this.props.user.name
		};
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/Home/Update',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'RECHARGES.DRY_ICE', data: dryIceSale }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Dry Ice transaction {this.props.params.transId} deleted.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	validateDryIceSale(event, columns, data) {
		return this.validate(columns, data);
	}
		
	onSubmit(event) {
		var self = this;		
		
		// make copy of data
		var dryIceSale = this.copyObject(this.state.dryIceSale);

		// if this is a new user
		if (!this.props.params.transId) {
			// set ID sequence
			dryIceSale.TRANS_ID = 'dry_ice_seq.nextval';
		}
		
		// format transaction date
		dryIceSale.TRANS_DATE = this.dateTimeToMVC(this.getDate(dryIceSale.TRANS_DATE));
		
		// update modification
		dryIceSale.MODIF_DATE = this.dateTimeToMVC(new Date());
		dryIceSale.MODIF_BY = this.props.user.name;
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/CRS/UpdateDryIceSale',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, dryIceSale: dryIceSale }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Dry Ice transaction <Link to={'/HeLAD/DryIceSales/' + data.ID}>{data.ID}</Link> updated.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Dry Ice Sale {this.state.dryIceSale.TRANS_ID}</div>
				<ChemEdit parent={this} columns={dryIceSaleColumns} data={this.state.dryIceSale} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}
				   validate={(event, columns, data) => this.validateDryIceSale(event, columns, data)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					{this.props.params.transId && <Button id="delete" variant="warning" type="button" className="float-end" style={{ marginRight: '10px' }} onClick={(event) => this.deleteDryIceSale(event)}>Delete</Button>}
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const dryIceSaleColumns = [
	{ Header: 'User Name', accessor: 'USER_NAME', type: 'dropdown', row: 0, col: 0, required: true,
		options: { value: 'USER_NAME', label: 'USER_NAME', entity: 'RECHARGES.DRY_ICE_PEOPLE_SELECT_VW', order: ['LNAME', 'FNAME'] }},
	{ Header: 'Speedtype', accessor: 'COST_CENTER', type: 'select', row: 0, col: 1, required: true,
		options: { value: 'SPEEDTYPE', label: 'PI_SPEEDTYPE', entity: 'RECHARGES.DRY_ICE_CC_SELECT_VW', order: ['PI_NAME', 'SPEEDTYPE'] }}, 
	{ Header: 'Date', accessor: 'TRANS_DATE', type: 'date', row: 0, col: 2, required: true },
	{ Header: 'Quantity', accessor: 'QTY', row: 1, col: 0, required: true },
	{ Header: 'Rate', accessor: 'MATERIAL_RATE', row: 1, col: 1, editable: false },
	{ Header: 'Units', accessor: 'RCH_UNIT', row: 1, col: 2, editable: false },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 2, col: 0 },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 2, col: 1, editable: false }
];

export default withRouter(DryIceSale);
