import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import { Col, Container, Row, Spinner, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';

class ApprovedKey extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			query: this.mergeObject(approvedKey.query, { search: { children: [
				{ Attribute: 'REQ_ROLE_ID', Operator: '=', LongValue: props.params.reqRoleId },
				{ Attribute: 'ROOM_ID', Operator: '=', LongValue: props.params.roomId },
			]}}),	
			approvedKey: {}
		};
	}

	componentDidMount() {
		var self = this;
		this.search({ query: this.state.query }, function(data) {
			if (data.Data.length === 1) {
				// use current date for out date
				data.Data[0].OUT_DATE = new Date();
				
				self.mergeState({ approvedKey: data.Data[0] }, () => self.getName(data.Data[0].PERSON_ID));
			} else {
				self.showAlert('Expected 1 key admin but found ' + data.Data.length);
			}
		});
	}
	
	getName(personId) {
		var self = this;
		this.search({ query: {
			columns: ['FNAME', 'LNAME'],
			entity: 'PEOPLE',
			search: { Attribute: 'PERSON_ID', Operator: '=', LongValue: personId }
		}}, function (data) {
			if (data.Data.length === 1) {
				self.mergeState({ approvedKey: { PERSON_NAME: data.Data[0].FNAME + ' ' + data.Data[0].LNAME }});
			} else {
				self.showAlert('No person found for ID ' + personId);
			}
		});
	}
	
	onChangeApprovedKey(accessor, value) {
		// merge into state
		this.mergeState({ approvedKey: this.setByAccessor({}, accessor, value) });
	}
	
	onSubmit(event) {
		var self = this;
		var key = this.copyObject(this.state.approvedKey);
		
		// convert dates
		key.OUT_DATE = self.dateTimeToMVC(key.OUT_DATE);
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + "/BuildingAccess/UpdateApprovedKey",
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, key: key }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Approved Key updated for <Link to={'/BuildingAccess/People/' + self.state.approvedKey.PERSON_ID}>{self.state.approvedKey.PERSON_NAME}</Link>
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	render() {
	  return (
		<Container fluid>
		  <Row>
			<Col style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
			  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Approved Key {this.isEmpty(this.state.approvedKey.REQ_ROLE_ID) ? <Spinner animation='border' as='div' size='sm' style={{ marginBottom: '4px' }} /> : this.state.approvedKey.REQ_ROLE_ID}</div>
			  <ChemEdit parent={this} columns={approvedKey.columns} data={this.state.approvedKey} user={this.props.user}
			    onChange={(accessor, value) => this.onChangeApprovedKey(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
				<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
				  <Col xs={12}>
				    <Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
				  </Col>
				</Form.Group>
			  </ChemEdit>
			</Col>
		  </Row>
		</Container>
	  );
	}
}

const approvedKey = {
	query: { entity: 'KEYS_APPROVED_VW', search: { Operator: 'and' } },
	columns: [
		{ Header: 'Key', accessor: 'KEY_ID', type: 'select', row: 0, col: 0,
			options: { value: 'KEY_ID', label: 'KEY_INFO', entity: 'ALL_KEYS_VW', order: 'KEY_NUMBER' } },
		{ Header: 'Location', accessor: 'LOCATION', editable: false, row: 0, col: 1 },
		{ Header: 'Check Out Date', accessor: 'OUT_DATE', type: 'date', row: 1, col: 0 },
		{ Header: 'Authorized By', accessor: 'AUTH_PERSON', row: 1, col: 1 },
		{ Header: 'Deposit Paid', accessor: 'DEPOSIT', row: 2, col: 0 },
		{ Header: 'Payment Type', accessor: 'PAYMENT_TYPE', type: 'select', row: 2, col: 1,
			options: [{ value: null, label: 'Select...' },{ value: 'Personal Check', label: 'Personal Check' },{ value: 'Money Order', label: 'Money Order' },{ value: 'Other', label: 'Other' }]},
		{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 3, col: 1 }
	]
};

export default withRouter(ApprovedKey);
