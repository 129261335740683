import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class Authorizations extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			APPLICATION_NAME: '',
			USER_NAME: ''
		};
		
		var initialAuthorizationList = this.copyAuthorizationList();
		initialAuthorizationList.query.search = this.constructSearch(search);
		
		this.state = {
			renderKey: 1,
			authorizationList: initialAuthorizationList,
			search: search,
			searchCount: null
		};
	}
	
	copyAuthorizationList() {
		var authorizationListCopy = this.copyObject(authorizationList);
		for (var i = 0; i < authorizationList.columns.length; i++) {
			if (authorizationList.columns[i].Header) {
				authorizationListCopy.columns[i].Header = authorizationList.columns[i].Header;
			}
			if (authorizationList.columns[i].Cell) {
				authorizationListCopy.columns[i].Cell = authorizationList.columns[i].Cell;
			}
		}
		return authorizationListCopy;
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newAuthorizationList = this.copyAuthorizationList();
		newAuthorizationList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			authorizationList: newAuthorizationList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.APPLICATION_NAME)) search.Children.push({ Attribute: 'APPLICATION_NAME', Operator: 'like', StringValue: '%' + data.APPLICATION_NAME + '%' });
		if (!this.isEmpty(data.USER_NAME)) search.Children.push({ Attribute: 'USER_NAME', Operator: 'like', StringValue: '%' + data.USER_NAME + '%' });
		
		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	addAuthorization() {
		this.props.navigate('/BuildingAccess/Authorizations/new');
	}
	
	editAuthorization(authorizationId) {
		this.props.navigate('/BuildingAccess/Authorizations/' + authorizationId);		
	}
	
	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Authorizations',
			columns: exportColumns,
			entity: 'AUTHORIZATION_VW',
			search: this.constructSearch(this.state.search),
			order: ['APPLICATION_NAME', 'USER_NAME']
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Authorizations</span>
					<ChemEdit parent={this} columns={authorizationSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={3} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_Authorizations' table={this.state.authorizationList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='200px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const authorizationSearch = [
	{ Header: 'Application', accessor: 'APPLICATION_NAME', row: 0, col: 0 },
	{ Header: 'User', accessor: 'USER_NAME', row: 0, col: 1 }
];

const authorizationList = {
	query: { entity: 'AUTHORIZATION_VW', search: null, order: ['APPLICATION_NAME', 'USER_NAME'] },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addAuthorization()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editAuthorization(props.row.values.AUTH_ID)} />, width: 25 },
		{ Header: 'ID', accessor: 'AUTH_ID', width: 70 },
		{ Header: 'Application', accessor: 'APPLICATION_NAME', width: 300,
			Cell: props => <Link to={'/BuildingAccess/Applications/' + props.row.values.APPLICATION_ID}>{props.value}</Link> },
		{ Header: 'User', accessor: 'USER_NAME', width: 300 },
		{ Header: 'Access', accessor: 'ACCESS_NAME', width: 150 },
		{ Header: 'Begin', accessor: 'AUTH_BEG_DATE', width: 100 },
		{ Header: 'End', accessor: 'AUTH_END_DATE', width: 100 },
		{ Header: 'Comments', accessor: 'COMMENTS', width: 400 },
		{ Header: 'APPLICATION_ID', accessor: 'APPLICATION_ID', show: false }
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'AUTH_ID', width: 7 },
	{ Header: 'Application', accessor: 'APPLICATION_NAME', width: 30 },
	{ Header: 'User', accessor: 'USER_NAME', width: 30 },
	{ Header: 'Access', accessor: 'ACCESS_NAME', width: 15 },
	{ Header: 'Begin', accessor: 'AUTH_BEG_DATE', width: 10 },
	{ Header: 'End', accessor: 'AUTH_END_DATE', width: 10 },
	{ Header: 'Comments', accessor: 'COMMENTS', width: 40 }
];

export default withRouter(Authorizations);