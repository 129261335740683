import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import ChemTable from '../ChemTable';
import Tabs from '../Tabs';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Speedtype extends ChemComponent {
	constructor(props) {
		super(props);
		
		var initSpeedtypeColumns = this.copyColumns(speedtypeColumns);
		
		// if this is a new speedtype
		if (!props.params.speedtypeId) {
			// use view that prepends dept code to role ID
			this.findColumn(initSpeedtypeColumns, 'PI_ROLE').options.entity = 'RECHARGES.SPEEDTYPE_NEW_PI_SELECT_VW';
		} else {
			// get rid of stuff that generates speedtypes
			delete this.findColumn(initSpeedtypeColumns, 'BFS_SPEED_TYPE').type;
			delete this.findColumn(initSpeedtypeColumns, 'BFS_SPEED_TYPE').options;
		}
		
		this.state = {
			speedtype: {
				CC_ID: '' 
			},
			history: [],
			speedtypeColumns: initSpeedtypeColumns,
			ras: {},
			renderKey: 0
		}
	}
	
	findColumn(columns, accessor) {
		for (var i = 0; i < columns.length; i++) {
			if (columns[i].accessor === accessor) return columns[i];
		}
		return null;
	}
	
	setContactVisibility(columns, journalType) {
		var hidden = journalType !== 'O' && journalType !== 'U';
		this.findColumn(columns, 'CONTACT_NAME1').hidden = hidden;
		this.findColumn(columns, 'DEPT_ID').hidden = hidden;
		this.findColumn(columns, 'CONTACT_EMAIL1').hidden = hidden;
	}
	
	setCapitalAccountVisibility(columns, capFlag) {
		var hidden = !capFlag;
		this.findColumn(columns, 'CAP_OFFSET_ACCT').hidden = hidden;
		this.findColumn(columns, 'CAP_RCH_ACCT').hidden = hidden;
	}
	
	setFabricationVisibility(columns, fabFlag) {
		var hidden = !fabFlag;
		this.findColumn(columns, 'FAB_RCH_ACCT').hidden = hidden;
	}

	componentDidMount() {
		this.loadSpeedtype();
	}

	loadSpeedtype(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/GetSpeedtype',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				ccId: this.props.params.speedtypeId ? this.props.params.speedtypeId : null
			}
		}).done(function (data) {
			if (data.Success) {
				if (self.props.params.speedtypeId) {
					var speedtype = data.Speedtype;
					
					// convert flags from oracle style to boolean
					speedtype.LABOR_FLAG = speedtype.LABOR_FLAG === 'Y';
					speedtype.SUBSIDY_FLAG = speedtype.SUBSIDY_FLAG === 'Y';
					speedtype.CAP_PROJECT_FLAG = speedtype.CAP_PROJECT_FLAG === 'Y';
					speedtype.FABRICATION_FLAG = speedtype.FABRICATION_FLAG === 'Y';
					speedtype.SPONSORED_FLAG = speedtype.SPONSORED_FLAG === 'Y';
					speedtype.RECHARGE_FLAG = speedtype.RECHARGE_FLAG === 'Y';
					
					// convert dates to javascript
					speedtype.ENDING_DATE = self.dateTimeToMVC(self.getDate(speedtype.ENDING_DATE));
					speedtype.END_VALID_DATE = self.dateTimeToMVC(self.getDate(speedtype.END_VALID_DATE));
					
					// add pi role ID's to PI lookups
					var speedtypeColumns = self.copyColumns(self.state.speedtypeColumns);
					if (!self.isEmpty(speedtype.PI_ROLE)) {
						self.findColumn(speedtypeColumns, 'PI_ROLE').options.search.Children.push({ Attribute: 'ROLE_ID', Operator: '=', LongValue: speedtype.PI_ROLE });
					}
					if (!self.isEmpty(speedtype.OTHER_PI_ROLE)) {
						self.findColumn(speedtypeColumns, 'OTHER_PI_ROLE').options.search.Children.push({ Attribute: 'ROLE_ID', Operator: '=', LongValue: speedtype.OTHER_PI_ROLE });
					}
					// if there are multiple RAs, make it non-editable for now
					if (!self.isEmpty(speedtype.RA) && speedtype.RA.indexOf(',') >= 0) {
						var raIdx = self.findColumnByAccessor(speedtypeColumns, 'RA');
						speedtypeColumns[raIdx] = { Header: 'RA', accessor: 'RA_NAME', row: 1, col: 1, editable: false };
					}
					
					// set visibility of fields
					self.setContactVisibility(speedtypeColumns, speedtype.JOURNAL_TYPE);
					self.setCapitalAccountVisibility(speedtypeColumns, speedtype.CAP_PROJECT_FLAG);
					self.setFabricationVisibility(speedtypeColumns, speedtype.FABRICATION_FLAG);
					
					// set RCH_ACCT
					if (speedtype.FABRICATION_FLAG) {
						speedtype.FAB_RCH_ACCT = speedtype.RCH_ACCT;
					}
					if (speedtype.CAP_PROJECT_FLAG) {
						speedtype.CAP_RCH_ACCT = speedtype.RCH_ACCT;
					}
					
					// set ending date - 30
					speedtype.ENDING_DATE_30 = self.getEndingDate30(speedtype.ENDING_DATE);
					
					// set CREATED, MODIFIED
					speedtype.CREATED = self.dateToString(self.getDate(speedtype.CREATION_DATE)) + ' ' + speedtype.CREATED_BY;
					speedtype.MODIFIED = self.dateToString(self.getDate(speedtype.MODIF_DATE)) + ' ' + speedtype.MODIF_BY;
					
					// get history
					var history = speedtype.history;
					delete speedtype.history;
					
					self.mergeState({
						speedtype: speedtype,
						history: history,
						speedtypeColumns: speedtypeColumns,
						ras: data.RAsByPI,
						renderKey: self.state.renderKey + 1
					}, () => {
						if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
					});
				} else {
					self.mergeState({
						speedtype: {
							CC_ID: '(new)',
							BFS_SPEED_TYPE: '',
							PI_ROLE: null,
							RA: null,
							OTHER_PI_ROLE: null,
							BFS_BUSINESS_UNIT: '',
							BFS_FUND: '',
							BFS_ORG: '',
							BFS_PROGRAM: '',
							BFS_PROJECT: '',
							BFS_FLEX_FIELD: '',
							AGENCY_NAME: '',
							AGENCY_NUM: '',
							ENDING_DATE: null,
							ENDING_DATE_30: null,
							JOURNAL_TYPE: '',
							CONTACT_NAME1: '',
							DEPT_ID: '',
							CONTACT_EMAIL1: '',
							START_VALID_DATE: null,
							END_VALID_DATE: null,
							LABOR_FLAG: false,
							SUBSIDY_FLAG: false,
							CAP_PROJECT_FLAG: false,
							RECHARGE_FLAG: true,
							CAP_OFFSET_ACCT: '',
							CAP_RCH_ACCT: '',
							FABRICATION_FLAG: false,
							FAB_RCH_ACCT: '',
							SPONSORED_FLAG: false,
							COMMENTS: '',
							CREATION_DATE: null,
							CREATED_BY: null,
							RCH_ACCT: null,
							CONTACT_ID: null
						},
						ras: data.RAsByPI,
						renderKey: self.state.renderKey + 1
					});
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
	
	getEndingDate30(value) {
		var endingDate30 = '';
		if (!this.isEmpty(value)) {
			// set ending date - 30
			var endingDate = this.getDate(value);
			endingDate30 = new Date(endingDate.valueOf());
			endingDate30.setDate(endingDate.getDate() - 30);
			endingDate30 = this.dateToString(endingDate30);
		}
		return endingDate30;
	}
		
	onChange(accessor, value) {
		var speedtype = this.copyObject(this.state.speedtype);
		var speedtypeColumns = this.copyColumns(this.state.speedtypeColumns);
		if (accessor === 'BFS_SPEED_TYPE' && (value === generateGlobalSpeedtype || value === generateLocalSpeedtype)) {
			this.generateSpeedtype(value);
		} else {
			this.setByAccessor(speedtype, accessor, value);
			if (accessor === 'ENDING_DATE') {
				this.setByAccessor(speedtype, 'ENDING_DATE_30', this.getEndingDate30(value));
			}
			if (accessor === 'JOURNAL_TYPE') {
				this.setContactVisibility(speedtypeColumns, value);
			}
			if (accessor === 'CAP_PROJECT_FLAG') {
				this.setCapitalAccountVisibility(speedtypeColumns, value);
			}
			if (accessor === 'FABRICATION_FLAG') {
				this.setFabricationVisibility(speedtypeColumns, value);
			}
			if (accessor === 'PI_ROLE') {
				var strkey = '' + value;
				// strip dept code from pi role ID
				if (/^[A-Z][A-Z].*/.test(strkey)) strkey = strkey.substring(2);
				if (this.state.ras.hasOwnProperty(strkey)) {
					this.setByAccessor(speedtype, 'RA', this.state.ras[strkey]);
				}
			}
			this.mergeState({ speedtype: speedtype, speedtypeColumns: speedtypeColumns, renderKey: this.state.renderKey + 1 });
		}
	}
	
	generateSpeedtype(value) {
		var self = this;
		// make sure data is present
		if (this.state.speedtype.PI_ROLE === null ||
			this.state.speedtype.BFS_ORG.length < 4) {
			this.showAlert('Speedtype Error', 'Please select Primary PI and enter 4+ digit Dept ID');
		} else {
			// split PI_ROLE into dept code and role ID
			var deptCode = value === generateGlobalSpeedtype ? this.state.speedtype.PI_ROLE.substring(0, 2) : 'DD';
			var roleID = this.state.speedtype.PI_ROLE.substring(2);
			
			this.ajax({
				type: 'POST',
				url: self.getConfig().host + '/CRS/GenerateSpeedtype',
				data: { 
					__RequestVerificationToken: self.props.user.antiForgeryToken,
					deptcode: deptCode,
					deptid: this.state.speedtype.BFS_ORG,
					pirole: roleID
				}
			}).done(function (data) {
				if (data.Success) {
					var speedtype = self.copyObject(self.state.speedtype);
					self.setByAccessor(speedtype, 'BFS_SPEED_TYPE', data.Speedtype);
					self.mergeState({ speedtype: speedtype });
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});			
		}		
	}
		
	deleteSpeedtype(event) {
		var self = this;
		
		this.showOKCancel('Confirm Close Speedtype', 'Close Speedtype ' + this.props.params.speedtypeId + '?', (okClicked) => {
			if (okClicked) {
				// prepare speedtype for deletion
				var dtNow = new Date();
				
				var speedtype = {
					CC_ID: self.state.speedtype.CC_ID,
					END_VALID_DATE: self.dateTimeToMVC(self.isEmpty(self.state.speedtype.END_VALID_DATE) ? dtNow : self.getDate(self.state.speedtype.END_VALID_DATE)),
					COMMENTS: self.state.speedtype.COMMENTS,
					MODIF_BY: self.props.user.name,
					MODIF_DATE: self.dateTimeToMVC(dtNow)
				};
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'ACCOUNTING.CC_LIST', data: speedtype }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Speedtype {self.props.params.speedtypeId} closed.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
	
	validateSpeedtype(event, columns, data) {
		return this.validate(columns, data);
	}
		
	onSubmit(event) {
		var self = this;		
		
		// make copy of data
		var speedtype = this.copyObject(this.state.speedtype);

		// choose recharge account
		if (speedtype.CAP_PROJECT_FLAG) {
			speedtype.RCH_ACCT = speedtype.CAP_RCH_ACCT;
		} else if (speedtype.FABRICATION_FLAG) {
			speedtype.RCH_ACCT = speedtype.FAB_RCH_ACCT;
		}

		// convert dates
		speedtype.ENDING_DATE = this.dateTimeToMVC(this.getDate(speedtype.ENDING_DATE));
		speedtype.END_VALID_DATE = this.dateTimeToMVC(this.getDate(speedtype.END_VALID_DATE));
		speedtype.CREATION_DATE = this.dateTimeToMVC(this.getDate(speedtype.CREATION_DATE));
		
		// convert flags
		speedtype.LABOR_FLAG = speedtype.LABOR_FLAG ? 'Y' : 'N';
		speedtype.SUBSIDY_FLAG = speedtype.SUBSIDY_FLAG ? 'Y' : 'N';
		speedtype.CAP_PROJECT_FLAG = speedtype.CAP_PROJECT_FLAG ? 'Y' : 'N';
		speedtype.FABRICATION_FLAG = speedtype.FABRICATION_FLAG ? 'Y' : 'N';
		speedtype.SPONSORED_FLAG = speedtype.SPONSORED_FLAG ? 'Y' : 'N';
		speedtype.RECHARGE_FLAG = speedtype.RECHARGE_FLAG ? 'Y' : 'N';
		
		// if this is a new speedtype
		if (!this.props.params.speedtypeId) {
			// set ID to sequence name
			speedtype.CC_ID = 'seq_cc_list.nextval';
			
			// strip dept code from pi role ID
			if (/^[A-Z][A-Z].*/.test(speedtype.PI_ROLE)) {
				speedtype.PI_ROLE = speedtype.PI_ROLE.substring(2);
			}
		}			
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/CRS/UpdateSpeedtype',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, speedtype: speedtype }
		}).done(function (data) {
			if (data.Success) {
				var speedtypeId = data.ID;
				var verb = self.props.params.speedtypeId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Speedtype <Link to={'/CRS/Speedtypes/' + speedtypeId}>{speedtypeId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Speedtype {this.state.speedtype.CC_ID}</div>
				  <Tabs>
					<div label='General Info'>
					  <Container fluid>
						<Row>
						  <Col>
							<ChemEdit parent={this} columns={this.state.speedtypeColumns} data={this.state.speedtype} user={this.props.user} renderKey={this.state.renderKey} 
							   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}
							   validate={(event, columns, data) => this.validateSpeedtype(event, columns, data)}>
							  <Form.Group>
								<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
								{this.props.params.speedtypeId && <Button id="delete" variant="warning" type="button" className="float-end"
								  style={{ marginRight: '20px' }} onClick={(event) => this.deleteSpeedtype(event)}>Close</Button>} 
							  </Form.Group>
							</ChemEdit>
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='History'>
					  <Container fluid>
						<Row>
						  <Col>
							<ChemTable renderKey={this.state.renderKey} parent={this} name='CRS_Speedtype_History' columns={historyColumns} data={this.state.history} />
							<div style={{ paddingBottom: '10px' }} />
						  </Col>
						</Row>
					  </Container>
					</div>
				  </Tabs>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const generateGlobalSpeedtype = 'Generate Speedtype from Dept. ID and PI';
const generateLocalSpeedtype = 'Generate "Dummy" Speedtype from Dept. ID and PI';

const speedtypeColumns = [
	{ Header: 'Speedtype', accessor: 'BFS_SPEED_TYPE', type: 'dropdown', maxlength: 10, row: 0, col: 0, required: true,
		options: [{ label: generateGlobalSpeedtype }, { label: generateLocalSpeedtype }]},
	{ Header: 'PI', accessor: 'PI_ROLE', row: 0, col: 1, type: 'select', required: true,
		options: { value: 'ROLE_ID', label: 'PI_NAME', entity: 'RECHARGES.SPEEDTYPE_EDIT_PI_SELECT_VW', order: 'PI_NAME', 
			search: { Operator: 'or', Children: [ { Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' }]}}},
	{ Header: 'RA', accessor: 'RA', row: 1, col: 0, type: 'select',
		options: { value: 'PERSON_ID', label: 'RA', entity: 'RECHARGES.SPEEDTYPE_EDIT_RA_SELECT_VW', order: 'RA' }},
	{ Header: 'Other PI', accessor: 'OTHER_PI_ROLE', row: 1, col: 1, type: 'select',
		options: { value: 'ROLE_ID', label: 'PI_NAME', entity: 'RECHARGES.SPEEDTYPE_EDIT_PI_SELECT_VW', order: 'PI_NAME', 
			search: { Operator: 'or', Children: [ { Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' }]}}},
	{ Header: 'Unit', accessor: 'BFS_BUSINESS_UNIT', maxlength: 5, row: 2, col: 0 },
	{ Header: 'Fund', accessor: 'BFS_FUND', maxlength: 5, row: 2, col: 1 },
	{ Header: 'Dept. ID', accessor: 'BFS_ORG', maxlength: 5, row: 3, col: 0, required: true },
	{ Header: 'Program', accessor: 'BFS_PROGRAM', maxlength: 2, row: 3, col: 1 },
	{ Header: 'CF1', accessor: 'BFS_PROJECT', maxlength: 6, row: 4, col: 0 },
	{ Header: 'CF2', accessor: 'BFS_FLEX_FIELD', maxlength: 5, row: 4, col: 1 },
	{ Header: 'Fund Name', accessor: 'AGENCY_NAME', maxlength: 50, row: 5, col: 0 },
	{ Header: 'Award Number', accessor: 'AGENCY_NUM', maxlength: 15, row: 5, col: 1 },
	{ Header: 'Fund End Date', accessor: 'ENDING_DATE', row: 6, col: 0, type: 'date' },
	{ Header: 'Funding End -30 days', accessor: 'ENDING_DATE_30', row: 6, col: 1, editable: false },
	{ Header: 'Outside', accessor: 'JOURNAL_TYPE', row: 7, col: 0, type: 'select',
		options: [{ value: 'E', label: 'Select...' }, { value: 'O', label: 'Non UC' }, { value: 'U', label: 'IOC' }]},
	{ Header: 'Contact Name', accessor: 'CONTACT_NAME1', maxlength: 50, row: 7, col: 1, hidden: true },
	{ Header: 'Department', accessor: 'DEPT_ID', row: 8, col: 0, type: 'select', hidden: true,
		options: { value: 'DEPARTMENT_ID', label: 'DEPT_NAME', entity: 'DEPARTMENT', order: 'DEPT_NAME' }},
	{ Header: 'Email', accessor: 'CONTACT_EMAIL1', maxlength: 50, row: 8, col: 1, hidden: true },
	{ Header: 'Info Validity Start', accessor: 'START_VALID_DATE', row: 9, col: 0, editable: false,
		Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
	{ Header: 'Info Validity End', accessor: 'END_VALID_DATE', row: 9, col: 1, type: 'date' },
	{ Header: '100% Subsidy', accessor: 'LABOR_FLAG', row: 10, col: 0, type: 'checkbox' },
	{ Header: 'No Labor Subsidy', accessor: 'SUBSIDY_FLAG', row: 10, col: 1, type: 'checkbox' },
	{ Header: 'Capital Project', accessor: 'CAP_PROJECT_FLAG', row: 11, col: 0, type: 'checkbox' },
	{ Header: 'Recharge', accessor: 'RECHARGE_FLAG', row: 11, col: 1, type: 'checkbox', subscript: '(selection for recharge systems)' },
	{ Header: 'Income Account', accessor: 'CAP_OFFSET_ACCT', maxlength: 5, row: 12, col: 0 , hidden: true },
	{ Header: 'Recharge Debit Account', accessor: 'CAP_RCH_ACCT', maxlength: 5, row: 12, col: 1, hidden: true },
	{ Header: 'Fabrication', accessor: 'FABRICATION_FLAG', row: 13, col: 0, type: 'checkbox' },
	{ Header: 'Account Code', accessor: 'FAB_RCH_ACCT', maxlength: 5, row: 13, col: 1, hidden: true },
	{ Header: 'Sponsored', accessor: 'SPONSORED_FLAG', row: 14, col: 0, type: 'checkbox', subscript: 'Updated with CalAnswers Report upload' },
	{ Header: 'Comments', accessor: 'COMMENTS', maxlength: 500, row: 14, col: 1, type: 'textarea' },
	{ Header: 'Speedtype Entered', accessor: 'CREATED', row: 15, col: 0, editable: false },
	{ Header: 'Record Last Modified', accessor: 'MODIFIED', row: 15, col: 1, editable: false }
];

const historyColumns = [
	{ Header: 'ID', accessor: 'CC_ID', Cell: props => <Link to={'/CRS/Speedtypes/' + props.value}>{props.value}</Link> },
	{ Header: 'Child ID', accessor: 'CHILD_ID' },
	{ Header: 'Chart String', accessor: 'CHART_STRING' },
	{ Header: 'Start Valid', accessor: 'START_VALID_DATE' },
	{ Header: 'End Valid', accessor: 'END_VALID_DATE' },
	{ Header: 'Outside', accessor: 'OUTSIDE_FLAG' },
	{ Header: 'PI', accessor: 'PI_NAME', Cell: props => <Link to={'/BuildingAccess/Roles/' + props.row.values.PI_ROLE}>{props.value}</Link> },
	{ Header: 'Fund', accessor: 'AGENCY_NAME', Cell: props => { return props.value + ' / ' + props.row.values.AGENCY_NUM } },
	{ Header: 'Flags', accessor: 'RECHARGE_FLAG', Cell: props => {
		var display = props.value;
		if (!props.parent.isEmpty(props.row.values.CAP_PROJECT)) display += ' | ' + props.row.values.CAP_PROJECT;
		if (!props.parent.isEmpty(props.row.values.FABRICATION_FLAG)) display += ' | ' + props.row.values.FABRICATION_FLAG;
		return display;
	}},
	{ Header: 'Subsidy', accessor: 'SUBSIDY_FLAG' },
	{ Header: 'Sponsored', accessor: 'SPONSORED_FLAG' },
	{ Header: 'Fund Ends', accessor: 'ENDING_DATE' },
	{ Header: 'RA', accessor: 'RA' },
	{ Header: 'Modified', accessor: 'MODIF_DATE', Cell: props => {
		if (!props.parent.isEmpty(props.row.values.MODIF_BY)) {
			return props.value + ' ' + props.row.values.MODIF_BY;
		} else {
			return '';
		}
	}},
	{ Header: 'PI_ROLE', accessor: 'PI_ROLE', show: false },
	{ Header: 'AGENCY_NUM', accessor: 'AGENCY_NUM', show: false },
	{ Header: 'CAP_PROJECT', accessor: 'CAP_PROJECT', show: false },
	{ Header: 'FABRICATION_FLAG', accessor: 'FABRICATION_FLAG', show: false },
	{ Header: 'MODIF_BY', accessor: 'MODIF_BY', show: false }
];

export default withRouter(Speedtype);
