import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import { Col, Container, Row, Spinner, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';

class IssuedKey extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			query: this.mergeObject(issuedKey.query, { search: { LongValue: this.props.params.keyAdminId }}),	
			issuedKey: {
				KEY_ADMIN_ID: null
			}
		};
	}

	componentDidMount() {
		var self = this;
		this.search({ query: this.state.query }, function(data) {
			if (data.Data.length === 1) {
				// convert dates
				data.Data[0].LETTER_SENT = self.getDate(data.Data[0].LETTER_SENT);
				data.Data[0].DATE_LOST = self.getDate(data.Data[0].DATE_LOST);
				data.Data[0].OUT_DATE = self.getDate(data.Data[0].OUT_DATE);
				data.Data[0].RETURN_DATE = self.getDate(data.Data[0].RETURN_DATE);
				data.Data[0].RETURN_DEPOSIT = self.getDate(data.Data[0].RETURN_DEPOSIT);
				
				// compose change info
				data.Data[0].CREATED = self.composeChangeInfo(data.Data[0].CREATE_BY, data.Data[0].CREATE_DATE);
				data.Data[0].MODIFIED = self.composeChangeInfo(data.Data[0].MODIF_BY, data.Data[0].MODIF_DATE);
				
				// calculate lost flag
				data.Data[0].LOST_FLAG = data.Data[0].KEY_STATUS === 5;
				
				self.mergeState({ issuedKey: data.Data[0] }, () => self.getName(data.Data[0].PERSON_ID));
			} else {
				self.showAlert('Expected 1 key admin but found ' + data.Data.length);
			}
		});
	}
	
	getName(personId) {
		var self = this;
		this.search({ query: {
			columns: ['FNAME', 'LNAME'],
			entity: 'PEOPLE',
			search: { Attribute: 'PERSON_ID', Operator: '=', LongValue: personId }
		}}, function (data) {
			if (data.Data.length === 1) {
				self.mergeState({ issuedKey: { PERSON_NAME: data.Data[0].FNAME + ' ' + data.Data[0].LNAME }});
			} else {
				self.showAlert('No person found for ID ' + personId);
			}
		});
	}
	
	onChangeIssuedKey(accessor, value) {
		// merge into state
		this.mergeState({ issuedKey: this.setByAccessor({}, accessor, value) });
	}
	
	onSubmit(event) {
		var self = this;
		var key = this.copyObject(this.state.issuedKey);
		
		// convert dates
		key.LETTER_SENT = self.dateTimeToMVC(key.LETTER_SENT);
		key.DATE_LOST = self.dateTimeToMVC(key.DATE_LOST);
		key.OUT_DATE = self.dateTimeToMVC(key.OUT_DATE);
		key.RETURN_DATE = self.dateTimeToMVC(key.RETURN_DATE);
		key.RETURN_DEPOSIT = self.dateTimeToMVC(key.RETURN_DEPOSIT);
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + "/BuildingAccess/UpdateIssuedKey",
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, key: key }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						<Link to={'/BuildingAccess/Keys/' + self.state.issuedKey.KEY_ID}>Issued Key</Link> updated for <Link to={'/BuildingAccess/People/' + self.state.issuedKey.PERSON_ID}>{self.state.issuedKey.PERSON_NAME}</Link>
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	render() {
	  return (
		<Container fluid>
		  <Row>
			<Col style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
			  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Issued Key {this.isEmpty(this.state.issuedKey.KEY_ADMIN_ID) ? <Spinner animation='border' as='div' size='sm' style={{ marginBottom: '4px' }} /> : this.state.issuedKey.KEY_ADMIN_ID}</div>
			  <ChemEdit parent={this} columns={issuedKey.columns} data={this.state.issuedKey} user={this.props.user}
			    onChange={(accessor, value) => this.onChangeIssuedKey(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
				<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
				  <Col xs={12}>
				    <Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
				  </Col>
				</Form.Group>
			  </ChemEdit>
			</Col>
		  </Row>
		</Container>
	  );
	}
}

const issuedKey = {
	query: { entity: 'KEYS_CHECKED_OUT_VW', search: { Attribute: 'KEY_ADMIN_ID', Operator: '=' }},
	columns: [
		{ Header: 'Key', accessor: 'KEY_INFO', editable: false, row: 0, col: 0 },
		{ Header: 'Type', accessor: 'TYPE_NAME', editable: false, row: 0, col: 1 },
		{ Header: 'Location', accessor: 'LOCATION', editable: false, row: 0, col: 2 },
		{ Header: 'Authorized By', accessor: 'AUTHORIZED_BY', row: 1, col: 0 },
		{ Header: 'Checked Out', accessor: 'OUT_DATE', type: 'date', row: 1, col: 1 },
		{ Header: 'Letter Sent', accessor: 'LETTER_SENT', type: 'date', row: 2, col: 0 },
		{ Header: 'Deposit Paid', accessor: 'DEPOSIT', row: 2, col: 1 },
		{ Header: 'Payment Type', accessor: 'PAYMENT_TYPE', type: 'select', row: 2, col: 2,
			options: [{ value: null, label: 'Select...' },{ value: 'Personal Check', label: 'Personal Check' },{ value: 'Money Order', label: 'Money Order' },{ value: 'Other', label: 'Other' }]},
		{ Header: 'Returned', accessor: 'RETURN_DATE', type: 'date', row: 3, col: 0 },
		{ Header: 'Deposit Returned', accessor: 'RETURN_DEPOSIT', type: 'date', row: 3, col: 1 },
		{ Header: 'Lost', accessor: 'LOST_FLAG', type: 'checkbox', row: 4, col: 0 },
		{ Header: 'Date Lost', accessor: 'DATE_LOST', type: 'date', row: 4, col: 1 },
		{ Header: 'Police Report', accessor: 'POLICE_RPT', row: 4, col: 2 },
		{ Header: 'Comments', accessor: 'COMMENTS', row: 5, col: 0 },
		{ Header: 'Created', accessor: 'CREATED', editable: false, row: 5, col: 1 },
		{ Header: 'Modified', accessor: 'MODIFIED', editable: false, row: 5, col: 2 }
	]
};

export default withRouter(IssuedKey);
