import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Department extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			department: {
				DEPARTMENT_ID: null,
				DEPT_NAME: '',
				COMMENTS: '',
				CREATE_DATE: null,
				CREATE_BY: '',
				MODIF_DATE: null,
				MODIF_BY: '',
				MODIFIED: '',
				PERSON_COUNT: ''
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadDepartment();
	}

	loadDepartment(event) {
		var self = this;
		
		if (this.props.params.departmentId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'DEPARTMENT_VW',
					search: { Attribute: 'DEPARTMENT_ID', Operator: '=', LongValue: this.props.params.departmentId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length === 1) {
						var department = data.Data[0];
																		
						self.mergeState({
							department: department,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Department Error', data.Data.length + ' departments found for ID ' + self.props.params.departmentId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				department: {
					DEPARTMENT_ID: '(new)'
				}
			});
		}
	}
		
	onChange(accessor, value) {
		var department = this.copyObject(this.state.department);
		this.setByAccessor(department, accessor, value);
		this.mergeState({ department: department, renderKey: this.state.renderKey + 1 });
	}
		
	deleteDepartment(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Department', 'Delete Department ' + this.props.params.departmentId + '?', (okClicked) => {
			if (okClicked) {
				// prepare department for deletion
				var department = self.copyObject(self.state.department);
				department.DELETE_FLAG = 'Y';
				department.CREATE_DATE = self.dateTimeToMVC(self.getDate(department.CREATE_DATE));
				department.MODIF_DATE = self.dateTimeToMVC(new Date());
				department.MODIF_BY = self.props.user.name;
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'DEPARTMENT', data: department }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Department {self.props.params.departmentId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
		
	onSubmit(event) {
		var self = this;		
		var dtNow = this.dateTimeToMVC(new Date());
		var currentUser = this.props.user.name;
		
		// make copy of data
		var department = this.copyObject(this.state.department);
		
		// if this is a new department, set ID to sequence name
		var url;
		if (this.props.params.departmentId) {
			url = this.getConfig().host + '/Home/Update';
			department.CREATE_DATE = this.dateTimeToMVC(this.getDate(department.CREATE_DATE));
			department.MODIF_DATE = dtNow;
			department.MODIF_BY = currentUser;
		} else {
			url = this.getConfig().host + '/Home/Insert';
			department.DEPARTMENT_ID = 'org_dept_seq.nextval';
			department.CREATE_DATE = dtNow;
			department.CREATE_BY = currentUser;
			department.MODIF_DATE = null;
			department.MODIF_BY = null;
			department.DELETE_FLAG = null;
		}			
		
		this.ajax({
			type: 'POST',
			url: url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'DEPARTMENT', data: department }
		}).done(function (data) {
			if (data.Success) {
				var departmentId = self.props.params.departmentId ? department.DEPARTMENT_ID : data.ID;
				var verb = self.props.params.departmentId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Department <Link to={'/BuildingAccess/Departments/' + departmentId}>{departmentId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Department {this.state.department.DEPARTMENT_ID}</div>
				<ChemEdit parent={this} columns={departmentColumns} data={this.state.department} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					{this.props.params.departmentId && <Button id="delete" variant="warning" type="button" className="float-end"
					  style={{ marginRight: '20px' }} onClick={(event) => this.deleteDepartment(event)}>Delete</Button>} 
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const departmentColumns = [
	{ Header: 'Name', accessor: 'DEPT_NAME', required: true },
	{ Header: 'Comments', accessor: 'COMMENTS' },
	{ Header: '# in Dept', accessor: 'PERSON_COUNT', editable: false },
	{ Header: 'Last Modified', accessor: 'MODIFIED', editable: false },
	{ Header: 'Deleted', accessor: 'DELETE_FLAG', editable: false }
];

export default withRouter(Department);
