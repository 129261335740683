import React from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class GN2User extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			editable: props.editable,
			user: {
				RECHARGE_ID: ''
			},
			userColumns: this.copyColumns(userColumns),
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadUser();
	}

	loadUser(event) {
		var self = this;
		
		if (this.props.params.rechargeId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/HeLAD/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'METER_RECHARGE',
					search: { Attribute: 'RECHARGE_ID', Operator: '=', LongValue: this.props.params.rechargeId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var user = data.Data[0];
						self.mvc2js(user, userColumns);
						
						// multiply percentage by 100 so as not to confuse people
						user.PERCENTAGE = '' + user.PERCENTAGE * 100;
						// truncate any decimal places
						var dotIdx = user.PERCENTAGE.indexOf('.');
						if (dotIdx >= 0) user.PERCENTAGE = user.PERCENTAGE.substring(0, dotIdx);
						
						// add current PI_ID to PI lookup in case they are no longer active
						var newUserColumns = self.copyColumns(userColumns);
						if (!self.isEmpty(user.PI_ID)) {
							var piIdx = self.findColumnByAccessor(newUserColumns, 'PI_ID');
							newUserColumns[piIdx].options.search.Children.push({ Attribute: 'PERSON_ID', Operator: '=', LongValue: user.PI_ID });
						}
						
						self.mergeState({
							user: user,
							userColumns: newUserColumns,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('User Error', data.Data.length + ' Users found for ID ' + self.props.params.rechargeId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				user: {
					RECHARGE_ID: '(new)',
					ACTIVE_FLAG: true
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var user = this.copyObject(this.state.user);
		this.setByAccessor(user, accessor, value);
		this.mergeState({ user: user, renderKey: this.state.renderKey + 1 });
	}
		
	onSubmit(event) {
		var self = this;
		
		// make copy of data
		var user = this.copyObject(this.state.user);

		user.LAST_MODIF = new Date();
		user.MODIF_BY = this.props.user.name;
		this.js2mvc(user, userColumns);
		// convert percentage to decimal
		if (this.isNumeric(user.PERCENTAGE)) {
			user.PERCENTAGE = user.PERCENTAGE / 100.0;
		} else {
			user.PERCENTAGE = 1;
		}
		
		// if this is a new user
		if (!this.props.params.rechargeId) {
			// set ID to sequence name
			user.RECHARGE_ID = 'recharge_id_seq.nextval';
			user.SERVICE_ID = 21;
		}
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/HeLAD/UpdateGN2User',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, user: user }
		}).done(function (data) {
			if (data.Success) {
				var rechargeId = self.props.params.rechargeId || data.ID;
				var verb = self.props.params.rechargeId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					var totalPercentage = Math.round(data.TotalPercentage * 100);
					return (<>
						User <Link to={'/HeLAD/GN2Users/' + rechargeId}>{rechargeId}</Link> {verb}.
						{totalPercentage !== 100 && <>
							<br />
							Total percentage for user(s) {data.ExistingRecharges.map((existingRechargeId, index) => {
								return (<React.Fragment key={index}>
									{index > 0 && <>, </>}
									<Link to={'/HeLAD/GN2Users/' + existingRechargeId}>{existingRechargeId}</Link>
								</React.Fragment>);
							})} adds up to {totalPercentage}%. Please adjust percentages as necessary.
						</>}
						{data.PIRecharges.length > 0 && <>
							<br />
							This PI is already associated with this meter through {data.PIRecharges.map((piRechargeId, index) => {
								return (<React.Fragment key={index}>
									{index > 0 && <>, </>}
									<Link to={'/HeLAD/GN2Users/' + piRechargeId}>{piRechargeId}</Link>
								</React.Fragment>);
							})}. Please ensure that this is correct.
						</>}
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {

		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col xs={6}>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Recharge {this.state.user.RECHARGE_ID}&nbsp;&nbsp;
							{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
							  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
						  </div>
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={this.state.userColumns} data={this.state.user} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)} editable={this.state.editable}>
						  <Form.Group>
							{this.state.editable && <Button id="save" variant="warning" type="submit" className="float-end">Save</Button>}
						  </Form.Group>
						</ChemEdit>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const userColumns = [
	{ Header: 'Meter', accessor: 'METER_ID', type: 'select', row: 0, col: 0,
		options: { value: 'METER_ID', label: 'METER_DESC', entity: 'LIQUID_AIR.GN2_USER_METER_SELECT_VW', order: 'BUILDING' }},
	{ Header: 'PI', accessor: 'PI_ID', type: 'select', row: 0, col: 1,
		options: { value: 'PERSON_ID', label: 'PI_NAME', entity: 'LIQUID_AIR.GN2_USER_PI_SELECT_VW', order: 'PI_NAME',
			search: { Operator: 'or', Children: [{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' }]}}}, 
	{ Header: 'SpeedType', accessor: 'COST_CENTER', row: 0, col: 2 },
	{ Header: 'Percentage', accessor: 'PERCENTAGE', row: 1, col: 0 },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 1, col: 1 },
	{ Header: 'Active', accessor: 'VALID_FLAG', type: 'checkbox', row: 1, col: 2 },
	{ Header: 'Last Modified', accessor: 'LAST_MODIF', type: 'date', row: 2, col: 0, editable: false },
	{ Header: 'Modified By', accessor: 'MODIF_BY', row: 2, col: 1, editable: false }
];

export default withRouter(GN2User);
