import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class KeyStatus extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			keyStatus: {
				STATUS_ID: null,
				STATUS_NAME: '',
				COMMENTS: '',
				CREATE_DATE: null,
				CREATE_BY: '',
				MODIF_DATE: null,
				MODIF_BY: '',
				CREATED: '',
				MODIFIED: ''
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadKeyStatus();
	}

	loadKeyStatus(event) {
		var self = this;
		
		if (this.props.params.keyStatusId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'KEY_STATUS',
					search: { Attribute: 'STATUS_ID', Operator: '=', LongValue: this.props.params.keyStatusId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length === 1) {
						var keyStatus = data.Data[0];
												
						// compose change info
						keyStatus.CREATED = self.composeChangeInfo(keyStatus.CREATE_BY, keyStatus.CREATE_DATE);
						keyStatus.MODIFIED = self.composeChangeInfo(keyStatus.MODIF_BY, keyStatus.MODIF_DATE);
						
						self.mergeState({
							keyStatus: keyStatus,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Key Status Error', data.Data.length + ' key statuses found for ID ' + self.props.params.keyStatusId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				keyStatus: {
					STATUS_ID: '(new)'
				}
			});
		}
	}
		
	onChange(accessor, value) {
		var keyStatus = this.copyObject(this.state.keyStatus);
		this.setByAccessor(keyStatus, accessor, value);
		this.mergeState({ keyStatus: keyStatus, renderKey: this.state.renderKey + 1 });
	}
		
	deleteKeyStatus(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Key Status', 'Delete Key Status ' + this.props.params.keyStatusId + '?', (okClicked) => {
			if (okClicked) {
				// prepare key status for deletion
				var keyStatus = self.copyObject(self.state.keyStatus);
				keyStatus.DELETE_FLAG = 'Y';
				keyStatus.CREATE_DATE = self.dateTimeToMVC(self.getDate(keyStatus.CREATE_DATE));
				keyStatus.MODIF_DATE = self.dateTimeToMVC(new Date());
				keyStatus.MODIF_BY = self.props.user.name;
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'KEY_STATUS', data: keyStatus }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Key Status {self.props.params.keyStatusId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
		
	onSubmit(event) {
		var self = this;		
		var dtNow = this.dateTimeToMVC(new Date());
		var currentUser = this.props.user.name;
		
		// make copy of data
		var keyStatus = this.copyObject(this.state.keyStatus);
		
		// if this is a new room, set ID to sequence name
		var url;
		if (this.props.params.keyStatusId) {
			url = this.getConfig().host + '/Home/Update';
			keyStatus.CREATE_DATE = this.dateTimeToMVC(this.getDate(keyStatus.CREATE_DATE));
			keyStatus.MODIF_DATE = dtNow;
			keyStatus.MODIF_BY = currentUser;
		} else {
			url = this.getConfig().host + '/Home/Insert';
			keyStatus.STATUS_ID = null;
			keyStatus.CREATE_DATE = dtNow;
			keyStatus.CREATE_BY = currentUser;
			keyStatus.MODIF_DATE = null;
			keyStatus.MODIF_BY = null;
		}			
		
		this.ajax({
			type: 'POST',
			url: url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'KEY_STATUS', data: keyStatus }
		}).done(function (data) {
			if (data.Success) {
				var keyStatusId = self.props.params.keyStatusId ? keyStatus.STATUS_ID : data.ID;
				var verb = self.props.params.keyStatusId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Key Status <Link to={'/BuildingAccess/KeyStatuses/' + keyStatusId}>{keyStatusId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Key Status {this.state.keyStatus.STATUS_ID}</div>
				<ChemEdit parent={this} columns={keyStatusColumns} data={this.state.keyStatus} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					{/* this.props.params.keyStatusId && <Button id="delete" variant="warning" type="button" className="float-end"
					  style={{ marginRight: '20px' }} onClick={(event) => this.deleteKeyStatus(event)}>Delete</Button> */} 
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const keyStatusColumns = [
	{ Header: 'Name', accessor: 'STATUS_NAME', required: true },
	{ Header: 'Comments', accessor: 'COMMENTS' }
];

export default withRouter(KeyStatus);
