import React from 'react';
import './App.css';
import ChemMenu from './components/ChemMenu';
import config from './config/index';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
	return (
		<DndProvider backend={HTML5Backend}>
			<BrowserRouter>
			    <Routes>
					<Route path={config.baseRoute} element={<ChemMenu config={config} />} />
				</Routes>
			</BrowserRouter>
	    </DndProvider>
	);
}

export default App;
