import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withReuseDefaultRoute } from './withReuseDefaultRoute';
import ChemHeader from '../ChemHeader';
import Restricted from '../Restricted';
import Confirm from '../Confirm';
import TreeMenu from '../TreeMenu';
import Help from './Help';
import Home from './Home';
import Search from './Search';
import Bottles from './Bottles';
import Bottle from './Bottle';

class Reuse extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var newTreeMenuItems = this.copyObject(treeMenuItems);
		
		// remove anything not authorized for the general public
		if (props.user.reuse.authLevel < 1) {
			this.removeRoute(newTreeMenuItems, routes.home);
			this.removeRoute(newTreeMenuItems, routes.bottles);
			this.removeRouteByLabel(newTreeMenuItems, 'Admin');
		} else {
			// add hidden elements to Admin form
			var adminMenuItem = this.findMenuItemByLabel(newTreeMenuItems, 'Admin');
			if (adminMenuItem !== null) {
				if (this.isDevelopment()) {
					adminMenuItem.action = 'http://localhost:8000' + adminMenuItem.action;
				}
				adminMenuItem.data = {
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					redirect: '/chempy/admin/reuse'
				};
			}
		}
		
		this.state = {
			treeMenuItems: newTreeMenuItems,
			bottleEditable: false,
			fullScreen: false,
			confirm: ''
		};
	}

	removeRoute(menuItems, route) {
		for (var i = 0; i < menuItems.length; i++) {
			if (menuItems[i].nodes) {
				this.removeRoute(menuItems[i].nodes, route);
				if (menuItems[i].nodes.length === 0) {
					menuItems.splice(i--, 1);
				}
			} else {
				if (menuItems[i].route && menuItems[i].route.toUpperCase() === route.toUpperCase()) {
					menuItems.splice(i--, 1);
				}
			}
		}
	}
	
	removeRouteByLabel(menuItems, label) {
		for (var i = 0; i < menuItems.length; i++) {
			if (menuItems[i].nodes) {
				this.removeRouteByLabel(menuItems[i].nodes, label);
				if (menuItems[i].nodes.length === 0) {
					menuItems.splice(i--, 1);
				}
			} else {
				if (menuItems[i].label && menuItems[i].label.toUpperCase() === label.toUpperCase()) {
					menuItems.splice(i--, 1);
				}
			}
		}
	}

	findMenuItemByLabel(menuItems, label) {
		for (var i = 0; i < menuItems.length; i++) {
			if (menuItems[i].nodes) {
				var subItem = this.findMenuItemByLabel(menuItems[i].nodes, label);
				if (subItem !== null) return subItem;
			} else {
				if (menuItems[i].label && menuItems[i].label.toUpperCase() === label.toUpperCase()) {
					return menuItems[i];
				}
			}
		}
		
		return null;
	}

	showConfirmation(message) {
		var self = this;
		this.mergeState({
			confirm: message
		}, () => self.props.navigate('/Reuse/Confirm'));
	}

	render() {
		return (<>
			<ChemHeader parent={this} user={this.props.user} title='Reuse Facility' fullScreen={this.state.fullScreen} 
				toggleFullScreen={(fullScreen) => this.mergeState({ fullScreen: fullScreen, renderKey: this.state.renderKey + 1 })}
				showHelp={() => this.props.navigate('/Reuse/Help', { helpPath: this.props.location.pathname })} />
			<Container fluid style={{ paddingTop: 20, paddingBottom: 20, backgroundColor: '#e6e6e4', height: 'calc(100vh - 64px)', overflow: 'auto' }}>
				<Row>
					{!this.state.fullScreen && <Col xs={2}>
						<TreeMenu parent={this} menuItems={this.state.treeMenuItems} selectedRoute={this.props.location.pathname} />
					</Col>}
					<Col xs={this.state.fullScreen ? 12 : 10}>
						<Routes>
							<Route path={'/home'} element={
								<Restricted authorized={this.props.user.reuse && this.props.user.reuse.authLevel > 0}>
									<Home parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/search'} element={
								<Search parent={this} user={this.props.user} />
							} />
							<Route path={'/bottles'} element={
								<Restricted authorized={this.props.user.reuse && this.props.user.reuse.authLevel > 0}>
									<Bottles parent={this} user={this.props.user} setBottleEditable={(bottleEditable) => this.mergeState({ bottleEditable: bottleEditable })} />
								</Restricted>
							} />
							<Route path={'/bottles/new'} element={
								<Restricted authorized={this.props.user.reuse && this.props.user.reuse.authLevel > 0}>
									<Bottle parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/bottles/new/:activeTab'} element={
								<Restricted authorized={this.props.user.reuse && this.props.user.reuse.authLevel > 0}>
									<Bottle parent={this} user={this.props.user} editable={true} />
								</Restricted>
							} />
							<Route path={'/bottles/:itemId'} element={
								<Restricted authorized={this.props.user.reuse && this.props.user.reuse.authLevel > 0}>
									<Bottle parent={this} user={this.props.user} editable={this.state.bottleEditable } />
								</Restricted>
							} />
							<Route path={'/bottles/:itemId/:activeTab'} element={
								<Restricted authorized={this.props.user.reuse && this.props.user.reuse.authLevel > 0}>
									<Bottle parent={this} user={this.props.user} editable={this.state.bottleEditable } />
								</Restricted>
							} />
							<Route path={'/confirm'} element={
								<Confirm parent={this} user={this.props.user} message={this.state.confirm} />
							} />
							<Route path={'/help'} element={
								<Help parent={this} user={this.props.user} />
							} />
						</Routes>
					</Col>
				</Row>
			</Container>
		</>);
	}
}

// routes defined here so there are no accidents when removing them!
const routes = {
	home: '/Reuse/Home',
	bottles: '/Reuse/Bottles',
	search: '/Reuse/Search'
};

const treeMenuItems = [
	{
		label: 'Home',
		route: routes.home
	},
	{
		label: 'Search',
		route: routes.search
	},
	{
		label: 'Bottles',
		route: routes.bottles
	},
	{
		label: 'Admin',
		action: '/chempy/login'
	}
];

export default withReuseDefaultRoute(Reuse);