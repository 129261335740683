import { Fragment } from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class Vendor extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			editable: props.editable,
			vendor: {
				OWNER_ID: ''
			},
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadVendor();
	}

	loadVendor(event) {
		var self = this;
		
		if (this.props.params.vendorId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/HeLAD/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					context: 'DEMURRAGE',
					entity: 'DEM_OWNERS',
					search: { Attribute: 'OWNER_ID', Operator: '=', LongValue: this.props.params.vendorId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var vendor = data.Data[0];
						self.mvc2js(vendor, vendorColumns);
							
						self.mergeState({
							vendor: vendor,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Vendor Error', data.Data.length + ' Vendors found for ID ' + self.props.params.vendorId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				vendor: {
					OWNER_ID: '(new)',
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var vendor = this.copyObject(this.state.vendor);
		this.setByAccessor(vendor, accessor, value);
		this.mergeState({ vendor: vendor, renderKey: this.state.renderKey + 1 });
	}
		
	onSubmit(event) {
		var self = this;
		var url;
		
		// make copy of data
		var vendor = this.copyObject(this.state.vendor);
		this.js2mvc(vendor, vendorColumns);
		
		// if this is a new vendor
		if (!this.props.params.vendorId) {
			// set ID to sequence name
			vendor.OWNER_ID = 'dem_owners_seq.nextval';
			url = '/Home/Insert';
		} else {
			url = '/Home/Update';
		}
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + url,
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'DEMURRAGE.DEM_OWNERS', data: vendor }
		}).done(function (data) {
			if (data.Success) {
				var vendorId = self.props.params.vendorId || data.ID;
				var verb = self.props.params.vendorId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Vendor <Link to={'/HeLAD/Vendors/' + vendorId}>{vendorId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Vendor {this.state.vendor.OWNER_ID}&nbsp;&nbsp;
							{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
							  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
						  </div>
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={vendorColumns} data={this.state.vendor} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} editable={this.state.editable} />
					  </Col>
					</Row>
					<Row style={{ paddingTop: 5 }}>
					  <Col>
						{this.state.editable && <Button id="save" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmit(event)}>Save</Button>}
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const vendorColumns = [
	{ Header: 'Description', accessor: 'OWNER_NAME', row: 0, col: 0 },
	{ Header: 'Comments', accessor: 'COMMENTS', row: 0, col: 1 },
	{ Header: 'Contact Person', accessor: 'CONTACT_PERSON', row: 1, col: 0 },
	{ Header: 'Contact Phone', accessor: 'CONTACT_PHONE', row: 1, col: 1 },
	{ Header: 'Deactivated', accessor: 'DELETE_FLAG', type: 'checkbox', row: 2, col: 0 },
	{ Header: 'Modified By', accessor: 'MODIF_BY', row: 3, col: 0, editable: false },
	{ Header: 'Modified Date', accessor: 'MODIF_DATE', row: 3, col: 1, editable: false }
];

export default withRouter(Vendor);
