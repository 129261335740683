import React from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class ServiceContract extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			serviceContract: {
				SERVICE_CONTRACT_ID: null,
				COMPANY_NAME: '',
				CUSTOMER_ID: '',
				ADDRESS: {
					STREET1: '',
					STREET2: '',
					CITY: '',
					STATE: '',
					ZIP: '',
					COUNTRY: ''
				},
				EMAIL: '',
				PO_NUM: '',
				PROJECT_ID: '',
				START_DATE: null,
				END_DATE: null,
				CHANGE_INFO: {
					COMMENTS: '',
					CREATE_DATE: null,
					CREATE_BY: '',
					MODIF_DATE: null,
					MODIF_BY: ''
				}
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadServiceContract();
	}

	loadServiceContract(event) {
		var self = this;
		
		if (this.props.params.serviceContractId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/CRS/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'SERVICE_CONTRACT',
					search: { Attribute: 'SERVICE_CONTRACT_ID', Operator: '=', LongValue: this.props.params.serviceContractId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var serviceContract = data.Data[0];
						
						// compose created/modified
						serviceContract.CREATED = self.composeChangeInfo(serviceContract.CHANGE_INFO.CREATE_BY, serviceContract.CHANGE_INFO.CREATE_DATE);
						serviceContract.MODIFIED = self.composeChangeInfo(serviceContract.CHANGE_INFO.MODIF_BY, serviceContract.CHANGE_INFO.MODIF_DATE);
																		
						self.mergeState({
							serviceContract: serviceContract,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Service Contract Error', data.Data.length + ' Service Contracts found for ID ' + self.props.params.serviceContractId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				serviceContract: {
					SERVICE_CONTRACT_ID: '(new)'
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var serviceContract = this.copyObject(this.state.serviceContract);
		this.setByAccessor(serviceContract, accessor, value);
		this.mergeState({ serviceContract: serviceContract, renderKey: this.state.renderKey + 1 });
	}
	
	deleteServiceContract(event) {
		var self = this;
		
		// assemble service contract object to update delete flag
		var serviceContract = {
			SERVICE_CONTRACT_ID: this.props.params.serviceContractId,
			DELETE_FLAG: 'Y',
			MODIF_DATE: this.dateTimeToMVC(new Date()),
			MODIF_BY: this.props.user.name
		};
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/Home/Update',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'RECHARGES.SERVICE_CONTRACT', data: serviceContract }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Service Contract {self.props.params.serviceContractId} deleted.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	validateServiceContract(event, columns, data) {
		return this.validate(columns, data);
	}
		
	onSubmit(event) {
		var self = this;
		
		// make copy of data
		var serviceContract = this.copyObject(this.state.serviceContract);

		// if this is a new service contract
		if (!this.props.params.serviceContractId) {
			// set ID sequence
			serviceContract.SERVICE_CONTRACT_ID = 'service_contract_id_seq.nextval';
			
			// set create info
			serviceContract.CHANGE_INFO.CREATE_DATE = this.dateTimeToMVC(new Date());
			serviceContract.CHANGE_INFO.CREATE_BY = this.props.user.name;
		} else {
			// set modify info
			serviceContract.CHANGE_INFO.MODIF_DATE = this.dateTimeToMVC(new Date());
			serviceContract.CHANGE_INFO.MODIF_BY = this.props.user.name;
		}
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + (this.props.params.serviceContractId ? '/Home/Update' : '/Home/Insert'),
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'RECHARGES.SERVICE_CONTRACT', data: serviceContract }
		}).done(function (data) {
			if (data.Success) {
				var id = self.props.params.serviceContractId || data.ID;
				var action = self.props.params.serviceContractId ? 'updated' : 'created';
				self.props.parent.showConfirmation(() => {
					return (<>
						Service Contract <Link to={'/CRS/ServiceContracts/' + id}>{id}</Link> {action}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Service Contract {this.state.serviceContract.SERVICE_CONTRACT_ID}</div>
				<ChemEdit parent={this} columns={serviceContractColumns} data={this.state.serviceContract} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}
				   validate={(event, columns, data) => this.validateServiceContract(event, columns, data)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					{this.props.params.serviceContractId && <Button id="delete" variant="warning" type="button" className="float-end" style={{ marginRight: '10px' }} onClick={(event) => this.deleteServiceContract(event)}>Delete</Button>}
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const serviceContractColumns = [
	{ Header: 'Company Name', accessor: 'COMPANY_NAME', row: 0, col: 0, required: true },
	{ Header: 'Customer ID', accessor: 'CUSTOMER_ID', row: 0, col: 1, required: true },
	{ Header: 'Street 1', accessor: 'ADDRESS.STREET1', row: 1, col: 0, required: true },
	{ Header: 'Street 2', accessor: 'ADDRESS.STREET2', row: 1, col: 1 },
	{ Header: 'City', accessor: 'ADDRESS.CITY', row: 2, col: 0, required: true },
	{ Header: 'State', accessor: 'ADDRESS.STATE', row: 2, col: 1, required: true },
	{ Header: 'Zip', accessor: 'ADDRESS.ZIP', row: 3, col: 0, required: true },
	{ Header: 'Country', accessor: 'ADDRESS.COUNTRY', row: 3, col: 1, required: true },
	{ Header: 'Email', accessor: 'EMAIL', row: 4, col: 0, required: true },
	{ Header: 'PO', accessor: 'PO_NUM', row: 4, col: 1, required: true },
	{ Header: 'BCMS Project ID', accessor: 'PROJECT_ID', row: 5, col: 0, required: true },
	{ Header: 'Comments', accessor: 'CHANGE_INFO.COMMENTS', type: 'textarea', row: 5, col: 1 },
	{ Header: 'Start Date', accessor: 'START_DATE', type: 'date', row: 6, col: 0, required: true },
	{ Header: 'End Date', accessor: 'END_DATE', type: 'date', row: 6, col: 1, required: true },
	{ Header: 'Created', accessor: 'CREATED', row: 7, col: 0, editable: false },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 7, col: 1, editable: false }
];

export default withRouter(ServiceContract);
