import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../../ChemComponent';
import QueryTable from '../../QueryTable';

class DuplicatePeople extends ChemComponent {
  	constructor(props) {
		super(props);
		
		this.state = {
			renderKey: 1,
			searchCount: null
		};
	}
	
	onSearchResult(data) {
		this.mergeState({
			renderKey: this.state.renderKey + 1,
			searchCount: data.ResultCount
		});
	}
	
	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Duplicate People Report',
			columns: exportColumns,
			entity: 'DUPLICATE_PEOPLE_VW',
			order: ['LNAME_1','FNAME_1','MNAME_1']
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Duplicate People Report</span>
					<Container fluid>
					  <Row style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={3} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={7}>
						</Col>
					  </Row>
					</Container>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_DuplicatePeople' table={duplicateList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='140px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const duplicateList = {
	query: { entity: 'DUPLICATE_PEOPLE_VW', order: ['LNAME_1','FNAME_1','MNAME_1'] },
	pageSize: 20,
	columns: [
		{ Header: 'Person ID', accessor: 'PERSON_ID_1', width: 80 },
		{ Header: 'First Name', accessor: 'FNAME_1', width: 150 },
		{ Header: 'Middle Name', accessor: 'MNAME_1', width: 150 },
		{ Header: 'Last Name', accessor: 'LNAME_1', width: 150 },
		{ Header: 'Cal UID', accessor: 'CAL_UID_1', width: 80 },
		{ Header: 'Person ID', accessor: 'PERSON_ID_2', width: 80 },
		{ Header: 'First Name', accessor: 'FNAME_2', width: 150 },
		{ Header: 'Middle Name', accessor: 'MNAME_2', width: 150 },
		{ Header: 'Last Name', accessor: 'LNAME_2', width: 150 },
		{ Header: 'Cal UID', accessor: 'CAL_UID_2', width: 80 },
	]
};

const exportColumns = [
	{ Header: 'Person ID', accessor: 'PERSON_ID_1', width: 16 },
	{ Header: 'First Name', accessor: 'FNAME_1', width: 30 },
	{ Header: 'Middle Name', accessor: 'MNAME_1', width: 30 },
	{ Header: 'Last Name', accessor: 'LNAME_1', width: 30 },
	{ Header: 'Cal UID', accessor: 'CAL_UID_1', width: 16 },
	{ Header: 'Person ID', accessor: 'PERSON_ID_2', width: 16 },
	{ Header: 'First Name', accessor: 'FNAME_2', width: 30 },
	{ Header: 'Middle Name', accessor: 'MNAME_2', width: 30 },
	{ Header: 'Last Name', accessor: 'LNAME_2', width: 30 },
	{ Header: 'Cal UID', accessor: 'CAL_UID_2', width: 16 },
];

export default DuplicatePeople;