import { Fragment } from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class HeTransaction extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			editable: props.editable,
			transColumns: this.copyColumns(transColumns),
			trans: {
				READING_ID: ''
			},
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadTransaction();
	}

	loadTransaction(event) {
		var self = this;
		
		if (this.props.params.transId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/HeLAD/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'HE_TRANSACTIONS_VW',
					search: { Attribute: 'READING_ID', Operator: '=', LongValue: this.props.params.transId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var trans = data.Data[0];
						self.mvc2js(trans, transColumns);
						var newTransColumns = self.copyColumns(transColumns);
						if (trans.DATE_BILLED_EDITABLE === 0) {
							var dateBilledIdx = self.findColumnByAccessor(transColumns, 'DATE_BILLED');
							newTransColumns[dateBilledIdx].editable = false;
						}
							
						self.mergeState({
							trans: trans,
							transColumns: newTransColumns,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Transaction Error', data.Data.length + ' Transactions found for ID ' + self.props.params.transId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				trans: {
					TRANS_PK: '(new)',
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var trans = this.copyObject(this.state.trans);
		this.setByAccessor(trans, accessor, value);
		this.mergeState({ trans: trans, renderKey: this.state.renderKey + 1 });
	}
		
	onSubmit(event) {
		var self = this;

		var trans = this.copyObject(this.state.trans);
		this.js2mvc(trans, transColumns);

		trans.LAST_MODIF = this.dateTimeToMVC(new Date());
		trans.MODIF_BY = this.props.user.name;
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/HeLAD/UpdateHeTransaction',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, transaction: trans }
		}).done(function (data) {
			if (data.Success) {
				var transId = self.props.params.transId || data.ID;
				var verb = self.props.params.transId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Transaction <Link to={'/HeLAD/HeTransactions/' + transId}>{transId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Helium Recovery Transaction {this.state.trans.READING_ID}&nbsp;&nbsp;
							{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
							  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
						  </div>
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={this.state.transColumns} data={this.state.trans} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} editable={this.state.editable} />
					  </Col>
					</Row>
					<Row style={{ paddingTop: 5 }}>
					  <Col>
						{this.state.editable && <Button id="save" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmit(event)}>Save</Button>}
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const transColumns = [
	{ Header: 'Reading Date', accessor: 'READING_DATE_DISPLAY', row: 0, col: 0, editable: false },
	{ Header: 'Meter', accessor: 'METER_DESC', row: 0, col: 1, editable: false },
	{ Header: 'PI', accessor: 'PI_NAME', row: 0, col: 2, editable: false },
	{ Header: 'Speedtype', accessor: 'COST_CENTER', row: 0, col: 3, editable: false },
	{ Header: 'Current Meter Reading', accessor: 'NEW_AMOUNT', row: 1, col: 0, editable: false },
	{ Header: 'Past Meter Reading', accessor: 'PAST_AMOUNT', row: 1, col: 1, editable: false },
	{ Header: 'Total Amount', accessor: 'TOTAL_AMOUNT', row: 1, col: 2, editable: false },
	{ Header: 'Total Cost', accessor: 'AMOUNT_CHARGED', row: 1, col: 3, editable: false },
	{ Header: 'Date Billed', accessor: 'DATE_BILLED', type: 'date', row: 2, col: 0 },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 2, col: 2 },
	{ Header: 'Cancel', accessor: 'CANCEL_FLAG', type: 'checkbox', row: 2, col: 3 },
	{ Header: 'Entered', accessor: 'ENTERED', row: 3, col: 2, editable: false },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 3, col: 3, editable: false }
];

export default withRouter(HeTransaction);
