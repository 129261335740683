import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class WorkTitle extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			workTitle: {
				WORK_TITLE_ID: null,
				WORK_TITLE_NAME: '',
				WORK_TITLE_ABBREV: '',
				MODIF_DATE: null,
				MODIF_BY: '',
				MODIFIED: '',
				PERSON_COUNT: '',
				DISPLAY_ORDER: null
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadWorkTitle();
	}

	loadWorkTitle(event) {
		var self = this;
		
		if (this.props.params.workTitleId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'WORK_TITLE',
					search: { Attribute: 'WORK_TITLE_ID', Operator: '=', LongValue: this.props.params.workTitleId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length === 1) {
						var workTitle = data.Data[0];
																		
						self.mergeState({
							workTitle: workTitle,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Work Title Error', data.Data.length + ' work titles found for ID ' + self.props.params.workTitleId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				workTitle: {
					WORK_TITLE_ID: '(new)'
				}
			});
		}
	}
		
	onChange(accessor, value) {
		var workTitle = this.copyObject(this.state.workTitle);
		this.setByAccessor(workTitle, accessor, value);
		this.mergeState({ workTitle: workTitle, renderKey: this.state.renderKey + 1 });
	}
		
	deleteWorkTitle(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Work Title', 'Delete Work Title ' + this.props.params.workTitleId + '?', (okClicked) => {
			if (okClicked) {
				// prepare work title for deletion
				var workTitle = self.copyObject(self.state.workTitle);
				workTitle.DELETE_FLAG = 'Y';
				workTitle.CREATE_DATE = self.dateTimeToMVC(self.getDate(workTitle.CREATE_DATE));
				workTitle.MODIF_DATE = self.dateTimeToMVC(new Date());
				workTitle.MODIF_BY = self.props.user.name;
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'WORK_TITLE', data: workTitle }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Work Title {self.props.params.workTitleId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
		
	onSubmit(event) {
		var self = this;		
		var dtNow = this.dateTimeToMVC(new Date());
		var currentUser = this.props.user.name;
		
		// make copy of data
		var workTitle = this.copyObject(this.state.workTitle);
		
		// set common fields
		workTitle.MODIF_DATE = dtNow;
		workTitle.MODIF_BY = currentUser;

		// if this is a new work title, set ID to sequence name
		var url;
		if (this.props.params.workTitleId) {
			url = this.getConfig().host + '/Home/Update';
		} else {
			url = this.getConfig().host + '/Home/Insert';
			workTitle.WORK_TITLE_ID = 'seq_work_title.nextval';
			workTitle.DELETE_FLAG = null;
		}			
		
		this.ajax({
			type: 'POST',
			url: url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'WORK_TITLE', data: workTitle }
		}).done(function (data) {
			if (data.Success) {
				var workTitleId = self.props.params.workTitleId ? workTitle.WORK_TITLE_ID : data.ID;
				var verb = self.props.params.workTitleId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Work Title <Link to={'/BuildingAccess/WorkTitles/' + workTitleId}>{workTitleId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Work Title {this.state.workTitle.WORK_TITLE_ID}</div>
				<ChemEdit parent={this} columns={workTitleColumns} data={this.state.workTitle} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					{this.props.params.workTitleId && <Button id="delete" variant="warning" type="button" className="float-end"
					  style={{ marginRight: '20px' }} onClick={(event) => this.deleteWorkTitle(event)}>Delete</Button>} 
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const workTitleColumns = [
	{ Header: 'Name', accessor: 'WORK_TITLE_NAME', required: true },
	{ Header: 'Abbreviation', accessor: 'WORK_TITLE_ABBREV' },
	{ Header: 'Last Modified', accessor: 'MODIF_BY', editable: false,
		Cell: props => {
			// if there is no modif date
			if (props.editor.props.data.MODIF_DATE === null)
			{
				// if there is no create date
				if (props.editor.props.data.CREATE_DATE === null)
				{
					// no info to return
					return null;
				} else {
					// use creation date
					return <>{props.parent.dateToString(props.parent.getDate(props.editor.props.data.CREATE_DATE)) + ' ' + props.editor.props.data.CREATE_BY}</>;
				}
			} else {
				return <>{props.parent.dateToString(props.parent.getDate(props.editor.props.data.MODIF_DATE)) + ' ' + props.value}</>;
			}
		}
	}
];

export default withRouter(WorkTitle);
