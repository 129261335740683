import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class DryIceSales extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			USER_NAME: '',
			PI_NAME: '',
			SPEEDTYPE: ''
		};
		
		// initialize dry ice list
		var initialDryIceSalesList = this.copyDryIceSalesList();
		initialDryIceSalesList.query.search = this.constructSearch(search);
		
		this.state = {
			renderKey: 1,
			dryIceSalesList: initialDryIceSalesList,
			search: search,
			searchCount: null
		};
	}
	
	copyDryIceSalesList() {
		var dryIceSalesListCopy = this.copyObject(dryIceSalesList);
		for (var i = 0; i < dryIceSalesList.columns.length; i++) {
			if (dryIceSalesList.columns[i].Header) {
				dryIceSalesListCopy.columns[i].Header = dryIceSalesList.columns[i].Header;
			}
			if (dryIceSalesList.columns[i].Cell) {
				dryIceSalesListCopy.columns[i].Cell = dryIceSalesList.columns[i].Cell;
			}
		}
		return dryIceSalesListCopy;
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newDryIceSalesList = this.copyDryIceSalesList();
		newDryIceSalesList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			dryIceSalesList: newDryIceSalesList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.USER_NAME)) search.Children.push({ Attribute: 'USER_NAME', Operator: 'like', StringValue: '%' + data.USER_NAME + '%' });
		if (!this.isEmpty(data.PI_NAME)) search.Children.push({ Attribute: 'PI_NAME', Operator: 'like', StringValue: '%' + data.PI_NAME + '%' });		
		if (!this.isEmpty(data.SPEEDTYPE)) search.Children.push({ Attribute: 'SPEEDTYPE', Operator: 'like', StringValue: '%' + data.SPEEDTYPE + '%' });		

		if (search.Children.length === 0) return null;
		return search;
	}
	
	addDryIceSale() {
		this.props.navigate('/HeLAD/DryIceSales/new');
	}
	
	editDryIceSale(transId) {
		this.props.navigate('/HeLAD/DryIceSales/' + transId);
	}
	
	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Dry Ice Sales',
			columns: exportColumns,
			entity: 'RECHARGES.DRY_ICE_TRANS_VW',
			search: this.constructSearch(this.state.search),
			order: ['TRANS_DATE desc', 'USER_NAME']
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Dry Ice Sales</span>
					<ChemEdit parent={this} columns={dryIceSalesSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={4} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='HeLAD_DryIceSales' table={this.state.dryIceSalesList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='200px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const dryIceSalesSearch = [
	{ Header: 'User Name', accessor: 'USER_NAME', row: 0, col: 0 },
	{ Header: 'PI Name', accessor: 'PI_NAME', row: 0, col: 1 },
	{ Header: 'SpeedType', accessor: 'SPEEDTYPE', row: 0, col: 2 }
];

const dryIceSalesList = {
	query: { entity: 'RECHARGES.DRY_ICE_TRANS_VW', search: null, order: ['TRANS_DATE desc', 'USER_NAME'] },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addDryIceSale()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editDryIceSale(props.row.values.TRANS_ID)} />, width: 25 },
		{ Header: 'ID', accessor: 'TRANS_ID', width: 60 },
		{ Header: 'Date', accessor: 'TRANS_DATE', width: 100 },
		{ Header: 'User', accessor: 'USER_NAME', width: 200 },
		{ Header: 'Quantity', accessor: 'QTY', width: 75 },
		{ Header: 'Total', accessor: 'TOTAL', width: 60 },
		{ Header: 'SpeedType', accessor: 'SPEEDTYPE', width: 125 },
		{ Header: 'PI', accessor: 'PI_NAME', width: 150 },
		{ Header: 'Modified', accessor: 'MODIFIED', width: 250 }
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'TRANS_ID', width: 12 },
	{ Header: 'Date', accessor: 'TRANS_DATE', width: 20, format: 'mm/dd/yy' },
	{ Header: 'User', accessor: 'USER_NAME', width: 40 },
	{ Header: 'Quantity', accessor: 'QTY', width: 15 },
	{ Header: 'Total', accessor: 'TOTAL', width: 12 },
	{ Header: 'SpeedType', accessor: 'SPEEDTYPE', width: 25 },
	{ Header: 'PI', accessor: 'PI_NAME', width: 30 },
	{ Header: 'Modified', accessor: 'MODIFIED', width: 50 }
];

export default withRouter(DryIceSales);