import 'bootstrap/dist/css/bootstrap.min.css';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';

class Debug extends ChemComponent {
  	constructor(props) {
		super(props);
				
		this.state = {
			run: false,
			success: false
		};
	}

	componentDidMount() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Admin/Debug',
			overlay: true,
			data: {}
		}).done(function (data) {
			if (data.Success) {
				self.mergeState({
					run: true,
					success: true
				});
			} else {
				self.showAlert('Debug Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}

	render() {
		return (<>
			run: {this.state.run}<br />
			success: {this.state.success}
		</>);
	}
}	

export default withRouter(Debug);