import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';

class NewItemReport extends ChemComponent {
	constructor(props) {
		super(props);

		this.state = {
			searchCount: 0,
			renderKey: 0
		};
	}

	onSubmitSearch(event) {
		this.mergeState({
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}

	viewNewItem(newItemId) {
		this.props.setItemEditable(false);
		this.props.navigate('/Stores/Catalog/' + newItemId);		
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'New Item Report',
			columns: exportColumns,
			entity: 'STORES.NEW_ITEM_REPORT_VW',
			search: null,
			order: 'ITEM_ID desc'
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>New Item Report</span>
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='Stores_NewItems' table={newItemsList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='190px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const newItemsList = {
	query: { entity: 'STORES.NEW_ITEM_REPORT_VW', search: null, order: 'ITEM_ID desc' },
	pageSize: 20,
	columns: [	
		{ Header: '', accessor: 'ITEM_ID', show: false },
		{ Header: 'Description', accessor: 'DESCRIPTION', width: 350 },
		{ Header: 'Stock Number', accessor: 'STOCK_NO', width: 100,
			Cell: props => <HashLink onClick={() => props.parent.viewNewItem(props.row.values.ITEM_ID)}>{props.value}</HashLink> },
		{ Header: 'Location', accessor: 'LOCATION', width: 100 },
		{ Header: 'Catalog Number', accessor: 'CAT_NUMBER', width: 200 },
		{ Header: 'Price Sold', accessor: 'STORE_PRICE', width: 90 },
		{ Header: 'Stocking UOM', accessor: 'STOCKING_UOM', width: 80 },
		{ Header: 'Notes', accessor: 'MODIF_ACTION', width: 150 }
	]
};

const exportColumns = [
		{ Header: 'Description', accessor: 'DESCRIPTION', width: 70 },
		{ Header: 'Stock Number', accessor: 'STOCK_NO', width: 20 },
		{ Header: 'Location', accessor: 'LOCATION', width: 20 },
		{ Header: 'Catalog Number', accessor: 'CAT_NUMBER', width: 40 },
		{ Header: 'Price Sold', accessor: 'STORE_PRICE', width: 18 },
		{ Header: 'Stocking UOM', accessor: 'STOCKING_UOM', width: 16 },
		{ Header: 'Notes', accessor: 'MODIF_ACTION', width: 30 }
];

export default withRouter(NewItemReport);
