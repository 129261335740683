import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { Link } from 'react-router-dom';

class MyGroup extends ChemComponent {
  	constructor(props) {
		super(props);
		
		this.state = {
			renderKey: 1,
			search: {
				MEMBER_NAME: null
			},
			groupList: this.mergeObject(groupList, { ajax: { data: { personid: props.user.personID } } })
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event, data)
	{
		// update state
		this.mergeState({
			groupList: {
				renderKey: this.state.groupList.renderKey + 1,
				ajax: {
					data: {
						membername: this.state.search.MEMBER_NAME
					}
				}
			},
		}, () => {
			if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
		});
	}
	
	render() {
		return (<>
			<Container fluid>
				<Row>
					<Col>
					  <Container>
					    <Row>
						  <Col xs={10} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
							<span style={{ fontSize: '18px', fontWeight: 'bold' }}>My Group/Unit</span>
					        <ChemEdit parent={this} columns={groupListSearchColumns} data={this.state.search} renderKey={this.state.key} 
						      onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
							  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						        <Col xs={12}>
					              <Button id="update" variant="warning" type="submit" className="float-end">Update</Button>
								</Col>
							  </Form.Group>
					        </ChemEdit>
					      </Col>
						</Row>
					  </Container>
					</Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.groupList.renderKey} parent={this} name='BuildingAccess_GroupList' table={this.state.groupList} offsetHeight='230px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const groupListSearchColumns = [
	{ Header: 'Name', accessor: 'MEMBER_NAME' }
];

const groupList = {
	renderKey: 0,
	pageSize: 10,
	ajax: { 
		url: '/BuildingAccess/GetGroup',
		data: {
			personid: null,
			membername: null
		},
		accessor: 'Group'
	},
	columns: [
		{ Header: 'PERSON_ID', accessor: 'PERSON_ID', show: false },
		{ Header: 'Name', accessor: 'MEMBER_NAME',
			Cell: props => <Link to={'/BuildingAccess/People/' + props.row.values.PERSON_ID}>{props.value}</Link> },
		{ Header: 'Email', accessor: 'EMAIL' },
		{ Header: 'Appointment', accessor: 'TYPE_NAME' },
		{ Header: 'PI', accessor: 'PI_NAME' },
		{ Header: 'Supervisor', accessor: 'SUP_NAME' },
		{ Header: 'Unit', accessor: 'UNIT_NAME' },
		{ Header: () => <>Stores<br/>Checkout</>, accessor: 'STORES_ACCESS' },
		{ Header: () => <>Default<br/>Speedtype</>, accessor: 'SPEEDTYPE' },
		{ Header: () => <>Expected<br/>Departure</>, accessor: 'END_DATE' }
	]
};

export default MyGroup;
