import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import DatePicker from 'react-datepicker';

class DailyReadings extends ChemComponent {
	constructor(props) {
		super(props);

		var daily = {
			ENTRY_DAY: this.dateTimeToMVC(new Date()),
			BANK1: '',
			BANK2: '',
			BANK3: '',
			BANK4: '',
			BANK5: '',
			BANK6: '',
			BANK7: '',
			BANK8: '',
			TEMP1: '',
			TEMP2: '',
			BANK_COMMENTS: '',
			TIME_COOL_DOWN: '',
			PURIFIER_1: false,
			PURIFIER_2: false,
			TIME_START_PLANT: '',
			START_BANK1: false,
			START_BANK2: false,
			START_BANK3: false,
			START_BANK4: false,
			START_BANK5: false,
			START_BANK6: false,
			START_BANK7: false,
			START_BANK8: false,
			START_PRESSURE: '',
			START_LITERS: '',
			START_INCH: '',
			TIME_LOAD: '',
			LOAD_BANK1: false,
			LOAD_BANK2: false,
			LOAD_BANK3: false,
			LOAD_BANK4: false,
			LOAD_BANK5: false,
			LOAD_BANK6: false,
			LOAD_BANK7: false,
			LOAD_BANK8: false,
			LOAD_PRESSURE: '',
			LOAD_LITERS: '',
			LOAD_INCH: '',
			TIME_SHUT_DOWN: '',
			DOWN_BANK1: false,
			DOWN_BANK2: false,
			DOWN_BANK3: false,
			DOWN_BANK4: false,
			DOWN_BANK5: false,
			DOWN_BANK6: false,
			DOWN_BANK7: false,
			DOWN_BANK8: false,
			DOWN_PRESSURE: '',
			DOWN_LITERS: '',
			DOWN_INCH: '',
			TIME_COMMENTS: '',
		};
		
		this.state = {
			daily: daily,
			renderKey: 0
		};
	}
	
	onChange(accessor, value) {
		var daily = this.copyObject(this.state.daily);
		this.setByAccessor(daily, accessor, value);
		this.mergeState({ daily: daily });
	}

	onSubmitRequest(event) {
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}

	render() {
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col>
						<div style={{ display: 'inline-block', fontSize: '18px', fontWeight: 'bold', marginRight: '10px' }}>Enter Readings Taken on</div>
						<div style={{ display: 'inline-block' }}>
						  <DatePicker
							selected={this.getDate(this.getByAccessor(this.state.daily, 'ENTRY_DAY'))}
							onChange={(date) => this.onChange('ENTRY_DAY', date === null ? null : this.dateTimeToMVC(date))}
							dateFormat='MM/dd/yy'
						  />
						</div>
					  </Col>
					</Row>
					<Row style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col>
					      <table width="800" cellspacing="0" cellpadding="1" style={{ border: '1px solid #ccc', borderRadius: 4, borderCollapse: 'separate' }}>
							<tr align="center" bgcolor="#FDB515" bordercolor="#ccc">
							  <td style={{ border: '1px solid #ccc' }} width='103' bgcolor="#FDB515"><font color="#000000">Banks</font></td>
							  <td style={{ border: '1px solid #ccc' }} width="99" bgcolor="#FDB515"><font color="#000000">1 </font></td>
							  <td style={{ border: '1px solid #ccc' }} width="99" bgcolor="#FDB515"><font color="#000000">2 </font></td>
							  <td style={{ border: '1px solid #ccc' }} width="99" bgcolor="#FDB515"><font color="#000000">3 </font></td>
							  <td style={{ border: '1px solid #ccc' }} width="99" bgcolor="#FDB515"><font color="#000000">4 </font></td>
							  <td style={{ border: '1px solid #ccc' }} width="99" bgcolor="#FDB515"><font color="#000000">5 </font></td>
							  <td style={{ border: '1px solid #ccc' }} width="99" bgcolor="#FDB515"><font color="#000000">6 </font></td>
							  <td style={{ border: '1px solid #ccc' }} width="99" bgcolor="#FDB515"><font color="#000000">7 </font></td>
							  <td style={{ border: '1px solid #ccc' }} width="99" bgcolor="#FDB515"><font color="#000000">8 </font></td>
							</tr>
							<tr align="center">
							  <td style={{ border: '1px solid #ccc' }} bgcolor="#FDB515"><font color="#000000">Pressure</font></td>
							  <td style={{ border: '1px solid #ccc' }}>
								<Form.Control type='text' value={this.getByAccessor(this.state.daily, 'BANK1')||''}
									onChange={(event) => this.onChange('BANK1', event.target.value)} size={6} maxlength={6} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
								<Form.Control type='text' value={this.getByAccessor(this.state.daily, 'BANK2')||''}
									onChange={(event) => this.onChange('BANK2', event.target.value)} size={6} maxlength={6} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
								<Form.Control type='text' value={this.getByAccessor(this.state.daily, 'BANK3')||''}
									onChange={(event) => this.onChange('BANK3', event.target.value)} size={6} maxlength={6} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
								<Form.Control type='text' value={this.getByAccessor(this.state.daily, 'BANK4')||''}
									onChange={(event) => this.onChange('BANK4', event.target.value)} size={6} maxlength={6} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
								<Form.Control type='text' value={this.getByAccessor(this.state.daily, 'BANK5')||''}
									onChange={(event) => this.onChange('BANK5', event.target.value)} size={6} maxlength={6} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
								<Form.Control type='text' value={this.getByAccessor(this.state.daily, 'BANK6')||''}
									onChange={(event) => this.onChange('BANK6', event.target.value)} size={6} maxlength={6} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
								<Form.Control type='text' value={this.getByAccessor(this.state.daily, 'BANK7')||''}
									onChange={(event) => this.onChange('BANK7', event.target.value)} size={6} maxlength={6} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
								<Form.Control type='text' value={this.getByAccessor(this.state.daily, 'BANK8')||''}
									onChange={(event) => this.onChange('BANK8', event.target.value)} size={6} maxlength={6} />
							  </td>
							</tr>
							<tr>
							  <td style={{ border: '1px solid #ccc' }} align="center" bgcolor="#FDB515" bordercolor="#000000"><font color="#000000">Temperature</font></td>
							  <td style={{ border: '1px solid #ccc' }} colspan="4" align="center">
							    <div style={{ display: 'inline-block' }}>
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'TEMP1')||''}
									onChange={(event) => this.onChange('TEMP1', event.target.value)} size={6} maxlength={6} />
								</div>
								<div style={{ display: 'inline-block', paddingLeft: '10px' }}>F</div>
							  </td>
							  <td style={{ border: '1px solid #ccc' }} colspan="4" align="center">
							    <div style={{ display: 'inline-block' }}> 
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'TEMP2')||''}
									onChange={(event) => this.onChange('TEMP2', event.target.value)} size={6} maxlength={6} />
								</div>
								<div style={{ display: 'inline-block', paddingLeft: '10px' }}>F</div>
							  </td>
							</tr>
							<tr>
							  <td style={{ border: '1px solid #ccc' }} align="center" bgcolor="#FDB515" bordercolor="#000000"><font color="#000000">Comments</font></td>
							  <td style={{ border: '1px solid #ccc' }} colspan="8" align="center">
								<Form.Control as="textarea" cols={75} rows={2} value={this.getByAccessor(this.state.daily, 'BANK_COMMENTS')||''}
								  onChange={(event) => this.onChange('BANK_COMMENTS', event.target.value)} />
							  </td>
							</tr>
						  </table>
					  </Col>
					</Row>
					<Row style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col>
						  <table width="800" cellspacing="0" cellpadding="1" style={{ border: '1px solid #ccc', borderRadius: 4, borderCollapse: 'separate' }}>
							<tr bgcolor="#FDB515" bordercolor="#ccc">
							  <td style={{ border: '1px solid #ccc' }} align="center"><font color="#000">Time</font></td>
							  <td style={{ border: '1px solid #ccc' }} align="center"><font color="#000">Action</font></td>
							  <td style={{ border: '1px solid #ccc' }} colspan="8" align="center"><font color="#000">Banks</font></td>
							  <td style={{ border: '1px solid #ccc' }} align="center"><font color="#000">Pressure</font></td>
							  <td style={{ border: '1px solid #ccc' }} align="center"><font color="#000">Liters</font></td>
							  <td style={{ border: '1px solid #ccc' }} align="center"><font color="#000">Inches</font></td>
							  <td style={{ border: '1px solid #ccc' }} align="center"><font color="#000">Calculations</font></td>
							</tr>
							<tr>
							  <td style={{ border: '1px solid #ccc' }}><div align="center">
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'TIME_COOL_DOWN')||''}
									onChange={(event) => this.onChange('TIME_COOL_DOWN', event.target.value)} size={4} maxlength={4} />
							  </div></td>
							  <td style={{ border: '1px solid #ccc' }}>START COOL DOWN </td>
							  <td style={{ border: '1px solid #ccc' }} colspan="4">
								<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'PURIFIER_1')} label='Purifier #1'
									onClick={(event) => this.onChange('PURIFIER_1', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }} colspan="4">
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'PURIFIER_2')} label='Purifier #2'
									onClick={(event) => this.onChange('PURIFIER_2', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>&nbsp;</td>
							  <td style={{ border: '1px solid #ccc' }}>&nbsp;</td>
							  <td style={{ border: '1px solid #ccc' }}>&nbsp;</td>
							  <td style={{ border: '1px solid #ccc' }}>&nbsp;</td>
							</tr>
							<tr>
							  <td style={{ border: '1px solid #ccc' }} align="center">
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'TIME_START_PLANT')||''}
									onChange={(event) => this.onChange('TIME_START_PLANT', event.target.value)} size={4} maxlength={4} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>START PLANT </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'START_BANK1')} label='1'
									onClick={(event) => this.onChange('START_BANK1', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'START_BANK2')} label='2'
									onClick={(event) => this.onChange('START_BANK2', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'START_BANK3')} label='3'
									onClick={(event) => this.onChange('START_BANK3', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'START_BANK4')} label='4'
									onClick={(event) => this.onChange('START_BANK4', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'START_BANK5')} label='5'
									onClick={(event) => this.onChange('START_BANK5', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'START_BANK6')} label='6'
									onClick={(event) => this.onChange('START_BANK6', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'START_BANK7')} label='7'
									onClick={(event) => this.onChange('START_BANK7', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'START_BANK8')} label='8'
									onClick={(event) => this.onChange('START_BANK8', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }} align="center">
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'START_PRESSURE')||''}
									onChange={(event) => this.onChange('START_PRESSURE', event.target.value)} size={6} maxlength={6} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }} align="center">
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'START_LITERS')||''}
									onChange={(event) => this.onChange('START_LITERS', event.target.value)} size={6} maxlength={6} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }} align="center">
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'START_INCH')||''}
									onChange={(event) => this.onChange('START_INCH', event.target.value)} size={6} maxlength={6} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>&nbsp;</td>
							</tr>
							<tr>
							  <td style={{ border: '1px solid #ccc' }} align="center">
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'TIME_LOAD')||''}
									onChange={(event) => this.onChange('TIME_LOAD', event.target.value)} size={4} maxlength={4} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>LOAD PLANT </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'LOAD_BANK1')} label='1'
									onClick={(event) => this.onChange('LOAD_BANK1', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'LOAD_BANK2')} label='2'
									onClick={(event) => this.onChange('LOAD_BANK2', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'LOAD_BANK3')} label='3'
									onClick={(event) => this.onChange('LOAD_BANK3', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'LOAD_BANK4')} label='4'
									onClick={(event) => this.onChange('LOAD_BANK4', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'LOAD_BANK5')} label='5'
									onClick={(event) => this.onChange('LOAD_BANK5', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'LOAD_BANK6')} label='6'
									onClick={(event) => this.onChange('LOAD_BANK6', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'LOAD_BANK7')} label='7'
									onClick={(event) => this.onChange('LOAD_BANK7', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'LOAD_BANK8')} label='8'
									onClick={(event) => this.onChange('LOAD_BANK8', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }} align="center">
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'LOAD_PRESSURE')||''}
									onChange={(event) => this.onChange('LOAD_PRESSURE', event.target.value)} size={6} maxlength={6} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }} align="center">
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'LOAD_LITERS')||''}
									onChange={(event) => this.onChange('LOAD_LITERS', event.target.value)} size={6} maxlength={6} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }} align="center">
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'LOAD_INCH')||''}
									onChange={(event) => this.onChange('LOAD_INCH', event.target.value)} size={6} maxlength={6} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>&nbsp;</td>
							</tr>
							<tr>
							  <td style={{ border: '1px solid #ccc' }} align="center">
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'TIME_SHUT_DOWN')||''}
									onChange={(event) => this.onChange('TIME_SHUT_DOWN', event.target.value)} size={4} maxlength={4} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>SHUT DOWN </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'DOWN_BANK1')} label='1'
									onClick={(event) => this.onChange('DOWN_BANK1', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'DOWN_BANK2')} label='2'
									onClick={(event) => this.onChange('DOWN_BANK2', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'DOWN_BANK3')} label='3'
									onClick={(event) => this.onChange('DOWN_BANK3', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'DOWN_BANK4')} label='4'
									onClick={(event) => this.onChange('DOWN_BANK4', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'DOWN_BANK5')} label='5'
									onClick={(event) => this.onChange('DOWN_BANK5', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'DOWN_BANK6')} label='6'
									onClick={(event) => this.onChange('DOWN_BANK6', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'DOWN_BANK7')} label='7'
									onClick={(event) => this.onChange('DOWN_BANK7', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
							  	<Form.Check style={{ paddingTop: '8px' }} type="checkbox" 
									checked={this.getByAccessor(this.state.daily, 'DOWN_BANK8')} label='8'
									onClick={(event) => this.onChange('DOWN_BANK8', event.target.checked)} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }} align="center">
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'DOWN_PRESSURE')||''}
									onChange={(event) => this.onChange('DOWN_PRESSURE', event.target.value)} size={6} maxlength={6} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }} align="center">
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'DOWN_LITERS')||''}
									onChange={(event) => this.onChange('DOWN_LITERS', event.target.value)} size={6} maxlength={6} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }} align="center">
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'DOWN_INCH')||''}
									onChange={(event) => this.onChange('DOWN_INCH', event.target.value)} size={6} maxlength={6} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>&nbsp;</td>
							</tr>
							<tr>
							  <td style={{ border: '1px solid #ccc' }} align="center" bgcolor="#FDB515" bordercolor="#ccc"><font color="#000">Comments</font></td>
							  <td style={{ border: '1px solid #ccc' }} colspan="13" align="center">
								<Form.Control as="textarea" cols={75} rows={2} value={this.getByAccessor(this.state.daily, 'TIME_COMMENTS')||''}
								  onChange={(event) => this.onChange('TIME_COMMENTS', event.target.value)} />
							  </td>
							</tr>
						  </table>
					  </Col>
					</Row>
					<Row style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col>
						  <table width="800" cellspacing="0" cellpadding="1" style={{ border: '1px solid #ccc', borderRadius: 4, borderCollapse: 'separate' }}>
							<tr align="center" bgcolor="#FDB515">
							  <td style={{ border: '1px solid #ccc' }} bordercolor="#ccc"><font color="#000">Meter</font></td>
							  <td style={{ border: '1px solid #ccc' }} bordercolor="#ccc"><font color="#000">Reading</font></td>
							  <td style={{ border: '1px solid #ccc' }} bgcolor="#FDB515">&nbsp;</td>
							  <td style={{ border: '1px solid #ccc' }} bordercolor="#ccc"><font color="#000">Meter</font></td>
							  <td style={{ border: '1px solid #ccc' }} bordercolor="#ccc"><font color="#000">Reading</font></td>
							  <td style={{ border: '1px solid #ccc' }} bordercolor="#ccc"><font color="#000">Pressure</font></td>
							</tr>
							<tr align="center">
							  <td style={{ border: '1px solid #ccc' }}>RE</td>
							  <td style={{ border: '1px solid #ccc' }}>
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'RE_READING')||''}
									onChange={(event) => this.onChange('RE_READING', event.target.value)} size={15} maxlength={15} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }} bgcolor="#FDB515">&nbsp;</td>
							  <td style={{ border: '1px solid #ccc' }}>PHE </td>
							  <td style={{ border: '1px solid #ccc' }}>
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'PHE_READING')||''}
									onChange={(event) => this.onChange('PHE_READING', event.target.value)} size={15} maxlength={15} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>&nbsp;</td>
							</tr>
							<tr align="center">
							  <td style={{ border: '1px solid #ccc' }}>GAS</td>
							  <td style={{ border: '1px solid #ccc' }}>
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'GAS_READING')||''}
									onChange={(event) => this.onChange('GAS_READING', event.target.value)} size={15} maxlength={15} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }} bgcolor="#FDB515">&nbsp;</td>
							  <td style={{ border: '1px solid #ccc' }}>PHYS</td>
							  <td style={{ border: '1px solid #ccc' }}>
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'PHYS_READING')||''}
									onChange={(event) => this.onChange('PHYS_READING', event.target.value)} size={15} maxlength={15} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>&nbsp;</td>
							</tr>
							<tr align="center">
							  <td style={{ border: '1px solid #ccc' }}>BAG</td>
							  <td style={{ border: '1px solid #ccc' }}>
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'BAG_READING')||''}
									onChange={(event) => this.onChange('BAG_READING', event.target.value)} size={15} maxlength={15} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }} bgcolor="#FDB515">&nbsp;</td>
							  <td style={{ border: '1px solid #ccc' }}>Liquid Helium Tank</td>
							  <td style={{ border: '1px solid #ccc' }}>
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'HE_TANK_READING')||''}
									onChange={(event) => this.onChange('HE_TANK_READING', event.target.value)} size={15} maxlength={15} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'HE_TANK_PRESSURE')||''}
									onChange={(event) => this.onChange('HE_TANK_PRESSURE', event.target.value)} size={15} maxlength={15} />
							  </td>
							</tr>
							<tr align="center">
							  <td style={{ border: '1px solid #ccc' }}>H+G</td>
							  <td style={{ border: '1px solid #ccc' }}>
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'HG_READING')||''}
									onChange={(event) => this.onChange('HG_READING', event.target.value)} size={15} maxlength={15} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }} bgcolor="#FDB515">&nbsp;</td>
							  <td style={{ border: '1px solid #ccc' }}>Liquid Nitrogen Tank </td>
							  <td style={{ border: '1px solid #ccc' }}>
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'LN_READING')||''}
									onChange={(event) => this.onChange('LN_READING', event.target.value)} size={15} maxlength={15} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'LN_PRESSURE')||''}
									onChange={(event) => this.onChange('LN_PRESSURE', event.target.value)} size={15} maxlength={15} />
							  </td>
							</tr>
							<tr align="center">
							  <td style={{ border: '1px solid #ccc' }}>L+T</td>
							  <td style={{ border: '1px solid #ccc' }}>
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'LT_READING')||''}
									onChange={(event) => this.onChange('LT_READING', event.target.value)} size={15} maxlength={15} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }} bgcolor="#FDB515">&nbsp;</td>
							  <td style={{ border: '1px solid #ccc' }}>Liquid Oxygen Tank</td>
							  <td style={{ border: '1px solid #ccc' }}>
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'LO_READING')||''}
									onChange={(event) => this.onChange('LO_READING', event.target.value)} size={15} maxlength={15} />
							  </td>
							  <td style={{ border: '1px solid #ccc' }}>
								  <Form.Control type='text' value={this.getByAccessor(this.state.daily, 'LO_PRESSURE')||''}
									onChange={(event) => this.onChange('LO_PRESSURE', event.target.value)} size={15} maxlength={15} />
							  </td>
							</tr>
							<tr align="center">
							<td style={{ border: '1px solid #ccc' }} bgcolor="#FDB515" bordercolor="#ccc"><font color="#000">Comments</font></td>
							<td style={{ border: '1px solid #ccc' }} colspan="5">
								<Form.Control as="textarea" cols={75} rows={2} value={this.getByAccessor(this.state.daily, 'METER_COMMENTS')||''}
								  onChange={(event) => this.onChange('METER_COMMENTS', event.target.value)} />
							</td>
							</tr>
						  </table>					  
					  </Col>
					</Row>
				    <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={12}>
					    <Button id="submit" variant="warning" type="submit" className="float-end" onClick={(event) => this.onSubmitRequest(event)}>Submit</Button>
					  </Col>
				    </Form.Group>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

export default DailyReadings;
