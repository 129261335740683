import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';

class Help extends ChemComponent {
	
	render() {
		return(
			<Container fluid>
			  <Row>
			    <Col md={12} lg={11} xl={10} style={{ paddingTop: '20px', paddingBottom: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Stores</span><br />
			      Please contact John Borland <a href='mailto:jborland@berkeley.edu'>jborland@berkeley.edu</a> 510-643-1706 for assistance with the Stores application.
				</Col>
			  </Row>
			</Container>
		);
	}
}

export default Help;