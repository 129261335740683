import ChemComponent from '../ChemComponent';
import { withGSIDefaultRoute } from './withGSIDefaultRoute';
import ChemEdit from '../ChemEdit';
import ChemTable from '../ChemTable';
import Tabs from '../Tabs';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import GSICourse from './GSICourse';
import HashLink from '../HashLink';

class GSI extends ChemComponent {
	constructor(props) {
		super(props);

		this.state = {
			editable: props.editable,
			activeTab: this.getActiveTabParam() || 'GSI',
			assignment: {},
			courseColumns: approvedCourseColumns,
			payrollColumns: this.copyColumns(payrollColumns),
			renderKey: 0,
			unsavedChanges: false,
			offset: {
				top: 0,
				left: 0,
				bottom: 0,
				right: 0
			}
		}
	}

	getActiveTabParam() {
		if (this.props.params.activeTab) return this.props.params.activeTab;
		// if there is only one parameter after 'new', we have to test if it's numeric to see whether it is student ID or active tab
		if (this.props.params.studentIdOrActiveTab && !this.isNumeric(this.props.params.studentIdOrActiveTab)) return this.props.params.studentIdOrActiveTab;
		return null;
	}

	getStudentIDParam() {
		if (this.props.params.studentId) return this.props.params.studentId;
		// if there is only one parameter after 'new', we have to test if it's numeric to see whether it is student ID or active tab
		if (this.props.params.studentIdOrActiveTab && this.isNumeric(this.props.params.studentIdOrActiveTab)) return this.props.params.studentIdOrActiveTab;
		return null;
	}

	componentDidMount() {
		this.loadAssignment();
	}
	
	componentDidUpdate(prevProps) {
		var newActiveTab = this.getActiveTabParam() || 'GSI';
		
		// this is necessary so browser back/forward buttons will work across tabs
		if (newActiveTab !== this.state.activeTab) {
			this.mergeState({
				activeTab: newActiveTab,
				renderKey: this.state.renderKey + 1
			});
		}
	}

	loadAssignment(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/GradOffice/GetGSI',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,	
				assignmentid: this.props.params.assignmentId,
				studentid: this.getStudentIDParam(),
				outside: this.props.params.assignmentId ? null : !this.getStudentIDParam()
			}
		}).done(function (data) {
			if (data.Success) {
				var assignment = data.assignment;
				var isApproved = !self.isEmpty(assignment.gsi_course.APPROVED_BY);
				
				// convert dates, checkboxes
				if (assignment.outside_gsi) {
					assignment.outside_gsi.CA_RESIDENT = assignment.outside_gsi.CA_RESIDENT === 1 ? 'Y' : null;
					self.mvc2js(assignment, outsideGSIColumns);
				} else {
					self.mvc2js(assignment, gsiColumns);
					
					// copy email address if temp is missing
					if (self.isEmpty(assignment.address_phone.TEMP_EMAIL_ADD)) assignment.address_phone.TEMP_EMAIL_ADD = assignment.address_phone.EMAIL;
					
					// calculate salary
					assignment.payroll_info.SALARY = (assignment.PAY_PERCENTAGE * assignment.payroll_info.MONTHLY_RATE).toFixed(2);
				}
				self.mvc2js(assignment, isApproved ? approvedCourseColumns : pendingCourseColumns);
				self.mvc2js(assignment, payrollColumns);
				
				assignment.PREPARED_BY = self.composeChangeInfo(assignment.MODIF_BY, assignment.MODIF_DATE) ||
					self.composeChangeInfo(assignment.CREATED_BY, assignment.CREATE_DATE);
					
				assignment.REVIEWED = assignment.review_comments.length === 0 ? "" :
					self.composeChangeInfo(
						assignment.review_comments[0].REVIEWER_NAME,
						assignment.review_comments[0].DATE_REVIEWED);
				
				if (!assignment.fellowships) assignment.fellowships = [];
				assignment.FELLOWSHIP = assignment.fellowships.length === 0 ? "" :
					assignment.fellowships[0].FUND + " (id: " + assignment.fellowships[0].FELLOWSHIP_ID +
					") Fee Remission: " + assignment.fellowships[0].FEE_REMISSION + " " +
					self.dateToString(self.getDate(assignment.fellowships[0].START_DATE)) +
					self.dateToString(self.getDate(assignment.fellowships[0].END_DATE));
					
				var newPayrollColumns = self.copyColumns(payrollColumns);
				self.setStringValue(newPayrollColumns, 'PAYROLL_ID', 'SEMESTER', assignment.SEMESTER.substring(0, 2).toUpperCase());
				self.setStringValue(newPayrollColumns, 'PAYROLL_ID', 'ACAD_YEAR', assignment.ACAD_YEAR);

				self.mergeState({
					assignment: assignment,
					courseColumns: isApproved ? approvedCourseColumns : pendingCourseColumns,
					payrollColumns: newPayrollColumns,
					renderKey: self.state.renderKey + 1,
					unsavedChanges: false
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
		
	onChange(accessor, value) {
		var assignment = this.copyObject(this.state.assignment);
		var newPayrollColumns = null;
		
		if (Array.isArray(accessor) && accessor.includes('PAYROLL_ID')) {
			// get payroll id and title code display
			var payrollID = value[accessor.indexOf('PAYROLL_ID')];
			var titleCodeDisplay = value[accessor.indexOf('TITLE_CODE_DISPLAY')];
			
			// separate into title code, monthly amount
			var titleCode;
			var monthlyRate;
			if (this.isEmpty(payrollID)) {
				titleCode = '';
				monthlyRate = 0;
			} else {
				var titleAndMonthly = titleCodeDisplay.split('-');
				titleCode = titleAndMonthly[0].trim();
				monthlyRate = this.unFormatCurrency(titleAndMonthly[1].trim()).toFixed(2);
			}
			
			// set payroll id, title code, monthly amount
			this.setByAccessor(assignment, 'PAYROLL_ID', payrollID);
			this.setByAccessor(assignment, 'TITLE_CODE', titleCode);
			this.setByAccessor(assignment, 'payroll_info.MONTHLY_RATE', monthlyRate);
		} else {
			if ((accessor === 'ACAD_YEAR' && /^[0-9][0-9]-[0-9][0-9]$/.test(value)) || accessor === 'SEMESTER') {
				newPayrollColumns = this.copyColumns(this.state.payrollColumns);
				if (accessor === 'ACAD_YEAR') this.setStringValue(newPayrollColumns, 'PAYROLL_ID', 'ACAD_YEAR', value);
				if (accessor === 'SEMESTER') this.setStringValue(newPayrollColumns, 'PAYROLL_ID', 'SEMESTER', value.substring(0, 2).toUpperCase());
			}
			
			this.setByAccessor(assignment, accessor, value);
		}

		// calculate salary
		this.setByAccessor(assignment, 'payroll_info.SALARY', (assignment.PAY_PERCENTAGE * (assignment.payroll_info ? assignment.payroll_info.MONTHLY_RATE : 0)).toFixed(2));
		var newState = { 
			assignment: assignment,
			renderKey: this.state.renderKey + 1, 
			unsavedChanges: true
		};
		if (newPayrollColumns !== null) newState.payrollColumns = newPayrollColumns;
		this.mergeState(newState);
	}
	
	setStringValue(columns, accessor, attribute, value) {
		for (var i = 0; i < columns.length; i++) {
			if (columns[i].accessor === accessor) {
				this.setStringValueRecursive(columns[i].options.search, attribute, value);
			}
		}
	}
	
	setStringValueRecursive(search, attribute, value) {
		if (search.Operator === 'and' || search.Operator === 'or') {
			for (var i = 0; i < search.Children.length; i++) {
				this.setStringValueRecursive(search.Children[i], attribute, value);
			}
		} else if (search.Attribute === attribute) {
			search.StringValue = value;
		}
	}
		
	deleteAssignment(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Assignment', 'Delete Assignment ' + this.props.params.assignmentId + '?', (okClicked) => {
			if (okClicked) {
				// prepare assignment for deletion		
				var assignment = {
					ASSIGNMENT_ID: self.props.params.assignmentId,
					DELETE_FLAG: 'Y',
					MODIF_BY: self.props.user.name,
					MODIF_DATE: self.dateTimeToMVC(new Date())
				};
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'ADMISSIONS.GS_ASSIG', data: assignment }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Assignment {self.props.params.assignmentId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}

	onSubmit(event) {
		var self = this;		
		
		// make copy of data
		var assignment = this.copyObject(this.state.assignment);
		var isApproved = !this.isEmpty(assignment.gsi_course.APPROVED_BY);

		// convert dates, checkboxes
		if (assignment.outside_gsi) {
			self.js2mvc(assignment, outsideGSIColumns);
			assignment.outside_gsi.CA_RESIDENT = assignment.outside_gsi.CA_RESIDENT === 'Y' ? 1 : 0;
		} else {
			self.js2mvc(assignment, gsiColumns);
		}
		self.js2mvc(assignment, isApproved ? approvedCourseColumns : pendingCourseColumns);
		self.js2mvc(assignment, payrollColumns);
					
		// if this is a new assignment, set ID to sequence name
		if (!this.props.params.assignmentId) {
			assignment.ASSIGNMENT_ID = 'gsi_assignment_seq.nextval';
			assignment.DEPT_CODE = 1;
		}
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/GradOffice/UpdateGSI',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, assignment: assignment }
		}).done(function (data) {
			if (data.Success) {
				var assignmentId = data.ID;
				var verb = self.props.params.assignmentId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						GSI Assignment <Link to={'/GradOffice/GSIs/' + assignmentId}>{assignmentId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	onClickTabItem(tab) {
		this.props.navigate('/GradOffice/GSIs/' + (this.props.params.assignmentId || 'new') + '/' + tab);
	}
	
	onClickPayroll(event) {
		this.showAlert('Not Implemented', 'Payroll functions not implemented.');
	}
	
	onAnchorTLResize(offset) {
		this.mergeState({
			offset: {
				top: offset.top,
				left: offset.left
			}
		});
	}

	onAnchorBRResize(offset) {
		this.mergeState({
			offset: {
				bottom: offset.bottom,
				right: offset.right
			}
		});
	}

	render() {
		return (<>
			<Container fluid style={{ border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '20px' }}>
				  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>GSI {this.props.params.assignmentId}&nbsp;&nbsp;
					{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
					  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
				  </div>
				  <Tabs activeTab={this.state.activeTab} onClickTabItem={(tab) => this.onClickTabItem(tab)}>
				    <div label='GSI'>
					  <Container fluid>
						<Row>
						  <Col>
							<ChemEdit parent={this} columns={this.state.assignment.outside_gsi ? outsideGSIColumns : gsiColumns} data={this.state.assignment} user={this.props.user}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} editable={this.state.editable} />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Course'>
					  <Container fluid>
						<Row>
						  <Col>
							<ChemEdit parent={this} columns={this.state.courseColumns} data={this.state.assignment} user={this.props.user}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} editable={this.state.editable} />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Payroll'>
					  <Container fluid>
						<Row>
						  <Col>
							<ChemEdit style={{ position: 'relative' }} parent={this} columns={this.state.payrollColumns} data={this.state.assignment} user={this.props.user}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} editable={this.state.editable} />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='History'>
						{this.state.assignment.history &&
							<ChemTable parent={this} user={this.props.user} renderKey={this.state.renderKey} data={this.state.assignment.history} columns={historyColumns} 
								tableName='GradOffice_GSI_History' />
						}
					</div>
				  </Tabs>
				</Col>
			  </Row>
			  <Row>
				<Col style={{ paddingTop: '20px', paddingBottom: '20px' }}>
				  {this.state.editable && <Button id="saveAssignment" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmit(event)}>Save</Button>}
				  {this.state.editable && this.props.params.assignmentId && <Button id="deleteAssignment" variant="warning" type="button" className="float-end"
					style={{ marginRight: '20px' }} onClick={(event) => this.deleteAssignment(event)}>Delete</Button>} 
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const gsiColumns = [
	{ Header: 'Last Name', accessor: 'application.LNAME', row: 0, col: 0, editable: false },
	{ Header: 'First Name', accessor: 'application.FNAME', row: 0, col: 1, editable: false },
	{ Header: 'MI', accessor: 'application.MNAME', row: 0, col: 2, editable: false },
	{ Header: 'Student ID', accessor: 'students.STUDENT_CARD', row: 0, col: 3, editable: false },
	{ Header: 'Head GSI', accessor: 'gsi_course.HEAD_GSI', type: 'checkbox', row: 1, col: 0 },
	{ Header: 'CA Resident', accessor: 'application.CA_RESIDENT', type: 'checkbox', row: 1, col: 1, editable: false },
	{ Header: 'Email', accessor: 'address_phone.TEMP_EMAIL_ADD', row: 1, col: 2, editable: false },
	{ Header: 'Send Email', accessor: 'address_phone.TEMP_EMAIL_ADD', row: 1, col: 3,
		Cell: props => <a href={'mailto:' + props.parent.getByAccessor(props.parent.state.assignment, 'address_phone.TEMP_EMAIL_ADD')}>
			{props.parent.getByAccessor(props.parent.state.assignment, 'address_phone.TEMP_EMAIL_ADD')}</a> },
	{ Header: 'Academic Year Entered', accessor: 'students.ACAD_YEAR', row: 2, col: 0, editable: false },
	{ Header: 'Citizenship', accessor: 'application.CITIZENSHIP', row: 2, col: 1, editable: false },
	{ Header: 'Department', accessor: 'application.DEPT_CODE', Cell: props => <>Chemistry</>, row: 2, col: 2, editable: false },
	{ Header: 'Visa', accessor: 'application.VISA', row: 2, col: 3, editable: false }
];

const outsideGSIColumns = [
	{ Header: 'Last Name', accessor: 'outside_gsi.LNAME', row: 0, col: 0, required: true },
	{ Header: 'First Name', accessor: 'outside_gsi.FNAME', row: 0, col: 1, required: true },
	{ Header: 'MI', accessor: 'outside_gsi.MNAME', row: 0, col: 2, required: true },
	{ Header: 'Student ID', accessor: 'outside_gsi.STUDENT_CARD', row: 0, col: 3, required: true },
	{ Header: 'Head GSI', accessor: 'gsi_course.HEAD_GSI', type: 'checkbox', row: 1, col: 0 },
	{ Header: 'CA Resident', accessor: 'outside_gsi.CA_RESIDENT', type: 'checkbox', row: 1, col: 1, required: true },
	{ Header: 'Email', accessor: 'outside_gsi.EMAIL', row: 1, col: 2, required: true },
	{ Header: 'Send Email', accessor: 'outside_gsi.EMAIL', row: 1, col: 3,
		Cell: props => <a href={'mailto:' + props.parent.getByAccessor(props.parent.state.assignment, 'outside_gsi.EMAIL')}>
			{props.parent.getByAccessor(props.parent.state.assignment, 'outside_gsi.EMAIL')}</a> },
	{ Header: 'Academic Year Entered', accessor: 'outside_gsi.ACAD_YEAR', row: 2, col: 0, required: true },
	{ Header: 'Citizenship', accessor: 'outside_gsi.CITIZENSHIP', row: 2, col: 1, required: true },
	{ Header: 'Department', accessor: 'outside_gsi.DEPARTMENT', row: 2, col: 2, required: true },
	{ Header: 'Visa', accessor: 'outside_gsi.VISA', row: 2, col: 3, required: true }
];

const approvedCourseColumns = [
	{ Header: 'Semester', accessor: 'SEMESTER', row: 0, col: 0, editable: false },
	{ Header: 'Year', accessor: 'ACAD_YEAR', row: 0, col: 1, editable: false },
	{ Header: 'Module', accessor: 'gsi_course.MODULE_FLAG', type: 'checkbox', row: 0, col: 2 },
	{ Editor: props => <GSICourse onChange={(accessor, value) => props.parent.onChange(accessor, value)}
		academicYear={props.parent.getByAccessor(props.parent.state.assignment, 'ACAD_YEAR')}
		semester={props.parent.getByAccessor(props.parent.state.assignment, 'SEMESTER')}
		moduleFlag={props.parent.getByAccessor(props.parent.state.assignment, 'gsi_course.MODULE_FLAG')}
		scheduleId={props.parent.getByAccessor(props.parent.state.assignment, 'gsi_course.SCHEDULE_ID')}
		courseId={props.parent.getByAccessor(props.parent.state.assignment, 'course_schedule.COURSE_ID')}
		facultyId={props.parent.getByAccessor(props.parent.state.assignment, 'course_schedule.FACULTY1')}
		parent={props.parent} user={props.parent.props.user} editable={props.parent.state.editable} />, row: 0, col: 3 },
	{ Header: 'Schedule ID', accessor: 'gsi_course.SCHEDULE_ID', row: 1, col: 0, editable: false },
	{ Header: 'Approved By', accessor: 'gsi_course.APPROVED_BY', row: 1, col: 1 },
	{ Header: 'Created', accessor: 'CREATED', row: 1, col: 2, editable: false },
	{ Header: 'Comments', accessor: 'gsi_course.COMMENTS', type: 'textarea', row: 1, col: 3 }
];

const pendingCourseColumns = [
	{ Header: 'Semester', accessor: 'SEMESTER', row: 0, col: 0 },
	{ Header: 'Year', accessor: 'ACAD_YEAR', row: 0, col: 1, subscript: 'YY-YY' },
	{ Header: 'Module', accessor: 'gsi_course.MODULE_FLAG', type: 'checkbox', row: 0, col: 2 },
	{ Editor: props => <GSICourse onChange={(accessor, value) => props.parent.onChange(accessor, value)}
		academicYear={props.parent.getByAccessor(props.parent.state.assignment, 'ACAD_YEAR')}
		semester={props.parent.getByAccessor(props.parent.state.assignment, 'SEMESTER')}
		moduleFlag={props.parent.getByAccessor(props.parent.state.assignment, 'gsi_course.MODULE_FLAG')}
		scheduleId={props.parent.getByAccessor(props.parent.state.assignment, 'gsi_course.SCHEDULE_ID')}
		courseId={props.parent.getByAccessor(props.parent.state.assignment, 'course_schedule.COURSE_ID')}
		facultyId={props.parent.getByAccessor(props.parent.state.assignment, 'course_schedule.FACULTY1')}
		parent={props.parent} user={props.parent.props.user} />, row: 0, col: 3 },
	{ Header: 'Schedule ID', accessor: 'gsi_course.SCHEDULE_ID', row: 1, col: 0, editable: false },
	{ Header: 'Approved By', accessor: 'gsi_course.APPROVED_BY', row: 1, col: 1 },
	{ Header: 'Created', accessor: 'CREATED', row: 1, col: 2, editable: false },
	{ Header: 'Comments', accessor: 'gsi_course.COMMENTS', type: 'textarea', row: 1, col: 3 }
];

const payrollColumns = [
	{ Header: 'Status', accessor: 'payroll_info.GSI_STATUS_ID', type: 'select', row: 0, col: 0,
		options: { value: 'GSI_STATUS_ID', label: 'GSI_STATUS_ABBREV', entity: 'ADMISSIONS.GSI_STATUS_SELECT_VW' }},
	{ Header: 'Appointment Rate', accessor: 'PAY_PERCENTAGE', row: 0, col: 1 },
	{ Header: 'Not Paid By Chemistry', accessor: 'NON_CHEM', type: 'checkbox', row: 0, col: 2 },
	{ Header: 'Payroll Actions', accessor: 'gs_pay_action', type: 'list', row: 0, col: 3,
		options: { value: 'PAY_ACTION_ID', label: 'ACTION_NAME', entity: 'ADMISSIONS.PAYROLL_ACTION', order: 'DISPLAY_ORDER' }},
	{ Header: 'Title Code', accessor: 'PAYROLL_ID', labelAccessor: 'TITLE_CODE_DISPLAY', type: 'select', row: 1, col: 0,
		options: { value: 'PAYROLL_ID', label: 'TITLE_CODE_DISPLAY', entity: 'ADMISSIONS.PAYROLL_TITLE_SELECT_VW',
			search: { Operator: 'and', Children: [
				{ Attribute: 'SEMESTER', Operator: '=', StringValue: '' },
				{ Attribute: 'ACAD_YEAR', Operator: '=', StringValue: '' }
	]}}},
	{ Header: 'Concurrent Appointment', accessor: 'CONCURR_APPT', row: 1, col: 1 },
	{ Header: 'Monthly Rate $', accessor: 'payroll_info.MONTHLY_RATE', row: 1, col: 2, editable: false },
	{ Header: 'Monthly Salary $', accessor: 'payroll_info.SALARY', row: 1, col: 3, editable: false },
	{ Header: 'Chart String', accessor: 'paf_funding.CHART_STRING', row: 2, col: 0 },
	{ Header: 'GSI Request ID', accessor: 'gsi_course.REQUEST_ID', row: 2, col: 1 },
	{ Editor: props => <HashLink hash={'none'} onClick={(event) => props.parent.onClickPayroll(event)}>View/Update Payroll Appt.</HashLink>, row: 2, col: 2 },
	{ Header: 'Start Date', accessor: 'START_DATE', type: 'date', row: 3, col: 0 },
	{ Header: 'End Date', accessor: 'END_DATE', type: 'date', row: 3, col: 1 },
	{ Header: 'Health Coverage', accessor: 'BENEFITS', type: 'select', row: 3, col: 2,
		options: [{ value: null, label: 'Select...' },{ value: 'own', label: 'Own Health Coverage' },
			{ value: 'Y', label: 'PI Covering Benefits' },{ value: 'N', label: 'PI Not Covering Benefits' }]},
	{ Header: 'Delete Assignment', accessor: 'DELETE_FLAG', type: 'checkbox', row: 4, col: 0, subscript: "(assignment won't show in reports)" },
	{ Header: 'Prepared By', accessor: 'PREPARED_BY', row: 4, col: 1, editable: false },
	{ Header: 'Payroll Comments', accessor: 'COMMENTS', type: 'textarea', row: 4, col: 2 },
	{ Header: 'Fellowship', accessor: 'FELLOWSHIP', row: 5, col: 0, editable: false },
	{ Header: 'Reviewed', accessor: 'REVIEWED', row: 5, col: 1, editable: false },
	{ Header: 'Review Comments (not printed on form)', accessor: 'review_comments[0].review_comments', row: 5, col: 2, editable: false }
];

const historyColumns = [
	{ Header: 'ID', accessor: 'ASSIGNMENT_ID', width: 75 },
	{ Header: 'Type', accessor: 'GSI_STATUS', width: 75 },
	{ Header: 'Course', accessor: 'COURSE_NAME', width: 75 },
	{ Header: 'Title', accessor: 'PAYROLL_TITLE', width: 75 },
	{ Header: 'Pct', accessor: 'PAY_PERCENTAGE', width: 75 },
	{ Header: 'Start', accessor: 'START_DATE', width: 75 },
	{ Header: 'End', accessor: 'END_DATE', width: 75 },
	{ Header: '$/mo.', accessor: 'MONTHLY_RATE', width: 75 },
	{ Header: 'Deleted', accessor: 'DELETE_FLAG', width: 75 },
	{ Header: 'Modif', accessor: 'PREPARED_BY', width: 150 }
];

export default withGSIDefaultRoute(GSI);
