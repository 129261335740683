import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import { Link } from 'react-router-dom';

class GN2Upload extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var upload = {
			SERVICE_ID: null,
			FILE: null,
			VERIFY: true
		};
				
		this.state = {
			renderKey: 1,
			upload: upload,
		};
	}
	
	onChangeSearch(accessor, value) {
		var upload = this.copyObject(this.state.upload);
		this.setByAccessor(upload, accessor, value);
		this.mergeState({ upload: upload });
	}
	
	onSubmitSearch(event) {
		var self = this;
		
		// get file from state
		var upload = this.state.upload.FILE;
		
		// choose URL from verification checkbox
		var url = this.state.upload.VERIFY ? '/HeLAD/UploadGN2' : '/CRS/Upload'; 
		
		// if there is an upload file
		if (upload && upload.name) {
			// Create an object of formData
			const formData = new FormData();

			// add the token to the formData
			formData.append("__RequestVerificationToken", this.props.user.antiForgeryToken);
		
			// add the upload file with the service ID
			formData.append("upload", upload, '21');
			
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + url,
				overlay: true,
				data: formData,
				contentType: false,
				processData: false
			}).done(function (data) {
				if (data.Success) {
					if (self.state.upload.VERIFY) {					
						// if the upload went off with no input needed from user
						if (data.Data.linesUploaded) {					
							self.showConfirmation(() => {
								return (<>
									{data.Data.linesUploaded} Lines uploaded.<br />
									<Link to={'/HeLAD/MeterReadings'}>View meter readings</Link>
								</>);
							});
						} else {
							self.props.reconcileGN2Upload(data.Data.meterReadings);
						}
					} else {
					   self.showConfirmation(() => {
							   return (<>
									   {data.LinesUploaded} Lines uploaded.<br />
									   {data.ServicesNotFound.length > 0 && <>Services not found: {data.ServicesNotFound.join(', ')}<br /></>}
									   {data.UsersNotUploaded.length > 0 && <>Users not uploaded: {data.UsersNotUploaded.join(', ')}<br /></>}
									   <Link to={'/HeLAD/MeterReadings'}>View meter readings</Link>
							   </>);
					   });
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		}
	}
	
	render() {
		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>GN2 Upload</span>
					<ChemEdit parent={this} columns={uploadColumns} data={this.state.upload} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={12}>
						  <Button id="upload" variant="warning" type="submit" className="float-end">Upload xlsx file</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
			</Container>
		</>);
	}
}

const uploadColumns = [
	{ Header: 'File to Upload', accessor: 'FILE', type: 'file', required: true },
	{ Header: 'Verify Billing', accessor: 'VERIFY', type: 'checkbox', label: '(requires additional column Past_Amount with previous meter readings)' }
];

export default withRouter(GN2Upload);