import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';
import { withRouter } from '../withRouter';
import QueryTable from '../QueryTable';

class CardSwipeReport extends ChemComponent {
	constructor(props) {
		super(props);

		var twoDaysAgo = new Date();
		twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
		
		var search = {
			SWIPE_DATE_FROM: this.dateTimeToMVC(twoDaysAgo),
			SWIPE_DATE_TO: null,
			USER_NAME: ''
		};
		
		var initCardSwipeList = this.copyTable(cardSwipeList);
		initCardSwipeList.query.search = this.constructSearch(search);

		this.state = {
			search: search,
			cardSwipeList: initCardSwipeList,
			searchCount: null,
			renderKey: 0
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({
			search: search,
			renderKey: this.state.renderKey + 1
		});
	}

	onSubmitSearch(event) {
		var newCardSwipeList = this.copyTable(cardSwipeList);
		newCardSwipeList.query.search = this.constructSearch(this.state.search);
		this.mergeState({
			cardSwipeList: newCardSwipeList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.SWIPE_DATE_FROM)) search.Children.push({ Attribute: 'SWIPE_DATE', Operator: '>=', DateValue: this.dateTimeToMVC(data.SWIPE_DATE_FROM) });
		if (!this.isEmpty(data.SWIPE_DATE_TO)) search.Children.push({ Attribute: 'SWIPE_DATE', Operator: '<', DateValue: this.dateTimeToMVC(data.SWIPE_DATE_TO) });
		if (!this.isEmpty(data.USER_NAME)) search.Children.push({ Attribute: 'PERSON_NAME', Operator: 'like', StringValue: '%' + data.USER_NAME + '%' });

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}

	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Card Swipe Report',
			columns: exportColumns,
			entity: 'STORES.CARD_SWIPE_REPORT_VW',
			search: this.constructSearch(this.state.search),
			order: 'SWIPE_DATE desc'
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Card Swipe Report</span>
						<ChemEdit parent={this} user={this.props.user} columns={cardSwipeSearch} data={this.state.search} autoSubmit={true}
						  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)}
						  onSubmit = {(event) => this.onSubmitSearch(event, this.state.order)} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='Stores_CardSwipes' table={this.state.cardSwipeList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='190px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const cardSwipeSearch = [
	{ Header: 'Date From', accessor: 'SWIPE_DATE_FROM', type: 'datetime', row: 0, col: 0 },
	{ Header: 'Date To', accessor: 'SWIPE_DATE_TO', type: 'datetime', row: 0, col: 1 },
	{ Header: 'User Name', accessor: 'USER_NAME', row: 0, col: 2 }
];

const cardSwipeList = {
	query: { entity: 'STORES.CARD_SWIPE_REPORT_VW', search: null, order: 'SWIPE_DATE desc' },
	pageSize: 20,
	columns: [	
		{ Header: 'Swipe ID', accessor: 'SWIPE_ID', width: 80 },
		{ Header: 'Date/Time', accessor: 'SWIPE_DATE_DISPLAY', width: 200 },
		{ Header: 'User', accessor: 'PERSON_NAME', width: 250 },
		{ Header: 'PI', accessor: 'PI_NAME', width: 250 },
		{ Header: 'Default Speedtype', accessor: 'DEFAULT_SPEEDTYPE', width: 200 },
		{ Header: 'Card Mag', accessor: 'CARD_MAG' },
		{ Header: 'Card ID', accessor: 'CARD_ID' }
	]
};

const exportColumns = [
		{ Header: 'Description', accessor: 'DESCRIPTION', width: 70 },
		{ Header: 'Stock Number', accessor: 'STOCK_NO', width: 20 },
		{ Header: 'Location', accessor: 'LOCATION', width: 20 },
		{ Header: 'Catalog Number', accessor: 'CAT_NUMBER', width: 40 },
		{ Header: 'Price Sold', accessor: 'STORE_PRICE', width: 18 },
		{ Header: 'Stocking UOM', accessor: 'STOCKING_UOM', width: 16 },
		{ Header: 'Notes', accessor: 'MODIF_ACTION', width: 30 }
];

export default withRouter(CardSwipeReport);
