import React from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Column extends ChemComponent {
	constructor(props) {
		super(props);
		
		var initColumnColumns = this.copyColumns(columnColumns);
		initColumnColumns[0].options.search = { Attribute: 'HEADER_ID', Operator: '=', LongValue: this.props.params.headerId };
				
		this.state = {
			column: {
				HEADER_ID: props.params.headerId,
				COLUMN_ID: null,
				COLUMN_ORDER: null,
				COMMENTS: '',
				DATE_CREATED: null,
				CREATED_BY: '',
				DATE_MODIF: null,
				MODIF_BY: '',
				DELETE_FLAG: undefined,
				CREATED: '',
				MODIFIED: ''
			},
			columnColumns: initColumnColumns,
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadColumn();
	}

	loadColumn(event) {
		var self = this;
		
		if (this.props.params.columnOrder) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/CRS/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'UPLOAD_HEADER_COLUMN',
					search: { Operator: 'and', Children: [
						{ Attribute: 'HEADER_ID', Operator: '=', LongValue: this.props.params.headerId },
						{ Attribute: 'COLUMN_ORDER', Operator: '=', LongValue: this.props.params.columnOrder },
						{ Attribute: 'DELETE_FLAG', Operator: 'is null' }
					]},
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var column = data.Data[0];
						
						// convert flags
						column.DELETE_FLAG = column.DELETE_FLAG === 'Y' ? true : false;
						
						// convert dates
						column.DATE_CREATED = self.dateTimeToMVC(self.getDate(column.DATE_CREATED));
						column.DATE_MODIF = self.dateTimeToMVC(self.getDate(column.DATE_MODIF));
						
						// compose created/modified
						column.CREATED = self.composeChangeInfo(column.CREATED_BY, column.DATE_CREATED);
						column.MODIFIED = self.composeChangeInfo(column.MODIF_BY, column.DATE_MODIF);
						
						self.mergeState({
							column: column,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Column Error', data.Data.length + ' Column ' + self.props.params.columnOrder + ' not found');
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/CRS/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'NEW_COLUMN_ORDER_VW',
					search: { Attribute: 'HEADER_ID', Operator: '=', LongValue: this.props.params.headerId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var colorder = data.Data[0];
						
						self.mergeState({
							column: {
								COLUMN_ORDER: colorder.NEW_COLUMN_ORDER,
								DELETE_FLAG: false
							},
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.mergeState({
							column: {
								COLUMN_ORDER: 1,
								DELETE_FLAG: false
							},
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		}
	}
		
	onChange(accessor, value) {
		var column = this.copyObject(this.state.column);
		this.setByAccessor(column, accessor, value);
		this.mergeState({ column: column, renderKey: this.state.renderKey + 1 });
	}

	deleteColumn(event) {
		var self = this;
		
		// assemble user object to update delete flag
		var column = {
			HEADER_ID: this.props.params.headerId,
			COLUMN_ORDER: this.props.params.columnOrder,
			DELETE_FLAG: 'Y',
			DATE_MODIF: this.dateTimeToMVC(new Date()),
			MODIF_BY: this.props.user.name
		};
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/CRS/DeleteColumn',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, column: column }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Column {self.props.params.columnOrder} deleted.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	validateColumn(event, columns, data) {
		return this.validate(columns, data);
	}
		
	onSubmit(event) {
		var self = this;		
		
		// make copy of data
		var column = this.copyObject(this.state.column);

		// if this is a new user
		var url;
		if (this.props.params.columnOrder) {
			url = this.getConfig().host + '/CRS/UpdateColumn';
			column.DATE_MODIF = this.dateTimeToMVC(new Date());
			column.MODIF_BY = this.props.user.name;
			column.DATE_CREATED = this.dateTimeToMVC(this.getDate(column.DATE_CREATED));
		} else {
			url = this.getConfig().host + '/Home/Insert';
			column.DATE_CREATED = this.dateTimeToMVC(new Date());
			column.CREATED_BY = this.props.user.name;
		}
		
		// convert flags
		column.DELETE_FLAG = column.DELETE_FLAG ? 'Y' : null;
		
		this.ajax({
			type: 'POST',
			url: url,
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'RECHARGES.UPLOAD_HEADER_COLUMN', data: column }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Column <Link to={'/CRS/Headers/' + self.props.params.headerId + '/Columns/' + column.COLUMN_ORDER}>{column.COLUMN_ORDER}</Link> updated.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Header {this.state.column.HEADER_ID} Column {this.state.column.COLUMN_ORDER}</div>
				<ChemEdit parent={this} columns={this.state.columnColumns} data={this.state.column} user={this.props.user} renderKey={this.state.renderKey} 
				  onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}
				  validate={(event, columns, data) => this.validateColumn(event, columns, data)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					  {this.props.params.columnOrder && <Button id="delete" variant="warning" type="button" className="float-end" style={{ marginRight: '10px' }} onClick={(event) => this.deleteColumn(event)}>Delete</Button>}
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const columnColumns = [
	{ Header: 'Header', accessor: 'HEADER_ID', type: 'listoptions', row: 0, col: 0, editable: false,
		options: { value: 'HEADER_ID', label: 'HEADER_NAME', entity: 'RECHARGES.UPLOAD_HEADER' }},
	{ Header: 'Column', accessor: 'COLUMN_ID', type: 'select', row: 0, col: 1, required: true,
		options: { value: 'COLUMN_ID', label: 'COLUMN_NAME', entity: 'RECHARGES.UPLOAD_COLUMN', order: 'COLUMN_NAME',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Order', accessor: 'COLUMN_ORDER', row: 1, col: 0, editable: false },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 1, col: 1 },
	{ Header: 'Created', accessor: 'CREATED', row: 2, col: 0, editable: false },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 2, col: 1, editable: false },
];

export default withRouter(Column);
