import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class Rates extends ChemComponent {
	constructor(props) {
		super(props);

		var search = {
			RENTAL_DESC: ''
		};
		
		var rateListInit = this.copyTable(rateList);
		rateListInit.query.search = this.constructSearch(search);

		this.state = {
			rateList: rateListInit,
			search: search,
			searchCount: null,
			renderKey: 0
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newRateList = this.copyTable(rateList);
		newRateList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			rateList: newRateList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.RENTAL_DESC)) search.Children.push({ Attribute: 'RENTAL_DESC', Operator: 'like', StringValue: '%' + data.RENTAL_DESC + '%' });

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	addRate() {
		this.props.navigate('/HeLAD/Rates/new');
	}
	
	editRate(rateId) {
		this.props.setRateEditable(true);
		this.props.navigate('/HeLAD/Rates/' + rateId);		
	}

	viewRate(rateId) {
		this.props.setRateEditable(false);
		this.props.navigate('/HeLAD/Rates/' + rateId);		
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Rental Rates',
			columns: exportColumns,
			entity: 'DEMURRAGE.DEM_RENTAL_RATES',
			search: this.constructSearch(this.state.search)
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Rental Rates</span>
						<ChemEdit parent={this} columns={rateSearch} data={this.state.search} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} {...this.props} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='HeLAD_Rates' table={this.state.rateList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='190px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const rateSearch = [
	{ Header: 'Description', accessor: 'RENTAL_DESC', row: 0, col: 0 },
];

const rateList = {
	query: { entity: 'DEMURRAGE.DEM_RENTAL_RATES', search: null },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addRate()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editRate(props.row.values.RENTAL_RATE_ID)} />, width: 25 },
		{ Header: 'ID', accessor: 'RENTAL_RATE_ID', width: 50,
			Cell: props => <HashLink onClick={() => props.parent.viewRate(props.value)}>{props.value}</HashLink> },
		{ Header: 'Description', accessor: 'RENTAL_DESC', width: 500 },
		{ Header: 'Rate', accessor: 'RENTAL_RATE', width: 60 },
		{ Header: 'Deleted', accessor: 'DELETE_FLAG', width: 70	},		
		{ Header: 'Modified By', accessor: 'MODIF_BY', width: 150 },
		{ Header: 'Date Modified', accessor: 'MODIF_DATE', width: 200 }
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'RENTAL_RATE_ID', width: 10 },
	{ Header: 'Description', accessor: 'RENTAL_DESC', width: 100 },
	{ Header: 'Rate', accessor: 'RENTAL_RATE', width: 12 },
	{ Header: 'Deleted', accessor: 'DELETE_FLAG', width: 14	},		
	{ Header: 'Modified By', accessor: 'MODIF_BY', width: 30 },
	{ Header: 'Date Modified', accessor: 'MODIF_DATE', width: 40 }
];

export default withRouter(Rates);
