import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';


class ServiceContracts extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			COMPANY_NAME: '',
			CUSTOMER_ID: '',
			PO_NUM: '',
			PROJECT_ID: '',
			END_DATE_FROM: null,
			END_DATE_TO: null
		};
		
		var initServiceContractsList = this.copyTable(serviceContractsList);
		initServiceContractsList.query.search = this.constructSearch(search);
		
		this.state = {
			serviceContractsList: initServiceContractsList,
			search: search,
			searchCount: null,
			renderKey: 1,
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newServiceContractsList = this.copyTable(serviceContractsList);
		newServiceContractsList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			serviceContractsList: newServiceContractsList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: [
				{ Attribute: 'DELETE_FLAG', Operator: 'is null' }
			]
		};

		if (!this.isEmpty(data.COMPANY_NAME)) search.Children.push({ Attribute: 'COMPANY_NAME', Operator: 'like', StringValue: '%' + data.COMPANY_NAME + '%' });
		if (!this.isEmpty(data.CUSTOMER_ID)) search.Children.push({ Attribute: 'CUSTOMER_ID', Operator: '=', StringValue: data.CUSTOMER_ID });
		if (!this.isEmpty(data.PO_NUM)) search.Children.push({ Attribute: 'PO_NUM', Operator: '=', StringValue: data.PO_NUM });
		if (!this.isEmpty(data.PROJECT_ID)) search.Children.push({ Attribute: 'PROJECT_ID', Operator: '=', StringValue: data.PROJECT_ID });

		return search;
	}
	
	addServiceContract() {
		this.props.navigate('/CRS/ServiceContracts/new');
	}
	
	editServiceContract(serviceContractId) {
		this.props.navigate('/CRS/ServiceContracts/' + serviceContractId);
	}

	render() {

		var exportColumns = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Service Contracts',
			columns: serviceContractExportColumns,
			entity: 'RECHARGES.SERVICE_CONTRACT_VW',
			search: this.constructSearch(this.state.search)
		});

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Service Contracts</span>
					<ChemEdit parent={this} columns={searchColumns} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={4} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportColumns.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportColumns.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>

				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_ServiceContracts' table={this.state.serviceContractsList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='250px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const searchColumns = [
	{ Header: 'Company Name', accessor: 'COMPANY_NAME', row: 0, col: 0 },
	{ Header: 'Customer ID', accessor: 'CUSTOMER_ID', row: 0, col: 1 },
	{ Header: 'PO Number', accessor: 'PO_NUM', row: 0, col: 2 },
	{ Header: 'BCMS Project ID', accessor: 'PROJECT_ID', row: 1, col: 0 },
	{ Header: 'End Date From', accessor: 'END_DATE_FROM', type: 'date', row: 1, col: 1 },
	{ Header: 'End Date To', accessor: 'END_DATE_TO', type: 'date', row: 1, col: 2 }
];

const serviceContractsList = {
	query: { entity: 'RECHARGES.SERVICE_CONTRACT_VW', search: null },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addServiceContract()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editServiceContract(props.row.values.SERVICE_CONTRACT_ID)} />, width: 25 },
		{ Header: 'ID', accessor: 'SERVICE_CONTRACT_ID', width: 60 },
		{ Header: 'Company Name', accessor: 'COMPANY_NAME', width: 225 },
		{ Header: 'Customer ID', accessor: 'CUSTOMER_ID', width: 125 },
		{ Header: 'Address', accessor: 'ADDRESS', width: 400 },
		{ Header: 'Email', accessor: 'EMAIL', width: 200 },
		{ Header: 'PO Number', accessor: 'PO_NUM', width: 125 },
		{ Header: 'BCMS Project ID', accessor: 'PROJECT_ID', width: 125 },
		{ Header: 'Start Date', accessor: 'START_DATE', width: 100 },
		{ Header: 'End Date', accessor: 'END_DATE', width: 100 }
	]
};

const serviceContractExportColumns = [
	{ Header: 'ID', accessor: 'SERVICE_CONTRACT_ID', width: 12 },
	{ Header: 'Company Name', accessor: 'COMPANY_NAME', width: 45 },
	{ Header: 'Customer ID', accessor: 'CUSTOMER_ID', width: 25 },
	{ Header: 'Address', accessor: 'ADDRESS', width: 80 },
	{ Header: 'Email', accessor: 'EMAIL', width: 40 },
	{ Header: 'PO Number', accessor: 'PO_NUM', width: 25 },
	{ Header: 'BCMS Project ID', accessor: 'PROJECT_ID', width: 25 },
	{ Header: 'Start Date', accessor: 'START_DATE', width: 20 },
	{ Header: 'End Date', accessor: 'END_DATE', width: 20 }
];

export default withRouter(ServiceContracts);