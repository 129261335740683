import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../../ChemComponent';
import ChemEdit from '../../ChemEdit';
import QueryTable from '../../QueryTable';

class LocationList extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			TYPE_ID: '10',
		};
		
		var initialLocationList = this.copyLocationList();
		initialLocationList.query.search = this.constructSearch(search);
		
		this.state = {
			renderKey: 1,
			locationList: initialLocationList,
			search: search,
			searchCount: null
		};
	}
	
	toNumberList(str) {
		var strs = str.split(',');
		var nums = [];
		for (var i = 0; i < strs.length; i++) {
			nums.push(+strs[i]);
		}
		return nums;
	}
	
	copyLocationList() {
		var locationListCopy = this.copyObject(locationList);
		for (var i = 0; i < locationList.columns.length; i++) {
			if (locationList.columns[i].Cell) {
				locationListCopy.columns[i].Cell = locationList.columns[i].Cell;
			}
		}
		return locationListCopy;
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newLocationList = this.copyLocationList();
		newLocationList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			locationList: newLocationList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = { Attribute: 'TYPE_ID', Operator: 'in', LongList: this.toNumberList(data.TYPE_ID) };
		return search;
	}
	
	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Location List',
			columns: exportColumns,
			entity: 'LOCATION_LIST_VW',
			search: this.constructSearch(this.state.search),
			order: ['PI_NAME', 'PERSON']
		});

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Location List</span>
					<ChemEdit parent={this} columns={locationSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={3} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_LocationList' table={this.state.locationList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='245px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const locationSearch = [
	{ Header: 'Status', accessor: 'TYPE_ID', type: 'select', options: [ 
		{ value: '10', label: 'Graduate Students' },
		{ value: '3', label: 'Staff' },
		{ value: '11,18,19', label: 'PostDocs' },
		{ value: '2', label: 'Faculty' }
	] }
];

const locationList = {
	query: { entity: 'LOCATION_LIST_VW', search: null, order: ['PI_NAME', 'PERSON'] },
	pageSize: 20,
	columns: [
		{ Header: 'PI', accessor: 'PI_NAME' },
		{ Header: 'Dept.', accessor: 'DEPARTMENT' },
		{ Header: 'Personnel', accessor: 'PERSON' },
		{ Header: 'Status', accessor: 'TYPE_NAME' },
		{ Header: 'Location', accessor: 'LOCATION' },
		{ Header: 'Phone', accessor: 'PHONE' }
	]
};

const exportColumns = [
	{ Header: 'PI', accessor: 'PI_NAME', width: 20 },
	{ Header: 'Dept.', accessor: 'DEPARTMENT', width: 20 },
	{ Header: 'Personnel', accessor: 'PERSON', width: 20 },
	{ Header: 'Status', accessor: 'TYPE_NAME', width: 20 },
	{ Header: 'Location', accessor: 'LOCATION', width: 20 },
	{ Header: 'Phone', accessor: 'PHONE', width: 20 }
];

export default LocationList;