import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class DemTransactions extends ChemComponent {
	constructor(props) {
		super(props);
		
		var today = this.midnight(new Date());
		var thirtyDaysAgo = new Date(today);
		thirtyDaysAgo.setDate(today.getDate() - 30);

		var search = {
			CYCLE_ID: '',
			GAS_ID: null,
			OWNER_ID: null,
			OUT_DATE_FROM: this.dateTimeToMVC(thirtyDaysAgo),
			OUT_DATE_TO: this.dateTimeToMVC(today),
			treeMenuClickCount: 0 // don't modify in this class
		};
		
		var demTransactionListInit = this.copyTable(demTransactionList);
		
		if (props.search) {
			var propsSearch = this.copyObject(props.search);
			this.mergeObject(search, propsSearch);
			search = propsSearch;
			demTransactionListInit.query.search = this.constructSearch(search);
		}

		this.state = {
			demTransactionList: demTransactionListInit,
			search: search,
			searchCount: null,
			renderKey: 0
		};
	}

	componentDidUpdate(prevProps) {
		if (this.state.search.treeMenuClickCount !== this.props.search.treeMenuClickCount) {
			var newDemTransactionList = this.copyTable(demTransactionList);
			newDemTransactionList.query.search = this.constructSearch(this.props.search);
			this.mergeState({
				demTransactionList: newDemTransactionList,
				search: this.copyObject(this.props.search),
				searchCount: null,
				renderKey: this.state.renderKey + 1
			});
		}
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newDemTransactionList = this.copyTable(demTransactionList);
		newDemTransactionList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			demTransactionList: newDemTransactionList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}

	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.CYCLE_ID)) search.Children.push({ Attribute: 'CYCLE_ID', Operator: '=', LongValue: data.CYCLE_ID });
		if (!this.isEmpty(data.GAS_ID)) search.Children.push({ Attribute: 'GAS_ID', Operator: '=', LongValue: data.GAS_ID });
		if (!this.isEmpty(data.OWNER_ID)) search.Children.push({ Attribute: 'OWNER_ID', Operator: '=', LongValue: data.OWNER_ID });
		if (!this.isEmpty(data.OUT_DATE_FROM)) search.Children.push({ Attribute: 'OUT_DATE', Operator: '>=', DateValue: data.OUT_DATE_FROM });
		if (!this.isEmpty(data.OUT_DATE_TO)) search.Children.push({ Attribute: 'OUT_DATE', Operator: '<', DateValue: this.nextDayMVC(data.OUT_DATE_TO) });		

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	addDemTransaction() {
		this.props.navigate('/HeLAD/DemTransactions/new');
	}
	
	editDemTransaction(demTransactionId) {
		this.props.setDemTransactionEditable(true);
		this.props.navigate('/HeLAD/DemTransactions/' + demTransactionId);		
	}

	viewDemTransaction(demTransactionId) {
		this.props.setDemTransactionEditable(false);
		this.props.navigate('/HeLAD/DemTransactions/' + demTransactionId);		
	}
	
	historyForCylinder(cylinderId) {
		this.props.navigate('/HeLAD/CylHistory/' + cylinderId);
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Demurrage Transactions',
			columns: exportColumns,
			entity: 'DEMURRAGE.DEM_TRANS_VW',
			search: this.constructSearch(this.state.search),
			order: 'OUT_DATE'
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Demurrage Transactions</span>
						<ChemEdit parent={this} user={this.props.user} columns={demTransactionSearch} data={this.state.search}
						  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='HeLAD_DemTransactions' table={this.state.demTransactionList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='230px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const demTransactionSearch = [
	{ Header: 'Trans ID', accessor: 'CYCLE_ID', row: 0, col: 0  },
	{ Header: 'Gas', accessor: 'GAS_ID', type: 'select', row: 0, col: 1,
		options: { value: 'GAS_ID', label: 'GAS_NAME', entity: 'DEMURRAGE.DEM_GASES', order: 'GAS_NAME',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is not null' }}},
	{ Header: 'Owner', accessor: 'OWNER_ID', type: 'select', row: 0, col: 2,
		options: { value: 'OWNER_ID', label: 'OWNER_NAME', entity: 'DEMURRAGE.DEM_OWNERS', order: 'OWNER_NAME',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is not null' }}},
	{ Header: 'Date Out From', accessor: 'OUT_DATE_FROM', type: 'date', row: 1, col: 0 }, 
	{ Header: 'Date Out To', accessor: 'OUT_DATE_TO', type: 'date', row: 1, col: 1 }
];

const demTransactionList = {
	query: { entity: 'DEMURRAGE.DEM_TRANS_VW', search: null, order: 'OUT_DATE' },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addDemTransaction()} />, accessor: 'id', width: 25, 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editDemTransaction(props.row.values.CYCLE_ID)} /> },
		{ Header: '', accessor: 'CYL_ID', show: false },
		{ Header: 'ID', accessor: 'CYCLE_ID', width: 50,
			Cell: props => <HashLink onClick={() => props.parent.viewDemTransaction(props.row.values.CYCLE_ID)}>{props.value}</HashLink> },
		{ Header: 'Checked out by', accessor: 'USER_NAME', width: 200, },
		{ Header: 'Date Out', accessor: 'OUT_DATE_DISPLAY', width: 125, },
		{ Header: 'Gas', accessor: 'GAS_NAME', width: 250 },
		{ Header: 'Cylinder', accessor: 'MFR_CODE', width: 100 },
		{ Header: 'Date Returned', accessor: 'RETURN_DATE_DISPLAY', width: 125 },
		{ Header: props => <OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">{'View Cylinder History'}</Popover.Header></Popover>}>
				<img src={props.parent.getConfig().host + '/Content/Icons/tank.gif'} alt={'View Cylinder History'}/>
			</OverlayTrigger>, accessor: 'drag', width: 70,
			Cell: props => <OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">{'View Cylinder History for ' + props.row.values.MFR_CODE}</Popover.Header></Popover>}>
				<HashLink onClick={() => props.parent.historyForCylinder(props.row.values.CYL_ID)}>
					<img src={props.parent.getConfig().host + '/Content/Icons/tank.gif'} alt={'View Cylinder History for ' + props.row.values.MFR_CODE} />
				</HashLink>
			</OverlayTrigger> }
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'CYCLE_ID', width: 10 },
	{ Header: 'Checked out by', accessor: 'USER_NAME', width: 40, },
	{ Header: 'Date Out', accessor: 'OUT_DATE_DISPLAY', width: 25, },
	{ Header: 'Gas', accessor: 'GAS_NAME', width: 50 },
	{ Header: 'Cylinder', accessor: 'MFR_CODE', width: 20 },
	{ Header: 'Date Returned', accessor: 'RETURN_DATE_DISPLAY', width: 25 }
];

export default withRouter(DemTransactions);
