import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import ChemEdit from '../ChemEdit';
import FellowshipDetail from './FellowshipDetail';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class Fellowship extends ChemComponent {
	constructor(props) {
		super(props);

		this.state = {
			editable: props.editable,
			fellowship: {
				current_year: {}
			},
			fellowshipColumns: fellowshipColumns,
			renderKey: 0,
		}
	}
	
	componentDidMount() {
		this.loadFellowship();
	}
	
	loadFellowship(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/GradOffice/GetFellowship',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,	
				fellowshipid: this.props.params.fellowshipId,
				studentid: this.props.params.studentId,
				outside: this.props.params.fellowshipId ? null : !this.props.params.studentId
			}
		}).done(function (data) {
			if (data.Success) {
				var fellowship = data.Data;
				
				if (fellowship.outside_fellow) {
					fellowship.outside_fellow.CA_RESIDENT = fellowship.outside_fellow.CA_RESIDENT === 1 ? 'Y' : null;
					self.mvc2js(fellowship, outsideFellowColumns);
				} else {
					self.mvc2js(fellowship, fellowshipColumns);
				}
				self.calculateFellowshipTotals(fellowship);
				
				self.mergeState({
					fellowship: fellowship,
					fellowshipColumns: fellowship.outside_fellow ? outsideFellowColumns : fellowshipColumns,
					renderKey: self.state.renderKey + 1,
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
	
	calculateFellowshipTotals(fellowship) {
		fellowship.current_year = {};
		// calculate current year totals
		// TODO: why does this cause a stack depth error when a non-numeric character is entered in fees, tuition, stipend
		fellowship.current_year.TOTAL_AVAIL = this.formatCurrency(
			this.unFormatCurrency(fellowship.fellowships.CARRY_FORWARD) +
			this.unFormatCurrency(fellowship.fellowships.EST_INCOME) +
			this.unFormatCurrency(fellowship.fellowships.ACT_INCOME)
		);
		fellowship.current_year.FEES = this.formatCurrency(
			this.unFormatCurrency(fellowship.fellowships.fall[4] ? fellowship.fellowships.fall[4].FEES : 0) +
			this.unFormatCurrency(fellowship.fellowships.spring[4] ? fellowship.fellowships.spring[4].FEES : 0) +
			this.unFormatCurrency(fellowship.fellowships.summer[5] ? fellowship.fellowships.summer[5].FEES : 0) +
			this.unFormatCurrency(fellowship.fellowships.summer[6] ? fellowship.fellowships.summer[6].FEES : 0)
		);
		fellowship.current_year.TUITION = this.formatCurrency(
			this.unFormatCurrency(fellowship.fellowships.fall[4] ? fellowship.fellowships.fall[4].TUITION : 0) +
			this.unFormatCurrency(fellowship.fellowships.spring[4] ? fellowship.fellowships.spring[4].TUITION : 0) +
			this.unFormatCurrency(fellowship.fellowships.summer[5] ? fellowship.fellowships.summer[5].TUITION : 0) +
			this.unFormatCurrency(fellowship.fellowships.summer[6] ? fellowship.fellowships.summer[6].TUITION : 0)
		);
		fellowship.current_year.STIPEND = this.formatCurrency(
			this.unFormatCurrency(fellowship.fellowships.fall[4] ? fellowship.fellowships.fall[4].STIPEND : 0) +
			this.unFormatCurrency(fellowship.fellowships.spring[4] ? fellowship.fellowships.spring[4].STIPEND : 0) +
			this.unFormatCurrency(fellowship.fellowships.summer[5] ? fellowship.fellowships.summer[5].STIPEND : 0) +
			this.unFormatCurrency(fellowship.fellowships.summer[6] ? fellowship.fellowships.summer[6].STIPEND : 0)
		);
		fellowship.current_year.TOTAL = this.formatCurrency(
			this.unFormatCurrency(fellowship.current_year.FEES) +
			this.unFormatCurrency(fellowship.current_year.TUITION) +
			this.unFormatCurrency(fellowship.current_year.STIPEND)
		);
	}		

	onChange(accessor, value) {
		var fellowship = this.copyObject(this.state.fellowship);
		if (Array.isArray(accessor)) {
			for (var i = 0; i < accessor.length; i++) {
				this.setByAccessor(fellowship, accessor[i], value[i]);
			}
		} else {
			this.setByAccessor(fellowship, accessor, value);
		}
		this.calculateFellowshipTotals(fellowship);
		this.mergeState({ fellowship: fellowship, renderKey: this.state.renderKey + 1 });
	}

	deleteFellowship(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Fellowship', 'Delete Fellowship ' + this.props.params.fellowshipId + '?', (okClicked) => {
			if (okClicked) {
				// prepare fellowship for deletion		
				var fellowships = {
					FELLOWSHIP_ID: self.props.params.fellowshipId,
					DELETE_FLAG: 1,
					MODIF_BY: self.props.user.name,
					MODIF_PERSON_ID: self.props.user.personID,
					LAST_MODIF: self.dateTimeToMVC(new Date())
				};
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'ADMISSIONS.FELLOWSHIPS', data: fellowships }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Fellowship {self.props.params.fellowshipId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}

	onSubmitFellowship(event) {
		var self = this;		
		
		// make copy of data
		var fellowship = this.copyObject(this.state.fellowship);

		// convert dates, checkboxes
		if (fellowship.outside_fellow) {
			self.js2mvc(fellowship, outsideFellowColumns);
			fellowship.outside_fellow.CA_RESIDENT = fellowship.outside_fellow.CA_RESIDENT === 'Y' ? 1 : 0;
		} else {
			self.js2mvc(fellowship, fellowshipColumns);
		}
					
		// if this is a new assignment, set ID to sequence name
		if (!this.props.params.fellowshipId) {
			fellowship.fellowships.FELLOWSHIP_ID = 'felowship_id_seq.nextval';
		}
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/GradOffice/UpdateFellowship',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, fellowship: fellowship }
		}).done(function (data) {
			if (data.Success) {
				var fellowshipId = data.ID;
				var verb = self.props.params.fellowshipId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Fellowship <Link to={'/GradOffice/Fellowships/' + fellowshipId}>{fellowshipId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
	  return(
		<Container fluid style={{ border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
		  <Row>
			<Col style={{ paddingTop: '20px', paddingBottom: '20px' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Fellowship {this.props.params.fellowshipId || '(new)'}&nbsp;&nbsp;
					{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
					  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				
				</div>		
				<ChemEdit parent={this} columns={this.state.fellowshipColumns} data={this.state.fellowship} user={this.props.user} editable={this.state.editable}
				  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />
				<FellowshipDetail parent={this} user={this.props.user} semester='Fall' data={this.state.fellowship} onChange={(accessor, value) => this.onChange(accessor, value)} editable={this.state.editable} />
				<FellowshipDetail parent={this} user={this.props.user} semester='Spring' data={this.state.fellowship} onChange={(accessor, value) => this.onChange(accessor, value)} editable={this.state.editable} />
				<ChemEdit parent={this} columns={summerFellowshipColumns} data={this.state.fellowship} user={this.props.user} editable={this.state.editable}
				  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />
				<ChemEdit parent={this} columns={fellowshipSummaryColumns} data={this.state.fellowship} user={this.props.user} editable={this.state.editable}
				  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmitFellowship(event)}>
				  <Form.Group>
					{this.state.editable && <Button id="saveFellowship" variant="warning" type="submit" className="float-end">Save</Button>}
					{this.state.editable && this.props.params.fellowshipId && <Button id="deleteFellowship" variant="warning" type="button" className="float-end"
					  style={{ marginRight: '20px' }} onClick={(event) => this.deleteFellowship(event)}>Delete</Button>} 
				  </Form.Group>
				</ChemEdit>
			</Col>
		  </Row>
		</Container>
	  );
	}
}

const outsideFellowColumns = [
	{ Header: 'Block Grant', accessor: 'fellowships.BLOCK_GRANT_FLAG', type: 'checkbox', row: 0, col: 0 },
	{ Header: 'First Name', accessor: 'outside_fellow.FNAME', row: 0, col: 1 },
	{ Header: 'Middle Name', accessor: 'outside_fellow.MNAME', row: 0, col: 2 },
	{ Header: 'Last Name', accessor: 'outside_fellow.LNAME', row: 0, col: 3 },
	{ Header: 'Student ID', accessor: 'outside_fellow.STUDENT_CARD', row: 1, col: 0 },
	{ Header: 'Student Status', accessor: 'fellowships.STUDENT_STATUS', type: 'select', row: 1, col: 1,
		options: [{ value: null, label: 'Select...' },{ value: '1', label: 'New' },{ value: '0', label: 'Continuing' }]},
	{ Header: 'Citizenship', accessor: 'outside_fellow.CITIZENSHIP', row: 1, col: 2 },
	{ Header: 'Visa Type', accessor: 'outside_fellow.VISA', type: 'select', row: 1, col: 3,
		options: { value: 'VISA_ID', label: 'VISA_NAME', entity: 'ADMISSIONS.VISA', order: 'VISA_NAME' }},
	{ Header: 'CA Resident', accessor: 'outside_fellow.CA_RESIDENT', type: 'checkbox', row: 2, col: 0 },
	{ Header: 'Res. Director', accessor: 'outside_fellow.RES_DIRECTOR', type: 'dropdown', row: 2, col: 1,
		options: { value: 'RES_DIRECTOR_NAME', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'RES_DIRECTOR_NAME', Operator: '=', StringAccessor: 'outside_fellow.RES_DIRECTOR' }
	]}}},
	{ Header: 'Fellow Type', accessor: 'fellowships.FELLOW_TYPE', type: 'select', row: 2, col: 2,
		options: [{ value: null, label: 'Select...' },{ value: '1', label: 'Resident' },{ value: '0', label: 'Travel' }]},
	{ Header: 'Fee Remission', accessor: 'fellowships.FEE_REMISSION', type: 'select', row: 2, col: 3,
		options: [{ value: null, label: 'Select...' },{ value: 'F', label: 'Full' },{ value: 'P', label: 'Partial' },{ value: 'N', label: 'None' }]},
	{ Header: 'Start Date', accessor: 'fellowships.START_DATE', type: 'date', row: 3, col: 0 },
	{ Header: 'End Date', accessor: 'fellowships.END_DATE', type: 'date', row: 3, col: 1 },
	{ Header: 'Academic Year', accessor: 'outside_fellow.ACADEMIC_YEAR', row: 3, col: 2 },
	{ Header: 'Fellowship Year', accessor: 'fellowships.FELLOWSHIP_YEAR', row: 3, col: 3 },
	{ Header: 'Fund', accessor: 'fellowships.FUND', row: 4, col: 0 },
	{ Header: 'Fund Number', accessor: 'fellowships.FUND_NUMBER', row: 4, col: 1 },
	{ Header: 'Form Comments', accessor: 'fellowships.FORM_COMMENTS', type: 'textarea', row: 4, col: 2 },
	{ Header: 'List Comments', accessor: 'fellowships.LIST_COMMENTS', type: 'textarea', row: 4, col: 3 },
	{ Header: 'Non Printing Notes', accessor: 'fellowships.PRIV_COMMENTS', type: 'textarea', row: 5, col: 0 },
	{ Header: 'Created', accessor: 'fellowships.CREATED', row: 5, col: 1, editable: false },
	{ Header: 'Modified', accessor: 'fellowships.MODIFIED', row: 5, col: 2, editable: false }
];

const fellowshipColumns = [
	{ Header: 'Block Grant', accessor: 'fellowships.BLOCK_GRANT_FLAG', type: 'checkbox', row: 0, col: 0 },
	{ Header: 'First Name', accessor: 'application.FNAME', row: 0, col: 1 },
	{ Header: 'Middle Name', accessor: 'application.MNAME', row: 0, col: 2 },
	{ Header: 'Last Name', accessor: 'application.LNAME', row: 0, col: 3 },
	{ Header: 'Student ID', accessor: 'students.STUDENT_CARD', row: 1, col: 0 },
	{ Header: 'Student Status', accessor: 'fellowships.STUDENT_STATUS', type: 'select', row: 1, col: 1,
		options: [{ value: null, label: 'Select...' },{ value: '1', label: 'New' },{ value: '0', label: 'Continuing' }]},
	{ Header: 'Citizenship', accessor: 'application.CITIZENSHIP', row: 1, col: 2 },
	{ Header: 'Visa Type', accessor: 'application.VISA', type: 'dropdown', row: 1, col: 3,
		options: { value: 'VISA_NAME', label: 'VISA_NAME', entity: 'ADMISSIONS.VISA', order: 'VISA_NAME' }},
	{ Header: 'CA Resident', accessor: 'students.CA_RESIDENT', type: 'checkbox', row: 2, col: 0 },
	{ Header: 'Res. Director', valueAccessor: 'research_director.PERSON_ID', accessor: 'research_director.PI_NAME', type: 'dropdown', row: 2, col: 1,
		options: { value: 'PERSON_ID', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.STUDENT_RES_DIR_SELECT_VW', order: ['SORT_ORDER','RES_DIRECTOR_NAME'],
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'research_director.PERSON_ID' }
	]}}},
	{ Header: 'Fellow Type', accessor: 'fellowships.FELLOW_TYPE', type: 'select', row: 2, col: 2,
		options: [{ value: null, label: 'Select...' },{ value: '1', label: 'Resident' },{ value: '0', label: 'Travel' }]},
	{ Header: 'Fee Remission', accessor: 'fellowships.FEE_REMISSION', type: 'select', row: 2, col: 3,
		options: [{ value: null, label: 'Select...' },{ value: 'F', label: 'Full' },{ value: 'P', label: 'Partial' },{ value: 'N', label: 'None' }]},
	{ Header: 'Start Date', accessor: 'fellowships.START_DATE', type: 'date', row: 3, col: 0 },
	{ Header: 'End Date', accessor: 'fellowships.END_DATE', type: 'date', row: 3, col: 1 },
	{ Header: 'Academic Year', accessor: 'application.ACADEMIC_YEAR', row: 3, col: 2 },
	{ Header: 'Fellowship Year', accessor: 'fellowships.FELLOWSHIP_YEAR', row: 3, col: 3 },
	{ Header: 'Fund', accessor: 'fellowships.FUND', row: 4, col: 0 },
	{ Header: 'Fund Number', accessor: 'fellowships.FUND_NUMBER', row: 4, col: 1 },
	{ Header: 'Form Comments', accessor: 'fellowships.FORM_COMMENTS', type: 'textarea', row: 4, col: 2 },
	{ Header: 'List Comments', accessor: 'fellowships.LIST_COMMENTS', type: 'textarea', row: 4, col: 3 },
	{ Header: 'Non Printing Notes', accessor: 'fellowships.PRIV_COMMENTS', type: 'textarea', row: 5, col: 0 },
	{ Header: 'Created', accessor: 'fellowships.CREATED', row: 5, col: 1, editable: false },
	{ Header: 'Modified', accessor: 'fellowships.MODIFIED', row: 5, col: 2, editable: false }
];

const summerFellowshipColumns = [
	{ Header: 'Summer', accessor: 'fellowships.summer[5].YEAR', row: 0, col: 0 },
	{ Header: 'Stipend', accessor: 'fellowships.summer[5].STIPEND', row: 0, col: 1 },
	{ Header: 'Fees', accessor: 'fellowships.summer[5].FEES', row: 0, col: 2 },
	{ Header: 'Summer', accessor: 'fellowships.summer[6].YEAR', row: 1, col: 0 },
	{ Header: 'Stipend', accessor: 'fellowships.summer[6].STIPEND', row: 1, col: 1 },
	{ Header: 'Fees', accessor: 'fellowships.summer[6].FEES', row: 1, col: 2 }
];

const fellowshipSummaryColumns = [
	{ Header: 'Carry Forward', accessor: 'fellowships.CARRY_FORWARD', row: 0, col: 0 },
	{ Header: 'Fellowship Year', accessor: 'current_year.YEAR', row: 0, col: 1, editable: false },
	{ Header: 'Estimated Income', accessor: 'fellowships.EST_INCOME', row: 1, col: 0 },
	{ Header: 'Fees', accessor: 'current_year.FEES', row: 1, col: 1, editable: false },
	{ Header: 'Actual Income', accessor: 'fellowships.ACT_INCOME', row: 2, col: 0 },
	{ Header: 'Tuition', accessor: 'current_year.TUITION', row: 2, col: 1, editable: false },
	{ Header: 'Stipend Income', accessor: 'fellowships.STIP_INCOME', row: 3, col: 0 },
	{ Header: 'Stipend', accessor: 'current_year.STIPEND', row: 3, col: 1, editable: false },
	{ Header: 'Total Funds Available', accessor: 'current_year.TOTAL_AVAIL', row: 4, col: 0, editable: false },
	{ Header: 'Total', accessor: 'current_year.TOTAL', row: 4, col: 1, editable: false },
	{ Header: 'Total Funds Spent', accessor: 'fellowships.TOTAL_SPENT', row: 5, col: 0 },
	{ Header: 'Balance', accessor: 'fellowships.BALANCE', row: 6, col: 0 },
	{ Header: 'Block Grant Appropriation', accessor: 'fellowships.BLOCK_GRANT', type: 'select', row: 6, col: 1,
		options: { value: 'AMOUNT', label: 'AMOUNT', entity: 'ADMISSIONS.BLOCK_GRANT', order: ['CURRENT_GRANT desc','ACADEMIC_YEAR']}}
];

export default withRouter(Fellowship);