import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class AddPerson extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			person: {
				PERSON_ID: -1,
			},
			role: {
				ROLE_ID: -1
			},
			renderKey: 0
		}
	}
	
	onChangePerson(accessor, value) {
		// copy current person state
		var person = this.copyObject(this.state.person);
		
		// set new value
		this.setByAccessor(person, accessor, value); 
		
		// incorporate into state
		this.mergeState({ person: person });
	}
		
	onChangeRole(accessor, value) {
		// copy current role state
		var role = this.copyObject(this.state.role);
		
		// set new value
		this.setByAccessor(role, accessor, value); 
		
		// incorporate into state
		this.mergeState({ role: role });
	}
	
	validatePerson() {
		return this.validate(personColumns, this.state.person, roleColumns, this.state.role);
	}

	onSubmit(event) {
		var self = this;
		
		// construct a Person object to send to server
		var person = {
			PeopleView: this.copyObject(this.state.person),
			Roles: [
				{
					RoleView: this.copyObject(this.state.role),
					WorkContacts: [
						{	
							BUILDING_ID: this.state.person.BUILDING,
							OUTSIDE_LOCATION: this.state.person.LOCATION,
							PERS_EMERG_CELL: this.state.person.PERSONAL_MOBILE_CONTACT,
							ROOM_ID: this.state.person.OFFICE_LOCATION,
							WORK_NO_EMAIL: this.state.person.EMAIL,
							WORK_NO_PHONE1: this.state.person.WORK_PHONE
						}
					]
				}
			],
			Cards: [
				{
					CARD_ID: this.state.person.CARD_ID,
					PROX_NUMBER: this.state.person.PROX_NUMBER
				}
			]
		};
	
		// copy speedtype to role
		person.Roles[0].RoleView.SPEEDTYPE = this.state.person.SPEEDTYPE;
				
		// translate flags to something oracle will understand
		person.PeopleView.ACTIVE_FLAG = 'Y';
		person.PeopleView.LECTURE_FLAG = person.PeopleView.LECTURE_FLAG ? 'Y' : null;
		person.PeopleView.SAFETY_MONITOR = person.PeopleView.SAFETY_MONITOR ? 'Y' : null;
		person.Roles[0].RoleView.CASUAL_FLAG = person.Roles[0].RoleView.CASUAL_FLAG ? 'Y' : null;
		
		this.ajax({
			type: 'POST',
			url: self.getConfig().host + '/BuildingAccess/InsertPerson',
			data: { __RequestVerificationToken: self.props.user.antiForgeryToken, person: person }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return(<>
						Record updated for <Link to={'/BuildingAccess/People/' + data.PersonID}>{person.PeopleView.FNAME + ' ' + person.PeopleView.LNAME}</Link>
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Add Person</div>
				  <ul>
					<li>Chemistry, Chemical Engineering Graduate Students, and Chemistry Postdocs are entered by the departments. Do not re-enter.</li>
					<li>Required entry for first appointment. More appointments can be entered upon submission.</li>
					<li><span style={{ color: 'red' }}>*</span> Denotes Required Field</li>
				  </ul>
				  <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>General Information</div>
				  <ChemEdit parent={this} columns={personColumns} data={this.state.person} user={this.props.user} renderKey={this.state.renderKey}
					 onChange={(accessor, value) => this.onChangePerson(accessor, value)}>
					 <Row style={{ borderTop: '1px solid #eee' }} />
				  </ChemEdit>
				  <div style={{ fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>Appointment Information</div>
				  <ChemEdit parent={this} columns={roleColumns} data={this.state.role} user={this.props.user} renderKey={this.state.renderKey}
					validate={() => this.validatePerson()} onChange={(accessor, value) => this.onChangeRole(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
				    <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={12}>
					    <Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					  </Col>
				    </Form.Group>
				  </ChemEdit>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const personColumns = [
	{ Header: 'Last Name', accessor: 'LNAME', required: true, row: 0, col: 0 },
	{ Header: 'First Name', accessor: 'FNAME', required: true, row: 0, col: 1 },
	{ Header: 'Cal UID', accessor: 'CAL_UID', required: true, row: 0, col: 2 },
	{ Header: 'Middle Name', accessor: 'MNAME', row: 1, col: 0 },
	{ Header: 'Preferred Name', accessor: 'PREFERRED_NAME', row: 1, col: 1 },
	{ Header: 'Cal1 Card ID', accessor: 'CARD_ID', row: 1, col: 2 },
	{ Header: 'Campus Email', accessor: 'EMAIL', required: true, row: 2, col: 0 },
	{ Header: 'Personal Mobile Contact', accessor: 'PERSONAL_MOBILE_CONTACT', row: 2, col: 1 },
	{ Header: 'Proximity Card Key', accessor: 'PROX_NUMBER', row: 2, col: 2 },
	{ Header: 'Department 1', accessor: 'DEPT_ID', required: true, type: 'select', row: 3, col: 0,
		options: { value: 'DEPARTMENT_ID', label: 'DEPT_NAME', entity: 'DEPARTMENT', order: [ 'DEPT_NAME' ] } },
	{ Header: 'Department 2', accessor: 'DEPT2_ID', type: 'select', row: 3, col: 1,
		options: { value: 'DEPARTMENT_ID', label: 'DEPT_NAME', entity: 'DEPARTMENT', order: [ 'DEPT_NAME' ] } },
	{ Header: 'Work Phone', accessor: 'OFFICE_PHONE', row: 3, col: 2 },
	{ Header: 'Date Started', accessor: 'START_DATE', type: 'date', row: 4, col: 0 },
	{ Header: 'Expected Departure<br><i>(for non career appt.)</i>', accessor: 'END_DATE', type: 'date', row: 4, col: 1 },
	{ Header: 'Floor Safety Monitor', accessor: 'SAFETY_MONITOR', type: 'checkbox', row: 4, col: 2 },	
	{ Header: 'Emergency Contact Name', accessor: 'EMERGENCY_CONTACT', required: true, row: 5, col: 0 },
	{ Header: 'Emergency Contact Phone', accessor: 'EMERGENCY_CONTACT_PH', required: true, row: 5, col: 1 },
	{ Header: 'Emergency Cell', accessor: 'EMER_CELL', row: 5, col: 2 },
	{ Header: 'Lab Emergency<br>Phone 1 <i>(for PI)</i>', accessor: 'PI_EMER_PHONE1', row: 6, col: 0 },
	{ Header: 'Lab Emergency<br>Phone 2 <i>(for PI)</i>', accessor: 'PI_EMER_PHONE2', row: 6, col: 1 },
	{ Header: 'Default Speedtype', accessor: 'SPEEDTYPE', row: 6, col: 2 },
	{ Header: "Safety Training Req'd", accessor: 'LECTURE_FLAG', type: 'checkbox', row: 7, col: 0 },
	{ Header: 'Date Completed', accessor: 'SAFETY_LECTURE', type: 'date', row: 7, col: 1 },
	{ Header: 'Chemistry Office Location', accessor: 'OFFICE_LOCATION', required: true, row: 7, col: 2, type: 'select',
		options: { value: 'ROOM_ID', label: 'LOCATION', entity: 'ROOMS_VW', order: [ 'LOCATION' ] } }, 
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 8, col: 0 },
	{ Header: 'Off Site Building', accessor: 'BUILDING', row: 8, col: 1, type: 'select', 
		options: { value: 'BUILDING_ID', label: 'BUILDING_NAME', entity: 'BUILDING', order: [ 'BUILDING_NAME' ],
			search: { Operator: 'and', children: [ { Attribute: 'CHEMISTRY_FLAG', Operator: 'is null' }, { Attribute: 'DELETE_FLAG', Operator: 'is null' } ] } } },
	{ Header: 'Off Site Location', accessor: 'LOCATION', row: 8, col: 2 }
];

const roleColumns = [
	{ Header: 'Start Date', accessor: 'START_DATE', type: 'date', row: 0, col: 0 },
	{ Header: 'Appt. Type', accessor: 'TYPE_ID', type: 'select', required: true, row: 0, col: 1,
		options: { value: 'TYPE_ID', label: 'TYPE_NAME', entity: 'SELECT_ROLE_TYPE_VW', order: 'TYPE_NAME' } },
	{ Header: 'PI', accessor: 'PI_ROLE_ID', type: 'select', required: true, row: 0, col: 2,
		options: { value: 'ROLE_ID', label: 'PI_NAME', entity: 'PI_VW', order: 'PI_NAME' }},
	{ Header: 'Unit Assigned To', accessor: 'UNIT_ID', type: 'select', row: 1, col: 0,
		options: { value: 'UNIT_ID', label: 'UNIT_NAME', entity: 'ORG_UNIT',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }, order: 'UNIT_NAME' } },
	{ Header: 'Work Title', accessor: 'WORK_TITLE', type: 'select', row: 1, col: 1,
		options: { value: 'WORK_TITLE_ID', label: 'WORK_TITLE_NAME', entity: 'WORK_TITLE',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }, order: 'WORK_TITLE_NAME' } },
	{ Header: 'Casual Appointment', accessor: 'CASUAL_FLAG', type: 'checkbox', row: 1, col: 2 }			
];

export default AddPerson;