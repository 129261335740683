import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';
import ChemTable from '../ChemTable';


class Errors extends ChemComponent {
  	constructor(props) {
		super(props);
		
		this.state = {
			renderKey: 1,
			errors: [],
			pageNumber: 1,
			pageSize: 100,
			pageCount: 1,
			movePerson: 0,
			moveSteps: 0,
			search: {
				NAME: '',
				EXCEPTION: '',
				DATE_FROM: '',
				DATE_TO: ''
			}
		};
	}

	componentDidMount() {
		this.loadErrors();
	}
	
	loadErrors(pageNumber, pageSize, search, event) {
		var self = this;
		
		if (!pageNumber) pageNumber = this.state.pageNumber;
		if (!pageSize) pageSize = this.state.pageSize;
		if (!search) search = [];
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Home/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'ERROR_LOG_VW',
				search: search,
				order: ['ERROR_DATE desc'],
				pageNumber: pageNumber,
				pageSize: pageSize
			}
		}).done(function (data) {
			if (data.Success) {
				// for each person
				for (var i = 0; i < data.Data.length; i++) {
					// add an id to the row
					data.Data[i].id = i;
					data.Data[i].drag = i;
				}
			
				self.mergeState({
					renderKey: self.state.renderKey + 1,
					errors: data.Data,
					pageNumber: data.PageNumber,
					pageCount: data.PageCount,
					pageSize: data.PageSize
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	moveRow(personId, nSteps) {
		this.mergeState({ movePerson: personId, moveSteps: this.state.moveSteps + nSteps });
	}
	
	dropRow() {
		var self = this;
		var movePerson = {
			personId: this.state.movePerson,
			nSteps: this.state.moveSteps
		};
		this.mergeState({ movePerson: 0, moveSteps: 0 }, () => {
			if (movePerson.nSteps !== 0) {
				this.ajax({
					type: 'post',
					url: this.getConfig().baseURL + 'django_movetransaction/',
					data: JSON.stringify(movePerson)
				}).done(function (data) {
					if (data.success) {
						self.loadErrors(self.state.pageNumber, self.state.pageSize);
					} else {
						self.showAlert('Move Application Error', data.message);
					}
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
	
	firstPage() {
		if (this.state.pageNumber !== 1) {
			this.loadErrors(1, this.state.pageSize);
		}
	}
	
	onFileChange(event) {
		var file = event.target.files[0];
		// reject files that are too large
		if (file.size > 10000000) file = null; 
		this.mergeState({ selectedFile: file });
	}
	
	previousPage() {
		if (this.state.pageNumber > 1) {
			this.loadErrors(this.state.pageNumber - 1, this.state.pageSize);
		}		
	}
	
	nextPage() {
		// server will correct if page number is too big
		this.loadErrors(this.state.pageNumber + 1, this.state.pageSize);		
	}
	
	lastPage() {
		// use -1 to indicate last page since we don't 
		// necessarily know how many pages there are now
		this.loadErrors(-1, this.state.pageSize);
	}
	
	setPageSize(pageSize) {
		if (pageSize !== this.state.pageSize) {
			this.loadErrors(this.state.pageNumber, pageSize);
		}
	}
	
	setPageNumber(pageNumber) {
		if (pageNumber !== this.state.pageNumber) {
			this.loadErrors(pageNumber, this.state.pageSize);
		}
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		// construct search terms
		var search = {
			Operator: 'and',
			children: []
		};
		
		var data = this.copyObject(this.state.search);

		if (!this.isEmpty(data.NAME)) search.children.push({ Attribute: 'NAME', Operator: 'like', StringValue: '%' + data.NAME + '%' });
		if (!this.isEmpty(data.EXCEPTION)) search.children.push({ Attribute: 'EXCEPTION', Operator: 'like', StringValue: '%' + data.EXCEPTION + '%' });
		if (!this.isEmpty(data.DATE_FROM)) search.children.push({ Attribute: 'ERROR_DATE', Operator: '>=', DateValue: this.dateTimeToMVC(this.getDate(data.DATE_FROM)) });
		if (!this.isEmpty(data.DATE_TO)) search.children.push({ Attribute: 'ERROR_DATE', Operator: '<=', DateValue: this.dateTimeToMVC(this.getDate(data.DATE_TO)) });
		
		// if nothing was selected, forget it
		if (search.children.length === 0) search = null;
			
		this.loadErrors(this.state.pageNumber, this.state.pageSize, search, event);
	}
	
	render() {
		
		const search = [
			{ Header: 'Name', accessor: 'NAME', row: 0, col: 0 },
			{ Header: 'Exception', accessor: 'EXCEPTION', row: 0, col: 1 },
			{ Header: 'From', accessor: 'DATE_FROM', row: 1, col: 0, type: 'datetime' },
			{ Header: 'To', accessor: 'DATE_TO', row: 1, col: 1, type: 'datetime' }
 		];

		const columns = [
			{ Header: 'Error ID', accessor: 'ERROR_ID', width: 75, Cell: (props) => <Link to={'/WebAdmin/Errors/' + props.value}>{props.value}</Link> },
			{ Header: 'Name', accessor: 'NAME', width: 150 },
			{ Header: 'Date', accessor: 'ERROR_DATE', width: 150, Cell: (props) => this.dateTimeToString(this.getDate(props.value)) },
			{ Header: 'Exception', accessor: 'EXCEPTION', width: 600, Cell: (props) => props.value.substring(0, 100) + '...' }
		];

		return (<>
			<Container fluid>
				<Row>
					<Col>
					  <Container>
					    <Row>
						  <Col xs={10} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
							<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Web Application Error Log</span>
					        <ChemEdit parent={this} columns={search} data={this.state.search} renderKey={this.state.renderKey} 
						      onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
							  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						        <Col xs={12}>
					              <Button id="update" variant="warning" type="submit" className="float-end">Update</Button>
								</Col>
							  </Form.Group>
					        </ChemEdit>
					      </Col>
						</Row>
					  </Container>
					</Col>
				</Row>
				<Row>
					<Col>
						<ChemTable renderKey={this.state.renderKey} parent={this} name='WebAdmin_Errors' columns={columns} data={this.state.errors} offsetHeight='330px'
						  pageNumber={this.state.pageNumber} pageSize={this.state.pageSize} pageCount={this.state.pageCount}/>
					</Col>
				</Row>
			</Container>
		</>);
	}
}

export default Errors;