import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row, Button, Spinner } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import QueryTable from '../QueryTable';

class Jobs extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var initialJobsList = this.copyTable(jobsList);
		initialJobsList.ajax.data = { service: props.params.serviceId, job: props.params.jobId };
		
		this.state = {
			jobsList: initialJobsList,
			jobCount: null,
			renderKey: 1,
		};
	}
	
	onJobsResult(data) {
		this.mergeState({
			jobCount: data.ResultCount
		});
	}

	deleteJobs(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/DeleteJobs',
			overlay: true,
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				service: this.props.params.serviceId,
				job: this.props.params.jobId
			}
		}).done(function (data) {
			if (data.Success) {
				self.showConfirmation('Deleted ' + data.RowsAffected + ' jobs for service ' + self.props.params.serviceId + ', job ID ' + self.props.params.jobId);
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}

	render() {
		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Jobs</span>
					<Container fluid>
					  <Row>
					    <Col xs={6} style={{ marginTop: 8}}>
						  {this.isEmpty(this.state.jobCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.jobCount} records found.
					    </Col>
					    <Col xs={6}>	
						  {!this.isEmpty(this.state.jobCount) && this.state.jobCount > 0 && <Button id="delete" variant="warning" type="submit" className="float-end" onClick={(event) => this.deleteJobs(event)}>Delete All</Button>}
					    </Col>
					  </Row>
					</Container>
				  </Col>
				</Row>
				<Row>
				  <Col>
					<QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_Jobs' table={this.state.jobsList} onResult={(data) => this.onJobsResult(data)} offsetHeight='240px' />
				  </Col>
				</Row>
			</Container>
		</>);
	}
}

const jobsList = {
	ajax: { url: '/CRS/GetJobs', accessor: 'Data' },
	pageSize: 20,
	columns: [
		{ Header: 'Job Number', accessor: 'JOBNO', width: 125 },
		{ Header: 'Cost Center', accessor: 'COST_CENTER', width: 125 },
		{ Header: 'Service Name', accessor: 'SERVICE_NAME', width: 250 },
		{ Header: 'User Name', accessor: 'USER_NAME', width: 150 },
		{ Header: 'Transaction Date', accessor: 'TRANS_DATE', width: 125,
			Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
		{ Header: 'Labor Hours', accessor: 'LABOR_HOURS', width: 125 },
		{ Header: 'Qty', accessor: 'QTY', width: 80 }
	]
};

export default withRouter(Jobs);