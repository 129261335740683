var env;

if (window.location.hostname === 'chemnet.berkeley.edu') {
    env = require('./production');
} else if (window.location.hostname === 'chemtest.berkeley.edu') {
	env = require('./test');
} else if (process.env.NODE_ENV === 'development') {
	env = require('./development');
}

module.exports = env;