import React from 'react';
import ChemComponent from './ChemComponent';
import PropTypes from 'prop-types';

class Tab extends ChemComponent {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
	padding: PropTypes.string
  };

  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  }

  render() {
    const {
      onClick,
      props: {
        activeTab,
        label,
		padding
      },
    } = this;

    var style = this.copyObject(activeTab === label ? styles.tabListActive : styles.tabListItem);
	if (!this.isEmpty(padding)) style.padding = padding;

    return (
      <li
        style={style}
        onClick={onClick}
      >
        {label}
      </li>
    );
  }
}

const styles = {
	tabListItem: {
		display: 'inline-block',
		listStyle: 'none',
		marginBottom: '-1px',
		padding: '0.5rem 0.75rem',
		backgroundImage: 'linear-gradient(rgb(237, 231, 0), rgb(253, 181, 21))',
		cursor: 'pointer',
		borderBottom: '1px solid #000',
		borderTop: '1px solid rgb(237, 231, 0)'
	},
	tabListActive: {
		display: 'inline-block',
		listStyle: 'none',
		marginBottom: '-1px',
		padding: '0.5rem 0.75rem',
		backgroundImage: 'linear-gradient(rgb(237, 231, 0), rgb(253, 181, 21))',
		cursor: 'pointer',
		borderBottom: '1px solid rgb(253, 181, 21)',
		borderLeft: '1px solid #000',
		borderRight: '1px solid #000',
		borderTop: '1px solid #000',
		fontWeight: 'bold'
	}
};

export default Tab;