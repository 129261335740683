import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import QueryTable from '../QueryTable';
import { PlusSquare, PencilSquare } from 'react-bootstrap-icons';

class ViceChairs extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			searchCount: 0,
			renderKey: 0
		};
	}

	addViceChair() {
		this.props.navigate('/BuildingAccess/People');
	}
	
	editViceChair(roleId) {
		this.props.navigate('/BuildingAccess/Roles/' + roleId);
	}

	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'ViceChairs',
			columns: exportColumns,
			entity: 'COLLEGE.VICE_CHAIRS_VW'
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Vice Chairs</span>
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='GradOffice_ViceChairs' table={viceChairList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='300px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const viceChairList = {
	query: { entity: 'COLLEGE.VICE_CHAIRS_VW' },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addViceChair()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editViceChair(props.row.values.ROLE_ID)} />, width: 25 },
		{ Header: '', accessor: 'ROLE_ID', show: false },
		{ Header: 'Last Name', accessor: 'LNAME', width: 100 },
		{ Header: 'First Name', accessor: 'FNAME', width: 100 },
		{ Header: 'Work Title', accessor: 'WORK_TITLE_NAME', width: 400 }
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'SCHEDULE_ID', width: 25 },
	{ Header: 'Course', accessor: 'COURSE_NAME', width: 25 },
	{ Header: 'Semester', accessor: 'SEMESTER', width: 25 },
	{ Header: 'Year', accessor: 'CAL_YEAR', width: 25 },
	{ Header: 'Instructor 1', accessor: 'FACULTY1', width: 25 },
	{ Header: 'Instructor 2', accessor: 'FACULTY2', width: 25 },
	{ Header: 'Outside Instructor', accessor: 'OTHER_FACULTY', width: 25 },
	{ Header: 'Module', accessor: 'MODULE', width: 25 },
	{ Header: 'GSI', accessor: 'GSI_LIST', width: 25, wraptext: true }
];

export default withRouter(ViceChairs);
