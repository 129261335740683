import ChemComponent from '../ChemComponent';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { withRouter } from '../withRouter';

class MyRecord extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			person: null,
			renderKey: 0,
			submitterID: ''
		}
	}

	componentDidMount() {
		this.loadPerson();
	}

	loadPerson(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/BuildingAccess/GetPersonByUID',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, caluid: this.props.user.uid }
		}).done(function (data) {
			if (data.Success) {
				var person = null;
				
				// if this is a brand new person
				if (data.People.length === 0) {
					// create a new request
					person = {
						NewRequest: {
							REQ_ID: -1,
							CAL_UID: self.props.user.uid,
							FNAME: self.props.user.firstName,
							LNAME: self.props.user.lastName,
							DEPT_ID: 10, // College of Chemistry
							END_APPT: null
						}
					};
				} else {
					person = data.People[0];

					// if this is an existing person
					if (person.PeopleView) {						
						// compose created, modified fields
						person.PeopleView.CREATE_BY = self.composeChangeInfo(person.PeopleView.CREATE_BY, person.PeopleView.CREATE_DATE);
						person.PeopleView.MODIF_BY = self.composeChangeInfo(person.PeopleView.MODIF_BY, person.PeopleView.MODIF_DATE);
					}

					// if there is a safety certificate
					if (person.SafetyTraining.length > 0) {
						// compose message for safety certificate
						person.SafetyTraining = "Certificate Uploaded " + self.dateToString(self.getDate(person.SafetyTraining[0].DATE_CREATED));
					} else {
						// remove empty array
						person.SafetyTraining = null;
					}
				}
				
				self.mergeState({
					person: person,
					renderKey: self.state.renderKey + 1
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
	
	onClickContinue() {
		this.mergeState({
			submitterID: 'continue'
		});
	}
	
	onClickSave() {
		this.mergeState({
			submitterID: 'save'
		});
	}
	
	copyPerson() {
		var person = this.copyObject(this.state.person);
		// reference to safety training certificate must be copied explicitly
		if (this.state.person.SafetyTraining !== undefined) {
			person.SafetyTraining = this.state.person.SafetyTraining;
		}
		return person;
	}
	
	onChange(accessor, value) {
		var person = this.copyPerson();
		this.setByAccessor(person, accessor, value);
		this.mergeState({ person: person });
	}
		
	onSubmit(event) {
		var self = this;
		var person = this.copyPerson();
				
		// get the file info from the person
		var safety = person.SafetyTraining;

		// set to empty array so marshaling will work
		person.SafetyTraining = [];
		
		// convert dates
		if (person.PeopleView && person.PeopleView.END_DATE) {
			person.PeopleView.END_DATE = this.dateTimeToMVC(this.getDate(person.PeopleView.END_DATE));
		} else if (person.NewRequest && person.NewRequest.END_APPT) {
			person.NewRequest.END_APPT = this.dateTimeToMVC(this.getDate(person.NewRequest.END_APPT));
		}
		
		// get submitter ID
		var submitterID;
		if (event && event.nativeEvent && event.nativeEvent.submitter) {
			submitterID = event.nativeEvent.submitter.id;
		} else {
			submitterID = this.state.submitterID;
		}
		
		// clear submitter ID from state
		this.mergeState({
			submitterID: ''
		}, () => {
			this.ajax({
				type: 'POST',
				url: self.getConfig().host + '/BuildingAccess/UpdatePerson',
				overlay: true,
				data: { __RequestVerificationToken: self.props.user.antiForgeryToken, person: person }
			}).done(function (data) {
				if (data.Success) {
					self.insertSafetyCertificate(safety, self.props.user.uid + ' ' + self.props.user.name, () => {
						if (submitterID === 'save') {
							self.props.parent.showConfirmation("Your record has been updated.");
						} else if (submitterID === 'continue') {
							self.props.navigate('/BuildingAccess/MyAppointments');
						}
					});
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		});
	}
	
	insertSafetyCertificate(safety, name, callback) {
		var self = this;
		
		// if there is a safety certificate
		if (safety && safety.name) {		
			// Create an object of formData
			const formData = new FormData();

			// add the token to the formData
			formData.append("__RequestVerificationToken", this.props.user.antiForgeryToken);
		
			// add the safety certificate
			formData.append("safety", safety, name);
			
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/BuildingAccess/InsertSafetyCertificate',
				data: formData,
				contentType: false,
				processData: false
			}).done(function (data) {
				if (data.Success) {
					if (callback) callback();
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		} else {
			// no certificate to upload
			if (callback) callback();
		}
	}
	
	composeGreeting() {
		if (this.state.person.NewRequest) {
			return 'Welcome to College of Chemistry ' + this.state.person.NewRequest.FNAME + '!';
		} else {
			return 'Welcome Back ' + this.state.person.PeopleView.FNAME + '!';
		}
	}

	shallowCopyColumns(columns) {
		var shallowCopy = [];
	
		for (var i = 0; i < columns.length; i++) {
			shallowCopy.push(this.mergeObject(columns[i], {}));
		}
		
		return shallowCopy;
	}
	
	renderSafetyTrainingCertificate(props) {
		if (this.state.person.SafetyTraining && this.state.person.SafetyTraining.name) {
			// user has selected a certificate but not uploaded it
			return (<Row>
				<Col sm={12}>{this.state.person.SafetyTraining.name}</Col>
			</Row>);
		} else {
			// user has already uploaded a certificate and can view it
			return (<Row>
				<Col sm={8}>{this.state.person.SafetyTraining}</Col>
				<Col sm={4}>
					<form action={this.getConfig().host + '/BuildingAccess/DownloadSafetyCertificate'} method="POST">
						<input type="hidden" name="__RequestVerificationToken" value={this.props.user.antiForgeryToken} />
						<input type="hidden" name="caluid" value={this.props.user.uid} />
						<input type="submit" value="View" />
					</form>
				</Col>
			</Row>);
		}
	}

	render() {
		if (this.state.person) {
			var columnsWithDept = this.shallowCopyColumns(this.state.person.PeopleView ? personColumns : requestColumns);
			for (var i = 0; i < columnsWithDept.length; i++) {
				if (!this.state.person.IsPI && (columnsWithDept[i].accessor === 'PeopleView.PI_EMER_PHONE1' || columnsWithDept[i].accessor === 'PeopleView.PI_EMER_PHONE2')) {
					// not a PI, don't need these columns
					columnsWithDept.splice(i--, 1);
				} else if (columnsWithDept[i].accessor === 'SafetyTraining' && columnsWithDept[i].type === 'file' && this.state.person.SafetyTraining) {
					// user already uploaded a safety certificate
					columnsWithDept.splice(i--, 1);
				} else if (columnsWithDept[i].accessor === 'SafetyTraining' && columnsWithDept[i].editable === false && !this.state.person.SafetyTraining) {
					// user has not uploaded a safety certificate yet, so remove static text
					columnsWithDept.splice(i--, 1);
				}
			}
			return (<>
				<Container fluid>
				  <Row>
					<Col xs={12} sm={11} md={10} lg={9} xl={8} style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
					  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{this.composeGreeting()}</span>
					  <ul>
						<li>Complete this form to update your record</li>
						<li>Required Field <span style={{ color: 'red' }}>*</span></li>
					  </ul>
					  <ChemEdit parent={this} columns={columnsWithDept} data={this.state.person} user={this.props.user} renderKey={this.state.renderKey}
						onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
						<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
							<Col xs={12}>
							  <Button id="continue" variant="warning" type="submit" className="float-end" onClick={() => this.onClickContinue()}>Continue</Button>
							  {!this.state.person.NewRequest &&
								<Button id="save" variant="warning" type="submit" className="float-end" onClick={() => this.onClickSave()} style={{ marginRight: '20px' }}>Save</Button>
							  }
							</Col>
						</Form.Group>
					  </ChemEdit>
					</Col>
				  </Row>
				</Container>
			</>);
		} else {
			return null;
		}
	}
}

const personColumns = [
	{ Header: 'Last Name', accessor: 'PeopleView.LNAME', required: true },
	{ Header: 'First Name', accessor: 'PeopleView.FNAME', required: true },
	{ Header: 'Middle Name', accessor: 'PeopleView.MNAME' },
	{ Header: 'Preferred Name', accessor: 'PeopleView.PREFERRED_NAME' },
	{ Header: 'Primary Department', accessor: 'PeopleView.DEPT_ID', type: 'select', required: true,
		options: { value: 'DEPARTMENT_ID', label: 'DEPT_NAME', entity: 'DEPARTMENT', order: [ 'DEPT_NAME' ] } },
	{ Header: 'Secondary Department', accessor: 'PeopleView.DEPT2_ID', type: 'select',
		options: { value: 'DEPARTMENT_ID', label: 'DEPT_NAME', entity: 'DEPARTMENT', order: [ 'DEPT_NAME' ] } },
	{ Header: 'Non-Career Appointment End Date', accessor: 'PeopleView.END_DATE', type: 'date' },
	{ Header: 'Campus Email', accessor: 'Roles[0].WorkContacts[0].WORK_NO_EMAIL', required: true },
	{ Header: 'Secondary Email', accessor: 'Addresses[0].LOCAL_EMAIL' },
	{ Header: 'Cell Phone', accessor: 'PeopleView.EMER_CELL' },
	{ Header: 'Home Phone', accessor: 'Addresses[0].LOCAL_PHONE1' },
	{ Header: 'Home Address 1', accessor: 'Addresses[0].LOCAL_STREET1', required: true },
	{ Header: 'Home Address 2', accessor: 'Addresses[0].LOCAL_STREET2' },
	{ Header: 'City', accessor: 'Addresses[0].LOCAL_CITY', required: true },
	{ Header: 'Zip', accessor: 'Addresses[0].LOCAL_ZIP', required: true },
	{ Header: 'Emergency Contact Name', accessor: 'PeopleView.EMERGENCY_CONTACT', required: true },
	{ Header: 'Emergency Contact Phone', accessor: 'PeopleView.EMERGENCY_CONTACT_PH', required: true },
	{ Header: 'PI Lab Emergency Phone 1', accessor: 'PeopleView.PI_EMER_PHONE1' },
	{ Header: 'PI Lab Emergency Phone 2', accessor: 'PeopleView.PI_EMER_PHONE2' },
	{ Header: 'Cal1 Card ID', accessor: 'Cards[0].CARD_ID', required: true },
	{ Header: 'Cal1 Card Proximity Number<br /><span style="font-size: 12px; font-style: italic">(Required for after hours building access)</span>', 
		accessor: 'Cards[0].PROX_NUMBER', subscript: '<span style="font-size: 12px; font-style: italic">Enter first series of 5-6 digits on back of Cal1 Card bottom right corner</span>' },
	{ Header: 'Safety Training Certificate Upload', accessor: 'SafetyTraining', type: 'file', accept: 'application/pdf',
        subscript: '<br /><span style="font-size: 12px; font-style: italic">File Accepted: .pdf</span>' },
	{ Header: 'Safety Training Certificate Upload', accessor: 'SafetyTraining', editable: false,
		Cell: props => props.parent.renderSafetyTrainingCertificate(props) },
	{ Header: 'Record Create Date', accessor: 'PeopleView.CREATE_BY', editable: false },
	{ Header: 'Last Modified Date', accessor: 'PeopleView.MODIF_BY', editable: false }
];

const requestColumns = [
	{ Header: 'Last Name', accessor: 'NewRequest.LNAME', required: true },
	{ Header: 'First Name', accessor: 'NewRequest.FNAME', required: true },
	{ Header: 'Middle Name', accessor: 'NewRequest.MNAME' },
	{ Header: 'Preferred Name', accessor: 'NewRequest.PREF_NAME' },
	{ Header: 'Department', accessor: 'NewRequest.DEPT_ID', type: 'select', required: true,
		options: { value: 'DEPARTMENT_ID', label: 'DEPT_NAME', entity: 'DEPARTMENT', order: [ 'DEPT_NAME' ] } },
	{ Header: 'Non-Career Appointment End Date', accessor: 'NewRequest.END_APPT', type: 'date' },
	{ Header: 'Campus Email', accessor: 'NewRequest.CAMPUS_EMAIL', required: true },
	{ Header: 'Secondary Email', accessor: 'NewRequest.OTHER_EMAIL' },
	{ Header: 'Home Address 1', accessor: 'NewRequest.H_STREET1', required: true },
	{ Header: 'Home Address 2', accessor: 'NewRequest.H_STREET2' },
	{ Header: 'City', accessor: 'NewRequest.H_CITY', required: true },
	{ Header: 'Zip', accessor: 'NewRequest.H_ZIP', required: true },
	{ Header: 'Home Phone', accessor: 'NewRequest.H_PHONE' },
	{ Header: 'Cell Phone', accessor: 'NewRequest.CELL_PHONE' },
	{ Header: 'Emergency Contact Name', accessor: 'NewRequest.EMERGENCY_PERSON', required: true },
	{ Header: 'Emergency Contact Phone', accessor: 'NewRequest.EMERGENCY_CONTACT', required: true },
	{ Header: 'Cal1 Card ID', accessor: 'NewRequest.CARD_ID', required: true,
		subscript: '<span style="font-size: 12px; font-style: italic">Enter Employee ID if you do not have a Cal1 Card</span>'	},
	{ Header: 'Proximity Number<br /><span style="font-size: 12px; font-style: italic">(Required for after hours building access)</span>', 
		accessor: 'NewRequest.PROX_NUM', subscript: '<span style="font-size: 12px; font-style: italic">Enter first series of 5-6 digits on back of Cal1 Card bottom right corner</span>' },
	{ Header: 'Lab Emergency Phone 1<br /><span style="font-size: 12px; font-style: italic">(For PI Only)</span>', accessor: 'NewRequest.EMER_PHONE1' },
	{ Header: 'Lab Emergency Phone 2<br /><span style="font-size: 12px; font-style: italic">(For PI Only)</span>', accessor: 'NewRequest.EMER_PHONE2' },
	{ Header: 'Safety Training Certificate Upload', accessor: 'SafetyTraining', type: 'file', accept: 'application/pdf',
        subscript: '<br /><span style="font-size: 12px; font-style: italic">File Accepted: .pdf</span>' },
	{ Header: 'Safety Training Certificate Upload', accessor: 'SafetyTraining', editable: false,
		Cell: props => props.parent.renderSafetyTrainingCertificate(props) },
	{ Header: 'Comments for Supervisor/Delegate/PI<br /><span style="font-size: 12px; font-style: italic">250 Characters Maximum</span>', accessor: 'NewRequest.COMMENTS', type: 'textarea' },
];

export default withRouter(MyRecord);