import ChemComponent from './ChemComponent';

class ChemListBox extends ChemComponent {

	render() {
		var box = JSON.parse(JSON.stringify(style.box));
		if (this.props.float) box.float = this.props.float;
		
		return (
			<div style={box}>
				<h3 style={{ fontSize: '1.17em', fontWeight: 'bold', marginTop: 16, marginBottom: 18, fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif' }}>
					&nbsp;&nbsp;&nbsp;{this.props.title}
				</h3>
				<table style={{fontSize: 'medium', lineHeight: 'normal'}}>
					<tbody>
						{this.props.listItems.map((listItem, index) => {
							if (listItem.href.indexOf('///') === listItem.href.length - 3) {
								return (<tr key={index}>
									<td style={style.appDetail}><i>{listItem.text}</i></td>
									<td style={style.appDetail}><i>{listItem.comments}</i></td>
								</tr>);							
							} else {
								return (<tr key={index}>
									<td style={style.appDetail}><a href={listItem.href} target={listItem.target} rel={listItem.rel}>{listItem.text}</a></td>
									<td style={style.appDetail}>{listItem.comments}</td>
								</tr>);
							}
						})}
					</tbody>
				</table>
				<br /><br />
			</div>
		);
	}
}

const style = {
	appDetail: {
		fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
		paddingTop: 12,
		borderBottom: '1px solid #d4d4d4',
		padding: '10px 20px',
		verticalAlign: 'top'	
	},
	box: {
		border: '1px solid #ccc',
		width: '80%',
		marginRight: '0px',
		marginLeft: '0px',
		backgroundColor: '#FEFEFE'		
	}
};

export default ChemListBox;
