import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';

class CylHistory extends ChemComponent {
	constructor(props) {
		super(props);
		
		var today = this.midnight(new Date());
		var thirtyDaysAgo = new Date(today);
		thirtyDaysAgo.setDate(today.getDate() - 30);

		var search = {
		};
		
		var cylHistoryListInit = this.copyTable(cylHistoryList);
		cylHistoryListInit.query.search = { Attribute: 'CYL_ID', Operator: '=', LongValue: props.params.cylinderId };

		this.state = {
			cylHistoryList: cylHistoryListInit,
			search: search,
			searchCount: null,
			renderKey: 0
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newCylHistoryList = this.copyTable(cylHistoryList);
		newCylHistoryList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			cylHistoryList: newCylHistoryList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}

	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Cylinder History',
			columns: exportColumns,
			entity: 'DEMURRAGE.CYL_TRANS_HISTORY_VW',
			search: { Attribute: 'CYL_ID', Operator: '=', LongValue: this.props.params.cylinderId },
			order: 'TRANS_ID desc'
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Demurrage Transactions</span>
						<ChemEdit parent={this} user={this.props.user} columns={cylHistorySearch} data={this.state.search}
						  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='HeLAD_CylHistorys' table={this.state.cylHistoryList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='185px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const cylHistorySearch = [
];

const cylHistoryList = {
	query: { entity: 'DEMURRAGE.CYL_TRANS_HISTORY_VW', search: null, order: 'TRANS_ID desc' },
	pageSize: 20,
	columns: [
		{ Header: 'Cyl ID', accessor: 'CYL_ID', width: 80 },
		{ Header: 'Gas', accessor: 'GAS_NAME', width: 100, },
		{ Header: 'Owner', accessor: 'OWNER_NAME', width: 125, },
		{ Header: 'Speedtype', accessor: 'COST_CENTER', width: 125 },
		{ Header: '$ Gas', accessor: 'LINE_TOTAL', width: 100 },
		{ Header: 'Customer Num', accessor: 'REC_NUM_IN', width: 125 },
		{ Header: 'IOC Num', accessor: 'IOC_NUM_IN', width: 125 },
		{ Header: 'Tag Num In', accessor: 'TAG_NUM_IN', width: 125 },
		{ Header: 'Tag Num Out', accessor: 'TAG_NUM_OUT', width: 200 },
		{ Header: 'Cycle ID', accessor: 'CYCLE_ID', width: 125 },
		{ Header: 'Received Date', accessor: 'DATE_IN_COLLEGE', width: 125 },
		{ Header: 'Checkout User', accessor: 'OUT_USER_NAME', width: 150 },
		{ Header: 'Checkout Date', accessor: 'OUT_DATE', width: 125 },
		{ Header: 'Rental Rate', accessor: 'RENTAL_RATE', width: 125 },
		{ Header: 'Return User', accessor: 'RETURN_USER_NAME', width: 150 },
		{ Header: 'Return Date', accessor: 'RETURN_DATE', width: 125 },
		{ Header: 'Return Manifest', accessor: 'DATE_OUT_COLLEGE', width: 125 },
		{ Header: 'Remarks', accessor: 'REMARK', width: 300 },
		{ Header: 'Modified', accessor: 'MODIFIED', width: 300 }
	]
};

const exportColumns = [
	{ Header: 'Cyl ID', accessor: 'CYL_ID', width: 16 },
	{ Header: 'Gas', accessor: 'GAS_NAME', width: 20, },
	{ Header: 'Owner', accessor: 'OWNER_NAME', width: 25, },
	{ Header: 'Speedtype', accessor: 'COST_CENTER', width: 25 },
	{ Header: '$ Gas', accessor: 'LINE_TOTAL', width: 20 },
	{ Header: 'Customer Num', accessor: 'REC_NUM_IN', width: 25 },
	{ Header: 'IOC Num', accessor: 'IOC_NUM_IN', width: 25 },
	{ Header: 'Tag Num In', accessor: 'TAG_NUM_IN', width: 25 },
	{ Header: 'Tag Num Out', accessor: 'TAG_NUM_OUT', width: 40 },
	{ Header: 'Cycle ID', accessor: 'CYCLE_ID', width: 25 },
	{ Header: 'Received Date', accessor: 'DATE_IN_COLLEGE', width: 25 },
	{ Header: 'Checkout User', accessor: 'OUT_USER_NAME', width: 30 },
	{ Header: 'Checkout Date', accessor: 'OUT_DATE', width: 25 },
	{ Header: 'Rental Rate', accessor: 'RENTAL_RATE', width: 25 },
	{ Header: 'Return User', accessor: 'RETURN_USER_NAME', width: 30 },
	{ Header: 'Return Date', accessor: 'RETURN_DATE', width: 25 },
	{ Header: 'Return Manifest', accessor: 'DATE_OUT_COLLEGE', width: 25 },
	{ Header: 'Remarks', accessor: 'REMARK', width: 60 },
	{ Header: 'Modified', accessor: 'MODIFIED', width: 60 }
];

export default withRouter(CylHistory);
