import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import ReceiveDetail from './ReceiveDetail';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class Receive extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			editable: props.editable,
			po: {
				purchase_order: {
					PO_ID: ''
				},
				po_details: [],
				po_vendor: {}
			},
			emptyPODetail: this.copyObject(emptyPODetail),
			editorsOpen: [],
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadPO();
	}

	loadPO(idx) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Stores/GetPO',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, poid: this.props.params.poId }
		}).done(function (data) {
			if (data.Success) {
				var po = data.Data;
				self.mvc2js(po.purchase_order, poColumns);
				self.mvc2js(po.po_details, detailEditColumns);
				po.purchase_order.ENTERED = self.composeChangeInfo(po.purchase_order.CREATED_BY, po.purchase_order.DATE_CREATED);
				po.purchase_order.MODIFIED = self.composeChangeInfo(po.purchase_order.MODIF_BY, po.purchase_order.DATE_MODIF);

				po.po_vendor.CITY_STATE_ZIP = po.po_vendor.CITY + ' ' + po.po_vendor.STATE + ' ' + po.po_vendor.ZIP;
				po.po_vendor.PHONE = po.po_vendor.PHONE1 + ' ' + po.po_vendor.PHONE2;
				
				// add some PO info to each line so they can be submitted individually
				// initialize fields that only exist in ReceiveDetail
				var newEmptyPODetail = self.copyObject(emptyPODetail);
				newEmptyPODetail.VENDOR_ID = po.purchase_order.VENDOR_ID;
				newEmptyPODetail.NEW_PRICE = false;
				newEmptyPODetail.PO_NUMBER = po.purchase_order.PO_NUMBER;
				newEmptyPODetail.VENDORNAME = po.po_vendor.VENDORNAME;
				newEmptyPODetail.DATE_PLACED = po.purchase_order.DATE_PLACED;
				newEmptyPODetail.NEW_REC_QTY = '';
				newEmptyPODetail.ADD_REC_QTY = '';
				newEmptyPODetail.REC_ALL = false;
				for (var lineIdx = 0; lineIdx < po.po_details.length; lineIdx++) {
					po.po_details[lineIdx].VENDOR_ID = po.purchase_order.VENDOR_ID;
					po.po_details[lineIdx].NEW_PRICE = false;
					po.po_details[lineIdx].PO_NUMBER = po.purchase_order.PO_NUMBER;
					po.po_details[lineIdx].VENDORNAME = po.po_vendor.VENDORNAME;
					po.po_details[lineIdx].DATE_PLACED = po.purchase_order.DATE_PLACED;
					po.po_details[lineIdx].NEW_REC_QTY = '';
					po.po_details[lineIdx].ADD_REC_QTY = '';
					po.po_details[lineIdx].REC_ALL = false;
					
					// if subtotal is missing, calculate it
					if (!self.isEmpty(po.po_details[lineIdx].ORD_QTY) && !self.isEmpty(po.po_details[lineIdx].UNIT_PRICE)
						&& self.isEmpty(po.po_details[lineIdx].SUB_TOTAL)) {
						po.po_details[lineIdx].SUB_TOTAL = po.po_details[lineIdx].ORD_QTY * po.po_details[lineIdx].UNIT_PRICE;
					}
				}
				
				if (!self.isEmpty(idx)) {
					// we are only reloading one row of the data
					for (var i = 0; i < self.state.po.po_details.length; i++) {
						// if this is not the row we are reloading
						if (i !== idx) {
							// copy the existing row into the newly reloaded PO
							po.po_details[i] = self.copyObject(self.state.po.po_details[i]);
						}
					}
				}

				self.mergeState({
					po: po,
					emptyPODetail: newEmptyPODetail,
					renderKey: self.state.renderKey + 1
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
		
	onEdit(accessor, editorIsOpen) {
		this.mergeState({
			editorsOpen: this.setEditorStatus(accessor, editorIsOpen),
			renderKey: this.state.renderKey + 1
		});
	}
	
	setEditorStatus(accessor, editorIsOpen) {
		var editorsOpen = this.copyObject(this.state.editorsOpen);
		if (editorIsOpen) {
			// add to list if it isn't already present
			if (!editorsOpen.includes(accessor)) editorsOpen.push(accessor);
		} else {			
			// remove from list if it is present
			if (editorsOpen.includes(accessor)) editorsOpen.splice(editorsOpen.indexOf(accessor), 1);
		}
		return editorsOpen;
	}		

	onChange(accessor, value) {
		var self = this;
		var newState = {
			po: this.copyObject(this.state.po),
			renderKey: this.state.renderKey + 1
		};
		this.setByAccessor(newState.po, accessor, value);
		if (accessor === 'po_details') newState.editorsOpen = this.setEditorStatus(accessor, false);
		this.mergeState(newState, () => {
			if (accessor === 'purchase_order.VENDOR_ID') {
				// update line item detail
				var newEmptyPODetail = self.copyObject(emptyPODetail);
				newEmptyPODetail.VENDOR_ID = value;
				var po_details = self.copyObject(self.state.po.po_details);
				for (var detailIdx = 0; detailIdx < po_details.length; detailIdx++) {
					po_details[detailIdx].VENDOR_ID = value;
				}
				
				// update vendor information
				if (!self.isEmpty(value)) {
					// look up remaining vendor information
					self.ajax({
						type: 'post',
						url: self.getConfig().host + '/Stores/Search',
						data: { 
							__RequestVerificationToken: this.props.user.antiForgeryToken,
							entity: 'PO_VENDOR',
							search: { Attribute: 'VENDOR_ID', Operator: '=', LongValue: value },
							pageNumber: -1,
							pageSize: -1
						}
					}).done(function (data) {
						if (data.Success) {
							if (data.Data.length > 0) {
								var po_vendor = data.Data[0];
								po_vendor.CITY_STATE_ZIP = po_vendor.CITY + ' ' + po_vendor.STATE + ' ' + po_vendor.ZIP;
								po_vendor.PHONE = po_vendor.PHONE1 + ' ' + po_vendor.PHONE2;
								
								self.mergeState({
									po: {
										po_vendor: po_vendor,
										po_details: po_details
									},
									emptyPODetail: newEmptyPODetail
								});
							} else {
								self.mergeState({
									po: {
										po_vendor: {
											VENDORNAME: '',
											ADDRESS: '',
											CITY_STATE_ZIP: '',
											PHONE: '',
											CONTACT: ''
										},
										po_details: po_details
									},
									emptyPODetail: newEmptyPODetail
								});	
							}
						} else {
							self.showAlert('Server Error', data.Message);
						}			
					}).fail(function (jqXHR, textStatus, errorThrown) {
						self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
					});
				}
				else 	
				{
					self.mergeState({
						po: {
							po_vendor: {
								VENDORNAME: '',
								ADDRESS: '',
								CITY_STATE_ZIP: '',
								PHONE: '',
								CONTACT: ''
							},
							po_details: po_details
						},
						emptyPODetail: newEmptyPODetail
					});
				}
			}
		});
	}
	
	onChangePODetail(po_detail, accessor, value) {
		if (!Array.isArray(accessor) && ['NEW_REC_QTY','ADD_REC_QTY','REC_ALL'].includes(accessor)) {
			// get all values for calculations and convert to numeric or use default
			var ordQty = this.getNumeric(accessor === 'ORD_QTY' ? value : this.getByAccessor(po_detail, 'ORD_QTY'), 0);
			var recQty = this.getNumeric(accessor === 'REC_QTY' ? value : this.getByAccessor(po_detail, 'REC_QTY'), 0);
			var newRecQty = this.getNumeric(accessor === 'NEW_REC_QTY' ? value : this.getByAccessor(po_detail, 'NEW_REC_QTY'), 0);
			var addRecQty = this.getNumeric(accessor === 'ADD_REC_QTY' ? value : this.getByAccessor(po_detail, 'ADD_REC_QTY'), 0);
			var recAll = this.getBoolean(accessor === 'REC_ALL' ? value : this.getByAccessor(po_detail, 'REC_ALL'), false);
			
			var onOrder;
			if (accessor === 'REC_ALL' && recAll) {
				newRecQty = ordQty;
				this.setByAccessor(po_detail, 'NEW_REC_QTY', newRecQty);	
				onOrder = 0;
			} else {
				onOrder = Math.max(ordQty - (recQty === 0 ? newRecQty : (recQty + addRecQty)), 0);  
			}
			this.setByAccessor(po_detail, 'ON_ORDER', onOrder);
			
			// Set receive date to today
			this.setByAccessor(po_detail, 'DATE_RECEIVED', this.dateTimeToMVC(new Date()));
		}
	}
		
	onSubmit(event) {
		var self = this;		
		
		if (this.state.editorsOpen.length > 0) {
			this.showAlert('Editor Open', 'Please close the line item editor before saving.');
		} else {
			// make copy of data
			var dtNow = new Date();
			var po = this.copyObject(this.state.po);
			this.js2mvc(po.purchase_order, poColumns);
			this.js2mvc(po.po_details, detailEditColumns);
			
			if (this.props.params.poId) {
				// convert dates
				po.purchase_order.DATE_CREATED = this.dateTimeToMVC(this.getDate(po.purchase_order.DATE_CREATED));
				po.purchase_order.DATE_PLACED = this.dateTimeToMVC(this.getDate(po.purchase_order.DATE_PLACED));
			} else {
				po.purchase_order.DATE_CREATED = this.dateTimeToMVC(dtNow);
				po.purchase_order.DATE_PLACED = this.dateTimeToMVC(dtNow);			
			}
			
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/Stores/ReceivePO',
				data: { __RequestVerificationToken: this.props.user.antiForgeryToken, po: po }
			}).done(function (data) {
				if (data.Success) {
					var poId = self.props.params.poId || data.ID;
					var verb = self.props.params.poId ? 'updated' : 'inserted';
					self.props.parent.showConfirmation(() => {
						return (<>
							PO <Link to={'/Stores/POs/' + poId}>{poId}</Link> {verb}.
						</>);
					});
				} else {
					self.showAlert('Server Error', data.Message);
				}
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		}
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Receive Purchase Order {this.state.po.purchase_order.PO_ID}&nbsp;&nbsp;
							{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
							  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
						  </div>
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={poColumns} data={this.state.po} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)} editable={this.state.editable} />
					  </Col>
					</Row>
					<Row>
					  <Col style={{ paddingBottom: '10px' }}>
						<h6>Line Items</h6>
						<ReceiveDetail parent={this} user={this.props.user} renderKey={this.state.renderKey} newRecord={this.state.emptyPODetail} data={this.state.po.po_details}
						  tableColumns={detailTableColumns} editColumns={detailEditColumns} onEdit={(editorIsOpen) => this.onEdit('po_details', editorIsOpen)}
						  onChange={(data) => this.onChange('po_details', data)} tableName='Stores_PO_Detail' editable={this.state.editable}
						  onChangeInternal={(data, accessor, value) => this.onChangePODetail(data, accessor, value)} ReloadPO={(idx) => this.loadPO(idx)} />
					  </Col>
					</Row>
					<Row>
					  <Col>
						{this.state.editable && <Button id="save" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmit(event)}>Receive</Button>}
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const poColumns = [
	{ Header: 'Vendor', accessor: 'purchase_order.VENDOR_ID', type: 'select', row: 0, col: 0, required: true, editable: false,
		options: { value: 'VENDOR_ID', label: 'VENDORNAME', entity: 'STORES.PO_VENDOR', order: 'VENDORNAME' }},
	{ Header: 'Bear Buy PO #', accessor: 'purchase_order.PO_NUMBER', row: 0, col: 1, editable: false },
	{ Header: 'Vendor Addr', accessor: 'po_vendor.ADDRESS', row: 1, col: 0, editable: false }, 
	{ Header: 'Requisition/Cart ID', accessor: 'purchase_order.CART_ID', row: 1, col: 1, editable: false },
	{ Header: 'Vendor City/State', accessor: 'po_vendor.CITY_STATE_ZIP', row: 2, col: 0, editable: false },
	{ Header: 'Ref #', accessor: 'purchase_order.REFERENCE', row: 2, col: 1, editable: false },
	{ Header: 'Vendor Phone', accessor: 'po_vendor.PHONE', row: 3, col: 0, editable: false },
	{ Header: 'Date Initialized', accessor: 'purchase_order.DATE_PLACED', type: 'date', row: 3, col: 1, editable: false },
	{ Header: 'Contact', accessor: 'po_vendor.CONTACT', row: 4, col: 0, editable: false },
	{ Header: 'Order Status', accessor: 'purchase_order.STATUS_ID', type: 'select', row: 4, col: 1, editable: false,
		options: { value: 'STATUS_ID', label: 'STATUS_NAME', entity: 'STORES.PO_STATUS',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Last Action', accessor: 'purchase_order.LAST_ACTION', row: 5, col: 0, editable: false },
	{ Header: 'Blanket Order', accessor: 'purchase_order.BLANKET_FLAG', type: 'checkbox', row: 5, col: 1, editable: false },
	{ Header: 'Entered', accessor: 'purchase_order.ENTERED', row: 6, col: 0, editable: false }, 
	{ Header: 'Modified', accessor: 'purchase_order.MODIFIED', row: 6, col: 1, editable: false },
	{ Header: 'PO Comments', accessor: 'purchase_order.COMMENTS', type: 'textarea', row: 7, col: 0, editable: false },
	{ Header: 'Receiving Sheet', accessor: 'purchase_order.RECEIVING_SHEET', row: 7, col: 1, editable: false }
];

const emptyPODetail = {
	PO_ID: null,
	LINE_ID: null,
	PO_NUMBER: '',
	STOCK_NUMBER: '',
	CAT_NUMBER: '',
	ORD_QTY: null,
	REC_QTY: null,
	BACK_ORDER: null,
	UNIT_SIZE: null,
	UNIT_PRICE: null,
	TAX: null,
	SHIPPING: null,
	HANDLING: null,
	LINE_COMMENT: '',
	LINE_STATUS: 1,
	DATE_RECEIVED: null,
	DATE_MODIF: null,
	MODIF_BY: '',
	LAST_ACTION: '',
	LINE_TOTAL: null,
	SUB_TOTAL: null,
	CART_LINE_ID: '',
	ORD_DATE: null,
	INTER_REF: '',
	ITEM_ID: null,
	CALC_PRICE: null,
	ON_ORDER: null,
	TOTAL: null,
	DESCRIPTION: '',
	UNIT_COST: null,
	VENDOR_ID: null
}

const detailTableColumns = [
	{ Header: '', accessor: 'ITEM_ID', show: false },
	{ Header: 'Line', accessor: 'LINE_ID', width: 50 },
	{ Header: 'Stock Number', accessor: 'STOCK_NUMBER', width: 110,
		Cell: props => <Link to={'/Stores/Catalog/' + props.row.values.ITEM_ID}>{props.value}</Link> },	
	{ Header: 'Ord Date', accessor: 'ORD_DATE', width: 80,
		Cell: props => props.parent.dateToString(props.value) },
	{ Header: 'Ord Qty', accessor: 'ORD_QTY', width: 70,
		Cell: props => <div style={{ float: 'right', marginRight: 6, marginBottom: -6 }}>{props.value}</div> },
	{ Header: 'Catalog Number', accessor: 'CAT_NUMBER', width: 120 },
	{ Header: 'Description', accessor: 'DESCRIPTION', width: 300 },
	{ Header: "Rec'd Qty", accessor: 'REC_QTY', width: 80,
		Cell: props => <div style={{ float: 'right', marginRight: 6, marginBottom: -6 }}>{props.value}</div> },
	{ Header: "Date Rec'd", accessor: 'DATE_RECEIVED', width: 80,
		Cell: props => props.parent.dateToString(props.value) },
	{ Header: 'Qty on Order', accessor: 'ON_ORDER', width: 70,
		Cell: props => <div style={{ float: 'right', marginRight: 6, marginBottom: -6 }}>{props.value}</div> },
	{ Header: 'Unit Size', accessor: 'UNIT_SIZE', width: 70 },
	{ Header: 'Unit Price', accessor: 'UNIT_PRICE', width: 80,
		Cell: props => <div style={{ float: 'right', marginRight: 6, marginBottom: -6 }}>{props.parent.formatCurrency(props.value)}</div> },
	{ Header: 'Sub Total', accessor: 'SUB_TOTAL', width: 80,
		Cell: props => <div style={{ float: 'right', marginRight: 6, marginBottom: -6 }}>{props.parent.formatCurrency(props.value)}</div> },
	{ Header: 'Sub Total +Tax', accessor: 'TAX', width: 80,
		Cell: props => <div style={{ float: 'right', marginRight: 6, marginBottom: -6 }}>{props.parent.formatCurrency(props.value)}</div> },
	{ Header: 'Current Stores Pricing', accessor: 'UNIT_COST', width: 120,
		Cell: props => <div style={{ float: 'right', marginRight: 6, marginBottom: -6 }}>{props.parent.formatCurrency(props.value)}</div> },
	{ Header: 'Vendor Price +Tax +Markup', accessor: 'CALC_PRICE', width: 120,
		Cell: props => <div style={{ float: 'right', marginRight: 6, marginBottom: -6 }}>{props.parent.formatCurrency(props.value)}</div> },
	{ Header: 'Shipping', accessor: 'SHIPPING', width: 90,
		Cell: props => <div style={{ float: 'right', marginRight: 6, marginBottom: -6 }}>{props.parent.formatCurrency(props.value)}</div> },
	{ Header: 'Handling', accessor: 'HANDLING', width: 90,
		Cell: props => <div style={{ float: 'right', marginRight: 6, marginBottom: -6 }}>{props.parent.formatCurrency(props.value)}</div> },
	{ Header: 'Total', accessor: 'TOTAL', width: 100,
		Cell: props => <div style={{ float: 'right', marginRight: 6, marginBottom: -6 }}>{props.parent.formatCurrency(props.value)}</div> },
	{ Header: 'Comments', accessor: 'LINE_COMMENT', width: 300 },
	{ Header: 'Reference', accessor: 'INTER_REF', width: 200 }
];

const detailEditColumns = [
	{ Header: 'Line', accessor: 'LINE_ID', row: 0, col: 0, editable: false },
	{ Header: 'Stock Number', accessor: 'ITEM_ID', labelAccessor: ['STOCK_NUMBER','CAT_NUMBER','DESCRIPTION'], type: 'multicolumn', row: 0, col: 1, editable: false,
		options: { value: 'ITEM_ID', label: ['STOCK_NO','CATALOG_NO','DESCRIPTION'], entity: 'STORES.STOCK_CAT_DESC_VW', order: 'STOCK_NO',
			search: { Operator: 'or', Children: [
				{ Attribute: 'VENDOR_ID', Operator: '=', LongAccessor: 'VENDOR_ID' },
				{ Attribute: 'VENDOR_ID', Operator: 'is null' }
	]}}},
	{ Header: 'Catalog Number', accessor: 'CAT_NUMBER', row: 0, col: 2, editable: false, },
	{ Header: 'Description', accessor: 'DESCRIPTION', row: 0, col: 3, editable: false },
	{ Header: 'Ord Date', accessor: 'ORD_DATE', type: 'date', row: 1, col: 0, editable: false },
	{ Header: 'Ord Qty', accessor: 'ORD_QTY', row: 1, col: 1, editable: false },
	{ Header: "Rec'd Qty", accessor: 'REC_QTY', row: 1, col: 2 },
	{ Header: "Date Rec'd", accessor: 'DATE_RECEIVED', type: 'date', row: 1, col: 3 },
	{ Header: 'Qty on Order', accessor: 'ON_ORDER', row: 2, col: 0 },
	{ Header: 'Unit Size', accessor: 'UNIT_SIZE', row: 2, col: 1, editable: false },
	{ Header: 'Unit Price', accessor: 'UNIT_PRICE', row: 2, col: 2, editable: false },
	{ Header: 'Sub Total', accessor: 'SUB_TOTAL', row: 2, col: 3, editable: false },
	{ Header: 'Sub Total +Tax', accessor: 'TAX', row: 3, col: 0, editable: false },
	{ Header: 'Current Stores Pricing', accessor: 'UNIT_COST', row: 3, col: 1, editable: false },
	{ Header: 'Vendor Price +Tax +Markup', accessor: 'CALC_PRICE', row: 3, col: 2 },
	{ Header: 'Shipping', accessor: 'SHIPPING', row: 3, col: 3, editable: false },
	{ Header: 'Handling', accessor: 'HANDLING', row: 4, col: 0, editable: false },
	{ Header: 'Total', accessor: 'TOTAL', row: 4, col: 1, editable: false },
	{ Header: 'Comments', accessor: 'LINE_COMMENT', type: 'textarea', row: 4, col: 2 },
	{ Header: 'Reference', accessor: 'INTER_REF', type: 'textarea', row: 4, col: 3, editable: false }
];

export default withRouter(Receive);
