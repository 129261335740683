import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class Vendors extends ChemComponent {
	constructor(props) {
		super(props);

		var search = {
			PO_NUMBER: '',
			CART_ID: '',
			VENDOR_NAME: '',
			CATALOG_NUMBER: '',
			PLACED_FROM: null,
			PLACED_TO: null,
			DESCRIPTION: '',
			UPDATED_FROM: null,
			UPDATED_TO: null,
			REFERENCE: '',
			COMMENTS: '',
			STATUS_ID: null,
		};
		
		var vendorListInit = this.copyTable(vendorList);
		
		if (props.search) {
			var propsSearch = this.copyObject(props.search);
			this.mergeObject(search, propsSearch);
			search = propsSearch;
		}

		this.state = {
			vendorList: vendorListInit,
			search: search,
			searchCount: 0,
			renderKey: 0
		};
	}

	componentDidMount() {
		if (this.props.search) this.onSubmitSearch();
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newVendorList = this.copyTable(vendorList);
		newVendorList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			vendorList: newVendorList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.VENDORNAME)) search.Children.push({ Attribute: 'VENDORNAME_UCASE', Operator: 'like', StringValue: '%' + data.VENDORNAME.toUpperCase() + '%' });
		if (!this.isEmpty(data.BFS_SUP_NUM)) search.Children.push({ Attribute: 'BFS_SUP_NUM', Operator: 'like', StringValue: '%' + data.BFS_SUP_NUM + '%' });
		if (!this.isEmpty(data.ADDRESS)) search.Children.push({ Attribute: 'ADDRESS_UCASE', Operator: 'like', StringValue: '%' + data.ADDRESS.toUpperCase() + '%' });
		if (!this.isEmpty(data.CITY)) search.Children.push({ Attribute: 'CITY_UCASE', Operator: 'like', StringValue: data.CITY.toUpperCase() });
		if (!this.isEmpty(data.ZIP)) search.Children.push({ Attribute: 'ZIP', Operator: 'like', StringValue: '%' + data.ZIP + '%' });
		if (!this.isEmpty(data.STATE)) search.Children.push({ Attribute: 'STATE', Operator: 'like', StringValue: data.STATE });
		if (!this.isEmpty(data.PHONE)) search.Children.push({ Operator: 'or', Children: [
			{ Attribute: 'PHONE1', Operator: 'like', StringValue: '%' + data.PHONE + '%' },
			{ Attribute: 'PHONE2', Operator: 'like', StringValue: '%' + data.PHONE + '%' }
		]});
		if (!this.isEmpty(data.STORES_ACCOUNT)) search.Children.push({ Attribute: 'STORES_ACCOUNT', Operator: 'like', StringValue: '%' + data.STORES_ACCOUNT + '%' });

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}

	addVendor() {
		this.props.navigate('/Stores/Vendors/new');
	}
	
	editVendor(vendorId) {
		this.props.setVendorEditable(true);
		this.props.navigate('/Stores/Vendors/' + vendorId);
	}

	viewVendor(vendorId) {
		this.props.setVendorEditable(false);
		this.props.navigate('/Stores/Vendors/' + vendorId);
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Vendors',
			columns: exportColumns,
			entity: 'STORES.VENDORS_VW',
			search: this.constructSearch(this.state.search),
			order: 'VENDORNAME'
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Vendors</span>
						<ChemEdit parent={this} user={this.props.user} columns={vendorSearch} data={this.state.search} autoSubmit={true}
						  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)}
						  onSubmit={(event) => this.onSubmitSearch(event)} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Stores/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Stores/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='Stores_Vendors' table={this.state.vendorList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='340px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const vendorSearch = [
	{ Header: 'Vendor Name', accessor: 'VENDORNAME', row: 0, col: 0 },
	{ Header: 'BFS Code', accessor: 'BFS_SUP_NUM', row: 0, col: 1 },
	{ Header: 'Address', accessor: 'ADDRESS', row: 1, col: 0 },
	{ Header: 'City', accessor: 'CITY', type: 'select', row: 1, col: 1,
		options: { value: 'CITY_UCASE', label: 'CITY', entity: 'STORES.VENDOR_CITY_SELECT_VW', order: 'CITY_UCASE' }},
	{ Header: 'State', accessor: 'STATE', type: 'select', row: 2, col: 0,
		options: { value: 'STATE', label: 'STATE', entity: 'STORES.VENDOR_STATE_SELECT_VW', order: 'STATE' }},
	{ Header: 'Zip Code', accessor: 'ZIP', row: 2, col: 1 },
	{ Header: 'Phone', accessor: 'PHONE', row: 3, col: 0 },
	{ Header: 'Customer Account', accessor: 'STORES_ACCOUNT', row: 3, col: 1 }
];

const vendorList = {
	query: { entity: 'STORES.VENDORS_VW', search: null, order: 'VENDORNAME' },
	pageSize: 20,
	columns: [	
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addVendor()} />, accessor: 'id', width: 25, 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editVendor(props.row.values.VENDOR_ID)} /> },
		{ Header: 'ID', accessor: 'VENDOR_ID', width: 70,
			Cell: props => <HashLink onClick={() => props.parent.viewVendor(props.value)}>{props.value}</HashLink> },	
		{ Header: 'Vendor Name', accessor: 'VENDORNAME', width: 250 },
		{ Header: 'BFS Code', accessor: 'BFS_SUP_NUM', width: 80 },
		{ Header: 'Address', accessor: 'ADDRESS', width: 250 },
		{ Header: 'City', accessor: 'CITY', width: 150 },
		{ Header: 'State', accessor: 'STATE', width: 60 },
		{ Header: 'Zip', accessor: 'ZIP', width: 100 },
		{ Header: 'Country', accessor: 'COUNTRY', width: 125 },
		{ Header: 'Phone 1', accessor: 'PHONE1', width: 125 },
		{ Header: 'Phone 2', accessor: 'PHONE2', width: 125 }
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'VENDOR_ID', width: 14 },	
	{ Header: 'Vendor Name', accessor: 'VENDORNAME', width: 50 },
	{ Header: 'BFS Code', accessor: 'BFS_SUP_NUM', width: 16 },
	{ Header: 'Address', accessor: 'ADDRESS', width: 50 },
	{ Header: 'City', accessor: 'CITY', width: 30 },
	{ Header: 'State', accessor: 'STATE', width: 12 },
	{ Header: 'Zip', accessor: 'ZIP', width: 20 },
	{ Header: 'Country', accessor: 'COUNTRY', width: 25 },
	{ Header: 'Phone 1', accessor: 'PHONE1', width: 25 },
	{ Header: 'Phone 2', accessor: 'PHONE2', width: 25 }
];

export default withRouter(Vendors);
