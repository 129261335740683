import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Link } from "react-router-dom";

class HashLink extends React.Component {

	onClick(event) {
		event.preventDefault();
		var elem = document.getElementById(this.props.hash);
		if (elem) {
			elem.scrollIntoView();
		} else if (this.props.onClick) {
			this.props.onClick(event);
		}
	}

	render() {
		return(<Link to={{ hash: this.props.hash }} onClick={(event) => this.onClick(event)}>{this.props.children}</Link>);
	}
}

export default HashLink;
