import React from 'react';
import { Routes, Route } from "react-router-dom";
import { withRouter } from './withRouter';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ChemComponent from './ChemComponent';
import Home from './Home';
import Admin from './webadmin/Admin';
import BuildingAccess from './buildingaccess/BuildingAccess';
import CRS from './crs/CRS';
import GradOffice from './gradoffice/GradOffice';
import HeLAD from './helad/HeLAD';
import Stores from './stores/Stores';
import Reuse from './reuse/Reuse';
import Test from './test/Test';
import Restricted from './Restricted';
import $ from 'jquery';

class ChemMenu extends ChemComponent {
  	constructor(props) {
		super(props);
		
		this.state = {
			alert: {
				auth: false,
				title: '',
				content: '',
				canCancel: false,
				callback: null,
				show: false
			},
			overlay: false,
			user: {
				uid: -1,
				name: '',
				personID: '',
				antiForgeryToken: null
			}
		};
		
		if (!this.isProduction()) {
			var self = this;
			$.ajaxSetup({
				beforeSend: function(xhr, settings) {
					if (settings.type === 'POST') {
						xhr.setRequestHeader("Access-Control-Allow-Origin", self.getConfig().reactHost);
						xhr.setRequestHeader("X-CSRFToken", self.getCookie('csrftoken'));
					}
				},
				cache: false,
				xhrFields: {
				   withCredentials: true
				}
			});
		}
		
		this.logInRef = React.createRef(false);
	}
	
	componentDidMount() {
		var self = this;
		
		// if we have not done this already
		if (!this.logInRef.current) {
			// note that this has been done
			this.logInRef.current = true;
			
			// check to see if the browser thinks we're logged in
			var user = {
				originalUID: window.sessionStorage.getItem("original_uid"),
				uid: window.sessionStorage.getItem("cal_uid"),
				loginType: window.sessionStorage.getItem("login_type")
			};
			if (this.isEmpty(user.loginType)) user.loginType = loginTypes.cas;
			
			var isCASLocation = !this.startsWithIgnoreCase(this.props.location.pathname, '/stores/checkout') &&
				!this.startsWithIgnoreCase(this.props.location.pathname, '/helad/ccs');
			
			// if the login type is not CAS and we are trying to access a CAS location
			if (+user.loginType !== +loginTypes.cas && isCASLocation) {
				// transitioning from POS to CAS - login required
				this.logIn(loginTypes.cas, true);
			} else if (user.uid) {
				// try getting user info, log in if we fail
				this.getAntiForgeryToken(user, () => {
					if (isCASLocation) self.logIn(loginTypes.cas);
				});
			} else {
				// get CAS login if we need one
				if (isCASLocation) this.logIn(loginTypes.cas);
			}
		}
	}
	
	getAntiForgeryToken(user, fnFail) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Home/GetAntiForgeryToken',
			overlay: true,
			data: { caluid: user.uid }
		}).done(function (data) {
			if (data.Success) {
				user.antiForgeryToken = data.AntiForgeryToken;
				
				// transfer user info
				if (data.User.NewRequest) {
					user.firstName = data.User.NewRequest.FNAME;
					user.lastName = data.User.NewRequest.LNAME;
					user.name = data.User.NewRequest.FNAME + ' ' + data.User.NewRequest.LNAME;
					user.personID = null;
					user.bldgMgmtAccessLevel = 0;
					user.isAdmin = false;
					user.isDelegate = false;
					user.isFSA = false;
					user.isImpersonator = false;
					user.isPI = false;
					user.isSAO = false;
					user.isStoreManager = false;
					user.isStudentWorkshopManager = false;
					user.isSupervisor = false;
					user.isWebAdmin = false;
				} else {	
					user.firstName = data.User.PeopleView.FNAME;
					user.lastName = data.User.PeopleView.LNAME;
					user.name = data.User.PeopleView.FNAME + ' ' + data.User.PeopleView.LNAME;
					user.personID = data.User.PeopleView.PERSON_ID;
					user.bldgMgmtAccessLevel = data.User.BldgMgmtAccessLevel;
					user.isAdmin = data.User.IsAdmin;
					user.isDelegate = data.User.IsDelegate;
					user.isFSA = data.User.IsFSA;
					user.isImpersonator = data.Impersonators.includes(user.originalUID);
					user.isPI = data.User.IsPI;
					user.isSAO = data.User.IsSAO;
					user.isStoreManager = data.User.IsStoreManager;
					user.isStudentWorkshopManager = data.User.IsStudentWorkshopManager;
					user.isSupervisor = data.User.IsSupervisor;
					user.isWebAdmin = data.User.IsWebAdmin;
				}
				
				// transfer CRS auth info
				user.crs = {};
				if (data.User.CRS) {
					user.crs.guestStatus = data.User.CRS.GuestStatus;
					user.crs.raFlag = data.User.CRS.RAFlag;
					user.crs.raRoleIDs = data.User.CRS.RARoleIDs;
					user.crs.userAccessID = data.User.CRS.UserAccessID;
					user.crs.userUnitIDs = data.User.CRS.UserUnitIDs;
					user.crs.serviceMgr = data.User.CRS.ServiceMgr;
					user.crs.piRoleIDs = data.User.CRS.PIRoleIDs;
				} else {
					user.crs.guestStatus = false;
					user.crs.raFlag = false;
					user.crs.raRoleIDs = [];
					user.crs.userAccessID = 0;
					user.crs.userUnitIDs = [];
					user.crs.serviceMgr = [];
					user.crs.piRoleIDs = [];
				}
				
				// transfer GradOffice auth info
				user.gradoffice = {};
				if (data.User.GradOffice) {
					user.gradoffice.guestStatus = data.User.GradOffice.GuestStatus;
					user.gradoffice.userAccessID = data.User.GradOffice.UserAccessID;
				} else {
					user.gradoffice.guestStatus = false;
					user.gradoffice.userAccessID = 0;
				}
				
				// transfer HeLAD auth info
				user.helad = {};
				if (data.User.HeLAD) {
					user.helad.demurrageAuthLevel = data.User.HeLAD.DemurrageAuthLevel;
					user.helad.heladAuthLevel = data.User.HeLAD.HeLADAuthLevel;
					user.helad.liquidAirAuthLevel = data.User.HeLAD.LiquidAirAuthLevel;
				} else {
					user.helad.demurrageAuthLevel = 0;
					user.helad.heladAuthLevel = 0;
					user.helad.liquidAirAuthLevel = 0;
				}
				
				// transfer Stores auth info
				user.stores = {};
				if (data.User.Stores) {
					user.stores.authLevel = data.User.Stores.AuthLevel;
				} else {
					user.stores.authLevel = 0;
				}
				
				user.reuse = {};
				if (data.User.Reuse) {
					user.reuse.authLevel = data.User.Reuse.AuthLevel;
				} else {
					user.reuse.authLevel = 0;
				}
				
				self.mergeState({
					user: user
				});
			} else {
				if (fnFail) {
					fnFail();
				} else {
					self.showAlert('Authentication Error', data.Message);
				}
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			if (fnFail) {
				fnFail();
			} else {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			}
		});
	}			
			
	logIn(loginType, loginArg) {
		var self = this;
		
		// if no login type is supplied, assume CAS
		if (this.isEmpty(loginType)) loginType = loginTypes.cas;
		
		// look for ticket in query string (CAS); otherwise use cardmag (POS)
		var ticket = loginType === loginTypes.cas ? this.getQueryParam("ticket") : loginArg;
		
		// if there is no ticket and this is a CAS login
		if (!ticket && loginType === loginTypes.cas) {				
			// store route info in session storage
			window.sessionStorage.setItem("route", this.props.location.pathname);
			
			// redirect to authentication server
			window.location = 'https://' + this.getAuthServer() + '/cas/login?service=' + this.getConfig().reactHost + (loginArg ? '&renew=true' : '');
		} else {
			// verify ticket
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/VerifyTicket',
				overlay: true,
				data: { ticket: ticket, loginType : loginType }
			}).done(function (data) {
				if (data.Success) {
					// remove ticket from URL if this is a CAS login
					if (loginType === loginTypes.cas) window.history.replaceState({}, document.title, "/");
					
					// save user data to window session
					window.sessionStorage.setItem("original_uid", data.OriginalUID);
					window.sessionStorage.setItem("cal_uid", data.UID);
					window.sessionStorage.setItem("login_type", loginType);

					// get current route
					var route = window.sessionStorage.getItem("route");
					window.sessionStorage.removeItem("route");
					
					// if we are on the home page or this is not a CAS login
					if (!route || route === "" || route === "/" || loginType !== loginTypes.cas) {						
						// get anti forgery token
						self.getAntiForgeryToken({
							originalUID: data.OriginalUID,
							uid: data.UID
						});
					} else {
						// relocate to route
						window.location = self.pathJoin(window.location, route);
					}
				} else {
					self.showAlert('Authentication Error', data.Message);
				}
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		}
	}
	
	getQueryParam(name) {
        var query = window.location.search.substring(1);
        var nvpairs = query.split("&");
        for (var i = 0; i < nvpairs.length ; i++) {
			var nvpair = nvpairs[i].split("=");
			if (nvpair[0] === name) return nvpair[1];
        }    
		return null;
	}
	
	showAlert(title, content, callback) {		
		this.mergeState({
			alert: {
				auth: false,
				title: title,
				content: content,
				canCancel: false,
				callback: callback,
				show: true
			}
		});
	}
	
	showAuthAlert(retcode) {
		this.mergeState({
			alert: {
				auth: true,
				title: 'Not Authorized',
				content: 'User not authorized, or authorization has expired. Please log out and log in again. (' + retcode + ')',
				canCancel: false,
				callback: null,
				show: true
			}
		});
	}
	
	showOKCancel(title, content, callback) {
		this.mergeState({
			alert: {
				title: title,
				content: content,
				canCancel: true,
				callback: callback,
				show: true
			}
		});
	}		

	onAlertClose(okButtonPressed) {
		// save local values so we can reset state
		var callback = this.state.alert.callback;
		var canCancel = this.state.alert.canCancel;
		
		this.mergeState({
			alert: {
				auth: false,
				title: '',
				content: '',
				canCancel: false,
				callback: null,
				show: false
			}
		}, () => {
			if (callback) {
				if (canCancel) {
					callback(okButtonPressed);
				} else {
					callback();
				}
			}			
		});
	}
	
	cancelModalDialog(callback) {
		// close the modal dialog, only invoking the callback we've been passed
		this.mergeState({
			alert: {
				auth: false,
				title: '',
				content: '',
				canCancel: false,
				callback: null,
				show: false
			}
		}, () => {
			if (callback) callback();
		});
	}
	
	renderCancelButton() {
		if (this.state.alert.canCancel) {
			return (<Button variant="primary" onClick={() => this.onAlertClose(false)}>Cancel</Button>);
		}
	}
	
	renderContent() {
		// make copy of overlay style
		var overlayStyle = this.copyObject(overlay);
		overlayStyle.display = this.state.overlay ? 'block' : 'none';
		var logoOverlayStyle = this.copyObject(logoOverlay);
		logoOverlayStyle.display = this.state.overlay ? 'block' : 'none';
		
		return (<>
			<div style={overlayStyle}></div>
			<div style={logoOverlayStyle}><div style={logo}></div></div>
			{(this.startsWithIgnoreCase(this.props.location.pathname, '/stores/checkout') || this.startsWithIgnoreCase(this.props.location.pathname, '/helad/ccs')) ?
				<Routes>
					<Route path={'/stores/*'} element={
						<Stores parent={this} user={this.state.user} logIn={(cardmag) => this.logIn(loginTypes.stores, cardmag)} />
					} />
					<Route path={'/helad/*'} element={
						<HeLAD parent={this} user={this.state.user} logIn={(cardmag) => this.logIn(loginTypes.helad, cardmag)} />
					} />
				</Routes> :
				this.state.user.uid === -1 ?
					<Home parent={this} user={this.state.user} identity={false} /> :
					<Routes>
						<Route path={'/'} element={<Home parent={this} user={this.state.user} identity={false} />} />
						<Route path={'/identity'} element={<Home parent={this} user={this.state.user} identity={true} />} />
						<Route path={'/buildingaccess/*'} element={<BuildingAccess parent={this} user={this.state.user} />} />
						<Route path={'/crs/*'} element={<CRS parent={this} user={this.state.user} />} />
						<Route path={'/gradoffice/*'} element={
							<Restricted authorized={this.state.user.gradoffice.userAccessID > 0}>
								<GradOffice parent={this} user={this.state.user} />
							</Restricted>
						} />
						<Route path={'/helad/*'} element={<HeLAD parent={this} user={this.state.user} />} />
						<Route path={'/stores/*'} element={
							<Restricted authorized={this.state.user.stores.authLevel > 0}>
								<Stores parent={this} user={this.state.user} />
							</Restricted>
						} />
						<Route path={'/reuse/*'} element={<Reuse parent={this} user={this.state.user} />} />
						<Route path={'/webadmin/*'} element={
							<Restricted authorized={this.state.user.isWebAdmin || this.state.user.isAdmin}>
								<Admin parent={this} user={this.state.user} />
							</Restricted>
						} />
						<Route path={'/test/*'} element={<Test parent={this} user={this.state.user} />} />
					</Routes>
			}
		</>);
	}
 
	render() {
		return (<>
			{this.renderContent()}
			<Modal show={this.state.alert.show} onHide={() => this.onAlertClose(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{this.state.alert.title}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<p dangerouslySetInnerHTML={{__html: this.state.alert.content}}></p>
				</Modal.Body>

				<Modal.Footer>
					{this.state.alert.auth ?
						<Button variant='primary' onClick={() => this.logout()}>Log Out</Button> :
						<Button variant="primary" onClick={() => this.onAlertClose(true)}>OK</Button>}
					{this.renderCancelButton()}
				</Modal.Footer>
			</Modal>
		</>)
	}
}

const loginTypes = {
	cas: 0,
	stores: 1,
	helad: 2
};

const overlay = {
	position: 'fixed',
	left: '0px',
	top: '0px',
	width: '100%',
	height: '100%',
	zIndex: 10003,
	background: 'rgb(170, 170, 170)',
	opacity: 0.30,
	filter: 'Alpha(Opacity=30)',
	cursor: 'wait'
};

const logoOverlay = {
	position: 'fixed',
	left: '0px',
	top: '0px',
	width: '100%',
	height: '100%',
	zIndex: 10003,
	background: 'transparent',
	filter: 'Alpha(Opacity=30)',
	cursor: 'wait'
};

const logo = {
	opacity: 0.7,
	background: 'url(/images/seal.png) 50% 50% no-repeat scroll',
	backgroundSize: '200px 200px',
	position: 'fixed',
	left: '50%',
	top: '50%',
	margin: '-100px 0 0 -100px',
	width: '200px',
	height: '200px',
	zIndex: 10005,
	WebkitAnimation: 'logoRotate 4s linear infinite',
	animation: 'logoRotate 4s linear infinite',
	WebkitTransformStyle: 'preserve-3d',
	MozTransformStyle: 'preserve3d',
	MsTransformStyle: 'preserve-3d'
};

export default withRouter(ChemMenu);
