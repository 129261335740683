import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';

import { DoorClosed, DoorOpen, HandThumbsDown } from 'react-bootstrap-icons';

class StoresApproval extends ChemComponent {
  	constructor(props) {
		super(props);
		
		this.state = {
			renderKey: 1,
			storesApproval: this.mergeObject(storesApproval, { query: { search: null } }),
			search: {
				LNAME: '',
				FNAME: '',
			}
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event)
	{
		// construct search terms
		var search = {
			Operator: 'and',
			children: []
		};

		if (!this.isEmpty(this.state.search.LNAME)) search.children.push({ Attribute: 'LNAME', Operator: '=', StringValue: this.state.search.LNAME });
		if (!this.isEmpty(this.state.search.FNAME)) search.children.push({ Attribute: 'FNAME', Operator: '=', StringValue: this.state.search.FNAME });
		
		// if nothing was selected, forget it
		if (search.children.length === 0) search = null;
			
		// update state
		this.mergeState({
			storesApproval: {
				renderKey: this.state.storesApproval.renderKey + 1,
				query: {
					search: search
				}
			}
		}, () => {
			if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
		});
	}
	
	updateStoreroomAccess(props, approve, door) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/BuildingAccess/UpdateStoreroomAccess',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				approval: props.row.values,
				approve: approve,
				door: door
			}
		}).done(function (data) {
			if (data.Success) {
				// refresh approval list
				self.mergeState({
					storesApproval: {
						renderKey: self.state.storesApproval.renderKey + 1
					}
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	render() {

		return (<>
			<Container fluid>
				<Row>
					<Col>
					  <Container>
					    <Row>
						  <Col xs={10} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
							<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Find a Person in Storeroom Approval</span>
					        <ChemEdit parent={this} columns={storesApprovalSearchColumns} data={this.state.search} renderKey={this.state.key} 
						      onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
							  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						        <Col xs={12}>
					              <Button id="update" variant="warning" type="submit" className="float-end">Update</Button>
								</Col>
							  </Form.Group>
					        </ChemEdit>
					      </Col>
						</Row>
					  </Container>
					</Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.storesApproval.renderKey} parent={this} name='BuildingAccess_StoresApproval' table={this.state.storesApproval} offsetHeight='230px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const storesApprovalSearchColumns = [
	{ Header: 'Last Name', accessor: 'LNAME', row: 0, col: 0 },
	{ Header: 'First Name', accessor: 'FNAME', row: 1, col: 0 }
];

const storesApproval = {
	renderKey: 0,
	pageSize: 10,
	query: { entity: 'STORES_APPROVAL_VW', order: 'REQ_NAME' },
	columns: [
		{ Header: 'Name', accessor: 'REQ_NAME' },
		{ Header: 'PI', accessor: 'PI_NAME' },
		{ Header: 'Appt. Type', accessor: 'TYPE_NAME' },
		{ Header: 'Email', accessor: 'EMAIL' },
		{ Header: 'Last Updated', accessor: 'LAST_UPDATED' },
		{ Header: 'Action', accessor: 'LOC_ACCESS_ID',
			Cell: props => <>
			    <OverlayTrigger placement="top" trigger="hover" overlay={
					<Popover><Popover.Header as="h3">Approve With Door Access</Popover.Header></Popover>
				}>
					<DoorOpen style={{ cursor: 'pointer' }} onClick={() => props.parent.updateStoreroomAccess(props, true, true)} />
				</OverlayTrigger>&nbsp;&nbsp;&nbsp;&nbsp;
			    <OverlayTrigger placement="top" trigger="hover" overlay={
					<Popover><Popover.Header as="h3">Approve (No Door Access)</Popover.Header></Popover>
				}>
					<DoorClosed style={{ cursor: 'pointer' }} onClick={() => props.parent.updateStoreroomAccess(props, true, false)} />
				</OverlayTrigger>&nbsp;&nbsp;&nbsp;&nbsp;
			    <OverlayTrigger placement="top" trigger="hover" overlay={
					<Popover><Popover.Header as="h3">Deny</Popover.Header></Popover>
				}>
					<HandThumbsDown style={{ cursor: 'pointer' }} onClick={() => props.parent.updateStoreroomAccess(props, false, false)} />
				</OverlayTrigger>
			</>},
		{ Header: 'FNAME', accessor: 'FNAME', show: false },
		{ Header: 'LNAME', accessor: 'LNAME', show: false },
		{ Header: 'CAL_UID', accessor: 'CAL_UID', show: false },
		{ Header: 'REQ_ROLE_ID', accessor: 'REQ_ROLE_ID', show: false },
		{ Header: 'PERSON_ID', accessor: 'PERSON_ID', show: false },
		{ Header: 'PI_ROLE_ID', accessor: 'PI_ROLE_ID', show: false },
		{ Header: 'SUPERVISOR', accessor: 'SUPERVISOR', show: false }
	]
};

export default StoresApproval;