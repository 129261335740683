import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row, OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import ChemComponent from '../../ChemComponent';
import QueryTable from '../../QueryTable';

class DeansList extends ChemComponent {
  	constructor(props) {
		super(props);
		
		this.state = {
			renderKey: 1,
			searchCount: null
		};
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
		
	render() {		
	
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: "Dean's List",
			columns: exportColumns,
			entity: 'DEANS_LIST_VW',
			order: ['DEPARTMENT','PI_NAME','LOCATION','OCCUPANT_NAME']
		});			

		return (<>
			<Container fluid>
				<Row style={{ paddingTop: '20px', paddingBottom: '20px', marginBottom: '20px', border: '1px solid #ccc',
					borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<Col xs={2}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Dean's List</span>
					</Col>
					<Col xs={2} style={{ marginTop: 2}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					</Col>
					<Col xs={1}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					</Col>
					<Col xs={1}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					</Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_DeansList' table={deansList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='100px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const deansList = {
	query: { entity: 'DEANS_LIST_VW', order: ['DEPARTMENT','PI_NAME','LOCATION','OCCUPANT_NAME'] },
	pageSize: 10,
	columns: [
		{ Header: 'PI', accessor: 'PI_NAME' },
		{ Header: 'Dept.', accessor: 'DEPARTMENT' },
		{ Header: 'Personnel', accessor: 'OCCUPANT_NAME' },
		{ Header: 'Appt.', accessor: 'APPT_TYPE' },
		{ Header: 'Location', accessor: 'LOCATION' },
		{ Header: 'Room Function', accessor: 'ROOM_FUNCTION' },
		{ Header: 'ASF', accessor: 'SQUARE_FOOTAGE' },
		{ Header: 'Total ASF', accessor: 'TOTAL_FOOTAGE' }
	]
};

const exportColumns = [
	{ Header: 'PI', accessor: 'PI_NAME', width: 20 },
	{ Header: 'Dept.', accessor: 'DEPARTMENT', width: 20 },
	{ Header: 'Personnel', accessor: 'OCCUPANT_NAME', width: 20 },
	{ Header: 'Appt.', accessor: 'APPT_TYPE', width: 20 },
	{ Header: 'Location', accessor: 'LOCATION', width: 20 },
	{ Header: 'Room Function', accessor: 'ROOM_FUNCTION', width: 20 },
	{ Header: 'ASF', accessor: 'SQUARE_FOOTAGE', width: 10 },
	{ Header: 'Total ASF', accessor: 'TOTAL_FOOTAGE', width: 10 }
];

export default DeansList;