import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import Tabs from '../Tabs';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class Bottles extends ChemComponent {
	constructor(props) {
		super(props);

		this.state = {
			bottleList: this.copyTable(bottleList),
			search: {
				CAS_NUMBER: '',
				CHEM_NAME: '',
				SHELF_CODE: '',
				BOTTLE_ID: '',
				BOTTLE_ID_GT: '',
				BOTTLE_ID_LT: '',
				CHEM_STATUS_IN: true,
				CHEM_STATUS_OUT: true,
				DELETE_FLAG: false,
				FORMULA: false,
				ELEMENT: ['C','H','O','N',null,null],
				RELATION: ['>=','>=','>=','>=','>=','>='],
				NUMBER: ['','','','','',''],
				EXCLUSION: false,
				EXCLUDE: [null, null],
 				BF4: false,
 				BH3: false,
 				Br2: false,
 				C2H4O: false,
 				C2H4O2: false,
 				C4H6O6: false,
 				C4H8O: false,
 				C6H13N: false,
 				CH4SO3: false,
 				CO: false,
 				ClO4: false,
 				H2CO3: false,
 				H2O: false,
 				H2O2: false,
 				H2SO4: false,
 				H3PO4: false,
 				HBr: false,
 				HBr3: false,
 				HCO2: false,
 				HCl: false,
 				HF: false,
 				HNO3: false,
 				LiBr: false,
 				LiCl: false,
				UNIT_ID: null,
				STORAGE_CODE: null,
				BOTTLE_SIZE: '',
				PURITY: '',
				PERSON_FNAME: '',
				PERSON_LNAME: '',
				CATALOG_NUMBER: '',
				PERSON_ID: null,
				PI_ID: null,
				CHEM_STATE: [],
				DATE_SHELVED_FROM: null,
				DATE_SHELVED_TO: null,
				DATE_MODIF: null,
				CHEM_CLASS: [],
				BRAND_ID: [],
				SEG_ID: [],
				STORAGE_ID: [],
				FORM_ID: [],
				GRADE_ID: [],
				CONTAINER_ID: [],
				OTHER_NOTES: '',
				COMMENT_ID: null,
			},
			showSearch: true,
			searchCount: 0,
			renderKey: 0
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onExpandSearch(event) {
		this.mergeState({
			showSearch: true,
			bottleList: this.copyTable(bottleList),
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();		
	}

	onSubmitSearch(event) {
		var newBottleList = this.copyTable(bottleList);
		newBottleList.ajax.data = {
			search: this.copyObject(this.state.search)
		};
		newBottleList.hold = false;
		newBottleList.ajax.data.search.COMPLEX = this.getComplexArray(newBottleList.ajax.data.search);
		this.mergeState({ 
			bottleList: newBottleList,
			showSearch: false,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	getComplexArray(search) {
		var complex = [];
		
		if (search.BF4) complex.push('BF4');
		if (search.BH3) complex.push('BH3');
		if (search.Br2) complex.push('Br2');
		if (search.C2H4O) complex.push('C2H4O');
		if (search.C2H4O2) complex.push('C2H4O2');
		if (search.C4H6O6) complex.push('C4H6O6');
		if (search.C4H8O) complex.push('C4H8O');
		if (search.C6H13N) complex.push('C6H13N');
		if (search.CH4SO3) complex.push('CH4SO3');
		if (search.CO) complex.push('CO');
		if (search.ClO4) complex.push('ClO4');
		if (search.H2CO3) complex.push('H2CO3');
		if (search.H2O) complex.push('H2O');
		if (search.H2O2) complex.push('H2O2');
		if (search.H2SO4) complex.push('H2SO4');
		if (search.H3PO4) complex.push('H3PO4');
		if (search.HBr) complex.push('HBr');
		if (search.HBr3) complex.push('HBr3');
		if (search.HCO2) complex.push('HCO2');
		if (search.HCl) complex.push('HCl');
		if (search.HF) complex.push('HF');
		if (search.HNO3) complex.push('HNO3');
		if (search.LiBr) complex.push('LiBr');
		if (search.LiCl) complex.push('LiCl');
		
		return complex;
	}
	
	addBottle() {
		this.props.navigate('/Reuse/Bottles/new');
	}
	
	editBottle(bottleId) {
		this.props.setBottleEditable(true);
		this.props.navigate('/Reuse/Bottles/' + bottleId);
	}

	viewBottle(bottleId) {
		this.props.setBottleEditable(false);
		this.props.navigate('/Reuse/Bottles/' + bottleId);
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	renderElement(idx) {
		return(
			<Container fluid>
				<Row>
					<Col xs={5} style={{ marginBottom: '14px' }}>
						<Form.Control as="select" value={this.getByAccessor(this.state.search, 'ELEMENT[' + idx + ']')}
							onChange={(event) => this.onChangeSearch('ELEMENT[' + idx + ']', event.target.value)}>
							{elementList.map((option, optionIdx) => {
								return (<option key={optionIdx} value={option.value}>{option.label}</option>);
							})}
						</Form.Control>
					</Col>
					<Col xs={2} style={{ marginBottom: '14px' }}>
						<Form.Control as="select" value={this.getByAccessor(this.state.search, 'RELATION[' + idx + ']')}
							onChange={(event) => this.onChangeSearch('RELATION[' + idx + ']', event.target.value)}>
							{comparators.map((option, optionIdx) => {
								return (<option key={optionIdx} value={option.value}>{option.label}</option>);
							})}
						</Form.Control>
					</Col>
					<Col xs={5} style={{ marginBottom: '14px' }}>
						<Form.Control type="text" value={this.getByAccessorForInput(this.state.search, 'NUMBER[' + idx + ']')}
							onChange={(event) => this.onChangeSearch('NUMBER[' + idx + ']', event.target.value)} />
					</Col>
				</Row>
			</Container>
		);
	}
	
	renderFormula(formula) {
		return formula.split('').map((char, idx) => {
			if (this.isNumeric(char)) {
				return <React.Fragment key={idx}><sub>{char}</sub></React.Fragment>;
			} else {
				return <React.Fragment key={idx}>{char}</React.Fragment>;
			}
		});
	}
	
	renderComplex(formula1, formula2, formula3) {
		return (
			<Container fluid>
				<Row>
					<Col style={{ marginBottom: '14px' }}>
						<Form.Check style={{ paddingTop: '8px' }} type="checkbox"
							checked={this.getByAccessor(this.state.search, formula1)} label={this.renderFormula(formula1)}
							onClick={(event) => this.onChangeSearch(formula1, event.target.checked)} />
					</Col>
					<Col style={{ marginBottom: '14px' }}>
						<Form.Check style={{ paddingTop: '8px' }} type="checkbox"
							checked={this.getByAccessor(this.state.search, formula2)} label={this.renderFormula(formula2)}
							onClick={(event) => this.onChangeSearch(formula2, event.target.checked)} />
					</Col>
					<Col style={{ marginBottom: '14px' }}>
						<Form.Check style={{ paddingTop: '8px' }} type="checkbox"
							checked={this.getByAccessor(this.state.search, formula3)} label={this.renderFormula(formula3)}
							onClick={(event) => this.onChangeSearch(formula3, event.target.checked)} />
					</Col>
				</Row>
			</Container>
		);
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			Action: 'Bottles',
			columns: exportColumns,
			search: this.state.search
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Bottles</div>
						{this.state.showSearch && <Tabs>
						  <div label='Bottle'>
							<ChemEdit parent={this} user={this.props.user} columns={bottleSearch} data={this.state.search}
							  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)} />
						  </div>
						  <div label='Formula'>
							<ChemEdit parent={this} user={this.props.user} columns={formulaSearch} data={this.state.search}
							  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)} />
						  </div>
						  <div label='Additional'>
							<ChemEdit parent={this} user={this.props.user} columns={additionalSearch} data={this.state.search}
							  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)} />
						  </div>
						</Tabs>}
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Stores/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Stores/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						{this.state.showSearch ?
							<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button> :
							<Button id="expand" variant="warning" type="button" className="float-end" onClick={(event) => this.onExpandSearch(event)}>Modify Search</Button>
						}
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='Reuse_Bottles' table={this.state.bottleList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='150px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const comparators = [
	{ value: '=', label: '=' },
	{ value: '<=', label: '≤' },
	{ value: '>=', label: '≥' }
];

const elementList = [
	{ value: null, label: 'Select...'},                
	{ value: 'Pb', label: 'Pb'},                
	{ value: 'Mo', label: 'Mo' },                
	{ value: 'Eu', label: 'Eu' },                
	{ value: 'W', label: 'W' },                
	{ value: 'Tl', label: 'Tl' },                
	{ value: 'Ca', label: 'Ca' },                
	{ value: 'Lu', label: 'Lu' },                
	{ value: 'Ho', label: 'Ho' },                
	{ value: 'Ce', label: 'Ce' },                
	{ value: 'B', label: 'B' },                
	{ value: 'Mn', label: 'Mn' },                
	{ value: 'Se', label: 'Se' },                
	{ value: 'C', label: 'C' },                
	{ value: 'Tm', label: 'Tm' },                
	{ value: 'Xe', label: 'Xe' },                
	{ value: 'Sr', label: 'Sr' },                
	{ value: 'Te', label: 'Te' },                
	{ value: 'Nd', label: 'Nd' },                
	{ value: 'Pt', label: 'Pt' },                
	{ value: 'Rh', label: 'Rh' },                
	{ value: 'Sb', label: 'Sb' },                
	{ value: 'F', label: 'F' },                
	{ value: 'I', label: 'I' },                
	{ value: 'Si', label: 'Si' },                
	{ value: 'Br', label: 'Br' },                
	{ value: 'Cl', label: 'Cl' },                
	{ value: 'H', label: 'H' },                
	{ value: 'O', label: 'O' },                
	{ value: 'Ni', label: 'Ni' },                
	{ value: 'Pr', label: 'Pr' },                
	{ value: 'In', label: 'In' },                
	{ value: 'As', label: 'As' },                
	{ value: 'Th', label: 'Th' },                
	{ value: 'Rb', label: 'Rb' },                
	{ value: 'Y', label: 'Y' },                
	{ value: 'Cs', label: 'Cs' },                
	{ value: 'Cr', label: 'Cr' },                
	{ value: 'Sn', label: 'Sn' },                
	{ value: 'N', label: 'N' },                
	{ value: 'P', label: 'P' },                
	{ value: 'Er', label: 'Er' },                
	{ value: 'Gd', label: 'Gd' },                
	{ value: 'Nb', label: 'Nb' },                
	{ value: 'Hf', label: 'Hf' },                
	{ value: 'Yb', label: 'Yb' },                
	{ value: 'Zr', label: 'Zr' },                
	{ value: 'Al', label: 'Al' },                
	{ value: 'Ti', label: 'Ti' },                
	{ value: 'S', label: 'S' },                
	{ value: 'Sm', label: 'Sm' },                
	{ value: 'Ar', label: 'Ar' },                
	{ value: 'Re', label: 'Re' },                
	{ value: 'Ta', label: 'Ta' },                
	{ value: 'Bi', label: 'Bi' },                
	{ value: 'Ru', label: 'Ru' },                
	{ value: 'K', label: 'K' },                
	{ value: 'Ba', label: 'Ba' },                
	{ value: 'Fe', label: 'Fe' },                
	{ value: 'La', label: 'La' },                
	{ value: 'Hg', label: 'Hg' },                
	{ value: 'Tb', label: 'Tb' },                
	{ value: 'Au', label: 'Au' },                
	{ value: 'V', label: 'V' },                
	{ value: 'Li', label: 'Li' },                
	{ value: 'Ag', label: 'Ag' },                
	{ value: 'Cu', label: 'Cu' },                
	{ value: 'Zn', label: 'Zn' },                
	{ value: 'U', label: 'U' },                
	{ value: 'Cd', label: 'Cd' },                
	{ value: 'Ga', label: 'Ga' },                
	{ value: 'Pd', label: 'Pd' },                
	{ value: 'D', label: 'D' },                
	{ value: 'Na', label: 'Na' },                
	{ value: 'Mg', label: 'Mg' },                
	{ value: 'Co', label: 'Co' },                
	{ value: 'Ge', label: 'Ge' },                
	{ value: 'Be', label: 'Be' },                
	{ value: 'Os', label: 'Os' },                
	{ value: 'Dy', label: 'Dy' },                
	{ value: 'Ir', label: 'Ir' },                
	{ value: 'Sc', label: 'Sc' }
];

const bottleSearch = [
	{ Header: 'CAS Number', accessor: 'CAS_NUMBER', row: 0, col: 0 },
	{ Header: 'Name', accessor: 'CHEM_NAME', row: 0, col: 1 },
	{ Header: 'Shelf Code', accessor: 'SHELF_CODE', row: 0, col: 2 },
	{ Header: 'Bottle Number =', accessor: 'BOTTLE_ID', row: 1, col: 0 },
	{ Header: 'Bottle Number >', accessor: 'BOTTLE_ID_GT', row: 1, col: 1 },
	{ Header: 'Bottle Number <', accessor: 'BOTTLE_ID_LT', row: 1, col: 2 },
	{ Header: 'In Reuse', accessor: 'CHEM_STATUS_IN', type: 'checkbox', row: 2, col: 0 },
	{ Header: 'Checked Out', accessor: 'CHEM_STATUS_OUT', type: 'checkbox', row: 2, col: 1 },
	{ Header: 'Include Deleted', accessor: 'DELETE_FLAG', type: 'checkbox', row: 2, col: 2 },
];

const formulaSearch = [
	{ Header: '<b>Search by Formula</b>', accessor: 'FORMULA', type: 'checkbox', row: 0, col: 0 },
	{ Editor: props => props.parent.renderComplex('BF4', 'CH4SO3', 'HBr'), row: 0, col: 2 },
	{ Editor: props => props.parent.renderElement(0), row: 1, col: 0 },
	{ Editor: props => props.parent.renderComplex('BH3', 'CO', 'HBr3'), row: 1, col: 2 },
	{ Editor: props => props.parent.renderElement(1), row: 2, col: 0 },
	{ Header: 'Exclude', accessor: 'EXCLUSION', type: 'checkbox', label: 'all other elements', row: 2, col: 1 },
	{ Editor: props => props.parent.renderComplex('Br2', 'ClO4', 'HCO2'), row: 2, col: 2 },
	{ Editor: props => props.parent.renderElement(2), row: 3, col: 0 },
	{ Header: 'Exclude', accessor: 'EXCLUDE[0]', type: 'select', options: elementList, row: 3, col: 1 },
	{ Editor: props => props.parent.renderComplex('C2H4O', 'H2CO3', 'HCl'), row: 3, col: 2 },
	{ Editor: props => props.parent.renderElement(3), row: 4, col: 0 },
	{ Header: 'Exclude', accessor: 'EXCLUDE[1]', type: 'select', options: elementList, row: 4, col: 1 },
	{ Editor: props => props.parent.renderComplex('C2H4O2', 'H2O', 'HF'), row: 4, col: 2 },
	{ Editor: props => props.parent.renderElement(4), row: 5, col: 0 },
	{ Editor: props => props.parent.renderComplex('C4H6O6', 'H2O2', 'HNO3'), row: 5, col: 2 },
	{ Editor: props => props.parent.renderElement(5), row: 6, col: 0 },
	{ Editor: props => props.parent.renderComplex('C4H8O', 'H2SO4', 'LiBr'), row: 6, col: 2 },
	{ Editor: props => props.parent.renderComplex('C6H13N', 'H3PO4', 'LiCl'), row: 7, col: 2 }
];

const additionalSearch = [
	{ Header: 'Unit', accessor: 'UNIT_ID', type: 'select', row: 0, col: 0,
		options: { value: 'UNIT_ID', label: 'UNIT_NAME', entity: 'REUSE.UNIT_SIZE', 
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Storage Code', accessor: 'STORAGE_CODE', type: 'select', row: 0, col: 1,
		options: { value: 'TYPE_ID', label: 'TYPE_CODE', entity: 'REUSE.STORAGE_TYPE' }},
	{ Header: 'Bottle Size', accessor: 'BOTTLE_SIZE', row: 0, col: 2 },
	{ Header: 'Purity (%)', accessor: 'PURITY', row: 1, col: 0 },
	{ Header: 'Checked Out By', accessor: 'PERSON_FNAME', subscript: 'First Name', row: 1, col: 1 },
	{ Header: 'Checked Out By', accessor: 'PERSON_LNAME', subscript: 'Last Name', row: 1, col: 2 },
	{ Header: 'Catalog #', accessor: 'CATALOG_NUMBER', row: 2, col: 0 },
	{ Header: 'Checked Out By', accessor: 'PERSON_ID', type: 'select', row: 2, col: 1,
		options: { value: 'PERSON_ID', label: 'USER_NAME', entity: 'REUSE.CHECKED_OUT_BY_VW', order: 'USER_NAME_UCASE' }},
	{ Header: 'Checked Out By', accessor: 'PI_ID', type: 'select', subscript: 'PI', row: 2, col: 2,
		options: { value: 'ROLE_ID', label: 'PI_NAME', entity: 'REUSE.CHECKED_OUT_BY_PI_VW', order: 'PI_NAME' }},
	{ Header: 'State', accessor: 'CHEM_STATE', type: 'checklist', row: 3, col: 0,
		options: [{ value: 'liquid', label: 'Liquid' },{ value: 'solid', label: 'Solid' },{ value: 'gas', label: 'Gas' }]},
	{ Header: 'Date Shelved From', accessor: 'DATE_SHELVED_FROM', type: 'date', row: 3, col: 1 },
	{ Header: 'Date Shelved To', accessor: 'DATE_SHELVED_TO', type: 'date', row: 3, col: 2 },
	{ Header: 'Modified', accessor: 'MODIF_DATE', type: 'select', row: 4, col: 0,
		options: [
			{ value: '', label: 'Select...' },
			{ value: new Date(new Date().setDate(new Date().getDate() - 2)).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }), label: 'Past 24 hours' },
			{ value: new Date(new Date().setDate(new Date().getDate() - 8)).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }), label: 'Past 7 days' },
			{ value: new Date(new Date().setDate(new Date().getDate() - 31)).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }), label: 'Past 30 days' },
			{ value: new Date(new Date().setDate(new Date().getDate() - 181)).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }), label: 'Past 6 months' },
			{ value: new Date(new Date().setDate(new Date().getDate() - 366)).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }), label: 'Past year' },
	]},
	{ Header: 'Class', accessor: 'CHEM_CLASS', type: 'list', row: 4, col: 1,
		options: { value: 'CLASS_ID', label: 'CLASS_NAME', entity: 'REUSE.CHEM_CLASS', order: 'CLASS_ID', 
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Brand', accessor: 'BRAND_ID', type: 'list', row: 5, col: 0,
		options: { value: 'BRAND_ID', label: 'BRAND_NAME', entity: 'REUSE.BRAND_SELECT_VW', order: ['SEPARATOR','SHOW_PREF','BRAND_NAME_UCASE']}},
	{ Header: 'Segregation', accessor: 'SEG_ID', type: 'list', row: 5, col: 1,
		options: { value: 'SEG_ID', label: 'SEG_CODE', entity: 'REUSE.CHEM_SEGREGATION', 
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Location', accessor: 'STORAGE_ID', type: 'list', row: 5, col: 2,
		options: { value: 'STORAGE_ID', label: 'STORAGE_NAME', entity: 'REUSE.STORAGE', 
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Form', accessor: 'FORM_ID', type: 'list', row: 6, col: 0,
		options: { value: 'FORM_ID', label: 'FORM_NAME', entity: 'REUSE.CHEM_FORM', 
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Grade', accessor: 'GRADE_ID', type: 'list', row: 6, col: 1,
		options: { value: 'GRADE_ID', label: 'GRADE_NAME', entity: 'REUSE.GRADE_LIST', 
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Container Code', accessor: 'CONTAINER_ID', type: 'list', row: 6, col: 2,
		options: { value: 'CONT_ID', label: 'CONT_CODE', entity: 'REUSE.STORAGE_CONTAINER', 
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Other Notes', accessor: 'OTHER_NOTES', type: 'textarea', row: 7, col: 0 },
	{ Header: 'Notes', accessor: 'COMMENT_ID', type: 'select', row: 7, col: 1,
		options: { value: 'COMMENT_ID', label: 'NOTE_ELEMENT', entity: 'REUSE.CHEM_COMMENT', order: 'NOTE_ORDER',
			search: { Operator: 'and', Children: [
				{ Attribute: 'NOTE_ID', Operator: '=', LongValue: '1' },
				{ Attribute: 'DELETE_FLAG', Operator: 'is null' }
	]}}}
];

const bottleList = {
	ajax: { 
		url: '/Reuse/BottleSearch',
		data: null,
		accessor: 'Data'
	},
	hold: true,
	pageSize: 20,
	columns: [	
		{ Header: '', accessor: 'ITEM_ID', show: false },
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addBottle()} />, accessor: 'id', width: 25, 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editBottle(props.row.values.ITEM_ID)} /> },	
		{ Header: 'Bottle ID', accessor: 'BOTTLE_ID',
			Cell: props => <HashLink onClick={() => props.parent.viewBottle(props.row.values.ITEM_ID)}>{props.value}</HashLink> },
		{ Header: 'Name', accessor: 'CHEM_NAME' },
		{ Header: 'CAS', accessor: 'CAS_NUMBER' },
		{ Header: 'Amount', accessor: 'AMOUNT' },
		{ Header: 'Brand', accessor: 'BRAND_NAME' },
		{ Header: 'Status', accessor: 'STORAGE_STATUS' },
		{ Header: 'Pur.', accessor: 'PURITY' },
		{ Header: 'Shelf Code', accessor: 'SHELF_CODE' },
		{ Header: 'Form', accessor: 'FORM_NAME' },
		{ Header: 'Class', accessor: 'CLASS_NAME' },
		{ Header: 'Shelved', accessor: 'DATE_OUT' },
		{ Header: 'User', accessor: 'OUT_PERSON' },
		{ Header: 'PI', accessor: 'PI_NAME' }
	]
};

const exportColumns = [
	{ Header: 'Bottle ID', accessor: 'BOTTLE_ID' },
	{ Header: 'Name', accessor: 'CHEM_NAME' },
	{ Header: 'CAS', accessor: 'CAS_NUMBER' },
	{ Header: 'Amount', accessor: 'AMOUNT' },
	{ Header: 'Brand', accessor: 'BRAND_NAME' },
	{ Header: 'Status', accessor: 'STORAGE_STATUS' },
	{ Header: 'Pur.', accessor: 'PURITY' },
	{ Header: 'Shelf Code', accessor: 'SHELF_CODE' },
	{ Header: 'Form', accessor: 'FORM_NAME' },
	{ Header: 'Class', accessor: 'CLASS_NAME' },
	{ Header: 'Shelved', accessor: 'DATE_OUT' },
	{ Header: 'User', accessor: 'OUT_PERSON' },
	{ Header: 'PI', accessor: 'PI_NAME' }
];

export default withRouter(Bottles);
