import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row, OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import ChemComponent from '../../ChemComponent';
import QueryTable from '../../QueryTable';
import { Link } from 'react-router-dom';

class RoomReport extends ChemComponent {
  	constructor(props) {
		super(props);
		
		this.state = {
			renderKey: 1,
			searchCount: null
		};
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
		
	render() {		
	
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Room Report',
			columns: exportColumns,
			entity: 'ROOM_REPORT_VW',
			order: ['DEPT_NAME', 'BUILDING_NAME', 'ROOM_NAME']
		});			

		return (<>
			<Container fluid>
				<Row style={{ paddingTop: '20px', paddingBottom: '20px', marginBottom: '20px', border: '1px solid #ccc',
					borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<Col xs={2}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Room Report</span>
					</Col>
					<Col xs={2} style={{ marginTop: 2}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					</Col>
					<Col xs={1}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					</Col>
					<Col xs={1}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					</Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_RoomReport' table={roomList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='100px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const roomList = {
	query: { entity: 'ROOM_REPORT_VW', order: ['DEPT_NAME','BUILDING_NAME','ROOM_NAME'] },
	pageSize: 10,
	columns: [
		{ Header: 'ID', accessor: 'ROOM_ID' },
		{ Header: 'Department', accessor: 'DEPT_NAME' },
		{ Header: 'Building', accessor: 'BUILDING_NAME' },
		{ Header: 'Level', accessor: 'ROOM_LEVEL' },
		{ Header: 'Room Num', accessor: 'ROOM_NUMBER' },
		{ Header: 'Room Name', accessor: 'ROOM_NAME', 
			Cell: props => <Link to={'/BuildingAccess/Rooms/' + props.row.values.ROOM_ID}>{props.value}</Link> },
		{ Header: 'Phone', accessor: 'ROOM_PHONE' },
		{ Header: 'Contact', accessor: 'CONTACT' },
		{ Header: 'SQ Foot', accessor: 'SQUARE_FOOTAGE' },
		{ Header: 'Room Funct.', accessor: 'ROOM_FUNCTION' },
		{ Header: 'Person', accessor: 'PERSON_NAME' },
		{ Header: 'Title', accessor: 'TYPE_NAME' },
		{ Header: 'PI', accessor: 'PI_NAME' }
	]
};

const exportColumns = [
		{ Header: 'ID', accessor: 'ROOM_ID', width: 10 },
		{ Header: 'Department', accessor: 'DEPT_NAME', width: 20 },
		{ Header: 'Building', accessor: 'BUILDING_NAME', width: 20 },
		{ Header: 'Level', accessor: 'ROOM_LEVEL', width: 10 },
		{ Header: 'Room Num', accessor: 'ROOM_NUMBER', width: 10 },
		{ Header: 'Room Name', accessor: 'ROOM_NAME', width: 10 },
		{ Header: 'Phone', accessor: 'ROOM_PHONE', width: 20 },
		{ Header: 'Contact', accessor: 'CONTACT', width: 20 },
		{ Header: 'SQ Foot', accessor: 'SQUARE_FOOTAGE', width: 10 },
		{ Header: 'Room Funct.', accessor: 'ROOM_FUNCTION', width: 20 },
		{ Header: 'Person', accessor: 'PERSON_NAME', width: 20 },
		{ Header: 'Title', accessor: 'TYPE_NAME', width: 20 },
		{ Header: 'PI', accessor: 'PI_NAME', width: 20 }
];


export default RoomReport;