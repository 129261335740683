import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';

class Links extends ChemComponent
{
	constructor(props) {
		super(props);
		
		this.state = {
			Contacts: [],
			renderKey: 1
		};
	}
	
	componentDidMount() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/GetServiceContactData',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken }
		}).done(function (data) {
			if (data.Success) {
				self.mergeState({
					Contacts: data.Contacts,
					renderKey: self.state.renderKey + 1
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	render() {
		return(
			<Container>
				<Row>
					<Col xs={12} style={{ paddingTop: '20px', paddingBottom: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}> 

<div id="contentInside" align="center">
  <h2>Contacts</h2>
  <div align="left">
  <ul>
  <li className="labelcell_black" ><strong>For CRS online technical issues such as error messages, access or display problems, please
  contact: <a href="jborland@berkeley.edu">jborland@berkeley.edu</a> (John Borland)</strong></li><br />
  <li className="labelcell_black">
    <strong>For issues regarding the recharge process, please
  contact: <cfoutput><a href="chemrecharge@berkeley.edu">chemrecharge@berkeley.edu</a></cfoutput></strong></li><br />
  
  <li className="labelcell_black"><strong>Service/Facility Contacts</strong></li>
  {this.props.user.crs.userAccessID === 1 && <span style={{ fontSize: '12px' }}><i>Click on the service name to open service page</i></span>}</ul>

<table id="alumni_form">
  <tbody>
    {this.state.Contacts.map((contact, contactIdx) => {
		return (<React.Fragment key={contactIdx}>
			<tr>
			  <td colspan="6" style={styles.labelcellSubHeadLeft}>{contact.UnitName}</td>
			</tr>
			<tr>
			  <td style={styles.labelcellLeft}>Service Name</td>
			  <td style={styles.labelcellLeft}>Location</td>
			  <td style={styles.labelcellLeft}>Phone</td>
			  <td style={styles.labelcellLeft}>email</td>
			  <td style={styles.labelcellLeft}>url</td>
			  <td style={styles.labelcellLeft}>Contact(s)</td>
			</tr>
			<tr>
				<td style={styles.fieldcellposting}>{this.props.user.crs.userAccessID === 1 ? <>
					{contact.Names.map((svcname, svcnameIdx) => {
						return (<React.Fragment key={svcnameIdx}><a href={'/CRS/Services/' + svcname.ID}>{svcname.Name}</a><br /></React.Fragment>);
					})}</> :
					<>{contact.Names.map((svcname, svcnameIdx) => {
						return (<React.Fragment key={svcnameIdx}>{svcname.Name}<br /></React.Fragment>);
					})}</>
				}</td>
				<td style={styles.fieldcellposting}>{contact.Locations.map((loc, locIdx) => {
					return (<React.Fragment key={locIdx}>{loc}<br /></React.Fragment>);
				})}</td>
				<td style={styles.fieldcellpostingNoWrap}>{contact.Phones.map((phone, phoneIdx) => {
					return (<React.Fragment key={phoneIdx}>{phone}<br /></React.Fragment>);
				})}</td>
				<td style={styles.fieldcellposting}>{contact.Emails.map((email, emailIdx) => {
					return (<React.Fragment key={emailIdx}>{email}<br /></React.Fragment>);
				})}</td>
				<td style={styles.fieldcellposting}>{contact.URLs.map((url, urlIdx) => {
					return (<React.Fragment key={urlIdx}><a href={url} target='_blank' rel="noreferrer">{url}</a><br /></React.Fragment>);
				})}</td>
				<td style={styles.fieldcellposting}>{contact.Contacts.map((contact, contactIdx) => {
					return (<React.Fragment key={contactIdx}>{contact}<br /></React.Fragment>);
				})}</td>
			</tr>
		</React.Fragment>);  
	})}
 </tbody>
</table>
  </div> 
</div>
					</Col>
				</Row>
			</Container>
		);
	}
}

const styles = {
	fieldcellposting: {
		border: '1px solid #D7E5F2',
		paddingLeft: '4px',
		paddingTop: '2px',
		paddingBottom: '2px',
		verticalAlign: 'top',
		font: '12px Verdana, Geneva, Arial, Helvetica, sans-serif',
		backgroundColor: '#F2F7FB',
		color: '#102132',
		textAlign: 'left'
	},
	fieldcellpostingNoWrap: {
		border: '1px solid #D7E5F2',
		paddingLeft: '4px',
		paddingTop: '2px',
		paddingBottom: '2px',
		verticalAlign: 'top',
		font: '12px Verdana, Geneva, Arial, Helvetica, sans-serif',
		backgroundColor: '#F2F7FB',
		color: '#102132',
		textAlign: 'left',
		whiteSpace: 'nowrap'
	},
	labelcellLeft: {
		border: '1px solid #D7E5F2',
		paddingLeft: '4px',
		paddingTop: '2px',
		paddingBottom: '2px',
		verticalAlign: 'top',
		font: '12px Verdana, Geneva, Arial, Helvetica, sans-serif',
		color: '#3670A7',
		backgroundColor: '#F9FBFD',
		textAlign: 'left',
	},
	labelcellSubHeadLeft: {
		border: '1px solid #D7E5F2',
		paddingLeft: '4px',
		paddingTop: '2px',
		paddingBottom: '2px',
		verticalAlign: 'top',
		font: '12px Verdana, Geneva, Arial, Helvetica, sans-serif',
		color: '#3670A7',
		backgroundColor: '#F9FBFD',
		textAlign: 'left',
		fontWeight: 'bold'
	}
};

export default Links;