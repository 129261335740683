import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemHeader from '../ChemHeader';
import TreeMenu from '../TreeMenu';
import Speedtype from './Speedtype';

class Test extends ChemComponent {
  	constructor(props) {
		super(props);

		// copy menu items so we can edit
		var stateTreeMenuItems = JSON.parse(JSON.stringify(treeMenuItems));
		
		this.state = {
			fullScreen: false,
			treeMenuItems: stateTreeMenuItems,
			confirm: ''
		};
	}
	
	render() {
		return (<>
			<ChemHeader parent={this} user={this.props.user} title='Chemistry Recharge System' fullScreen={this.state.fullScreen} 
				toggleFullScreen={(fullScreen) => this.mergeState({ fullScreen: fullScreen })} showHelp={() => alert('Not Implemented')} />
			<Container fluid style={{ paddingTop: 20, paddingBottom: 20, backgroundColor: '#e6e6e4', height: 'calc(100vh - 64px)', overflow: 'auto' }}>
				<Row>
					{!this.state.fullScreen && <Col xs={2}>
						<TreeMenu parent={this} menuItems={this.state.treeMenuItems} selectedRoute={this.props.location.pathname} />
					</Col>}
					<Col xs={this.state.fullScreen ? 12 : 10}>
						<Routes>
							<Route path={'/speedtype'} element={<Speedtype parent={this} user={this.props.user} />} />
						</Routes>
					</Col>
				</Row>
			</Container>
		</>);
	}
}

// routes defined here so there are no accidents when removing them!
const routes = {
	speedtype: '/Test/Speedtype'
};

const treeMenuItems = [
	{
		route: routes.speedtype,
		label: 'New Speedtype'
	}
];

export default withRouter(Test);