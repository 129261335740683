import React from 'react';
import { Buffer } from 'buffer';
import Form from 'react-bootstrap/Form';
import ChemComponent from '../ChemComponent';
import { withItemDefaultRoute } from './withItemDefaultRoute';
import ChemEdit from '../ChemEdit';
import ChemTable from '../ChemTable';
import EditTable from '../EditTable';
import QueryTable from '../QueryTable';
import Tabs from '../Tabs';
import { Col, Container, Row, Button, OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import { LockFill, UnlockFill, HourglassSplit, CaretLeftFill, CaretRightFill } from 'react-bootstrap-icons';
import HashLink from '../HashLink';
import { Link } from 'react-router-dom';

class Item extends ChemComponent {
	constructor(props) {
		super(props);

		this.state = {
			search: {
				STOCK_NUMBER: '',
				STOCK_NUMBER_LIKE: ''
			},
			editable: props.editable,
			activeTab: props.params.activeTab || 'Details',
			item: {
				any_sales_marked: false,
				inventory2: {
					ITEM_ID: ''
				},
				inventory_adjust: [],
				item_notes: {
					TEXT_NOTES: ''
				},
				item_documents: [],
			},
			salesList: this.copyTable(salesList),
			salesCount: 0,
			detailColumns: this.copyColumns(detailColumns),
			editorsOpen: [],
			renderKey: 0,
			unsavedChanges: false
		}
		
		this.notesEditorRef = React.createRef();
	}

	componentDidMount() {
		this.loadItem();
	}
	
	componentDidUpdate(prevProps) {
		var newActiveTab = this.props.params.activeTab || 'Details';
		
		// this is necessary so browser back/forward buttons will work across tabs
		if (newActiveTab !== this.state.activeTab) {
			this.mergeState({
				activeTab: newActiveTab,
				renderKey: this.state.renderKey + 1
			});
		}
		// also reload the item if the item ID has changed
		if (this.props.params.itemId !== prevProps.params.itemId) {
			this.loadItem();
		}
	}

	loadItem(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Stores/GetItem',
			overlay: true,
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				itemid: this.props.params.itemId
			}
		}).done(function (data) {
			if (data.Success) {
				var item = data.Data;
				if (!self.props.params.itemId) item.inventory2.ITEM_ID = '(new)';
				
				self.mvc2js(item, detailColumns);
				
				// compose read-only data
				item.inventory2.MODIFIED = self.composeChangeInfo(item.inventory2.MODIF_BY, item.inventory2.LAST_MODIF);
				var dtInventoryDate = self.getDate(item.inventory2.INVENTORY_DATE);
				item.inventory2.LAST_INVENTORY = item.inventory2.BEG_BALANCE + 
					(dtInventoryDate && (' on ' + dtInventoryDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' })));
				if (item.last_order.BACK_ORDER && +item.last_order.BACK_ORDER > 0) {
					item.last_order.REC_QTY_BACK_ORDER = item.last_order.REC_QTY + ' (on order: ' + item.last_order.BACK_ORDER + ')';
				} else {
					item.last_order.REC_QTY_BACK_ORDER = item.last_order.REC_QTY;
				}
				
				// initialize adjust and transfer
				item.adjust = {
					NEW_QTY: '',
					ADJUST_TYPE: null,
					FISCAL_INV: false,
					COMMENTS: ''
				};
				item.transfer = {
					TRANSFER_QTY: '',
					NEW_ITEM: '',
					QTY_IN: ''
				};
				item.new_pricing = {
					NEW_PRICE: item.inventory2.UNIT_COST,
					COMMENTS: ''
				};
				
				var newSalesList = self.copyTable(salesList);
				if (self.props.params.itemId) {
					newSalesList.query.search = { Attribute: 'ITEM_ID', Operator: '=', LongValue: self.props.params.itemId };
					newSalesList.hold = false;
				}
				
				self.mergeState({
					search: {
						STOCK_NUMBER: '',
						STOCK_NUMBER_LIKE: ''
					},
					item: item,
					salesList: newSalesList,
					salesCount: null,
					displayEditor: true,
					renderKey: self.state.renderKey + 1,
					unsavedChanges: false
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}

	onEdit(accessor, editorIsOpen) {
		this.mergeState({
			editorsOpen: this.setEditorStatus(accessor, editorIsOpen),
			renderKey: this.state.renderKey + 1
		});
	}
	
	setEditorStatus(accessor, editorIsOpen) {
		var editorsOpen = this.copyObject(this.state.editorsOpen);
		if (editorIsOpen) {
			// add to list if it isn't already present
			if (!editorsOpen.includes(accessor)) editorsOpen.push(accessor);
		} else {			
			// remove from list if it is present
			if (editorsOpen.includes(accessor)) editorsOpen.splice(editorsOpen.indexOf(accessor), 1);
		}
		return editorsOpen;
	}

	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		// add %s to values that will be used in 'like' expressions
		if (accessor === 'STOCK_NUMBER') {
			this.setByAccessor(search, 'STOCK_NUMBER_LIKE', '%' + value.toUpperCase() + '%');
		}
		this.mergeState({ search: search, renderKey: this.state.renderKey + 1 });	
	}

	findStock(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Stores/GetItemID',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, stockno: this.state.search.STOCK_NUMBER }
		}).done(function (data) {
			if (data.Success) {
				self.props.navigate('/Stores/Catalog/' + data.Data.ITEM_ID);
			} else {
				self.showAlert(data.Message.endsWith('found 0') ? 'Stock Number Not Found' : 'Multiple Matches Found', data.Message);
				/* none or multiple found; go to catalog search
				self.props.onCatalogSearch({
					CATALOG_NUMBER: '',
					VENDOR_NAME: '',
					STOCK_NUMBER: self.state.search.STOCK_NUMBER,
					CATEGORY: [],
					DESCRIPTION: '',
					LOCATION: '',
					LAST_MODIF: null
				});
				*/
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}

	onChange(accessor, value) {
		var item = this.copyObject(this.state.item);
		if (Array.isArray(accessor)) {
			for (var i = 0; i < accessor.length; i++) {
				this.setByAccessor(item, accessor[i], value[i]);
			}
		} else {
			this.setByAccessor(item, accessor, value);
		}
		var newState = { 
			item: item, 
			renderKey: this.state.renderKey + 1, 
			unsavedChanges: true
		};
		// if a new price has been set
		if (accessor === 'new_pricing.NEW_PRICE') {
			var new_price = value;
			var old_price = this.state.item.inventory2.UNIT_COST;
			var price_has_changed = false;
			if (this.isNumeric(new_price)) {
				if (this.isNumeric(old_price)) {
					price_has_changed = +new_price !== +old_price;
				} else {
					price_has_changed = true;
				}
			} else {
				if (this.isNumeric(old_price)) {
					price_has_changed = true;
				} else {
					price_has_changed = new_price !== old_price;
				}
			}

			var newDetailColumns = this.copyColumns(detailColumns);
			var commentIdx = this.findColumnByAccessor(newDetailColumns, 'new_pricing.COMMENTS');
			if (commentIdx >= 0) newDetailColumns[commentIdx].editable = price_has_changed;
			newState.detailColumns = newDetailColumns;
		}
		// if this accessor represents an EditTable
		if (['item_documents','vendors'].includes(accessor)) {
			// update editor status as well
			newState.editorsOpen = this.setEditorStatus(accessor, false);
		}
		this.mergeState(newState);
	}

	validateItem() {
		var msgs = [];
		// if there is a new price comment, there needs to be a new price
		if (!this.isEmpty(this.state.item.new_pricing.COMMENTS) && this.isEmpty(this.state.item.new_pricing.NEW_PRICE)) {
			msgs.push('New price cannot be empty once a comment has been entered');
		}
		
		return msgs.length > 0 ? msgs.join('; ') : null;
	}
		
	onSubmit(event) {
		var i;
		var self = this;		
		
		var msgs = this.validateItem();
		if (msgs) {
			this.showAlert('Validation Error', msgs);
		} else {
		
			// make copy of data
			var item = this.copyObject(this.state.item);

			// convert dates, checkboxes
			this.js2mvc(item, detailColumns);
			
			// encode html
			item.item_notes.TEXT_NOTES = item.item_notes.TEXT_NOTES ? Buffer.from(item.item_notes.TEXT_NOTES).toString('base64') : '';
						
			// if this is a new item, set ID to sequence name
			if (!this.props.params.itemId) {
				item.inventory2.ITEM_ID = 'item_id_seq.nextval';
			}

			// Create an object of formData
			const formData = new FormData();

			// add the token to the formData
			formData.append('__RequestVerificationToken', this.props.user.antiForgeryToken);
			
			// add the item to the formData
			var nvpairs = this.createHiddenFormData({ item: item });
			for (i = 0; i < nvpairs.length; i++) {
				formData.append(nvpairs[i].name, nvpairs[i].value);
			}
			
			// for each document in the original document table
			for (i = 0; i < this.state.item.item_documents.length; i++) {
				// if there is a file
				if (this.state.item.item_documents[i].UPLOADED && this.state.item.item_documents[i].UPLOADED.name) {
					// add the file information
					formData.append('files[' + i + ']', this.state.item.item_documents[i].UPLOADED, this.state.item.item_documents[i].NOTE_TITLE);
				}
			}			

			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/Stores/UpdateItem',
				data: formData,
				contentType: false,
				processData: false,
				overlay: true,
			}).done(function (data) {
				if (data.Success) {
					var itemId = data.ID;
					var verb = self.props.params.itemId ? 'updated' : 'inserted';
					self.props.parent.showConfirmation(() => {
						return (<>
							Item <Link to={'/Stores/Catalog/' + itemId}>{itemId}</Link> {verb}.
						</>);
					});
				} else {
					self.showAlert('Server Error', data.Message);
				}
			}).fail(function (jqXHR, textStatus, errorThrown) {
				if (jqXHR && jqXHR.status === 500 && jqXHR.responseText &&
					jqXHR.responseText.indexOf('Maximum request length exceeded.') >= 0) {
					self.showAlert('Files too large', 'File(s) are too large to be uploaded. ' +
						'For further assistance please contact John Borland at jborland@berkeley.edu');
				} else {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				}
			});
		}
	}

	onAdjust(event) {
		var self = this;

		if (this.state.unsavedChanges) {
			if (this.showOKCancel('Unsaved Changes', 'Changes you made to this item will be lost. Proceed?', (okClicked) => {
				if (okClicked) self.onAdjustProceed(event);
			}));
		} else {
			// no unsaved changes
			this.onAdjustProceed(event);
		}
	}
	
	onAdjustProceed(event) {
		var self = this;
		
		// make copy of data
		var item = this.copyObject(this.state.item);

		// convert dates, checkboxes
		this.js2mvc(item, adjustColumns);

		// add item ID to adjust object so we don't have to send everything
		item.adjust.ITEM_ID = item.inventory2.ITEM_ID;
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/Stores/AdjustInventory',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, adjust: item.adjust }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Item <Link to={'/Stores/Catalog/' + item.adjust.ITEM_ID}>{item.adjust.ITEM_ID}</Link> adjusted.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	onTransfer(event) {
		var self = this;		
		
		if (this.state.unsavedChanges) {
			if (this.showOKCancel('Unsaved Changes', 'Changes you made to this item will be lost. Proceed?', (okClicked) => {
				if (okClicked) self.onTransferProceed(event);
			}));
		} else {
			// no unsaved changes
			this.onTransferProceed(event);
		}
	}
	
	onTransferProceed(event) {
		var self = this;

		// make copy of data
		var item = this.copyObject(this.state.item);

		// convert dates, checkboxes
		this.js2mvc(item, transferColumns);

		// add item ID to transfer object so we don't have to send everything
		item.transfer.ITEM_ID = item.inventory2.ITEM_ID;
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/Stores/TransferInventory',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, transfer: item.transfer }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Item <Link to={'/Stores/Catalog/' + item.transfer.ITEM_ID}>{item.transfer.ITEM_ID}</Link> adjusted.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	onClickTabItem(tab) {
		this.props.navigate('/Stores/Catalog/' + (this.props.params.itemId || 'new') + '/' + tab);
	}
	
	viewPO(poId) {
		this.props.navigate('/Stores/POs/' + poId);
	}
	
	viewSale(saleId) {
		this.props.navigate('/Stores/Sales/' + saleId);
	}

	canClickTabItem(tab) {
		/* prevent user from leaving a tab if an editor is open
		if (this.state.activeTab === 'Student' && this.state.editorsOpen.includes('research_director')) {
			this.showAlert('Editor Open', 'Please close the RA/PI editor before leaving the Student tab.');
			return false;
		} else if (this.state.activeTab === 'Education' && this.state.editorsOpen.includes('app_education')) {
			this.showAlert('Editor Open', 'Please close the institution editor before leaving the Education tab.');
			return false;
		} else if (this.state.activeTab === 'Financial Notes' && this.state.editorsOpen.includes('financial_notes')) {
			this.showAlert('Editor Open', 'Please close the financial note editor before leaving the Financial Notes tab.');
			return false;
		}
		*/
		return true;
	}
	
	onClickBookmarks() {
		if (this.state.item.any_sales_marked) {
			if (this.showOKCancel('Confirm Uncheck All', 'Uncheck all sales bookmarks?', (okClicked) => {
				if (okClicked) this.checkAllBookmarks(false);
			}));
		} else {
			if (this.showOKCancel('Confirm Check All', 'Check all sales bookmarks?', (okClicked) => {
				if (okClicked) this.checkAllBookmarks(true);
			}));
		}
	}
	
	checkAllBookmarks(checked) {
		var self = this;

		// send change to server
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/Stores/UpdateBookmarks',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, itemid: this.state.item.inventory2.ITEM_ID, check: checked ? 'Y' : null }
		}).done(function (data) {
			if (data.Success) {
				// redraw grid
				self.mergeState({ 
					item: { any_sales_marked: data.AnySalesMarked },
					renderKey: self.state.renderKey + 1
				});
			} else {
				// show alert
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			// show alert
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	onClickBookmark(saleId, lineId, checked) {
		var self = this;
		
		// send change to server
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/Stores/UpdateBookmark',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, saleid: saleId, lineid: lineId, check: checked ? 'Y' : null }
		}).done(function (data) {
			if (data.Success) {
				// redraw grid
				self.mergeState({
					item: { any_sales_marked: data.AnySalesMarked },					
					renderKey: self.state.renderKey + 1
				});
			} else {
				// show alert
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {			
			// show alert
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}

	renderBarcodeForm(itemId) {
		
		var barcodeData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			itemid: itemId
		});
		
		return (
			<form action={this.getConfig().host + '/Stores/Barcode'} method='POST'>
				{barcodeData.map((pdfFormField, index) => {
					return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
				})}
				<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Print Barcode</Popover.Header></Popover>}>
					<input type='image' src={this.getConfig().host + '/Content/Icons/upc.png'} alt="Print Barcode" />
				</OverlayTrigger>
			</form>
		);
	}

	render() {	
	
		var exportSalesData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Sales for Stock Number ' + this.state.item.inventory2.STOCK_NO,
			columns: this.convertWidthsToExcel(this.removeColumnsByAccessor(salesList.columns, 'BOOKMARK')),
			entity: 'STORES.ITEM_SALES_VW',
			search: { Attribute: 'ITEM_ID', Operator: '=', LongValue: this.props.params.itemId }
		});
		
		var optionalDisabledProp = {};
		if (!this.state.editable) optionalDisabledProp.disabled = 'disabled';
		
		return (<>
			<Container fluid style={{ border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
			  <Row>
			    <Col xs={5} style={{ paddingTop: '20px' }}>
				  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Item {this.state.item.inventory2.ITEM_ID}&nbsp;&nbsp;
					{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
					  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}
					{this.props.params.itemId && <>
						{this.state.item.inventory2.PREV_ITEM_ID && <Button style={{ marginLeft: '20px', marginRight: '20px' }} id="previous" variant="warning" type="button"
							onClick={(event) => this.props.navigate('/Stores/Catalog/' + this.state.item.inventory2.PREV_ITEM_ID + '/' + this.state.activeTab)}><CaretLeftFill />Previous</Button>}
						{this.state.item.inventory2.STOCK_NO}
						{this.state.item.inventory2.NEXT_ITEM_ID && <Button style={{ marginLeft: '20px' }} id="next" variant="warning" type="button"
							onClick={(event) => this.props.navigate('/Stores/Catalog/' + this.state.item.inventory2.NEXT_ITEM_ID + '/' + this.state.activeTab)}>Next<CaretRightFill /></Button>}
					</>}
				  </div>
				</Col>
				<Col xs={5} style={{ paddingTop: '12px' }}>
					<ChemEdit parent={this} user={this.props.user} columns={stockSearch} data={this.state.search} labelColWidth={8} valueColWidth={4}
					  hideLines={true} renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)}
					  autoSubmit={true} onSubmit={(event) => this.findStock(event)} />
				</Col>
				<Col xs={2} style={{ paddingTop: '17px' }}>
				  <Button id="find" variant="warning" type="button" onClick={(event) => this.findStock(event)}>Find Item</Button>
				</Col>
			  </Row>
			  <Row>
			    <Col>
				  <Tabs activeTab={this.state.activeTab} canClickTabItem={(tab) => this.canClickTabItem(tab)} onClickTabItem={(tab) => this.onClickTabItem(tab)} padding={'1rem 2rem'}>
				    <div label='Details'>
					  <Container fluid>
						<Row>
						  <Col>
							<h5>Item Details</h5>
							<ChemEdit parent={this} columns={this.state.detailColumns} data={this.state.item} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />
						  </Col>
						  <Col>
							<h5>Physical Inventory</h5>
							Click on the Inventory tab to update qty in stock
							<ChemEdit parent={this} columns={detailInventoryColumns} data={this.state.item} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />
						  </Col>
						  <Col>
							<h5>Last Received Order</h5>
							Click on the POs tab to view more information
							<ChemEdit parent={this} columns={lastOrderColumns} data={this.state.item} user={this.props.user} editable={false}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Inventory'>
					  <Container fluid>
						<Row>
						  <Col>
							<ChemEdit parent={this} columns={adjustColumns} data={this.state.item} user={this.props.user} editable={true}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />
						  </Col>
						</Row>
						<Row>
						  <Col>
							<ChemEdit parent={this} columns={adjustComments} data={this.state.item} user={this.props.user} editable={true}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)}
								labelColWidth={2} valueColWidth={10} />
						  </Col>
						</Row>
						<Row>
						  <Col style={{ paddingTop: '20px', paddingBottom: '20px' }}>
							<Button id="adjust" variant="warning" type="submit" className="float-end" onClick={(event) => this.onAdjust(event)}>Adjust</Button>
						  </Col>
						</Row>
						<Row>
						  <Col>
							<ChemEdit parent={this} columns={transferColumns} data={this.state.item} user={this.props.user} editable={true}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />
						  </Col>
						</Row>
						<Row>
						  <Col style={{ paddingTop: '20px', paddingBottom: '20px' }}>
							<Button id="transfer" variant="warning" type="submit" className="float-end" onClick={(event) => this.onTransfer(event)}>Transfer</Button>
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Notes'>
					  <Container fluid>
						<Row>
						  <Col style={{ paddingBottom: '20px' }}>
						    <textarea id='notesEditor' value={this.state.item.item_notes.TEXT_NOTES} cols={80} rows={5}
								onChange={(event) => this.onChange('item_notes.TEXT_NOTES', event.target.value)} {...optionalDisabledProp} />
						  </Col>
						</Row>
						<Row>
						  <Col>
						    <h5>Documents</h5>
						  	<EditTable parent={this} user={this.props.user} renderKey={this.state.renderKey} newRecord={emptyDocument} data={this.state.item.item_documents} editable={this.state.editable}
								tableColumns={documentTableColumns} editColumns={documentEditColumns} onEdit={(editorIsOpen) => this.onEdit('item_documents', editorIsOpen)}
								onChange={(data, callback) => this.onChange('item_documents', data)} tableName='Stores_Item_Documents' />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Pricing'>
					  <Container fluid>
						<Row>
						  <Col>
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='POs'>
						<Container fluid>
						  <Row>
							<Col>
							</Col>								  
						  </Row>
						</Container>
					</div>
					<div label='Sales'>
					  <Container fluid>
					    <Row>
						  <Col xs={2} style={{ marginTop: 8}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportSalesData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
								</OverlayTrigger>
							</form>
							{this.isEmpty(this.state.salesCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.salesCount} records found.
						  </Col>
						  <Col xs={10}>
							<ChemEdit parent={this} columns={salesTotalColumns} data={this.state.item} user={this.props.user} editable={false}
								renderKey={this.state.renderKey} />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Vendor'>
					  <Container fluid>
						<Row>
						  <Col>
						  	<EditTable parent={this} user={this.props.user} renderKey={this.state.renderKey} newRecord={emptyVendor} data={this.state.item.vendors} editable={this.state.editable}
								tableColumns={vendorTableColumns} editColumns={vendorEditColumns} onEdit={(editorIsOpen) => this.onEdit('vendor', editorIsOpen)}
								onChange={(data, callback) => this.onChange('vendors', data)} tableName='Stores_Item_Vendors' />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Name'>
					  <Container fluid>
						<Row>
						  <Col>
						  </Col>
						</Row>
					  </Container>
					</div>
				  </Tabs>
				</Col>
			  </Row>
			  <Row>
				<Col style={{ paddingBottom: '20px' }}>
					{this.state.editable && <>
						<Button id="save" variant="warning" type="submit" className="float-end" onClick={(event) => this.onSubmit(event)}>Save</Button>
						{this.state.activeTab === 'Inventory' && <span className="float-end" style={{ fontStyle: 'italic', paddingTop: '6px', paddingRight: '10px' }}>
							Please save any changes to the item before initiating an adjustment or transfer</span>}
						{this.state.activeTab === 'Pricing' && <span className="float-end" style={{ fontStyle: 'italic', paddingTop: '6px', paddingRight: '10px' }}>
							Price can be adjusted on the Details tab</span>}
						{this.state.activeTab === 'Name' && <span className="float-end" style={{ fontStyle: 'italic', paddingTop: '6px', paddingRight: '10px' }}>
							Stock Number can be changed on the Details tab</span>}
					</>}
				</Col>
			  </Row>
			</Container>
			{this.state.activeTab === 'Inventory' && <Container fluid>
			  <Row>
				<Col>
				  <ChemTable renderKey={this.state.renderKey} parent={this} data={this.state.item.inventory_adjust} columns={inventoryAdjustColumns} name='Stores_Item_InvAdjust' />
				</Col>
			  </Row>
			</Container>}
			{this.state.activeTab === 'Pricing' && <Container fluid>
			  <Row>
				<Col>
				  <ChemTable renderKey={this.state.renderKey} parent={this} data={this.state.item.price_history} columns={priceHistoryColumns} name='Stores_Item_PriceHistory' />
				</Col>
			  </Row>
			</Container>}
			{this.state.activeTab === 'POs' && <Container fluid>
			  <Row>
				<Col>
				  <ChemTable renderKey={this.state.renderKey} parent={this} data={this.state.item.purchase_order} columns={poColumns} name='Stores_Item_PurchaseOrders' />
				</Col>
			  </Row>
			</Container>}
			{this.state.activeTab === 'Sales' && <Container fluid>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='Stores_Item_Sales' table={this.state.salesList}
					onResult={(data) => this.mergeState({ salesCount: data.ResultCount })} offsetHeight='240px'	/>
				</Col>
			  </Row>
			</Container>}
			{this.state.activeTab === 'Name' && <Container fluid>
			  <Row>
				<Col>
				  <ChemTable renderKey={this.state.renderKey} parent={this} data={this.state.item.stock_num_history} columns={nameColumns} name='Stores_Item_Names' />
				</Col>
			  </Row>
			</Container>}
		</>);
	}
}

const stockSearch = [
	{ Header: 'Enter Next Stock Number', accessor: 'STOCK_NUMBER', type: 'dropdown',
		options: { value: 'STOCK_NO', label: 'STOCK_NO', entity: 'STORES.STOCK_NO_AUTOSUGGEST_VW', order: 'STOCK_NO', distinct: true,
			search: { Attribute: 'STOCK_NO_UCASE', Operator: 'like', StringAccessor: 'STOCK_NUMBER_LIKE' }
	}}
];

const detailColumns = [
	{ Header: 'Stock Number', accessor: 'inventory2.STOCK_NO' },
	{ Header: 'Current Price $', accessor: 'new_pricing.NEW_PRICE' },
	{ Header: 'New Price Comment', accessor: 'new_pricing.COMMENTS', editable: false },
	{ Header: 'Description', accessor: 'inventory2.DESCRIPTION' },
	{ Header: 'Unit', accessor: 'inventory2.UNIT_OF_MEASURE', type: 'select',
		options: { value: 'UOM_NAME', label: 'UOM_NAME', entity: 'STORES.UOM', 
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }
	}},
	{ Header: 'Location', accessor: 'inventory2.LOCATION' },
	{ Header: 'Category', accessor: 'inventory2.CATEGORY', type: 'select',
		options: { value: 'BFS_ACCOUNT', label: 'ACCOUNT_DESC', entity: 'STORES.CATEGORY_SELECT_VW' }
	},
	{ Header: 'Comments', accessor: 'inventory2.COMMENTS', type: 'textarea' },
	{ Header: 'Safety Item', accessor: 'inventory2.SAFETY_ITEM', type: 'checkbox', label: '(has to be stocked)' },
	{ Header: 'Restricted Item', accessor: 'inventory2.RESTRICTED', type: 'checkbox', label: '(need of PI authorization for purchase)' },
	{ Header: 'Retire Item', accessor: 'inventory2.RETIRE_FLAG', type: 'checkbox', label: '(retire from sale and re-order report)' },
	{ Header: 'Modified', accessor: 'inventory2.MODIFIED', editable: false }
];

const detailInventoryColumns = [
	{ Header: 'Barcode', accessor: 'inventory2.ITEM_ID',
		Cell: props => props.parent.renderBarcodeForm(props.value) },
	{ Header: 'Qty in Stock', accessor: 'inventory2.QTY', editable: false },
	{ Header: 'Qty on Order', accessor: 'inventory2.ORDER_QTY', editable: false },
	{ Header: 'Last Inventory', accessor: 'inventory2.LAST_INVENTORY', editable: false },
	{ Header: 'Qty Sold', accessor: 'inventory2.SOLD_QTY', editable: false, subscript: '(since inventory)' },
	{ Header: 'Last Qty Adjust.', accessor: 'inventory2.LAST_QTY_ADJUST', editable: false },
	{ Header: 'Reorder Qty', accessor: 'inventory2.REORDER_QTY' },
	{ Header: 'Minimum Qty', accessor: 'inventory2.MINIMUM_QTY' },
	{ Header: 'Maximum Qty', accessor: 'inventory2.MAXIMUM_QTY' },
	{ Header: 'Conversion', accessor: 'inventory2.CONVERSION' },
];

const lastOrderColumns = [
	{ Header: 'PO ID', accessor: 'last_order.PO_ID',
		Cell: props => props.value && <HashLink onClick={() => props.parent.viewPO(props.value)}>{props.value}</HashLink> },
	{ Header: 'Catalog #', accessor: 'last_order.CAT_NUMBER' },
	{ Header: 'Vendor', accessor: 'last_order.VENDORNAME' },
	{ Header: 'Date Ordered', accessor: 'last_order.DATE_PLACED' },
	{ Header: 'Qty Ordered', accessor: 'last_order.ORD_QTY' },
	{ Header: 'Qty Received', accessor: 'last_order.REC_QTY_BACK_ORDER' },
	{ Header: 'Unit', accessor: 'last_order.UNIT_SIZE' },
	{ Header: 'Unit Price', accessor: 'last_order.UNIT_PRICE' },
	{ Header: 'Unit Price + Markup', accessor: 'last_order.SALE_PRICE' },
	{ Header: 'Order Status', accessor: 'last_order.STATUS_NAME' }
];

const adjustColumns = [
	{ Header: 'QTY in Stock', accessor: 'inventory2.QTY', row: 0, col: 0, editable: false },
	{ Header: 'Adjust QTY by', accessor: 'adjust.NEW_QTY', subscript: '(enter a minus sign for negative values)', row: 1, col: 0 },
	{ Header: 'Adjust Reason', accessor: 'adjust.ADJUST_TYPE', type: 'select', row: 1, col: 1,
		options: { value: 'ADJUST_TYPE', label: 'ADJUST_NAME', entity: 'STORES.ADJUST_TYPE', 
			search: { Operator: 'and', Children: [
				{ Attribute: 'DELETE_FLAG', Operator: 'is null' },
				{ Attribute: 'TRANS_TYPE', Operator: '=', StringValue: 'I' }
			]
	}}},
	{ Header: 'Fiscal Inventory', accessor: 'adjust.FISCAL_INV', type: 'checkbox', row: 1, col: 2 }
];

const adjustComments = [
	{ Header: 'Comments', accessor: 'adjust.COMMENTS', type: 'textarea' }
];

const transferColumns = [
	{ Header: 'Transfer QTY', accessor: 'transfer.TRANSFER_QTY', row: 0, col: 0, subscript: '(out)', required: true },
	{ Header: 'To Stock Number', accessor: 'transfer.NEW_ITEM', row: 0, col: 1, required: true },
	{ Header: 'QTY', accessor: 'transfer.QTY_IN', row: 0, col: 2, subscript: '(in)' }
];

const inventoryAdjustColumns = [
	{ Header: 'ID', accessor: 'ADJUST_ID', width: 125 },
	{ Header: 'Adjust QTY', accessor: 'ADJUST_QTY', width: 125 },
	{ Header: 'Entered', accessor: 'ENTERED', width: 300 },
	{ Header: 'Adjust Reason', accessor: 'ADJUST_NAME', width: 250 },
	{ Header: 'Comments', accessor: 'COMMENTS', width: 250 }
];

const emptyDocument = {
	NOTE_ID: 'item_notes_id_seq.nextval',
	NOTE_TITLE: '',
	UPLOADED: null,
	NOTE_BYTES: 0
};

const documentTableColumns = [
	{ Header: '', accessor: 'NOTE_ID', show: false },
	{ Header: 'Title', accessor: 'NOTE_TITLE' },
	{ Header: 'Size', accessor: 'NOTE_BYTES' },
	{ Header: 'Current', accessor: 'UPLOADED',
		Cell: props => props.parent.isNumeric(props.row.values.NOTE_ID) ? 
			<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Download</Popover.Header></Popover>}>
				<form action={props.parent.getConfig().host + '/Stores/DownloadItemNote'} method='POST'>
					<input type='hidden' name='__RequestVerificationToken' value={props.parent.props.user.antiForgeryToken} />
					<input type='hidden' name='noteid' value={props.row.values.NOTE_ID} />
					<input type='image' src={props.parent.getConfig().host + '/Content/Icons/download.png'} alt="Download" />
				</form>
			</OverlayTrigger> :
			<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Pending save</Popover.Header></Popover>}>
				<HourglassSplit />
			</OverlayTrigger>
	}		
];

const documentEditColumns = [
	{ Header: 'Title', accessor: 'NOTE_TITLE' },
	{ Header: 'Current File', accessor: 'NOTE_ID', 
		Cell: props => props.parent.isNumeric(props.value) ? 
			<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Download</Popover.Header></Popover>}>
				<form action={props.parent.getConfig().host + '/Stores/DownloadItemNote'} method='POST'>
					<input type='hidden' name='__RequestVerificationToken' value={props.parent.props.user.antiForgeryToken} />
					<input type='hidden' name='noteid' value={props.value} />
					<input type='image' src={props.parent.getConfig().host + '/Content/Icons/download.png'} alt="Download" />
				</form>
			</OverlayTrigger> :
			<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Pending save</Popover.Header></Popover>}>
				<HourglassSplit />
			</OverlayTrigger>
	},
	{ Header: 'New File', accessor: 'UPLOADED', type: 'file' }
];

const priceHistoryColumns = [
	{ Header: 'ID', accessor: 'PRICE_ID', width: 125 },
	{ Header: 'Item', accessor: 'STOCK_NO', width: 125 },
	{ Header: 'Unit Price', accessor: 'UNIT_COST', width: 125 },
	{ Header: 'Modified', accessor: 'ENTERED_DATE', width: 125 },
	{ Header: 'Modified By', accessor: 'ENTERED_BY', width: 250 },
	{ Header: 'Comments', accessor: 'COMMENTS', width: 250 }
];

const poColumns = [
	{ Header: 'ID', accessor: 'PO_ID', width: 60,
		Cell: props => props.value && <HashLink onClick={() => props.parent.viewPO(props.value)}>{props.value}</HashLink> },
	{ Header: 'PO Number', accessor: 'PO_NUMBER', width: 110 },
	{ Header: 'Cart ID', accessor: 'CART_ID', width: 100 },
	{ Header: 'Date', accessor: 'DATE_PLACED', width: 100 },
	{ Header: 'Vendor', accessor: 'VENDORNAME', width: 300 },
	{ Header: 'Cat. Number', accessor: 'CAT_NUMBER', width: 90 },
	{ Header: 'Unit Size', accessor: 'UNIT_SIZE', width: 40 },
	{ Header: 'Price', accessor: 'UNIT_PRICE', width: 60 },
	{ Header: 'Contact', accessor: 'CONTACT', width: 80 },
	{ Header: "Ord'd", accessor: 'ORD_QTY', width: 60 },
	{ Header: "Rec'd", accessor: 'REC_QTY', width: 60 },
	{ Header: 'On Order', accessor: 'BACK_ORDER', width: 60 },
	{ Header: 'PO Status', accessor: 'STATUS_NAME', width: 90 }
];

const salesTotalColumns = [
	{ Header: 'Current QTY In Stock', accessor: 'inventory2.QTY', row: 0, col: 0 },
	{ Header: 'Inventory Date', accessor: 'inventory2.INVENTORY_DATE', row: 0, col: 1 },
	{ Header: 'Total Sales Since Inventory', accessor: 'inventory2.SALES_TOTAL', row: 0, col: 2 }
];

const salesList = {
	query: { entity: 'STORES.ITEM_SALES_VW', search: null, order: 'TRANS_DATE desc' },
	pageSize: 20,
	hold: true,
	columns: [
		{ Header: 'ID', accessor: 'SALE_ID', width: 70,
			Cell: props => props.value && <HashLink onClick={() => props.parent.viewSale(props.value)}>{props.value}</HashLink> },
		{ Header: '', accessor: 'LINE_ID', show: false },
		{ Header: 'QTY', accessor: 'QUANTITY', width: 70 },
		{ Header: 'Customer', accessor: 'USER_NAME', width: 300 },
		{ Header: 'PI', accessor: 'PI_NAME', width: 300 },
		{ Header: 'Date', accessor: 'SALE_DATE', width: 90 },
		{ Header: props => <Form.Check type="checkbox" checked={props.parent.state.item.any_sales_marked}
			onClick={(event) => props.parent.onClickBookmarks()} />, accessor: 'BOOKMARK', width: 30,
			Cell: props => <Form.Check type="checkbox" checked={props.value === 'Y'}
			onClick={(event) => props.parent.onClickBookmark(props.row.values.SALE_ID, props.row.values.LINE_ID, event.target.checked)} />
		}
	]
};

const emptyVendor = {
	CATALOG_ID: 'vendor_cat_seq.nextval',
	VENDOR_ID: null,
	CATALOG_NO: '',
	MANUFACTURER_NUM: '',
	VENDOR_PRICE: '',
	VENDOR_UNIT: '',
	VENDOR_QTY_UNIT: '',
	STATUS_ID: null,
	COMMENTS: ''
};

const vendorTableColumns = [
	{ Header: 'Vendor', accessor: 'VENDORNAME', width: 300 },
	{ Header: 'Catalog No.', accessor: 'CATALOG_NO', width: 90 },
	{ Header: 'Manufacturer Number', accessor: 'MANUFACTURER_NUM', width: 90 },
	{ Header: 'Price', accessor: 'VENDOR_PRICE', width: 60 },
	{ Header: 'UOM', accessor: 'VENDOR_UNIT', width: 60 },
	{ Header: 'Qty/Unit', accessor: 'VENDOR_QTY_UNIT', width: 70 },
	{ Header: 'Status', accessor: 'STATUS_NAME', width: 80 },
	{ Header: 'Last PO', accessor: 'LAST_ORDER', width: 90 },
	{ Header: 'Comments', accessor: 'COMMENTS', width: 200 },
	{ Header: 'Modified', accessor: 'MODIF_DATE', width: 90 }
];

const vendorEditColumns = [
	{ Header: 'Vendor', accessor: 'VENDOR_ID', labelAccessor: 'VENDORNAME', type: 'select', row: 0, col: 0,
		options: { value: 'VENDOR_ID', label: 'VENDORNAME', entity: 'STORES.VENDOR_SELECT_VW' }},		
	{ Header: 'Catalog No.', accessor: 'CATALOG_NO', row: 0, col: 1 },
	{ Header: 'Manufacturer Number', accessor: 'MANUFACTURER_NUM', row: 0, col: 2 },
	{ Header: 'Price', accessor: 'VENDOR_PRICE', row: 1, col: 0 },
	{ Header: 'UOM', accessor: 'VENDOR_UNIT', row: 1, col: 1 },
	{ Header: 'Qty/Unit', accessor: 'VENDOR_QTY_UNIT', row: 1, col: 2 },
	{ Header: 'Status', accessor: 'STATUS_ID', labelAccessor: 'STATUS_NAME', type: 'select', row: 2, col: 0,
		options: { value: 'STATUS_ID', label: 'STATUS_NAME', entity: 'STORES.VENDOR_STATUS' }},
	{ Header: 'Last PO', accessor: 'LAST_ORDER', type: 'date', row: 2, col: 1, editable: false },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 2, col: 2 },
	{ Header: 'Modified', accessor: 'MODIF_DATE', type: 'date', row: 3, col: 0, editable: false }
];

const nameColumns = [
	{ Header: 'ID', accessor: 'HIST_ID' },
	{ Header: 'Name', accessor: 'STOCK_NO' },
	{ Header: 'Modified Date', accessor: 'MODIF_DATE' },
	{ Header: 'Modified By', accessor: 'MODIF_BY' }
];

export default withItemDefaultRoute(Item);
