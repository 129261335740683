import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { PlusSquare, PencilSquare } from 'react-bootstrap-icons';

class Courses extends ChemComponent {
	constructor(props) {
		super(props);

		var search = {
			COURSE: '',
			DESCRIPTION: '',
			SEMESTER: []
		};
		
		var initCourseList = this.copyTable(courseList);
		if (typeof(Storage) !== 'undefined') {
			var storedSearch = localStorage.getItem('GradOffice_Courses_search');
			if (storedSearch) {
				storedSearch = JSON.parse(storedSearch);
				this.mergeObject(search, storedSearch);
				search = storedSearch;
				initCourseList.query.search = this.constructSearch(search);
			}
		}

		this.state = {
			courseList: initCourseList,
			search: search,
			searchCount: null,
			renderKey: 0
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newCourseList = this.copyTable(courseList);
		newCourseList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			courseList: newCourseList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (typeof(Storage) !== 'undefined') {
			localStorage.setItem('GradOffice_Courses_search', JSON.stringify(this.state.search));
		}
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.COURSE_NAME)) search.Children.push({ Attribute: 'COURSE_NAME', Operator: '=', StringValue: data.COURSE_NAME });
		if (!this.isEmpty(data.COURSE_DESC)) search.Children.push({ Attribute: 'COURSE_DESC', Operator: 'like', StringValue: '%' + data.COURSE_DESC + '%' });
		if (Array.isArray(data.SEMESTER) && data.SEMESTER.length > 0) {
			var semesterSearch = { Operator: 'or', Children: [] };
			for (var i = 0; i < data.SEMESTER.length; i++) {
				var caseCombos = data.SEMESTER[i] === 'SP' ? ['sp','Sp','sP','SP'] :
					(data.SEMESTER[i] === 'FA' ? ['fa', 'Fa', 'fA', 'FA'] : ['su','Su','sU','SU']);
				for (var c = 0; c < caseCombos.length; c++) {
					semesterSearch.Children.push({ Attribute: 'SEMESTER', Operator: 'like', StringValue: '%' + caseCombos[c] + '%' });
				}
			}
			search.Children.push(semesterSearch);
		}

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}

	addCourse() {
		this.props.navigate('/GradOffice/Courses/new');
	}
	
	editCourse(courseId) {
		this.props.setCourseEditable(true);
		this.props.navigate('/GradOffice/Courses/' + courseId);		
	}

	viewCourse(courseId) {
		this.props.setCourseEditable(false);
		this.props.navigate('/GradOffice/Courses/' + courseId);		
	}

	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Courses',
			columns: exportColumns,
			entity: 'ADMISSIONS.COURSES_VW',
			search: this.constructSearch(this.state.search),
			order: 'COURSE_NAME'
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Courses</span>
						<ChemEdit parent={this} columns={courseSearch} data={this.state.search} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} {...this.props} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='GradOffice_Courses' table={this.state.courseList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='250px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const courseSearch = [
	{ Header: 'Course', accessor: 'COURSE_NAME', row: 0, col: 0 },
	{ Header: 'Description', accessor: 'COURSE_DESC', row: 0, col: 1 },
	{ Header: 'Semester', accessor: 'SEMESTER', type: 'list', row: 0, col: 2,
		options: [{ value: 'FA', label: 'Fall' },{ value: 'SP', label: 'Spring' },{ value: 'SU', label: 'Summer' }]}
];

const courseList = {
	query: { entity: 'ADMISSIONS.COURSES_VW', search: null, order: 'COURSE_NAME' },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addCourse()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editCourse(props.row.values.COURSE_ID)} />, width: 25 },
		{ Header: 'ID', accessor: 'COURSE_ID' },
		{ Header: 'Course', accessor: 'COURSE_NAME', Cell: props => <HashLink onClick={() => props.parent.viewCourse(props.row.values.COURSE_ID)}>{props.value}</HashLink> },
		{ Header: 'Description', accessor: 'COURSE_DESC' },
		{ Header: 'Semester', accessor: 'SEMESTER' },
		{ Header: 'GSI Flag', accessor: 'TA_FLAG' },
		{ Header: 'Dis/Lab', accessor: 'COURSE_TYPE' },
		{ Header: 'Module', accessor: 'MODULE_FLAG' }
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'COURSE_ID', width: 25 },
	{ Header: 'Course', accessor: 'COURSE_NAME', width: 25 },
	{ Header: 'Description', accessor: 'COURSE_DESC', width: 25 },
	{ Header: 'Semester', accessor: 'SEMESTER', width: 25 },
	{ Header: 'GSI Flag', accessor: 'TA_FLAG', width: 25 },
	{ Header: 'Dis/Lab', accessor: 'COURSE_TYPE', width: 25 },
	{ Header: 'Module', accessor: 'MODULE_FLAG', width: 25 }
];

export default withRouter(Courses);
