import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class Course extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			editable: props.editable,
			course: {
				COURSE_ID: '' 
			},
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadCourse();
	}

	loadCourse(event) {
		var self = this;
		
		if (this.props.params.courseId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/GradOffice/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'COURSES',
					search: { Attribute: 'COURSE_ID', Operator: '=', LongValue: this.props.params.courseId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var course = data.Data[0];
						
						course.MODULE = course.MODULE === 1 ? 'Y' : null;
						self.mvc2js(course, courseColumns);
							
						self.mergeState({
							course: course,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Course Error', data.Data.length + ' Courses found for ID ' + self.props.params.courseId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				course: {
					COURSE_ID: '(new)',
					DEPT_CODE: 1,
					COURSE_NAME: '',
					COURSE_DESC: '',
					SEMESTER: '',
					TA_FLAG: false,
					COURSE_TYPE: false,
					MODULE: false
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var course = this.copyObject(this.state.course);
		this.setByAccessor(course, accessor, value);
		this.mergeState({ course: course, renderKey: this.state.renderKey + 1 });
	}
		
	deleteCourse(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Course', 'Delete Course ' + this.props.params.courseId + '?', (okClicked) => {
			if (okClicked) {
				// prepare course for deletion		
				var course = {
					COURSE_ID: self.props.params.courseId,
					DELETE_FLAG: 'Y',
					MODIF_BY: self.props.user.name,
					MODIF_DATE: self.dateTimeToMVC(new Date())
				};
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'ADMISSIONS.COURSES', data: course }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Course {self.props.params.courseId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
		
	onSubmit(event) {
		var self = this;		
		var url = null;
		
		// make copy of data
		var course = this.copyObject(this.state.course);

		this.js2mvc(course, courseColumns);
		course.MODULE = course.MODULE === 'Y' ? 1 : 0;
		course.MODIF_BY = this.props.user.name;
		course.MODIF_DATE = this.dateTimeToMVC(new Date());
		
		// if this is a new course
		if (!this.props.params.courseId) {
			// set ID to sequence name
			course.COURSE_ID = 'course_id_seq.nextval';
			url = '/Home/Insert';
		} else {
			url = '/Home/Update';
		}
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'ADMISSIONS.COURSES', data: course }
		}).done(function (data) {
			if (data.Success) {
				var courseId = self.props.params.courseId || data.ID;
				var verb = self.props.params.courseId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Course <Link to={'/GradOffice/Courses/' + courseId}>{courseId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Course {this.state.course.COURSE_ID}&nbsp;&nbsp;
					{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
					  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
				  </div>
				  <Container fluid>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={courseColumns} data={this.state.course} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)} editable={this.state.editable}>
						  <Form.Group>
							{this.state.editable && <Button id="save" variant="warning" type="submit" className="float-end">Save</Button>}
							{this.state.editable && this.props.params.courseId && <Button id="delete" variant="warning" type="button" className="float-end"
							  style={{ marginRight: '20px' }} onClick={(event) => this.deleteCourse(event)}>Delete</Button>} 
						  </Form.Group>
						</ChemEdit>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const courseColumns = [
	{ Header: 'Course', accessor: 'COURSE_NAME', row: 0, col: 0 },
	{ Header: 'Description', accessor: 'COURSE_DESC', row: 0, col: 1 },
	{ Header: 'Semester', accessor: 'SEMESTER', row: 0, col: 2 },
	{ Header: 'GSI', accessor: 'TA_FLAG', type: 'checkbox', row: 1, col: 0 },
	{ Header: 'Discussion/Lab', accessor: 'COURSE_TYPE', type: 'checkbox', row: 1, col: 1 },
	{ Header: 'Module', accessor: 'MODULE', type: 'checkbox', row: 1, col: 2 }
];

export default withRouter(Course);
