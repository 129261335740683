import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';

class Home extends ChemComponent {	
	constructor(props) {
		super(props);
		
		this.state = {
			search: {
				STOCK_NUMBER: '',
				STOCK_NUMBER_LIKE: '',
				DESCRIPTION: '',
				DESCRIPTION_LIKE: '',
				CATALOG_NUMBER: '',
				CATALOG_NUMBER_LIKE: '',
				TRANS_ID: '',
				CUSTOMER_NAME: '',
				CUSTOMER_NAME_LIKE: '',
				PI_NAME: '',
				PI_NAME_LIKE: '',
				PO_NUMBER: '',
				CART_ID: '',
				VENDOR_NAME: '',
				VENDOR_NAME_LIKE: ''
			},
			renderKey: 0
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		// add %s to values that will be used in 'like' expressions
		if (accessor === 'STOCK_NUMBER') {
			this.setByAccessor(search, 'STOCK_NUMBER_LIKE', '%' + value.toUpperCase() + '%');
		} else if (accessor === 'DESCRIPTION') {
			this.setByAccessor(search, 'DESCRIPTION_LIKE', '%' + value.toUpperCase() + '%');
		} else if (accessor === 'CATALOG_NUMBER') {
			this.setByAccessor(search, 'CATALOG_NUMBER_LIKE', '%' + value.toUpperCase() + '%');
		} else if (accessor === 'CUSTOMER_NAME') {
			this.setByAccessor(search, 'CUSTOMER_NAME_LIKE', value.toUpperCase() + '%');
		} else if (accessor === 'PI_NAME') {
			this.setByAccessor(search, 'PI_NAME_LIKE', value.toUpperCase() + '%');
		} else if (accessor === 'VENDOR_NAME') {
			this.setByAccessor(search, 'VENDOR_NAME_LIKE', '%' + value.toUpperCase() + '%');
		}
		this.mergeState({ search: search, renderKey: this.state.renderKey + 1 });
	}
	
	findStock(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Stores/GetItemID',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, stockno: this.state.search.STOCK_NUMBER }
		}).done(function (data) {
			if (data.Success) {
				self.props.navigate('/Stores/Catalog/' + data.Data.ITEM_ID);
			} else {
				self.showAlert(data.Message.endsWith('found 0') ? 'Stock Number Not Found' : 'Multiple Matches Found', data.Message);
				/* none or multiple found; go to catalog search
				self.props.onCatalogSearch({
					CATALOG_NUMBER: '',
					VENDOR_NAME: '',
					STOCK_NUMBER: self.state.search.STOCK_NUMBER,
					CATEGORY: [],
					DESCRIPTION: '',
					LOCATION: '',
					LAST_MODIF: null
				});
				*/
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
	
	findCatalog(event) {
		this.props.onCatalogSearch({
			CATALOG_NUMBER: this.state.search.CATALOG_NUMBER,
			VENDOR_NAME: '',
			STOCK_NUMBER: '',
			CATEGORY: [],
			DESCRIPTION: this.state.search.DESCRIPTION,
			LOCATION: '',
			LAST_MODIF: null
		});
	}
	
	findSales(event) {
		this.props.onSalesSearch({
			TRANS_ID: this.state.search.TRANS_ID,
			CUSTOMER: this.state.search.CUSTOMER_NAME,
			PI_NAME: this.state.search.PI_NAME
		});
	}
	
	findPO(event) {
		this.props.onPOSearch({
			PO_NUMBER: this.state.search.PO_NUMBER,
			CART_ID: this.state.search.CART_ID,
			VENDOR_NAME: this.state.search.VENDOR_NAME,
			CATALOG_NUMBER: '',
			PLACED_FROM: null,
			PLACED_TO: null,
			DESCRIPTION: '',
			UPDATED_FROM: null,
			UPDATED_TO: null,
			REFERENCE: '',
			COMMENTS: '',
			STATUS_ID: null,
		});			
	}

	render() {
		return(
			<Container fluid>
			  <Row>
			    <Col md={12} lg={11} xl={10} style={{ paddingTop: '20px', paddingBottom: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Stores</span>
				  <Container>
					<Row className='align-items-center' style={{ borderTop: '1px solid #eee' }}>
					  <Col xs={10}>
						<ChemEdit parent={this} user={this.props.user} columns={stockSearch} data={this.state.search} labelColWidth={8} valueColWidth={4}
						  hideLines={true} renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)}
						  autoSubmit={true} onSubmit={(event) => this.findStock(event)} />
					  </Col>
					  <Col xs={2}>
						<Button onClick={(event) => this.findStock(event)}>Find Item</Button>
					  </Col>
					</Row>
					<Row className='align-items-center' style={{ borderTop: '1px solid #eee' }}>
					  <Col xs={10}>
						<ChemEdit parent={this} user={this.props.user} columns={catalogSearch} data={this.state.search} labelColWidth={8} valueColWidth={4}
						  hideLines={true} renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)}
						  autoSubmit={true} onSubmit={(event) => this.findCatalog(event)} />
					  </Col>
					  <Col xs={2}>
						<Button onClick={(event) => this.findCatalog(event)}>Find</Button>
					  </Col>
					</Row>
					<Row className='align-items-center' style={{ borderTop: '1px solid #eee' }}>
					  <Col xs={10}>
						<ChemEdit parent={this} user={this.props.user} columns={salesSearch} data={this.state.search} labelColWidth={8} valueColWidth={4}
						  hideLines={true} renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)}
						  autoSubmit={true} onSubmit={(event) => this.findSales(event)} />
					  </Col>
					  <Col xs={2}>
						<Button onClick={(event) => this.findSales(event)}>Find Sales</Button>
					  </Col>
					</Row>
					<Row className='align-items-center' style={{ borderTop: '1px solid #eee' }}>
					  <Col xs={10}>
						<ChemEdit parent={this} user={this.props.user} columns={poSearch} data={this.state.search} labelColWidth={8} valueColWidth={4}
						  hideLines={true} renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)}
						  autoSubmit={true} onSubmit={(event) => this.findPO(event)} />
					  </Col>
					  <Col xs={2}>
						<Button onClick={(event) => this.findPO(event)}>Find PO</Button>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		);
	}
}

const stockSearch = [
	{ Header: 'Select a COC Stock Number', accessor: 'STOCK_NUMBER', type: 'dropdown',
		options: { value: 'STOCK_NO', label: 'STOCK_NO', entity: 'STORES.STOCK_NO_AUTOSUGGEST_VW', order: 'STOCK_NO', distinct: true,
			search: { Attribute: 'STOCK_NO_UCASE', Operator: 'like', StringAccessor: 'STOCK_NUMBER_LIKE' }
	}}
];

const catalogSearch = [
	{ Header: 'Enter Partial Description and/or Select Description', accessor: 'DESCRIPTION', type: 'dropdown',
		options: { value: 'STOCK_NO', label: 'STOCK_NO', entity: 'STORES.STOCK_DESC_AUTOSUGGEST_VW', order: 'STOCK_NO', distinct: true,
			search: { Attribute: 'DESCRIPTION', Operator: 'like', StringAccessor: 'DESCRIPTION_LIKE' }
	}},
	{ Header: 'Enter Partial Vendor Catalog Number and/or Select Vendor Catalog Number', accessor: 'CATALOG_NUMBER', type: 'dropdown',
		options: { value: 'STOCK_NO', label: 'STOCK_NO', entity: 'STORES.CAT_NUM_AUTOSUGGEST_VW', order: 'STOCK_NO', distinct: true,
			search: { Attribute: 'CAT_NUM_UCASE', Operator: 'like', StringAccessor: 'CATALOG_NUMBER_LIKE' }
	}}
];

const salesSearch = [
	{ Header: 'Enter Sale ID', accessor: 'TRANS_ID' },
	{ Header: 'or Customer Name (Last Name, First Name)', accessor: 'CUSTOMER_NAME', type: 'dropdown',
		options: { value: 'CUSTOMER', label: 'CUSTOMER', entity: 'STORES.CUSTOMER_NAME_AUTOSUGGEST_VW', order: 'CUSTOMER', distinct: true,
			search: { Operator: 'or', Children: [
				{ Attribute: 'LNAME_FNAME_UCASE', Operator: 'like', StringAccessor: 'CUSTOMER_NAME_LIKE' },
				{ Attribute: 'FNAME_LNAME_UCASE', Operator: 'like', StringAccessor: 'CUSTOMER_NAME_LIKE' }
	]}}},
	{ Header: 'or PI Name (Last Name, First Name)', accessor: 'PI_NAME', type: 'dropdown',
		options: { value: 'PI_NAME', label: 'PI_NAME', entity: 'STORES.PI_NAME_AUTOSUGGEST_VW', order: 'PI_NAME', distinct: true,
			search: { Operator: 'or', Children: [
				{ Attribute: 'LNAME_FNAME_UCASE', Operator: 'like', StringAccessor: 'PI_NAME_LIKE' },
				{ Attribute: 'FNAME_LNAME_UCASE', Operator: 'like', StringAccessor: 'PI_NAME_LIKE' }
	]}}},
];

const poSearch = [
	{ Header: 'Enter PO Number', accessor: 'PO_NUMBER' },
	{ Header: 'or Cart ID', accessor: 'CART_ID' },
	{ Header: 'or Vendor Name', accessor: 'VENDOR_NAME', type: 'dropdown',
		options: { value: 'VENDORNAME', label: 'VENDORNAME', entity: 'STORES.VENDOR_NAME_AUTOSUGGEST_VW', order: 'VENDORNAME', distinct: true,
			search: { Attribute: 'VENDORNAME_UCASE', Operator: 'like', StringAccessor: 'VENDOR_NAME_LIKE' }
	}}
];

export default withRouter(Home);