import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class MarkupRate extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			editable: props.editable,
			markupRate: {
				RATE_ID: '' 
			},
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadMarkupRate();
	}

	loadMarkupRate(event) {
		var self = this;
		
		if (this.props.params.markupRateId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/CRS/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'MARK_UP_RATE',
					search: { Attribute: 'RATE_ID', Operator: '=', LongValue: this.props.params.markupRateId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var markupRate = data.Data[0];
						self.mvc2js(markupRate, markupRateColumns);
							
						self.mergeState({
							markupRate: markupRate,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Markup Rate Error', data.Data.length + ' Markup Rates found for ID ' + self.props.params.markupRateId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				markupRate: {
					RATE_ID: '(new)',
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var markupRate = this.copyObject(this.state.markupRate);
		this.setByAccessor(markupRate, accessor, value);
		this.mergeState({ markupRate: markupRate, renderKey: this.state.renderKey + 1 });
	}
	
	onDelete(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete', 'Delete Markup Rate ' + this.props.params.markupRateId + '?', (okClicked) => {
			if (okClicked) {
				// prepare tax rate for deletion
				var markupRate = {
					RATE_ID: self.props.params.markupRateId,
					DELETE_FLAG: 'Y',
					MODIF_BY: self.props.user.name,
					DATE_MODIF: self.dateTimeToMVC(new Date())
				};
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'RECHARGES.MARK_UP_RATE', data: markupRate }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Markup Rate {self.props.params.markupRateId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}	
		
	onSubmit(event) {
		var self = this;		
		var url = null;
		
		// make copy of data
		var markupRate = this.copyObject(this.state.markupRate);

		this.js2mvc(markupRate, markupRateColumns);
		
		// if this is a new tax rate
		if (!this.props.params.markupRateId) {
			// set ID to null
			markupRate.RATE_ID = null;
			markupRate.CREATED_BY = this.props.user.name;
			markupRate.DATE_CREATED = this.dateTimeToMVC(new Date());
			markupRate.SHOP_ID = 50;
			url = '/Home/Insert';
		} else {
			markupRate.MODIF_BY = this.props.user.name;
			markupRate.DATE_MODIF = this.dateTimeToMVC(new Date());
			url = '/Home/Update';
		}
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'RECHARGES.MARK_UP_RATE', data: markupRate }
		}).done(function (data) {
			if (data.Success) {
				var markupRateId = self.props.params.markupRateId || data.ID;
				var verb = self.props.params.markupRateId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Markup Rate <Link to={'/HeLAD/MarkupRates/' + markupRateId}>{markupRateId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Markup Rate {this.state.markupRate.RATE_ID}&nbsp;&nbsp;
							{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
							  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
						  </div>
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={markupRateColumns} data={this.state.markupRate} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)} editable={this.state.editable}>
						  <Form.Group>
							{this.state.editable && <Button id="save" variant="warning" type="submit" className="float-end">Save</Button>}
							{this.state.editable && this.props.params.markupRateId && <Button id="delete" variant="warning" type="button" className="float-end"
							  style={{ marginRight: '20px' }} onClick={(event) => this.onDelete(event)}>Delete</Button>} 
						  </Form.Group>
						</ChemEdit>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const markupRateColumns = [
	{ Header: 'Markup Rate', accessor: 'RATE_PCT', row: 0, col: 0 },
	{ Header: 'Start Date', accessor: 'START_DATE', type: 'date', row: 0, col: 1 },
	{ Header: 'End Date', accessor: 'END_DATE', type: 'date', row: 0, col: 2 },
	{ Header: 'Valid', accessor: 'VALID_FLAG', type: 'checkbox', row: 1, col: 0 },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 1, col: 1 }
];

export default withRouter(MarkupRate);
