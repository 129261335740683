import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class RoomFunction extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			roomFunction: {
				FUNCTION_ID: null,
				FUNCTION_NAME: '',
				COMMENTS: '',
				CREATE_DATE: null,
				CREATE_BY: '',
				MODIF_DATE: null,
				MODIF_BY: '',
				DELETE_FLAG: null,
				CREATED: '',
				MODIFIED: ''
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadRoomFunction();
	}

	loadRoomFunction(event) {
		var self = this;
		
		if (this.props.params.functionId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'ROOM_FUNCTION',
					search: { Attribute: 'FUNCTION_ID', Operator: '=', LongValue: this.props.params.functionId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length === 1) {
						var roomFunction = data.Data[0];
						
						// convert flags from oracle style
						roomFunction.DELETE_FLAG = roomFunction.DELETE_FLAG === 'Y';
												
						// compose change info
						roomFunction.CREATED = self.composeChangeInfo(roomFunction.CREATE_BY, roomFunction.CREATE_DATE);
						roomFunction.MODIFIED = self.composeChangeInfo(roomFunction.MODIF_BY, roomFunction.MODIF_DATE);
						
						self.mergeState({
							roomFunction: roomFunction,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Room Function Error', data.Data.length + ' room functions found for ID ' + self.props.params.functionId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				roomFunction: {
					FUNCTION_ID: '(new)'
				}
			});
		}
	}
		
	onChange(accessor, value) {
		var roomFunction = this.copyObject(this.state.roomFunction);
		this.setByAccessor(roomFunction, accessor, value);
		this.mergeState({ roomFunction: roomFunction, renderKey: this.state.renderKey + 1 });
	}
		
	deleteRoomFunction(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Room Function', 'Delete Room Function ' + this.props.params.functionId + '?', (okClicked) => {
			if (okClicked) {
				// prepare room for deletion
				var roomFunction = self.copyObject(self.state.roomFunction);
				roomFunction.DELETE_FLAG = 'Y';
				roomFunction.CREATE_DATE = self.dateTimeToMVC(self.getDate(roomFunction.CREATE_DATE));
				roomFunction.MODIF_DATE = self.dateTimeToMVC(new Date());
				roomFunction.MODIF_BY = self.props.user.name;
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'ROOM_FUNCTION', data: roomFunction }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Room Function {self.props.params.functionId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
		
	onSubmit(event) {
		var self = this;		
		var dtNow = this.dateTimeToMVC(new Date());
		var currentUser = this.props.user.name;
		
		// make copy of data
		var roomFunction = this.copyObject(this.state.roomFunction);
		
		// if this is a new room, set ID to sequence name
		var url;
		if (this.props.params.functionId) {
			url = this.getConfig().host + '/Home/Update';
			roomFunction.CREATE_DATE = this.dateTimeToMVC(this.getDate(roomFunction.CREATE_DATE));
			roomFunction.MODIF_DATE = dtNow;
			roomFunction.MODIF_BY = currentUser;
		} else {
			url = this.getConfig().host + '/Home/Insert';
			roomFunction.FUNCTION_ID = null;
			roomFunction.CREATE_DATE = dtNow;
			roomFunction.CREATE_BY = currentUser;
			roomFunction.MODIF_DATE = null;
			roomFunction.MODIF_BY = null;
		}			

		// convert flags to oracle style
		roomFunction.DELETE_FLAG = roomFunction.DELETE_FLAG ? 'Y' : null;
		
		this.ajax({
			type: 'POST',
			url: url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'ROOM_FUNCTION', data: roomFunction }
		}).done(function (data) {
			if (data.Success) {
				var functionId = self.props.params.functionId ? roomFunction.FUNCTION_ID : data.ID;
				var verb = self.props.params.functionId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Room Function <Link to={'/BuildingAccess/RoomFunctions/' + functionId}>{functionId}</Link> {verb}.
					</>); 
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Room Function {this.state.roomFunction.FUNCTION_ID}</div>
				<ChemEdit parent={this} columns={functionColumns} data={this.state.roomFunction} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
				  <Form.Group style={{  }}>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					{ this.props.params.functionId && <Button id="delete" variant="warning" type="button" className="float-end"
					  style={{ marginRight: '20px' }} onClick={(event) => this.deleteRoomFunction(event)}>Delete</Button> } 
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const functionColumns = [
	{ Header: 'Name', accessor: 'FUNCTION_NAME', required: true },
];

export default withRouter(RoomFunction);
