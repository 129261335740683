import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';
import { withRouter } from '../withRouter';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';

class ReorderReport extends ChemComponent {
	constructor(props) {
		super(props);

		this.state = {
			search: {
				STOCK_NO: '',
				LOCATION: ''
			},
			order: ['STOCK_NO'],
			reorderList: this.copyTable(reorderList),
			searchCount: 0,
			renderKey: 0
		};
	}

	viewItem(itemId) {
		this.props.setItemEditable(false);
		this.props.navigate('/Stores/Catalog/' + itemId);		
	}
	
	viewPO(poId) {
		this.props.setPOEditable(false);
		this.props.navigate('/Stores/POs/' + poId);		
	}

	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event, order) {
		var newReorderList = this.copyTable(reorderList);
		newReorderList.query.search = this.constructSearch(this.state.search);
		newReorderList.query.order = order;
		this.mergeState({
			order: order,
			reorderList: newReorderList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}

	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.STOCK_NO)) search.Children.push({ Attribute: 'STOCK_NO_UCASE', Operator: '=', StringValue: data.STOCK_NO.toUpperCase() });
		if (!this.isEmpty(data.LOCATION)) search.Children.push({ Attribute: 'LOCATION_UCASE', Operator: '=', StringValue: data.LOCATION.toUpperCase() });

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Reorder Report',
			columns: exportColumns,
			entity: 'STORES.REORDER_VW',
			search: this.constructSearch(this.state.search),
			order: this.state.order
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Reorder Report</span>
						<ChemEdit parent={this} user={this.props.user} columns={reorderSearch} data={this.state.search} autoSubmit={true}
						  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)}
						  onSubmit = {(event) => this.onSubmitSearch(event, this.state.order)} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event, this.state.order)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='Stores_Reorder' table={this.state.reorderList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='190px' onTableSort={(order) => this.onSubmitSearch(null, order)} />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const reorderSearch = [
	{ Header: 'Stock Number', accessor: 'STOCK_NO', row: 0, col: 0 },
	{ Header: 'Location', accessor: 'LOCATION', row: 0, col: 1 }
];

const reorderList = {
	query: { entity: 'STORES.REORDER_VW', search: null, order: ['STOCK_NO'] },
	pageSize: 100,
	columns: [	
		{ Header: '', accessor: 'ITEM_ID', show: false },
		{ Header: 'Stock Number', accessor: 'STOCK_NO', width: 100,
			Cell: props => <HashLink onClick={() => props.parent.viewItem(props.row.values.ITEM_ID)}>{props.value}</HashLink> },
		{ Header: 'Description', accessor: 'DESCRIPTION', width: 350 },
		{ Header: 'Current Qty', accessor: 'QTY', width: 80, align: 'right' },
		{ Header: '$/Unit Sold', accessor: 'UNIT_COST', width: 70, align: 'right' },
		{ Header: 'Stocking UOM', accessor: 'UNIT_OF_MEASURE', width: 100 },
		{ Header: 'Location', accessor: 'LOCATION', width: 100 },
		{ Header: 'Min Qty', accessor: 'MINIMUM_QTY', width: 70, align: 'right' },
		{ Header: 'Reorder Qty', accessor: 'REORDER_QTY', width: 70, align: 'right' },
		{ Header: 'Unit Conv.', accessor: 'CONVERSION', width: 60, align: 'right' },
		{ Header: '', accessor: 'PO_ID', show: false },
		{ Header: 'Last Order', accessor: 'DATE_RECEIVED', width: 80,
			Cell: props => <HashLink onClick={() => props.parent.viewPO(props.row.values.PO_ID)}>{props.value}</HashLink> },
		{ Header: 'QTY Received', accessor: 'REC_QTY', width: 80, align: 'right' },
		{ Header: 'Vendor Price', accessor: 'UNIT_PRICE', width: 70, align: 'right' },
		{ Header: 'Last PO Vendor', accessor: 'VENDORNAME', width: 250 },
		{ Header: 'Primary Vendor', accessor: 'PRIMARY_VENDOR', width: 250 },
		{ Header: 'Vendor UOM', accessor: 'UNIT_SIZE', width: 70 },
		{ Header: 'Vendor Catalog', accessor: 'CAT_NUMBER', width: 200 }		
	]
};

const exportColumns = [
	{ Header: 'Stock Number', accessor: 'STOCK_NO', width: 20 },
	{ Header: 'Description', accessor: 'DESCRIPTION', width: 70 },
	{ Header: 'Current Qty', accessor: 'QTY', width: 16 },
	{ Header: '$/Unit Sold', accessor: 'UNIT_COST', width: 14 },
	{ Header: 'Stocking UOM', accessor: 'UNIT_OF_MEASURE', width: 20 },
	{ Header: 'Location', accessor: 'LOCATION', width: 20 },
	{ Header: 'Min Qty', accessor: 'MINIMUM_QTY', width: 14 },
	{ Header: 'Reorder Qty', accessor: 'REORDER_QTY', width: 14 },
	{ Header: 'Unit Conv.', accessor: 'CONVERSION', width: 12 },
	{ Header: 'Last Order', accessor: 'DATE_RECEIVED', width: 16 },
	{ Header: 'QTY Received', accessor: 'REC_QTY', width: 16 },
	{ Header: 'Vendor Price', accessor: 'UNIT_PRICE', width: 14 },
	{ Header: 'Last PO Vendor', accessor: 'VENDORNAME', width: 50 },
	{ Header: 'Primary Vendor', accessor: 'PRIMARY_VENDOR', width: 50 },
	{ Header: 'Vendor UOM', accessor: 'UNIT_SIZE', width: 14 },
	{ Header: 'Vendor Catalog', accessor: 'CAT_NUMBER', width: 40 }		
];

export default withRouter(ReorderReport);
