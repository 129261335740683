import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class Keys extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			KEY_NUMBER: null,
			KEY_NAME: null,
			ROOM_ID: null,
			BUILDING_ID: null,
			KEY_TYPE: null,
			KEY_MAKER: null,
			KEY_STATUS: 4, // default: Available-Future (otherwise a huge number of records come up)
			DELETE_FLAG: 'No'
		};
		
		var initialKeyList = this.copyKeyList();
		initialKeyList.query.search = this.constructSearch(search);
		
		this.state = {
			renderKey: 1,
			keyList: initialKeyList,
			search: search,
			searchCount: null
		};
	}
	
	copyKeyList() {
		var keyListCopy = this.copyObject(keyList);
		for (var i = 0; i < keyList.columns.length; i++) {
			if (keyList.columns[i].Header) {
				keyListCopy.columns[i].Header = keyList.columns[i].Header;
			}
			if (keyList.columns[i].Cell) {
				keyListCopy.columns[i].Cell = keyList.columns[i].Cell;
			}
		}
		return keyListCopy;
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newKeyList = this.copyKeyList();
		newKeyList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			keyList: newKeyList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.KEY_NUMBER)) search.Children.push({ Attribute: 'KEY_NUMBER', Operator: '=', StringValue: data.KEY_NUMBER });
		if (!this.isEmpty(data.KEY_NAME)) search.Children.push({ Attribute: 'KEY_NAME', Operator: '=', StringValue: data.KEY_NAME });
		if (!this.isEmpty(data.ROOM_ID)) search.Children.push({ Attribute: 'ROOM_ID', Operator: 'like', StringValue: '%|' + data.ROOM_ID + '|%' });
		if (!this.isEmpty(data.BUILDING_ID)) search.Children.push({ Attribute: 'BUILDING_ID', Operator: 'like', StringValue: '%|' + data.BUILDING_ID + '|%' });
		if (!this.isEmpty(data.KEY_TYPE)) search.Children.push({ Attribute: 'KEY_TYPE', Operator: '=', LongValue: data.KEY_TYPE });
		if (!this.isEmpty(data.KEY_MAKER)) search.Children.push({ Attribute: 'KEY_MAKER', Operator: '=', StringValue: data.KEY_MAKER });
		if (!this.isEmpty(data.KEY_STATUS)) search.Children.push({ Attribute: 'KEY_STATUS', Operator: '=', LongValue: data.KEY_STATUS });
		if (data.DELETE_FLAG === 'Yes' || data.DELETE_FLAG === 'No') {
			if (data.DELETE_FLAG === 'Yes') {
				search.Children.push({ Attribute: 'DELETE_FLAG', Operator: '=', StringValue: 'Y' });
			} else {
				search.Children.push({ Attribute: 'DELETE_FLAG', Operator: 'is null' });
			}
		}
		
		// if nothing was selected, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	addKey() {
		this.props.navigate('/BuildingAccess/Keys/new');
	}
	
	editKey(keyId) {
		this.props.navigate('/BuildingAccess/Keys/' + keyId);		
	}
	
	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Keys',
			columns: exportColumns,
			entity: 'KEY_SEARCH_VW',
			search: this.constructSearch(this.state.search),
			order: 'KEY_NUMBER'
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Keys</span>
					<ChemEdit parent={this} columns={keySearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={3} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_Keys' table={this.state.keyList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='250px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const keySearch = [
	{ Header: 'Number', accessor: 'KEY_NUMBER', type: 'select', row: 0, col: 0,
		options: { value: 'KEY_NUMBER', label: 'KEY_NUMBER', entity: 'KEY_INFO', order: 'KEY_NUMBER' }},
	{ Header: 'Name', accessor: 'KEY_NAME', type: 'select', row: 0, col: 1,
		options: { value: 'KEY_NAME', label: 'KEY_NAME', entity: 'KEY_INFO', order: 'KEY_NAME',
			search: { Attribute: 'KEY_NAME', Operator: 'is not null' }}},
	{ Header: 'Room', accessor: 'ROOM_ID', type: 'select', row: 0, col: 2,
		options: { value: 'ROOM_ID', label: 'LOCATION', entity: 'ROOMS_VW', order: 'LOCATION' }},
	{ Header: 'Building', accessor: 'BUILDING_ID', type: 'select', row: 0, col: 3,
		options: { value: 'BUILDING_ID', label: 'BUILDING_NAME', entity: 'BUILDING', order: 'BUILDING_NAME',
			search: { Operator: 'and', Children: [
				{ Attribute: 'DELETE_FLAG', Operator: 'is null' },
				{ Attribute: 'CHEMISTRY_FLAG', Operator: '=', StringValue: 'Y' }]}}},
	{ Header: 'Type', accessor: 'KEY_TYPE', type: 'select', row: 1, col: 0,
		options: { value: 'TYPE_ID', label: 'TYPE_NAME', entity: 'KEY_TYPE', order: 'TYPE_NAME' }},
	{ Header: 'Maker', accessor: 'KEY_MAKER', type: 'select', row: 1, col: 1,
		options: { value: 'MAKER_NAME', label: 'MAKER_NAME', entity: 'KEY_MAKER', order: 'MAKER_NAME' }},
	{ Header: 'Status', accessor: 'KEY_STATUS', type: 'select', row: 1, col: 2,
		options: { value: 'STATUS_ID', label: 'STATUS_NAME', entity: 'KEY_STATUS', order: 'STATUS_NAME' }}, 
	{ Header: 'Deleted', accessor: 'DELETE_FLAG', row: 1, col: 3, type: 'select',
		options: [ { value: 'All', label: 'All' }, { value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' } ] }
];

const keyList = {
	query: { entity: 'KEY_SEARCH_VW', search: null, order: 'KEY_NUMBER' },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addKey()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editKey(props.row.values.KEY_ID)} />, width: 25 },
		{ Header: 'ID', accessor: 'KEY_ID' },
		{ Header: 'Number', accessor: 'KEY_NUMBER' },
		{ Header: 'Description', accessor: 'KEY_DESC' },
		{ Header: 'Location', accessor: 'LOCATION', Cell: props => <span dangerouslySetInnerHTML={{__html: props.value}} /> },
		{ Header: 'Series', accessor: 'TYPE_NAME' },
		{ Header: 'Make', accessor: 'KEY_MAKER' },
		{ Header: 'Status', accessor: 'STATUS_NAME' }
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'KEY_ID', width: 10 },
	{ Header: 'Number', accessor: 'KEY_NUMBER', width: 10 },
	{ Header: 'Description', accessor: 'KEY_DESC', width: 20 },
	{ Header: 'Location', accessor: 'LOCATION', width: 20 },
	{ Header: 'Series', accessor: 'TYPE_NAME', width: 15 },
	{ Header: 'Make', accessor: 'KEY_MAKER', width: 10 },
	{ Header: 'Status', accessor: 'STATUS_NAME', width: 10 }
];

export default withRouter(Keys);