import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../../ChemComponent';
import ChemEdit from '../../ChemEdit';
import QueryTable from '../../QueryTable';

class AdHocPeople extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			TYPE_ID: [],
			PI_ROLE_ID: [],
			BUILDING_ID: [],
			ROOM_LEVEL: [],
			ACTIVE_FLAG: 'Yes',
			SUPERVISOR: '',
			ROOM_ID: '',
			TITLE_ID: '',
			UNIT_ID: '',
			DEPT_ID: [],
			PEOPLE_MODIF_DATE_FROM: null,
			PEOPLE_MODIF_DATE_TO: null,
			START_DATE_FROM: null,
			START_DATE_TO: null,
			END_DATE_FROM: null,
			END_DATE_TO: null,
		};
		
		var initialAdHocList = this.copyAdHocList();
		initialAdHocList.query.search = this.constructSearch(search);
		
		this.state = {
			renderKey: 1,
			adHocList: initialAdHocList,
			search: search,
			searchCount: null
		};
	}
	
	copyAdHocList() {
		var adHocListCopy = this.copyObject(adHocList);
		for (var i = 0; i < adHocList.columns.length; i++) {
			if (adHocList.columns[i].Cell) {
				adHocListCopy.columns[i].Cell = adHocList.columns[i].Cell;
			}
		}
		return adHocListCopy;
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newAdHocList = this.copyAdHocList();
		newAdHocList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			adHocList: newAdHocList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (data.TYPE_ID.length > 0) search.Children.push({ Attribute: 'TYPE_ID', Operator: 'in', LongList: data.TYPE_ID });
		if (data.PI_ROLE_ID.length > 0) search.Children.push({ Attribute: 'PI_ROLE_ID', Operator: 'in', LongList: data.PI_ROLE_ID });
		if (data.BUILDING_ID.length > 0) {
			search.Children.push({ Operator: 'or', Children: [
				{ Attribute: 'BUILDING_ID', Operator: 'in', LongList: data.BUILDING_ID },
				{ Attribute: 'LAB_BUILDING', Operator: 'in', LongList: data.BUILDING_ID },
				{ Attribute: 'ALT_BUILDING', Operator: 'in', LongList: data.BUILDING_ID }
			]});
		}
		if (data.ROOM_LEVEL.length > 0) search.Children.push({ Attribute: 'ROOM_LEVEL', Operator: 'in', StringList: data.ROOM_LEVEL });
		if (data.ACTIVE_FLAG === 'Yes') {
			search.Children.push({ Operator: 'and', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Operator: 'or', Children: [
					{ Attribute: 'ROLE_END_DATE', Operator: '>=', DateValue: new Date() },
					{ Attribute: 'ROLE_END_DATE', Operator: 'is null' }]}]});
		} else if (data.ACTIVE_FLAG === 'No') {
			search.Children.push({ Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'N' },
				{ Operator: 'and', Children: [
					{ Attribute: 'ROLE_END_DATE', Operator: 'is not null' },
					{ Attribute: 'ROLE_END_DATE', Operator: '<=', DateValue: new Date() }]}]});
		}
		if (!this.isEmpty(data.SUPERVISOR)) search.Children.push({ Attribute: 'SUPERVISOR', Operator: '=', LongValue: data.SUPERVISOR });	
		if (!this.isEmpty(data.ROOM_ID)) search.Children.push({ Attribute: 'ROOM_ID', Operator: '=', LongValue: data.ROOM_ID });
		if (!this.isEmpty(data.TITLE_ID)) search.Children.push({ Attribute: 'TITLE_ID', Operator: '=', LongValue: data.TITLE_ID });
		if (!this.isEmpty(data.UNIT_ID)) search.Children.push({ Attribute: 'UNIT_ID', Operator: '=', LongValue: data.UNIT_ID });
		if (data.DEPT_ID.length > 0) search.Children.push({
			Operator: 'or',
			Children: [
				{ Attribute: 'DEPT_ID', Operator: 'in', LongList: data.DEPT_ID },
				{ Attribute: 'DEPT2_ID', Operator: 'in', LongList: data.DEPT_ID }
			]
		});
		if (!this.isEmpty(data.PEOPLE_MODIF_DATE_FROM)) search.Children.push({ Attribute: 'PEOPLE_MODIF_DATE', Operator: '>=', DateValue: data.PEOPLE_MODIF_DATE_FROM });
		if (!this.isEmpty(data.PEOPLE_MODIF_DATE_TO)) search.Children.push({ Attribute: 'PEOPLE_MODIF_DATE', Operator: '<=', DateValue: data.PEOPLE_MODIF_DATE_TO });
		if (!this.isEmpty(data.START_DATE_FROM)) search.Children.push({ Attribute: 'START_DATE', Operator: '>=', DateValue: data.START_DATE_FROM });
		if (!this.isEmpty(data.START_DATE_TO)) search.Children.push({ Attribute: 'START_DATE', Operator: '<=', DateValue: data.START_DATE_TO });
		if (!this.isEmpty(data.END_DATE_FROM)) search.Children.push({ Attribute: 'END_DATE', Operator: '>=', DateValue: data.END_DATE_FROM });
		if (!this.isEmpty(data.END_DATE_TO)) search.Children.push({ Attribute: 'END_DATE', Operator: '<=', DateValue: data.END_DATE_TO });
		
		// if nothing was selected, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'People Ad Hoc Report',
			columns: exportColumns,
			entity: 'AD_HOC_PEOPLE_VW',
			search: this.constructSearch(this.state.search),
			order: ['PERSON_NAME']
		});
		
		var emailData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Mailing List',
			distinct: true,
			columns: ['EMAIL'],
			entity: 'AD_HOC_PEOPLE_VW',
			search: this.constructSearch(this.state.search),
			order: ['EMAIL']
		});
			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Ad-Hoc People Report</span>
					<ChemEdit parent={this} columns={adHocSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={3} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/MailingList'} method='POST'>
								{emailData.map((emailFormField, index) => {
									return (<input key={index} type='hidden' name={emailFormField.name} value={emailFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export Email List</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Email.png'} style={{ paddingLeft: '20px' }} alt="Export Email List" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_AdHocPeople' table={this.state.adHocList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='470px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const adHocSearch = [
	{ Header: 'Appointment Type', accessor: 'TYPE_ID', type: 'list', row: 0, col: 0,
		options: { value: 'TYPE_ID', label: 'TYPE_NAME', entity: 'ROLE_TYPES', order: 'TYPE_NAME', 
			search: { Attribute: 'TYPE_NAME', Operator: 'is not null' } } },
	{ Header: 'PI', accessor: 'PI_ROLE_ID', type: 'list', row: 0, col: 1,
		options: { value: 'ROLE_ID', label: 'PI_NAME', entity: 'PEOPLE_SEARCH_PI_VW', order: 'PI_NAME' } },
	{ Header: 'Building', accessor: 'BUILDING_ID', type: 'list', row: 0, col: 2,
		options: { value: 'BUILDING_ID', label: 'BUILDING_NAME', entity: 'BUILDING', order: 'BUILDING_NAME' } },
	{ Header: 'Level', accessor: 'ROOM_LEVEL', type: 'list', row: 0, col: 3,
		options: { value: 'LEVEL_NAME', label: 'LEVEL_NAME', entity: 'FLOOR_LEVEL', order: 'LEVEL_NAME' } },
	{ Header: 'Active', accessor: 'ACTIVE_FLAG', type: 'select', row: 1, col: 0,
		options: [ { value: 'All', label: 'All' }, { value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' } ] },
	{ Header: 'Supervisor', accessor: 'SUPERVISOR', type: 'select', row: 1, col: 1,
		options: { value: 'ROLE_ID', label: 'SUP_NAME', entity: 'AD_HOC_SUPERVISORS_VW', order: 'SUP_NAME' } },
	{ Header: 'Room', accessor: 'ROOM_ID', type: 'select', row: 1, col: 2,
		options: { value: 'ROOM_ID', label: 'LOCATION', entity: 'ROOMS_VW', order: 'LOCATION' } },
	{ Header: 'Payroll Title', accessor: 'TITLE_ID', type: 'select', row: 1, col: 3,
		options: { value: 'TITLE_ID', label: 'TITLE_NAME', entity: 'PAYROLL_TITLE', order: 'TITLE_NAME' } },
	{ Header: 'Unit', accessor: 'UNIT_ID', type: 'select', row: 2, col: 0,
		options: { value: 'UNIT_ID', label: 'UNIT_NAME', entity: 'ORG_UNIT', order: 'UNIT_NAME',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' } } },
	{ Header: 'Department', accessor: 'DEPT_ID', type: 'list', row: 2, col: 1,
		options: { value: 'DEPARTMENT_ID', label: 'DEPT_NAME', entity: 'DEPARTMENT', order: 'DEPT_NAME' } },
	{ Header: 'Last Updated From', accessor: 'PEOPLE_MODIF_DATE_FROM', type: 'date', row: 2, col: 2 },
	{ Header: 'Last Updated To', accessor: 'PEOPLE_MODIF_DATE_TO', type: 'date', row: 2, col: 3 }, 
	{ Header: 'Start Date From', accessor: 'START_DATE_FROM', type: 'date', row: 3, col: 0 },
	{ Header: 'Start Date To', accessor: 'START_DATE_TO', type: 'date', row: 3, col: 1 }, 
	{ Header: 'End Date From', accessor: 'END_DATE_FROM', type: 'date', row: 3, col: 2 },
	{ Header: 'End Date To', accessor: 'END_DATE_TO', type: 'date', row: 3, col: 3 }, 
];

const adHocList = {
	query: { entity: 'AD_HOC_PEOPLE_VW', search: null, order: ['PERSON_NAME'] },
	pageSize: 10,
	columns: [
		{ Header: 'Name', accessor: 'PERSON_NAME' },
		{ Header: 'Department', accessor: 'DEPARTMENT' },
		{ Header: 'Appt. Type', accessor: 'APPT_TYPE' },
		{ Header: 'PI', accessor: 'PI_NAME' },
		{ Header: 'Supervisor', accessor: 'SUP_NAME' },
		{ Header: 'Unit', accessor: 'UNIT_NAME' },
		{ Header: 'Work Title', accessor: 'WORK_TITLE_NAME' },
		{ Header: 'Location', accessor: 'LOCATION' },
		{ Header: 'Phone', accessor: 'PHONE' },
		{ Header: 'Email', accessor: 'EMAIL' },
		{ Header: 'Emergency Info', accessor: 'EMERGENCY_CONTACT',
			Cell: props => <>{props.row.values.EMER_CELL}<br />{props.value&&(props.value + ' (' + props.row.values.EMERGENCY_CONTACT_PH + ')')}</> },
		{ Header: 'Local Address', accessor: 'LOCAL_ADDRESS', },
		{ Header: 'Forwarding Address', accessor: 'FORWARDING_ADDRESS', },
		{ Header: 'Start Date', accessor: 'START_DATE',
			Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
		{ Header: 'End Date', accessor: 'END_DATE',
			Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
		{ Header: 'Emergency Cell', accessor: 'EMER_CELL', show: false },
		{ Header: 'Emergency Contact Phone', accessor: 'EMERGENCY_CONTACT_PH', show: false }
	]
};

const exportColumns = [
	{ Header: 'Name', accessor: 'PERSON_NAME', width: 30 },
	{ Header: 'Department', accessor: 'DEPARTMENT', width: 30 },
	{ Header: 'Appt. Type', accessor: 'APPT_TYPE', width: 30 },
	{ Header: 'PI', accessor: 'PI_NAME', width: 30 },
	{ Header: 'Supervisor', accessor: 'SUP_NAME', width: 30 },
	{ Header: 'Unit', accessor: 'UNIT_NAME', width: 30 },
	{ Header: 'Work Title', accessor: 'WORK_TITLE_NAME', width: 30 },
	{ Header: 'Location', accessor: 'LOCATION', width: 30 },
	{ Header: 'Phone', accessor: 'PHONE', width: 30 },
	{ Header: 'Email', accessor: 'EMAIL', width: 30 },
	{ Header: 'Emergency Cell', accessor: 'EMER_CELL', width: 30 },
	{ Header: 'Emergency Contact', accessor: 'EMERGENCY_CONTACT', width: 30 },
	{ Header: 'Emergency Contact Phone', accessor: 'EMERGENCY_CONTACT_PH', width: 30 },
	{ Header: 'Local Address', accessor: 'LOCAL_ADDRESS', width: 40 },
	{ Header: 'Forwarding Address', accessor: 'FORWARDING_ADDRESS', width: 40 },
	{ Header: 'Start Date', accessor: 'START_DATE', width: 15 },
	{ Header: 'End Date', accessor: 'END_DATE', width: 15 }
];

export default AdHocPeople;