import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class AccessLevel extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			accessLevel: {
				ACCESS_ID: null,
				ACCESS_NAME: '',
				VIEW_APP: 'N',
				CHANGE_DATA: 'N',
				ADMIN_APP: 'N'
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadAccessLevel();
	}

	loadAccessLevel(event) {
		var self = this;
		
		if (this.props.params.accessLevelId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'ACCESS_LEVEL',
					search: { Attribute: 'ACCESS_ID', Operator: '=', LongValue: this.props.params.accessLevelId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length === 1) {
						var accessLevel = data.Data[0];
																		
						self.mergeState({
							accessLevel: accessLevel,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Access Level Error', data.Data.length + ' access levels found for ID ' + self.props.params.accessLevelId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				accessLevel: {
					ACCESS_ID: '(new)'
				}
			});
		}
	}
		
	onChange(accessor, value) {
		var accessLevel = this.copyObject(this.state.accessLevel);
		this.setByAccessor(accessLevel, accessor, value);
		this.mergeState({ accessLevel: accessLevel, renderKey: this.state.renderKey + 1 });
	}
		
	deleteAccessLevel(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Access Level', 'Delete Access Level ' + this.props.params.accessLevelId + '?', (okClicked) => {
			if (okClicked) {				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Delete',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'ACCESS_LEVEL', data: this.state.accessLevel }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Access Level {self.props.params.accessLevelId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
		
	onSubmit(event) {
		var self = this;		
		
		// make copy of data
		var accessLevel = this.copyObject(this.state.accessLevel);

		// if this is a new access level, set ID to sequence name
		var url;
		if (this.props.params.accessLevelId) {
			url = this.getConfig().host + '/Home/Update';
		} else {
			url = this.getConfig().host + '/Home/Insert';
			accessLevel.ACCESS_ID = 'access_id_seq.nextval';
		}			
		
		this.ajax({
			type: 'POST',
			url: url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'ACCESS_LEVEL', data: accessLevel }
		}).done(function (data) {
			if (data.Success) {
				var accessLevelId = self.props.params.accessLevelId ? accessLevel.ACCESS_ID : data.ID;
				var verb = self.props.params.accessLevelId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Access Level <Link to={'/BuildingAccess/AccessLevels/' + accessLevelId}>{accessLevelId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Access Level {this.state.accessLevel.ACCESS_ID}</div>
				<ChemEdit parent={this} columns={accessLevelColumns} data={this.state.accessLevel} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					{this.props.params.accessLevelId && <Button id="delete" variant="warning" type="button" className="float-end"
					  style={{ marginRight: '20px' }} onClick={(event) => this.deleteAccessLevel(event)}>Delete</Button>} 
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const accessLevelColumns = [
	{ Header: 'Name', accessor: 'ACCESS_NAME', required: true },
	{ Header: 'View App', accessor: 'VIEW_APP', type: 'select',
		options: [{ value: 'N', label: 'No' }, { value: 'Y', label: 'Yes' }]},
	{ Header: 'Change Data', accessor: 'CHANGE_DATA', type: 'select',
		options: [{ value: 'N', label: 'No' }, { value: 'Y', label: 'Yes' }]},
	{ Header: 'Admin App', accessor: 'ADMIN_APP', type: 'select',
		options: [{ value: 'N', label: 'No' }, { value: 'Y', label: 'Yes' }]}
];

export default withRouter(AccessLevel);
