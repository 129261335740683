import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Authorization extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			authorization: {
				AUTH_ID: null,
				ROLE_ID: null,
				TYPE_ID: null,
				UNIT_ID: null,
				PI_ROLE_ID: null,
				APPLICATION_ID: null,
				ACCESS_ID: null,
				AUTH_BEG_DATE: null,
				AUTH_END_DATE: null,
				COMMENTS: '',
				PC_SYSTEM: null,
				END_DATE: null
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadAuthorization();
	}

	loadAuthorization(event) {
		var self = this;
		
		if (this.props.params.authorizationId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'AUTHORIZATION',
					search: { Attribute: 'AUTH_ID', Operator: '=', LongValue: this.props.params.authorizationId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length === 1) {
						var authorization = data.Data[0];
																		
						self.mergeState({
							authorization: authorization,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Authorization Error', data.Data.length + ' authorizations found for ID ' + self.props.params.authorizationId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				authorization: {
					AUTH_ID: '(new)'
				}
			});
		}
	}
		
	onChange(accessor, value) {
		var authorization = this.copyObject(this.state.authorization);
		this.setByAccessor(authorization, accessor, value);
		this.mergeState({ authorization: authorization, renderKey: this.state.renderKey + 1 });
	}
		
	deleteAuthorization(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Authorization', 'Delete Authorization ' + this.props.params.authorizationId + '?', (okClicked) => {
			if (okClicked) {
				// prepare authorization for deletion
				var authorization = self.copyObject(self.state.authorization);
				authorization.AUTH_BEG_DATE = this.dateTimeToMVC(this.getDate(authorization.AUTH_BEG_DATE));
				authorization.AUTH_END_DATE = this.dateTimeToMVC(new Date());
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'AUTHORIZATION', data: authorization }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Authorization {self.props.params.authorizationId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
	
	validateAuthorization(event, columns, data) {
		// exactly one of the following must be entered
		var validation = "";
		var numEntries = 0;
		if (!this.isEmpty(this.state.authorization.ROLE_ID)) numEntries++;
		if (!this.isEmpty(this.state.authorization.TYPE_ID)) numEntries++;
		if (!this.isEmpty(this.state.authorization.UNIT_ID)) numEntries++;
		if (!this.isEmpty(this.state.authorization.PI_ROLE_ID)) numEntries++;
		if (numEntries < 1) {
			validation = "One of the following is required: Person, Role Type, Unit, or Supervisor. ";
		} else if (numEntries > 1) {
			validation = "Please specify only one of the following: Person, Role Type, Unit, or Supervisor. ";
		}

		var defaultValidation = this.validate(columns, data);
		if (defaultValidation !== null) validation += defaultValidation;
		if (this.isEmpty(validation)) return null;
		return validation;
	}
		
	onSubmit(event) {
		var self = this;		
		
		// make copy of data
		var authorization = this.copyObject(this.state.authorization);
		
		// if pi was specified, copy it into role
		if (!this.isEmpty(authorization.PI_ROLE_ID)) authorization.ROLE_ID = authorization.PI_ROLE_ID;

		// convert dates
		authorization.AUTH_BEG_DATE = this.dateTimeToMVC(this.getDate(authorization.AUTH_BEG_DATE));
		authorization.AUTH_END_DATE = this.dateTimeToMVC(this.getDate(authorization.AUTH_END_DATE));

		// if this is a new authorization, set ID to sequence name
		var url;
		if (this.props.params.authorizationId) {
			url = this.getConfig().host + '/Home/Update';
		} else {
			url = this.getConfig().host + '/Home/Insert';
			authorization.AUTH_ID = 'auth_id_seq.nextval';
		}			
		
		this.ajax({
			type: 'POST',
			url: url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'AUTHORIZATION', data: authorization }
		}).done(function (data) {
			if (data.Success) {
				var authorizationId = self.props.params.authorizationId ? authorization.AUTH_ID : data.ID;
				var verb = self.props.params.authorizationId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Authorization <Link to={'/BuildingAccess/Authorizations/' + authorizationId}>{authorizationId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Authorization {this.state.authorization.AUTH_ID}</div>
				<ul>
				  <li>Please select only one of the following: Person, Role, Unit, or Supervisor.</li>
				</ul>
				<ChemEdit parent={this} columns={authorizationColumns} data={this.state.authorization} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}
				   validate={(event, columns, data) => this.validateAuthorization(event, columns, data)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					{this.props.params.authorizationId && <Button id="delete" variant="warning" type="button" className="float-end"
					  style={{ marginRight: '20px' }} onClick={(event) => this.deleteAuthorization(event)}>Delete</Button>} 
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const authorizationColumns = [
	{ Header: 'Add By Person', accessor: 'ROLE_ID', type: 'select',
		options: { value: 'ROLE_ID', label: 'ROLE_DESC', entity: 'AUTH_ROLE_SELECT_VW', order: 'ROLE_DESC' } },
	{ Header: 'Add By Role', accessor: 'TYPE_ID', type: 'select',
		options: { value: 'TYPE_ID', label: 'TYPE_DESC', entity: 'ROLE_TYPES_VW', order: 'TYPE_DESC' } },
	{ Header: 'Add By Unit', accessor: 'UNIT_ID', type: 'select',
		options: { value: 'UNIT_ID', label: 'UNIT_DESC', entity: 'ORG_UNIT_VW', order: 'UNIT_DESC' } }, 
	{ Header: 'Add By Supervisor', accessor: 'PI_ROLE_ID', type: 'select',
		options: { value: 'PI_ROLE_ID', label: 'PI_NAME', entity: 'AUTH_PI_SELECT_VW', order: ['SELF_PI_FIRST', 'PI_NAME'] } },
	{ Header: 'Application', accessor: 'APPLICATION_ID', type: 'select', required: true,
		options: { value: 'APPLICATION_ID', label: 'APPLICATION_NAME', entity: 'AUTH_APP_SELECT_VW', order: 'APPLICATION_NAME' } },
	{ Header: 'Access Level', accessor: 'ACCESS_ID', type: 'select', required: true,
		options: { value: 'ACCESS_ID', label: 'ACCESS_NAME', entity: 'ACCESS_LEVEL', order: 'ACCESS_NAME' }},
	{ Header: 'Auth Begin Date', accessor: 'AUTH_BEG_DATE', type: 'date', required: true },
	{ Header: 'Auth End Date', accessor: 'AUTH_END_DATE', type: 'date' },
	{ Header: 'Comments', accessor: 'COMMENTS' }
];

export default withRouter(Authorization);
