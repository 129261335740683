import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import ChemComponent from '../ChemComponent';
import { Link } from 'react-router-dom';

class Home extends ChemComponent {
	
	constructor(props) {
		super(props);
		
		this.state = {
			begDate: null,
			endDate: null,
			prelimEnd: null,
			prelimEndRA: null,
			renderKey: 0
		}
	}
	
	componentDidMount() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'CURRENT_CYCLE_VW',
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				if (data.Data.length === 1) {
					var cycle = data.Data[0];
																	
					self.mergeState({
						begDate: self.getDate(cycle.BEG_DATE),
						endDate: self.getDate(cycle.END_DATE),
						prelimEnd: self.getDate(cycle.PRELIM_END),
						prelimEndRA: self.getDate(cycle.NEXT_WEEKDAY),
						renderKey: self.state.renderKey + 1
					});
				} else {
					self.showAlert('Current Cycle Error', data.Data.length + ' cycles found.');
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	renderClosingDates() {
		if (this.props.user.crs.userAccessID === 1) {
			return (<>
				<li>Customers Closes {this.state.prelimEnd && (this.state.prelimEnd.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' }) + ' at 2:00 pm')}</li>
				<li>RAs Closes {this.state.prelimEndRA && (this.state.prelimEndRA.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' }) + ' at 12:00 pm')}</li>
			</>);
		} else if (this.props.user.crs.raFlag) {
			return (
				<li>Prelim Closes {this.state.prelimEndRA && (this.state.prelimEndRA.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' }) + ' at 12:00 pm')}</li>
			);
		} else {
			return (
				<li>Prelim Closes {this.state.prelimEnd && (this.state.prelimEnd.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' }) + ' at 2:00 pm')}</li>
			);
		}		
	}
	
	submitFormFromLink(event, formID) {
		event.preventDefault();
		var formElement = document.getElementById(formID);
		formElement.submit();
	}
	
	render() {
		return(
			<Container fluid>
				<Row>
					<Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
						<ul>
							<li>{this.state.endDate && (this.state.endDate.toLocaleDateString('en-US', { month: 'long' }) + ' ')}
								Billing Month
								({this.state.begDate && this.state.begDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' })}
								&nbsp;-&nbsp; 
								{this.state.endDate && this.state.endDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' })})</li>
							{this.renderClosingDates()}
						</ul>
						<h2 style={{textAlign: "center"}}>Frequently Asked Questions</h2>
						<br />
						<Accordion>
							<Accordion.Item eventKey="0">
								<Accordion.Header>How do I change speedtypes that are being used in&nbsp;<strong>this month's</strong>&nbsp;recharges?</Accordion.Header>
								<Accordion.Body>
									{this.props.user.crs.raFlag ?
										<Form id='frmGetChangeSpeedtypePDF' action={this.getConfig().host + '/Home/GetPDF'} method='POST'>
											<input type='hidden' name='__RequestVerificationToken' value={this.props.user.antiForgeryToken} />
											<input type='hidden' name='path' value='How to change speedtypes used in current month.pdf' />
											<Link to={{}} onClick={(event) => this.submitFormFromLink(event, 'frmGetChangeSpeedtypePDF')}>
												Click here for screenshot showing step by step procedure
											</Link>.
										</Form> :
										<>You need to contact your RA to request a change of speedtype for the current recharge billing cycle. Please include all pertinent information such as the speedtype to change, the new speedtype, and the affected services.<br />
										You may want to consider updating the default speedtype for user:<br />
										To change default speedtypes for Storeroom and Cylinder cages, use the <a href="https://chemnet.berkeley.edu/BuildingAccess" title="College Access System" target="_blank" rel="noreferrer">College Access System</a>.<br />
										To change the default speedtype for the MicroAnalytical lab, log into the <a href="https://chemapps.berkeley.edu/xnet/FAS/Chemistry/fas/index.cfm?action=main.alogon" target="_blank" title="FAS application" rel="noreferrer">FAS application</a> and change the speedtype under application administration. <br />
										For all other services, please <a href="https://chemapps.berkeley.edu/inet/CRS/Administration/contacts.cfm" title="Contacts page" target="_blank" rel="noreferrer">contact</a> the facility manager.</>} 
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="1">
								<Accordion.Header>How can I add/remove access to view my PI's reports?</Accordion.Header>
								<Accordion.Body>
									You need to update your appointments using the <a href="https://chemnet.berkeley.edu/BuildingAccess" title="College Access System" target="_blank" rel="noreferrer">College Access System</a>. Make sure that your PI(s) and staff unit (such as FSA, Financial Services, Shops...) are correct as they automatically set your review access.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="2">
								<Accordion.Header>I missed the open preliminary period to change speedtypes. How do I correct a charge that has posted to the wrong chartstring?</Accordion.Header>
								<Accordion.Body>
									<a href="https://chemapps.berkeley.edu/inet/CRS/Administration/admin_list.cfm" title="Pi/Ra list" target="_blank" rel="noreferrer">Contact your RA</a> to request a cost transfer journal.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="3">
								<Accordion.Header>How do I find out how much I've been charged?</Accordion.Header>
								<Accordion.Body>
									<Form id='frmHowMuchIOwePDF' action={this.getConfig().host + '/Home/GetPDF'} method='POST'>
										<input type='hidden' name='__RequestVerificationToken' value={this.props.user.antiForgeryToken} />
										<input type='hidden' name='path' value='How do I find out how much I.pdf' />
										<Link to={{}} onClick={(event) => this.submitFormFromLink(event, 'frmHowMuchIOwePDF')}>
											Click here for step by step procedure on how to retrieve past billing statements
										</Link>.
									</Form>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="4">
								<Accordion.Header>I would like to create/update a speedtype. Who should I contact?</Accordion.Header>
								<Accordion.Body>
									Email <a href="mailto:chemrecharge@berkeley.edu" title="chemrecharge@berkeley.edu" target="_blank" rel="noreferrer">chemrecharge@berkeley.edu</a> with full chartstring with any other details pertaining to the speedtype.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="5">
								<Accordion.Header>How can I create/update a default speedtype used for the College of Chemistry recharges for a specific user?</Accordion.Header>
								<Accordion.Body>
									You may change default speedtypes for Storerooom and Cylinder cages using the <a href="https://chemnet.berkeley.edu/BuildingAccess" title="College Access System" target="_blank" rel="noreferrer">College Access System</a>.<br />
									To change the default speedtype for the MicroAnalytical lab, log into the <a href="https://chemapps.berkeley.edu/xnet/FAS/Chemistry/fas/index.cfm?action=main.alogon" target="_blank" title="FAS application" rel="noreferrer">FAS application</a> and change the speedtype under application administration. <br />
									For all other services, please <a href="https://chemapps.berkeley.edu/inet/CRS/Administration/contacts.cfm" title="Contacts page" target="_blank" rel="noreferrer">contact</a> the facility manager.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="6">
								<Accordion.Header>How can I delete/stop all charges on a speedtype?</Accordion.Header>
								<Accordion.Body>
									Email <a href="mailto:chemrecharge@berkeley.edu" title="chemrecharge@berkeley.edu" target="_blank" rel="noreferrer">chemrecharge@berkeley.edu</a> to delete/stop all charges on a speedtype.<br /> 
									<em>*Please understand that speedtypes may not be closed during the last week of the billing cycle unless a valid alternative speedtype is provided.</em>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="7">
								<Accordion.Header>How long do I have to dispute a charge once the charge has been posted?</Accordion.Header>
								<Accordion.Body>
									Per the recharge billing policy, customers have 60 days from the time a recharge is posted to the general ledger to dispute the charge.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="8">
								<Accordion.Header>I think I'm being charged for someone else's transactions. Who should I contact?</Accordion.Header>
								<Accordion.Body>
									Contact the facility manager for specific details regarding a transaction. You may find their contact information <a href="https://chemapps.berkeley.edu/inet/CRS/Administration/contacts.cfm" title="Contacts page" target="_blank" rel="noreferrer">here</a>.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="9">
								<Accordion.Header>My PI's lab is relocating. How will this affect my group?</Accordion.Header>
								<Accordion.Body>
									If your PI has an open meter with liquid air plant, please email the <a href="https://chemapps.berkeley.edu/inet/CRS/Administration/contacts.cfm" title="Contacts page" target="_blank" rel="noreferrer">Liquid Air Plant managers</a> and copy in <a href="mailto:chemrecharge@berkeley.edu" title="chemrecharge@berkeley.edu" target="_blank" rel="noreferrer">chemrecharge@berkeley.edu</a> to ensure your meter is appropriately closed and reassigned in the new location.<br /><br />
									Verify that all meter changes have been made correctly by reviewing subsequent recharge statements.<br /><br />
									If you do not request a move of your meter, the meter that was assigned in your old lab may continue to be charged to your PI's account even if another lab is using it (meter assigning is to the lab room, not the PI - making it very difficult to know if we are charging the correct meter to your PI).
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="10">
								<Accordion.Header>I need to request access to ship through FedEx. Who should I contact?</Accordion.Header>
								<Accordion.Body>
									Email <a href="mailto:chemrecharge@berkeley.edu" title="chemrecharge@berkeley.edu" target="_blank" rel="noreferrer">chemrecharge@berkeley.edu</a> for request.<br />
									<em>*Please note that FedEx access given by chemrecharge@berkeley.edu is for users under the College of Chemistry only and requires a valid speedtype.</em>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
						<br />
					</Col>
				</Row>
			</Container>
		);
	}
}

export default Home;