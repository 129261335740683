import ChemComponent from './ChemComponent';
import { Col, Container, Row } from 'react-bootstrap';

class Confirm extends ChemComponent {

	render() {		
		return (
			<Container fluid>
			  <Row>
			    <Col xs={12} sm={11} md={10} lg={9} xl={8} style={{ paddingBottom: '20px', paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
					{this.isFunction(this.props.message) ? this.props.message() : this.props.message}
				</Col>
			  </Row>
			</Container>
		);
	}
}

export default Confirm
