import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';
import ChemTable from '../ChemTable';
import { withRouter } from '../withRouter';

import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class People extends ChemComponent {
  	constructor(props) {
		super(props);
		
		this.state = {
			renderKey: 1,
			people: [],
			peopleCount: 0,
			pageNumber: 1,
			pageSize: 100,
			pageCount: 1,
			movePerson: 0,
			moveSteps: 0,
			search: {
				LNAME: '',
				FNAME: '',
				PI_ROLE_ID: '',
				SUPERVISOR: '',
				ROLE_TYPE_ID: '',
				PAYROLL_TITLE: '',
				DEPARTMENT_ID: '',
				UNIT_ID: '',
				START_DATE_FROM: null,
				START_DATE_TO: null,
				END_DATE_FROM: null,
				END_DATE_TO: null,
				CASUAL_FLAG: 'All',
				CARD_ID: '',
				PHONE: '',
				BUILDING: '',
				KEY: '',
				EMAIL: '',
				SPEEDTYPE: '',
				ACTIVE_FLAG: 'Yes'
			}
		};
	}

	componentDidMount() {
		this.onSubmitSearch(null, this.state.search);
	}
	
	loadPeople(pageNumber, pageSize, search, event) {
		var self = this;
		
		if (!pageNumber) pageNumber = this.state.pageNumber;
		if (!pageSize) pageSize = this.state.pageSize;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Home/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				distinct: true,
				columns: [ 'PERSON_ID', 'PERSON_NAME', 'LNAME', 'FNAME', 'TYPE_NAME', 'PHONE', 'PI_NAME', 'PAYROLL_TITLE',
					'DEPARTMENT', 'EMAIL', 'START_DATE', 'END_DATE', 'LOCATION', 'ACTIVE_FLAG', 'CASUAL_FLAG', 'SPEEDTYPE' ],
				entity: 'PEOPLE_SEARCH_VW',
				search: this.constructSearch(search || this.state.search),
				order: ['LNAME', 'FNAME'],
				pageNumber: pageNumber,
				pageSize: pageSize
			}
		}).done(function (data) {
			if (data.Success) {
				// for each person
				for (var i = 0; i < data.Data.length; i++) {
					// add an id to the row
					data.Data[i].id = i;
					data.Data[i].drag = i;
				}
			
				self.mergeState({
					renderKey: self.state.renderKey + 1,
					people: data.Data,
					peopleCount: data.ResultCount,
					pageNumber: data.PageNumber,
					pageCount: data.PageCount,
					pageSize: data.PageSize
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
		
	addPerson() {
		this.props.navigate('/BuildingAccess/People/new');
	}
	
	editPerson(rowId) {
		// look for personID in data
		var personId = -1;
		for (var i = 0; i < this.state.people.length; i++) {
			if (this.state.people[i].id === rowId) {
				personId = this.state.people[i].PERSON_ID;
				break;
			}
		}
		if (personId !== -1) {
			this.props.navigate('/BuildingAccess/People/' + personId);
		} else {
			this.showAlert('ID not found', 'Could not find a person ID for row ' + rowId);
		}
	}
	
	moveRow(personId, nSteps) {
		// not implemented
	}
	
	dropRow() {
		// not implemented
	}
	
	firstPage() {
		if (this.state.pageNumber !== 1) {
			this.loadPeople(1, this.state.pageSize);
		}
	}
	
	onFileChange(event) {
		var file = event.target.files[0];
		// reject files that are too large
		if (file.size > 10000000) file = null; 
		this.mergeState({ selectedFile: file });
	}
	
	previousPage() {
		if (this.state.pageNumber > 1) {
			this.loadPeople(this.state.pageNumber - 1, this.state.pageSize);
		}		
	}
	
	nextPage() {
		// server will correct if page number is too big
		this.loadPeople(this.state.pageNumber + 1, this.state.pageSize);		
	}
	
	lastPage() {
		// use -1 to indicate last page since we don't 
		// necessarily know how many pages there are now
		this.loadPeople(-1, this.state.pageSize);
	}
	
	setPageSize(pageSize) {
		if (pageSize !== this.state.pageSize) {
			this.loadPeople(this.state.pageNumber, pageSize);
		}
	}
	
	setPageNumber(pageNumber) {
		if (pageNumber !== this.state.pageNumber) {
			this.loadPeople(pageNumber, this.state.pageSize);
		}
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		this.loadPeople(this.state.pageNumber, this.state.pageSize, this.state.search, event);
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			children: []
		};

		if (!this.isEmpty(data.LNAME)) search.children.push({ Attribute: 'LNAME', Operator: '=', StringValue: data.LNAME.toLowerCase() });
		if (!this.isEmpty(data.FNAME)) search.children.push({ Attribute: 'FNAME', Operator: '=', StringValue: data.FNAME.toLowerCase() });
		if (!this.isEmpty(data.PI_ROLE_ID)) search.children.push({ Attribute: 'PI_ROLE_ID', Operator: '=', LongValue: data.PI_ROLE_ID });
		if (!this.isEmpty(data.SUPERVISOR)) search.children.push({ Attribute: 'SUPERVISOR', Operator: '=', LongValue: data.SUPERVISOR });
		if (!this.isEmpty(data.ROLE_TYPE_ID)) search.children.push({ Attribute: 'TYPE_ID', Operator: '=', LongValue: data.ROLE_TYPE_ID });
		if (!this.isEmpty(data.PAYROLL_TITLE)) search.children.push({ Attribute: 'PAYROLL_TITLE', Operator: '=', LongValue: data.PAYROLL_TITLE });
		if (!this.isEmpty(data.DEPARTMENT_ID)) {
			search.children.push({ 
				Operator: 'or',
				Children: [
					{ Attribute: 'DEPT_ID', Operator: '=', LongValue: data.DEPARTMENT_ID },
					{ Attribute: 'DEPT2_ID', Operator: '=', LongValue: data.DEPARTMENT_ID }					
				]
			});
		}
		if (!this.isEmpty(data.UNIT_ID)) search.children.push({ Attribute: 'UNIT_ID', Operator: '=', LongValue: data.UNIT_ID });
		if (!this.isEmpty(data.START_DATE_FROM)) search.children.push({ Attribute: 'START_DATE', Operator: '>=', DateValue: this.dateTimeToMVC(data.START_DATE_FROM) });
		if (!this.isEmpty(data.START_DATE_TO)) search.children.push({ Attribute: 'START_DATE', Operator: '<=', DateValue: this.dateTimeToMVC(data.START_DATE_TO) });
		if (!this.isEmpty(data.END_DATE_FROM)) search.children.push({ Attribute: 'END_DATE', Operator: '>=', DateValue: this.dateTimeToMVC(data.END_DATE_FROM) });
		if (!this.isEmpty(data.END_DATE_TO)) search.children.push({ Attribute: 'END_DATE', Operator: '<=', DateValue: this.dateTimeToMVC(data.END_DATE_TO) });
		if (data.CASUAL_FLAG === 'Yes' || data.CASUAL_FLAG === 'No') {
			if (data.CASUAL_FLAG === 'Yes') {
				search.children.push({ Attribute: 'CASUAL_FLAG', Operator: '=', StringValue: 'Y' });
			} else {
				search.children.push({ Attribute: 'CASUAL_FLAG', Operator: 'is null' });
			}
		}
		if (!this.isEmpty(data.CARD_ID)) search.children.push({ Attribute: 'CARD_ID', Operator: '=', StringValue: data.CARD_ID });
		if (!this.isEmpty(data.PHONE)) search.children.push({ Attribute: 'PHONE', Operator: '=', StringValue: data.PHONE });
		if (!this.isEmpty(data.BUILDING)) search.children.push({ Attribute: 'LOCATION', Operator: 'like', StringValue: '%' + data.BUILDING + '%' });
		if (!this.isEmpty(data.KEY)) search.children.push({ Attribute: 'KEY', Operator: '=', StringValue: data.KEY });
		if (!this.isEmpty(data.EMAIL)) search.children.push({ Attribute: 'EMAIL', Operator: '=', StringValue: data.EMAIL });
		if (!this.isEmpty(data.SPEEDTYPE)) search.children.push({ Attribute: 'SPEEDTYPE', Operator: '=', StringValue: data.SPEEDTYPE });
		if (data.ACTIVE_FLAG === 'Yes' || data.ACTIVE_FLAG === 'No') {
			if (data.ACTIVE_FLAG === 'Yes') {
				search.children.push({ Operator: 'and', Children: [
					{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
					{ Operator: 'or', Children: [
						{ Attribute: 'ROLE_END_DATE', Operator: '>=', DateValue: new Date() },
						{ Attribute: 'ROLE_END_DATE', Operator: 'is null' }]}]});
			} else {
				search.children.push({ Operator: 'or', Children: [
					{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'N' },
					{ Operator: 'and', Children: [
						{ Attribute: 'ROLE_END_DATE', Operator: 'is not null' },
						{ Attribute: 'ROLE_END_DATE', Operator: '<=', DateValue: new Date() }]}]});
			}
		}
		
		// if nothing was selected, forget it
		if (search.children.length === 0) search = null;
		return search;
	}
	
	render() {
		
		const search = [
			{ Header: 'Last Name', accessor: 'LNAME', row: 0, col: 0 },
			{ Header: 'First Name', accessor: 'FNAME', row: 0, col: 1 },
			{ Header: 'PI', accessor: 'PI_ROLE_ID', row: 0, col: 2, type: 'select',
				options: { value: 'ROLE_ID', label: 'PI_NAME', entity: 'PEOPLE_SEARCH_PI_VW', order: [ 'PI_NAME' ] } },
			{ Header: 'Supervisor', accessor: 'SUPERVISOR', row: 0, col: 3, type: 'select',
				options: { value: 'ROLE_ID', label: 'SUP_NAME', entity: 'PEOPLE_SEARCH_SUPERVISORS_VW', order: [ 'SUP_NAME' ] } },
			{ Header: 'Appt Type', accessor: 'ROLE_TYPE_ID', row: 1, col: 0, type: 'select',
				options: { value: 'TYPE_ID', label: 'TYPE_NAME', entity: 'ROLE_TYPES', order: [ 'TYPE_NAME' ] } },
			{ Header: 'Payroll Title', accessor: 'PAYROLL_TITLE', row: 1, col: 1, type: 'select',
				options: { value: 'TITLE_ID', label: 'TITLE_NAME', entity: 'PAYROLL_TITLE',
					search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }, order: 'TITLE_NAME' } },
			{ Header: 'Department', accessor: 'DEPARTMENT_ID', row: 1, col: 2, type: 'select', 
				options: { value: 'DEPARTMENT_ID', label: 'DEPT_NAME', entity: 'DEPARTMENT', order: [ 'DEPT_NAME' ] } },
			{ Header: 'Work Unit', accessor: 'UNIT_ID', row: 1, col: 3, type: 'select',
				options: { value: 'UNIT_ID', label: 'UNIT_NAME', entity: 'ORG_UNIT', search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }, order: [ 'UNIT_NAME' ] } },
			{ Header: 'Start Date From', accessor: 'START_DATE_FROM', row: 2, col: 0, type: 'date' },
			{ Header: 'Start Date To', accessor: 'START_DATE_TO', row: 2, col: 1, type: 'date' },
			{ Header: 'End Date From', accessor: 'END_DATE_FROM', row: 2, col: 2, type: 'date' },
			{ Header: 'End Date To', accessor: 'END_DATE_TO', row: 2, col: 3, type: 'date' },
			{ Header: 'Casual', accessor: 'CASUAL_FLAG', row: 3, col: 0, type: 'select',
				options: [ { value: 'All', label: 'All' }, { value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' } ] },
			{ Header: 'Card ID', accessor: 'CARD_ID', row: 3, col: 1 },
			{ Header: 'Work Phone', accessor: 'PHONE', row: 3, col: 2 },
			{ Header: 'Building', accessor: 'BUILDING', row: 3, col: 3, type: 'select', 
				options: { value: 'BUILDING_NAME', label: 'BUILDING_NAME', entity: 'BUILDING', order: [ 'BUILDING_NAME' ],
					search: { Attribute: 'DELETE_FLAG', Operator: 'is null' } } },
			{ Header: 'Key', accessor: 'KEY', row: 4, col: 0 },
			{ Header: 'Email', accessor: 'EMAIL', row: 4, col: 1 },
			{ Header: 'Speedtype', accessor: 'SPEEDTYPE', row: 4, col: 2 },
			{ Header: 'Active', accessor: 'ACTIVE_FLAG', row: 4, col: 3, type: 'select',
				options: [ { value: 'All', label: 'All' }, { value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' } ] },
 		];

		const columns = [
			{ Header: <PlusSquare style={{ cursor: 'pointer' }} onClick={() => this.addPerson()} />, accessor: 'id', 
				Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => this.editPerson(props.value)} />, width: 25 },
			{ Header: 'ID', accessor: 'PERSON_ID', width: 75 },
			{ Header: 'Name', accessor: 'PERSON_NAME', width: 250, 
				Cell: props => <Link to={'/BuildingAccess/People/' + props.row.values.PERSON_ID}>{props.value}</Link> },
			{ Header: 'Appointment', accessor: 'TYPE_NAME', width: 250 },
			{ Header: 'Department', accessor: 'DEPARTMENT', width: 300 },
			{ Header: 'Phone', accessor: 'PHONE', width: 250 },
			{ Header: 'Email', accessor: 'EMAIL', width: 250 },
			{ Header: 'PI', accessor: 'PI_NAME', width: 250 },
			{ Header: 'Start Date', accessor: 'START_DATE', Cell: props => props.value && this.dateToString(this.getDate(props.value)) },
			{ Header: 'End Date', accessor: 'END_DATE', Cell: props => props.value && this.dateToString(this.getDate(props.value)) },
			{ Header: 'Location', accessor: 'LOCATION', width: 250 },
			{ Header: 'Active', accessor: 'ACTIVE_FLAG', width: 70 }
		];
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'People Search',
			distinct: true,
			columns: [ 
				{ Header: 'ID', accessor: 'PERSON_ID', width: 8 },
				{ Header: 'Name', accessor: 'PERSON_NAME', width: 25 },
				{ Header: 'Appointment', accessor: 'TYPE_NAME', width: 25 },
				{ Header: 'Department', accessor: 'DEPARTMENT', width: 30 },
				{ Header: 'Phone', accessor: 'PHONE', width: 25 },
				{ Header: 'Email', accessor: 'EMAIL', width: 25 },
				{ Header: 'PI', accessor: 'PI_NAME', width: 25 },
				{ Header: 'Start Date', accessor: 'START_DATE', width: 15 },
				{ Header: 'End Date', accessor: 'END_DATE', width: 15 },
				{ Header: 'Location', accessor: 'LOCATION', width: 25 },
				{ Header: 'Active', accessor: 'ACTIVE_FLAG', width: 7 },
				{ accessor: 'FNAME', hidden: true },
				{ accessor: 'LNAME', hidden: true }
			],
			entity: 'PEOPLE_SEARCH_VW',
			search: this.constructSearch(this.state.search),
			order: ['LNAME','FNAME']
		});

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Find a Person in the College of Chemistry</span>
					<ChemEdit parent={this} columns={search} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={2} style={{ marginTop: 8}}>
							{this.state.peopleCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</form>
						</Col>
						<Col xs={8}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<ChemTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_People' columns={columns} data={this.state.people} offsetHeight='375px'
						  pageNumber={this.state.pageNumber} pageSize={this.state.pageSize} pageCount={this.state.pageCount}/>
					</Col>
				</Row>
			</Container>
		</>);
	}
}



export default withRouter(People);