import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemTable from '../ChemTable';
import { withRouter } from '../withRouter';
import { Link } from 'react-router-dom';

class ReconcileGN2Upload extends ChemComponent {
	constructor(props) {
		super(props);
		
		// strip out meter readings that are ready for upload
		var meterReadings = [];
		for (var i = 0; i < props.meterReadings.length; i++) {
			if (props.meterReadings[i].action !== 'Upload') {
				var meterReading = this.copyObject(props.meterReadings[i]);
				meterReading.dateBilled = this.dateToString(this.getDate(meterReading.dateBilled));
				meterReading.enteredDate = this.dateToString(this.getDate(meterReading.enteredDate));
				meterReading.issue = this.analyzeMeterReading(meterReading);
				// choose default action for issue
				if (meterReading.issue.indexOf('Wrong') === 0 ||
					meterReading.issue.indexOf('Missing') === 0 ||
					meterReading.issue.indexOf('Nothing') === 0 ||
					meterReading.issue.indexOf('Unknown') === 0 ||
					meterReading.issue.indexOf('Already') === 0) {
					meterReading.resolution = 'Skip';
				} else if (meterReading.issue.indexOf('Writeoff') === 0) {
					meterReading.resolution = 'Writeoff';
				} else if (meterReading.issue.indexOf('Double') === 0) {
					// if the entire thing overlaps, skip it
					if (meterReading.issue.indexOf('entire') > 0) {
						meterReading.resolution = 'Skip';
					} else {
						meterReading.resolution = 'Adjust';
					}
				} else if (meterReading.issue.indexOf('Inactive')) {
					meterReading.resolution = 'Reactivate';
				} else {
					meterReading.resolution = 'Skip';
				}
				meterReadings.push(meterReading);
			}
		}

		this.state = {
			meterReadings: meterReadings,
			renderKey: 0
		};
	}
	
	analyzeMeterReading(meterReading) {
		if (meterReading.meterType !== 'N2') return 'Wrong meter type';
		if (this.isEmpty(meterReading.readingID)) return 'Missing initial meter reading';
		if (this.numericAndEqual(meterReading.uploadPastAmount, meterReading.uploadNewAmount)) return 'Nothing to bill';
		if (this.numericAndEqual(meterReading.existingPastAmount, meterReading.uploadPastAmount) &&
			this.numericAndEqual(meterReading.existingNewAmount, meterReading.uploadNewAmount)) {
			return 'Already uploaded';
		}
		var mrExistingAmount = this.isEmpty(meterReading.existingNewAmount) ? meterReading.existingPastAmount : meterReading.existingNewAmount;
		if (this.numericAndLessThan(mrExistingAmount, meterReading.uploadPastAmount)) {
			return 'Writeoff amount of ' + (meterReading.uploadPastAmount - mrExistingAmount);
		}
		if (this.numericAndLessThan(meterReading.uploadPastAmount, mrExistingAmount)) {
			// is the entire thing double billed?
			if (this.numericAndLTE(meterReading.uploadNewAmount, mrExistingAmount)) {
				return 'Double billing of entire amount';
			} else {
				return 'Double billing of ' + (mrExistingAmount - meterReading.uploadPastAmount);
			}
		}
		if (meterReading.dateBilled) return 'Inactive meter';
			
		return 'Unknown error';
	}
	
	numericAndEqual(a, b) {
		if (!this.isNumeric(a)) return false;
		if (!this.isNumeric(b)) return false;
		return +a === +b;
	}
	
	numericAndLessThan(a, b) {
		if (!this.isNumeric(a)) return false;
		if (!this.isNumeric(b)) return false;
		return +a < +b;
	}		

	numericAndLTE(a, b) {
		if (!this.isNumeric(a)) return false;
		if (!this.isNumeric(b)) return false;
		return +a <= +b;
	}
	
	findMeterReading(meterReading) {
		// use the state meter reading if there is one, otherwise return what was passed in
		for (var i = 0; i < this.state.meterReadings.length; i++) {
			if (this.state.meterReadings[i].readingID === meterReading.readingID) {
				return this.state.meterReadings[i];
			}
		}
		
		return meterReading;
	}
	
	onSubmitReconcile(event) {
		var self = this;
		var meterReadings = [];
		
		for (var i = 0; i < this.props.meterReadings.length; i++) {
			meterReadings.push(this.findMeterReading(this.props.meterReadings[i]));
		}

		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/HeLAD/ReconcileGN2Upload',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, upload: { meterReadings: meterReadings }}
		}).done(function (data) {
			if (data.Success) {
			   self.showConfirmation(() => {
					return (<>
						{data.Message}<br />
						<Link to={'/HeLAD/MeterReadings'}>View meter readings</Link>
					</>);
			   });
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}

	render() {		
	
		var exportUploadData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Reconcile Meter Readings',
			columns: uploadExportColumns,
			data: this.state.meterReadings
		});	
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Reconcile GN2 Upload</span>
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3}>
						  {this.state.meterReadings.length} of {this.props.meterReadings.length} records contain errors.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6 }}>
						<form action={this.getConfig().host + '/Home/ExcelData'} method='POST'>
							{exportUploadData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>								  
					  <Col xs={8}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitReconcile(event)}>Reconcile</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <ChemTable renderKey={this.state.renderKey} parent={this} name='HeLAD_GN2Upload_Reconcile' columns={uploadColumns} data={this.state.meterReadings} />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const uploadColumns = [	
	{ Header: 'Meter ID', accessor: 'meterID', width: 70 },
	{ Header: 'Reading ID', accessor: 'readingID', width: 70 },
	{ Header: 'Existing Past Amount', accessor: 'existingPastAmount', width: 110 },
	{ Header: 'Existing New Amount', accessor: 'existingNewAmount', width: 110 },
	{ Header: 'Upload Past Amount', accessor: 'uploadPastAmount', width: 110 },
	{ Header: 'Upload New Amount', accessor: 'uploadNewAmount', width: 110 },
	{ Header: 'Ready To Bill', accessor: 'readyToBill', width: 70 },
	{ Header: 'Date Billed', accessor: 'dateBilled', width: 100 },
	{ Header: 'Entered Date', accessor: 'enteredDate', width: 100 },
	{ Header: 'Meter Type', accessor: 'meterType', width: 70 },
	{ Header: 'Issue', accessor: 'issue', width: 300 },
	{ Header: 'Resolution', accessor: 'resolution', width: 100,
		Cell: props => {
			if (props.row.values.issue.indexOf('Writeoff') === 0) {
				return (
					<Form.Control as="select" value={props.value}
						onChange={(event) => this.onSelectChange(props.row.values.readingID, event.target.value)}>
						<option value='Writeoff'>Writeoff</option>
						<option value='Skip'>Skip</option>
					</Form.Control>);
			} else if (props.row.values.issue.indexOf('Double') === 0 && props.row.values.issue.indexOf('entire') < 0) {
				return (
					<Form.Control as="select" value={props.value}
						onChange={(event) => this.onSelectChange(props.row.values.readingID, event.target.value)}>
						<option value='Adjust'>Adjust</option>
						<option value='Skip'>Skip</option>
					</Form.Control>);
			} else {
				return <>{props.value}</>;
			}
		}
	}
];

const uploadExportColumns = [
	{ Header: 'Meter ID', accessor: 'meterID', width: 14 },
	{ Header: 'Reading ID', accessor: 'readingID', width: 14 },
	{ Header: 'Existing Past Amount', accessor: 'existingPastAmount', width: 22 },
	{ Header: 'Existing New Amount', accessor: 'existingNewAmount', width: 22 },
	{ Header: 'Upload Past Amount', accessor: 'uploadPastAmount', width: 22 },
	{ Header: 'Upload New Amount', accessor: 'uploadNewAmount', width: 22 },
	{ Header: 'Ready To Bill', accessor: 'readyToBill', width: 14 },
	{ Header: 'Date Billed', accessor: 'dateBilled', width: 20 },
	{ Header: 'Entered Date', accessor: 'enteredDate', width: 20 },
	{ Header: 'Meter Type', accessor: 'meterType', width: 14 },
	{ Header: 'Issue', accessor: 'issue', width: 60 },
	{ Header: 'Resolution', accessor: 'resolution', width: 20 }
];

export default withRouter(ReconcileGN2Upload);
