import ChemComponent from '../ChemComponent';
import { Col, Container, Row, Button } from 'react-bootstrap';
//
class Scramble extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			team: [
				'Suzanne Sutton',
				'Bailian Guo',
				'Carla Arendez',
				'John Borland',
				'Roy Washington',
				'Carl Lamey',
				'Leigh Moyer',
				'Richard Newman'
			],
			scrambling: false,
			renderKey: 0
		}
	}
	
	toggleScramble() {
		if (this.state.scrambling) {
			this.mergeState({
				scrambling: false,
				renderKey: this.state.renderKey + 1
			});
		} else {
			var self = this;
			this.mergeState({
				scrambling: true,
				renderKey: this.state.renderKey + 1
			}, () => {
				self.scramble()
			});
		}
	}
	
	scramble() {
		if (this.state.scrambling) {
			var oldTeam = this.copyObject(this.state.team);
			var newTeam = [];
			while (oldTeam.length > 0) {
				// pick a random number within the team
				var removeIdx = Math.floor(Math.random() * oldTeam.length);
				
				// move corresponding team member to new team
				newTeam.push(oldTeam.splice(removeIdx, 1));
			}
			
			// set new team and timer
			var self = this;
			this.mergeState({
				team: newTeam,
				renderKey: this.state.renderKey + 1
			}, () => {
				setTimeout(() => self.scramble(), 100);
			});
		}
	}
	
	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col md={12} lg={11} xl={10} style={{ paddingTop: '10px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Team Scramble</span>
				</Col>
			  </Row>
			  {this.state.team.map((member, index) => {
				 return (<Row key={index}>
					<Col md={12} lg={11} xl={10} style={{ backgroundColor: '#FEFEFE' }}>
					{member}
					</Col>
				</Row>);				
			  })}
			  <Row>
			    <Col md={12} lg={11} xl={10} style={{ paddingTop: '10px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Button className='float-end' onClick={() => this.toggleScramble()}>{this.state.scrambling ? 'Stop!' : 'Scramble!'}</Button>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

export default Scramble;
