import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class WorkTitles extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			WORK_TITLE_NAME: '',
			COMMENTS: ''
		};
		
		var initialWorkTitleList = this.copyWorkTitleList();
		initialWorkTitleList.query.search = this.constructSearch(search);
		
		this.state = {
			renderKey: 1,
			workTitleList: initialWorkTitleList,
			search: search,
			searchCount: null
		};
	}
	
	copyWorkTitleList() {
		var workTitleListCopy = this.copyObject(workTitleList);
		for (var i = 0; i < workTitleList.columns.length; i++) {
			if (workTitleList.columns[i].Header) {
				workTitleListCopy.columns[i].Header = workTitleList.columns[i].Header;
			}
			if (workTitleList.columns[i].Cell) {
				workTitleListCopy.columns[i].Cell = workTitleList.columns[i].Cell;
			}
		}
		return workTitleListCopy;
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newWorkTitleList = this.copyWorkTitleList();
		newWorkTitleList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			workTitleList: newWorkTitleList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.WORK_TITLE_NAME)) search.Children.push({ Attribute: 'WORK_TITLE_NAME', Operator: 'like', StringValue: '%' + data.WORK_TITLE_NAME + '%' });
		
		// if nothing was selected, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	addWorkTitle() {
		this.props.navigate('/BuildingAccess/WorkTitles/new');
	}
	
	editWorkTitle(workTitleId) {
		this.props.navigate('/BuildingAccess/WorkTitles/' + workTitleId);		
	}
	
	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Work Titles',
			columns: exportColumns,
			entity: 'WORK_TITLE_VW',
			search: this.constructSearch(this.state.search),
			order: 'WORK_TITLE_NAME'
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Work Titles</span>
					<ChemEdit parent={this} columns={workTitleSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={3} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_WorkTitles' table={this.state.workTitleList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='200px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const workTitleSearch = [
	{ Header: 'Name', accessor: 'WORK_TITLE_NAME', row: 0, col: 0 }
];

const workTitleList = {
	query: { entity: 'WORK_TITLE_VW', search: null, order: 'WORK_TITLE_NAME' },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addWorkTitle()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editWorkTitle(props.row.values.WORK_TITLE_ID)} />, width: 25 },
		{ Header: 'ID', accessor: 'WORK_TITLE_ID' },
		{ Header: 'Name', accessor: 'WORK_TITLE_NAME', width: 300 },
		{ Header: 'Abbreviation', accessor: 'WORK_TITLE_ABBREV' },
		{ Header: 'Last Modified', accessor: 'MODIFIED', width: 300 }
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'WORK_TITLE_ID', width: 5 },
	{ Header: 'Name', accessor: 'WORK_TITLE_NAME', width: 20 },
	{ Header: 'Abbreviation', accessor: 'WORK_TITLE_ABBREV', width: 10 },
	{ Header: 'Last Modified', accessor: 'MODIFIED', width: 20 }
];

export default withRouter(WorkTitles);