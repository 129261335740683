import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class RecurringJobs extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			USER_NAME: '',
			PI_NAME: '',
			SPEEDTYPE: ''
		};
		
		// initialize dry ice list
		var initialRecurringJobsList = this.copyRecurringJobsList();
		initialRecurringJobsList.query.search = this.constructSearch(search);
		
		// remove add/edit column if not authorized
		if (props.user.crs.userAccessID !== 1) {
			initialRecurringJobsList.columns.splice(0, 1);
		}
		
		this.state = {
			renderKey: 1,
			recurringJobsList: initialRecurringJobsList,
			search: search,
			searchCount: null
		};
	}
	
	copyRecurringJobsList() {
		var recurringJobsListCopy = this.copyObject(recurringJobsList);
		for (var i = 0; i < recurringJobsList.columns.length; i++) {
			if (recurringJobsList.columns[i].Header) {
				recurringJobsListCopy.columns[i].Header = recurringJobsList.columns[i].Header;
			}
			if (recurringJobsList.columns[i].Cell) {
				recurringJobsListCopy.columns[i].Cell = recurringJobsList.columns[i].Cell;
			}
		}
		return recurringJobsListCopy;
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newRecurringJobsList = this.copyRecurringJobsList();
		newRecurringJobsList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			recurringJobsList: newRecurringJobsList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.JOB_NAME)) search.Children.push({ Attribute: 'JOB_NAME', Operator: 'like', StringValue: '%' + data.JOB_NAME + '%' });
		if (!this.isEmpty(data.CUSTOMER_NAME)) search.Children.push({ Attribute: 'CUSTOMER_NAME', Operator: 'like', StringValue: '%' + data.CUSTOMER_NAME + '%' });		
		if (!this.isEmpty(data.SPEEDTYPE)) search.Children.push({ Attribute: 'SPEEDTYPE', Operator: 'like', StringValue: '%' + data.SPEEDTYPE + '%' });		

		if (search.Children.length === 0) return null;
		return search;
	}
	
	addRecurringJob() {
		this.props.navigate('/CRS/RecurringJobs/new');
	}
	
	editRecurringJob(jobId) {
		this.props.navigate('/CRS/RecurringJobs/' + jobId);
	}
	
	transferIntoRecharges(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/TransferRecurringJobs',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken }
		}).done(function (data) {
			if (data.Success) {
				self.showConfirmation('Recurring jobs successfully transferred into recharges.');
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
	
	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Recurring Jobs',
			columns: exportColumns,
			entity: 'RECHARGES.RECURRING_JOB_VW',
			search: this.constructSearch(this.state.search),
			order: 'JOB_ID'
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Recurring Jobs</span>
					<ChemEdit parent={this} columns={recurringJobsSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={2} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={2}>
						  <Button id='transfer' variant='warning' type='button' onClick={(event) => this.transferIntoRecharges(event)}>Transfer into Recharges</Button>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_RecurringJobs' table={this.state.recurringJobsList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='200px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const recurringJobsSearch = [
	{ Header: 'Description', accessor: 'JOB_NAME', row: 0, col: 0 },
	{ Header: 'Customer', accessor: 'CUSTOMER_NAME', row: 0, col: 1 },
	{ Header: 'Speedtype', accessor: 'SPEEDTYPE', row: 0, col: 2 }
];

const recurringJobsList = {
	query: { entity: 'RECHARGES.RECURRING_JOB_VW', search: null, order: 'JOB_ID' },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addRecurringJob()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editRecurringJob(props.row.values.JOB_ID)} />, width: 25 },
		{ Header: 'ID', accessor: 'JOB_ID', width: 60 },
		{ Header: 'Service', accessor: 'SERVICE_NAME', width: 200 },
		{ Header: 'Description', accessor: 'JOB_NAME', width: 200 },
		{ Header: 'Customer', accessor: 'CUSTOMER_NAME', width: 150 },
		{ Header: 'PI', accessor: 'PI_NAME', width: 200 },
		{ Header: 'Speedtype', accessor: 'SPEEDTYPE', width: 100 },
		{ Header: 'Labor Hrs', accessor: 'LABOR_HOURS', width: 80 },
		{ Header: 'Material', accessor: 'MATERIAL_COST', width: 80 },
		{ Header: 'Start', accessor: 'START_DATE', width: 100 },
		{ Header: 'End', accessor: 'END_DATE', width: 100 },
		{ Header: 'Bill', accessor: 'BILL_FLAG', width: 40 },
		{ Header: 'Comments', accessor: 'COMMENTS', width: 400 },
		{ Header: 'Last Transfer', accessor: 'TRANSFER_DATE', width: 100 },
		{ Header: 'Created', accessor: 'CREATED', width: 250 },
		{ Header: 'Modified', accessor: 'MODIFIED', width: 250 }
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'JOB_ID', width: 12 },
	{ Header: 'Service', accessor: 'SERVICE_NAME', width: 40 },
	{ Header: 'Description', accessor: 'JOB_NAME', width: 40 },
	{ Header: 'Customer', accessor: 'CUSTOMER_NAME', width: 30 },
	{ Header: 'PI', accessor: 'PI_NAME', width: 40 },
	{ Header: 'Speedtype', accessor: 'SPEEDTYPE', width: 20 },
	{ Header: 'Labor Hrs', accessor: 'LABOR_HOURS', width: 16, format: '#,##0.00' },
	{ Header: 'Material', accessor: 'MATERIAL_COST', width: 16, format: '#,##0.00' },
	{ Header: 'Start', accessor: 'START_DATE', width: 20, format: 'mm/dd/yy' },
	{ Header: 'End', accessor: 'END_DATE', width: 20, format: 'mm/dd/yy' },
	{ Header: 'Bill', accessor: 'BILL_FLAG', width: 8 },
	{ Header: 'Comments', accessor: 'COMMENTS', width: 80 },
	{ Header: 'Last Transfer', accessor: 'TRANSFER_DATE', width: 20, format: 'mm/dd/yy' },
	{ Header: 'Created', accessor: 'CREATED', width: 50 },
	{ Header: 'Modified', accessor: 'MODIFIED', width: 50 }
];

export default withRouter(RecurringJobs);