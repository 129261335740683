import { useEffect } from 'react';
import {
    useLocation,
    useNavigate,
    useParams
  } from "react-router-dom";

export const withCCSDefaultRoute = (Component) =>  {
    const WithCCSDefaultRouteWrapper = (props) => {
		const location = useLocation();
		const navigate = useNavigate();
		const params = useParams();
		const match = {
			path: location.pathname
		};
		
		useEffect(() => {
			// if the default page has been requested
			var defaultRegex = /\/helad\/ccs$/i;
			if (defaultRegex.test(match.path)) {
				navigate('/HeLAD/CCS/Checkout');
			}
		});
		
		return (
			<Component
				location={location}
				navigate={navigate}
				params={params}
				match={match}
				{...props}
			/>
		);
    }
    return WithCCSDefaultRouteWrapper;
}
