import ChemComponent from '../ChemComponent';
import { Col, Row, Form, InputGroup } from 'react-bootstrap';

class GSICourse extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			schedules: [],
			modules: [],
			courses: [],
			faculty: [],
			renderKey: 0
		};
	}
	
	componentDidMount() {
		this.loadData(true);
	}
	
	componentDidUpdate(prevProps) {
		if ((prevProps.academicYear !== this.props.academicYear && /^[0-9][0-9]-[0-9][0-9]$/.test(this.props.academicYear))
			|| prevProps.semester !== this.props.semester) {
			this.loadData(false);
		}
	}

	onModuleChange(newModuleId) {
		this.props.onChange('gsi_course.SCHEDULE_ID', newModuleId);
	}
	
	onScheduleChange(newScheduleId) {
		this.props.onChange('gsi_course.SCHEDULE_ID', newScheduleId);
	}
	
	onCourseChange(newCourseId) {
		this.props.onChange('course_schedule.COURSE_ID', newCourseId);
	}
	
	onFacultyChange(newFacultyId) {
		this.props.onChange('course_schedule.FACULTY1', newFacultyId);
	}
	
	loadData(firstLoad) {
		var self = this;
		var searches = [];
		
		// search for schedules
		searches.push({
			Entity: 'ADMISSIONS.SCHEDULE_SELECT_VW',
			Search: { Operator: 'and', Children: [
				{ Attribute: 'SEMESTER', Operator: '=', StringValue: this.props.semester && this.props.semester.substring(0, 2).toUpperCase() },
				{ Attribute: 'ACAD_YEAR', Operator: '=', StringValue: this.props.academicYear }]},
			Order: ['COURSE_DESC'],
			PageNumber: -1,
			PageSize: -1
		});
		
		// search for modules
		searches.push({
			Entity: 'ADMISSIONS.MODULE_SELECT_VW',
			Search: { Operator: 'and', Children: [
				{ Attribute: 'SEMESTER', Operator: '=', StringValue: this.props.semester && this.props.semester.substring(0, 2).toUpperCase() },
				{ Attribute: 'ACAD_YEAR', Operator: '=', StringValue: this.props.academicYear }]},
			Order: ['CAL_YEAR', 'SEMESTER', 'MODULE_ID'],
			PageNumber: -1,
			PageSize: -1
		});
		
		// if this is the first load
		if (firstLoad) {
			// load courses
			searches.push({
				Columns: ['COURSE_ID', 'COURSE_NAME'],
				Entity: 'ADMISSIONS.COURSES',
				Search: { Operator: 'and', Children: [
					{ Attribute: 'DELETE_FLAG', Operator: 'is null' },
					{ Attribute: 'RETIRE_FLAG', Operator: 'is null' },
					{ Attribute: 'DEPT_CODE', Operator: '=', IntValue: '1' }]},
				PageNumber: -1,
				PageSize: -1
			});

			// load faculty
			searches.push({
				Entity: 'ADMISSIONS.FACULTY_SELECT_VW',
				PageNumber: -1,
				PageSize: -1
			});
		}
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Home/MultiSearch',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				searches: JSON.stringify(searches)
			}
		}).done(function (data) {
			var schedules = data.SearchResults[0].Success ? data.SearchResults[0].Data : [];
			var modules = data.SearchResults[1].Success ? data.SearchResults[1].Data : [];
							
			if (data.SearchResults.length === 2) {
				self.mergeState({
					schedules: schedules,
					modules: modules,
					renderKey: self.state.renderKey + 1
				});
			} else {
				var courses = data.SearchResults[2].Success ? data.SearchResults[2].Data : [];
				var faculty = data.SearchResults[3].Success ? data.SearchResults[3].Data : [];
				
				self.mergeState({
					schedules: schedules,
					modules: modules,
					courses: courses,
					faculty: faculty,
					renderKey: self.state.renderKey + 1
				});
			}
			
			self.props.onChange('CREATE_SCHEDULE_FLAG', schedules.length === 0);
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	render() {
		var optionalDisabledProp = this.props.editable === false ? { disabled: true } : {};
		if (this.props.moduleFlag) {
		  return (
			<Form.Group as={Row} controlId='MODULE_ID'>
			  <Form.Label column sm={5} style={{ textAlign: 'right' }}>Module</Form.Label>
			  <Col sm={7} style={{ marginBottom: '14px' }}>
				<InputGroup>
				  <Form.Control as="select" value={this.props.scheduleId} {...optionalDisabledProp}
					  onChange={(event) => this.onModuleChange(event.target.value)}>
					  <option value={null}>Select...</option>
					  {this.state.modules.map((option, index) => {
						  return (<option key={index} value={option.value}>{option.label}</option>);
					  })}
				  </Form.Control>
				</InputGroup>
			  </Col>
			</Form.Group>
		  );
		} else /*if (this.state.schedules.length > 0)*/ {
		  return (
			<Form.Group as={Row} controlId='SCHEDULE_ID'>
			  <Form.Label column sm={5} style={{ textAlign: 'right' }}>Schedule</Form.Label>
			  <Col sm={7} style={{ marginBottom: '14px' }}>
				<InputGroup>
				  <Form.Control as="select" value={this.props.scheduleId} {...optionalDisabledProp}
					  onChange={(event) => this.onScheduleChange(event.target.value)}>
					  <option value={null}>Select...</option>
					  {this.state.schedules.map((option, index) => {
						  return (<option key={index} value={option.SCHEDULE_ID}>{option.COURSE_DESC}</option>);
					  })}
				  </Form.Control>
				</InputGroup>
			  </Col>
			</Form.Group>
		  );
		} /*else {
		  return (
		   <>
			<Form.Group as={Row} controlId='COURSE_ID'>
			  <Form.Label column sm={5} style={{ textAlign: 'right' }}>Course</Form.Label>
			  <Col sm={7} style={{ marginBottom: '14px' }}>
				<InputGroup>
				  <Form.Control as="select" value={this.props.courseId}
					  onChange={(event) => this.onCourseChange(event.target.value)}>
					  <option value={null}>Select...</option>
					  {this.state.courses.map((option, index) => {
						  return (<option key={index} value={option.COURSE_ID}>{option.COURSE_NAME}</option>);
					  })}
				  </Form.Control>
				</InputGroup>
			  </Col>
			</Form.Group>
			<Form.Group as={Row} controlId='FACULTY_ID'>
			  <Form.Label column sm={5} style={{ textAlign: 'right' }}>Faculty</Form.Label>
			  <Col sm={7} style={{ marginBottom: '14px' }}>
				<InputGroup>
				  <Form.Control as="select" value={this.props.facultyId}
					  onChange={(event) => this.onFacultyChange(event.target.value)}>
					  <option value={null}>Select...</option>
					  {this.state.faculty.map((option, index) => {
						  return (<option key={index} value={option.PERSON_ID}>{option.FACULTY}</option>);
					  })}
				  </Form.Control>
				</InputGroup>
			  </Col>
			</Form.Group>
		   </>
		  );
		}*/			
	}
}

export default GSICourse;
	