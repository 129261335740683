import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';

class Funds extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			FUND_CAT: '',
			OP_FGC: '',
			FUND_RANGE_START: '',
			FUND_RANGE_END: '',
			FGC_DESC1: '',
			FGC_DESC2: '',
			SPONSORED_FLAG: 'All'
		};
		
		// initialize funds list
		var initialFundsList = this.copyFundsList();
		initialFundsList.query.search = this.constructSearch(search);
		
		this.state = {
			renderKey: 1,
			fundsList: initialFundsList,
			search: search,
			searchCount: null
		};
	}
	
	copyFundsList() {
		var fundsListCopy = this.copyObject(fundsList);
		for (var i = 0; i < fundsList.columns.length; i++) {
			if (fundsList.columns[i].Header) {
				fundsListCopy.columns[i].Header = fundsList.columns[i].Header;
			}
			if (fundsList.columns[i].Cell) {
				fundsListCopy.columns[i].Cell = fundsList.columns[i].Cell;
			}
		}
		return fundsListCopy;
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newFundsList = this.copyFundsList();
		newFundsList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			fundsList: newFundsList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: [{ Attribute: 'DELETE_FLAG', Operator: 'is null' }]
		};		

		if (!this.isEmpty(data.FUND_CAT)) search.Children.push({ Attribute: 'FUND_CAT', Operator: '=', StringValue: data.FUND_CAT });
		if (!this.isEmpty(data.OP_FGC)) search.Children.push({ Attribute: 'OP_FGC', Operator: '=', StringValue: data.OP_FGC });
		if (!this.isEmpty(data.FUND_RANGE_START)) search.Children.push({ Attribute: 'FUND_RANGE_START', Operator: '=', StringValue: data.FUND_RANGE_START });
		if (!this.isEmpty(data.FUND_RANGE_END)) search.Children.push({ Attribute: 'FUND_RANGE_END', Operator: '=', StringValue: data.FUND_RANGE_END });
		if (!this.isEmpty(data.FGC_DESC1)) search.Children.push({ Attribute: 'FGC_DESC1', Operator: '=', StringValue: data.FGC_DESC1 });
		if (!this.isEmpty(data.FGC_DESC2)) search.Children.push({ Attribute: 'FGC_DESC2', Operator: '=', StringValue: data.FGC_DESC2 });
		if (data.SPONSORED_FLAG === 'Yes') {
			search.Children.push({ Attribute: 'SPONSORED_FLAG', Operator: '=', StringValue: 'Y' });
		} else if (data.SPONSORED_FLAG === 'No') {
			search.Children.push({ Operator: 'or', Children: [
				{ Attribute: 'SPONSORED_FLAG', Operator: 'is null' },
				{ Attribute: 'SPONSORED_FLAG', Operator: '!=', StringValue: 'Y' }]});
		}

		return search;
	}
	
	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Funds',
			columns: exportColumns,
			entity: 'RECHARGES.FUND_GROUP',
			search: this.constructSearch(this.state.search),
			order: ['FUND_CAT', 'FUND_RANGE_START']
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Fund List by Fund Group</span>
					<ChemEdit parent={this} columns={fundsSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={3} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_Funds' table={this.state.fundsList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='250px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const fundsSearch = [
	{ Header: 'Fund Category', accessor: 'FUND_CAT', row: 0, col: 0 },
	{ Header: 'OP FGC', accessor: 'OP_FGC', row: 0, col: 1 },
	{ Header: 'UCB Fund Range Start', accessor: 'FUND_RANGE_START', row: 0, col: 2 },
	{ Header: 'UCB Fund Range End', accessor: 'FUND_RANGE_END', row: 0, col: 3 },
	{ Header: 'FGC Description 1', accessor: 'FGC_DESC1', row: 1, col: 0 },
	{ Header: 'FGC Description 2', accessor: 'FGC_DESC2', row: 1, col: 1 },
	{ Header: 'Sponsored', accessor: 'SPONSORED_FLAG', type: 'select', row: 1, col: 2,
		options: [{ value: 'All', label: 'All' }, { value: 'No', label: 'No' }, { value: 'Yes', label: 'Yes' }]}
];

const fundsList = {
	query: { entity: 'RECHARGES.FUND_GROUP', search: null, order: ['FUND_CAT', 'FUND_RANGE_START'] },
	pageSize: 20,
	columns: [
		{ Header: 'Fund Category', accessor: 'FUND_CAT' },
		{ Header: 'OP FGC', accessor: 'OP_FGC' },
		{ Header: 'UCB Fund Range Start', accessor: 'FUND_RANGE_START' },
		{ Header: 'UCB Fund Range End', accessor: 'FUND_RANGE_END' },
		{ Header: 'FGC Description 1', accessor: 'FGC_DESC1', width: 400 },
		{ Header: 'FGC Description 2', accessor: 'FGC_DESC2', width: 400 },
		{ Header: 'Sponsored', accessor: 'SPONSORED_FLAG', width: 100 }
	]
};

const exportColumns = [
	{ Header: 'Fund Category', accessor: 'FUND_CAT', width: 20 },
	{ Header: 'OP FGC', accessor: 'OP_FGC', width: 20 },
	{ Header: 'UCB Fund Range Start', accessor: 'FUND_RANGE_START', width: 20 },
	{ Header: 'UCB Fund Range End', accessor: 'FUND_RANGE_END', width: 20 },
	{ Header: 'FGC Description 1', accessor: 'FGC_DESC1', width: 50 },
	{ Header: 'FGC Description 2', accessor: 'FGC_DESC2', width: 50 },
	{ Header: 'Sponsored', accessor: 'SPONSORED_FLAG', width: 20 }
];

export default withRouter(Funds);