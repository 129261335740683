import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { PencilSquare } from 'react-bootstrap-icons';


class Degrees extends ChemComponent {
	constructor(props) {
		super(props);

		var search = {
			APP_YEAR: [],
			RES_DIR_NAME: null,
			DIVISION: null
		};
		
		this.state = {
			degreeList: this.copyTable(degreeList),
			search: search,
			searchCount: 0,
			renderKey: 0
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newDegreeList = this.copyTable(degreeList);
		newDegreeList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			degreeList: newDegreeList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};
		
		if (Array.isArray(data.APP_YEAR) && data.APP_YEAR.length > 0) search.Children.push({ Attribute: 'APP_YEAR', Operator: 'in', StringList: data.APP_YEAR });
		if (!this.isEmpty(data.RES_DIR_NAME)) search.Children.push({ Attribute: 'RES_DIR_NAME', Operator: '=', StringValue: data.RES_DIR_NAME });
		if (!this.isEmpty(data.DIVISION)) search.Children.push({ Attribute: 'DIVISION', operator: '=', StringValue: data.DIVISION });

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}

	editStudent(studentId) {
		this.props.setStudentEditable(true);
		this.props.navigate('/GradOffice/Students/' + studentId);		
	}

	viewStudent(studentId) {
		this.props.setStudentEditable(false);
		this.props.navigate('/GradOffice/Students/' + studentId);		
	}

	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Degrees',
			columns: exportColumns,
			entity: 'ADMISSIONS.STUDENT_DEGREE_VW',
			search: this.constructSearch(this.state.search),
			order: ['YEAR_ORDER','APP_YEAR desc','STUDENT_NAME']
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Degrees</span>
						<ChemEdit parent={this} columns={degreeSearch} data={this.state.search} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} {...this.props} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='GradOffice_Degrees' table={this.state.degreeList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='250px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const degreeSearch = [
	{ Header: 'App Year', accessor: 'APP_YEAR', type: 'list', row: 0, col: 0,
		options: { value: 'APP_YEAR', label: 'APP_YEAR', entity: 'ADMISSIONS.APP_YEAR_SELECT_VW', order: ['YEAR_ORDER','APP_YEAR desc']}},
	{ Header: 'PI', accessor: 'RES_DIR_NAME', type: 'select', row: 0, col: 1,
		options: { value: 'RES_DIRECTOR_NAME', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.RESEARCH_DIRECTOR_TYPE_SELECT_VW', order: 'RES_DIRECTOR_NAME',
			search: { Attribute: 'PI_TYPE', Operator: '=', LongValue: 1 }}},
	{ Header: 'Division', accessor: 'DIVISION', type: 'select', row: 0, col: 2,
		options: { value: 'DIVISION', label: 'DIVISION', entity: 'ADMISSIONS.STUDENTS', distinct: true,
			search: { Attribute: 'DIVISION', Operator: 'is not null' }}}
];

const degreeList = {
	query: { entity: 'ADMISSIONS.STUDENT_DEGREE_VW', search: null, order: ['YEAR_ORDER','APP_YEAR desc','STUDENT_NAME'] },
	pageSize: 20,
	columns: [
		{ Header: '', accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editStudent(props.row.values.STUDENT_ID)} />, width: 25 },
		{ Header: '', accessor: 'STUDENT_ID', show: false },
		{ Header: 'Student ID', accessor: 'STUDENT_CARD', width: 85 },
		{ Header: 'Student Name', accessor: 'STUDENT_NAME', width: 200, Cell: props => <HashLink onClick={() => props.parent.viewStudent(props.row.values.STUDENT_ID)}>{props.value}</HashLink> },
		{ Header: 'App Year', accessor: 'APP_YEAR', width: 70 },
		{ Header: 'Degree Date', accessor: 'DEGREE_DATE', width: 90 },
		{ Header: 'Degree', accessor: 'DEGREE', width: 60 },
		{ Header: 'Division', accessor: 'DIVISION', width: 80 },
		{ Header: 'PI', accessor: 'RES_DIR_NAME', width: 200 },
		{ Header: 'Assoc. Adv.', accessor: 'ASSOC_ADV_NAME', width: 200 },
		{ Header: 'Topic', accessor: 'DEGREE_TOPIC', width: 600 }
	]
};

const exportColumns = [
	{ Header: 'Student ID', accessor: 'STUDENT_CARD', width: 17 },
	{ Header: 'Student Name', accessor: 'STUDENT_NAME', width: 40 },
	{ Header: 'App Year', accessor: 'APP_YEAR', width: 14 },
	{ Header: 'Degree Date', accessor: 'DEGREE_DATE', width: 18 },
	{ Header: 'Degree', accessor: 'DEGREE', width: 12 },
	{ Header: 'Division', accessor: 'DIVISION', width: 16 },
	{ Header: 'PI', accessor: 'RES_DIR_NAME', width: 40 },
	{ Header: 'Assoc. Adv.', accessor: 'ASSOC_ADV_NAME', width: 40 },
	{ Header: 'Topic', accessor: 'DEGREE_TOPIC', width: 120 }
];

export default withRouter(Degrees);
