import React from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class RecurringJob extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			recurringJob: {
				JOB_ID: null,
				SERVICE_ID: null,
				JOB_NAME: '',
				CUSTOMER_ID: null,
				PI_ROLE: null,
				SPEEDTYPE: '',
				SPEEDTYPE_PI: '',
				LABOR_HOURS: '',
				MATERIAL_COST: '',
				COMMENTS: '',
				START_DATE: null,
				END_DATE: null,
				BILL_FLAG: undefined,
				TRANSFER_DATE: null,
				TRANSFERRED: '',
				DATE_CREATED: null,
				CREATED_BY: '',
				CREATED: '',
				DATE_MODIF: null,
				MODIF_BY: '',
				MODIFIED: '',
				DELETE_FLAG: undefined,
				RCH_CODE: '',
				ASSIGNED_TO: null
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadRecurringJob();
	}

	loadRecurringJob(event) {
		var self = this;
		
		if (this.props.params.jobId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/CRS/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'RECURRING_JOB',
					search: { Attribute: 'JOB_ID', Operator: '=', LongValue: this.props.params.jobId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var recurringJob = data.Data[0];
						
						// convert flags
						recurringJob.BILL_FLAG = recurringJob.BILL_FLAG === 'Y' ? true : false;
						recurringJob.DELETE_FLAG = recurringJob.DELETE_FLAG === 'Y' ? true : false;
						
						// convert dates
						recurringJob.TRANSFER_DATE = self.dateTimeToMVC(self.getDate(recurringJob.TRANSFER_DATE));
						recurringJob.START_DATE = self.dateTimeToMVC(self.getDate(recurringJob.START_DATE));
						recurringJob.END_DATE = self.dateTimeToMVC(self.getDate(recurringJob.END_DATE));
						recurringJob.DATE_CREATED = self.dateTimeToMVC(self.getDate(recurringJob.DATE_CREATED));
						recurringJob.DATE_MODIF = self.dateTimeToMVC(self.getDate(recurringJob.DATE_MODIF));
						
						// compose created/modified
						recurringJob.TRANSFERRED = self.dateToString(recurringJob.TRANSFER_DATE);
						recurringJob.CREATED = self.composeChangeInfo(recurringJob.CREATED_BY, recurringJob.DATE_CREATED);
						recurringJob.MODIFIED = self.composeChangeInfo(recurringJob.MODIF_BY, recurringJob.DATE_MODIF);
						
						// load speedtype PI
						self.checkSpeedtype('SPEEDTYPE', recurringJob.SPEEDTYPE, (speedtype) => {
							recurringJob.SPEEDTYPE_PI = speedtype.SPEEDTYPE_PI;
							self.mergeState({
								recurringJob: recurringJob,
								renderKey: self.state.renderKey + 1
							}, () => {
								if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
							});
						});
					} else {
						self.showAlert('Recurring Job Error', data.Data.length + ' Recurring Jobs found for ID ' + self.props.params.jobId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				recurringJob: {
					JOB_ID: '(new)'
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var self = this;
		var recurringJob = this.copyObject(this.state.recurringJob);
		this.setByAccessor(recurringJob, accessor, value);
		this.checkSpeedtype(accessor, value, (speedtype) => {
			if (speedtype) recurringJob.SPEEDTYPE_PI = speedtype.SPEEDTYPE_PI;
			self.mergeState({ recurringJob: recurringJob, renderKey: this.state.renderKey + 1 });
		});
	}
	
	checkSpeedtype(accessor, value, callback) {
		var noSpeedtype = {
			SPEEDTYPE_PI: '(none)'
		};
		
		if (accessor === 'SPEEDTYPE') {
			if (value && value.length === 10) {
				var self = this;
				
				this.ajax({
					type: 'POST',
					url: this.getConfig().host + '/Home/Search',
					data: {
						__RequestVerificationToken: this.props.user.antiForgeryToken,
						entity: 'SPEEDTYPE_PI_VW',
						search: { Attribute: 'SPEEDTYPE', Operator: '=', StringValue: value },
						pageNumber: -1,
						pageSize: -1
					}
				}).done(function (data) {
					if (data.Success) {
						if (data.Data.length > 0) {
							callback({ SPEEDTYPE_PI: data.Data[0].NAME_AND_TYPE });
						} else {
							callback(noSpeedtype);
						}
					} else {
						self.showAlert('Server Error', data.Message);
						callback(noSpeedtype);
					}
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
					callback(noSpeedtype);
				});			
			} else {
				callback(noSpeedtype);
			}
		} else {
			callback(null);
		}
	}
		
	deleteRecurringJob(event) {
		var self = this;
		
		// assemble user object to update inactive flag
		var recurringJob = {
			JOB_ID: this.props.params.jobId,
			DELETE_FLAG: 'Y',
			MODIF_DATE: this.dateTimeToMVC(new Date()),
			MODIF_BY: this.props.user.name
		};
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/Home/Update',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'RECHARGES.RECURRING_JOB', data: recurringJob }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Recurring Job {self.props.params.jobId} deleted.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	validateRecurringJob(event, columns, data) {
		return this.validate(columns, data);
	}
		
	onSubmit(event) {
		var self = this;		
		
		// make copy of data
		var recurringJob = this.copyObject(this.state.recurringJob);

		// if this is a new user
		var url;
		if (this.props.params.jobId) {
			url = this.getConfig().host + '/Home/Update';
			recurringJob.MODIF_DATE = this.dateTimeToMVC(new Date());
			recurringJob.MODIF_BY = this.props.user.name;
			recurringJob.DATE_CREATED = this.dateTimeToMVC(this.getDate(recurringJob.DATE_CREATED));
		} else {
			url = this.getConfig().host + '/Home/Insert';
			recurringJob.DATE_CREATED = this.dateTimeToMVC(new Date());
			recurringJob.CREATED_BY = this.props.user.name;
			// set ID sequence
			recurringJob.JOB_ID = 'shop_job_id_seq.nextval';
		}
		
		// convert flags
		recurringJob.BILL_FLAG = recurringJob.BILL_FLAG ? 'Y' : null;
		recurringJob.DELETE_FLAG = recurringJob.DELETE_FLAG ? 'Y' : null;
		
		// format dates
		recurringJob.START_DATE = this.dateTimeToMVC(this.getDate(recurringJob.START_DATE));
		recurringJob.END_DATE = this.dateTimeToMVC(this.getDate(recurringJob.END_DATE));
		recurringJob.TRANSFER_DATE = this.dateTimeToMVC(this.getDate(recurringJob.TRANSFER_DATE));
		
		this.ajax({
			type: 'POST',
			url: url,
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'RECHARGES.RECURRING_JOB', data: recurringJob }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Recurring Job <Link to={'/CRS/RecurringJobs/' + (self.props.params.jobId || data.ID)}>{self.props.params.jobId || data.ID}</Link> updated.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Recurring Job {this.state.recurringJob.JOB_ID}</div>
				<ChemEdit parent={this} columns={recurringJobColumns} data={this.state.recurringJob} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}
				   validate={(event, columns, data) => this.validateRecurringJob(event, columns, data)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					{this.props.params.jobId && <Button id="delete" variant="warning" type="button" className="float-end" style={{ marginRight: '10px' }} onClick={(event) => this.deleteRecurringJob(event)}>Delete</Button>}
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const recurringJobColumns = [
	{ Header: 'Service', accessor: 'SERVICE_ID', type: 'select', row: 0, col: 0, required: true,
		options: { value: 'SERVICE_ID', label: 'SERVICE_NAME', entity: 'RECHARGES.SERVICE_LIST', order: 'SERVICE_NAME',
			search: { Operator: 'and', Children: [
				{ Attribute: 'DELETE_FLAG', Operator: 'is null' },
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'SERVICE_ID', Operator: '=', LongValue: 9 }
			]}}},
	{ Header: 'Description', accessor: 'JOB_NAME', row: 0, col: 1, required: true },
	{ Header: 'Customer', accessor: 'CUSTOMER_ID', type: 'select', row: 0, col: 2, required: true,
		options: { value: 'ROLE_ID', label: 'NAME_AND_TYPE', entity: 'NAME_AND_TYPE_SELECT_VW', order: ['LNAME', 'FNAME', 'TYPE_NAME'],
			search: { Operator: 'or', Children: [
				{ Operator: 'and', Children: [
					{ Attribute: 'END_ROLE_DATE', Operator: 'is null' },
					{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' }
				]},
				{ Attribute: 'ROLE_ID', Operator: '=', LongAccessor: 'CUSTOMER_ID' }
			]}}},
	{ Header: 'PI', accessor: 'PI_ROLE', type: 'select', row: 1, col: 0, required: true,
		options: { value: 'ROLE_ID', label: 'PI_NAME', entity: 'CC_PI_SELECT_VW', order: ['LNAME', 'FNAME'] }},
	{ Header: 'Speedtype', accessor: 'SPEEDTYPE', row: 1, col: 1, required: true },
	{ Header: 'Speedtype PI', accessor: 'SPEEDTYPE_PI', row: 1, col: 2, editable: false },
	{ Header: 'Labor Hrs', accessor: 'LABOR_HOURS', row: 2, col: 0, required: true },
	{ Header: 'Material', accessor: 'MATERIAL_COST', row: 2, col: 1, required: true },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 2, col: 2 },	
	{ Header: 'Start', accessor: 'START_DATE', type: 'date', row: 3, col: 0, required: true },
	{ Header: 'End', accessor: 'END_DATE', type: 'date', row: 3, col: 1 },
	{ Header: 'Bill', accessor: 'BILL_FLAG', type: 'checkbox', row: 3, col: 2 },
	{ Header: 'Last Transfer', accessor: 'TRANSFERRED', row: 4, col: 0, editable: false },
	{ Header: 'Created', accessor: 'CREATED', row: 4, col: 1, editable: false },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 4, col: 2, editable: false }
];

export default withRouter(RecurringJob);
