import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class HeUsers extends ChemComponent {
	constructor(props) {
		super(props);

		var search = {
			RECHARGE_ID: null,
			COST_CENTER: '',
		};
		
		var userListInit = this.copyTable(userList);
		
		if (props.search) {
			var propsSearch = this.copyObject(props.search);
			this.mergeObject(search, propsSearch);
			search = propsSearch;
			userListInit.query.search = this.constructSearch(search);
		}

		this.state = {
			userList: userListInit,
			search: search,
			searchCount: null,
			renderKey: 0
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newUserList = this.copyTable(userList);
		newUserList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			userList: newUserList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}

	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.RECHARGE_ID)) search.Children.push({ Attribute: 'RECHARGE_ID', Operator: '=', LongValue: data.RECHARGE_ID });
		if (!this.isEmpty(data.COST_CENTER)) search.Children.push({ Attribute: 'COST_CENTER', Operator: 'like', StringValue: '%' + data.COST_CENTER + '%' });

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	addUser() {
		this.props.navigate('/HeLAD/HeUsers/new');
	}
	
	editUser(userId) {
		this.props.setHeUserEditable(true);
		this.props.navigate('/HeLAD/HeUsers/' + userId);		
	}

	viewUser(userId) {
		this.props.setHeUserEditable(false);
		this.props.navigate('/HeLAD/HeUsers/' + userId);		
	}
	
	viewMeter(meterId) {
		this.props.setHeMeterEditable(false);
		this.props.navigate('/HeLAD/HeMeters/' + meterId);
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Helium Users',
			columns: exportColumns,
			entity: 'LIQUID_AIR.HE_USERS_VW',
			search: this.constructSearch(this.state.search),
			order: 'PI_NAME'
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Helium Recovery Users</span>
						<ChemEdit parent={this} user={this.props.user} columns={userSearch} data={this.state.search}
						  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='HeLAD_HeUsers' table={this.state.userList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='185px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const userSearch = [
	{ Header: 'User', accessor: 'RECHARGE_ID', type: 'select', row: 0, col: 0,
		options: { value: 'RECHARGE_ID', label: 'RECHARGE_DESC', entity: 'LIQUID_AIR.HE_RECHARGE_SELECT_VW', order: 'RECHARGE_DESC' }},
	{ Header: 'Speedtype', accessor: 'COST_CENTER', row: 0, col: 1 }
];

const userList = {
	query: { entity: 'LIQUID_AIR.HE_USERS_VW', search: null, order: 'PI_NAME' },
	pageSize: 20,
	columns: [
		{ Header: 'METER_ID', accessor: 'METER_ID', show: false },
		{ Header: 'RECHARGE_ID', accessor: 'RECHARGE_ID', show: false },
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addUser()} />, accessor: 'id', width: 25, 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editUser(props.row.values.RECHARGE_ID)} /> },
		{ Header: 'Meter', accessor: 'METER_NAME', width: 60,
			Cell: props => <HashLink onClick={() => props.parent.viewMeter(props.row.values.METER_ID)}>{props.value}</HashLink> },
		{ Header: 'PI', accessor: 'PI_NAME', width: 200,
			Cell: props => <HashLink onClick={() => props.parent.viewUser(props.row.values.RECHARGE_ID)}>{props.value}</HashLink> },
		{ Header: 'Speedtype', accessor: 'COST_CENTER', width: 125 },
		{ Header: 'Percentage', accessor: 'PERCENTAGE', width: 125 },
		{ Header: 'Room', accessor: 'ROOM', width: 70 },
		{ Header: 'Building', accessor: 'BUILDING', width: 125 },
		{ Header: 'Room Served', accessor: 'ROOM_SERVED', width: 225 },
		{ Header: 'Valid', accessor: 'VALID_FLAG', width: 60 }
	]
};

const exportColumns = [
		{ Header: 'Meter', accessor: 'METER_ID', width: 12 },
		{ Header: 'PI', accessor: 'PI_NAME', width: 40 },
		{ Header: 'Speedtype', accessor: 'COST_CENTER', width: 25 },
		{ Header: 'Percentage', accessor: 'PERCENTAGE', width: 25 },
		{ Header: 'Room', accessor: 'ROOM', width: 14 },
		{ Header: 'Building', accessor: 'BUILDING', width: 25 },
		{ Header: 'Room Served', accessor: 'ROOM_SERVED', width: 45 },
		{ Header: 'Valid', accessor: 'VALID_FLAG', width: 12 }
];

export default withRouter(HeUsers);
