import { Fragment } from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import EditTable from '../EditTable';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class Product extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			editable: props.editable,
			editorsOpen: [],
			product: {
				PRODUCT_ID: '',
				prices: []
			},
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadProduct();
	}

	loadProduct(event) {
		var self = this;
		
		if (this.props.params.productId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/HeLAD/GetProduct',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					productid: this.props.params.productId
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var product = data.Data[0];
						self.mvc2js(product, productColumns);
							
						self.mergeState({
							product: product,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Product Error', data.Data.length + ' Products found for ID ' + self.props.params.productId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				product: {
					PRODUCT_ID: '(new)',
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var product = this.copyObject(this.state.product);
		if (Array.isArray(accessor)) {
			for (var i = 0; i < accessor.length; i++) {
				this.setByAccessor(product, accessor[i], value[i]);
			}
		} else {
			this.setByAccessor(product, accessor, value);
		}
		var newState = {
			product: product,
			renderKey: this.state.renderKey + 1
		};
		if (['prices'].includes(accessor)) {
			// update editor status as well
			newState.editorsOpen = this.setEditorStatus(accessor, false);
		}
		this.mergeState(newState);
	}
		
	/* TODO: add delete_flag to products table
	deleteProduct(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Product', 'Delete Product ' + this.props.params.productId + '?', (okClicked) => {
			if (okClicked) {
				// prepare product for deletion		
				var product = {
					PRODUCT_ID: self.props.params.productId,
					DELETE_FLAG: 'Y',
					MODIF_BY: self.props.user.name,
					MODIF_DATE: self.dateTimeToMVC(new Date())
				};
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'ADMISSIONS.COURSES', data: product }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Product {self.props.params.productId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
	*/
	
	onEdit(accessor, editorIsOpen) {
		this.mergeState({
			editorsOpen: this.setEditorStatus(accessor, editorIsOpen),
			renderKey: this.state.renderKey + 1
		});
	}
	
	setEditorStatus(accessor, editorIsOpen) {
		var editorsOpen = this.copyObject(this.state.editorsOpen);
		if (editorIsOpen) {
			// add to list if it isn't already present
			if (!editorsOpen.includes(accessor)) editorsOpen.push(accessor);
		} else {			
			// remove from list if it is present
			if (editorsOpen.includes(accessor)) editorsOpen.splice(editorsOpen.indexOf(accessor), 1);
		}
		return editorsOpen;
	}		
		
	onSubmit(event) {
		var self = this;
		
		// if price editor is open
		if (this.state.editorsOpen.length > 0) {
			this.showAlert('Price Editor Open', 'Please close the price editor before saving.');
		} else {		
			// make copy of data
			var product = this.copyObject(this.state.product);

			this.js2mvc(product, productColumns);
			
			// if this is a new product
			if (!this.props.params.productId) {
				// set ID to sequence name
				product.PRODUCT_ID = 'seq_products.nextval';
			}
			
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/HeLAD/UpdateProduct',
				overlay: true,
				data: { __RequestVerificationToken: this.props.user.antiForgeryToken, product: product }
			}).done(function (data) {
				if (data.Success) {
					var productId = self.props.params.productId || data.ID;
					var verb = self.props.params.productId ? 'updated' : 'inserted';
					self.props.parent.showConfirmation(() => {
						return (<>
							Product <Link to={'/HeLAD/Products/' + productId}>{productId}</Link> {verb}.
						</>);
					});
				} else {
					self.showAlert('Server Error', data.Message);
				}
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		}
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Product {this.state.product.PRODUCT_ID}&nbsp;&nbsp;
							{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
							  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
						  </div>
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={productColumns} data={this.state.product} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} editable={this.state.editable} />
					  </Col>
					</Row>
					<Row>
					  <Col>
						<EditTable title='Prices' parent={this} user={this.props.user} renderKey={this.state.renderKey} newRecord={emptyPrice} data={this.state.product.prices} editable={this.state.editable}
						  tableColumns={priceTableColumns} editColumns={priceEditColumns} onEdit={(editorIsOpen) => this.onEdit('prices', editorIsOpen)}
						  onChange={(data, callback) => this.onChange('prices', data)} tableName='HeLAD_Product_Prices'>
						  <EditTable title='Price Details' parent={this} user={this.props.user} renderKey={this.state.renderKey} newRecord={emptyDetails} accessor='price_details' editable={this.state.editable}
							tableColumns={detailTableColumns} editColumns={detailEditColumns} tableName='HeLAD_Product_Price_Details' />
						</EditTable>
					  </Col>
					</Row>
					<Row style={{ paddingTop: 5 }}>
					  <Col>
						{this.state.editable && <Button id="save" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmit(event)}>Save</Button>}
						{/* this.state.editable && this.props.params.productId && <Button id="delete" variant="warning" type="button" className="float-end"
						  style={{ marginRight: '20px' }} onClick={(event) => this.deleteProduct(event)}>Delete</Button> */} 
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const productColumns = [
	{ Header: 'Name', accessor: 'PRODUCT_NAME', row: 0, col: 0 },
	{ Header: 'State', accessor: 'PRODUCT_STATE', row: 0, col: 1 },
	{ Header: 'Description', accessor: 'PRODUCT_DESC', row: 0, col: 2 }
];

const emptyPrice = {
	PRICE_ID: 'seq_prices.nextval',
	PRODUCT_ID: null,
	UNIT_ID: null,
	UNIT_NAME: null,
	LOWER_QTY_RANGE: null,
	UPPER_QTY_RANGE: null,
	PARENT_PRICE: null,
	CONT_TYPE_ID: null,
	CONT_TYPE: null,
	PRICE_DESC: null,
	price_details: []
};

const priceTableColumns = [
	{ Header: 'Description', accessor: 'PRICE_DESC' },
	{ Header: 'Parent Price', accessor: 'PARENT_PRICE_DESC' },
	{ Header: 'Unit', accessor: 'UNIT_NAME' },
	{ Header: 'Container', accessor: 'CONT_TYPE' },
	{ Header: 'Min Qty', accessor: 'LOWER_QTY_RANGE' },
	{ Header: 'Max Qty', accessor: 'UPPER_QTY_RANGE' }
];

const priceEditColumns = [
	{ Header: 'Description', accessor: 'PRICE_DESC', row: 0, col: 0 },
	{ Header: 'Parent Price', accessor: 'PARENT_PRICE', labelAccessor: 'PARENT_PRICE_DESC', type: 'select', row: 0, col: 1,
		options: { value: 'PRICE_ID', label: 'PRICE_DESC', entity: 'LIQUID_AIR.PRICES_VW',
			search: { Attribute: 'PRODUCT_ID', Operator: '=', LongAccessor: 'PRODUCT_ID' }}},
	{ Header: 'Unit', accessor: 'UNIT_ID', labelAccessor: 'UNIT_NAME', type: 'select', row: 0, col: 2,
		options: { value: 'UNIT_ID', label: 'UNIT_NAME', entity: 'LIQUID_AIR.MEASUREMENT_UNITS', order: 'UNIT_NAME' }},
	{ Header: 'Container', accessor: 'CONT_TYPE_ID', labelAccessor: 'CONT_TYPE', type: 'select', row: 1, col: 0,
		options: { value: 'CONT_TYPE_ID', label: 'CONT_TYPE', entity: 'LIQUID_AIR.CONTAINER_TYPES', order: 'CONT_TYPE' }},
	{ Header: 'Min Qty', accessor: 'LOWER_QTY_RANGE', row: 1, col: 1 },
	{ Header: 'Max Qty', accessor: 'UPPER_QTY_RANGE', row: 1, col: 2 }
];

const emptyDetails = {
	PRICE_ID: null,
	UNIT_PRICE: null,
	START_VALID_DATE: null,
	END_VALID_DATE: null,
	LOWER_RANGE_PRICE: null,
	RATIO_AMOUNT: null,
	CONVERSION_RATIO: null,
	RATIO_DESC: null
};

const detailTableColumns = [
	{ Header: 'Unit Price', accessor: 'UNIT_PRICE' },
	{ Header: 'Valid From', accessor: 'START_VALID_DATE', Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
	{ Header: 'Valid To', accessor: 'END_VALID_DATE', Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
	{ Header: 'Lower Range Price', accessor: 'LOWER_RANGE_PRICE' },
	{ Header: 'Conversion Ratio', accessor: 'CONVERSION_RATIO' },
	{ Header: 'Ratio Amount', accessor: 'RATIO_AMOUNT' },
	{ Header: 'Ratio Desc.', accessor: 'RATIO_DESC' }
];

const detailEditColumns = [
	{ Header: 'Unit Price', accessor: 'UNIT_PRICE', row: 0, col: 0 },
	{ Header: 'Valid From', accessor: 'START_VALID_DATE', type: 'date', row: 0, col: 1 },
	{ Header: 'Valid To', accessor: 'END_VALID_DATE', type: 'date', row: 0, col: 2 },
	{ Header: 'Lower Range Price', accessor: 'LOWER_RANGE_PRICE', row: 1, col: 0 },
	{ Header: 'Conversion Ratio', accessor: 'CONVERSION_RATIO', row: 1, col: 1 },
	{ Header: 'Ratio Amount', accessor: 'RATIO_AMOUNT', row: 1, col: 2 },
	{ Header: 'Ratio Desc.', accessor: 'RATIO_DESC', row: 2, col: 2 }
];

export default withRouter(Product);
