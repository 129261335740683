import React from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class GraphicsUser extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			graphicsUser: {
				USER_ID: null,
				USER_NAME: '',
				LNAME: '',
				FNAME: '',
				EMAIL: '',
				COST_CENTER: '',
				PI_ID: null,
				RECHARGE: 'Y',
				COMMENTS: '',
				INACTIVE_FLAG: undefined,
				LAST_MODIF: null,
				MODIF_BY: '',
				MODIFIED: ''
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadGraphicsUser();
	}

	loadGraphicsUser(event) {
		var self = this;
		
		if (this.props.params.userId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/CRS/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'GRAPHIC_USERS',
					search: { Attribute: 'USER_ID', Operator: '=', LongValue: this.props.params.userId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var graphicsUser = data.Data[0];
						
						// convert flags
						graphicsUser.INACTIVE_FLAG = graphicsUser.INACTIVE_FLAG === 'Y' ? true : false;
						graphicsUser.RECHARGE = graphicsUser.RECHARGE === 'Y' ? true : false;
						
						// compose created/modified
						graphicsUser.MODIFIED = self.composeChangeInfo(graphicsUser.MODIF_BY, graphicsUser.LAST_MODIF);
																		
						self.mergeState({
							graphicsUser: graphicsUser,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Graphics User Error', data.Data.length + ' Graphics Users found for ID ' + self.props.params.userId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				graphicsUser: {
					USER_ID: '(new)'
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var graphicsUser = this.copyObject(this.state.graphicsUser);
		this.setByAccessor(graphicsUser, accessor, value);
		this.mergeState({ graphicsUser: graphicsUser, renderKey: this.state.renderKey + 1 });
	}
	
	deleteUser(event) {
		var self = this;
		
		// assemble user object to update inactive flag
		var graphicsUser = {
			USER_ID: this.props.params.userId,
			INACTIVE_FLAG: 'Y',
			LAST_MODIF: this.dateTimeToMVC(new Date()),
			MODIF_BY: this.props.user.name
		};
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/Home/Update',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'RECHARGES.GRAPHIC_USERS', data: graphicsUser }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Graphics User {this.props.params.userId} deleted.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	validateGraphicsUser(event, columns, data) {
		return this.validate(columns, data);
	}
		
	onSubmit(event) {
		var self = this;		
		
		// make copy of data
		var graphicsUser = this.copyObject(this.state.graphicsUser);

		// if this is a new user
		if (!this.props.params.userId) {
			// set ID sequence
			graphicsUser.USER_ID = 'graphics_user_seq.nextval';
		}

		// convert flags
		graphicsUser.INACTIVE_FLAG = graphicsUser.INACTIVE_FLAG ? 'Y' : null;
		graphicsUser.RECHARGE = graphicsUser.RECHARGE ? 'Y' : 'N';
		
		// update modification
		graphicsUser.LAST_MODIF = this.dateTimeToMVC(new Date());
		graphicsUser.MODIF_BY = this.props.user.name;
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/CRS/UpdateGraphicsUser',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, graphicsUser: graphicsUser }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Graphics User <Link to={'/CRS/GraphicsUsers/' + data.ID}>{data.ID}</Link> updated.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Graphics User {this.state.graphicsUser.USER_ID}</div>
				<ChemEdit parent={this} columns={graphicsUserColumns} data={this.state.graphicsUser} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}
				   validate={(event, columns, data) => this.validateGraphicsUser(event, columns, data)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					{this.props.params.userId && <Button id="delete" variant="warning" type="button" className="float-end" style={{ marginRight: '10px' }} onClick={(event) => this.deleteUser(event)}>Delete</Button>}
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const graphicsUserColumns = [
	{ Header: 'User Name', accessor: 'USER_NAME', row: 0, col: 0, required: true },
	{ Header: 'Last Name', accessor: 'LNAME', row: 0, col: 1, required: true },
	{ Header: 'First Name', accessor: 'FNAME', row: 0, col: 2, required: true },
	{ Header: 'Email', accessor: 'EMAIL', row: 1, col: 0, required: true },
	{ Header: 'Speedtype', accessor: 'COST_CENTER', row: 1, col: 1, required: true },
	{ Header: 'PI', accessor: 'PI_ID', type: 'select', row: 1, col: 2, required: true,
		options: { value: 'PERSON_ID', label: 'PI_NAME', entity: 'RECHARGES.GRAPHIC_USER_PI_SELECT_VW', order: 'PI_NAME' }},
	{ Header: 'Recharge', accessor: 'RECHARGE', type: 'checkbox', row: 2, col: 0 },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 2, col: 1 },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 2, col: 2, editable: false }
];

export default withRouter(GraphicsUser);
