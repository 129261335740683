import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class Headers extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			SERVICE_NAME: '',
			RCH_NAME: '',
			SERVICE_TITLE: ''
		};
		
		// initialize headers list
		var initialHeadersList = this.copyTable(headersList);
		initialHeadersList.query.search = this.constructSearch(search);
		
		// remove add/edit column if not authorized
		if (props.user.crs.userAccessID !== 1) {
			initialHeadersList.columns.splice(0, 1);
		}
		
		this.state = {
			renderKey: 1,
			headersList: initialHeadersList,
			search: search,
			searchCount: null
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newHeadersList = this.copyTable(headersList);
		newHeadersList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			headersList: newHeadersList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.HEADER_NAME)) search.Children.push({ Attribute: 'HEADER_NAME', Operator: 'like', StringValue: '%' + data.HEADER_NAME + '%' });
		if (!this.isEmpty(data.SERVICE_NAME)) search.Children.push({ Attribute: 'SERVICE_NAME', Operator: 'like', StringValue: '%' + data.SERVICE_NAME + '%' });		
		if (!this.isEmpty(data.COMMENTS)) search.Children.push({ Attribute: 'COMMENTS', Operator: 'like', StringValue: '%' + data.COMMENTS + '%' });
		
		if (search.Children.length === 0) return null;
		return search;
	}
	
	addHeader() {
		this.props.navigate('/CRS/Headers/new');
	}
	
	editHeader(jobId) {
		this.props.navigate('/CRS/Headers/' + jobId);
	}
	
	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Headers',
			columns: exportColumns,
			entity: 'RECHARGES.HEADERS_VW',
			search: this.constructSearch(this.state.search),
			order: ['SERVICE_UNIT', 'SERVICE_ID']
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Headers</span>
					<ChemEdit parent={this} columns={headersSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={4} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_Headers' table={this.state.headersList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='200px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const headersSearch = [
	{ Header: 'Name', accessor: 'HEADER_NAME', row: 0, col: 0 },
	{ Header: 'Service', accessor: 'SERVICE_NAME', row: 0, col: 1 },
	{ Header: 'Comments', accessor: 'COMMENTS', row: 0, col: 2 }
];

const headersList = {
	query: { entity: 'RECHARGES.HEADERS_VW', search: null, order: ['SERVICE_UNIT', 'SERVICE_ID'] },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addHeader()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editHeader(props.row.values.HEADER_ID)} />, width: 25 },
		{ Header: 'ID', accessor: 'HEADER_ID', width: 60 },
		{ Header: 'Name', accessor: 'HEADER_NAME', width: 250 },
		{ Header: 'Service', accessor: 'SERVICE_NAME', width: 250 },
		{ Header: 'Comments', accessor: 'COMMENTS', width: 800 },
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'HEADER_ID', width: 12 },
	{ Header: 'Name', accessor: 'HEADER_NAME', width: 50 },
	{ Header: 'Service', accessor: 'SERVICE_NAME', width: 50 },
	{ Header: 'Comments', accessor: 'COMMENTS', width: 160 },
];

export default withRouter(Headers);