import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';
import ChemTable from '../ChemTable';
import Form from 'react-bootstrap/Form';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Col, Container, Row, Button } from 'react-bootstrap';

class CAF extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			caf: {
				file: null
			},
			result: null,
			progress: 0,
			renderKey: 0
		}
	}
		
	onChange(accessor, value) {
		this.mergeState({
			caf: {
				file: value
			}
		});
	}

	onSubmit(event) {
		var self = this;
		// if there is a file
		if (this.state.caf.file && this.state.caf.file.name) {			
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/CRS/UploadCAFMetadata',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					fileName: this.state.caf.file.name,
					fileSize: this.state.caf.file.size
				}
			}).done(function (data) {
				if (data.Success) {
					self.sendFileChunk(data.UploadID, 0);
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		} else {
			self.showAlert('No File Selected', 'Please select a file to upload');
		}
	}
	
	sendFileChunk(uploadID, chunkIdx) {
		var self = this;
		
		// calculate start of file chunk
		var fileChunkStart = chunkIdx * FILE_CHUNK_SIZE;
		
		// calculate end of file chunk
		var fileChunkEnd = fileChunkStart + FILE_CHUNK_SIZE;
		
		// if this is the last chunk, adjust file size
		if (fileChunkEnd > this.state.caf.file.size) fileChunkEnd = this.state.caf.file.size;
		
		// get slice of file
		var fileSlice = null;
		if (this.state.caf.file.slice) {
			fileSlice = this.state.caf.file.slice(fileChunkStart, fileChunkEnd);
		} else if (this.state.caf.file.webkitSlice) {
			fileSlice = this.state.caf.file.webkitSlice(fileChunkStart, fileChunkEnd);
		} else if (this.state.caf.file.mozSlice) {
			fileSlice = this.state.caf.file.mozSlice(fileChunkStart, fileChunkEnd);
		}
		
		// create the formdata object
		var formData = new FormData();
		
		// append verification token
		formData.append('__RequestVerificationToken', this.props.user.antiForgeryToken);
		
		// append upload ID
		formData.append('uploadID', uploadID);
		
		// append chunk index
		formData.append('chunkIndex', chunkIdx);
		
		// append file slice
		formData.append('fileChunk', fileSlice);
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/CRS/UploadCAFFileChunk',
			data: formData,
			contentType: false,
			processData: false
		}).done(function (data) {
			if (data.Success) {
				// increment chunk index
				chunkIdx++;
				
				// calculate total bytes sent so far
				var totalBytesSent = chunkIdx * FILE_CHUNK_SIZE;
				
				// if the whole file has been sent
				if (totalBytesSent >= self.state.caf.file.size)
				{
					// set progress bar to 100%
					self.mergeState({ progress: 100 });
					
					// if the file is complete
					if (data.FileComplete) {
						// process the uploaded file
						self.processCAFUpload(uploadID);
					} else {
						self.showAlert('File Upload Error', 'File upload could not be confirmed by the server.');
					}
				} else {
					// calculate progress percentage
					var progressPct = Math.round((totalBytesSent / self.state.caf.file.size) * 100);
					
					// display percentage in progress bar
					self.mergeState({ progress: progressPct });
					
					// send next file chunk
					self.sendFileChunk(uploadID, chunkIdx);
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	processCAFUpload(uploadID) {
		var self = this;
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/CRS/ProcessCAFUpload',
			overlay: true,
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				uploadID : uploadID
			}
		}).done(function (data) {
			if (data.Success) {
				var i;
				
				// add ids to tables so they will display correctly
				for (i = 0; i < data.ValidSpeedTypes.length; i++) {
					data.ValidSpeedTypes[i].id = i;
				}
				for (i = 0; i < data.InvalidSpeedTypes.length; i++) {
					data.InvalidSpeedTypes[i].id = i;
				}
				
				self.mergeState({
					progress: 0,
					result: data,
					renderKey: self.state.renderKey + 1
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	onSubmitEntireFile(event) {
		var self = this;
		// if there is a file
		if (this.state.caf.file && this.state.caf.file.name) {
			// Create an object of formData
			const formData = new FormData();

			// add the token to the formData
			formData.append("__RequestVerificationToken", this.props.user.antiForgeryToken);
		
			// add the file information
			formData.append("caf", this.state.caf.file, this.state.caf.file.name);
			
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/CRS/UploadCAF',
				overlay: true,
				data: formData,
				contentType: false,
				processData: false
			}).done(function (data) {
				if (data.Success) {
					var i;
					
					// add ids to tables so they will display correctly
					for (i = 0; i < data.ValidSpeedTypes.length; i++) {
						data.ValidSpeedTypes[i].id = i;
					}
					for (i = 0; i < data.InvalidSpeedTypes.length; i++) {
						data.InvalidSpeedTypes[i].id = i;
					}
					
					self.mergeState({
						result: data,
						renderKey: self.state.renderKey + 1
					});
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		} else {
			self.showAlert('No File Selected', 'Please select a file to upload');
		}
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col md={12} lg={11} xl={10} style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>CalAnswers Report Data Upload</span>
				  <ul>
					<li>Upload data exported in a .xlsx format from a CAF report to update chart of account, fund end date, fund name, subsidy, Fund Group Descr, and fund extension requests.</li>
					<li>Columns must be in order with the following headers (NOTE: No spaces in column header):
						<ul>
							<li>SpeedtypeKey</li>
							<li>SpeedtypeDesc</li>
							<li>AccountCode</li>
							<li>FundCode</li>
							<li>DeptID</li>
							<li>ProgramCode</li>
							<li>CF1Code</li>
							<li>CF2Code</li>
							<li>BFSFundDesc</li>
						</ul>
					</li>
				  </ul>	
			      <ChemEdit parent={this} columns={cafColumns} data={this.state.caf} user={this.props.user} renderKey={this.state.renderKey}
					onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
				    <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={11} style={{ paddingTop: '10px' }}>
							<ProgressBar now={this.state.progress} />
						</Col>
						<Col xs={1}>
							<Button id="save" variant="warning" type="submit" className="float-end">Upload</Button>
						</Col>
					</Form.Group>
				  </ChemEdit>
				</Col>
			  </Row>
			  {this.state.result && <>
			    <Row>
				  <Col md={12} lg={11} xl={10} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Valid SpeedTypes</span>
				  	<ChemTable renderKey={this.state.renderKey} parent={this} data={this.state.result.ValidSpeedTypes} columns={validSpeedTypeColumns} name='CRS_CAF_ValidSpeedTypes' />
				  </Col>
				</Row>
				<Row>
				  <Col md={12} lg={11} xl={10} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Invalid SpeedTypes</span>
				  	<ChemTable renderKey={this.state.renderKey} parent={this} data={this.state.result.InvalidSpeedTypes} columns={invalidSpeedTypeColumns} name='CRS_CAF_InvalidSpeedTypes' />
				  </Col>
				</Row>
			  </>}
			</Container>
		</>);
	}
}

const FILE_CHUNK_SIZE = 1048576;

const cafColumns = [
	{ Header: 'File', accessor: 'file', type: 'file' }
];

const validSpeedTypeColumns = [
	{ Header: 'Row Number', accessor: 'RowID' },
	{ Header: 'SpeedType', accessor: 'SpeedType' },
	{ Header: 'Description', accessor: 'SpeedTypeDesc' },
	{ Header: 'BFS Fund Desc', accessor: 'BFSFundDesc' }
];

const invalidSpeedTypeColumns = [
	{ Header: 'Row Number', accessor: 'RowID' },
	{ Header: 'SpeedType', accessor: 'SpeedType' },
	{ Header: 'Description', accessor: 'SpeedTypeDesc' },
	{ Header: 'BFS Fund Desc', accessor: 'BFSFundDesc' }
];

export default CAF;
