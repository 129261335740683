import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Building extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			building: {
				BUILDING_ID: null,
				BUILDING_NAME: '',
				CHEMISTRY_FLAG: null,
				DELETE_FLAG: null,
				CHANGE_INFO: {
					CREATE_BY: null,
					CREATE_DATE: null,
					MODIF_BY: null,
					MODIF_DATE: null,
					COMMENTS: null
				}
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadBuilding();
	}

	loadBuilding(event) {
		var self = this;
		
		if (this.props.params.buildingId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'BUILDING',
					search: { Attribute: 'BUILDING_ID', Operator: '=', LongValue: this.props.params.buildingId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length === 1) {
						var building = data.Data[0];
																		
						self.mergeState({
							building: building,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Building Error', data.Data.length + ' buildings found for ID ' + self.props.params.buildingId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				building: {
					BUILDING_ID: '(new)'
				}
			});
		}
	}
		
	onChange(accessor, value) {
		var building = this.copyObject(this.state.building);
		this.setByAccessor(building, accessor, value);
		this.mergeState({ building: building, renderKey: this.state.renderKey + 1 });
	}
		
	deleteBuilding(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Building', 'Delete Building ' + this.props.params.buildingId + '?', (okClicked) => {
			if (okClicked) {
				// prepare building for deletion
				var building = self.copyObject(self.state.building);
				building.DELETE_FLAG = 'Y';
				building.CHANGE_INFO.CREATE_DATE = self.dateTimeToMVC(self.getDate(building.CHANGE_INFO.CREATE_DATE));
				building.CHANGE_INFO.MODIF_DATE = self.dateTimeToMVC(new Date());
				building.CHANGE_INFO.MODIF_BY = self.props.user.name;
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'BUILDING', data: building }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Building {self.props.params.buildingId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
		
	onSubmit(event) {
		var self = this;		
		var dtNow = this.dateTimeToMVC(new Date());
		var currentUser = this.props.user.name;
		
		// make copy of data
		var building = this.copyObject(this.state.building);
		
		// modify chemistry flag to oracle format
		building.CHEMISTRY_FLAG = building.CHEMISTRY_FLAG === 'Yes' ? 'Y' : null;
		
		// if this is a new building, set ID to sequence name
		var url;
		if (this.props.params.buildingId) {
			url = this.getConfig().host + '/Home/Update';
			building.CHANGE_INFO.CREATE_DATE = this.dateTimeToMVC(this.getDate(building.CHANGE_INFO.CREATE_DATE));
			building.CHANGE_INFO.MODIF_DATE = dtNow;
			building.CHANGE_INFO.MODIF_BY = currentUser;
		} else {
			url = this.getConfig().host + '/Home/Insert';
			building.BUILDING_ID = null;
			building.CHANGE_INFO.CREATE_DATE = dtNow;
			building.CHANGE_INFO.CREATE_BY = currentUser;
			building.CHANGE_INFO.MODIF_DATE = null;
			building.CHANGE_INFO.MODIF_BY = null;
			building.DELETE_FLAG = null;
		}			
		
		this.ajax({
			type: 'POST',
			url: url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'BUILDING', data: building }
		}).done(function (data) {
			if (data.Success) {
				var buildingId = self.props.params.buildingId ? building.BUILDING_ID : data.ID;
				var verb = self.props.params.buildingId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Building <Link to={'/BuildingAccess/Buildings/' + buildingId}>{buildingId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Building {this.state.building.BUILDING_ID}</div>
				<ChemEdit parent={this} columns={buildingColumns} data={this.state.building} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					{this.props.params.buildingId && <Button id="delete" variant="warning" type="button" className="float-end"
					  style={{ marginRight: '20px' }} onClick={(event) => this.deleteBuilding(event)}>Delete</Button>} 
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const buildingColumns = [
	{ Header: 'Name', accessor: 'BUILDING_NAME', required: true },
	{ Header: 'Comments', accessor: 'CHANGE_INFO.COMMENTS' },
	{ Header: 'Chemistry', accessor: 'CHEMISTRY_FLAG', type: 'select',
		options: [{ value: 'No', label: 'No'}, { value: 'Yes', label: 'Yes' }] },
	{ Header: 'Last Modified', accessor: 'MODIFIED', editable: false,
		Cell: props => {
			// if there is no modif date
			if (props.editor.props.data.CHANGE_INFO.MODIF_DATE === null)
			{
				// if there is no create date
				if (props.editor.props.data.CHANGE_INFO.CREATE_DATE === null)
				{
					// no info to return
					return null;
				} else {
					// use creation date
					return <>{props.parent.dateToString(props.parent.getDate(props.editor.props.data.CHANGE_INFO.CREATE_DATE)) + ' ' + props.editor.props.data.CHANGE_INFO.CREATE_BY}</>;
				}
			} else {
				return <>{props.parent.dateToString(props.parent.getDate(props.editor.props.data.CHANGE_INFO.MODIF_DATE)) + ' ' + props.value}</>;
			}
		}
	},
	{ Header: 'Deleted', accessor: 'DELETE_FLAG', editable: false }
];

export default withRouter(Building);
