import ChemComponent from '../ChemComponent';
import ChemEdit from "../ChemEdit";
import ChemTable from '../ChemTable';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';

class LogAnalysis extends ChemComponent {
  	constructor(props) {
		super(props);
		
		this.state = {
			load: {
				file: null
			},
			logEntries: [],
			pageNumber: -1,
			pageSize: -1,
			pageCount: -1,
			renderKey: 0
		}
	}
	
	onChange(accessor, value) {
		this.mergeState({
			load: {
				file: value
			}
		});
	}
	
	addLogEntries(newLogEntries) {
		var existingLogEntries = this.copyObject(this.state.logEntries);
		
		for (var newIdx = 0; newIdx < newLogEntries.length; newIdx++) {
			var logEntryFound = false;
			
			for (var existingIdx = 0; existingIdx < existingLogEntries.length; existingIdx++) {
				// if this log entry already exists
				if (newLogEntries[newIdx].URL === existingLogEntries[existingIdx].URL) {
					// add to count
					existingLogEntries[existingIdx].COUNT += newLogEntries[newIdx].COUNT;
					logEntryFound = true;
					
					break;
				}
			}
			
			// if log entry was not found
			if (!logEntryFound) {
				// assign ID to new log entry
				newLogEntries[newIdx].id = existingLogEntries.length;
				
				// add as new entry
				existingLogEntries.push(newLogEntries[newIdx]);
			}
		}

		this.mergeState({
			logEntries: existingLogEntries
		});
	}

	onSubmit(event) {
		var self = this;
		// if there is a file
		if (this.state.load.file && this.state.load.file.name) {
			// Create an object of formData
			const formData = new FormData();

			// add the token to the formData
			formData.append("__RequestVerificationToken", this.props.user.antiForgeryToken);
		
			// add the file information
			formData.append("log", this.state.load.file, this.state.load.file.name);
			
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/Admin/LogAnalysis',
				data: formData,
				contentType: false,
				processData: false
			}).done(function (data) {
				if (data.Success) {
					self.addLogEntries(data.LogEntries);
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		} else {
			self.showAlert('No File Selected', 'Please select a file to upload');
		}
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col md={12} lg={11} xl={10} style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Log Analysis</span>
			      <ChemEdit parent={this} columns={loadColumns} data={this.state.load} user={this.props.user} renderKey={this.state.renderKey}
					onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
				    <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={12}>
					      <Button id="save" variant="warning" type="submit" className="float-end">Analyze</Button>
						</Col>
					</Form.Group>
				  </ChemEdit>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <ChemTable renderKey={this.state.renderKey} parent={this} name='WebAdmin_LogAnalysis' columns={logEntryColumns} data={this.state.logEntries} offsetHeight='330px'
					pageNumber={this.state.pageNumber} pageSize={this.state.pageSize} pageCount={this.state.pageCount}/>
				</Col>
			  </Row>
			</Container>
		</>);
	}

}

const logEntryColumns = [
	{ Header: 'URL', accessor: 'URL', width: 400 },
	{ Header: 'Count', accessor: 'COUNT', width: 50 }
];

const loadColumns = [
	{ Header: 'File', accessor: 'file', type: 'file' }
];

export default LogAnalysis;
