import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class Cylinder extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			editable: props.editable,
			cylinder: {
				CONTAINER_ID: '' 
			},
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadCylinder();
	}

	loadCylinder(event) {
		var self = this;
		
		if (this.props.params.cylinderId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/HeLAD/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'CONTAINERS_VW',
					search: { Attribute: 'CONTAINER_ID', Operator: '=', LongValue: this.props.params.cylinderId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var cylinder = data.Data[0];
						self.mvc2js(cylinder, cylinderColumns);
							
						self.mergeState({
							cylinder: cylinder,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Cylinder Error', data.Data.length + ' Cylinders found for ID ' + self.props.params.cylinderId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				cylinder: {
					CONTAINER_ID: '(new)',
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var cylinder = this.copyObject(this.state.cylinder);
		this.setByAccessor(cylinder, accessor, value);
		this.mergeState({ cylinder: cylinder, renderKey: this.state.renderKey + 1 });
	}
		
	onSubmit(event) {
		var self = this;		
		var url = null;
		
		// make copy of data
		var cylinder = this.copyObject(this.state.cylinder);

		this.js2mvc(cylinder, cylinderColumns);
		
		// if this is a new cylinder
		if (!this.props.params.cylinderId) {
			// set ID to sequence name
			cylinder.CONTAINER_ID = 'seq_containers.nextval';
			url = '/Home/Insert';
		} else {
			url = '/Home/Update';
		}
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'LIQUID_AIR.CONTAINERS', data: cylinder }
		}).done(function (data) {
			if (data.Success) {
				var cylinderId = self.props.params.cylinderId || data.ID;
				var verb = self.props.params.cylinderId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Cylinder <Link to={'/HeLAD/Cylinders/' + cylinderId}>{cylinderId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		
		var barcodeData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			cylinderid: this.props.params.cylinderId
		});

		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col xs={6}>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Cylinder {this.state.cylinder.CONTAINER_ID}&nbsp;&nbsp;
							{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
							  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
						  </div>
					  </Col>
					  <Col xs={6}>
						<form action={this.getConfig().host + '/HeLAD/CylinderBarcode'} method='POST'>
							{barcodeData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Print Barcode</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Print Barcode" />
							</OverlayTrigger>
						</form>					  
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={cylinderColumns} data={this.state.cylinder} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)} editable={this.state.editable}>
						  <Form.Group>
							{this.state.editable && <Button id="save" variant="warning" type="submit" className="float-end">Save</Button>}
						  </Form.Group>
						</ChemEdit>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const cylinderColumns = [
	{ Header: 'Manufacturer Code', accessor: 'MFR_CODE', row: 0, col: 0 },
	{ Header: 'College ID', accessor: 'COLLEGE_ID', row: 0, col: 1 },
	{ Header: 'Product', accessor: 'PRODUCT_ID', type: 'select', row: 1, col: 0,
		options: { value: 'PRODUCT_ID', label: 'CYL_PRODUCT', entity: 'LIQUID_AIR.CYLINDER_PRODUCTS_VW' }},
	{ Header: 'Rental Rate', accessor: 'RENTAL_RATE', row: 1, col: 1 },
	{ Header: 'Status', accessor: 'STATUS_ID', type: 'select', row: 2, col: 0,
		options: { value: 'STATUS_ID', label: 'STATUS_NAME', entity: 'LIQUID_AIR.CYLINDER_STATUS' }},
];

export default withRouter(Cylinder);
