import React from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class EmailContact extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			emailContact: {
				EMAIL_ID: null,
				EMAIL: '',
				REC_NAME: '',
				OUTSIDE_FLAG: undefined,
				COMMENTS: '',
				DATE_CREATED: null,
				CREATED_BY: null,
				DATE_MODIF: null,
				MODIF_BY: null,
				DELETE_FLAG: undefined,
				CREATED: '',
				MODIFIED: ''
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadEmailContact();
	}

	loadEmailContact(event) {
		var self = this;
		
		if (this.props.params.emailId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/CRS/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'RECHARGE_EMAILS',
					search: { Attribute: 'EMAIL_ID', Operator: '=', LongValue: this.props.params.emailId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var emailContact = data.Data[0];
						
						// convert flags
						emailContact.OUTSIDE_FLAG = emailContact.OUTSIDE_FLAG === 'Y' ? true : false;
						emailContact.DELETE_FLAG = emailContact.DELETE_FLAG === 'Y' ? true : false;
						
						// compose created/modified
						emailContact.CREATED = self.composeChangeInfo(emailContact.CREATED_BY, emailContact.DATE_CREATED);
						emailContact.MODIFIED = self.composeChangeInfo(emailContact.MODIF_BY, emailContact.DATE_MODIF);
																		
						self.mergeState({
							emailContact: emailContact,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Graphics User Error', data.Data.length + ' Graphics Users found for ID ' + self.props.params.userId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				emailContact: {
					EMAIL_ID: '(new)'
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var emailContact = this.copyObject(this.state.emailContact);
		this.setByAccessor(emailContact, accessor, value);
		this.mergeState({ emailContact: emailContact, renderKey: this.state.renderKey + 1 });
	}
	
	deleteEmail(event) {
		var self = this;
		
		// assemble user object to update inactive flag
		var emailContact = {
			EMAIL_ID: this.props.params.emailId,
			DELETE_FLAG: 'Y',
			DATE_MODIF: this.dateTimeToMVC(new Date()),
			MODIF_BY: this.props.user.name
		};
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/Home/Update',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'RECHARGES.RECHARGE_EMAILS', data: emailContact }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Email Contact {self.props.params.emailId} deleted.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	validateEmailContact(event, columns, data) {
		return this.validate(columns, data);
	}
		
	onSubmit(event) {
		var self = this;		
		
		// make copy of data
		var emailContact = this.copyObject(this.state.emailContact);

		// if this is an existing user
		var url;
		if (this.props.params.emailId) {
			url = this.getConfig().host + '/Home/Update';
			// update modification
			emailContact.DATE_MODIF = this.dateTimeToMVC(new Date());
			emailContact.MODIF_BY = this.props.user.name;
			emailContact.DATE_CREATED = this.dateTimeToMVC(this.getDate(emailContact.DATE_CREATED));
		} else {
			url = this.getConfig().host + '/Home/Insert';
			// set ID sequence
			emailContact.EMAIL_ID = 'recharge_email_id_seq.nextval';
			
			// set creation info
			emailContact.DATE_CREATED = this.dateTimeToMVC(new Date());
			emailContact.CREATED_BY = this.props.user.name;
		}

		// convert flags
		emailContact.OUTSIDE_FLAG = emailContact.OUTSIDE_FLAG ? 'Y' : null;
		emailContact.DELETE_FLAG = emailContact.DELETE_FLAG ? 'Y' : null;

		this.ajax({
			type: 'POST',
			url: url,
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'RECHARGES.RECHARGE_EMAILS', data: emailContact }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					var id = self.props.params.emailId ? self.props.params.emailId : data.ID;
					return (<>
						Email Contact <Link to={'/CRS/EmailContacts/' + id}>{id}</Link> updated.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Email Contact {this.state.emailContact.EMAIL_ID}</div>
				<ChemEdit parent={this} columns={emailContactColumns} data={this.state.emailContact} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}
				   validate={(event, columns, data) => this.validateEmailContact(event, columns, data)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					{this.props.params.emailId && <Button id="delete" variant="warning" type="button" className="float-end" style={{ marginRight: '10px' }} onClick={(event) => this.deleteEmail(event)}>Delete</Button>}
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const emailContactColumns = [
	{ Header: 'Email', accessor: 'EMAIL', row: 0, col: 0, required: true },
	{ Header: 'Name', accessor: 'REC_NAME', row: 0, col: 1, required: true },
	{ Header: 'Outside Contact', accessor: 'OUTSIDE_FLAG', type: 'checkbox', row: 1, col: 0 },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 1, col: 1 },
	{ Header: 'Created', accessor: 'CREATED', row: 2, col: 0, editable: false },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 2, col: 1, editable: false }
];

export default withRouter(EmailContact);
