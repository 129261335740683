import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class Catalog extends ChemComponent {
	constructor(props) {
		super(props);

		var search = {
            CATALOG_NUMBER: '',
			CATALOG_NUMBER_LIKE: '',
            VENDOR_NAME: '',
			VENDOR_NAME_LIKE: '',
            STOCK_NUMBER: '',
            CATEGORY: [],
            DESCRIPTION: '',
            LOCATION: '',
            LAST_MODIF_FROM: null,
			LAST_MODIF_TO: null
		};
		
		var catalogListInit = this.copyTable(catalogList);
		
		if (props.search) {
			var propsSearch = this.copyObject(props.search);
			this.mergeObject(search, propsSearch);
			search = propsSearch;
		}

		this.state = {
			catalogList: catalogListInit,
			search: search,
			searchCount: 0,
			renderKey: 0
		};
	}

	componentDidMount() {
		if (this.props.search) this.onSubmitSearch();
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		if (accessor === 'CATALOG_NUMBER') {
			this.setByAccessor(search, 'CATALOG_NUMBER_LIKE', '%' + value.toUpperCase() + '%');
		} else if (accessor === 'VENDOR_NAME') {
			this.setByAccessor(search, 'VENDOR_NAME_LIKE', '%' + value.toUpperCase() + '%');
		}
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newCatalogList = this.copyTable(catalogList);
		newCatalogList.ajax.data = {
			search: this.copyObject(this.state.search)
		};
		newCatalogList.hold = false;
		this.mergeState({ 
			catalogList: newCatalogList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	addItem() {
		this.props.navigate('/Stores/Catalog/new');
	}
	
	editItem(itemId) {
		this.props.setItemEditable(true);
		this.props.navigate('/Stores/Catalog/' + itemId);		
	}

	viewItem(itemId) {
		this.props.setItemEditable(false);
		this.props.navigate('/Stores/Catalog/' + itemId);		
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			Action: 'Catalog',
			columns: exportColumns,
			search: this.state.search
		});
		
		var exportAllData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Catalog',
			columns: exportAllColumns,
			entity: 'STORES.EXPORT_CATALOG_VW',
			landscape: true
		});

		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Catalog</span>
						<ChemEdit parent={this} user={this.props.user} columns={catalogSearch} data={this.state.search} autoSubmit={true}
						  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)}
						  onSubmit={(event) => this.onSubmitSearch(event)} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={2} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={2} style={{ marginTop: 8 }}>
						<div className="float-end">Export search results:</div>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Stores/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Stores/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={2} style={{ marginTop: 8 }}>
						<div className="float-end">Export full catalog:</div>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportAllData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportAllData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={2}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='Stores_Catalog' table={this.state.catalogList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='400px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const catalogSearch = [
	{ Header: 'Catalog Number', accessor: 'CATALOG_NUMBER', type: 'dropdown', row: 0, col: 0,
		options: { value: 'STOCK_NO', label: 'STOCK_NO', entity: 'STORES.CAT_NUM_AUTOSUGGEST_VW', order: 'STOCK_NO', distinct: true,
			search: { Attribute: 'CAT_NUM_UCASE', Operator: 'like', StringAccessor: 'CATALOG_NUMBER_LIKE' }
	}},
	{ Header: 'Vendor', accessor: 'VENDOR_NAME', type: 'dropdown', row: 0, col: 1,
		options: { value: 'VENDORNAME', label: 'VENDORNAME', entity: 'STORES.VENDOR_NAME_AUTOSUGGEST_VW', order: 'VENDORNAME', distinct: true,
			search: { Attribute: 'VENDORNAME_UCASE', Operator: 'like', StringAccessor: 'VENDOR_NAME_LIKE' }
	}},
	{ Header: 'Stock Number', accessor: 'STOCK_NUMBER', row: 1, col: 0 },
	{ Header: 'Category', accessor: 'CATEGORY', type: 'list', row: 1, col: 1,
		options: { value: 'CATEGORY', label: 'CATEGORY', entity: 'STORES.INVENTORY2', distinct: true,
			search: { Attribute: 'RETIRE_FLAG', Operator: 'is null' }, order: 'CATEGORY'
	}},
	{ Header: 'Description', accessor: 'DESCRIPTION', row: 2, col: 0 },
	{ Header: 'Location', accessor: 'LOCATION', row: 2, col: 1 },
	{ Header: 'Last Modified From', accessor: 'LAST_MODIF_FROM', type: 'date', row: 3, col: 0 },
	{ Header: 'Last Modified To', accessor: 'LAST_MODIF_TO', type: 'date', row: 3, col: 1 }
];

const catalogList = {
	ajax: { 
		url: '/Stores/CatalogSearch',
		data: null,
		accessor: 'Data'
	},
	pageSize: 20,
	hold: true,
	columns: [	
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addItem()} />, accessor: 'id', width: 25, 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editItem(props.row.values.ITEM_ID)} /> },
		{ Header: '', accessor: 'ITEM_ID', show: false },
		{ Header: 'Stock Number', accessor: 'STOCK_NO', width: 100,
			Cell: props => <HashLink onClick={() => props.parent.viewItem(props.row.values.ITEM_ID)}>{props.value}</HashLink> },
		{ Header: 'Description', accessor: 'DESCRIPTION', width: 350 },
		{ Header: 'Location', accessor: 'LOCATION', width: 100 },
		{ Header: 'Qty', accessor: 'QTY', width: 70 },
		{ Header: 'Unit', accessor: 'UNIT_OF_MEASURE', width: 80 },
		{ Header: 'Price', accessor: 'UNIT_COST', width: 90 },
		{ Header: 'Category', accessor: 'CATEGORY', width: 80 },
		{ Header: 'Cat. Number', accessor: 'CAT_NUMBER', width: 200 },
		{ Header: 'Vendor', accessor: 'VENDORNAME', width: 350 }
	]
};

const exportColumns = [
	{ Header: 'Stock Number', accessor: 'STOCK_NO', width: 20 },
	{ Header: 'Description', accessor: 'DESCRIPTION', width: 70 },
	{ Header: 'Location', accessor: 'LOCATION', width: 20 },
	{ Header: 'Qty', accessor: 'QTY', width: 14 },
	{ Header: 'Unit', accessor: 'UNIT_OF_MEASURE', width: 16 },
	{ Header: 'Price', accessor: 'UNIT_COST', width: 18 },
	{ Header: 'Category', accessor: 'CATEGORY', width: 16 },
	{ Header: 'Cat. Number', accessor: 'CAT_NUMBER', width: 40 },
	{ Header: 'Vendor', accessor: 'VENDORNAME', width: 70 }
];

const exportAllColumns = [
	{ Header: 'Description', accessor: 'DESCRIPTION', width: 70 },
	{ Header: 'Stock Number', accessor: 'STOCK_NO', width: 20 },
	{ Header: 'Location', accessor: 'LOCATION', width: 20 },
	{ Header: 'Catalog Number', accessor: 'CAT_NUMBER', width: 40 },
	{ Header: 'Price Sold', accessor: 'STORE_PRICE', width: 18 },
	{ Header: 'Stocking UOM', accessor: 'STOCKING_UOM', width: 16 }
];

export default withRouter(Catalog);
