import { Col, Container, Row, InputGroup, Form } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';

class FellowshipDetail extends ChemComponent {	
	render() {
	  var semester = this.props.semester.toLowerCase();
	  var optionalDisabledProp = this.props.editable === false ? { disabled: true } : {};
	  return(
		<Container fluid>
		  <Row style={{ borderTop: '1px solid #eee', paddingTop: 5, marginBottom: -10 }}>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_year'}>
				<Form.Label column sm={5} style={{ textAlign: 'right' }}>{this.props.semester}</Form.Label>
				<Col sm={7} style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[4].YEAR')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[4].YEAR', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
			<Col xs={2} style={{ paddingTop: 8 }}>Original</Col>
			<Col xs={2} style={{ paddingTop: 8 }}>Revision&nbsp;1</Col>
			<Col xs={2} style={{ paddingTop: 8 }}>Revision&nbsp;2</Col>
			<Col xs={2} style={{ paddingTop: 8 }}>Revision&nbsp;3</Col>
			<Col xs={2} style={{ paddingTop: 8 }}>Revisions</Col>
		  </Row>
		  <Row style={{ borderTop: '1px solid #eee', marginBottom: 8 }}>
		    <Col xs={2} style={{ textAlign: 'right', paddingTop: 8 }}>Date</Col>
			<Col xs={2} style={{ paddingTop: 8 }}>{this.dateToString(this.getDate(this.getByAccessor(this.props.data, 'fellowships.' + semester + '[0].REV_DATE')))}</Col>
			<Col xs={2} style={{ paddingTop: 8 }}>{this.dateToString(this.getDate(this.getByAccessor(this.props.data, 'fellowships.' + semester + '[1].REV_DATE')))}</Col>
			<Col xs={2} style={{ paddingTop: 8 }}>{this.dateToString(this.getDate(this.getByAccessor(this.props.data, 'fellowships.' + semester + '[2].REV_DATE')))}</Col>
			<Col xs={2} style={{ paddingTop: 8 }}>{this.dateToString(this.getDate(this.getByAccessor(this.props.data, 'fellowships.' + semester + '[3].REV_DATE')))}</Col>
			<Col xs={2} style={{ paddingTop: 8 }}>Current</Col>
		  </Row>
		  <Row style={{ borderTop: '1px solid #eee', paddingTop: 5, marginBottom: -10 }}>
		    <Col xs={2} style={{ textAlign: 'right', paddingTop: 8 }}>Fees</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_original_fees'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[0].FEES')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[0].FEES', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_rev1_fees'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[1].FEES')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[1].FEES', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_rev2_fees'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[2].FEES')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[2].FEES', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_rev3_fees'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[3].FEES')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[3].FEES', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_current_fees'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[4].FEES')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[4].FEES', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
		  </Row>
		  <Row style={{ borderTop: '1px solid #eee', paddingTop: 5, marginBottom: -10 }}>
		    <Col xs={2} style={{ textAlign: 'right', paddingTop: 8 }}>Tuition</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_original_tuition'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[0].TUITION')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[0].TUITION', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_rev1_tuition'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[1].TUITION')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[1].TUITION', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_rev2_tuition'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[2].TUITION')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[2].TUITION', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_rev3_tuition'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[3].TUITION')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[3].TUITION', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_current_tuition'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[4].TUITION')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[4].TUITION', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
		  </Row>
		  <Row style={{ borderTop: '1px solid #eee', paddingTop: 5, marginBottom: -10 }}>
		    <Col xs={2} style={{ textAlign: 'right', paddingTop: 8 }}>Stipend</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_original_stipend'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[0].STIPEND')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[0].STIPEND', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_rev1_stipend'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[1].STIPEND')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[1].STIPEND', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_rev2_stipend'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[2].STIPEND')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[2].STIPEND', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_rev3_stipend'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[3].STIPEND')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[3].STIPEND', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_current_stipend'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[4].STIPEND')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[4].STIPEND', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
		  </Row>
		  <Row style={{ borderTop: '1px solid #eee', marginBottom: 8 }}>
		    <Col xs={2} style={{ textAlign: 'right', paddingTop: 8 }}>Total</Col>
			<Col xs={2} style={{ paddingTop: 8 }}>{
				+this.getByAccessor(this.props.data, 'fellowships.' + semester + '[0].FEES') +
				+this.getByAccessor(this.props.data, 'fellowships.' + semester + '[0].TUITION') +
				+this.getByAccessor(this.props.data, 'fellowships.' + semester + '[0].STIPEND')
			}</Col>
			<Col xs={2} style={{ paddingTop: 8 }}>{
				+this.getByAccessor(this.props.data, 'fellowships.' + semester + '[1].FEES') +
				+this.getByAccessor(this.props.data, 'fellowships.' + semester + '[1].TUITION') +
				+this.getByAccessor(this.props.data, 'fellowships.' + semester + '[1].STIPEND')
			}</Col>
			<Col xs={2} style={{ paddingTop: 8 }}>{
				+this.getByAccessor(this.props.data, 'fellowships.' + semester + '[2].FEES') +
				+this.getByAccessor(this.props.data, 'fellowships.' + semester + '[2].TUITION') +
				+this.getByAccessor(this.props.data, 'fellowships.' + semester + '[2].STIPEND')
			}</Col>
			<Col xs={2} style={{ paddingTop: 8 }}>{
				+this.getByAccessor(this.props.data, 'fellowships.' + semester + '[3].FEES') +
				+this.getByAccessor(this.props.data, 'fellowships.' + semester + '[3].TUITION') +
				+this.getByAccessor(this.props.data, 'fellowships.' + semester + '[3].STIPEND')
			}</Col>
			<Col xs={2} style={{ paddingTop: 8 }}>{
				+this.getByAccessor(this.props.data, 'fellowships.' + semester + '[4].FEES') +
				+this.getByAccessor(this.props.data, 'fellowships.' + semester + '[4].TUITION') +
				+this.getByAccessor(this.props.data, 'fellowships.' + semester + '[4].STIPEND')
			}</Col>
		  </Row>
		  <Row style={{ borderTop: '1px solid #eee', paddingTop: 5, marginBottom: -10 }}>
		    <Col xs={2} style={{ textAlign: 'right', paddingTop: 8 }}>Payments</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_original_payments'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[0].PAYMENTS')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[0].PAYMENTS', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_rev1_payments'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[1].PAYMENTS')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[1].PAYMENTS', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_rev2_payments'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[2].PAYMENTS')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[2].PAYMENTS', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_rev3_payments'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[3].PAYMENTS')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[3].PAYMENTS', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
			<Col xs={2}>
			  <Form.Group as={Row} controlId={semester + '_current_payments'}>
				<Col style={{ marginBottom: '14px' }}>
				  <InputGroup>
					<Form.Control {...optionalDisabledProp} type="text" value={this.getByAccessor(this.props.data, 'fellowships.' + semester + '[4].PAYMENTS')||''}
					  onChange={(event) => this.props.onChange('fellowships.' + semester + '[4].PAYMENTS', event.target.value)} />
				  </InputGroup>
				</Col>
			  </Form.Group>
			</Col>
		  </Row>
		</Container>
	  );
	}
}

export default FellowshipDetail