import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';

class Email extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			email: {
				to: '',
				cc: '',
				bcc: '',
				subject: '',
				body: ''
			},
			renderKey: 0
		};
	}
	
	onChange(accessor, value) {
		var email = this.copyObject(this.state.email);
		this.setByAccessor(email, accessor, value);
		this.mergeState({ email: email, renderKey: this.state.renderKey + 1 });
	}

	onSubmit(event) {
		var self = this;		
		
		var postData = this.copyObject(this.state.email);
		postData.__RequestVerificationToken = this.props.user.antiForgeryToken;

		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/Admin/EmailTest',
			overlay: true,
			data: postData
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(data.Message);
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {		
		return (<>
			<Container fluid>
			  <Row>
			    <Col md={12} lg={11} xl={10} style={{ paddingTop: '20px', paddingBottom: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Email Test</span>
			      <ChemEdit parent={this} columns={emailColumns} data={this.state.email} user={this.props.user} renderKey={this.state.renderKey}
					onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)} >
					<Button id="save" variant="warning" type="submit" className="float-end">Send</Button>
				  </ChemEdit>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const emailColumns = [
	{ Header: 'To', accessor: 'to' },
	{ Header: 'CC', accessor: 'cc' },
	{ Header: 'BCC', accessor: 'bcc' },
	{ Header: 'Subject', accessor: 'subject' },
	{ Header: 'Text', accessor: 'body', type: 'textarea' }
];

export default Email;