import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import QueryTable from '../QueryTable';

class Recharges extends ChemComponent {
  	constructor(props) {
		super(props);

		this.state = {
			totalsLoading: true,
			incomeLoading: true,
			renderKey: 1
		};
	}
	
	onTotalsResult(data) {
		this.mergeState({
			totalsLoading: false
		});
	}
	
	onIncomeResult(data) {
		this.mergeState({
			incomeLoading: false
		});
	}

	render() {

		var exportRechargesData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Recharges BFS journal export',
			columns: exportColumns,
			entity: 'RECHARGES.RECHARGES_EXPORT_VW',
			order: 'DESCR'
		});			

		var exportCostXferData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Labor Cost Transfer BFS journal export',
			columns: exportColumns,
			entity: 'RECHARGES.COSTXFER_EXPORT_VW',
			search: { Attribute: 'MONETARYAMOUNT', Operator: '!=', DecimalValue: '0' },
			order: 'DESCR'
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Recharges View Journal</span>
					<Container fluid>
					  <Row>
						<Col xs={1} style={{ marginTop: 6 }}>
							<form action={this.getConfig().host + '/Home/CSV'} method='POST' className='float-end'>
								{exportRechargesData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to CSV</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/filetype-csv.png'} style={{ paddingLeft: '20px' }} alt="Export to CSV" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={5} style={{ marginTop: 10 }}>
							Recharges BFS journal export
						</Col>
						<Col xs={1} style={{ marginTop: 6 }}>
							<form action={this.getConfig().host + '/Home/CSV'} method='POST' className='float-end'>
								{exportCostXferData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to CSV</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/filetype-csv.png'} style={{ paddingLeft: '20px' }} alt="Export to CSV" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={5} style={{ marginTop: 10 }}>
							Labor Cost Transfer BFS journal export
						</Col>
					  </Row>
					</Container>
				  </Col>
				</Row>
				<Row>
				  <Col style={{ marginBottom: '20px' }}>
				    <QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_RechargesTotals' table={totalsList} 
						onResult={(data) => this.onTotalsResult(data)} offsetHeight='300px' />
				  </Col>
				</Row>
				<Row>
				  <Col>
				    <QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_RechargesIncome' table={incomeList} 
						onResult={(data) => this.onIncomeResult(data)} offsetHeight='300px' />
				  </Col>
				</Row>
			</Container>
		</>);
	}
}

const totalsList = {
	query: { entity: 'RECHARGES.RECHARGES_TOTALS_VW', search: null, order: 'RCH_TYPE desc' },
	columns: [
		{ Header: props => <div>{props.parent.state.totalsLoading && <Spinner animation='border' as='div' size='sm' style={{ marginLeft: '4px', marginRight: '4px' }} />}<strong>Grand Totals</strong></div>,
		    accessor: 'id',
			columns: [
				{ Header: 'Type', accessor: 'RCH_TYPE', width: 150 },
				{ Header: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>Amount</div>, accessor: 'AMOUNT', width: 150, 
					Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>{props.value}</div> }
			]
		}
	]
};

const incomeList = {
	query: { entity: 'RECHARGES.RECHARGES_INCOME_VW', search: null, order: 'DESCR' },
	columns: [
		{ Header: props => <div>{props.parent.state.incomeLoading && <Spinner animation='border' as='div' size='sm' style={{ marginLeft: '4px', marginRight: '4px' }} />}<strong>Service Totals</strong></div>,
			accessor: 'id',
			columns: [
				{ Header: 'Desc', accessor: 'DESCR', width: 400 },
				{ Header: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>Current Income</div>, accessor: 'MONETARYAMOUNT', width: 150,
					Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>{props.value}</div> },
				{ Header: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>12 Month Average</div>, accessor: 'SERVICE_SUM', width: 150,
					Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>{props.value}</div> }
			]
		}
	]
};

const exportColumns = [
	{ Header: 'Line # (Required)', accessor: 'ROWNUM', width: 10 },
	{ Header: 'Unit (Required)', accessor: 'UNIT', width: 10 },
	{ Header: 'Ledger (Required)', accessor: 'LEDGER', width: 10 },
	{ Header: 'Account (Required)', accessor: 'ACCOUNT', width: 10 },
	{ Header: 'Fund Code (Required)', accessor: 'FUNDCODE', width: 10 },
	{ Header: 'Org (Deptid) (Required)', accessor: 'DEPT_ID', width: 10 },
	{ Header: 'Program (Optional)', accessor: 'PROGRAM', width: 10 },
	{ Header: 'Chartfield1 (Optional)', accessor: 'CHARTFIELD1', width: 10 },
	{ Header: 'Chartfield2 (Optional)', accessor: 'CHARTFIELD2', width: 10 },
	{ Header: 'Descr (Required)', accessor: 'DESCR', width: 45 },
	{ Header: 'Reference (Optional)', accessor: 'REFERENCE', width: 16 },
	{ Header: 'Open Item Key (Optional)', accessor: 'OPENITEMKEY', width: 10 },
	{ Header: 'Monetary Amount (Required)', accessor: 'MONETARYAMOUNT', width: 10, format: '#,##0.00' },
	{ Header: 'Chartfield3 (must be NULL)', accessor: 'CHARTFIELD3', width: 10 },
	{ Header: 'Product (must be NULL)', accessor: 'PRODUCT', width: 10 },
	{ Header: 'Stat Amt (must be ZEROs)', accessor: 'STAT_AMT', width: 10, format: '#,##0.00' },
	{ Header: 'Stat Code (Optional)', accessor: 'STAT_CODE', width: 10 }
];

export default withRouter(Recharges);