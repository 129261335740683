import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';


class RemoteLogout extends ChemComponent {
  	constructor(props) {
		super(props);
		
		this.state = {
			search: {
				UID: ''
			}
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}	
		
	onSubmitSearch(event) {		
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Home/RemoteLogout',
			data: { caluid: this.state.search.UID }
		}).done(function (data) {
			if (data.Success) {
				self.showAlert("Logout succeeded.");
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	render() {
		
		const search = [
			{ Header: 'UID', accessor: 'UID' }
 		];

		return (<>
			<Container fluid>
				<Row>
					<Col>
					  <Container>
					    <Row>
						  <Col xs={10} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
							<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Remote Logout</span>
					        <ChemEdit parent={this} columns={search} data={this.state.search} renderKey={this.state.renderKey} 
						      onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
							  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						        <Col xs={12}>
					              <Button id="update" variant="warning" type="submit" className="float-end">Logout</Button>
								</Col>
							  </Form.Group>
					        </ChemEdit>
					      </Col>
						</Row>
					  </Container>
					</Col>
				</Row>
			</Container>
		</>);
	}
}

export default RemoteLogout;
