import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { Link } from 'react-router-dom';

class Return extends ChemComponent {
	constructor(props) {
		super(props);

		var returns = {
			DATE_OUT_COLLEGE: this.dateTimeToMVC(new Date()),
			TAG_NUM_OUT: '',
			REC_NUM_OUT: ''
		};

		this.state = {
			returns: returns,
			returnListCount: null,
			renderKey: 0
		};
	}
	
	onChange(accessor, value) {
		var returns = this.copyObject(this.state.returns);
		this.setByAccessor(returns, accessor, value);
		this.mergeState({ returns: returns });
	}

	onSubmit(event) {
		var self = this;
		
		var validMsg = this.validateReturn(event, returnSearch, this.state.returns);
		if (this.isEmpty(validMsg)) {		
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/HeLAD/ReturnCylinders',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					returns: this.state.returns
				}
			}).done(function (data) {
				if (data.Success) {
					self.showConfirmation(() => {
						return (<>
							{data.Message} Return to the <Link to={'/HeLAD/Return'}>Cylinder Return Page</Link>.
						</>);
					});
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		} else {
			this.showAlert('Required Fields', validMsg);			
		}
		
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			returnListCount: data.ResultCount
		});
	}
	
	validateReturn(event, columns, data) {
		// validate required fields
		var msg = this.validate(columns, data);
		
		// require at least one cycle ID
		var cycleIDCount = 0;
		Object.keys(data).forEach(function(key) {
			if (key.length > 9 && key.substring(0, 9) === 'CYCLE_ID_') cycleIDCount++;
		});
		
		if (cycleIDCount === 0) msg = (this.isEmpty(msg) ? '' : (msg + '; ')) + 'Please select at least one cylinder to return.';
		return msg;
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Card Return',
			columns: exportColumns,
			entity: 'DEMURRAGE.CARD_ACCESS_VW',
			search: null,
			order: ['OWNER_NAME','MFR_CODE']
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Cylinders Ready To Be Returned To Vendor </span>
						<ChemEdit parent={this} columns={returnSearch} data={this.state.returns} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChange(accessor, value)} user={this.props.user} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.returnListCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.returnListCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmit(event)}>Return</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='HeLAD_Return' table={returnList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='190px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const returnSearch = [
	{ Header: 'Return Date', accessor: 'DATE_OUT_COLLEGE', type: 'date', row: 0, col: 0, required: true },
	{ Header: 'Return Tag Number', accessor: 'TAG_NUM_OUT', row: 0, col: 1, required: true },
	{ Header: 'Customer Number Out', accessor: 'REC_NUM_OUT', row: 0, col: 2, required: true }
];

const returnList = {
	query: { entity: 'DEMURRAGE.RETURN_CYL_VW', search: null, order: ['OWNER_NAME','MFR_CODE'] },
	pageSize: 20,
	columns: [
		{ Header: 'Owner', accessor: 'OWNER_NAME', width: 250 },
		{ Header: 'ID', accessor: 'CYL_ID', width: 80 },
		{ Header: 'Cylinder', accessor: 'MFR_CODE', width: 125 },
		{ Header: 'Gas', accessor: 'GAS_NAME', width: 250 },
		{ Header: "Ret'd to Cage", accessor: 'RETURN_DATE', width: 100 },
		{ Header: 'Include', accessor: 'CYCLE_ID', width: 70,
			Cell: props => <Form.Check type="checkbox"
				checked={props.parent.getByAccessor(props.parent.state.returns, 'CYCLE_ID_' + props.value)}
				onClick={(event) => props.parent.onChange('CYCLE_ID_' + props.value, event.target.checked)} /> }
	]
};

const exportColumns = [
	{ Header: 'Owner', accessor: 'OWNER_NAME', width: 50 },
	{ Header: 'ID', accessor: 'CYL_ID', width: 16 },
	{ Header: 'Cylinder', accessor: 'MFR_CODE', width: 25 },
	{ Header: 'Gas', accessor: 'GAS_NAME', width: 50 },
	{ Header: "Ret'd to Cage", accessor: 'RETURN_DATE', width: 20 }
];

export default withRouter(Return);
