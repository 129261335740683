import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import ChemTable from "../ChemTable";
import QueryTable from "../QueryTable";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trash } from 'react-bootstrap-icons';
import Tabs from '../Tabs';

class Room extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			room: {
				ROOM_ID: null,
				ROOM_NUMBER: '',
				ROOM_LEVEL: null,
				BUILDING_ID: null,
				ROOM_NAME: '',
				ROOM_FUNCTION: null,
				DEPARTMENT_ID: null,
				HOOD_FAN: null,
				HOOD_FAN_QTY: '',
				SQUARE_FOOTAGE: '',
				CONTACT_UNIT: null,
				EMERGENCY_PERSON1: null,
				EMERGENCY_PERSON2: null,
				ROOM_PHONE: '',
				DELETE_FLAG: null,
				CHANGE_INFO: {
					COMMENTS: '',
					CREATE_DATE: null,
					CREATE_BY: null,
					MODIF_DATE: null,
					MODIF_BY: null
				},
				CREATED: '',
				MODIFIED: ''
			},
			contact: {
				ROLE_ID: null
			},
			contacts: [],
			deletedContacts: [],
			occupants: this.copyOccupantTable(0),
			keys: this.copyKeysTable(0),
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadRoom();
	}
	
	copyOccupantTable(roomId)
	{
		var occupantsCopy = this.copyObject(occupants);
		occupantsCopy.columns[1].Cell = occupants.columns[1].Cell;
		occupantsCopy.query.search.LongValue = 0;
		return occupantsCopy;
	}

	copyKeysTable(roomId)
	{
		var keysCopy = this.copyObject(keys);
		keysCopy.query.search.LongValue = 0;
		return keysCopy;
	}

	loadRoom(event) {
		var self = this;
		
		if (this.props.params.roomId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'ROOM_INFO',
					search: { Attribute: 'ROOM_ID', Operator: '=', LongValue: this.props.params.roomId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length === 1) {
						var room = data.Data[0];
						
						// convert flags from oracle style
						room.DELETE_FLAG = room.DELETE_FLAG === 'Y';
						room.HOOD_FAN = room.HOOD_FAN === 'Y';
												
						// compose change info
						room.CREATED = self.composeChangeInfo(room.CHANGE_INFO.CREATE_BY, room.CHANGE_INFO.CREATE_DATE);
						room.MODIFIED = self.composeChangeInfo(room.CHANGE_INFO.MODIF_BY, room.CHANGE_INFO.MODIF_DATE);
						
						self.mergeState({
							room: room,
							occupants: { query: { search: { LongValue: room.ROOM_ID }}},
							keys: { query: { search: { LongValue: room.ROOM_ID }}},
							renderKey: self.state.renderKey + 1
						}, () => {
							self.loadContacts(event);
						});
					} else {
						self.showAlert('Room Error', data.Data.length + ' rooms found for ID ' + self.props.params.roomId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				room: {
					ROOM_ID: '(new)'
				}
			});
		}
	}
	
	loadContacts(event) {
		var self = this;
		
		// make copy of contact query
		var query = this.copyObject(contacts.query);
		
		// add room ID to search parameter
		query.search.LongValue = this.state.room.ROOM_ID;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Home/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: query.entity,
				search: query.search,
				order: query.order,
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				// assign id's so table will display correctly
				for (var i = 0; i < data.Data.length; i++) {
					data.Data[i].id = i;
				}
				self.mergeState({
					contacts: data.Data,
					renderKey: self.state.renderKey + 1
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	addContact(event) {
		var self = this;
		
		// look up contact again... there should be a way around this!
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Home/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'ROOM_CONTACT_SELECT_VW',
				search: { Attribute: 'ROLE_ID', Operator: '=', LongValue: this.state.contact.ROLE_ID },
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				if (data.Data.length === 1) {
					// find max ID in table
					var maxId = 0;
					for (var i = 0; i < self.state.contacts.length; i++) {
						if (self.state.contacts[i].id > maxId) {
							maxId = self.state.contacts[i].id;
						}
					}
					// assign new unique id to new row
					data.Data[0].id = maxId + 1;
					// add new row to contacts
					var newContacts = self.copyObject(self.state.contacts);
					newContacts.push(data.Data[0]);
					self.mergeState({ 
						contacts: newContacts,
						renderKey: self.state.renderKey + 1
					}, () => {
						if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
					});
				} else {
					self.showAlert('Contact Error', 'Expected 1 contact but found ' + data.Data.length);
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	deleteContact(id) {
		var newContacts = this.copyObject(this.state.contacts);
		var newDeletedContacts = [];
		for (var i = 0; i < newContacts.length; i++) {
			if (newContacts[i].id === id) {
				newDeletedContacts = newContacts.splice(i, 1);
				// if this contact doesn't have an ID
				if (!newDeletedContacts[0].CONTACT_ID) {
					// we can delete it here, because it was going to be inserted
					newDeletedContacts = [];
				} else {
					// must be deleted on the server since it already exists there
					newDeletedContacts[0].DELETE_FLAG = 'Y';
				}
				break;
			}
		}
		
		this.mergeState({
			contacts: newContacts,
			deletedContacts: this.copyArrays(this.state.deletedContacts, newDeletedContacts)
		});
	}
	
	onChange(accessor, value) {
		var room = this.copyObject(this.state.room);
		this.setByAccessor(room, accessor, value);
		this.mergeState({ room: room, renderKey: this.state.renderKey + 1 });
	}
	
	onChangeContact(accessor, value) {
		var contact = this.copyObject(this.state.contact);
		this.setByAccessor(contact, accessor, value);
		this.mergeState({ contact: contact, renderKey: this.state.renderKey + 1 });
	}
	
	deleteRoom(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Room', 'Delete Room ' + this.props.params.roomId + '?', (okClicked) => {
			if (okClicked) {
				// prepare room for deletion
				var room = self.copyObject(self.state.room);
				room.DELETE_FLAG = 'Y';
				room.HOOD_FAN = room.HOOD_FAN ? 'Y' : null;
				room.CHANGE_INFO.CREATE_DATE = self.dateTimeToMVC(self.getDate(room.CHANGE_INFO.CREATE_DATE));
				room.CHANGE_INFO.MODIF_DATE = self.dateTimeToMVC(new Date());
				room.CHANGE_INFO.MODIF_BY = self.props.user.name;
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'ROOM_INFO', data: room }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Room {self.props.params.roomId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
		
	onSubmit(event) {
		var roomValidation = this.validate(roomColumns, this.state.room);
		if (roomValidation) {
			this.showAlert("Required Fields", roomValidation);
		} else {
			var self = this;		
			var dtNow = this.dateTimeToMVC(new Date());
			var currentUser = this.props.user.name;
			
			// make copy of data
			var room = this.copyObject(this.state.room);
			
			// if this is a new room, set ID to sequence name
			if (!this.props.params.roomId) {
				room.ROOM_ID = 'room_id_seq.nextval';
				room.CHANGE_INFO.CREATE_DATE = dtNow;
				room.CHANGE_INFO.CREATE_BY = currentUser;
				room.CHANGE_INFO.MODIF_DATE = null;
				room.CHANGE_INFO.MODIF_BY = null;
			} else {
				// convert dates to MVC
				room.CHANGE_INFO.CREATE_DATE = this.dateTimeToMVC(this.getDate(room.CHANGE_INFO.CREATE_DATE));
				room.CHANGE_INFO.MODIF_DATE = dtNow;
				room.CHANGE_INFO.MODIF_BY = currentUser;
			}			

			// convert flags to oracle style
			room.HOOD_FAN = room.HOOD_FAN ? 'Y' : null;
			room.DELETE_FLAG = room.DELETE_FLAG ? 'Y' : null;
			
			// copy contacts
			var children = this.copyArrays(this.state.contacts, this.state.deletedContacts);
			
			// for each contact
			for (var i = 0; i < children.length; i++) {
				children[i].ROOM_ID = this.props.params.roomId ? room.ROOM_ID : null;
				if (!children[i].DELETE_FLAG) children[i].DELETE_FLAG = null;
				if (!children[i].CONTACT_ID) {
					children[i].CONTACT_ID = 'room_contact_id_seq.nextval';
					children[i].CREATE_DATE = dtNow;
					children[i].CREATE_BY = currentUser;
					children[i].MODIF_DATE = null;
					children[i].MODIF_BY = null;
				} else {
					children[i].CREATE_DATE = this.dateTimeToMVC(this.getDate(children[i].CREATE_DATE));
					children[i].MODIF_DATE = dtNow;
					children[i].MODIF_BY = currentUser;
				}
			}
			
			var url;
			if (this.props.params.roomId) {
				url = this.getConfig().host + '/Home/UpdateWithChildren';
			} else {
				url = this.getConfig().host + '/Home/InsertWithChildren';
				room.ROOM_ID = 'room_id_seq.nextval';
			}
			
			var data = [
				{
					entity: 'ROOM_INFO',
					data: room
				},
				{
					entity: 'ROOM_CONTACT',
					data: children
				}
			];
			
			this.ajax({
				type: 'POST',
				url: url,
				data: { __RequestVerificationToken: this.props.user.antiForgeryToken, data: data }
			}).done(function (data) {
				if (data.Success) {
					var roomId = self.props.params.roomId ? room.ROOM_ID : data.ID;
					var verb = self.props.params.roomId ? 'updated' : 'inserted';
					self.props.parent.showConfirmation(() => {
						return (<>
							Room <Link to={'/BuildingAccess/rooms/' + roomId}>{roomId}</Link> {verb}.
						</>); 
					});
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		}
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col xs={12} style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Room {this.state.room.ROOM_ID}</div>
				  <Tabs>
					<div label='General Info'>
				      <Container fluid>
					    <Row>
						  <Col>
						    <ChemEdit parent={this} columns={roomColumns} data={this.state.room} user={this.props.user} renderKey={this.state.renderKey} 
							   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
						    </ChemEdit>
						  </Col>
					    </Row>
					    <Row>
						  <Col>
						    <div style={{ fontSize: '18px', fontWeight: 'bold', paddingBottom: '10px' }}>Contacts</div>
						    <ChemEdit parent={this} columns={addContactColumns} data={this.state.contact} user={this.props.user} renderKey={this.state.renderKey} 
							  onChange={(accessor, value) => this.onChangeContact(accessor, value)} onSubmit={(event) => this.addContact(event)}>
							  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
								<Col xs={12}>
								  <Button id="add" variant="warning" type="submit" className="float-end">Add</Button>
								</Col>
							  </Form.Group>
						    </ChemEdit>
						    <ChemTable renderKey={this.state.renderKey} parent={this} data={this.state.contacts} columns={contacts.columns} name='BuildingAccess_Room_Contacts' />
						    <div style={{ paddingBottom: '10px' }} />
						  </Col>
					    </Row>
					    <Row>
						  <Col style={{ paddingBottom: '20px'}}>
						    <Button id="save" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmit(event)}>Save</Button>
						    { this.props.params.roomId && <Button id="delete" variant="warning" type="button" className="float-end"
							  style={{ marginRight: '20px' }} onClick={(event) => this.deleteRoom(event)}>Delete</Button> }
						  </Col>
					    </Row>
					  </Container>
					</div>
					<div label='Occupants'>
					  <Container fluid>
					    <Row>
						  <Col style={{ marginBottom: '20px' }}>
							<QueryTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_Room_Occupants' table={this.state.occupants} />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Keys'>
					  <Container fluid>
					    <Row>
						  <Col style={{ marginBottom: '20px' }}>
							<QueryTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_Room_Keys' table={this.state.keys} />
						  </Col>
						</Row>
					  </Container>
					</div>
				  </Tabs>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const roomColumns = [
	{ Header: 'Number', accessor: 'ROOM_NUMBER', required: true, row: 0, col: 0 },
	{ Header: 'Level', accessor: 'ROOM_LEVEL', type: 'select', required: true, row: 0, col: 1,
		options: { value: 'ROOM_LEVEL', label: 'ROOM_LEVEL', entity: 'ROOM_INFO', order: 'ROOM_LEVEL',
			search: { Attribute: 'ROOM_LEVEL', Operator: 'is not null' }}},
	{ Header: 'Building', accessor: 'BUILDING_ID', type: 'select', required: true, row: 0, col: 2,
		options: { value: 'BUILDING_ID', label: 'BUILDING_NAME', entity: 'BUILDING', order: 'BUILDING_NAME',
			search: { Operator: 'and', Children: [
				{ Attribute: 'CHEMISTRY_FLAG', Operator: '=', StringValue: 'Y' },
				{ Attribute: 'DELETE_FLAG', Operator: 'is null' }]}}},
	{ Header: 'Name', accessor: 'ROOM_NAME', row: 0, col: 3 },
	{ Header: 'Function', accessor: 'ROOM_FUNCTION', type: 'select', required: true, row: 1, col: 0,
		options: { value: 'FUNCTION_NAME', label: 'FUNCTION_NAME', entity: 'ROOM_FUNCTION', order: 'FUNCTION_NAME',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Department', accessor: 'DEPARTMENT_ID', type: 'select', required: true, row: 1, col: 1,
		options: { value: 'DEPARTMENT_ID', label: 'DEPT_NAME', entity: 'DEPARTMENT', order: 'DEPT_NAME' }},
	{ Header: 'Hood Fan', accessor: 'HOOD_FAN', type: 'checkbox', row: 1, col: 2 },
	{ Header: 'Hood Fan Qty', accessor: 'HOOD_FAN_QTY', row: 1, col: 3 },
	{ Header: 'Square Footage', accessor: 'SQUARE_FOOTAGE', required: true, row: 2, col: 0 },
	{ Header: 'Phone', accessor: 'ROOM_PHONE', row: 2, col: 1 },
	{ Header: 'Unit', accessor: 'CONTACT_UNIT', type: 'select', row: 2, col: 2,
		options: { value: 'UNIT_ID', label: 'UNIT_NAME', entity: 'ORG_UNIT', order: 'UNIT_NAME',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Emergency Call Level<br /><i>Unit</i>', accessor: 'UNIT_CALL_LEVEL', type: 'listoptions', editable: false, row: 2, col: 3,
		options: { value: 'CONTACT_PERSON', label: 'CONTACT_PERSON', entity: 'UNIT_CONTACT_VW', order: 'CONTACT_PERSON',
			search: { Attribute: 'UNIT_ID', Operator: '=', LongAccessor: 'CONTACT_UNIT' }}},
	{ Header: 'Comments', accessor: 'CHANGE_INFO.COMMENTS', row: 3, col: 0 },
	{ Header: 'Created', accessor: 'CREATED', editable: false, row: 3, col: 1 },
	{ Header: 'Modified', accessor: 'MODIFIED', editable: false, row: 3, col: 2 }
];

const addContactColumns = [
	{ Header: 'Name', accessor: 'ROLE_ID', type: 'select',
		options: { value: 'ROLE_ID', label: 'PERSON_NAME', entity: 'ROOM_CONTACT_SELECT_VW', order: 'PERSON_NAME' }}
];

const contacts = {
	query: {
		entity: 'ROOM_CONTACT_VW',
		search: { Attribute: 'ROOM_ID', Operator: '=' },
		order: 'PERSON_NAME'
	},
	columns: [
		{ Header: 'CONTACT_ID', accessor: 'CONTACT_ID', show: false }, 
		{ Header: 'ROOM_ID', accessor: 'ROOM_ID', show: false },
		{ Header: 'ROLE_ID', accessor: 'ROLE_ID', show: false },
		{ Header: '', accessor: 'id', Cell: props => <Trash style={{ cursor: 'pointer' }} onClick={() => props.parent.deleteContact(props.value)} />, width: 25 },
		{ Header: 'Name', accessor: 'PERSON_NAME' }
	]
};

const occupants = {
	query: {
		entity: 'ROOM_OCCUPANTS_VW',
		search: { Attribute: 'ROOM_ID', Operator: '=' },
		order: 'PERSON_NAME'
	},
	columns: [
		{ Header: '', accessor: 'PERSON_ID', show: false, width: 150 },
		{ Header: 'Occupant', accessor: 'PERSON_NAME', Cell: props => <Link to={'/BuildingAccess/People/' + props.row.values.PERSON_ID}>{props.value}</Link>, width: 150 },
		{ Header: 'Role', accessor: 'TYPE_NAME', width: 150 },
		{ Header: 'Phone Number', accessor: 'PHONE1', width: 150 },
		{ Header: 'Email', accessor: 'EMAIL', width: 150 }
	]
};

const keys = {
	query: {
		entity: 'ROOM_KEYS_DETAIL_VW',
		search: { Attribute: 'ROOM_ID', Operator: '=' },
		order: 'KEY_NUMBER'
	},
	columns: [
		{ Header: 'Room ID', accessor: 'ROOM_ID' },
		{ Header: 'Location', accessor: 'LOCATION' },
		{ Header: 'Key Number', accessor: 'KEY_NUMBER' },
		{ Header: 'Key Name', accessor: 'KEY_NAME' },
		{ Header: 'Key type', accessor: 'TYPE_NAME' },
		{ Header: 'Description', accessor: 'KEY_DESC' }
	]
};

export default withRouter(Room);
