import React from 'react';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import ChemComponent from '../ChemComponent';
import { withBottleDefaultRoute } from './withBottleDefaultRoute';
import ChemEdit from '../ChemEdit';
import ChemTable from '../ChemTable';
import Tabs from '../Tabs';

class Bottle extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			activeTab: props.params.activeTab || 'Chemical',
			editable: props.editable,
			inventory: {
				ITEM_ID: null
			},
			chem_checkout: [],
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadBottle();
	}

	componentDidUpdate(prevProps) {
		var newActiveTab = this.props.params.activeTab || 'Chemical';
		
		// this is necessary so browser back/forward buttons will work across tabs
		if (newActiveTab !== this.state.activeTab) {
			this.mergeState({
				activeTab: newActiveTab,
				renderKey: this.state.renderKey + 1
			});
		}
		// also reload the item if the item ID has changed
		if (this.props.params.itemId !== prevProps.params.itemId) {
			this.loadBottle();
		}
	}

	loadBottle(event) {
		var self = this;

		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Reuse/GetBottle',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				itemid: this.props.params.itemId
			}
		}).done(function (data) {
			if (data.Success) {
				var inventory = data.Data.inventory;
				
				// if this is a new record
				if (self.isEmpty(inventory.ITEM_ID)) inventory.ITEM_ID = '(new)';

				// compose create info
				inventory.CREATED = self.composeChangeInfo(inventory.CREATED_BY, inventory.CREATE_DATE);
				inventory.MODIFIED = self.composeChangeInfo(inventory.MODIF_BY, inventory.MODIF_DATE);
				
				// compose previous storage
				inventory.PREV_STORAGE = self.join(' / ', inventory.PREV_STORAGE_CODE,
					self.join(' ', inventory.PREV_STORAGE_NAME, inventory.PREV_ROOM_NUMBER));

				// convert from mvc to js
				self.mvc2js(inventory, locationColumns);
					
				self.mergeState({
					inventory: inventory,
					chem_checkout: data.Data.chem_checkout,
					renderKey: self.state.renderKey + 1
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
		
	onChange(accessor, value) {
		var inventory = this.copyObject(this.state.inventory);
		if (Array.isArray(accessor)) {
			for (var i = 0; i < accessor.length; i++) {
				this.setByAccessor(inventory, accessor[i], value[i]);
			}
		} else {
			this.setByAccessor(inventory, accessor, value);
		}
		this.mergeState({ inventory: inventory, renderKey: this.state.renderKey + 1 });
	}
		
	onSubmit(event) {
		var self = this;		
		var url = null;
		
		// make copy of data
		var inventory = this.copyObject(this.state.inventory);
		this.js2mvc(inventory, locationColumns);
		
		// date on bottle is actually stored as a string, so format it
		if (!this.isEmpty(inventory.DATE_ON_BOTTLE)) {
			inventory.DATE_ON_BOTTLE = this.getDate(inventory.DATE_ON_BOTTLE).toLocaleDateString('en-US');
		}
		
		// if this is a new inventory item
		if (!this.props.params.itemId) {
			// set ID to sequence name
			inventory.ITEM_ID = 'item_id_seq.nextval';
			url = '/Home/Insert';
		} else {
			url = '/Home/Update';
		}
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'REUSE.INVENTORY', data: inventory }
		}).done(function (data) {
			if (data.Success) {
				var itemId = self.props.params.itemId || data.ID;
				var verb = self.props.params.itemId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Inventory item <Link to={'/Reuse/Bottles/' + itemId}>{itemId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	onClickTabItem(tab) {
		this.props.navigate('/Reuse/Bottles/' + (this.props.params.itemId || 'new') + '/' + tab);
	}

	canClickTabItem(tab) {
		return true;
	}

	renderFormula(formula) {
		var self = this;
		if (this.isEmpty(formula)) return null;
		var elements = formula.match(/([A-Z][a-z]?)([0-9]*)/g);
		if (this.isEmpty(elements) || elements.length === 0) return null;
		return elements.map((element, key) => {
			var elementAndNumber = element.match(/^([A-Z][a-z]?)([0-9]*)$/);
			if (self.isEmpty(elementAndNumber[2])) {
				return (<React.Fragment key={key}>{elementAndNumber[1]}</React.Fragment>);
			} else {
				return (<React.Fragment key={key}>{elementAndNumber[1]}<sub>{elementAndNumber[2]}</sub></React.Fragment>);
			}
		});
	}
	
	renderFormulae(formulae) {
		if (this.isEmpty(formulae)) return '';
		var pmatch = formulae.match(/^\(((?:[a-zA-Z]+[0-9]*)+)\)n(?:\s●\s([a-zA-Z0-9]+)\s((?:[a-zA-Z]+[0-9]*)+))?(?:\s●\s([a-zA-Z0-9]+)\s((?:[a-zA-Z]+[0-9]*)+))?$/);
		var match = formulae.match(/^((?:[a-zA-Z]+[0-9]*)+)(?:\s●\s([a-zA-Z0-9]+)\s((?:[a-zA-Z]+[0-9]*)+))?(?:\s●\s([a-zA-Z0-9]+)\s((?:[a-zA-Z]+[0-9]*)+))?$/);
		if (pmatch) {
			return (<>({this.renderFormula(pmatch[1])})n{!this.isEmpty(pmatch[2]) && <> ● {pmatch[2]} {this.renderFormula(pmatch[3])}</>}{!this.isEmpty(pmatch[4]) && <> ● {pmatch[4]} {this.renderFormula(pmatch[5])}</>}</>);
		} else if (match) {
			return (<>{this.renderFormula(match[1])}{!this.isEmpty(match[2]) && <> ● {match[2]} {this.renderFormula(match[3])}</>}{!this.isEmpty(match[4]) && <> ● {match[4]} {this.renderFormula(match[5])}</>}</>);
		} else {
			return (<>{formulae}</>);
		}
	}
	
	renderChemicalName() {
		
		var safetyData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			chemid: this.state.inventory.CHEM_ID
		});
		
		var optionalMargin = {};
		if (!this.state.editable) optionalMargin.marginBottom = '10px';

		return(
		  <Container fluid>
		    <Row>
			  <Col xs={5} style={optionalMargin}>
				  <form action={this.getConfig().host + '/Reuse/SafetyPage'} method='POST' target='_blank' className="float-end">
					{safetyData.map((safetyFormField, index) => {
					  return (<input key={index} type='hidden' name={safetyFormField.name} value={safetyFormField.value} />);
					})}
					<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Safety Sheet</Popover.Header></Popover>}>
					  <input type='image' src={this.getConfig().host + '/Content/Icons/safety_page.gif'} alt="Safety Sheet" />
					</OverlayTrigger>
				  </form>
			  </Col>
			  <Col xs={7} style={{ alignSelf: 'center' }}>
			    {this.state.inventory.CHEM_NAME}
			  </Col>
			</Row>
		  </Container>
		);
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Inventory Item {this.state.inventory.ITEM_ID}&nbsp;&nbsp;
							{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
							  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
						  </div>
					  </Col>
					</Row>
					<Row>
					  <Col style={{ paddingBottom: '10px' }}>
					    <Tabs activeTab={this.state.activeTab} canClickTabItem={(tab) => this.canClickTabItem(tab)} onClickTabItem={(tab) => this.onClickTabItem(tab)}>
						  <div label='Chemical'>
							<ChemEdit parent={this} columns={chemicalColumns} data={this.state.inventory} user={this.props.user} renderKey={this.state.renderKey} 
							   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)} editable={this.state.editable} />
						  </div>
						  <div label='Location'>
							<ChemEdit parent={this} columns={locationColumns} data={this.state.inventory} user={this.props.user} renderKey={this.state.renderKey} 
							   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)} editable={this.state.editable} />
						  </div>
						  <div label='Details'>
						  </div>
						  <div label='History'>
							<ChemTable renderKey={this.state.renderKey} parent={this} name='Reuse_Bottle_History' columns={historyColumns} data={this.state.chem_checkout}
								offsetHeight='85px' />
						  </div>
						</Tabs>
					  </Col>
					</Row>
					<Form.Group as='Row'>
					  {this.state.editable && <Button id="save" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmit(event)}>Save</Button>}
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const chemicalColumns = [
	{ Header: 'Chem ID', accessor: 'CHEM_ID', row: 0, col: 0, editable: false },
	{ Header: 'Change Chemical', accessor: 'CHEM_ID', labelAccessor: ['CHEM_NAME','CAS_NUMBER','FORMULA'], type: 'multicolumn', compare: 'indexOf', row: 0, col: 1,
		options: { value: 'CHEM_ID', label: ['CHEM_NAME','CAS_NUMBER','FORMULA'], entity: 'REUSE.CHEMICAL_SELECT_VW', order: 'CHEM_NAME' }},
	{ Header: 'Bottle ID', accessor: 'BOTTLE_ID', row: 0, col: 2 },
	{ Editor: props => props.parent.renderChemicalName(), row: 1, col: 0 },
	{ Header: 'CAS Number', accessor: 'CAS_NUMBER', row: 1, col: 1, editable: false },
	{ Header: 'Formula', accessor: 'FORMULA', row: 1, col: 2, editable: false,
	  Cell: props => props.parent.renderFormulae(props.value) },
	{ Header: 'Created', accessor: 'CREATED', row: 2, col: 0, editable: false },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 2, col: 1, editable: false }
];

const locationColumns = [
	{ Header: 'Status', accessor: 'STORAGE_STATUS', type: 'select', row: 0, col: 0,
		options: [{ value: 1, label: 'In ReUse' },{ value: 2, label: 'Checked Out' }]},
	{ Header: 'Date Shelved / Checked Out', accessor: 'DATE_SHELVED', type: 'date', row: 0, col: 1 },
	{ Header: 'Bottle Date', accessor: 'DATE_ON_BOTTLE', type: 'date', row: 0, col: 2 },
	{ Header: 'Previous Storage', accessor: 'PREV_STORAGE', row: 0, col: 3, editable: false },
	{ Header: 'Shelf Code', accessor: 'SHELF_CODE', row: 1, col: 0 },
	{ Header: 'Storage Code', accessor: 'STORAGE_CODE', row: 1, col: 1, editable: false },
	{ Header: 'Storage', accessor: 'STORAGE_ID', labelAccessor: ['STORAGE_NAME','STORAGE_CODE','ROOM_NUMBER'], type: 'multicolumn', row: 1, col: 2,
		options: { value: 'STORAGE_ID', label: ['STORAGE_NAME','STORAGE_CODE','ROOM_NUMBER'], entity: 'REUSE.STORAGE_SELECT_VW' }},
	{ Header: 'Room', accessor: 'ROOM_NUMBER', row: 1, col: 3, editable: false },
	/*{ Header: 'Checked Out By', accessor: */
];

const historyColumns = [
	{ Header: 'Checkout ID', accessor: 'CHECKOUT_ID' },
	{ Header: 'Date Out', accessor: 'DATE_OUT' },
	{ Header: 'Name', accessor: 'PERSON_NAME' },
	{ Header: 'Location', accessor: 'LOCATION' },
	{ Header: 'Phone', accessor: 'PHONE1' },
	{ Header: 'PI', accessor: 'PI_NAME' },
	{ Header: 'Returned', accessor: 'DATE_RETURNED' }
];

export default withBottleDefaultRoute(Bottle);
