import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class OrgUnit extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			orgUnit: {
				UNIT_ID: null,
				UNIT_NAME: '',
				COMMENTS: '',
				CREATE_DATE: null,
				CREATE_BY: '',
				MODIF_DATE: null,
				MODIF_BY: '',
				DELETE_FLAG: null,
				ORG_ID: null,
				UNIT_LOCATION: null,
				UNIT_PH: null,
				UNIT_EMAIL: null,
				DISPLAY_ORDER: null,
				ANCHOR_NAME: null,
				RCH_NAME: null,
				ORG_DEPT_ID: null,
				DISPLAY_NAME: null
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadOrgUnit();
	}

	loadOrgUnit(event) {
		var self = this;
		
		if (this.props.params.orgUnitId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'ORG_UNIT',
					search: { Attribute: 'UNIT_ID', Operator: '=', LongValue: this.props.params.orgUnitId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length === 1) {
						var orgUnit = data.Data[0];
																		
						self.mergeState({
							orgUnit: orgUnit,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Organizational Unit Error', data.Data.length + ' organizational units found for ID ' + self.props.params.orgUnitId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				orgUnit: {
					UNIT_ID: '(new)'
				}
			});
		}
	}
		
	onChange(accessor, value) {
		var orgUnit = this.copyObject(this.state.orgUnit);
		this.setByAccessor(orgUnit, accessor, value);
		this.mergeState({ orgUnit: orgUnit, renderKey: this.state.renderKey + 1 });
	}
		
	deleteOrgUnit(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Organizational Unit', 'Delete Organizational Unit ' + this.props.params.orgUnitId + '?', (okClicked) => {
			if (okClicked) {
				// prepare organizational unit for deletion
				var orgUnit = self.copyObject(self.state.orgUnit);
				orgUnit.DELETE_FLAG = 'Y';
				orgUnit.CREATE_DATE = self.dateTimeToMVC(self.getDate(orgUnit.CREATE_DATE));
				orgUnit.MODIF_DATE = self.dateTimeToMVC(new Date());
				orgUnit.MODIF_BY = self.props.user.name;
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'ORG_UNIT', data: orgUnit }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Organizational Unit {self.props.params.orgUnitId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
		
	onSubmit(event) {
		var self = this;		
		var dtNow = this.dateTimeToMVC(new Date());
		var currentUser = this.props.user.name;
		
		// make copy of data
		var orgUnit = this.copyObject(this.state.orgUnit);
		
		// if this is a new organizational unit, set ID to sequence name
		var url;
		if (this.props.params.orgUnitId) {
			url = this.getConfig().host + '/Home/Update';
			orgUnit.CREATE_DATE = this.dateTimeToMVC(this.getDate(orgUnit.CREATE_DATE));
			orgUnit.MODIF_DATE = dtNow;
			orgUnit.MODIF_BY = currentUser;
		} else {
			url = this.getConfig().host + '/Home/Insert';
			orgUnit.UNIT_ID = 'org_unit_seq.nextval';
			orgUnit.CREATE_DATE = dtNow;
			orgUnit.CREATE_BY = currentUser;
			orgUnit.MODIF_DATE = null;
			orgUnit.MODIF_BY = null;
			orgUnit.DELETE_FLAG = null;
		}			
		
		this.ajax({
			type: 'POST',
			url: url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'ORG_UNIT', data: orgUnit }
		}).done(function (data) {
			if (data.Success) {
				var orgUnitId = self.props.params.orgUnitId ? orgUnit.UNIT_ID : data.ID;
				var verb = self.props.params.orgUnitId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Organizational Unit <Link to={'/BuildingAccess/OrgUnits/' + orgUnitId}>{orgUnitId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Organizational Unit {this.state.orgUnit.UNIT_ID}</div>
				<ChemEdit parent={this} columns={orgUnitColumns} data={this.state.orgUnit} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					{this.props.params.orgUnitId && <Button id="delete" variant="warning" type="button" className="float-end"
					  style={{ marginRight: '20px' }} onClick={(event) => this.deleteOrgUnit(event)}>Delete</Button>} 
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const orgUnitColumns = [
	{ Header: 'Name', accessor: 'UNIT_NAME', required: true },
	{ Header: 'Department', accessor: 'ORG_DEPT_ID', type: 'select',
		options: { value: 'DEPARTMENT_ID', label: 'DEPT_NAME', entity: 'DEPARTMENT', order: [ 'DEPT_NAME' ] } },
	{ Header: 'Comments', accessor: 'COMMENTS' },
	{ Header: 'Last Modified', accessor: 'MODIF_BY', editable: false,
		Cell: props => {
			// if there is no modif date
			if (props.editor.props.data.MODIF_DATE === null)
			{
				// if there is no create date
				if (props.editor.props.data.CREATE_DATE === null)
				{
					// no info to return
					return null;
				} else {
					// use creation date
					return <>{props.parent.dateToString(props.parent.getDate(props.editor.props.data.CREATE_DATE)) + ' ' + props.editor.props.data.CREATE_BY}</>;
				}
			} else {
				return <>{props.parent.dateToString(props.parent.getDate(props.editor.props.data.MODIF_DATE)) + ' ' + props.value}</>;
			}
		}
	},
];

export default withRouter(OrgUnit);
