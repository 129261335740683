import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import ChemTable from "../ChemTable";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PencilSquare, Trash } from 'react-bootstrap-icons';

class KeyType extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			keyType: {
				TYPE_ID: null,
				TYPE_NAME: '',
				COMMENTS: '',
				CREATE_DATE: null,
				CREATE_BY: '',
				MODIF_DATE: null,
				MODIF_BY: '',
				DELETE_FLAG: null,
				CREATED: '',
				MODIFIED: ''
			},
			room: {
				KEY_TYPE: null,
				ROOM_ID: '',
				BUILDING_ID: '',
				COMMENTS: '',
				CREATE_BY: null,
				CREATE_DATE: null
			},
			rooms: [],
			deletedRooms: [],
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadKeyType();
	}

	loadKeyType(event) {
		var self = this;
		
		if (this.props.params.keyTypeId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'KEY_TYPE',
					search: { Attribute: 'TYPE_ID', Operator: '=', LongValue: this.props.params.keyTypeId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length === 1) {
						var keyType = data.Data[0];
						
						// convert flags from oracle style
						keyType.DELETE_FLAG = keyType.DELETE_FLAG === 'Y';
												
						// compose change info
						keyType.CREATED = self.composeChangeInfo(keyType.CREATE_BY, keyType.CREATE_DATE);
						keyType.MODIFIED = self.composeChangeInfo(keyType.MODIF_BY, keyType.MODIF_DATE);
						
						self.mergeState({
							keyType: keyType,
							renderKey: self.state.renderKey + 1
						}, () => {
							self.loadRooms(event);
						});
					} else {
						self.showAlert('Key Type Error', data.Data.length + ' key types found for ID ' + self.props.params.keyTypeId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				keyType: {
					TYPE_ID: '(new)'
				}
			});
		}
	}

	loadRooms(event) {
		var self = this;
		
		// make copy of room query
		var query = this.copyObject(rooms.query);
		
		// add key type ID to search parameter
		query.search.LongValue = this.state.keyType.TYPE_ID;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Home/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: query.entity,
				search: query.search,
				order: query.order,
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				// assign id's so table will display correctly
				for (var i = 0; i < data.Data.length; i++) {
					data.Data[i].id = i;
				}
				self.mergeState({
					rooms: data.Data,
					renderKey: self.state.renderKey + 1
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}	
		
	addRoom(event) {
		var self = this;
		
		// look up location/building name for display in grid
		if (this.state.room.ROOM_ID) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'ROOM_LOCATION',
					search: { Attribute: 'ROOM_ID', Operator: '=', LongValue: this.state.room.ROOM_ID },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length === 1) {
						self.addRoomWithLocation(data.Data[0].ROOM_ID, data.Data[0].BUILDING_ID, data.Data[0].LOCATION, event);
					} else {
						self.showAlert('Room Error', 'Expected 1 room but found ' + data.Data.length);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		} else {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'BUILDING',
					search: { Attribute: 'BUILDING_ID', Operator: '=', LongValue: this.state.room.BUILDING_ID },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length === 1) {
						self.addRoomWithLocation(null, data.Data[0].BUILDING_ID, data.Data[0].BUILDING_NAME, event);
					} else {
						self.showAlert('Building Error', 'Expected 1 building but found ' + data.Data.length);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		}
	}
	
	addRoomWithLocation(roomId, buildingId, newLocation, event)
	{
		// create new room
		var newRoom = this.copyObject(this.state.room);
		newRoom.ROOM_ID = roomId;
		newRoom.BUILDING_ID = buildingId;
		newRoom.LOCATION = newLocation;
		if (this.props.params.keyTypeId) newRoom.KEY_TYPE = this.props.params.keyTypeId;
		
		// find max ID in table
		var maxId = 0;
		for (var i = 0; i < this.state.rooms.length; i++) {
			if (this.state.rooms[i].id > maxId) {
				maxId = this.state.rooms[i].id;
			}
		}
		// assign new unique id to new row
		newRoom.id = maxId + 1;

		// add new room to rooms list
		var newRooms = this.copyObject(this.state.rooms);
		newRooms.push(newRoom);
		this.mergeState({
			room: {
				id: null,
				KEY_TYPE: null,
				ROOM_ID: '',
				BUILDING_ID: '',
				COMMENTS: '',
				CREATE_BY: null,
				CREATE_DATE: null
			},
			rooms: newRooms,
			renderKey: this.state.renderKey + 1
		}, () => {
			if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
		});
	}
	
	editRoom(id) {
		var roomToEdit = null;
		for (var i = 0; i < this.state.rooms.length; i++) {
			if (this.state.rooms[i].id === id) {
				roomToEdit = this.copyObject(this.state.rooms[i]);
				break;
			}
		}
		
		if (roomToEdit) {
			if (roomToEdit.ROOM_ID) roomToEdit.BUILDING_ID = null;
			this.mergeState({
				room: roomToEdit,
				renderKey: this.state.renderKey + 1
			});
		}
	}
	
	cancelRoomEdit(event) {
		this.mergeState({
			room: {
				id: null,
				KEY_TYPE: null,
				ROOM_ID: '',
				BUILDING_ID: '',
				COMMENTS: '',
				CREATE_BY: null,
				CREATE_DATE: null
			}
		});			
	}
	
	updateRoom(event) {
		var self = this;
		
		// look up location/building name for display in grid
		if (this.state.room.ROOM_ID) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'ROOM_LOCATION',
					search: { Attribute: 'ROOM_ID', Operator: '=', LongValue: this.state.room.ROOM_ID },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length === 1) {
						self.updateRoomWithLocation(data.Data[0].ROOM_ID, data.Data[0].BUILDING_ID, data.Data[0].LOCATION, event);
					} else {
						self.showAlert('Room Error', 'Expected 1 room but found ' + data.Data.length);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		} else {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'BUILDING',
					search: { Attribute: 'BUILDING_ID', Operator: '=', LongValue: this.state.room.BUILDING_ID },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length === 1) {
						self.updateRoomWithLocation(null, data.Data[0].BUILDING_ID, data.Data[0].BUILDING_NAME, event);
					} else {
						self.showAlert('Building Error', 'Expected 1 building but found ' + data.Data.length);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		}
	}
	
	/* why isn't this updating? */
	updateRoomWithLocation(roomId, buildingId, location, event) {
		var newRooms = this.copyObject(this.state.rooms);
		
		for (var i = 0; i < newRooms.length; i++) {
			if (this.state.room.id === newRooms[i].id) {
				// if the primary key is changing and we haven't changed it before
				if ((newRooms[i].ROOM_ID !== roomId ||
					 newRooms[i].BUILDING_ID !== buildingId) &&
					!newRooms[i].OLD_PK) {
					// save the old primary key
					newRooms[i].OLD_PK = {
						KEY_TYPE: newRooms[i].KEY_TYPE,
						ROOM_ID: newRooms[i].ROOM_ID,
						BUILDING_ID: newRooms[i].BUILDING_ID
					};
				}
				newRooms[i].LOCATION = location;
				newRooms[i].ROOM_ID = roomId;
				newRooms[i].BUILDING_ID = buildingId;
				newRooms[i].COMMENTS = this.state.room.COMMENTS;
				break;
			}
		}
		
		this.mergeState({
			room: {
				id: null,
				KEY_TYPE: null,
				ROOM_ID: '',
				BUILDING_ID: '',
				COMMENTS: '',
				CREATE_BY: null,
				CREATE_DATE: null
			},
			rooms: newRooms
		});
	}
	
	deleteRoom(id) {
		var newRooms = this.copyObject(this.state.rooms);
		var deletedRoom = null;
		for (var i = 0; i < newRooms.length; i++) {
			if (newRooms[i].id === id) {
				deletedRoom = newRooms.splice(i, 1);
				deletedRoom[0].DELETE_FLAG = 'Y';
				break;
			}
		}
		
		// if the deleted room exists in the database, it must be deleted 
		var newDeletedRooms = (deletedRoom && deletedRoom[0].CREATE_DATE) ? this.copyArrays(this.state.deletedRooms, deletedRoom) : this.copyObject(this.state.deletedRooms);
		this.mergeState({
			rooms: newRooms,
			deletedRooms: newDeletedRooms,
			renderKey: this.state.renderKey + 1
		});
	}

	onChange(accessor, value) {
		var keyType = this.copyObject(this.state.keyType);
		this.setByAccessor(keyType, accessor, value);
		this.mergeState({ keyType: keyType, renderKey: this.state.renderKey + 1 });
	}
		
	onChangeRoom(accessor, value) {
		var room = this.copyObject(this.state.room);
		this.setByAccessor(room, accessor, value);
		
		// since room and building are either/or, each one clears the other
		if (accessor === 'ROOM_ID') {
			room.BUILDING_ID = '';
		} else if (accessor === 'BUILDING_ID') {
			room.ROOM_ID = '';
		}
		
		this.mergeState({ room: room, renderKey: this.state.renderKey + 1 });
	}

	deleteKeyType(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Key Type', 'Delete Key Type ' + this.props.params.keyTypeId + '?', (okClicked) => {
			if (okClicked) {
				// prepare key maker for deletion
				var keyType = self.copyObject(self.state.keyType);
				keyType.DELETE_FLAG = 'Y';
				keyType.CREATE_DATE = self.dateTimeToMVC(self.getDate(keyType.CREATE_DATE));
				keyType.MODIF_DATE = self.dateTimeToMVC(new Date());
				keyType.MODIF_BY = self.props.user.name;
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'KEY_TYPE', data: keyType }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Key Type {self.props.params.keyTypeId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
		
	onSubmit(event) {
		// validate key type
		var keyTypeValidation = this.validate(keyTypeColumns, this.state.keyType);
		if (keyTypeValidation) {
			this.showAlert("Required Fields", keyTypeValidation);
		} else {
			var self = this;		
			var dtNow = this.dateTimeToMVC(new Date());
			var currentUser = this.props.user.name;
			
			// make copy of data
			var keyType = this.copyObject(this.state.keyType);
			
			// if this is a new key type, set ID to sequence name
			var url;
			if (!this.props.params.keyTypeId) {
				url = this.getConfig().host + '/Home/InsertWithChildren';
				keyType.TYPE_ID = 'key_type_id_seq.nextval';
				keyType.CREATE_DATE = dtNow;
				keyType.CREATE_BY = currentUser;
				keyType.MODIF_DATE = null;
				keyType.MODIF_BY = null;
			} else {
				url = this.getConfig().host + '/Home/UpdateWithChildren';
				keyType.CREATE_DATE = this.dateTimeToMVC(this.getDate(keyType.CREATE_DATE));
				keyType.MODIF_DATE = dtNow;
				keyType.MODIF_BY = currentUser;
			}			

			// convert flags to oracle style
			keyType.DELETE_FLAG = keyType.DELETE_FLAG ? 'Y' : null;
			
			// copy rooms
			var children = this.copyArrays(this.state.deletedRooms, this.state.rooms);
			
			// for each room
			for (var i = 0; i < children.length; i++) {
				children[i].KEY_TYPE = this.props.params.keyTypeId ? keyType.TYPE_ID : null;
				if (!children[i].DELETE_FLAG) children[i].DELETE_FLAG = null;
				if (!children[i].CREATE_DATE) {
					children[i].CHANGE_INFO = {
						COMMENTS: children[i].COMMENTS,
						CREATE_DATE: dtNow,
						CREATE_BY: currentUser,
						MODIF_DATE: null,
						MODIF_BY: null,
					};
				} else {
					children[i].CHANGE_INFO = {
						COMMENTS: children[i].COMMENTS,
						CREATE_DATE: this.dateTimeToMVC(this.getDate(children[i].CREATE_DATE)),
						CREATE_BY: children[i].CREATE_BY,
						MODIF_DATE: dtNow,
						MODIF_BY: currentUser
					};
				}
			}

			var data = [
				{
					entity: 'KEY_TYPE',
					data: keyType
				},
				{
					entity: 'ROOM_KEY',
					pk: ['KEY_TYPE', 'ROOM_ID', 'BUILDING_ID'],
					old_pk: 'OLD_PK',
					data: children
				}
			];

			this.ajax({
				type: 'POST',
				url: url,
				data: { __RequestVerificationToken: this.props.user.antiForgeryToken, data: data }
			}).done(function (data) {
				if (data.Success) {
					var keyTypeId = self.props.params.keyTypeId ? keyType.TYPE_ID : data.ID;
					var verb = self.props.params.keyTypeId ? 'updated' : 'inserted';
					self.props.parent.showConfirmation(() => {
						return (<>
							Key Type <Link to={'/BuildingAccess/KeyTypes/' + keyTypeId}>{keyTypeId}</Link> {verb}.
						</>);
					});
				} else {
					self.showAlert('Server Error', data.Message);
				}
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		}
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Key Type {this.state.keyType.TYPE_ID}</div>
				<Container fluid>
				  <Row>
				    <Col>
					  <ChemEdit parent={this} columns={keyTypeColumns} data={this.state.keyType} user={this.props.user} renderKey={this.state.renderKey} 
						onChange={(accessor, value) => this.onChange(accessor, value)} />
					</Col>
				  </Row>
				  <Row>
				    <Col>
					  <div style={{ fontSize: '18px', fontWeight: 'bold', paddingBottom: '10px' }}>Rooms</div>
						<ChemEdit parent={this} columns={addRoomColumns} data={this.state.room} user={this.props.user} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChangeRoom(accessor, value)} onSubmit={(event) => this.addRoom(event)}>
						  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
							<Col xs={12}>
							  {this.state.room.id ?
								<>
								  <Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.updateRoom(event)}>Update</Button>
								  <Button id="cancel" variant="warning" type="button" className="float-end" style={{marginRight: '20px'}} onClick={(event) => this.cancelRoomEdit(event)}>Cancel</Button>
								</> :
								<Button id="add" variant="warning" type="submit" className="float-end">Add</Button>
							  }
							</Col>
						  </Form.Group>
						</ChemEdit>
					  <ChemTable renderKey={this.state.renderKey} parent={this} data={this.state.rooms} columns={rooms.columns} name='BuildingAccess_KeyType_Rooms' />
					</Col>
				  </Row>
				  <Row>
				    <Col style={{ paddingTop: '10px'}}>
					  <Button id="save" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmit(event)}>Save</Button>
					  { this.props.params.keyTypeId && <Button id="delete" variant="warning" type="button" className="float-end"
						style={{ marginRight: '20px' }} onClick={(event) => this.deleteKeyType(event)}>Delete</Button> } 
					</Col>
				  </Row>
				</Container>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const keyTypeColumns = [
	{ Header: 'Name', accessor: 'TYPE_NAME', required: true, row: 0, col: 0 },
	{ Header: 'Comments', accessor: 'COMMENTS', row: 0, col: 1 }
];

const addRoomColumns = [
	{ Header: 'Select Room', accessor: 'ROOM_ID', type: 'select',
		options: { value: 'ROOM_ID', label: 'LOCATION', entity: 'ROOMS_VW', order: 'LOCATION' }},
	{ Header: 'OR Select Building:', accessor: 'BUILDING_ID', type: 'select',
		options: { value: 'BUILDING_ID', label: 'BUILDING_NAME', entity: 'BUILDING', order: 'BUILDING_NAME' }},
	{ Header: 'Comments', accessor: 'COMMENTS' }
];

const rooms = {
	query: {
		entity: 'KEY_TYPE_ROOM_VW',
		search: { Attribute: 'KEY_TYPE', Operator: '=' },
		order: 'LOCATION'
	},
	columns: [
		{ Header: '', accessor: 'trash', Cell: props => <Trash style={{ cursor: 'pointer' }} onClick={() => props.parent.deleteRoom(props.row.values.id)} />, width: 25 },
		{ Header: '', accessor: 'id', Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editRoom(props.value)} />, width: 25 },
		{ Header: 'Location', accessor: 'LOCATION' }, 
		{ Header: 'Comments', accessor: 'COMMENTS' },
		{ Header: 'Modified', accessor: 'MODIFIED' },
		{ Header: 'ROOM_ID', accessor: 'ROOM_ID', show: false },
		{ Header: 'BUILDING_ID', accessor: 'BUILDING_ID', show: false },
		{ Header: 'CREATE_BY', accessor: 'CREATE_BY', show: false },
		{ Header: 'CREATE_DATE', accessor: 'CREATE_DATE', show: false }
	]
};

export default withRouter(KeyType);
