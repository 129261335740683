import { Fragment } from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class Rate extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			editable: props.editable,
			rate: {
				RENTAL_RATE_ID: ''
			},
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadRate();
	}

	loadRate(event) {
		var self = this;
		
		if (this.props.params.rateId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/HeLAD/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					context: 'DEMURRAGE',
					entity: 'DEM_RENTAL_RATES',
					search: { Attribute: 'RENTAL_RATE_ID', Operator: '=', LongValue: this.props.params.rateId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var rate = data.Data[0];
						self.mvc2js(rate, rateColumns);
							
						self.mergeState({
							rate: rate,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Rate Error', data.Data.length + ' Rates found for ID ' + self.props.params.rateId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				rate: {
					RENTAL_RATE_ID: '(new)',
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var rate = this.copyObject(this.state.rate);
		this.setByAccessor(rate, accessor, value);
		this.mergeState({ rate: rate, renderKey: this.state.renderKey + 1 });
	}
		
	onSubmit(event) {
		var self = this;
		var url;
		
		// make copy of data
		var rate = this.copyObject(this.state.rate);
		this.js2mvc(rate, rateColumns);
		
		// if this is a new rate
		if (!this.props.params.rateId) {
			// set ID to sequence name
			rate.RENTAL_RATE_ID = 'dem_rate_seq.nextval';
			url = '/Home/Insert';
		} else {
			url = '/Home/Update';
		}
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + url,
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'DEMURRAGE.DEM_RENTAL_RATES', data: rate }
		}).done(function (data) {
			if (data.Success) {
				var rateId = self.props.params.rateId || data.ID;
				var verb = self.props.params.rateId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Rental Rate <Link to={'/HeLAD/Rates/' + rateId}>{rateId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Rental Rate {this.state.rate.RENTAL_RATE_ID}&nbsp;&nbsp;
							{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
							  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
						  </div>
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={rateColumns} data={this.state.rate} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} editable={this.state.editable} />
					  </Col>
					</Row>
					<Row style={{ paddingTop: 5 }}>
					  <Col>
						{this.state.editable && <Button id="save" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmit(event)}>Save</Button>}
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const rateColumns = [
	{ Header: 'Description', accessor: 'RENTAL_DESC', row: 0, col: 0 },
	{ Header: 'Rate', accessor: 'RENTAL_RATE', row: 0, col: 1 },
	{ Header: 'Deleted', accessor: 'DELETE_FLAG', type: 'checkbox', row: 1, col: 0	},		
	{ Header: 'Modified By', accessor: 'MODIF_BY', row: 2, col: 0, editable: false },
	{ Header: 'Modified Date', accessor: 'MODIF_DATE', row: 2, col: 1, editable: false }
];

export default withRouter(Rate);
