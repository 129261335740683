import { Fragment } from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import { Col, Container, Row, Button } from 'react-bootstrap';

class Settings extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			settings: this.copyObject(defaultSettings),
			columns: this.copyObject(defaultColumns),
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadSettings();
	}

	loadSettings() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Stores/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'SETTINGS',
				order: 'SETTING_NAME',
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				var columns;
				var settings;
				var accessor;
				
				if (data.Data.length > 0) {
					columns = [];
					settings = {};
					for (var i = 0; i < data.Data.length; i++) {
						accessor = 'SETTING_' + i;
						columns.push({
							Header: data.Data[i].SETTING_NAME,
							accessor: accessor
						});
						settings[accessor] = data.Data[i].SETTING_VALUE;
					}		
				} else {
					columns = self.copyObject(defaultColumns);
					settings = self.copyObject(defaultSettings);
				}
				
				self.mergeState({
					settings: settings,
					columns: columns,
					renderKey: self.state.renderKey + 1
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
		
	onChange(accessor, value) {
		var settings = this.copyObject(this.state.settings);
		this.setByAccessor(settings, accessor, value);
		this.mergeState({ settings: settings, renderKey: this.state.renderKey + 1 });
	}
		
	onSubmit(event) {
		var self = this;
		
		var settings = [];
		// create rows as they will look in the settings table
		Object.keys(this.state.settings).forEach(function(key) {
			// find the key in the columns array
			for (var c = 0; c < self.state.columns.length; c++) {
				if (key === self.state.columns[c].accessor) {
					settings.push({ SETTING_NAME: self.state.columns[c].Header, SETTING_VALUE: self.state.settings[key] });
					break;
				}
			}
		});
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/Home/InsertOrUpdate',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'STORES.SETTINGS', keys: ['SETTING_NAME'], data: settings }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Settings were updated.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Settings</div>
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={this.state.columns} data={this.state.settings} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} />
					  </Col>
					</Row>
					<Row style={{ paddingTop: 5 }}>
					  <Col>
						<Button id="save" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmit(event)}>Save</Button>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const defaultColumns = [
	{ Header: 'Item Barcode Scale', accessor: 'SETTING_0' },
	{ Header: 'Item Barcode Width', accessor: 'SETTING_1' },
	{ Header: 'Print Advisory', accessor: 'SETTING_2' }
];

const defaultSettings = {
	SETTING_0: '0.9',
	SETTING_1: '3',
	SETTING_2: ''
};

export default withRouter(Settings);
