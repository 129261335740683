import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';

class Task extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			task: {
				TASK_ID: props.params.taskId,
				ASSIGN_DATE: this.dateToString(new Date())
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadTask();
	}

	loadTask(event) {
		var self = this;
		if (this.state.task.TASK_ID !== 'new') {
			this.search({
				query: {
					entity: 'TASK',
					search: { Attribute: 'TASK_ID', Operator: '=', LongValue: this.state.task.TASK_ID }
				}
			}, (data) => {
				if (data.Data.length === 1) {					
					self.mergeState({
						task: data.Data[0],
						renderKey: self.state.renderKey + 1
					});
				} else {
					self.showAlert('Found ' + data.Data.length + ' tasks for id ' + this.state.task.TASK_ID + '; expected 1');
				}
			});
		}
	}
		
	onChange(accessor, value) {
		var task = this.copyObject(this.state.task);
		this.setByAccessor(task, accessor, value);
		this.mergeState({ task: task });
	}

	onSubmit(event) {
		var self = this;
		var task = this.copyObject(this.state.task);
		var url = this.getConfig().host + (task.TASK_ID === 'new' ? '/Admin/InsertTask' : '/Admin/UpdateTask');
		
		// convert dates
		task.ASSIGN_DATE = this.dateTimeToMVC(this.getDate(task.ASSIGN_DATE));
		task.COMPLETE_DATE = this.dateTimeToMVC(this.getDate(task.COMPLETE_DATE));
		
		this.ajax({
			type: 'POST',
			url: url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, task: this.state.task }
		}).done(function (data) {
			if (data.Success) {
				self.props.navigate('/WebAdmin/Tasks');
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col md={12} lg={11} xl={10} style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Task {this.state.task.TASK_ID}</span>
			      <ChemEdit parent={this} columns={taskColumns} data={this.state.task} user={this.props.user} renderKey={this.state.renderKey}
					onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
				    <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={12}>
					      <Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
						</Col>
					</Form.Group>
				  </ChemEdit>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const taskColumns = [
	{ Header: 'Name', accessor: 'NAME' },
	{ Header: 'Description', accessor: 'DESCRIPTION', type: 'textarea' },
	{ Header: 'Application', accessor: 'APPLICATION_ID', type: 'select',
		options: { value: 'APPLICATION_ID', label: 'APPLICATION_NAME', entity: 'APPLICATION',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }, order: 'APPLICATION_NAME' } },		
	{ Header: 'Created', accessor: 'ASSIGN_DATE', editable: false,
		Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
	{ Header: 'Requested By', accessor: 'REQUEST_BY' },
	{ Header: 'Assigned To', accessor: 'ASSIGN_TO' },
	{ Header: 'Completed', accessor: 'COMPLETE_DATE', type: 'date' }
];

export default withRouter(Task);