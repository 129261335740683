import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import DatePicker from 'react-datepicker';
import HashLink from '../HashLink';
import { CaretUpFill, CaretDownFill, CheckCircle, XCircle } from 'react-bootstrap-icons';

class HeMeterReadings extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			entryDate: new Date(),
			readings: [],
			order: 'READ_ORDER',
			renderKey: 0
		};
	}
	
	componentDidMount() {
		this.loadMeterReadings();
	}
	
	loadMeterReadings() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/HeLAD/Search',
			overlay: true,
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'HE_METER_READINGS_VW',
				order: this.state.order,
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				self.mergeState({
					readings: data.Data,
					renderKey: self.state.renderKey + 1
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	findReading(readings, readingId) {
		for (var i = 0; i < readings.length; i++) {
			if (readings[i].READING_ID === readingId) {
				return i;
			}
		}
		
		return -1;
	}
	
	onChangeReading(readingId, accessor, value) {
		var readings = this.copyObject(this.state.readings);
		var readingIdx = this.findReading(readings, readingId);
		if (readingIdx >= 0) {
			readings[readingIdx][accessor] = value;
			readings[readingIdx].STATE = 'EDITING';
			readings[readingIdx].AMOUNT_DIFF = this.isEmpty(readings[readingIdx].NEW_AMOUNT) ? '' : (+readings[readingIdx].NEW_AMOUNT - readings[readingIdx].PAST_AMOUNT);
		}
		this.mergeState({ readings: readings, renderKey: this.state.renderKey + 1 });
	}

	onKeyDown(event, readingId) {
		var readings = null;
		var readingIdx = -1;
		
		// allow numbers, backspace, delete, left/right
		if (this.isNumeric(event.keyCode) && (event.keyCode < 48 || event.keyCode > 57) && ![8,37,39,46].includes(event.keyCode)) {
			event.preventDefault();
			// tab, return, down arrow - move forward
			if ([9,13,40].includes(event.keyCode)) {
				readings = this.copyObject(this.state.readings);
				readingIdx = this.findReading(readings, readingId);
				if (readingIdx >= 0) {
					readings[readingIdx].STATE = 'SAVING';
					this.mergeState({ readings: readings, renderKey: this.state.renderKey + 1 }, () => {
						this.updateMeterReading(readingId, true);
					});
				}
			// up arrow - move back
			} else if ([38].includes(event.keyCode)) {
				readings = this.copyObject(this.state.readings);
				readingIdx = this.findReading(readings, readingId);
				if (readingIdx >= 0) {
					readings[readingIdx].STATE = 'SAVING';
					this.mergeState({ readings: readings, renderKey: this.state.renderKey + 1 }, () => {
						this.updateMeterReading(readingId, false);
					});
				}
			}
			event.cancel = true;
		}
	}
	
	updateMeterReading(readingId, moveForward) {
		var self = this;
		var readingIdx = this.findReading(this.state.readings, readingId);
		var reading = this.state.readings[readingIdx];
		var readings;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/HeLAD/UpdateMeterReading',
			overlay: true,
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				readingid: reading.READING_ID,
				meterid: reading.METER_ID,
				readingdate: this.dateTimeToMVC(this.state.entryDate),
				pastamount: reading.PAST_AMOUNT,
				newamount: reading.NEW_AMOUNT
			}
		}).done(function (data) {
			readings = self.copyObject(self.state.readings);
			readings[readingIdx].STATE = data.Success ? 'SAVED' : 'ERROR';
			self.mergeState({
				readings: readings,
				renderKey: self.state.renderKey + 1
			}, () => {
				if (data.Success) {
					var currentlyFocused = document.getElementById('NEW_AMOUNT_' + readingId);
					if (currentlyFocused) {
						var trFocused = currentlyFocused.closest('tr');
						if (moveForward) {
							if (trFocused && trFocused.nextElementSibling) {
								var nextFocused = trFocused.nextElementSibling.querySelector('.newAmountFocusable');
								if (nextFocused) nextFocused.focus();
							}
						} else {
							if (trFocused && trFocused.previousElementSibling) {
								var previousFocused = trFocused.previousElementSibling.querySelector('.newAmountFocusable');
								if (previousFocused) previousFocused.focus();
							}
						}
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}
			});				
		}).fail(function (jqXHR, textStatus, errorThrown) {
			readings = self.copyObject(self.state.readings);
			readings[readingIdx].STATE = 'ERROR';
			self.mergeState({
				readings: readings,
				renderKey: self.state.renderKey + 1
			}, () => {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		});		
	}
	
	onMarkReadyToBill(event) {
		var self = this;
		
		this.showOKCancel('Confirm Ready to Bill', 'Mark all transactions ready to bill?', okClicked => {
			if (okClicked) {
				self.ajax({
					type: 'post',
					url: self.getConfig().host + '/HeLAD/MarkHeReadyToBill',
					overlay: true,
					data: { 
						__RequestVerificationToken: self.props.user.antiForgeryToken,
						entrydate: self.dateTimeToMVC(self.state.entryDate)
					}
				}).done(function (data) {
					self.showAlert(data.Success ? 'Success' : 'Server Error', data.Message, () => {
						if (data.Success) self.loadMeterReadings();
					});
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
	
	onClickHeader(colname) {
		var self = this;
		var order = this.state.order === colname ? (colname + ' desc') : colname;
		this.mergeState({ order: order }, () => {
			self.loadMeterReadings();
		});
	}
	
	renderHeader(label, colname) {
		var asc = this.state.order === colname;
		var caret = asc || this.state.order === (colname + ' desc');
		
		return(<HashLink onClick={() => this.onClickHeader(colname)}><table><tr><td>{caret && (asc ? <CaretUpFill /> : <CaretDownFill />)}</td><td>{label}</td></tr></table></HashLink>);
	}
	
	renderReadingState(readingState) {
		if (readingState === 'SAVING') {
			return (
				<td style={{ paddingLeft: 20, paddingRight: 20, border: '1px solid #ccc' }}>
					<Spinner animation='border' as='div' size='sm' />
				</td>
			);
		} else if (readingState === 'SAVED') {
			return (
				<td style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 6, border: '1px solid #ccc' }}>			
					<CheckCircle color='green' />
				</td>
			);
		} else if (readingState === 'ERROR') {
			return (
				<td style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 6, border: '1px solid #ccc' }}>			
					<XCircle color='red' />
				</td>
			);
		} else {
			return (<td style={{ border: '1px solid #ccc' }}></td>);
		}
	}

	render() {
		// add up all the meter readings
		var totalDiff = 0;
		for (var i = 0; i < this.state.readings.length; i++) {
			if (this.isNumeric(this.state.readings[i].AMOUNT_DIFF)) {
				totalDiff += +this.state.readings[i].AMOUNT_DIFF;
			}
		}
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col>
						<div style={{ display: 'inline-block', fontSize: '18px', fontWeight: 'bold', marginRight: '10px' }}>Enter Readings Taken on</div>
						<div style={{ display: 'inline-block' }}>
						  <DatePicker
							selected={this.state.entryDate}
							onChange={(entryDate) => this.mergeState({ entryDate: entryDate })}
							dateFormat='MM/dd/yy'
						  />
						</div>
						<div style={{ display: 'inline-block', paddingLeft: '10px' }}>
							<Button id="markbilled" variant="warning" type="button" onClick={(event) => this.onMarkReadyToBill(event)}>Mark Ready to Bill</Button>						
						</div>
					  </Col>
					</Row>
					<Row style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col>
					      <table cellSpacing="0" cellPadding="1" style={{ border: '1px solid #ccc', borderRadius: 4, borderCollapse: 'separate' }}>
						    <thead>
							  <tr>
								<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>{this.renderHeader('Meter', 'METER_NAME_NUMERIC')}</td>
								<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>{this.renderHeader('Building', 'BUILDING')}</td>
								<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>{this.renderHeader('Room Served', 'ROOM_SERVED')}</td>
								<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>{this.renderHeader('Read Order', 'READ_ORDER')}</td>
								<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>{this.renderHeader('PI', 'PI_NAME')}</td>
								<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>{this.renderHeader('Rch PI', 'OTHER_PI_NAME')}</td>
								<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>{this.renderHeader('%', 'PERCENTAGE')}</td>
								<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>{this.renderHeader('Last Reading', 'PAST_AMOUNT')}</td>
								<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>{this.renderHeader('Current Reading', 'NEW_AMOUNT')}</td>
								<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>{this.renderHeader('Difference', 'AMOUNT_DIFF')}</td>
								<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>Saved</td>
							  </tr>
							</thead>
							<tbody>
							  {this.state.readings.map((reading, rIdx) => {
							    return (
								  <tr key={rIdx}>
									<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>{reading.METER_NAME}</td>
									<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>{reading.BUILDING}</td>
									<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>{reading.ROOM_SERVED}</td>
									<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>{reading.READ_ORDER}</td>
									<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>{reading.PI_NAME}</td>
									<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>{reading.OTHER_PI_NAME}</td>
									<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>{reading.PERCENTAGE}</td>
									<td style={{ border: '1px solid #ccc' }}>
										<Form.Control id={'PAST_AMOUNT_' + reading.READING_ID} type="text" value={reading.PAST_AMOUNT}
											onChange={(event) => this.onChangeReading(reading.READING_ID, 'PAST_AMOUNT', event.target.value)} />
									</td>
									<td style={{ border: '1px solid #ccc' }}>
										<Form.Control id={'NEW_AMOUNT_' + reading.READING_ID} className='newAmountFocusable' type="text" value={reading.NEW_AMOUNT}
											onChange={(event) => this.onChangeReading(reading.READING_ID, 'NEW_AMOUNT', event.target.value)}
											onKeyDown={(event) => this.onKeyDown(event, reading.READING_ID)} />
									</td>
									<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>{reading.AMOUNT_DIFF}</td>
									{this.renderReadingState(reading.STATE)}
								  </tr>
								);
							  })}
							  <tr>
								<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc', fontWeight: 'bold' }} colspan={9} align='right'>
									Total Difference
								</td>
								<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc', fontWeight: 'bold' }}>
									{totalDiff}
								</td>
								<td style={{ paddingLeft: 6, paddingRight: 6, border: '1px solid #ccc' }}>
								</td>
							  </tr>
							</tbody>
						  </table>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

export default HeMeterReadings;
