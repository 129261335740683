import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import ChemTable from '../ChemTable';
import QueryTable from '../QueryTable';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import Tabs from '../Tabs';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Spinner, Button } from 'react-bootstrap';
import { PencilSquare, PlusSquare, Trash, HandThumbsUp, HandThumbsDown } from 'react-bootstrap-icons';

class Person extends ChemComponent {
	constructor(props) {
		super(props);
		
		// set person ID in requestAccessColumns
		var reqAccessColsWithPersonID = this.copyObject(requestAccessColumns);
		reqAccessColsWithPersonID[0].options.search.LongValue = this.props.params.personId;
		
		this.state = {
			person: {
				PERSON_ID: ''
			},
			keyRequest: {
				ROOM_ID: null
			},
			accessRequest: {
				PI_ROLE_ID: null,
				LOC_ACCESS_ID: null
			},
			card: {
				CARD_PK: ''
			},
			show_expired_roles: false,
			roles: [],
			ethanolAuthorizations: this.mergeObject(ethanolAuthorizations, { query: { search: { LongValue: this.props.params.personId }}}),
			keysCheckedOut: this.mergeObject(keysCheckedOut, { query: { search: { LongValue: this.props.params.personId }}}),		
			approvedKeys: this.mergeObject(approvedKeys, { query: { search: { LongValue: this.props.params.personId }}}),		
			authElectronicAccess: this.mergeObject(authElectronicAccess, { query: { search: { LongValue: this.props.params.personId }}}),
			pendingElectronicAccess: this.mergeObject(pendingElectronicAccess, { query: { search: { LongValue: this.props.params.personId }}}),
			requestAccessColumns: reqAccessColsWithPersonID,
			addresses: {
				PERSON_ID: ''
			},
			returnAllDate: new Date(),
			renderKey: 0,
			cardSubmitterID: ''
		}
	}

	componentDidMount() {
		this.loadPerson();
	}
	
	loadPerson(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/BuildingAccess/GetPerson',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, personId: this.props.params.personId }
		}).done(function (data) {
			if (data.Success) {
				var person = data.People[0].PeopleView;
				
				// compose full name
				person.PERSON_NAME = person.FNAME + ' ' + person.LNAME;
				
				// convert flags
				person.ACTIVE_FLAG = person.ACTIVE_FLAG === 'Y';
				person.SAFETY_MONITOR = person.SAFETY_MONITOR === 'Y';
				person.LECTURE_FLAG = person.LECTURE_FLAG === 'Y';
				person.HasDirectoryPhoto = data.People[0].HasDirectoryPhoto;
				person.HasSafetyCertificate = data.People[0].SafetyTraining && data.People[0].SafetyTraining.length > 0;
				
				// get card info
				var card;
				if (data.People[0].Cards && data.People[0].Cards.length > 0) {
					person.CARD_ID = data.People[0].Cards[0].CARD_ID;
					person.PROX_NUMBER = data.People[0].Cards[0].PROX_NUMBER;
					card = {
						CARD_PK: data.People[0].Cards[0].CARD_PK,
						CARD_ID: data.People[0].Cards[0].CARD_ID,
						PROX_NUMBER: data.People[0].Cards[0].PROX_NUMBER,
						CARD_MAG: data.People[0].Cards[0].CARD_MAG,
						COMMENTS: data.People[0].Cards[0].COMMENTS,
						CREATED: self.composeChangeInfo(data.People[0].Cards[0].CREATE_BY, data.People[0].Cards[0].CREATE_DATE),
						MODIFIED: self.composeChangeInfo(data.People[0].Cards[0].MODIF_BY, data.People[0].Cards[0].MODIF_DATE)
					};
				} else {
					person.CARD_ID = '';
					person.PROX_NUMBER = '';
					card = {
						CARD_PK: '(new)',
						CARD_ID: '',
						PROX_NUMBER: ''
					};
				}
				
				// get safety training info
				if (person.HasSafetyCertificate) {
					person.SafetyTraining = data.People[0].SafetyTraining.DATE_MODIF ?
						self.getDate(data.People[0].SafetyTraining.DATE_MODIF) :
						self.getDate(data.People[0].SafetyTraining.DATE_CREATED);
				} else {
					person.SafetyTraining = null;
				}
				
				// compose created, modified fields
				person.CREATE_BY = self.composeChangeInfo(person.CREATE_BY, person.CREATE_DATE);
				person.MODIF_BY = self.composeChangeInfo(person.MODIF_BY, person.MODIF_DATE);
				
				// flatten roles and get default PI_ROLE_ID
				var roles = [];
				var defaultPIRoleIDIsValid = false;
				var defaultPIRoleID = null;
				for (var i = 0; i < data.People[0].Roles.length; i++) {
					roles.push(data.People[0].Roles[i].RoleView);
					roles[i].id = roles[i].ROLE_ID;
					roles[i].PIPersonID = data.People[0].Roles[i].PIPersonID;
					if (data.People[0].Roles[i].WorkContacts.length > 0) {
						var w = data.People[0].Roles[i].WorkContacts[0];
						roles[i].LOCATION = self.isEmpty(w.ROOM_ID) ? (w.OUTSIDE_LOCATION + ' ' + w.BUILDING_NAME) : (w.ROOM_NUMBER + ' ' + w.BUILDING_NAME);
					}
					if (roles[i].PI_ROLE_ID) {
						// if there is already a default PI_ROLE_ID, then invalidate, because the user has to pick
						if (defaultPIRoleID) {
							defaultPIRoleIDIsValid = false;
						} else {
							// this is the first valid PI_ROLE_ID, so set it as the valid default
							defaultPIRoleID = roles[i].PI_ROLE_ID;
							defaultPIRoleIDIsValid = true;
						}
					}
				}
				
				// clear the defaultPIRoleID if it is not valid
				if (!defaultPIRoleIDIsValid) defaultPIRoleID = null;

				var addresses = {
					PERSON_ID: person.PERSON_ID
				};
				// get address info
				if (data.People[0].Addresses.length > 0) {
					addresses = data.People[0].Addresses[0];
					
					// compose change info
					addresses.CREATED = self.composeChangeInfo(addresses.CREATE_BY, addresses.CREATE_DATE);
					addresses.MODIFIED = self.composeChangeInfo(addresses.MODIF_BY, addresses.MODIF_DATE);
				}

				self.mergeState({
					person: person,
					roles: roles,
					card: card,
					addresses: addresses,
					accessRequest: { PI_ROLE_ID: defaultPIRoleID },
					renderKey: self.state.renderKey + 1
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	copyPerson() {
		var person = this.copyObject(this.state.person);
		person.DIR_PHOTO = this.state.person.DIR_PHOTO;
		person.CERTIF = this.state.person.CERTIF;
		return person;
	}
	
	onChangePerson(accessor, value) {
		var person = this.copyPerson();
		this.setByAccessor(person, accessor, value);
		this.mergeState({ person: person });
	}
		
	onSubmitPerson(event) {
		var self = this;
		var person = this.copyPerson();
		
		this.uploadDirPhoto(person, () => {
			this.uploadSafetyCertificate(person, () => {
				// clear out stuff that will screw up marshaling
				person.CERTIF = null;
				person.DIR_PHOTO = null;
				
				// translate flags to something oracle will understand
				person.ACTIVE_FLAG = person.ACTIVE_FLAG ? 'Y' : null;
				person.LECTURE_FLAG = person.LECTURE_FLAG ? 'Y' : null;
				person.SAFETY_MONITOR = person.SAFETY_MONITOR ? 'Y' : null;
				
				// convert dates
				person.END_DATE = self.dateTimeToMVC(self.getDate(person.END_DATE));
				person.START_DATE = self.dateTimeToMVC(self.getDate(person.START_DATE));
				person.SAFETY_LECTURE = self.dateTimeToMVC(self.getDate(person.SAFETY_LECTURE));
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/BuildingAccess/UpdatePeopleView',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, person: person }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Record updated for <Link to={'/BuildingAccess/People/' + person.PERSON_ID}>{person.PERSON_NAME}</Link>
							</>);
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			});
		});
	}
	
	uploadDirPhoto(person, callback) {
		var self = this;		
		var dirphoto = person.DIR_PHOTO;
		
		// if there is a directory photo
		if (dirphoto && dirphoto.name) {
			// Create an object of formData
			const formData = new FormData();

			// add the token to the formData
			formData.append("__RequestVerificationToken", this.props.user.antiForgeryToken);
		
			// add the directory photo with the person ID
			formData.append("dirphoto", dirphoto, person.PERSON_ID);
			
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/BuildingAccess/UpdateDirectoryPhoto',
				data: formData,
				contentType: false,
				processData: false
			}).done(function (data) {
				if (data.Success) {
					if (callback) callback();
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		} else {
			// no photo to upload
			if (callback) callback();
		}			
	}
	
	uploadSafetyCertificate(person, callback) {
		var self = this;
		var safety = person.CERTIF;
		
		// if there is a safety certificate
		if (safety && safety.name && person.CAL_UID) {
			// Create an object of formData
			const formData = new FormData();

			// add the token to the formData
			formData.append("__RequestVerificationToken", this.props.user.antiForgeryToken);
		
			// add the safety certificate with the UID and name
			formData.append("safety", safety, person.CAL_UID + ' ' + person.PERSON_NAME);
			
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/BuildingAccess/UpdateSafetyCertificate',
				data: formData,
				contentType: false,
				processData: false
			}).done(function (data) {
				if (data.Success) {
					if (callback) callback();
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		} else {
			// no certificate to upload
			if (callback) callback();
		}			
	}

	addRole() {
		this.props.navigate('/BuildingAccess/Roles/new/' + this.props.params.personId);
	}
	
	onChangeAccessRequest(accessor, value) {
		var accessRequest = this.copyObject(this.state.accessRequest);
		this.setByAccessor(accessRequest, accessor, value);
		this.mergeState({ accessRequest: accessRequest });
	}
	
	createAccessRequest(event) {
		var self = this;
		
		// create access request
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/BuildingAccess/InsertAccessRequest',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, personid: this.props.params.personId, locaccessid: this.state.accessRequest.LOC_ACCESS_ID }
		}).done(function (data) {
			if (data.Success) {
				// redraw
				self.mergeState({
					renderKey: self.state.renderKey + 1,
					pendingElectronicAccess: {
						renderKey: self.state.pendingElectronicAccess.renderKey + 1
					}
				}, () => {
					// un-focus save button
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});				
	}
	
	onChangeKeyRequest(accessor, value) {
		var keyRequest = this.copyObject(this.state.keyRequest);
		this.setByAccessor(keyRequest, accessor, value);
		this.mergeState({ keyRequest: keyRequest });
	}

	createKeyRequest(event) {
		var self = this;
		
		// create key request
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/BuildingAccess/InsertKeyRequest',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, personid: this.props.params.personId, roomid: this.state.keyRequest.ROOM_ID }
		}).done(function (data) {
			if (data.Success) {
				// redraw
				self.mergeState({
					renderKey: self.state.renderKey + 1,
					approvedKeys: {
						renderKey: self.state.approvedKeys.renderKey + 1
					}
				}, () => {
					// un-focus save button
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});				
	}
	
	editRole(roleId) {
		this.props.navigate('/BuildingAccess/Roles/' + roleId);
	}
	
	onClickSaveCard() {
		this.mergeState({
			cardSubmitterID: 'save'
		});
	}
	
	onClickDeleteCard() {
		this.mergeState({
			cardSubmitterID: 'delete'
		});
	}
	
	onChangeCard(accessor, value) {
		var card = this.copyObject(this.state.card);
		this.setByAccessor(card, accessor, value);
		this.mergeState({ card: card });
	}
	
	onSubmitCard(event) {	
		var submitterID = '';
		if (event && event.nativeEvent && event.nativeEvent.submitter) {
			submitterID = event.nativeEvent.submitter.id;
		} else {
			submitterID = this.state.cardSubmitterID;
		}
	
		var self = this;
		var card = this.copyObject(this.state.card);
	
		// clear card submitter
		this.mergeState({
			cardSubmitterID: '',
		}, () => {
			if (submitterID === 'delete') {
				self.showOKCancel('Delete Card', 'Delete card ' + card.CARD_PK + '?', (deleteConfirmed) => {
					if (deleteConfirmed) {
						card.DELETE_FLAG = 'Y';
						self.updateCard(card);
					}
				});
			} else if (submitterID === 'save') {
				if (card.CARD_PK === '(new)') {
					card.CARD_PK = 0;
					card.PERSON_ID = self.state.person.PERSON_ID;
					self.insertCard(card);
				} else {
					self.updateCard(card);
				}
			}
		});
	}
	
	insertCard(card) {
		var self = this;
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/BuildingAccess/InsertCard',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, card: card }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Card created for <Link to={'/BuildingAccess/People/' + self.state.person.PERSON_ID}>{self.state.person.PERSON_NAME}</Link>
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}		
	
	updateCard(card) {
		var self = this;
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/BuildingAccess/UpdateCard',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, card: card }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Card updated for <Link to={'/BuildingAccess/People/' + self.state.person.PERSON_ID}>{self.state.person.PERSON_NAME}</Link>
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	createEthanolRequest(piroleid) {
		var self = this;
				
		// create access request
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/BuildingAccess/InsertPeopleAccess',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, personid: this.props.params.personId, piroleid: piroleid, locaccessid: 999 }
		}).done(function (data) {
			if (data.Success) {
				// reload ethanol authorizations
				self.mergeState({
					ethanolAuthorizations: {
						renderKey: self.state.ethanolAuthorizations.renderKey + 1
					}
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});				
	}
	
	requestDeleteElectronicAccess(locaccessid) {
		var self = this;
		
		var confirmText = 'Are you sure you want to request revocation of this electronic access? Access will be removed when the revocation is approved.';
		this.showOKCancel('Confirm Revoke', confirmText, (okClicked) => {
			if (okClicked) {
				// delete electronic access
				this.ajax({
					type: 'POST',
					url: this.getConfig().host + '/BuildingAccess/DeleteElectronicAccess',
					overlay: true,
					data: { __RequestVerificationToken: this.props.user.antiForgeryToken, personid: this.props.params.personId, locaccessid: locaccessid }
				}).done(function (data) {
					if (data.Success) {
						// reload electronic access
						self.mergeState({
							authElectronicAccess: {
								renderKey: self.state.authElectronicAccess.renderKey + 1
							}
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});				
			}
		});
	}
	
	deleteElectronicAccess(accessid) {
		var self = this;
		
		// delete access
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/BuildingAccess/DeletePeopleAccess',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, accessid: accessid }
		}).done(function (data) {
			if (data.Success) {
				// reload ethanol authorizations
				self.mergeState({
					authElectronicAccess: {
						renderKey: self.state.authElectronicAccess.renderKey + 1
					}
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});				
	}
	
	approveElectronicAccessRequest(locaccessid) {
		var self = this;
		
		// if a PI has been chosen
		if (this.state.accessRequest.PI_ROLE_ID) {
			// create access
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/BuildingAccess/InsertPeopleAccess',
				overlay: true,
				data: { __RequestVerificationToken: this.props.user.antiForgeryToken, personid: this.props.params.personId, piroleid: this.state.accessRequest.PI_ROLE_ID, locaccessid: locaccessid }
			}).done(function (data) {
				if (data.Success) {
					// reload access & access requests
					self.mergeState({
						authElectronicAccess: {
							renderKey: self.state.authElectronicAccess.renderKey + 1
						},
						pendingElectronicAccess: {
							renderKey: self.state.pendingElectronicAccess.renderKey + 1
						}
					});
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});				
		} else {
			this.showAlert('PI for Approval', 'Please select a PI for approval.');
		}
	}

	deleteElectronicAccessRequest(locaccessid) {
		var self = this;
		
		var confirmText = 'Are you sure you want to delete this electronic access request?';
		this.showOKCancel('Confirm Delete', confirmText, (okClicked) => {
			if (okClicked) {
				// delete electronic access request
				this.ajax({
					type: 'POST',
					url: this.getConfig().host + '/BuildingAccess/DeleteElectronicAccessRequest',
					data: { __RequestVerificationToken: this.props.user.antiForgeryToken, personid: this.props.params.personId, locaccessid: locaccessid }
				}).done(function (data) {
					if (data.Success) {
						// reload electronic access requests
						self.mergeState({
							pendingElectronicAccess: {
								renderKey: self.state.pendingElectronicAccess.renderKey + 1
							}
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});				
			}
		});
	}

	deleteEthanolRequest(props) {
		var self = this;
		
		var accessid = props.value;
		var approved = props.row.values.APPROVED === 'Y';
		
		var confirmText = 'Are you sure you want to delete this ' + (approved ?  'authorization?' : 'request?');
		this.showOKCancel('Confirm Delete', confirmText, (okClicked) => {
			if (okClicked) {
				// delete access request
				this.ajax({
					type: 'POST',
					url: this.getConfig().host + '/BuildingAccess/DeletePeopleAccess',
					data: { __RequestVerificationToken: this.props.user.antiForgeryToken, accessid: accessid }
				}).done(function (data) {
					if (data.Success) {
						// reload ethanol authorizations
						self.mergeState({
							ethanolAuthorizations: {
								renderKey: self.state.ethanolAuthorizations.renderKey + 1
							}
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});				
			}
		});
	}
	
	deleteKeyRequest(props) {
		var self = this;
		
		var roomid = props.value;
		var reqroleid = props.row.values.REQ_ROLE_ID;
		
		var confirmText = 'Are you sure you want to delete this key approval?';
		this.showOKCancel('Confirm Delete', confirmText, (okClicked) => {
			if (okClicked) {
				// delete authorized key
				this.ajax({
					type: 'POST',
					url: this.getConfig().host + '/BuildingAccess/DeleteKeyRequest',
					data: { __RequestVerificationToken: this.props.user.antiForgeryToken, roomid: roomid, reqroleid: reqroleid }
				}).done(function (data) {
					if (data.Success) {
						// reload authorized keys
						self.mergeState({
							approvedKeys: {
								renderKey: self.state.approvedKeys.renderKey + 1
							}
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});				
			}
		});
	}
	
	editApprovedKey(props) {
		var roomid = props.row.values.ROOM_ID;
		var reqroleid = props.value;
		
		this.props.navigate('/BuildingAccess/ApprovedKeys/' + reqroleid + '/' + roomid);
	}
	
	editIssuedKey(keyAdminID) {
		this.props.navigate('/BuildingAccess/IssuedKeys/' + keyAdminID);
	}
	
	returnAllKeys() {
		var self = this;
		this.showOKCancel('Confirm Return', 'Return all keys for ' + this.state.person.PERSON_NAME + '?', (okClicked) => {
			if (okClicked) {
				var returnDate = this.dateTimeToMVC(this.state.returnAllDate);
				// return all keys
				this.ajax({
					type: 'POST',
					url: this.getConfig().host + '/BuildingAccess/ReturnAllKeys',
					data: { __RequestVerificationToken: this.props.user.antiForgeryToken, personid: this.state.person.PERSON_ID, returndate: returnDate }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								All keys returned for <Link to={'/BuildingAccess/People/' + self.state.person.PERSON_ID}>{self.state.person.PERSON_NAME}</Link>
							</>);
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});				
			}
		});
		
	}
	
	onChangeAddresses(accessor, value) {
		// make copy of addresses
		var addresses = this.copyObject(this.state.addresses);
		
		// set new value
		this.setByAccessor(addresses, accessor, value);
		
		// merge into state
		this.mergeState({ addresses: addresses });
	}
	
	validateAddressInfo() {
		// no validation required
		return null;
	}
	
	onSubmitAddresses(event) {
		var self = this;

		// update addresses
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/BuildingAccess/UpdateAddresses',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, addresses: this.state.addresses }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Addresses updated for <Link to={'/BuildingAccess/People/' + self.state.person.PERSON_ID}>{self.state.person.PERSON_NAME}</Link>
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});				

	}
	
	renderCal1Card(props) {
		var proxNum = this.getByAccessor(props.editor.props.data, 'PROX_NUMBER');
		return this.getByAccessor(props.editor.props.data, 'CARD_ID') + ' Prox. Num. ' + (proxNum || '(none)');
	}
	
	renderDirectoryPhoto(props) {
		if (this.getByAccessor(props.editor.props.data, 'HasDirectoryPhoto')) {
			var personID = this.getByAccessor(props.editor.props.data, 'PERSON_ID');
			return (
				<form action={this.getConfig().host + '/BuildingAccess/DownloadDirectoryPhoto'} method="POST">
					<input type="hidden" name="__RequestVerificationToken" value={this.props.user.antiForgeryToken} />
					<input type="hidden" name="personid" value={personID} />
					<input type="submit" value="View" />
				</form>
			);
		} else {
			return '(none)';
		}
	}
		
	renderSafetyCertificate(props) {
		if (this.getByAccessor(props.editor.props.data, 'HasSafetyCertificate')) {
			var calUID = this.getByAccessor(props.editor.props.data, 'CAL_UID');
			return (
				<form action={this.getConfig().host + '/BuildingAccess/DownloadSafetyCertificate'} method="POST">
					<input type="hidden" name="__RequestVerificationToken" value={this.props.user.antiForgeryToken} />
					<input type="hidden" name="caluid" value={calUID} />
					<input type="submit" value="View" />
				</form>
			);
		} else {
			return '(none)';
		}
	}
	
	renderDeleteCardButton() {
		if (this.state.card.CARD_PK !== '(new)') {
			return (<Button id="delete" variant="warning" type="submit" onClick={() => this.onClickDeleteCard()} className="float-end" style={{ marginRight: '10px' }}>Delete</Button>);
		}
	}

	render() {
		// copy roles to local var so that expired roles will be hidden if checkbox is not Checked
		var displayed_roles = [];
		for (var i = 0; i < this.state.roles.length; i++)
		{
			// if we are showing expired roles or the role is not expired
			if (this.state.show_expired_roles || this.state.roles[i].END_ROLE_DATE === null) {
				// add role to list of displayed Roles
				displayed_roles.push(this.state.roles[i]);
			}
		}
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Current Record for {this.isEmpty(this.state.person.PERSON_NAME) ? <Spinner animation='border' as='div' size='sm' style={{ marginLeft: '4px' }} /> : this.state.person.PERSON_NAME}</div>
				  <Tabs>
					<div label='General Info'>
					  <Container fluid>
						<Row>
						  <Col>
							<ChemEdit parent={this} columns={personColumns} data={this.state.person} user={this.props.user} renderKey={this.state.renderKey} 
								onChange={(accessor, value) => this.onChangePerson(accessor, value)} onSubmit={(event) => this.onSubmitPerson(event)}>
							  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
								<Col xs={12}>
								  <Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
								</Col>
							  </Form.Group>
							</ChemEdit>
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Appointments'>
					  <Container fluid>
						<Row>
						  <Col>
						  	<Form.Check type="checkbox" checked={this.state.show_expired_roles} label="Show Expired Appointments"
								onClick={(event) => this.mergeState({ show_expired_roles: event.target.checked })} />
							<ChemTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_Person_Roles' columns={roleColumns} data={displayed_roles} />
							<div style={{ paddingBottom: '10px' }} />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Home Address Info'>
					  <Container fluid>
					    <Row>
						  <Col>
							<div style={{ fontWeight: 'bold', marginBottom: '10px' }}>Local Address</div>
							<ChemEdit parent={this} columns={localAddressColumns} data={this.state.addresses} user={this.props.user} renderKey={this.state.renderKey}
							  onChange={(accessor, value) => this.onChangeAddresses(accessor, value)}>
							  <Row style={{ borderTop: '1px solid #eee' }} />
							</ChemEdit>
							<div style={{ fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>Forwarding Address</div>
							<ChemEdit parent={this} columns={fwdAddressColumns} data={this.state.addresses} user={this.props.user} renderKey={this.state.renderKey}
							  validate={() => this.validateAddressInfo()} onChange={(accessor, value) => this.onChangeAddresses(accessor, value)} onSubmit={(event) => this.onSubmitAddresses(event)}>
							  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
								<Col xs={12}>
								  <Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
								</Col>
							  </Form.Group>
							</ChemEdit>
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Card/Access Info'>
					  <Container fluid>
					    <Row>
						  <Col>
						    <div style={{ fontSize: '18px', fontWeight: 'bold', paddingBottom: '10px' }}>Card {this.state.card.CARD_PK}</div>
						    <ChemEdit parent={this} columns={cardColumns} data={this.state.card} user={this.props.user} renderKey={this.state.renderKey} 
							  onChange={(accessor, value) => this.onChangeCard(accessor, value)} onSubmit={(event) => this.onSubmitCard(event)}>
							  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
								<Col xs={12}>
								  <Button id="save" variant="warning" type="submit" onClick={() => this.onClickSaveCard()} className="float-end">Save</Button>
								  {this.renderDeleteCardButton()}
								</Col>
							  </Form.Group>
						    </ChemEdit>
						  </Col>
						</Row>
						<Row>
						  <Col>
							<div style={{ fontSize: '18px', fontWeight: 'bold', paddingBottom: '10px' }}>Storeroom Ethanol Authorization</div>
							<QueryTable renderKey={this.state.ethanolAuthorizations.renderKey} parent={this} table={this.state.ethanolAuthorizations} name='BuildingAccess_Person_Ethanol' />
							<div style={{ paddingBottom: '10px' }} />
						  </Col>
						</Row>
					    <Row>
						  <Col>
						    <div style={{ fontSize: '18px', fontWeight: 'bold', paddingBottom: '10px' }}>Authorized Electronic Access</div>
						    <QueryTable renderKey={this.state.authElectronicAccess.renderKey} parent={this} table={this.state.authElectronicAccess} name='BuildingAccess_Person_Auth_Electronic_Access' />
						    <div style={{ paddingBottom: '10px' }} />
						  </Col>
					    </Row>
					    <Row>
						  <Col>
						    <div style={{ fontSize: '18px', fontWeight: 'bold', paddingBottom: '10px' }}>Pending Electronic Access Request and Special Keys</div>
						    <ChemEdit parent={this} columns={this.state.requestAccessColumns} data={this.state.accessRequest} user={this.props.user} renderKey={this.state.renderKey} 
							  onChange={(accessor, value) => this.onChangeAccessRequest(accessor, value)} onSubmit={(event) => this.createAccessRequest(event)}>
							  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
								<Col xs={10}>
									<span style={{ fontStyle: 'italic', paddingTop: 5 }} className="float-end">
										Note: Storeroom access will not be granted until after storeroom tour has been taken.
									</span>
								</Col>
								<Col xs={2}>
								  <Button id="save" variant="warning" type="submit" className="float-end">Save Request</Button>
								</Col>
							  </Form.Group>
						    </ChemEdit>
						    <QueryTable renderKey={this.state.pendingElectronicAccess.renderKey} parent={this} table={this.state.pendingElectronicAccess} name='BuildingAccess_Person_Pending_Electronic_Access' />
						    <div style={{ paddingBottom: '10px' }} />
						  </Col>
					    </Row>
					  </Container>
					</div>
					<div label='Keys Info'>
					  <Container fluid>
					    <Row>
						  <Col sm={4}>
							<div style={{ fontSize: '18px', fontWeight: 'bold', paddingBottom: '10px' }}>Keys Checked Out</div>
						  </Col>
						  <Col sm={8}>
								Return all keys as of&nbsp;&nbsp;
								<DatePicker
									selected={this.state.returnAllDate}
									onChange={(date) => this.mergeState({ returnAllDate: date })}
									dateFormat='MM/dd/yy' />&nbsp;&nbsp;
								<Button size='sm' style={{ paddingBottom: '6px' }} onClick={() => this.returnAllKeys()}>Return Keys</Button>
						  </Col>
						</Row>
					    <Row>
						  <Col>
						    <QueryTable renderKey={this.state.keysCheckedOut.renderKey} parent={this} table={this.state.keysCheckedOut} name='BuildingAccess_Person_Keys_Checked_Out' />
						    <div style={{ paddingBottom: '10px' }} />
						  </Col>
					    </Row>
					    <Row>
						  <Col>
						    <div style={{ fontSize: '18px', fontWeight: 'bold', paddingBottom: '10px' }}>Approved Room Keys to be picked up</div>
						    <ChemEdit parent={this} columns={requestKeyColumns} data={this.state.keyRequest} user={this.props.user} renderKey={this.state.renderKey} 
							  onChange={(accessor, value) => this.onChangeKeyRequest(accessor, value)} onSubmit={(event) => this.createKeyRequest(event)}>
							  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
								  <Col xs={10}>
									<span style={{ fontStyle: 'italic', paddingTop: 5 }} className="float-end">
										If you do not find a room, please contact Facility Management to check if access is restricted.
									</span>
								  </Col>
								  <Col xs={2}>
								    <Button id="save" variant="warning" type="submit" className="float-end">Save Request</Button>
								  </Col>
							  </Form.Group>
						    </ChemEdit>
						    <QueryTable renderKey={this.state.approvedKeys.renderKey} parent={this} table={this.state.approvedKeys} name='BuildingAccess_Person_Approved_Keys' />
						    <div style={{ paddingBottom: '10px' }} />
						  </Col>
					    </Row>
					  </Container>
					</div>
				  </Tabs>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const personColumns = [
	{ Header: 'ID', accessor: 'PERSON_ID', editable: false, row: 0, col: 0 },
	{ Header: 'Active', accessor: 'ACTIVE_FLAG', type: 'checkbox', row: 0, col: 1 },
	{ Header: 'Cal UID', accessor: 'CAL_UID', required: true, row: 0, col: 2 },
	{ Header: 'Last Name', accessor: 'LNAME', required: true, row: 1, col: 0 },
	{ Header: 'First Name', accessor: 'FNAME', required: true, row: 1, col: 1 },
	{ Header: 'Cal1 Card ID', accessor: 'CARD_ID', editable: false, row: 1, col: 2,
		Cell: props => props.parent.renderCal1Card(props) },
	{ Header: 'Middle Name', accessor: 'MNAME', row: 2, col: 0 },
	{ Header: 'Preferred Name', accessor: 'PREFERRED_NAME', row: 2, col: 1 },
	{ Header: 'Directory Photo', accessor: 'HasDirectoryPhoto', row: 2, col: 2,
		Cell: props => props.parent.renderDirectoryPhoto(props)	},
	{ Header: 'Department 1', accessor: 'DEPT_ID', required: true, type: 'select', row: 3, col: 0,
		options: { value: 'DEPARTMENT_ID', label: 'DEPT_NAME', entity: 'DEPARTMENT', order: [ 'DEPT_NAME' ] } },
	{ Header: 'Department 2', accessor: 'DEPT2_ID', type: 'select', row: 3, col: 1,
		options: { value: 'DEPARTMENT_ID', label: 'DEPT_NAME', entity: 'DEPARTMENT', order: [ 'DEPT_NAME' ] } },
	{ Header: 'Upload Directory Photo', accessor: 'DIR_PHOTO', type: 'file', row: 3, col: 2 },	
	{ Header: 'Date Started', accessor: 'START_DATE', editable: false, row: 4, col: 0,
		Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
	{ Header: 'Expected Departure<br /><i>(for non career appt.)</i>', accessor: 'END_DATE', type: 'date', row: 4, col: 1 },
	{ Header: 'Floor Safety Monitor', accessor: 'SAFETY_MONITOR', type: 'checkbox', row: 4, col: 2 },	
	{ Header: 'Emergency Contact Name', accessor: 'EMERGENCY_CONTACT', required: true, row: 5, col: 0 },
	{ Header: 'Emergency Contact Phone', accessor: 'EMERGENCY_CONTACT_PH', required: true, row: 5, col: 1 },
	{ Header: 'Emergency Cell', accessor: 'EMER_CELL', row: 5, col: 2 },
	{ Header: 'Lab Emergency<br>Phone 1 <i>(for PI)</i>', accessor: 'PI_EMER_PHONE1', row: 6, col: 0 },
	{ Header: 'Lab Emergency<br>Phone 2 <i>(for PI)</i>', accessor: 'PI_EMER_PHONE2', row: 6, col: 1 },
	{ Header: 'Safety Certificate', accessor: 'SafetyCertificate', row: 6, col: 2,
		Cell: props => props.parent.renderSafetyCertificate(props) }, 
	{ Header: "Safety Training Req'd", accessor: 'LECTURE_FLAG', type: 'checkbox', row: 7, col: 0 },
	{ Header: 'Date Completed', accessor: 'SAFETY_LECTURE', type: 'date', row: 7, col: 1 },
	{ Header: 'Upload Safety Certificate', accessor: 'CERTIF', type: 'file', row: 7, col: 2 },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 8, col: 0 },
	{ Header: 'Created By', accessor: 'CREATE_BY', editable: false, row: 8, col: 1 },
	{ Header: 'Modified By', accessor: 'MODIF_BY', editable: false, row: 8, col: 2 }
];

const cardColumns = [
	{ Header: 'Cal1 Card ID', accessor: 'CARD_ID', row: 0, col: 0, required: true },
	{ Header: 'Proximity Number', accessor: 'PROX_NUMBER', row: 0, col: 1 },
	{ Header: 'Cal1 Card Mag', accessor: 'CARD_MAG', row: 1, col: 0 },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 1, col: 1 },
	{ Header: 'Created', accessor: 'CREATED', editable: false, row: 2, col: 0 },
	{ Header: 'Modified', accessor: 'MODIFIED', editable: false, row: 2, col: 1 }
];

const ethanolAuthorizations = {
	renderKey: 0,
	query: { entity: 'ETHANOL_ACCESS_PI_VW', search: { Attribute: 'PERSON_ID', Operator: '=' }},
	columns: [
		{ Header: '', accessor: 'PEOPLE_ACCESS_ID', width: 25,
			Cell: props => { if (props.value) return (<Trash style={{ cursor: 'pointer' }} onClick={() => props.parent.deleteEthanolRequest(props)} />); else return null; }},
		{ Header: '', accessor: 'REQUEST_PI_ROLE_ID', width: 25,
			Cell: props => { if (props.value) return (<PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.createEthanolRequest(props.value)} />); else return null; }},
		{ Header: 'Approved', accessor: 'APPROVED' },
		{ Header: 'Status', accessor: 'DESCRIPTION' },
		{ Header: 'Unit', accessor: 'UNIT_NAME' } 
	]
};
	
const keysCheckedOut = {
	renderKey: 0,
	query: { entity: 'KEYS_CHECKED_OUT_VW', search: { Attribute: 'PERSON_ID', Operator: '=' }},
	columns: [
		{ Header: '', accessor: 'KEY_ADMIN_ID', width: 25,
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editIssuedKey(props.value)} /> },
		{ Header: 'Key', accessor: 'KEY_INFO', width: 200 },
		{ Header: 'Type', accessor: 'TYPE_NAME', width: 125 },
		{ Header: 'Location', accessor: 'LOCATION', width: 400 },
		{ Header: 'Checked Out', accessor: 'OUT_DATE', width: 100, Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
		{ Header: 'Returned', accessor: 'RETURN_DATE', width: 100, Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
		{ Header: 'Depost Paid', accessor: 'DEPOSIT', width: 75 },
		{ Header: 'Deposit Returned', accessor: 'RETURN_DEPOSIT', width: 100, Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
		{ Header: 'Comments', accessor: 'COMMENTS', width: 300 }
	]
};

const requestKeyColumns = [
	{ Header: 'Request Key', accessor: 'ROOM_ID', type: 'select', options: { value: 'ROOM_ID', label: 'LOCATION', entity: 'ROOM_KEYS_VW', order: 'LOCATION' } }
];

const approvedKeys = {
	renderKey: 0,
	query: { entity: 'KEYS_APPROVED_VW', search: { Attribute: 'PERSON_ID', Operator: '=' }},
	columns: [
		{ Header: '', accessor: 'ROOM_ID',
			Cell: props => <Trash style={{ cursor: 'pointer' }} onClick={() => props.parent.deleteKeyRequest(props)} />, width: 25 },
		{ Header: '', accessor: 'REQ_ROLE_ID',
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editApprovedKey(props)} />, width: 25 },
		{ Header: 'Location', accessor: 'LOCATION', width: 600, maxWidth: 1000 },
		{ Header: 'Authorized By', accessor: 'AUTH_PERSON', width: 200 }
	]
};

const authElectronicAccess = {
	renderKey: 0,
	query: { entity: 'AUTH_ELECTRONIC_ACCESS_VW', search: { Attribute: 'PERSON_ID', Operator: '=' }},
	columns: [
		{ Header: '', accessor: 'REQ_STATUS', show: false },
		{ Header: '', accessor: 'PEOPLE_ACCESS_ID',
			Cell: props => <>{ props.row.values.REQ_STATUS === 'N' && <Trash style={{ cursor: 'pointer' }} onClick={() => props.parent.deleteElectronicAccess(props.value)} /> }</>, width: 25 },
		{ Header: '', accessor: 'LOC_ACCESS_ID',
			Cell: props => <>{ props.row.values.REQ_STATUS === null && <HandThumbsDown style={{ cursor: 'pointer' }} onClick={() => props.parent.requestDeleteElectronicAccess(props.value)} /> }</>, width: 25 },
		{ Header: 'Location', accessor: 'LOC_ACCESS_NAME' }
	]
};	

const requestAccessColumns = [
    { Header: 'PI for Approval', accessor: 'PI_ROLE_ID', type: 'select', row: 0, col: 0,
		options: { value: 'PI_ROLE_ID', label: 'PI_NAME', entity: 'PI_SELECT_VW', order: 'PI_NAME',
			search: { Attribute: 'PERSON_ID', Operator: '=' } } },
	{ Header: 'Request Door Access', accessor: 'LOC_ACCESS_ID', type: 'select', row: 0, col: 1,
		options: { value: 'LOC_ACCESS_ID', label: 'LOC_ACCESS_NAME', entity: 'LOCATION_ACCESS', order: 'LOC_ACCESS_NAME',
			search: { Operator: 'and', Children: [
				{ Attribute: 'DELETE_FLAG', Operator: 'is null' },
				{ Attribute: 'LOC_ACCESS_ID', Operator: '<', LongValue: '999' }
			]}
		}
	}
];

const pendingElectronicAccess = {
	renderKey: 0,
	query: { entity: 'PENDING_ELECTRONIC_ACCESS_VW', search: { Attribute: 'PERSON_ID', Operator: '=' }},
	columns: [
		{ Header: '', accessor: 'LOC_ACCESS_ID',
			Cell: props => <Trash style={{ cursor: 'pointer' }} onClick={() => props.parent.deleteElectronicAccessRequest(props.value)} />, width: 25 },
		{ Header: '', accessor: 'id', 
			Cell: props => <HandThumbsUp style={{ cursor: 'pointer' }} onClick={() => props.parent.approveElectronicAccessRequest(props.row.values.LOC_ACCESS_ID)} />, width: 25 },
		{ Header: 'Location', accessor: 'LOC_ACCESS_NAME' }
	]
};	

const roleColumns = [
	{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addRole()} />, accessor: 'id', 
		Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editRole(props.value)} />, width: 25 },
	{ Header: 'Appointment', accessor: 'TYPE_NAME', width: 110 },
	{ Header: 'PI', accessor: 'PI_NAME', width: 125 },
	{ Header: 'Default Speedtype', accessor: 'SPEEDTYPE', width: 150 },
	{ Header: 'Supervisor', accessor: 'SUP_NAME', width: 125 },
	{ Header: 'Work Title', accessor: 'WORK_TITLE_NAME', width: 125 },
	{ Header: 'Unit', accessor: 'UNIT_NAME', width: 125 },
	{ Header: 'Office Location', accessor: 'LOCATION', width: 125 }
];

const localAddressColumns = [
	{ Header: 'Street 1', accessor: 'LOCAL_STREET1', row: 0, col: 0 },
	{ Header: 'Street 2', accessor: 'LOCAL_STREET2', row: 0, col: 1 },
	{ Header: 'City', accessor: 'LOCAL_CITY', row: 0, col: 2 },
	{ Header: 'Zip', accessor: 'LOCAL_ZIP', row: 1, col: 0 },
	{ Header: 'State', accessor: 'LOCAL_STATE', row: 1, col: 1 },
	{ Header: 'Country', accessor: 'LOCAL_COUNTRY', row: 1, col: 2 },
	{ Header: 'Phone 1', accessor: 'LOCAL_PHONE1', row: 2, col: 0 },
	{ Header: 'Phone 2', accessor: 'LOCAL_PHONE2', row: 2, col: 1 },
	{ Header: 'Cell', accessor: 'LOCAL_CELL', row: 2, col: 2 },
	{ Header: 'Fax', accessor: 'LOCAL_FAX', row: 2, col: 2 },
	{ Header: 'Email', accessor: 'LOCAL_EMAIL', row: 2, col: 2 },
	{ Header: 'URL', accessor: 'LOCAL_URL', row: 2, col: 2 }
];

const fwdAddressColumns = [
	{ Header: 'Street 1', accessor: 'FWD_STREET1', row: 0, col: 0 },
	{ Header: 'Street 2', accessor: 'FWD_STREET2', row: 0, col: 1 },
	{ Header: 'City', accessor: 'FWD_CITY', row: 0, col: 2 },
	{ Header: 'Zip', accessor: 'FWD_ZIP', row: 1, col: 0 },
	{ Header: 'State', accessor: 'FWD_STATE', row: 1, col: 1 },
	{ Header: 'Country', accessor: 'FWD_COUNTRY', row: 1, col: 2 },
	{ Header: 'Phone 1', accessor: 'FWD_PHONE1', row: 2, col: 0 },
	{ Header: 'Phone 2', accessor: 'FWD_PHONE2', row: 2, col: 1 },
	{ Header: 'Cell', accessor: 'FWD_CELL', row: 2, col: 2 },
	{ Header: 'Fax', accessor: 'FWD_FAX', row: 2, col: 2 },
	{ Header: 'Email', accessor: 'FWD_EMAIL', row: 2, col: 2 },
	{ Header: 'URL', accessor: 'FWD_URL', row: 2, col: 2 },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 3, col: 0 },
	{ Header: 'Created', accessor: 'CREATED', editable: false, row: 3, col: 1 },
	{ Header: 'Modified', accessor: 'MODIFIED', editable: false, row: 3, col: 2 }
];

export default withRouter(Person);
