import { Fragment } from 'react';
import Form from 'react-bootstrap/Form';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import { LockFill, UnlockFill, QuestionCircle } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class GN2Meter extends ChemComponent {
	constructor(props) {
		super(props);
		
		var initMeterColumns = this.copyColumns(meterColumns);
		var readValueIdx = this.findColumnByAccessor(initMeterColumns, 'READ_VALUE');
		initMeterColumns[readValueIdx].editable = props.params.meterId ? false : true;
		
		var initReadingList = this.copyTable(readingList);
		if (props.params.meterId) {
			initReadingList.query.search.LongValue = props.params.meterId;
		} else {
			initReadingList.hold = true;
		}
		
		this.state = {
			editable: props.editable,
			meter: {
				METER_ID: '',
				readings: []
			},
			meterColumns: initMeterColumns,
			readingList: initReadingList,
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadMeter();
	}

	loadMeter(event) {
		var self = this;
		
		if (this.props.params.meterId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/HeLAD/GetGN2Meter',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					meterid: this.props.params.meterId
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var meter = data.Data[0];
						self.mvc2js(meter, meterColumns);
						
						var newMeterColumns = self.copyColumns(meterColumns);
						var readValueIdx = self.findColumnByAccessor(newMeterColumns, 'READ_VALUE');
						newMeterColumns[readValueIdx].editable = self.props.params.meterId ? false : true;
						if (!self.isEmpty(meter.METER_CHILD1)) {
							var child1Idx = self.findColumnByAccessor(newMeterColumns, 'METER_CHILD1');
							newMeterColumns[child1Idx].options.search.Children.push({ Attribute: 'METER_ID', Operator: '=', LongValue: meter.METER_CHILD1 });
						}
						if (!self.isEmpty(meter.METER_CHILD2)) {
							var child2Idx = self.findColumnByAccessor(newMeterColumns, 'METER_CHILD2');
							newMeterColumns[child2Idx].options.search.Children.push({ Attribute: 'METER_ID', Operator: '=', LongValue: meter.METER_CHILD2 });
						}						
						
						self.mergeState({
							meter: meter,
							meterColumns: newMeterColumns,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('GN2 Meter Error', data.Data.length + ' GN2 meters found for ID ' + self.props.params.meterId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				meter: {
					METER_ID: '(new)',
					READ_VALUE: '0',
					ACTIVE_FLAG: true
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var meter = this.copyObject(this.state.meter);
		this.setByAccessor(meter, accessor, value);
		this.mergeState({ meter: meter, renderKey: this.state.renderKey + 1 });
	}
		
	onSubmit(event) {
		var self = this;

		var meter = this.copyObject(this.state.meter);
		this.js2mvc(meter, meterColumns);
			
		if (!this.props.params.meterId) {
			meter.METER_ID = 'meter_id_seq.nextval';
		}
		meter.LAST_MODIF = this.dateTimeToMVC(new Date());
		meter.MODIF_BY = this.props.user.name;
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/HeLAD/UpdateGN2Meter',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, meter: meter }
		}).done(function (data) {
			if (data.Success) {
				var meterId = self.props.params.meterId || data.ID;
				var verb = self.props.params.meterId ? 'updated' : 'inserted';
				// add an informative message if the meter name is not the same as the meter ID
				var meterNameMsg = (('' + meterId) === meter.METER_NAME) ? '' : (' (meter name ' + meter.METER_NAME + ')');
				self.props.parent.showConfirmation(() => {
					return (<>
						Meter <Link to={'/HeLAD/GN2Meters/' + meterId}>{meterId}</Link>{meterNameMsg} {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Meter ' + this.props.params.meterId + ' Readings',
			columns: exportColumns,
			entity: 'LIQUID_AIR.GN2_METER_READINGS_VW',
			search: { Attribute: 'METER_ID', Operator: '=', LongValue: this.props.params.meterId },
			order: 'READING_DATE desc'
		});

		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Gaseous Nitrogen Meter {this.state.meter.METER_ID}&nbsp;&nbsp;
							  {this.props.params.meterId && (('' + this.state.meter.METER_ID) !== this.state.meter.METER_NAME) && <><OverlayTrigger trigger='hover' placement='right' overlay={<Popover>
								  <Popover.Header as='h3'>Why is this number different than the meter name?</Popover.Header>
								  <Popover.Body>{this.state.meter.METER_ID} identifies the meter in the database, and {this.state.meter.METER_NAME} is the meter name.
									These are not necessarily the same. The meter name can be changed, while the database ID cannot.</Popover.Body>
								</Popover>}>
								<QuestionCircle style={{ marginTop: '-4px' }}/>
							  </OverlayTrigger>&nbsp;&nbsp;</>}
							{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
							  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
						  </div>
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={this.state.meterColumns} data={this.state.meter} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} editable={this.state.editable}>
						</ChemEdit>
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.props.params.meterId && <>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</>}
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
					    {this.props.params.meterId && <form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>}
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						{this.props.params.meterId && <form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>}
					  </Col>
					  <Col xs={7}>
						{this.state.editable && <Button id="save" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmit(event)}>Save</Button>}
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  {this.props.params.meterId && <Row>
				  <Col>
				    <QueryTable renderKey={this.state.renderKey} parent={this} name='HeLAD_GN2Meter_Readings' table={this.state.readingList}
				      onResult={(data) => this.onSearchResult(data)} offsetHeight='340px' />
				  </Col>
			  </Row>}
			</Container>
		</>);
	}
}

const meterColumns = [
	{ Header: 'Name', accessor: 'METER_NAME', row: 0, col: 0 },
	{ Header: 'Room', accessor: 'ROOM', row: 0, col: 1 },
	{ Header: 'Building', accessor: 'BUILDING', type: 'dropdown', row: 0, col: 2,
		options: { value: 'BUILDING_NAME', label: 'BUILDING_NAME', entity: 'COLLEGE.BUILDING', order: 'BUILDING_NAME' }},
	{ Header: 'Room(s) Served', accessor: 'ROOM_SERVED', row: 0, col: 3 },
	{ Header: 'Child Meter 1', accessor: 'METER_CHILD1', type: 'select', row: 1, col: 0,
		options: { value: 'METER_ID', label: 'METER_DESC', entity: 'LIQUID_AIR.GN2_CHILD_METER_SELECT_VW', order: 'BUILDING',
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' }
			]}}},
	{ Header: 'Child Meter 2', accessor: 'METER_CHILD2', type: 'select', row: 1, col: 1,
		options: { value: 'METER_ID', label: 'METER_DESC', entity: 'LIQUID_AIR.GN2_CHILD_METER_SELECT_VW', order: 'BUILDING',
			search: { Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' }
			]}}},		
	{ Header: 'Order Read', accessor: 'READ_ORDER', row: 1, col: 2 },
	{ Header: 'Last Reading', accessor: 'READ_VALUE', row: 1, col: 3 },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 2, col: 0 },
	{ Header: 'Active', accessor: 'ACTIVE_FLAG', type: 'checkbox', row: 2, col: 1 },
	{ Header: 'Last Modified', accessor: 'LAST_MODIF', row: 2, col: 2, editable: false,
		Cell: props => props.parent.dateToString(props.parent.getDate(props.value)) },
	{ Header: 'Modified By', accessor: 'MODIF_BY', row: 2, col: 3, editable: false }
];

const readingList = {
	query: { entity: 'LIQUID_AIR.GN2_METER_READINGS_VW', order: 'READING_DATE desc',
		search: { Attribute: 'METER_ID', Operator: '=', LongValue: null }},
	pageSize: 20,
	columns: [
		{ Header: 'Read Date', accessor: 'READING_DATE_DISPLAY', width: 125 },
		{ Header: 'Read Value', accessor: 'NEW_AMOUNT', width: 125 }
	]
};

const exportColumns = [
	{ Header: 'Read Date', accessor: 'READING_DATE_DISPLAY', width: 25 },
	{ Header: 'Read Value', accessor: 'NEW_AMOUNT', width: 25 }
];


export default withRouter(GN2Meter);
