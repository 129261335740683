import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class RechargeRates extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			RCH_CODE: '',
			SERVICE_NAME: '',
			LABOR_LABEL: '',
			ACTIVE_FLAG: 'Yes'
		};
		
		// initialize dry ice list
		var initialRechargeRatesList = this.copyTable(rechargeRatesList);
		initialRechargeRatesList.query.search = this.constructSearch(search);
		
		// remove add/edit column if not authorized
		if (props.user.crs.userAccessID !== 1) {
			initialRechargeRatesList.columns.splice(0, 1);
		}
		
		this.state = {
			renderKey: 1,
			rechargeRatesList: initialRechargeRatesList,
			search: search,
			searchCount: null
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newRechargeRatesList = this.copyTable(rechargeRatesList);
		newRechargeRatesList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			rechargeRatesList: newRechargeRatesList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.RCH_CODE)) search.Children.push({ Attribute: 'RCH_CODE', Operator: 'like', StringValue: '%' + data.RCH_CODE + '%' });
		if (!this.isEmpty(data.SERVICE_NAME)) search.Children.push({ Attribute: 'SERVICE_NAME', Operator: 'like', StringValue: '%' + data.SERVICE_NAME + '%' });		
		if (!this.isEmpty(data.LABOR_LABEL)) search.Children.push({ Attribute: 'LABOR_LABEL', Operator: 'like', StringValue: '%' + data.LABOR_LABEL + '%' });
		if (data.ACTIVE_FLAG === 'Yes') {
			search.Children.push({ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' });
		} else if (data.ACTIVE_FLAG === 'No') {
			search.Children.push({ Operator: 'or', Children: [
				{ Attribute: 'ACTIVE_FLAG', Operator: '!=', StringValue: 'Y' },
				{ Attribute: 'ACTIVE_FLAG', Operator: 'is null' }
			]});
		}
		
		if (search.Children.length === 0) return null;
		return search;
	}
	
	addRechargeRate() {
		this.props.navigate('/CRS/RechargeRates/new');
	}
	
	editRechargeRate(jobId) {
		this.props.navigate('/CRS/RechargeRates/' + jobId);
	}
	
	onTableSort(newOrder) {
		var newRechargeRatesList = this.copyTable(this.state.rechargeRatesList);
		newRechargeRatesList.query.order = newOrder;
		
		this.mergeState({
			rechargeRatesList: newRechargeRatesList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
	}
	
	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Recharge Rates',
			columns: exportColumns,
			entity: 'RECHARGES.SHOPS_RATES_VW',
			search: this.constructSearch(this.state.search),
			order: 'SERVICE_NAME'
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Recharge Rates</span>
					<ChemEdit parent={this} columns={rechargeRatesSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={4} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_RechargeRates' table={this.state.rechargeRatesList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='235px' onTableSort={(newOrder) => this.onTableSort(newOrder)} />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const rechargeRatesSearch = [
	{ Header: 'Code', accessor: 'RCH_CODE', row: 0, col: 0 },
	{ Header: 'Service', accessor: 'SERVICE_NAME', row: 0, col: 1 },
	{ Header: 'Label', accessor: 'LABOR_LABEL', row: 1, col: 0 },
	{ Header: 'Active', accessor: 'ACTIVE_FLAG', type: 'select', row: 1, col: 1,
		options: [{ value: 'No', label: 'No'}, { value: 'Yes', label: 'Yes' }, { value: 'All', label: 'All' }]}
];

const rechargeRatesList = {
	query: { entity: 'RECHARGES.SHOPS_RATES_VW', search: null, order: 'SERVICE_NAME' },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addRechargeRate()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editRechargeRate(props.row.values.RCH_ID)} />, width: 25 },
		{ Header: 'ID', accessor: 'RCH_ID', width: 60 },
		{ Header: 'Code', accessor: 'RCH_CODE', width: 60 },
		{ Header: 'Service', accessor: 'SERVICE_NAME', width: 150 },
		{ Header: '$/h', accessor: 'COSTPERH', width: 60 },
		{ Header: '% Disc.', accessor: 'DISCOUNT', width: 60 },
		{ Header: 'Alt. Disc.', accessor: 'ALT_DISCOUNT', width: 80 },
		{ Header: 'Statement Label', accessor: 'LABOR_LABEL', width: 300 },
		{ Header: 'Mat. Rate', accessor: 'MATERIAL_RATE', width: 80 },
		{ Header: 'Unit', accessor: 'RCH_UNIT', width: 80 },
		{ Header: props => <>Conversion<br />to Unit</>, accessor: 'CONVERSION', width: 100 },
		{ Header: props => <>Outside Rate<br />Multiplier</>, accessor: 'OUTSIDE_RATE', width: 100 },
		{ Header: 'Beg Date', accessor: 'START_VALID', width: 100 },
		{ Header: 'End Date', accessor: 'END_VALID', width: 100 },
		{ Header: 'Modified', accessor: 'MODIF_DATE', width: 100 }
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'RCH_ID', width: 12 },
	{ Header: 'Code', accessor: 'RCH_CODE', width: 12 },
	{ Header: 'Service', accessor: 'SERVICE_NAME', width: 30 },
	{ Header: '$/h', accessor: 'COSTPERH', width: 12, format: '#,##0.00' },
	{ Header: '% Disc.', accessor: 'DISCOUNT', width: 12, format: '#,##0.00' },
	{ Header: 'Alt. Disc.', accessor: 'ALT_DISCOUNT', width: 16, format: '#,##0.00' },
	{ Header: 'Statement Label', accessor: 'LABOR_LABEL', width: 60 },
	{ Header: 'Mat. Rate', accessor: 'MATERIAL_RATE', width: 16, format: '#,##0.00' },
	{ Header: 'Unit', accessor: 'RCH_UNIT', width: 16 },
	{ Header: 'Conversion to Unit', accessor: 'CONVERSION', width: 20, format: '#,##0.00' },
	{ Header: 'Outside Rate Multiplier', accessor: 'OUTSIDE_RATE', width: 20, format: '#,##0.00' },
	{ Header: 'Beg Date', accessor: 'START_VALID', width: 20, format: 'mm/dd/yy' },
	{ Header: 'End Date', accessor: 'END_VALID', width: 20, format: 'mm/dd/yy' },
	{ Header: 'Modified', accessor: 'MODIF_DATE', width: 20, format: 'mm/dd/yy' }
];

export default withRouter(RechargeRates);