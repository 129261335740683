import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { Link } from 'react-router-dom';

class Uploads extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var upload = {
			SERVICE_ID: null,
			FILE: null
		};
		
		// initialize upload list
		var initialUploadList = this.copyUploadList();
		var initialUploadColumns = this.copyObject(uploadColumns);
		
		// if user is a service mgr
		if (props.user.crs.serviceMgr.length > 0) {
			// restrict to service units managed by this user 
			initialUploadList.query.search = { Attribute: 'SERVICE_UNIT', Operator: 'in', LongList: props.user.crs.serviceMgr };
			initialUploadColumns[0].options.search = { Attribute: 'SERVICE_UNIT', Operator: 'in', LongList: props.user.crs.serviceMgr };
		}
		
		this.state = {
			renderKey: 1,
			uploadList: initialUploadList,
			uploadColumns: initialUploadColumns,
			upload: upload,
			searchCount: null
		};
	}
	
	copyUploadList() {
		var uploadListCopy = this.copyObject(uploadList);
		for (var i = 0; i < uploadList.columns.length; i++) {
			if (uploadList.columns[i].Header) {
				uploadListCopy.columns[i].Header = uploadList.columns[i].Header;
			}
			if (uploadList.columns[i].Cell) {
				uploadListCopy.columns[i].Cell = uploadList.columns[i].Cell;
			}
		}
		return uploadListCopy;
	}
	
	onChangeSearch(accessor, value) {
		var upload = this.copyObject(this.state.upload);
		this.setByAccessor(upload, accessor, value);
		this.mergeState({ upload: upload });
	}
	
	onSubmitSearch(event) {
		var self = this;
		
		// get file from state
		var upload = this.state.upload.FILE;
		
		// if there is an upload file
		if (upload && upload.name) {
			// Create an object of formData
			const formData = new FormData();

			// add the token to the formData
			formData.append("__RequestVerificationToken", this.props.user.antiForgeryToken);
		
			// add the upload file with the service ID
			formData.append("upload", upload, this.state.upload.SERVICE_ID);
			
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/CRS/Upload',
				overlay: true,
				data: formData,
				contentType: false,
				processData: false
			}).done(function (data) {
				if (data.Success) {
					self.showConfirmation(() => {
						return (<>
							{data.LinesUploaded} Lines uploaded.<br />
							{data.ServicesNotFound.length > 0 && <>Services not found: {data.ServicesNotFound.join(', ')}<br /></>}
							{data.UsersNotUploaded.length > 0 && <>Users not uploaded: {data.UsersNotUploaded.join(', ')}<br /></>}
							<Link to='/CRS/Uploads'>Return to Uploads</Link><br />
							<Link to={'/CRS/SearchPrelim/' + self.state.upload.SERVICE_ID}>View transactions in Preliminary Journal</Link>
						</>);
					});
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		}
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Uploads',
			columns: exportColumns,
			entity: 'RECHARGES.UPLOADS_VW',
			search: this.isEmpty(this.props.user.crs.serviceMgr) ? null : { Attribute: 'SERVICE_UNIT', Operator: 'in', LongList: this.props.user.crs.serviceMgr },
			order: ['SERVICE_UNIT', 'SERVICE_NAME']
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Uploads</span>
					<ChemEdit parent={this} columns={this.state.uploadColumns} data={this.state.upload} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={3} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="upload" variant="warning" type="submit" className="float-end">Upload xlsx file</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_Uploads' table={this.state.uploadList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='250px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const uploadColumns = [
	{ Header: 'Service', accessor: 'SERVICE_ID', type: 'select', row: 0, col: 0, required: true,
		options: { value: 'SERVICE_ID', label: 'SERVICE_NAME', entity: 'RECHARGES.UPLOAD_SERVICES_VW',
			search: null, order: ['SERVICE_UNIT', 'SERVICE_NAME']}},
	{ Header: 'File to Upload', accessor: 'FILE', type: 'file', row: 0, col: 1, required: true }
];

const uploadList = {
	query: { entity: 'RECHARGES.UPLOADS_VW', search: null, order: ['SERVICE_UNIT', 'SERVICE_NAME'] },
	pageSize: 20,
	columns: [
		{ Header: 'Service', accessor: 'SERVICE_NAME', width: 300 },
		{ Header: 'Lines Uploaded', accessor: 'LINES' },
		{ Header: 'Total Amount', accessor: 'TOTAL_AMOUNT' }
	]
};

const exportColumns = [
	{ Header: 'Service', accessor: 'SERVICE_NAME', width: 50 },
	{ Header: 'Lines Uploaded', accessor: 'LINES', width: 15 },
	{ Header: 'Total Amount', accessor: 'TOTAL_AMOUNT', width: 15 }
];

export default withRouter(Uploads);