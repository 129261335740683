import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemTable from '../ChemTable';


class DataSources extends ChemComponent {
  	constructor(props) {
		super(props);
		
		this.state = {
			renderKey: 1,
			dataSources: []
		};
	}

	componentDidMount() {
		this.loadDataSources();
	}
	
	loadDataSources(pageNumber, pageSize) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Home/GetDataSources',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken }
		}).done(function (data) {
			if (data.Success) {
				// for each person
				for (var i = 0; i < data.DataSources.length; i++) {
					// add an id to the row
					data.DataSources[i].id = i;
					data.DataSources[i].drag = i;
				}
			
				self.mergeState({
					renderKey: self.state.renderKey + 1,
					dataSources: data.DataSources
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	moveRow(personId, nSteps) {
		// not implemented
	}
	
	dropRow() {
		// not implemented
	}
	
	firstPage() {
		if (this.state.pageNumber !== 1) {
			this.loadPeople(1, this.state.pageSize);
		}
	}
	
	onFileChange(event) {
		var file = event.target.files[0];
		// reject files that are too large
		if (file.size > 10000000) file = null; 
		this.mergeState({ selectedFile: file });
	}
	
	previousPage() {
		if (this.state.pageNumber > 1) {
			this.loadPeople(this.state.pageNumber - 1, this.state.pageSize);
		}		
	}
	
	nextPage() {
		// server will correct if page number is too big
		this.loadPeople(this.state.pageNumber + 1, this.state.pageSize);		
	}
	
	lastPage() {
		// use -1 to indicate last page since we don't 
		// necessarily know how many pages there are now
		this.loadPeople(-1, this.state.pageSize);
	}
	
	setPageSize(pageSize) {
		if (pageSize !== this.state.pageSize) {
			this.loadPeople(this.state.pageNumber, pageSize);
		}
	}
	
	setPageNumber(pageNumber) {
		if (pageNumber !== this.state.pageNumber) {
			this.loadPeople(pageNumber, this.state.pageSize);
		}
	}
	
	render() {

		const columns = [
			{ Header: 'Data Source', accessor: 'DataSourceName' },
			{ Header: 'User ID', accessor: 'UserID' },
			{ Header: 'Dev', accessor: 'DevStatus' },
			{ Header: 'Test', accessor: 'TestStatus' },
			{ Header: 'Prod', accessor: 'ProdStatus' }
		];

		return (<>
			<Container fluid>
				<Row>
					<Col>
					  <Container>
					    <Row>
						  <Col xs={10} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
							<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Data Source Status</span>
					      </Col>
						</Row>
					  </Container>
					</Col>
				</Row>
				<Row>
					<Col>
						<ChemTable renderKey={this.state.renderKey} parent={this} name='WebAdmin_DataSources' columns={columns} data={this.state.dataSources} />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

export default DataSources;