import React from 'react';
import { useTable, useBlockLayout, useResizeColumns } from 'react-table';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { ChevronDoubleLeft, ChevronDoubleRight, ChevronLeft, ChevronRight } from 'react-bootstrap-icons';

const shallowCopyColumns = (parent, columns) => {
	var shallowCopy = [];
	
	for (var i = 0; i < columns.length; i++) {
		shallowCopy.push(parent.mergeObject(columns[i], {}));
	}
	
	return shallowCopy;
}

const findColumn = (cols, accessor) => {
	// for each column
	for (var i = 0; i < cols.length; i++) {
		// if this column matches, return the index
		if (cols[i].accessor === accessor) return i;
	}
	
	// column not found
	return -1;
};

const DndTable = ({ parent, name, columns, data, initialState, rowSelected, onClickHeader }) => {
  const [records, setRecords] = React.useState(data);
  const [isDragging, setDragging] = React.useState(false);
  
  if (records !== data && !isDragging) setRecords(data);

  const getRowId = React.useCallback(row => {
    return row.id
  }, []);

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 1000,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
	state: { columnResizing }
	/*, resetResizing */
  } = useTable({
    data: records,
    columns,
    getRowId,
	defaultColumn,
	initialState
  },
  useBlockLayout,
  useResizeColumns);
  
  // Listen for column resizing
  React.useEffect(() => {
    if (columnResizing.isResizingColumn === null) {
		// if there is local storage
		if (typeof(Storage) !== "undefined") {
			// try to access column width info
			var colwidths = localStorage.getItem(name + '_colwidths');
						
			if (colwidths) {
				// convert to JSON
				colwidths = JSON.parse(colwidths);
			} else {
				// nothing saved, create empty Array
				colwidths = [];
			}
				
			// get keys for accessors that have changed width
			var keys = Object.keys(columnResizing.columnWidths);
			
			// for each accessor
			for (var i = 0; i < keys.length; i++) {
				// locate the column
				var colIdx = findColumn(colwidths, keys[i]);
				
				// if the column was found
				if (colIdx >= 0) {
					// set the column width
					colwidths[colIdx].width = columnResizing.columnWidths[keys[i]];
				} else {
					// create a new colwidth record
					colwidths.push({ accessor: keys[i], width: columnResizing.columnWidths[keys[i]] });
				}
			}
			
			// save the modified colwidth Object
			localStorage.setItem(name + '_colwidths', JSON.stringify(colwidths));
		}
    }
  }, [columnResizing, name]);

  const moveRow = (dragIndex, hoverIndex) => {
	setDragging(true);
    const dragRecord = records[dragIndex]
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    )
	parent.moveRow(dragRecord.id, dragIndex - hoverIndex)
  }
  
  const dropRow = () => {
	setDragging(false);  
	parent.dropRow();
  }
  
  const extraHeaderProps = {
	  style: {
		  border: '1px solid #ddd', 
		  display: 'table-cell',
		  padding: '2px',
          overflowWrap: 'anywhere',
		  background: 'white'
	  }
  };

  return (
      <div style={{ display: 'table' }} {...getTableProps()}>
        <div>
          {headerGroups.map(headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <div onClick={(event) => onClickHeader && onClickHeader(event, column.id)} {...column.getHeaderProps(extraHeaderProps)}>{column.align === 'right' ?
				  <div style={{ float: 'right', marginRight: 6, marginBottom: -6 }}>{column.render('Header', { parent: parent })}</div> : column.render('Header', { parent: parent })}
				  <div onClick={(event) => event.stopPropagation()}
                      {...column.getResizerProps({style: {
						display: 'inline-block',
						background: '#fdb515',
						width: '4px',
						height: '100%',
						position: 'absolute',
						right: 0,
						top: 0,
						transform: 'translateX(50%)',
						zIndex: 1,
						touchAction: 'none',
						color: 'black'
					}})} />
				</div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()}>
          {rows.map(
            (row, index) =>
              prepareRow(row) || (
                <DndRow
				  parent={parent}
                  index={index}
                  row={row}
                  moveRow={moveRow}
				  dropRow={dropRow}
				  rowSelected={rowSelected}
                  {...row.getRowProps()}
                />
              )
          )}
        </div>
      </div>
  )
}

const DND_ITEM_TYPE = 'row'

const DndRow = ({ parent, row, index, moveRow, dropRow, rowSelected }) => {
  const dropRef = React.useRef(null)
  const dragRef = React.useRef(null)

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag, preview] = useDrag({
	type: DND_ITEM_TYPE,
    item: () => ({ index }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
	end(item, monitor) {
		dropRow();
	}
  })

  const opacity = isDragging ? 0 : 1

  preview(drop(dropRef))
  drag(dragRef)

  const extraCellProps = {
	  style: {
		  border: '1px solid #ddd', 
		  display: 'table-cell',
		  padding: '2px',
          overflowWrap: 'anywhere',
		  background: '#ffe'
	  }
  };

  return (
    <div ref={dropRef} style={{ opacity }}>
      {row.cells.map(cell => {
		  if (cell.column.drag) {
			return <div onClick={() => { if (rowSelected) rowSelected(row); }} ref={dragRef} {...cell.getCellProps(extraCellProps)}>{cell.column.align === 'right' ?
				<div style={{ float: 'right', marginRight: 6, marginBottom: -6 }}>{cell.render('Cell', { parent: parent })}</div> : cell.render('Cell', { parent: parent })}</div>
	      } else {
			return <div onClick={() => { if (rowSelected) rowSelected(row); }} {...cell.getCellProps(extraCellProps)}>{cell.column.align === 'right' ?
				<div style={{ float: 'right', marginRight: 6, marginBottom: -6 }}>{cell.render('Cell', { parent: parent })}</div> : cell.render('Cell', { parent: parent })}</div>
		  }
      })}
    </div>
  );
}

const Paging = ({ parent, pageNumber, pageSize, pageCount, firstPage, previousPage, nextPage, lastPage, setPageSize, setPageNumber }) => {
	
	const [statePageNumber, setStatePageNumber] = React.useState(pageNumber ? pageNumber : -1);
	const [statePageSize, setStatePageSize] = React.useState(pageSize ? pageSize : -1);
	
	React.useEffect(() => {
		setStatePageNumber(pageNumber);
	}, [pageNumber]);
	
	if ((pageNumber ? pageNumber : -1) < 0 && (pageSize ? pageSize : -1) < 0) {
	  return null;
	} else {
	  return (
		<Row style={{ paddingTop: 20 }}>
		  <Col className='d-flex justify-content-center'>
		    <Form className='d-flex'>
			  <Form.Group style={{ paddingTop: '8px' }}>Page </Form.Group>
			  <Form.Group controlId='pageNumber'>
				<Form.Control type="text" style={{ marginLeft: 5, marginRight: 5, width: 70 }} 
						value={statePageNumber} onChange={(event) => setStatePageNumber(event.target.value)} 
						onBlur={() => setPageNumber ? setPageNumber(statePageNumber) : parent.setPageNumber(statePageNumber)}
						onKeyPress={(event) => { if (event.charCode === 13) { setPageNumber ? setPageNumber(statePageNumber) : parent.setPageNumber(statePageNumber) }}} />
			  </Form.Group>
			  <Form.Group style={{ paddingTop: '8px' }}> of {pageCount}</Form.Group>
				<Button variant='outline-dark' style={{ paddingTop: 3, marginLeft: 10, marginRight: 4 }} onClick={() => firstPage ? firstPage() : parent.firstPage()}>
				  <ChevronDoubleLeft />
				</Button>
				<Button variant='outline-dark' style={{ paddingTop: 3, marginRight: 4 }} onClick={() => previousPage ? previousPage() : parent.previousPage()}>
				  <ChevronLeft />
				</Button>
				<Button variant='outline-dark' style={{ paddingTop: 3, marginRight: 4 }} onClick={() => nextPage ? nextPage() : parent.nextPage()}>
				  <ChevronRight />
				</Button>
				<Button variant='outline-dark' style={{ paddingTop: 3, marginRight: 10 }} onClick={() => lastPage ? lastPage() : parent.lastPage()}>
				  <ChevronDoubleRight />
				</Button>
			  <Form.Group style={{ paddingTop: '8px' }}>Show </Form.Group>
			  <Form.Group controlId='pageSize'>
				<Form.Control type="text" style={{ marginLeft: 5, marginRight: 5, width: 70 }}
						value={statePageSize} onChange={(event) => setStatePageSize(event.target.value)}
						onBlur={() => setPageSize ? setPageSize(statePageSize) : parent.setPageSize(statePageSize)}
						onKeyPress={(event) => { if (event.charCode === 13) { setPageSize ? setPageSize(statePageSize) : parent.setPageSize(statePageSize) }}} />
			  </Form.Group>
			  <Form.Group style={{ paddingTop: '8px' }}> per page</Form.Group>
			</Form>
		  </Col>
		</Row>
	  );
	}
}

const ChemTable = ({ parent, name, columns, data, pageNumber, pageSize, pageCount, offsetHeight, firstPage, previousPage, nextPage, lastPage, setPageSize, setPageNumber, rowSelected, onClickHeader }) => {
		
	// make copy of columns
	var localColumns = shallowCopyColumns(parent, columns);
		
	// if there is local storage
	if (typeof(Storage) !== "undefined") {
		// try to access column width info
		var colwidths = localStorage.getItem(name + '_colwidths');

		// if there was column width info
		if (colwidths) {
			// convert to JSON
			colwidths = JSON.parse(colwidths);
			
			// for each column width
			for (var w = 0; w < colwidths.length; w++) {
				// locate the column
				var colIdx = findColumn(localColumns, colwidths[w].accessor);
				
				// if the column was found, set the width
				if (colIdx >= 0) localColumns[colIdx].width = colwidths[w].width;
			}
		}
	}
	
	// gather hidden column accessors
	var initialState = { hiddenColumns: [] };
	for (var h = 0; h < localColumns.length; h++) {
		if (localColumns[h].show === false) {
			initialState.hiddenColumns.push(localColumns[h].accessor);
		}
	}

	const memoColumns = React.useMemo(() => localColumns, [localColumns]);
	
	return (
	  <Container fluid>
		<Row>
		  <Col style={{ minHeight: '80px', maxHeight: 'calc(100vh ' + (offsetHeight ? ( ' - ' + offsetHeight) : '') + ' - 160px)', overflow: 'auto',
			border: '1px solid #ccc', paddingRight: 0, paddingLeft: 0, marginRight: 0, marginLeft: 0 }}>
		    <DndTable parent={parent} name={name} columns={memoColumns} data={data || []} initialState={initialState} rowSelected={rowSelected} onClickHeader={onClickHeader} />
		  </Col>
		</Row>
		<Paging parent={parent} pageNumber={pageNumber} pageSize={pageSize} pageCount={pageCount}
			firstPage={firstPage} previousPage={previousPage} nextPage={nextPage} lastPage={lastPage} setPageSize={setPageSize} setPageNumber={setPageNumber} />
      </Container>
	);
}

export default ChemTable
