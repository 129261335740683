import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { PencilSquare } from 'react-bootstrap-icons';

class Assignments extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			PI: '',
			RA: '',
			FSA: '',
			MSO: '',
			CF2: ''
		};
		
		// initialize assignment list
		var initialAssignmentList = this.copyTable(assignmentList);
		initialAssignmentList.query.search = this.constructSearch(search);
		
		// remove add/edit column if not authorized
		if (props.user.crs.userAccessID !== 1) {
			initialAssignmentList.columns.splice(0, 1);
		}
		
		this.state = {
			renderKey: 1,
			assignmentList: initialAssignmentList,
			search: search,
			searchCount: null
		};
	}
		
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newAssignmentList = this.copyTable(assignmentList);
		newAssignmentList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			assignmentList: newAssignmentList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.PI)) {
			search.Children.push({
				Operator: 'or', Children: [
					{ Attribute: 'PI_NAME', Operator: 'like', StringValue: '%' + data.PI + '%' },
					{ Attribute: 'EMAIL', Operator: 'like', StringValue: '%' + data.PI + '%' }
				]});
		}
		if (!this.isEmpty(data.RA)) {
			search.Children.push({
				Operator: 'or', Children: [
					{ Attribute: 'RA_NAME', Operator: 'like', StringValue: '%' + data.RA + '%' },
					{ Attribute: 'RA_EMAIL', Operator: 'like', StringValue: '%' + data.RA + '%' }
				]});
		}
		if (!this.isEmpty(data.FSA)) {
			search.Children.push({
				Operator: 'or', Children: [
					{ Attribute: 'FSA_NAME', Operator: 'like', StringValue: '%' + data.FSA + '%' },
					{ Attribute: 'FSA_EMAIL', Operator: 'like', StringValue: '%' + data.FSA + '%' }
				]});
		}
		if (!this.isEmpty(data.MSO)) {
			search.Children.push({ Attribute: 'MSO_NAME', Operator: 'like', StringValue: '%' + data.MSO + '%' });
		}
		if (!this.isEmpty(data.CF2)) {
			search.Children.push({ Attribute: 'CF2', Operator: 'like', StringValue: '%' + data.CF2 + '%' });
		}
		
		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	editAssignment(roleId) {
		this.props.navigate('/CRS/People/' + roleId);		
	}
	
	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Assignments',
			columns: exportColumns,
			entity: 'RECHARGES.PI_ASSIGNMENT_VW',
			search: this.constructSearch(this.state.search),
			order: 'PI_NAME'
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>PI Assignments</span>
					<ChemEdit parent={this} columns={assignmentSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={3} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_Assignments' table={this.state.assignmentList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='240px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const assignmentSearch = [
	{ Header: 'PI', accessor: 'PI', row: 0, col: 0 },
	{ Header: 'RA', accessor: 'RA', row: 0, col: 1 },
	{ Header: 'FSA', accessor: 'FSA', row: 0, col: 2 },
	{ Header: 'MSO', accessor: 'MSO', row: 1, col: 0 },
	{ Header: 'Chartfield2', accessor: 'CF2', row: 1, col: 1 }
];

const assignmentList = {
	query: { entity: 'RECHARGES.PI_ASSIGNMENT_VW', search: null, order: 'PI_NAME' },
	pageSize: 20,
	columns: [
		{ Header: '', accessor: 'ROLE_ID', width: 25,
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editAssignment(props.value)} /> },
		{ Header: 'PI Name', accessor: 'PI_NAME', width: 180 },
		{ Header: 'PI Email', accessor: 'EMAIL', width: 225 },
		{ Header: 'RA Name', accessor: 'RA_NAME', width: 180 },
		{ Header: 'RA Email', accessor: 'RA_EMAIL', width: 225 },
		{ Header: 'FSA Name', accessor: 'FSA_NAME', width: 180 },
		{ Header: 'FSA Email', accessor: 'FSA_EMAIL', width: 225 },
		{ Header: 'Dept MSO', accessor: 'MSO_NAME', width: 180 },
		{ Header: 'Chartfield2', accessor: 'CF2', width: 125 }
	]
};

const exportColumns = [
	{ Header: 'PI Name', accessor: 'PI_NAME', width: 36 },
	{ Header: 'PI Email', accessor: 'EMAIL', width: 45 },
	{ Header: 'RA Name', accessor: 'RA_NAME', width: 36 },
	{ Header: 'RA Email', accessor: 'RA_EMAIL', width: 45 },
	{ Header: 'FSA Name', accessor: 'FSA_NAME', width: 36 },
	{ Header: 'FSA Email', accessor: 'FSA_EMAIL', width: 45 },
	{ Header: 'Dept MSO', accessor: 'MSO_NAME', width: 36 },
	{ Header: 'Chartfield2', accessor: 'CF2', width: 25 }
];

export default withRouter(Assignments);