import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { PencilSquare, PlusSquare, Upc } from 'react-bootstrap-icons';

class DemCylinders extends ChemComponent {
	constructor(props) {
		super(props);

		var search = {
			CYL_CURRENTLY: null,
			CYL_ID: null,
			GAS_ID: null,
			RENTAL_RATE_ID: null,
			OWNER_ID: null,
			IOC_NUM_IN: '',
			DATE_IN_COLLEGE_FROM: null,
			DATE_IN_COLLEGE_TO: null,
			DATE_OUT_COLLEGE_FROM: null,
			DATE_OUT_COLLEGE_TO: null,
			TAG_NUM_IN: '',
			TAG_NUM_OUT: '',
			REC_NUM_IN: null,
			REC_NUM_OUT: null,
			treeMenuClickCount: 0 // don't modify in this class
		};
		
		var cylinderListInit = this.copyTable(cylinderList);
		
		if (props.search) {
			var propsSearch = this.copyObject(props.search);
			this.mergeObject(search, propsSearch);
			search = propsSearch;
			cylinderListInit.query.search = this.constructSearch(search);
		}

		this.state = {
			cylinderList: cylinderListInit,
			search: search,
			searchCount: null,
			renderKey: 0
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.search && this.state.search.treeMenuClickCount !== this.props.search.treeMenuClickCount) {
			var newCylinderList = this.copyTable(cylinderList);
			newCylinderList.query.search = this.constructSearch(this.props.search);
			this.mergeState({
				cylinderList: newCylinderList,
				search: this.copyObject(this.props.search),
				searchCount: null,
				renderKey: this.state.renderKey + 1
			});
		}
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newCylinderList = this.copyTable(cylinderList);
		newCylinderList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			cylinderList: newCylinderList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}

	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.CYL_CURRENTLY)) search.Children.push({ Attribute: data.CYL_CURRENTLY, Operator: '=', StringValue: 'Y' });
		if (!this.isEmpty(data.CYL_ID)) search.Children.push({ Attribute: 'CYL_ID', Operator: '=', LongValue: data.CYL_ID });
		if (!this.isEmpty(data.GAS_ID)) search.Children.push({ Attribute: 'GAS_ID', Operator: '=', LongValue: data.GAS_ID });
		if (!this.isEmpty(data.RENTAL_RATE_ID)) search.Children.push({ Attribute: 'RENTAL_RATE_ID', Operator: '=', LongValue: data.RENTAL_RATE_ID });
		if (!this.isEmpty(data.OWNER_ID)) search.Children.push({ Attribute: 'OWNER_ID', Operator: '=', LongValue: data.OWNER_ID });
		if (!this.isEmpty(data.IOC_NUM_IN)) search.Children.push({ Attribute: 'IOC_NUM_IN', Operator: '=', StringValue: data.IOC_NUM_IN });
		if (!this.isEmpty(data.DATE_IN_COLLEGE_FROM)) search.Children.push({ Attribute: 'DATE_IN_COLLEGE', Operator: '>=', DateValue: data.DATE_IN_COLLEGE_FROM });
		if (!this.isEmpty(data.DATE_IN_COLLEGE_TO)) search.Children.push({ Attribute: 'DATE_IN_COLLEGE', Operator: '<=', DateValue: data.DATE_IN_COLLEGE_TO });
		if (!this.isEmpty(data.DATE_OUT_COLLEGE_FROM)) search.Children.push({ Attribute: 'DATE_OUT_COLLEGE', Operator: '>=', DateValue: data.DATE_OUT_COLLEGE_FROM });
		if (!this.isEmpty(data.DATE_OUT_COLLEGE_TO)) search.Children.push({ Attribute: 'DATE_OUT_COLLEGE', Operator: '<=', DateValue: data.DATE_OUT_COLLEGE_TO });
		if (!this.isEmpty(data.TAG_NUM_IN)) search.Children.push({ Attribute: 'TAG_NUM_IN', Operator: '=', StringValue: data.TAG_NUM_IN });
		if (!this.isEmpty(data.TAG_NUM_OUT)) search.Children.push({ Attribute: 'TAG_NUM_OUT', Operator: '=', StringValue: data.TAG_NUM_OUT });
		if (!this.isEmpty(data.REC_NUM_IN)) search.Children.push({ Attribute: 'REC_NUM_IN', Operator: '=', StringValue: data.REC_NUM_IN });
		if (!this.isEmpty(data.REC_NUM_OUT)) search.Children.push({ Attribute: 'REC_NUM_OUT', Operator: '=', StringValue: data.REC_NUM_OUT });

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	addCylinder() {
		this.props.navigate('/HeLAD/DemCylinders/new');
	}
	
	editCylinder(cylinderId) {
		this.props.setCylinderEditable(true);
		this.props.navigate('/HeLAD/DemCylinders/' + cylinderId);		
	}

	viewCylinder(cylinderId) {
		this.props.setCylinderEditable(false);
		this.props.navigate('/HeLAD/DemCylinders/' + cylinderId);		
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	renderBarcodeForm(cylinderId) {
		
		var barcodeData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			cylinderid: cylinderId
		});
		
		return (
			<form action={this.getConfig().host + '/HeLAD/DemCylinderBarcode'} method='POST'>
				{barcodeData.map((pdfFormField, index) => {
					return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
				})}
				<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Print Barcode</Popover.Header></Popover>}>
					<input type='image' src={this.getConfig().host + '/Content/Icons/upc.png'} alt="Print Barcode" />
				</OverlayTrigger>
			</form>
		);
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Demurrage Cylinders',
			columns: exportColumns,
			entity: 'DEMURRAGE.DEM_CYLS_VW',
			search: this.constructSearch(this.state.search),
			order: 'MFR_CODE'
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Demurrage Cylinders</span>
						<ChemEdit parent={this} user={this.props.user} columns={cylinderSearch} data={this.state.search}
						  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='HeLAD_DemCylinders' table={this.state.cylinderList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='375px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const cylinderSearch = [
	{ Header: 'Cyl. Currently', accessor: 'CYL_CURRENTLY', type: 'select', row: 0, col: 0,
		options: [{ value: '', label: 'Select...' },{ value: 'FULL_IN_CAGE_FLAG', label: 'Full, in Cage' },
			{ value: 'CHECKED_OUT_FLAG', label: 'Checked Out' },{ value: 'EMPTY_IN_CAGE_FLAG', label: 'Returned (Empty in Cage)' }]},
	{ Header: 'Cylinder ID', accessor: 'CYL_ID', type: 'select', row: 0, col: 1,
		options: { value: 'CYL_ID', label: 'MFR_CODE', entity: 'DEMURRAGE.DEM_CYLS', order: 'MFR_CODE' }},
	{ Header: 'Gas', accessor: 'GAS_ID', type: 'select', row: 0, col: 2,
		options: { value: 'GAS_ID', label: 'GAS_NAME', entity: 'DEMURRAGE.DEM_GASES', order: 'GAS_NAME' }},
	{ Header: 'Rental Rate', accessor: 'RENTAL_RATE_ID', type: 'select', row: 1, col: 0,
		options: { value: 'RENTAL_RATE_ID', label: 'RENTAL_RATE_DESC', entity: 'DEMURRAGE.RENTAL_RATE_VW' }},
	{ Header: 'Vendor', accessor: 'OWNER_ID', type: 'select', row: 1, col: 1,
		options: { value: 'OWNER_ID', label: 'OWNER_NAME', entity: 'DEMURRAGE.DEM_OWNERS', order: 'OWNER_NAME' }},
	{ Header: 'IOC Number', accessor: 'IOC_NUM_IN', type: 'dropdown', row: 1, col: 2,
		options: { value: 'IOC_NUM_IN', label: 'IOC_NUM_IN', entity: 'DEMURRAGE.IOC_NUM_SELECT_VW', order: 'IOC_NUM_IN' }},
	{ Header: 'In College From', accessor: 'DATE_IN_COLLEGE_FROM', type: 'date', row: 2, col: 0 },
	{ Header: 'In College To', accessor: 'DATE_IN_COLLEGE_TO', type: 'date', row: 2, col: 1 },
	{ Header: 'Tag Number In', accessor: 'TAG_NUM_IN', row: 2, col: 2 },
	{ Header: 'Out College From', accessor: 'DATE_OUT_COLLEGE_FROM', type: 'date', row: 3, col: 0 },
	{ Header: 'Out College To', accessor: 'DATE_OUT_COLLEGE_TO', type: 'date', row: 3, col: 1 },
	{ Header: 'Tag Number Out', accessor: 'TAG_NUM_OUT', row: 3, col: 2 },
	{ Header: 'Customer Num In', accessor: 'REC_NUM_IN', type: 'select', row: 4, col: 0,
		options: { value: 'REC_NUM_IN', label: 'REC_NUM_IN', entity: 'DEMURRAGE.REC_NUM_IN_SELECT_VW' }},
	{ Header: 'Customer Num Out', accessor: 'REC_NUM_OUT', type: 'select', row: 4, col: 1,
		options: { value: 'REC_NUM_OUT', label: 'REC_NUM_OUT', entity: 'DEMURRAGE.REC_NUM_OUT_SELECT_VW' }}
];

const cylinderList = {
	query: { entity: 'DEMURRAGE.DEM_CYLS_VW', search: null, order: 'MFR_CODE' },
	pageSize: 20,
	columns: [	
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addCylinder()} />, accessor: 'id', width: 25, 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editCylinder(props.row.values.CYL_ID)} /> },
		{ Header: props => <Upc />, accessor: 'drag', width: 25,
			Cell: props => props.parent.renderBarcodeForm(props.row.values.CYL_ID) },
		{ Header: 'ID', accessor: 'CYL_ID', width: 80,
			Cell: props => <HashLink onClick={() => props.parent.viewCylinder(props.value)}>{props.value}</HashLink> },
		{ Header: 'MFR Code', accessor: 'MFR_CODE', width: 200, },
		{ Header: "Rec'd", accessor: 'DATE_IN_COLLEGE_DISPLAY', width: 100, },
		{ Header: 'Gas', accessor: 'GAS_NAME', width: 200 },
		{ Header: 'Rental Rate', accessor: 'RENTAL_DESC', width: 300 },
		{ Header: 'Owner', accessor: 'OWNER_NAME', width: 150 }
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'CYL_ID', width: 16 },
	{ Header: 'MFR Code', accessor: 'MFR_CODE', width: 40, },
	{ Header: "Rec'd", accessor: 'DATE_IN_COLLEGE_DISPLAY', width: 20, },
	{ Header: 'Gas', accessor: 'GAS_NAME', width: 40 },
	{ Header: 'Rental Rate', accessor: 'RENTAL_DESC', width: 60 },
	{ Header: 'Owner', accessor: 'OWNER_NAME', width: 30 }
];

export default withRouter(DemCylinders);
