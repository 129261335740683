import ChemComponent from './ChemComponent';
import { Button } from 'react-bootstrap';
import { Backspace } from 'react-bootstrap-icons';

class NumPad extends ChemComponent {
	render() {
		return(
			<table>
				<tbody>
					<tr>
						<td><Button style={buttonStyle} id="btn_7" variant="warning" type="button" onClick={(event) => this.props.onChange(this.props.value + "7")}>7</Button></td>
						<td><Button style={buttonStyle} id="btn_8" variant="warning" type="button" onClick={(event) => this.props.onChange(this.props.value + "8")}>8</Button></td>
						<td><Button style={buttonStyle} id="btn_9" variant="warning" type="button" onClick={(event) => this.props.onChange(this.props.value + "9")}>9</Button></td>
					</tr>
					<tr>
						<td><Button style={buttonStyle} id="btn_4" variant="warning" type="button" onClick={(event) => this.props.onChange(this.props.value + "4")}>4</Button></td>
						<td><Button style={buttonStyle} id="btn_5" variant="warning" type="button" onClick={(event) => this.props.onChange(this.props.value + "5")}>5</Button></td>
						<td><Button style={buttonStyle} id="btn_6" variant="warning" type="button" onClick={(event) => this.props.onChange(this.props.value + "6")}>6</Button></td>
					</tr>
					<tr>
						<td><Button style={buttonStyle} id="btn_1" variant="warning" type="button" onClick={(event) => this.props.onChange(this.props.value + "1")}>1</Button></td>
						<td><Button style={buttonStyle} id="btn_2" variant="warning" type="button" onClick={(event) => this.props.onChange(this.props.value + "2")}>2</Button></td>
						<td><Button style={buttonStyle} id="btn_3" variant="warning" type="button" onClick={(event) => this.props.onChange(this.props.value + "3")}>3</Button></td>
					</tr>
					<tr>
						<td><Button style={buttonStyle} id="btn_0" variant="warning" type="button" onClick={(event) => this.props.onChange(this.props.value + "0")}>0</Button></td>
						<td><Button style={buttonStyle} id="btn_dot" variant="warning" type="button" onClick={(event) => this.props.onChange(this.props.value + ".")}>.</Button></td>
						<td><Button style={buttonStyle} id="btn_delete" variant="warning" type="button" onClick={(event) => { if (!this.isEmpty(this.props.value)) {
							this.props.onChange(this.props.value.substring(0, this.props.value.length - 1))}}}><Backspace /></Button></td>
					</tr>
				</tbody>
			</table>
		);
	}
}

const buttonStyle = {
	width: '64px',
	height: '64px'
};

export default NumPad;
