import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';
import ChemTable from '../ChemTable';
import { Trash } from 'react-bootstrap-icons';


class MyRequests extends ChemComponent {
  	constructor(props) {
		super(props);
		
		this.state = {
			renderKey: 1,
			requestIDs: [],
			requests: {},
			buildings: [],
			rooms: {},
			locations: [],
			submitterID: ''
		};
	}

	componentDidMount() {
		this.loadRequests();
	}
		
	loadRequests() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/BuildingAccess/GetRequests',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, personid: this.props.user.personID }
		}).done(function (data) {
			if (data.Success) {
				var defaultBuilding = data.Buildings.length > 0 ? data.Buildings[0].BUILDING_ID : null;
				var bldgStateID = defaultBuilding ? self.getBuildingStateID(defaultBuilding) : null;
				var defaultRoom = (bldgStateID && data.Rooms[bldgStateID] && data.Rooms[bldgStateID].length > 0) ?
					data.Rooms[bldgStateID][0].ROOM_ID : null;
				var defaultLocation = data.Locations.length > 0 ? data.Locations[0].LOC_ACCESS_ID : null;
				
				// for each request
				var requests = {};
				for (var i = 0; i < data.RequestIDs.length; i++) {
					// get request ID
					var requestID = data.RequestIDs[i];
					
					// copy request view to new object
					requests[requestID] = data.Requests[requestID].RequestView;
					
					// add Keys array
					requests[requestID].Keys = data.Requests[requestID].Keys;
					
					// for each key
					for (var k = 0; k < requests[requestID].Keys.length; k++) {
						// set id
						requests[requestID].Keys[k].id = k;
						
						// copy the request state id
						requests[requestID].Keys[k].requestStateID = requestID;
					}
					
					// add Access array
					requests[requestID].Access = data.Requests[requestID].Access;
					
					// for each Location
					for (var a = 0; a < requests[requestID].Access.length; a++) {
						// set id
						requests[requestID].Access[a].id = a;
						
						// copy the request state id
						requests[requestID].Access[a].requestStateID = requestID;
					}
					
					// add empty field for denial reason
					requests[requestID].DenialReason = '';
					
					// add fields for currently selected building & roomID
					requests[requestID].selectedBuilding = defaultBuilding;
					requests[requestID].selectedRoom = defaultRoom;
					requests[requestID].selectedLocation = defaultLocation;
					
					// render keys for embedded tables
					requests[requestID].keysRenderKey = 1;
					requests[requestID].accessRenderKey = 1;
				}
				
				self.mergeState({
					renderKey: self.state.renderKey + 1,
					requestIDs: data.RequestIDs,
					requests: requests,
					buildings: data.Buildings,
					rooms: data.Rooms,
					locations: data.Locations
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	getRequestStateID(reqRoleID) {
		return 'REQUEST_' + reqRoleID;
	}
	
	getBuildingStateID(buildingID) {
		return 'BUILDING_' + buildingID;
	}
	
	getBuildingName(buildingID) {
		for (var i = 0; i < this.state.buildings.length; i++) {
			if (this.state.buildings[i].BUILDING_ID === buildingID) {
				return this.state.buildings[i].BUILDING_NAME;
			}
		}
		
		return null;
	}
	
	getLocationName(locAccessID) {
		for (var i = 0; i < this.state.locations.length; i++) {
			if (this.state.locations[i].LOC_ACCESS_ID === locAccessID) {
				return this.state.locations[i].LOC_ACCESS_NAME;
			}
		}
		
		return null;
	}
	
	getRoomName(buildingID, roomID) {
		var bldgStateID = this.getBuildingStateID(buildingID);
		
		var rooms = this.state.rooms[bldgStateID];
		
		for (var i = 0; i < rooms.length; i++) {
			if (rooms[i].ROOM_ID === roomID) return rooms[i].ROOM_NAME;
		}
		
		return null;
	}
	
	addAccessRequest(event, requestStateID) {	
		// if we have state for this request
		if (this.state.requests[requestStateID]) {
			// get a reference to the request
			var request = this.state.requests[requestStateID];
			
			// get a copy of the access array
			var access = this.copyObject(request.Access);
			
			// add currently selected location to the list of locations
			access.push({
				id: access.length,
				requestStateID: requestStateID,
				LOC_ACCESS_ID: +request.selectedLocation,
				LOC_ACCESS_NAME: this.getLocationName(+request.selectedLocation)
			});
			
			// update state
			var requests = {};
			requests[requestStateID] = { 
				accessRenderKey: request.accessRenderKey + 1, 
				Access: access
			};
			this.mergeState({
				renderKey: this.state.renderKey + 1,
				requests: requests
			});
		}
	}

	addKeyRequest(event, requestStateID) {	
		// if we have state for this request
		if (this.state.requests[requestStateID]) {
			// get a reference to the request
			var request = this.state.requests[requestStateID];
			
			// get a copy of the keys array
			var keys = this.copyObject(request.Keys);
			
			// add currently selected key to the list of keys
			keys.push({
				id: keys.length,
				requestStateID: requestStateID,
				BUILDING_ID: +request.selectedBuilding,
				BUILDING_NAME: this.getBuildingName(+request.selectedBuilding),
				ROOM_ID: +request.selectedRoom,
				ROOM_NAME: this.getRoomName(+request.selectedBuilding, +request.selectedRoom)
			});
			
			// update state
			var requests = {};
			requests[requestStateID] = { 
				keysRenderKey: request.keysRenderKey + 1, 
				Keys: keys
			};
			this.mergeState({
				renderKey: this.state.renderKey + 1,
				requests: requests
			});
		}
	}
	
	deleteAccessRequest(props) {		
		// get request state ID
		var requestStateID = props.row.values.requestStateID;
		
		// if we have state for this request
		if (this.state.requests[requestStateID]) {
			// get a reference to the request
			var request = this.state.requests[requestStateID];
			
			// get a copy of the access array
			var access = this.copyObject(request.Access);
			
			// for each location
			for (var i = 0; i < access.length; i++) {
				// if the id matches
				if (access[i].id === props.value) {
					// remove from access array
					access.splice(i, 1);
					
					// exit for loop
					break;
				}
			}
			
			// update state
			var requests = {};
			requests[requestStateID] = { 
				accessRenderKey: request.accessRenderKey + 1, 
				Access: access
			};
			this.mergeState({
				renderKey: this.state.renderKey + 1,
				requests: requests
			});
		}
	}

	deleteKeyRequest(props) {		
		// get request state ID
		var requestStateID = props.row.values.requestStateID;
		
		// if we have state for this request
		if (this.state.requests[requestStateID]) {
			// get a reference to the request
			var request = this.state.requests[requestStateID];
			
			// get a copy of the keys array
			var keys = this.copyObject(request.Keys);
			
			// for each key
			for (var i = 0; i < keys.length; i++) {
				// if the id matches
				if (keys[i].id === props.value) {
					// remove from keys array
					keys.splice(i, 1);
					
					// exit for loop
					break;
				}
			}
			
			// update state
			var requests = {};
			requests[requestStateID] = { 
				keysRenderKey: request.keysRenderKey + 1, 
				Keys: keys
			};
			this.mergeState({
				renderKey: this.state.renderKey + 1,
				requests: requests
			});
		}
	}
	
	validateRequest(event, columns, data) {
		// make a copy of the columns
		var columnsCopy = this.copyObject(columns);
		
		// get submitter ID
		var submitterID = '';
		if (event && event.nativeEvent && event.nativeEvent.submitter) {
			submitterID = event.nativeEvent.submitter.id;
		} else {
			submitterID = this.state.submitterID;
		}
			
		// if this is not a denial
		if (submitterID !== 'deny') {
			// for each column
			for (var i = 0; i < columnsCopy.length; i++) {
				// if this is the reason for denial column
				if (columnsCopy[i].accessor === 'DenialReason') {
					// turn off requirement
					columnsCopy[i].required = false;
					break;
				}
			}
		}
		
		// validate as usual
		return this.validate(columnsCopy, data);
	}
	
	onClickDeny() {
		this.mergeState({
			submitterID: 'deny'
		});
	}
	
	onClickApprove() {
		this.mergeState({
			submitterID: 'approve'
		});
	}
	
	onClickSave() {
		this.mergeState({
			submitterID: 'save'
		});
	}
	
	copyRequest(request) {
		var newRequest = this.copyObject(request);
		newRequest.SafetyTraining = request.SafetyTraining;
		return newRequest;
	}
		
	onChange(reqRoleID, accessor, value) {
		var reqStateID = this.getRequestStateID(reqRoleID);
		if (this.state.requests[reqStateID]) {
			var requests = {};
			requests[reqStateID] = this.copyRequest(this.state.requests[reqStateID]);
			this.setByAccessor(requests[reqStateID], accessor, value);
			this.mergeState({ requests: requests });
		} else {
			this.showAlert("Request Not Found", "No request found for ID " + reqRoleID);
		}
	}
	
	onSubmit(reqRoleID, event) {
		var approve = null;
		
		// determine what we are doing
		if (event && event.nativeEvent && event.nativeEvent.submitter) {
			if (event.nativeEvent.submitter.id === 'approve') {
				approve = true;
			} else if (event.nativeEvent.submitter.id === 'deny') {
				approve = false;
			}
		} else {
			if (this.state.submitterID === 'approve') {
				approve = true;
			} else if (this.state.submitterID === 'deny') {
				approve = false;
			}
		}
		
		// get data from state instead of args
		var reqStateID = this.getRequestStateID(reqRoleID);
		if (this.state.requests[reqStateID]) {
			var data = this.copyRequest(this.state.requests[reqStateID]);
		
			// convert data back to format that can be sent to server
			var request = {
				Access: data.Access,
				DenialReason: data.DenialReason,
				Keys: data.Keys,
				RequestView: data
			};
			
			// get safety certificate
			var safety = data.SafetyTraining;
			
			// remove old refs
			delete request.RequestView.Access;
			delete request.RequestView.DenialReason;
			delete request.RequestView.Keys;
			delete request.RequestView.SafetyTraining;
			
			// convert safety flag
			request.RequestView.SAFETY_FLAG = request.RequestView.SAFETY_FLAG ? 'Y' : null;
			
			// send to server
			var self = this;
			
			// clear submitter ID
			this.mergeState({
				submitterID: ''
			}, () => {
				self.ajax({
					type: 'post',
					url: self.getConfig().host + '/BuildingAccess/UpdateRequest',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, request: request, approve: approve }
				}).done(function (data) {
					if (data.Success) {
						self.updateSafetyCertificate(safety, approve, request.RequestView.CAL_UID + ' ' + request.RequestView.NAME, () => {
							var action = (approve === null) ? 'updated' : (approve ? 'approved' : 'denied');
							self.showAlert('Success', 'Request ' + request.RequestView.REQ_ROLE_ID + ' ' + action, () => {
								if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
								self.loadRequests();
							});
						});
					} else {
						self.showAlert('Server Error', data.Message, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					}
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status, () => {
						if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
					});
				});
			});
		} else {
			this.showAlert("Request Not Found", "No request found for ID " + reqRoleID);
		}
	}

	updateSafetyCertificate(safety, approve, name, callback) {
		var self = this;
		
		// if there is a safety certificate
		if (safety && safety.name && approve) {
			// Create an object of formData
			const formData = new FormData();

			// add the token to the formData
			formData.append("__RequestVerificationToken", this.props.user.antiForgeryToken);
		
			// add the safety certificate
			formData.append("safety", safety, name);
			
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/BuildingAccess/UpdateSafetyCertificate',
				data: formData,
				contentType: false,
				processData: false
			}).done(function (data) {
				if (data.Success) {
					if (callback) callback();
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		} else {
			// no certificate to upload
			if (callback) callback();
		}
	}

	renderAccess(props) {
		// get request state ID
		var requestStateID = this.getRequestStateID(this.getByAccessor(props.editor.props.data, 'REQ_ROLE_ID'));
		
		// get reference to request
		var request = this.state.requests[requestStateID];
		
		return (<Container>
		  <Row>
			<Col sm={9} style={{ marginTop: '-8px' }}>
			  <Form.Control as="select" value={request.selectedLocation}
				onChange={(event) => {
					var requests = {};
					requests[requestStateID] = { selectedLocation: event.target.value };
					this.mergeState({ requests: requests });
				}}>
				  {this.state.locations.map((option) => {
					  return (
						  <option key={option.LOC_ACCESS_ID} value={option.LOC_ACCESS_ID}>{option.LOC_ACCESS_NAME}</option>
					  );
				  })}
			  </Form.Control>
			</Col>
			<Col sm={3} style={{ marginTop: '-8px' }}>
			  <Button variant='warning' onClick={(event) => { this.addAccessRequest(event, requestStateID) }}>Add</Button>
			</Col>
		  </Row>
		  <Row>
		    <Col sm={12} style={{ marginTop: '8px' }}>
		      <ChemTable renderKey={request.accessRenderKey} parent={this} name='BuildingAccess_MyRequests_Access' columns={accessColumns} data={props.value} />
			</Col>
		  </Row>
		</Container>);
	}
	
	renderKeys(props) {
		// get request state ID
		var requestStateID = this.getRequestStateID(this.getByAccessor(props.editor.props.data, 'REQ_ROLE_ID'));
		
		// get reference to request
		var request = this.state.requests[requestStateID];
		
		var rooms;
		
		// if a building has been selected
		if (request.selectedBuilding) {
			// get building state ID
			var bldgStateID = this.getBuildingStateID(request.selectedBuilding);
		
			// get list of rooms for this building
			rooms = this.state.rooms[bldgStateID];
		} else {
			// no building, no rooms
			rooms = [];
		}
		
		return (<Container>
		  <Row>
			<Col sm={5} style={{ marginTop: '-8px' }}>
			  <Form.Control as="select" value={request.selectedBuilding}
				onChange={(event) => {
					// get building state ID
					var bldgStateID = this.getBuildingStateID(event.target.value);
				
					// get list of rooms for this building
					rooms = this.state.rooms[bldgStateID];
					
					// select first room by default
					var requests = {};
					requests[requestStateID] = { 
						selectedBuilding: event.target.value,
						selectedRoom: rooms[0].ROOM_ID
					};
					this.mergeState({ requests: requests });
				}}>
				  {this.state.buildings.map((option) => {
					  return (
						  <option key={option.BUILDING_ID} value={option.BUILDING_ID}>{option.BUILDING_NAME}</option>
					  );										
				  })}
			  </Form.Control>
			</Col>
			<Col sm={4} style={{ marginTop: '-8px' }}>
			  <Form.Control as="select" value={request.selectedRoom}
				onChange={(event) => {
					var requests = {};
					requests[requestStateID] = { selectedRoom: event.target.value };
					this.mergeState({ requests: requests });
				}}>
				  {rooms.map((option) => {
					  return (
						  <option key={option.ROOM_ID} value={option.ROOM_ID}>{option.ROOM_NAME}</option>
					  );										
				  })}
			  </Form.Control>
			</Col>
			<Col sm={3} style={{ marginTop: '-8px' }}>
			  <Button variant='warning' onClick={(event) => { this.addKeyRequest(event, requestStateID) }}>Add</Button>
			</Col>
		  </Row>
		  <Row>
		    <Col sm={12} style={{ marginTop: '8px' }}>
		      <ChemTable renderKey={request.keysRenderKey} parent={this} name='BuildingAccess_MyRequests_Keys' columns={keyColumns} data={props.value} />
			</Col>
		  </Row>
		</Container>);
	}

	renderRequests() {
		// create array of requests
		var requests = [];
		for (var i = 0; i < this.state.requestIDs.length; i++) {
			var requestID = this.state.requestIDs[i];
			if (this.state.requests[requestID]) requests.push(this.state.requests[requestID]);
		}

		if (requests.length < 1) {
			return (
			  <Row>
			    <Col xs={12} sm={11} md={10} lg={9} xl={8} style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Welcome Back {this.props.user.name}!</span>
				  <ul>
					<li>There are no requests to approve.</li>
				  </ul>
			    </Col>
			  </Row>
			)
		} else {
			var plural = requests.length > 1 ? 's' : '';
			return (<>
			  <Row>
			    <Col xs={12} sm={11} md={10} lg={9} xl={8} style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Welcome Back {this.props.user.name}!</span>
				  <ul>
					<li>(<span style={{ fontWeight: 'bold' }}>{requests.length}</span>) Appointment Request{plural} Pending Approval</li>
					<ol>
					{requests.map((request, index) => {
						return (
							<li key={index}><a href={'#' + request.REQ_ROLE_ID}>{request.FNAME} {request.LNAME}</a></li>
						);
					})}
					</ol>
					<li>Building access will be entered 1-2 business days by Building Management after approval</li>
					<li>Keys have to be picked up in person from Building Management</li>
					<li>If you do not find the work or payroll title that you need, please contact Building Management</li>
					<li>Required Field <span style={{ color: 'red' }}>*</span></li>
				  </ul>
			    </Col>
			  </Row>
			  {requests.map((request, index) => {
				  return (
			  <Row key={index}>
			    <Col xs={12} sm={11} md={10} lg={9} xl={8} style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
					<ChemEdit parent={this} columns={requestColumns} data={request} user={this.props.user}
						renderKey={this.state.renderKey} validate={(event, columns, data) => this.validateRequest(event, columns, data)}
						onChange={(accessor, value) => this.onChange(request.REQ_ROLE_ID, accessor, value)} onSubmit={(event) => this.onSubmit(request.REQ_ROLE_ID, event)}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={12}>
						  <Button id="deny" variant="warning" type="submit" className="float-end" onClick={() => this.onClickDeny()} style={{ marginLeft: '2px', marginRight: '2px' }}>Deny</Button>
						  <Button id="approve" variant="warning" type="submit" className="float-end" onClick={() => this.onClickApprove()} style={{ marginLeft: '2px', marginRight: '2px' }}>Save & Approve</Button>				
						  <Button id="save" variant="warning" type="submit" className="float-end" onClick={() => this.onClickSave()} style={{ marginLeft: '2px', marginRight: '2px' }}>Save</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
			    </Col>
			  </Row>
				  );
			  })}
			</>);
		}		
	}
	
	render() {
		return (
			<Container fluid>
				{this.renderRequests()}
			</Container>
		);
	}
}

const requestColumns = [
	{ Header: 'Request ID', accessor: 'REQ_ROLE_ID', type: 'text', editable: false },
	{ Header: 'Name', accessor: 'NAME', Cell: props => <span style={{ fontWeight: 'bold' }}>{props.value}</span> },
	{ Header: 'Cal1 Card ID', accessor: 'CARD_ID', editable: false },
	{ Header: 'Appointment Type', accessor: 'TYPE_ID', type: 'select', required: true,
		options: { value: 'TYPE_ID', label: 'TYPE_NAME', entity: 'ROLE_TYPES', order: 'TYPE_NAME',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'PI/Sponsor', accessor: 'PI_ROLE', type: 'select',
		options: { value: 'ROLE_ID', label: 'PI_NAME', entity: 'REQUESTS_PI_VW', order: 'PI_NAME' } },
	{ Header: 'Default Speedtype', accessor: 'SPEEDTYPE', type: 'select',
		options: { value: 'SPEEDTYPE', label: 'SPEEDTYPE_INFO', entity: 'ROLE_SPEEDTYPES_VW', order: 'SPEEDTYPE_INFO',
			search: { Attribute: 'ROLE_ID', Operator: '=', LongAccessor: 'PI_ROLE' }}},
	{ Header: 'Primary Department', accessor: 'DEPT_ID', required: true, type: 'select',
		options: { value: 'DEPARTMENT_ID', label: 'DEPT_NAME', entity: 'DEPARTMENT', order: 'DEPT_NAME' } },
	{ Header: 'Secondary Department', accessor: 'DEPT2_ID', type: 'select',
		options: { value: 'DEPARTMENT_ID', label: 'DEPT_NAME', entity: 'DEPARTMENT', order: 'DEPT_NAME' } },
	{ Header: 'Functional Supervisor', accessor: 'SUPERVISOR', type: 'select',
		subscript: '<span style="font-size: 12px; font-style: italic">(For Staff Appointment Only)</span>',
		options: { value: 'ROLE_ID', label: 'SUP_NAME', entity: 'REQUESTS_SUPERVISORS_VW', order: 'SUP_NAME' } },
	{ Header: 'Unit', accessor: 'UNIT_ID', type: 'select',
		subscript: '<span style="font-size: 12px; font-style: italic">(For Staff Appointment Only)</span>',
		options: { value: 'UNIT_ID', label: 'UNIT_NAME', entity: 'ORG_UNIT',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }, order: 'UNIT_NAME' } },
	{ Header: 'Directory Work Title', accessor: 'WORK_TITLE', type: 'select',
		subscript: '<span style="font-size: 12px; font-style: italic">(For Staff Appointment Only)</span>',
		options: { value: 'WORK_TITLE_ID', label: 'WORK_TITLE_NAME', entity: 'WORK_TITLE',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }, order: 'WORK_TITLE_NAME' } },			
	{ Header: 'Payroll Title', accessor: 'PAYROLL_TITLE', type: 'select',
		options: { value: 'TITLE_ID', label: 'TITLE_NAME', entity: 'PAYROLL_TITLE',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }, order: 'TITLE_NAME' } },
	{ Header: 'Appointment Start Date', accessor: 'START_DATE', type: 'date',
		subscript: '<span style="font-size: 12px; font-style: italic">MM/DD/YY</span>' },
	{ Header: 'Non-Career End Date', accessor: 'END_DATE', type: 'date',
		subscript: '<span style="font-size: 12px; font-style: italic">MM/DD/YY</span>' },
	{ Header: 'Campus Email', accessor: 'EMAIL', type: 'text' },
	{ Header: 'Primary Phone', accessor: 'C_PHONE', type: 'text' },
	{ Header: 'Secondary Phone', accessor: 'C_PHONE2', type: 'text' },
	{ Header: 'Campus Office Location', accessor: 'C_OFF_ROOM_ID', required: true, type: 'select',
		options: { value: 'ROOM_ID', label: 'LOCATION', entity: 'ROOMS_VW', order: 'LOCATION' } },
	{ Header: 'Off Campus Location', accessor: 'OUTSIDE_LOC', type: 'text' },
	{ Header: 'Campus Lab Room', accessor: 'C_LAB_LOCATION', type: 'text' },
	{ Header: 'Campus Lab Building', accessor: 'C_OFF_BLDG_ID', type: 'select',
		options: { value: 'BUILDING_ID', label: 'BUILDING_NAME', entity: 'BUILDING', order: 'BUILDING_NAME', 
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' } } },
	{ Header: 'Alternate Room', accessor: 'ALT_LOCATION', type: 'text' },
	{ Header: 'Alternate Building', accessor: 'ALT_BUILDING', type: 'select',
		options: { value: 'BUILDING_ID', label: 'BUILDING_NAME', entity: 'BUILDING', order: 'BUILDING_NAME', 
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' } } },	
	{ Header: 'Required Safety Training', accessor: 'SAFETY_FLAG', type: 'checkbox' },
	{ Header: 'Safety Training Date', accessor: 'TRAINING_DATE', type: 'date',
		subscript: '<span style="font-size: 12px; font-style: italic">MM/DD/YY</span>' },
	{ Header: 'Safety Training Certificate', accessor: 'SafetyTraining', type: 'file', accept: 'application/pdf',
        subscript: '<br /><span style="font-size: 12px; font-style: italic">File Accepted: .pdf</span>' },
	{ Header: 'Lab Emergency Phone 1', accessor: 'EMER_PHONE_1', type: 'text',
		subscript: '<span style="font-size: 12px; font-style: italic">(For PI Only)</span>' },
	{ Header: 'Lab Emergency Phone 2', accessor: 'EMER_PHONE_2', type: 'text',
		subscript: '<span style="font-size: 12px; font-style: italic">(For PI Only)</span>' },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea',
		subscript: '<span style="font-size: 12px; font-style: italic">250 Characters Max</span>' },
	{ Header: 'Keys', accessor: 'Keys', Cell: props => props.parent.renderKeys(props) },
	{ Header: 'Access', accessor: 'Access', Cell: props => props.parent.renderAccess(props) },
	{ Header: 'Reason for Denial', accessor: 'DenialReason', type: 'textarea', required: true }
];

const keyColumns = [
	{ Header: '', accessor: 'id', Cell: props => <Trash style={{ cursor: 'pointer' }} onClick={() => props.parent.deleteKeyRequest(props)} />, width: 25 },
	{ Header: 'Building', accessor: 'BUILDING_NAME' },
	{ Header: 'Room', accessor: 'ROOM_NAME' },
	{ Header: 'BUILDING_ID', accessor: 'BUILDING_ID', show: false },
	{ Header: 'ROOM_ID', accessor: 'ROOM_ID', show: false },
	{ Header: 'requestStateID', accessor: 'requestStateID', show: false }
];

const accessColumns = [
	{ Header: '', accessor: 'id', Cell: props => <Trash style={{ cursor: 'pointer' }} onClick={() => props.parent.deleteAccessRequest(props)} />, width: 25 },
	{ Header: 'Location', accessor: 'LOC_ACCESS_NAME' },
	{ Header: 'LOC_ACCESS_ID', accessor: 'LOC_ACCESS_ID', show: false },
	{ Header: 'requestStateID', accessor: 'requestStateID', show: false }
];

export default MyRequests;
