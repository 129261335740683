import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';


class YearToDate extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			BEG_FISCAL_YEAR: null,
			PI_NAME: '',
			SERVICE_ID: null,
			SPEEDTYPE: '',
			EXCLUDE_LABOR: undefined
		};

		this.state = {
			yearToDateList: this.copyTable(yearToDateList),
			search: search,
			searchCount: 0,
			renderKey: 1
		};
	}
	
	componentDidMount() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/Search',
			data: {
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'CURRENT_CYCLE_VW',
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				if (data.Data.length === 1) {
					self.mergeState({ 
						cycleBegDate: self.getDate(data.Data[0].BEG_DATE).toLocaleDateString('en-US', {year:'2-digit', month:'2-digit', day:'2-digit'}),
						cycleEndDate: self.getDate(data.Data[0].END_DATE).toLocaleDateString('en-US', {year:'2-digit', month:'2-digit', day:'2-digit'}),
						renderKey: self.state.renderKey + 1
					});
				} else {
					self.showAlert('Cycle Error', 'Expected 1 current billing cycle but found ' + data.Data.length);
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}

	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		this.mergeState({
			yearToDateList: {
				ajax: {
					data: {
						criteria: {
							BEG_FISCAL_YEAR: this.dateTimeToMVC(this.getDate(this.state.search.BEG_FISCAL_YEAR)),
							PI_NAME: '',
							SERVICE_ID: this.state.search.SERVICE_ID ? this.state.search.SERVICE_ID.join(',') : null,
							SPEEDTYPE: this.state.search.SPEEDTYPE,
							EXCLUDE_LABOR: this.state.search.EXCLUDE_LABOR === true ? true : false
						}
					}
				},
				hold: false
			},
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {

		var exportYearToDateData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			action: 'YearToDate',
			columns: yearToDateExportColumns,
			criteria: {
				BEG_FISCAL_YEAR: this.dateTimeToMVC(this.getDate(this.state.search.BEG_FISCAL_YEAR)),
				PI_NAME: '',
				SERVICE_ID: this.state.search.SERVICE_ID ? this.state.search.SERVICE_ID.join(',') : null,
				SPEEDTYPE: this.state.search.SPEEDTYPE,
				EXCLUDE_LABOR: this.state.search.EXCLUDE_LABOR === true ? true : false
			}
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Year To Date</span>
					<div style={{ paddingBottom: '10px' }}>Current billing cycle {this.state.cycleBegDate} - {this.state.cycleEndDate}</div>
					<ChemEdit parent={this} columns={yearToDateSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={4} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/CRS/Excel'} method='POST'>
								{exportYearToDateData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/CRS/Pdf'} method='POST'>
								{exportYearToDateData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
				  <Col>
				    <QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_YearToDate' table={this.state.yearToDateList}
					  onResult={(data) => this.onSearchResult(data)} offsetHeight='350px' />
				  </Col>
				</Row>
			</Container>
		</>);
	}
}

const yearToDateSearch = [
	{ Header: 'Fiscal Year', accessor: 'BEG_FISCAL_YEAR', type: 'select', row: 0, col: 0, required: true,
		options: { value: 'BEG_FISCAL_YEAR', label: 'FISCAL_YEAR', entity: 'RECHARGES.FISCAL_YEAR_SELECT_VW', order: 'BEG_FISCAL_YEAR desc' }},
	{ Header: 'Service<br /><i>Demurrage will show in statement after 06/30/16</i>', accessor: 'SERVICE_ID', type: 'list', row: 0, col: 1,
		options: { value: 'SERVICE_ID_LIST', label: 'RCH_NAME', entity: 'RECHARGES.STATEMENT_SERVICE_SELECT_VW', order: 'RCH_NAME' }},
	{ Header: 'Speedtype', accessor: 'SPEEDTYPE', row: 1, col: 0, subscript: 'Use % for wild card' },
	{ Header: 'Exclude Shops Non-Productive Labor Hours', accessor: 'EXCLUDE_LABOR', type: 'checkbox', row: 1, col: 1 } 
];

const yearToDateList = {
	ajax: {
		url: '/CRS/YearToDate',
		accessor: 'Data',
		data: {
			criteria: {
				BEG_FISCAL_YEAR: null,
				PI_NAME: '',
				SERVICE_ID: null,
				SPEEDTYPE: '',
				EXCLUDE_LABOR: false
			}
		}
	},
	pageSize: 20,
	hold: true,
	columns: [
		{ Header: 'Service', accessor: 'SERVICE_NAME', width: 250 },
		{ Header: 'Billed', accessor: 'DATE_BILLED' },
		{ Header: 'Labor Hours', accessor: 'LABOR_HOURS',
			Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>{props.value}</div> },
		{ Header: 'Labor Rate', accessor: 'COSTPERH',
			Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>{props.value}</div> },
		{ Header: 'Labor Gross', accessor: 'LABOR_GROSS',
			Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>{props.value}</div> },
		{ Header: 'Labor Discount', accessor: 'DISCOUNT',
			Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>{props.value}</div> },
		{ Header: 'Labor Total', accessor: 'LABOR_TOTAL',
			Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>{props.value}</div> },
		{ Header: 'Qty', accessor: 'MATERIAL_COST',
			Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>{props.value}</div> },
		{ Header: 'Unit Cost', accessor: 'MATERIAL_RATE',
			Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>{props.value}</div> },
		{ Header: 'Total', accessor: 'MATERIAL_TOTAL',
			Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>{props.value}</div> },
		{ Header: 'Total (incl Labor Discount)', accessor: 'TOTAL_PLUS_DISCOUNT',
			Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>{props.value}</div> }
	]
};

const yearToDateExportColumns = [
	{ Header: 'Service', accessor: 'SERVICE_NAME', width: 50 },
	{ Header: 'Billed', accessor: 'DATE_BILLED', width: 25, format: 'yyyy-mm-dd' },
	{ Header: 'Labor Hours', accessor: 'LABOR_HOURS', width: 25, format: '#,##0.00' },
	{ Header: 'Labor Rate', accessor: 'COSTPERH', width: 25, format: '#,##0.00' },
	{ Header: 'Labor Gross', accessor: 'LABOR_GROSS', width: 25, format: '#,##0.00' },
	{ Header: 'Labor Discount', accessor: 'DISCOUNT', width: 25, format: '#,##0.00' },
	{ Header: 'Labor Total', accessor: 'LABOR_TOTAL', width: 25, format: '#,##0.00' },
	{ Header: 'Qty', accessor: 'MATERIAL_COST', width: 25, format: '#,##0.00' },
	{ Header: 'Unit Cost', accessor: 'MATERIAL_RATE', width: 25, format: '#,##0.00' },
	{ Header: 'Total', accessor: 'MATERIAL_TOTAL', width: 25, format: '#,##0.00' },
	{ Header: 'Total (incl Labor Discount)', accessor: 'TOTAL_PLUS_DISCOUNT', width: 25, format: '#,##0.00' }
];

export default withRouter(YearToDate);