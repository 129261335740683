import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class POs extends ChemComponent {
	constructor(props) {
		super(props);
		
		var poListInit = this.copyTable(poList);
		var search = this.copyObject(defaultSearch);
		
		if (props.search) {
			var propsSearch = this.copyObject(props.search);
			this.mergeObject(search, propsSearch);
			poListInit.ajax.data = { search: propsSearch };
		} else {
			search.STATUS_ID = 0;
			poListInit.ajax.data = { search: this.copyObject(search) };
		}

		this.state = {
			poList: poListInit,
			search: search,
			searchCount: null,
			renderKey: 0
		};
	}
	
	componentDidMount() {
		if (this.props.search && !this.objectsAreEqual(this.props.search, this.state.search)) {
			var propsSearch = this.copyObject(this.props.search);
			this.mergeObject(defaultSearch, propsSearch);
			this.onSubmitSearch(null, propsSearch);
		}
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event, search) {
		var newPOList = this.copyTable(poList);
		newPOList.ajax.data = {
			search: this.copyObject(search)
		};
		this.mergeState({ 
			poList: newPOList,
			search: this.copyObject(search),
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	addPO() {
		this.props.navigate('/Stores/POs/new');
	}
	
	editPO(poId) {
		this.props.setPOEditable(true);
		this.props.navigate('/Stores/POs/' + poId);
	}

	viewPO(poId) {
		this.props.setPOEditable(false);
		this.props.navigate('/Stores/POs/' + poId);
	}
	
	receivePO(poId) {
		this.props.navigate('/Stores/Receive/' + poId);
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			Action: 'POs',
			columns: exportColumns,
			search: this.state.search
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>POs</span>
						<ChemEdit parent={this} user={this.props.user} columns={poSearch} data={this.state.search} autoSubmit={true}
						  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)}
						  onSubmit={(event) => this.onSubmitSearch(event, this.state.search)} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Stores/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Stores/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event, this.state.search)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='Stores_POs' table={this.state.poList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='340px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const defaultSearch = {
	PO_NUMBER: '',
	CART_ID: '',
	VENDOR_NAME: '',
	CATALOG_NUMBER: '',
	PLACED_FROM: null,
	PLACED_TO: null,
	DESCRIPTION: '',
	UPDATED_FROM: null,
	UPDATED_TO: null,
	REFERENCE: '',
	COMMENTS: '',
	STATUS_ID: null,
};

const poSearch = [
	{ Header: 'PO Number', accessor: 'PO_NUMBER', row: 0, col: 0 },
	{ Header: 'Cart ID', accessor: 'CART_ID', row: 0, col: 1 },
	{ Header: 'Vendor Name', accessor: 'VENDOR_NAME', row: 0, col: 2 },
	{ Header: 'Catalog Number', accessor: 'CATALOG_NUMBER', row: 1, col: 0 },
	{ Header: 'Placed From', accessor: 'PLACED_FROM', type: 'date', row: 1, col: 1 },
	{ Header: 'Placed To', accessor: 'PLACED_TO', type: 'date', row: 1, col: 2 },
	{ Header: 'Item Description', accessor: 'DESCRIPTION', row: 2, col: 0 },
	{ Header: 'Updated From', accessor: 'UPDATED_FROM', type: 'date', row: 2, col: 1 },
	{ Header: 'Updated To', accessor: 'UPDATED_TO', type: 'date', row: 2, col: 2 },
	{ Header: 'Reference', accessor: 'REFERENCE', row: 3, col: 0 },
	{ Header: 'Comments', accessor: 'COMMENTS', row: 3, col: 1 },
	{ Header: 'Status', accessor: 'STATUS_ID', type: 'select', row: 3, col: 2,
		options: { value: 'STATUS_ID', label: 'STATUS_NAME', entity: 'STORES.STATUS_SELECT_VW' }
	},
];

const poList = {
	ajax: { 
		url: '/Stores/POSearch',
		data: null,
		accessor: 'Data'
	},
	pageSize: 20,
	columns: [	
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addPO()} />, accessor: 'id', width: 25, 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editPO(props.row.values.PO_ID)} /> },
		{ Header: 'Receive', accessor: 'drag', width: 75,
			Cell: props => <HashLink onClick={() => props.parent.receivePO(props.row.values.PO_ID)}>Receive</HashLink> },
		{ Header: 'ID', accessor: 'PO_ID', width: 70,
			Cell: props => <HashLink onClick={() => props.parent.viewPO(props.value)}>{props.value}</HashLink> },
		{ Header: 'Purchase Order', accessor: 'PO_NUMBER', width: 125 },
		{ Header: 'Cart/Req ID', accessor: 'CART_ID', width: 100 },
		{ Header: 'Vendor', accessor: 'VENDORNAME', width: 250 },
		{ Header: 'Vendor Catalog Number', accessor: 'CAT_NUMBER', width: 150 },
		{ Header: 'COC Stock Number', accessor: 'STOCK_NUMBER', width: 150 },
		{ Header: 'Placed', accessor: 'DATE_PLACED', width: 100 },
		{ Header: 'Reference', accessor: 'REFERENCE', width: 100 },
		{ Header: 'Blanket', accessor: 'BLANKET_FLAG', width: 80 },
		{ Header: 'Status', accessor: 'STATUS_NAME', width: 80 },
		{ Header: 'Update', accessor: 'DATE_MODIF', width: 100 }
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'PO_ID', width: 14 },
	{ Header: 'Purchase Order', accessor: 'PO_NUMBER', width: 25 },
	{ Header: 'Cart/Req ID', accessor: 'CART_ID', width: 20 },
	{ Header: 'Vendor', accessor: 'VENDORNAME', width: 50 },
	{ Header: 'Vendor Catalog Number', accessor: 'CAT_NUMBER', width: 30 },
	{ Header: 'COC Stock Number', accessor: 'STOCK_NUMBER', width: 30 },
	{ Header: 'Placed', accessor: 'DATE_PLACED', width: 20 },
	{ Header: 'Reference', accessor: 'REFERENCE', width: 20 },
	{ Header: 'Blanket', accessor: 'BLANKET_FLAG', width: 16 },
	{ Header: 'Status', accessor: 'STATUS_NAME', width: 16 },
	{ Header: 'Update', accessor: 'DATE_MODIF', width: 20 }
];

export default withRouter(POs);
