import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';


class Billing extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			BEG_FISCAL_YEAR: null,
			MONTH: null,
			PI_ROLE_ID: null,
			SERVICE_ID: null,
			SPEEDTYPE: '',
			STATEMENT_TYPE: null
		};

		var initialBillingSearchColumns = this.copyColumns(billingSearch);
		initialBillingSearchColumns[0].options.search =
			(props.user.crs.raRoleIDs && props.user.crs.raRoleIDs.length > 0) ?
			{
				// user is an RA - separate out their PI's at the top of the list
				Operator: 'or',
				Children: [
					// top of list: PI's that this user is responsible for
					{ Operator: 'and', Children: [
						{ Attribute: 'SORT_ORDER', Operator: '=', IntValue: 1 },
						{ Attribute: 'ROLE_ID', Operator: 'in', LongList: props.user.crs.raRoleIDs }
					]},
					// separator
					{ Attribute: 'SORT_ORDER', Operator: '=', IntValue: 2 },
					// bottom of list: PI's that this user is not responsible for
					{ Operator: 'and', Children: [
						{ Attribute: 'SORT_ORDER', Operator: '=', IntValue: 3 },
						{ Attribute: 'ROLE_ID', Operator: 'not in', LongList: props.user.crs.raRoleIDs }
					]}
				]
			} :
			(props.user.crs.piRoleIDs && props.user.crs.piRoleIDs.length > 0) ?
			// user is not an RA, list only their PI's
			{ Operator: 'and', Children: [
				{ Attribute: 'SORT_ORDER', Operator: '=', IntValue: 1 },
				{ Attribute: 'ROLE_ID', Operator: 'in', LongList: props.user.crs.piRoleIDs }
			]} :
			// anonymous user, show all PI's
			{ Attribute: 'SORT_ORDER', Operator: '=', IntValue: 1 };
		
		// if production, remove statement type so we don't crash the production server
		if (this.isProduction()) initialBillingSearchColumns.splice(5, 1);

		// initialize billing list
		var initialBillingList = this.copyTable(billingList);
		initialBillingList.query.search = this.constructSearch(search);

		this.state = {
			billingSearch: initialBillingSearchColumns,
			billingList: initialBillingList,
			search: search,
			searchCount: 0,
			renderKey: 1
		};
	}
	
	componentDidMount() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/Search',
			data: {
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'CURRENT_CYCLE_VW',
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				if (data.Data.length === 1) {
					self.mergeState({ 
						cycleBegDate: self.getDate(data.Data[0].BEG_DATE).toLocaleDateString('en-US', {year:'2-digit', month:'2-digit', day:'2-digit'}),
						cycleEndDate: self.getDate(data.Data[0].END_DATE).toLocaleDateString('en-US', {year:'2-digit', month:'2-digit', day:'2-digit'}),
						renderKey: self.state.renderKey + 1
					});
				} else {
					self.showAlert('Cycle Error', 'Expected 1 current billing cycle but found ' + data.Data.length);
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}

	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newBillingList = this.copyTable(billingList);
		newBillingList.query.search = this.constructSearch(this.state.search);
		newBillingList.hold = false;
		this.mergeState({ 
			billingList: newBillingList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data, includeStatementType) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};
		
		if (!this.isEmpty(data.BEG_FISCAL_YEAR)) {
			var dtBegFiscalYear = this.getDate(data.BEG_FISCAL_YEAR);
			var dtEndFiscalYear = new Date(dtBegFiscalYear);
			dtEndFiscalYear.setMonth(dtBegFiscalYear.getMonth() + 12);
			search.Children.push({ Attribute: 'DATE_BILLED', Operator: '>=', StringValue: this.formatDateForCompare(dtBegFiscalYear) });
			search.Children.push({ Attribute: 'DATE_BILLED', Operator: '<', StringValue: this.formatDateForCompare(dtEndFiscalYear) });
		}
		if (data.MONTH && data.MONTH.length > 0) {
			search.Children.push({ Attribute: 'MONTH_BILLED', Operator: 'in', IntList: data.MONTH });
		}
		if (data.PI_ROLE_ID && data.PI_ROLE_ID.length > 0) {
			search.Children.push({ Attribute: 'PI_ROLE_ID', Operator: 'in', LongList: data.PI_ROLE_ID });
		}
		if (data.SERVICE_ID && data.SERVICE_ID.length > 0) {
			// join all lists into one list
			search.Children.push({ Attribute: 'SERVICE_ID', Operator: 'in', LongList: data.SERVICE_ID.join(',').split(',') });
		}
		if (!this.isEmpty(data.SPEEDTYPE)) search.Children.push({ Attribute: 'SPEEDTYPE', Operator: 'like', StringValue: data.SPEEDTYPE });
		
		if (includeStatementType && !this.isProduction()) {
			var stmtType = this.isEmpty(data.STATEMENT_TYPE) ? 'New' : data.STATEMENT_TYPE;
			search.Children.push({ Attribute: 'STATEMENT_TYPE', Operator: '=', StringValue: stmtType });
		}
		
		// if nothing was specified, forget it
		if (search.Children.length === 0) return null;
		return search;
	}
	
	formatDateForCompare(date) {
		var d = date.getDate();
		var m = date.getMonth() + 1; //Month from 0 to 11
		var y = date.getFullYear();
		return y.toString() + '-' + (m <= 9 ? ('0' + m) : m) + '-' + (d <= 9 ? ('0' + d) : d);
	}

	render() {

		var exportBillingDataExcel = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Billing Statements Past 5 Years',
			columns: billingExportColumns,
			entity: 'RECHARGES.BILLING_VW',
			search: this.constructSearch(this.state.search),
			order: ['PI_NAME','DATE_BILLED','SPEEDTYPE','TRANS_DATE']
		});			

		var exportBillingDataPDF = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Billing Statements Past 5 Years',
			columns: billingExportColumns,
			entity: 'RECHARGES.BILLING_VW',
			search: this.constructSearch(this.state.search, true),
			order: ['PI_NAME','DATE_BILLED','SPEEDTYPE','TRANS_DATE']
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Billing Statements Past 5 Years</span>
					<div style={{ paddingBottom: '10px' }}>Current billing cycle {this.state.cycleBegDate} - {this.state.cycleEndDate}</div>
					<ChemEdit parent={this} columns={this.state.billingSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={4} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportBillingDataExcel.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/CRS/Billing'} method='POST'>
								{exportBillingDataPDF.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
				  <Col>
				    <QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_BillingStatements' table={this.state.billingList}
					  onResult={(data) => this.onSearchResult(data)} offsetHeight='470px' />
				  </Col>
				</Row>
			</Container>
		</>);
	}
}

const months = [
	{ value: 1, label: 'January' },
	{ value: 2, label: 'February' },
	{ value: 3, label: 'March' },
	{ value: 4, label: 'April' },
	{ value: 5, label: 'May' },
	{ value: 6, label: 'June' },
	{ value: 7, label: 'July' },
	{ value: 8, label: 'August' },
	{ value: 9, label: 'September' },
	{ value: 10, label: 'October' },
	{ value: 11, label: 'November' },
	{ value: 12, label: 'December' }
];

const billingSearch = [
	{ Header: 'PI', accessor: 'PI_ROLE_ID', type: 'list', row: 0, col: 0,
		options: { value: 'ROLE_ID', label: 'PI_NAME', entity: 'RECHARGES.BILLING_PI_SELECT_VW', order: ['SORT_ORDER','PI_NAME'] }},
	{ Header: 'Fiscal Year', accessor: 'BEG_FISCAL_YEAR', type: 'select', row: 0, col: 1, required: true,
		options: { value: 'BEG_FISCAL_YEAR', label: 'FISCAL_YEAR', entity: 'RECHARGES.FISCAL_5YEAR_SELECT_VW', order: 'BEG_FISCAL_YEAR desc' }},
	{ Header: 'Month', accessor: 'MONTH', type: 'list', row: 1, col: 0, options: months },
	{ Header: 'Service', accessor: 'SERVICE_ID', type: 'list', row: 1, col: 1,
		options: { value: 'SERVICE_ID_LIST', label: 'RCH_NAME', entity: 'RECHARGES.STATEMENT_SERVICE_SELECT_VW', order: 'RCH_NAME' }},
	{ Header: 'Speedtype', accessor: 'SPEEDTYPE', row: 2, col: 0 },
	{ Header: 'Statement Type', accessor: 'STATEMENT_TYPE', type: 'select', row: 2, col: 1,
		options: [{ value: null, label: 'Select...' },{ value: 'Compare', label: 'Compare' },
			{ value: 'Old', label: 'Old' },{ value: 'New', label: 'New' }]}
];

const billingList = {
	query: { entity: 'RECHARGES.BILLING_VW', search: null, order: ['PI_NAME','DATE_BILLED','SPEEDTYPE','TRANS_DATE'] },
	pageSize: 20,
	hold: true,
	columns: [
		{ Header: 'Speedtype', accessor: 'SPEEDTYPE', width: 125 },
		{ Header: 'PI', accessor: 'PI_NAME', width: 150 },
		{ Header: 'Service', accessor: 'SERVICE_NAME', width: 300 },
		{ Header: 'Description', accessor: 'DESCRIPTION', width: 300 },
		{ Header: 'ID', accessor: 'ID', width: 80 },
		{ Header: 'Date', accessor: 'TRANS_DATE', width: 80 },
		{ Header: 'User', accessor: 'USER_NAME', width: 200 },
		{ Header: '$Labor', accessor: 'LABOR_HOURS', width: 80,
			Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>{props.parent.formatCurrency(props.value)}</div> },
		{ Header: '$Discount', accessor: 'DISCOUNT', width: 80,
			Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>{props.parent.formatCurrency(props.value)}</div> },
		{ Header: '$Material', accessor: 'MATERIAL_TOTAL', width: 80,
			Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>{props.parent.formatCurrency(props.value)}</div> },
		{ Header: '$Total', accessor: 'TOTAL_AMOUNT', width: 80,
			Cell: props => <div style={{ textAlign: 'right', paddingRight: '4px' }}>{props.parent.formatCurrency(props.value)}</div> },
		{ Header: 'Billed', accessor: 'DATE_BILLED', width: 100 },
	]
};

const billingExportColumns = [
	{ Header: 'Speedtype', accessor: 'SPEEDTYPE', width: 18 },
	{ Header: 'Account', accessor: 'ACCOUNT', width: 18 },
	{ Header: 'Fund Code', accessor: 'FUNDCODE', width: 18 },
	{ Header: 'Dept ID', accessor: 'DEPT_ID', width: 18 },
	{ Header: 'Program', accessor: 'PROGRAM', width: 18 },
	{ Header: 'Chart Field 1', accessor: 'CHARTFIELD1', width: 18 },
	{ Header: 'Chart Field 2', accessor: 'CHARTFIELD2', width: 18 },
	{ Header: 'PI', accessor: 'PI_NAME', width: 30 },
	{ Header: 'Service', accessor: 'SERVICE_NAME', width: 60 },
	{ Header: 'Description', accessor: 'DESCRIPTION', width: 60 },
	{ Header: 'ID', accessor: 'ID', width: 16 },
	{ Header: 'Date', accessor: 'TRANS_DATE', width: 16, format: 'mm/dd/yy' },
	{ Header: 'User', accessor: 'USER_NAME', width: 40 },
	{ Header: 'Labor', accessor: 'LABOR_HOURS', width: 16, format: '#,##0.00' },
	{ Header: 'Cost Per Hr', accessor: 'COSTPERH', width: 16, format: '#,##0.00' },
	{ Header: 'Discount', accessor: 'DISCOUNT', width: 16, format: '#,##0.00' },
	{ Header: 'Labor Total', accessor: 'LABOR_TOTAL', width: 16, format: '#,##0.00' },
	{ Header: 'Material Total', accessor: 'MATERIAL_TOTAL', width: 16, format: '#,##0.00' },
	{ Header: 'Total Amount', accessor: 'TOTAL_AMOUNT', width: 16, format: '#,##0.00' },
	{ Header: 'Billed', accessor: 'DATE_BILLED', width: 20, format: 'yyyy-mm-dd' }
];

export default withRouter(Billing);