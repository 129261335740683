import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class Buildings extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			BUILDING_NAME: '',
			COMMENTS: ''
		};
		
		var initialBuildingList = this.copyBuildingList();
		initialBuildingList.query.search = this.constructSearch(search);
		
		this.state = {
			renderKey: 1,
			buildingList: initialBuildingList,
			search: search,
			searchCount: null
		};
	}
	
	copyBuildingList() {
		var buildingListCopy = this.copyObject(buildingList);
		for (var i = 0; i < buildingList.columns.length; i++) {
			if (buildingList.columns[i].Header) {
				buildingListCopy.columns[i].Header = buildingList.columns[i].Header;
			}
			if (buildingList.columns[i].Cell) {
				buildingListCopy.columns[i].Cell = buildingList.columns[i].Cell;
			}
		}
		return buildingListCopy;
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newBuildingList = this.copyBuildingList();
		newBuildingList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			buildingList: newBuildingList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.BUILDING_NAME)) search.Children.push({ Attribute: 'BUILDING_NAME', Operator: 'like', StringValue: '%' + data.BUILDING_NAME + '%' });
		if (!this.isEmpty(data.COMMENTS)) search.Children.push({ Attribute: 'COMMENTS', Operator: 'like', StringValue: '%' + data.COMMENTS + '%' });
		
		// if nothing was selected, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	addBuilding() {
		this.props.navigate('/BuildingAccess/Buildings/new');
	}
	
	editBuilding(buildingId) {
		this.props.navigate('/BuildingAccess/Buildings/' + buildingId);		
	}
	
	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Buildings',
			columns: exportColumns,
			entity: 'BUILDING_VW',
			search: this.constructSearch(this.state.search),
			order: 'BUILDING_NAME'
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Buildings</span>
					<ChemEdit parent={this} columns={buildingSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={3} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_Buildings' table={this.state.buildingList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='200px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const buildingSearch = [
	{ Header: 'Name', accessor: 'BUILDING_NAME', row: 0, col: 0 },
	{ Header: 'Comments', accessor: 'COMMENTS', row: 0, col: 1 }
];

const buildingList = {
	query: { entity: 'BUILDING_VW', search: null, order: 'BUILDING_NAME' },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addBuilding()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editBuilding(props.row.values.BUILDING_ID)} />, width: 25 },
		{ Header: 'ID', accessor: 'BUILDING_ID' },
		{ Header: 'Name', accessor: 'BUILDING_NAME', width: 300 },
		{ Header: 'Chemistry', accessor: 'CHEMISTRY_FLAG' },
		{ Header: 'Last Modified', accessor: 'MODIFIED', width: 300 },
		{ Header: 'Deleted', accessor: 'DELETE_FLAG' },
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'BUILDING_ID', width: 5 },
	{ Header: 'Name', accessor: 'BUILDING_NAME', width: 15 },
	{ Header: 'Chemistry', accessor: 'CHEMISTRY_FLAG', width: 5 },
	{ Header: 'Last Modified', accessor: 'MODIFIED', width: 20 },
	{ Header: 'Deleted', accessor: 'DELETE_FLAG', width: 5 }
];

export default withRouter(Buildings);