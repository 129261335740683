import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withGradOfficeDefaultRoute } from './withGradOfficeDefaultRoute';
import ChemHeader from '../ChemHeader';
import Confirm from '../Confirm';
import TreeMenu from '../TreeMenu';
import Help from './Help';
import Home from './Home';
import Applications from './Applications';
import Application from './Application';
import Students from './Students';
import Student from './Student';
import CurrentStudents from './CurrentStudents';
import Fellowships from './Fellowships';
import Fellowship from './Fellowship';
import GSIs from './GSIs';
import GSI from './GSI';
import Courses from './Courses';
import Course from './Course';
import Schedules from './Schedules';
import Schedule from './Schedule';
import QualExams from './QualExams';
import Degrees from './Degrees';
import Teaching from './Teaching';
import GeneralAdmin from './GeneralAdmin';
import BlockGrants from './BlockGrants';
import BlockGrant from './BlockGrant';
import ViceChairs from './ViceChairs';

class GradOffice extends ChemComponent {
  	constructor(props) {
		super(props);

		// copy menu items so we can edit
		var stateTreeMenuItems = JSON.parse(JSON.stringify(treeMenuItems));
		
		this.state = {
			fullScreen: false,
			treeMenuItems: stateTreeMenuItems,
			studentEditable: false,
			applicationEditable: false,
			fellowshipEditable: false,
			gsiEditable: false,
			courseEditable: false,
			scheduleEditable: false,
			studentSearch: null,
			applicationSearch: null,
			confirm: ''
		};
	}
	
	removeRoute(menuItems, route) {
		for (var i = 0; i < menuItems.length; i++) {
			if (menuItems[i].nodes) {
				this.removeRoute(menuItems[i].nodes, route);
				if (menuItems[i].nodes.length === 0) {
					menuItems.splice(i--, 1);
				}
			} else {
				if (menuItems[i].route && menuItems[i].route.toUpperCase() === route.toUpperCase()) {
					menuItems.splice(i--, 1);
				}
			}
		}
	}
	
	showConfirmation(message) {
		var self = this;
		this.mergeState({
			confirm: message
		}, () => self.props.navigate('/GradOffice/Confirm'));
	}
	
	onStudentSearch(studentSearch) {
		var self = this;
		this.mergeState({
			studentSearch: studentSearch,
			applicationSearch: null,
		}, () => self.props.navigate('/GradOffice/Students'));
	}
	
	onApplicationSearch(applicationSearch) {
		var self = this;
		this.mergeState({
			studentSearch: null,
			applicationSearch: applicationSearch
		}, () => self.props.navigate('/GradOffice/Applications'));
	}
	
	clearStudentSearch() {
		this.mergeState({ studentSearch: null });
	}
	
	clearApplicationSearch() {
		this.mergeState({ applicationSearch: null });
	}
	
	render() {
		return (<>
			<ChemHeader parent={this} user={this.props.user} title='Department Graduate Office' fullScreen={this.state.fullScreen} 
				toggleFullScreen={(fullScreen) => this.mergeState({ fullScreen: fullScreen })}
				showHelp={() => this.props.navigate('/GradOffice/Help', { helpPath: this.props.location.pathname })} />
			<Container fluid style={{ paddingTop: 20, paddingBottom: 20, backgroundColor: '#e6e6e4', height: 'calc(100vh - 64px)', overflow: 'auto' }}>
				<Row>
					{!this.state.fullScreen && <Col xs={2}>
						<TreeMenu parent={this} menuItems={this.state.treeMenuItems} selectedRoute={this.props.location.pathname} />
					</Col>}
					<Col xs={this.state.fullScreen ? 12 : 10}>
						<Routes>
							<Route path={'/applications'} element={<Applications parent={this} user={this.props.user} search={this.state.applicationSearch}
								clear={() => this.clearApplicationSearch()} setApplicationEditable={(applicationEditable) => this.mergeState({ applicationEditable: applicationEditable })} />} />
							<Route path={'/applications/new'} element={<Application parent={this} user={this.props.user} editable={true} />} />
							<Route path={'/applications/new/:activeTab'} element={<Application parent={this} user={this.props.user} editable={true} />} />
							<Route path={'/applications/:applicationId'} element={<Application parent={this} user={this.props.user} editable={this.state.applicationEditable} />} />
							<Route path={'/applications/:applicationId/:activeTab'} element={<Application parent={this} user={this.props.user} editable={this.state.applicationEditable} />} />
							<Route path={'/students'} element={<Students parent={this} user={this.props.user} search={this.state.studentSearch} 
								clear={() => this.clearStudentSearch()} setStudentEditable={(studentEditable) => this.mergeState({ studentEditable: studentEditable })} />} />
							<Route path={'/students/new'} element={<Student parent={this} user={this.props.user} editable={true}
								setFellowshipEditable={(fellowshipEditable) => this.mergeState({ fellowshipEditable: fellowshipEditable })}
								setGSIEditable={(gsiEditable) => this.mergeState({ gsiEditable: gsiEditable })} />} />
							<Route path={'/students/new/:activeTab'} element={<Student parent={this} user={this.props.user} editable={true}
								setFellowshipEditable={(fellowshipEditable) => this.mergeState({ fellowshipEditable: fellowshipEditable })}
								setGSIEditable={(gsiEditable) => this.mergeState({ gsiEditable: gsiEditable })} />} />
							<Route path={'/students/:studentId'} element={<Student parent={this} user={this.props.user} editable={this.state.studentEditable}
								setFellowshipEditable={(fellowshipEditable) => this.mergeState({ fellowshipEditable: fellowshipEditable })}
								setGSIEditable={(gsiEditable) => this.mergeState({ gsiEditable: gsiEditable })} />} />
							<Route path={'/students/:studentId/:activeTab'} element={<Student parent={this} user={this.props.user} editable={this.state.studentEditable}
								setFellowshipEditable={(fellowshipEditable) => this.mergeState({ fellowshipEditable: fellowshipEditable })}
								setGSIEditable={(gsiEditable) => this.mergeState({ gsiEditable: gsiEditable })} />} />
							<Route path={'/students/current'} element={<CurrentStudents parent={this} user={this.props.user}
								setStudentEditable={(studentEditable) => this.mergeState({ studentEditable: studentEditable })} />} />
							<Route path={'/fellowships'} element={<Fellowships parent={this} user={this.props.user}
								setFellowshipEditable={(fellowshipEditable) => this.mergeState({ fellowshipEditable: fellowshipEditable })} />} />
							<Route path={'/fellowships/:fellowshipId'} element={<Fellowship parent={this} user={this.props.user} editable={this.state.fellowshipEditable} />} />
							<Route path={'/fellowships/new'} element={<Fellowship parent={this} user={this.props.user} editable={true} />} />
							<Route path={'/fellowships/new/:studentId'} element={<Fellowship parent={this} user={this.props.user} editable={true} />} />
							<Route path={'/gsis'} element={<GSIs parent={this} user={this.props.user} setGSIEditable={(gsiEditable) => this.mergeState({ gsiEditable: gsiEditable })} />} />
							<Route path={'/gsis/:assignmentId'} element={<GSI parent={this} user={this.props.user} editable={this.state.gsiEditable} />} />
							<Route path={'/gsis/:assignmentId/:activeTab'} element={<GSI parent={this} user={this.props.user} editable={this.state.gsiEditable} />} />
							<Route path={'/gsis/new'} element={<GSI parent={this} user={this.props.user} editable={true} />} />
							<Route path={'/gsis/new/:studentIdOrActiveTab'} element={<GSI parent={this} user={this.props.user} editable={true} />} />
							<Route path={'/gsis/new/:studentId/:activeTab'} element={<GSI parent={this} user={this.props.user} editable={true} />} />
							<Route path={'/courses'} element={<Courses parent={this} user={this.props.user}
								setCourseEditable={(courseEditable) => this.mergeState({ courseEditable: courseEditable })} />} />
							<Route path={'/courses/new'} element={<Course parent={this} user={this.props.user} editable={true} />} />
							<Route path={'/courses/:courseId'} element={<Course parent={this} user={this.props.user} editable={this.state.courseEditable} />} />
							<Route path={'/schedules'} element={<Schedules parent={this} user={this.props.user}
								setScheduleEditable={(scheduleEditable) => this.mergeState({ scheduleEditable: scheduleEditable })} />} />
							<Route path={'/schedules/new'} element={<Schedule parent={this} user={this.props.user} editable={true} />} />
							<Route path={'/schedules/:scheduleId'} element={<Schedule parent={this} user={this.props.user} editable={this.state.scheduleEditable} />} />
							<Route path={'/qualexams'} element={<QualExams parent={this} user={this.props.user}
								setStudentEditable={(studentEditable) => this.mergeState({ studentEditable: studentEditable })} />} />
							<Route path={'/degrees'} element={<Degrees parent={this} user={this.props.user}
								setStudentEditable={(studentEditable) => this.mergeState({ studentEditable: studentEditable })} />} />
							<Route path={'/teaching'} element={<Teaching parent={this} user={this.props.user}
								setApplicationEditable={(applicationEditable) => this.mergeState({ applicationEditable: applicationEditable })} />} />
							<Route path={'/generaladmin'} element={<GeneralAdmin parent={this} user={this.props.user} />} />
							<Route path={'/blockgrants'} element={<BlockGrants parent={this} user={this.props.user} />} />
							<Route path={'/blockgrants/new'} element={<BlockGrant parent={this} user={this.props.user} />} />
							<Route path={'/blockgrants/:grantId'} element={<BlockGrant parent={this} user={this.props.user} />} />
							<Route path={'/vicechairs'} element={<ViceChairs parent={this} user={this.props.user} />} />
							<Route path={'/confirm'} element={<Confirm parent={this} user={this.props.user} message={this.state.confirm} />} />
							<Route path={'/help'} element={<Help parent={this} user={this.props.user} />} />
							<Route path={'/home'} element={<Home parent={this} user={this.props.user} 
								onStudentSearch={(studentSearch) => this.onStudentSearch(studentSearch)}
								onApplicationSearch={(applicationSearch) => this.onApplicationSearch(applicationSearch)} />} />
						</Routes>
					</Col>
				</Row>
			</Container>
		</>);
	}
}

// routes defined here so there are no accidents when removing them!
const routes = {
	applications: '/GradOffice/Applications',
	blockGrants: '/GradOffice/BlockGrants',
	courses: '/GradOffice/Courses',
	currentStudents: '/GradOffice/Students/Current',
	degrees: '/GradOffice/Degrees',
	fellowships: '/GradOffice/Fellowships',
	generalAdmin: '/GradOffice/GeneralAdmin',
	gsis: '/GradOffice/GSIs',
	home: '/GradOffice/Home',
	qualexams: '/GradOffice/QualExams',
	schedules: '/GradOffice/Schedules',
	students: '/GradOffice/Students',
	teaching: '/GradOffice/Teaching',
	viceChairs: '/GradOffice/ViceChairs'
};

const treeMenuItems = [
	{
		label: 'Home',
		route: routes.home
	},
	{
		label: 'Applications',
		nodes: [
			{
				label: 'Application Search',
				route: routes.applications
			}
		]
	},
	{
		label: 'Students',
		nodes: [
			{
				label: 'Student Search',
				route: routes.students
			},
			{
				label: 'Current Students',
				route: routes.currentStudents
			},
			{
				label: 'Qual Exams',
				route: routes.qualexams
			},
			{
				label: 'Degrees',
				route: routes.degrees
			}
		]
	},
	{
		label: 'Fellowships',
		nodes: [
			{
				label: 'Fellowship Search',
				route: routes.fellowships
			}
		]
	},
	{
		label: 'GSI',
		nodes: [
			{
				label: 'GSI Search',
				route: routes.gsis
			},
			{
				label: 'Teaching Report',
				route: routes.teaching
			}
		]
	},
	{
		label: 'Courses',
		nodes: [
			{
				label: 'Course Search',
				route: routes.courses
			}
		]
	},
	{
		label: 'Schedules',
		nodes: [
			{
				label: 'Schedule Search',
				route: routes.schedules
			}
		]
	},
	{
		label: 'Admin',
		nodes: [
			{
				label: 'General Admin',
				route: routes.generalAdmin
			},
			{
				label: 'Block Grants',
				route: routes.blockGrants
			},
			{
				label: 'Vice Chairs',
				route: routes.viceChairs
			}
		]
	}
];

export default withGradOfficeDefaultRoute(GradOffice);