import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class Sales extends ChemComponent {
	constructor(props) {
		super(props);

		var search = {
            TRANS_ID: '',
			STOCK_NO: '',
			DESCRIPTION: '',
            CUSTOMER: '',
			CUSTOMER_LIKE: '',
            PI_NAME: '',
			PI_NAME_LIKE: '',
			TRANS_DATE_FROM: null,
			TRANS_DATE_TO: null
		};
		
		var salesListInit = this.copyTable(salesList);
		
		if (props.search) {
			var propsSearch = this.copyObject(props.search);
			this.mergeObject(search, propsSearch);
			search = propsSearch;
		}

		this.state = {
			salesList: salesListInit,
			search: search,
			searchCount: 0,
			renderKey: 0
		};
	}

	componentDidMount() {
		if (this.props.search) this.onSubmitSearch();
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		if (accessor === 'CUSTOMER') {
			this.setByAccessor(search, 'CUSTOMER_LIKE', '%' + value.toUpperCase() + '%');
		} else if (accessor === 'PI_NAME') {
			this.setByAccessor(search, 'PI_NAME_LIKE', '%' + value.toUpperCase() + '%');
		}
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newSalesList = this.copyTable(salesList);
		newSalesList.ajax.data = {
			search: this.copyObject(this.state.search)
		};
		newSalesList.hold = false;
		this.mergeState({ 
			salesList: newSalesList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	addSale() {
		this.props.navigate('/Stores/Sales/new');
	}
	
	editSale(saleId) {
		this.props.setSaleEditable(true);
		this.props.navigate('/Stores/Sales/' + saleId);		
	}

	viewSale(saleId) {
		this.props.setSaleEditable(false);
		this.props.navigate('/Stores/Sales/' + saleId);		
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			Action: 'Sales',
			columns: exportColumns,
			search: this.state.search
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Sales</span>
						<ChemEdit parent={this} user={this.props.user} columns={salesSearch} data={this.state.search} autoSubmit={true}
						  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)}
						  onSubmit={(event) => this.onSubmitSearch(event)} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Stores/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Stores/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='Stores_Sales' table={this.state.salesList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='310px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const salesSearch = [
	{ Header: 'Sale ID', accessor: 'TRANS_ID', row: 0, col: 0 },
	{ Header: 'Stock Number', accessor: 'STOCK_NO', row: 0, col: 1 },
	{ Header: 'Description', accessor: 'DESCRIPTION', row: 0, col: 2 },
	{ Header: 'Customer Name', accessor: 'CUSTOMER', type: 'dropdown', row: 1, col: 0, subscript: '(Last Name, First Name)',
		options: { value: 'CUSTOMER', label: 'CUSTOMER', entity: 'STORES.CUSTOMER_NAME_AUTOSUGGEST_VW', order: 'CUSTOMER', distinct: true,
			search: { Operator: 'or', Children: [
				{ Attribute: 'LNAME_FNAME_UCASE', Operator: 'like', StringAccessor: 'CUSTOMER_LIKE' },
				{ Attribute: 'FNAME_LNAME_UCASE', Operator: 'like', StringAccessor: 'CUSTOMER_LIKE' }
	]}}},
	{ Header: 'PI Name', accessor: 'PI_NAME', type: 'dropdown', row: 1, col: 1, subscript: '(Last Name, First Name)',
		options: { value: 'PI_NAME', label: 'PI_NAME', entity: 'STORES.PI_NAME_AUTOSUGGEST_VW', order: 'PI_NAME', distinct: true,
			search: { Operator: 'or', Children: [
				{ Attribute: 'LNAME_FNAME_UCASE', Operator: 'like', StringAccessor: 'PI_NAME_LIKE' },
				{ Attribute: 'FNAME_LNAME_UCASE', Operator: 'like', StringAccessor: 'PI_NAME_LIKE' }
	]}}},
	{ Header: 'Date From', accessor: 'TRANS_DATE_FROM', type: 'date', row: 2, col: 0 },
	{ Header: 'Date To', accessor: 'TRANS_DATE_TO', type: 'date', row: 2, col: 1 }
];

const salesList = {
	ajax: { 
		url: '/Stores/SalesSearch',
		data: null,
		accessor: 'Data'
	},
	pageSize: 20,
	hold: true,
	columns: [	
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addSale()} />, accessor: 'id', width: 25, 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editSale(props.row.values.SALE_ID)} /> },
		{ Header: 'Trans ID', accessor: 'SALE_ID', width: 100,
			Cell: props => <HashLink onClick={() => props.parent.viewSale(props.row.values.SALE_ID)}>{props.value}</HashLink> },
		{ Header: 'Line', accessor: 'LINE_ID', width: 50 },
		{ Header: 'Date', accessor: 'TRANS_DATE', width: 150 },
		{ Header: 'User', accessor: 'USER_NAME', width: 200 },
		{ Header: 'SpeedType', accessor: 'COST_CENTER', width: 125 },
		{ Header: 'PI', accessor: 'PI_NAME', width: 200 },
		{ Header: 'Stock No.', accessor: 'STOCK_NO', width: 100 },
		{ Header: 'Description', accessor: 'DESCRIPTION', width: 350 },
		{ Header: 'Units', accessor: 'QTY_SOLD', width: 80 },
		{ Header: '$ Amount', accessor: 'TOTAL', width: 100 },
		{ Header: 'Date Billed', accessor: 'DATE_BILLED', width: 100 },
		{ Header: 'Type', accessor: 'MANUAL_FLAG', width: 80 }
	]
};

const exportColumns = [
	{ Header: 'Trans ID', accessor: 'SALE_ID', width: 20 },
	{ Header: 'User', accessor: 'USER_NAME', width: 40 },
	{ Header: 'Units', accessor: 'QTY_SOLD', width: 16 },
	{ Header: '$ Amount', accessor: 'TOTAL', width: 20 },
	{ Header: 'PI', accessor: 'PI_NAME', width: 40 },
	{ Header: 'SpeedType', accessor: 'COST_CENTER', width: 25 },
	{ Header: 'Date', accessor: 'TRANS_DATE', width: 30 },
	{ Header: 'Date Billed', accessor: 'DATE_BILLED', width: 20 },
	{ Header: 'Type', accessor: 'MANUAL_FLAG', width: 16 }
];

export default withRouter(Sales);
