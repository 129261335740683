import ChemComponent from '../ChemComponent';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';

class StudentUpload extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			sup: {
				file: null
			},
			renderKey: 0
		}
	}
		
	onChange(accessor, value) {
		this.mergeState({
			sup: {
				file: value
			}
		});
	}

	onSubmit(event) {
		var self = this;
		// if there is a file
		if (this.state.sup.file && this.state.sup.file.name) {
			// Create an object of formData
			const formData = new FormData();

			// add the token to the formData
			formData.append("__RequestVerificationToken", this.props.user.antiForgeryToken);
		
			// add the file information
			formData.append("newstudents", this.state.sup.file, this.state.sup.file.name);
			
			this.ajax({
				type: 'POST',
				url: this.getConfig().host + '/BuildingAccess/StudentUpload',
				data: formData,
				contentType: false,
				processData: false
			}).done(function (data) {
				if (data.Success) {
					self.showConfirmation(data.Message);
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		} else {
			self.showAlert('No File Selected', 'Please select a file to upload');
		}
	}
	
	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col md={12} lg={11} xl={10} style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Student Upload</span>
			      <ChemEdit parent={this} columns={qeColumns} data={this.state.sup} user={this.props.user} renderKey={this.state.renderKey}
					onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
				    <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={12}>
					      <Button id="save" variant="warning" type="submit" className="float-end">Upload</Button>
						</Col>
					</Form.Group>
				  </ChemEdit>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const qeColumns = [
	{ Header: 'File', accessor: 'file', type: 'file' }
];

export default StudentUpload;
