import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class PayrollTitle extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			payrollTitle: {
				TITLE_ID: null,
				TITLE_NAME: '',
				COMMENTS: '',
				CREATE_DATE: null,
				CREATE_BY: '',
				MODIF_DATE: null,
				MODIF_BY: '',
				MODIFIED: '',
				PERSON_COUNT: ''
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadPayrollTitle();
	}

	loadPayrollTitle(event) {
		var self = this;
		
		if (this.props.params.payrollTitleId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'PAYROLL_TITLE',
					search: { Attribute: 'TITLE_ID', Operator: '=', LongValue: this.props.params.payrollTitleId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length === 1) {
						var payrollTitle = data.Data[0];
																		
						self.mergeState({
							payrollTitle: payrollTitle,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Payroll Title Error', data.Data.length + ' payroll titles found for ID ' + self.props.params.payrollTitleId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				payrollTitle: {
					TITLE_ID: '(new)'
				}
			});
		}
	}
		
	onChange(accessor, value) {
		var payrollTitle = this.copyObject(this.state.payrollTitle);
		this.setByAccessor(payrollTitle, accessor, value);
		this.mergeState({ payrollTitle: payrollTitle, renderKey: this.state.renderKey + 1 });
	}
		
	deletePayrollTitle(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Payroll Title', 'Delete Payroll Title ' + this.props.params.payrollTitleId + '?', (okClicked) => {
			if (okClicked) {
				// prepare payroll title for deletion
				var payrollTitle = self.copyObject(self.state.payrollTitle);
				payrollTitle.DELETE_FLAG = 'Y';
				payrollTitle.CREATE_DATE = self.dateTimeToMVC(self.getDate(payrollTitle.CREATE_DATE));
				payrollTitle.MODIF_DATE = self.dateTimeToMVC(new Date());
				payrollTitle.MODIF_BY = self.props.user.name;
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'PAYROLL_TITLE', data: payrollTitle }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Payroll Title {self.props.params.payrollTitleId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
		
	onSubmit(event) {
		var self = this;		
		var dtNow = this.dateTimeToMVC(new Date());
		var currentUser = this.props.user.name;
		
		// make copy of data
		var payrollTitle = this.copyObject(this.state.payrollTitle);
		
		// if this is a new payroll title, set ID to sequence name
		var url;
		if (this.props.params.payrollTitleId) {
			url = this.getConfig().host + '/Home/Update';
			payrollTitle.CREATE_DATE = this.dateTimeToMVC(this.getDate(payrollTitle.CREATE_DATE));
			payrollTitle.MODIF_DATE = dtNow;
			payrollTitle.MODIF_BY = currentUser;
		} else {
			url = this.getConfig().host + '/Home/Insert';
			payrollTitle.TITLE_ID = null;
			payrollTitle.CREATE_DATE = dtNow;
			payrollTitle.CREATE_BY = currentUser;
			payrollTitle.MODIF_DATE = null;
			payrollTitle.MODIF_BY = null;
			payrollTitle.DELETE_FLAG = null;
		}			
		
		this.ajax({
			type: 'POST',
			url: url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'PAYROLL_TITLE', data: payrollTitle }
		}).done(function (data) {
			if (data.Success) {
				var payrollTitleId = self.props.params.payrollTitleId ? payrollTitle.TITLE_ID : data.ID;
				var verb = self.props.params.payrollTitleId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Payroll Title <Link to={'/BuildingAccess/PayrollTitles/' + payrollTitleId}>{payrollTitleId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Payroll Title {this.state.payrollTitle.TITLE_ID}</div>
				<ChemEdit parent={this} columns={payrollTitleColumns} data={this.state.payrollTitle} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					{this.props.params.payrollTitleId && <Button id="delete" variant="warning" type="button" className="float-end"
					  style={{ marginRight: '20px' }} onClick={(event) => this.deletePayrollTitle(event)}>Delete</Button>} 
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const payrollTitleColumns = [
	{ Header: 'Name', accessor: 'TITLE_NAME', required: true },
	{ Header: 'Comments', accessor: 'COMMENTS' },
	{ Header: 'Last Modified', accessor: 'MODIF_BY', editable: false,
		Cell: props => {
			// if there is no modif date
			if (props.editor.props.data.MODIF_DATE === null)
			{
				// if there is no create date
				if (props.editor.props.data.CREATE_DATE === null)
				{
					// no info to return
					return null;
				} else {
					// use creation date
					return <>{props.parent.dateToString(props.parent.getDate(props.editor.props.data.CREATE_DATE)) + ' ' + props.editor.props.data.CREATE_BY}</>;
				}
			} else {
				return <>{props.parent.dateToString(props.parent.getDate(props.editor.props.data.MODIF_DATE)) + ' ' + props.value}</>;
			}
		}
	},
	{ Header: 'Deleted', accessor: 'DELETE_FLAG', editable: false }
];

export default withRouter(PayrollTitle);
