import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import Tabs from '../Tabs';
import HashLink from '../HashLink';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class Applications extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = this.copyObject(defaultSearch);
		
		// get list of FLD_ keys
		var fldKeys = [];
		Object.keys(search).forEach(function(key) {
			if (key.length > 4 && key.substring(0, 4) === 'FLD_') {
				fldKeys.push(key);
			}
		});
		
		var initApplicationList = this.copyTable(applicationList);
		var searchCount = 0;
		var showSearch = true;

		if (props.search) {
			var propsSearch = this.copyObject(props.search);
			this.mergeObject(search, propsSearch);
			search = propsSearch;
		} else if (typeof(Storage) !== 'undefined') {
			var storedSearch = localStorage.getItem('GradOffice_Applications_search');
			if (storedSearch) {
				storedSearch = JSON.parse(storedSearch);
				this.mergeObject(search, storedSearch);
				search = storedSearch;
				initApplicationList.ajax.data = { search: this.copyObject(search) };
				this.quoteStringArrays(initApplicationList.ajax.data.search);
				initApplicationList.hold = false;
				this.removeUnusedColumns(initApplicationList);
				searchCount = null;
				showSearch = false;
			}
		}
		
		this.state = {
			activeTab: 'Personal',
			fldKeys: fldKeys,
			renderKey: 1,
			applicationList: initApplicationList,
			search: search,
			searchCount: searchCount,
			showSearch: showSearch
		};
	}
	
	componentDidMount() {
		if (this.props.search) this.onSubmitSearch();
	}

	onClickTabItem(tab) {
		this.mergeState({
			activeTab: tab
		});
	}

	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newApplicationList = this.copyTable(applicationList);
		newApplicationList.ajax.data = {
			search: this.copyObject(this.state.search)
		};
		this.quoteStringArrays(newApplicationList.ajax.data.search);
		newApplicationList.hold = false;
		this.removeUnusedColumns(newApplicationList);
		this.mergeState({ 
			activeTab: 'Personal',
			applicationList: newApplicationList,
			searchCount: null,
			renderKey: this.state.renderKey + 1,
			showSearch: false
		}, () => this.props.clear());
		if (typeof(Storage) !== 'undefined') {
			localStorage.setItem('GradOffice_Applications_search', JSON.stringify(this.state.search));
		}
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onNewSearch(event) {
		this.mergeState({
			search: this.copyObject(defaultSearch),
			showSearch: true,
			renderKey: this.state.renderKey + 1
		});
	}
	
	onExpandSearch(event) {
		this.mergeState({
			renderKey: this.state.renderKey + 1,
			showSearch: true
		});
	}
	
	getFieldList(search)
	{
		var fields = [];
		Object.keys(search).forEach(function(key) {
			if (key.length > 4 && key.substring(0, 4) === 'FLD_' && search[key]) {
				fields.push(key.substring(4));
			}
		});
		return fields;
	}

	removeUnusedColumns(appList) {
		var fields = this.getFieldList(appList.ajax.data.search);
		
		for (var i = appList.columns.length - 1; i >= 0; i--) {
			if (appList.columns[i].includedby && !fields.includes(appList.columns[i].includedby)) {
				appList.columns.splice(i, 1);
			}
		}
	}
	
	removeUnusedExportColumns(search, columns) {
		var fields = this.getFieldList(search);
		
		for (var i = columns.length - 1; i >= 0; i--) {
			if (columns[i].includedby && !fields.includes(columns[i].includedby)) {
				columns.splice(i, 1);
			}
		}
	}
	
	quoteStringArrays(search) {
		this.quoteStringArray(search['DIVISION']);
		this.quoteStringArray(search['CITIZENSHIP']);
		this.quoteStringArray(search['VISA']);
		this.quoteStringArray(search['CONTACT_PROF']);
	}

	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	selectAll(event) {
		var search = this.copyObject(this.state.search);
		for (var i = 0; i < this.state.fldKeys.length; i++) {
			this.setByAccessor(search, this.state.fldKeys[i], true);
		}
		this.mergeState({
			search: search,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}

	deselectAll(event) {
		var search = this.copyObject(this.state.search);
		for (var i = 0; i < this.state.fldKeys.length; i++) {
			this.setByAccessor(search, this.state.fldKeys[i], false);
		}
		this.mergeState({
			search: search,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}

	addApplication() {
		this.props.navigate('/GradOffice/Applications/new');
	}
	
	editApplication(appId) {
		this.props.setApplicationEditable(true);
		this.props.navigate('/GradOffice/Applications/' + appId);		
	}
	
	viewApplication(appId) {
		this.props.setApplicationEditable(false);
		this.props.navigate('/GradOffice/Applications/' + appId);
	}

	render() {
		var exportColumnsInUse = this.copyColumns(exportColumns);
		this.removeUnusedExportColumns(this.state.search, exportColumnsInUse);
		var search = this.copyObject(this.state.search);
		this.quoteStringArrays(search);

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			Action: 'Applications',
			columns: exportColumnsInUse,
			search: search
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				    <Container fluid>
					  <Row>
					    <Col xs={12}>
						  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Applications</span>
						  {this.state.showSearch && <Tabs onClickTabItem={(tab) => this.onClickTabItem(tab)}>
							<div label='Personal'>
							  <ChemEdit parent={this} columns={appPersonal} data={this.state.search} renderKey={this.state.renderKey} 
								onChange={(accessor, value) => this.onChangeSearch(accessor, value)} {...this.props} />
							</div>
							<div label='Academic'>
							  <ChemEdit parent={this} columns={appAcademic} data={this.state.search} renderKey={this.state.renderKey} 
								onChange={(accessor, value) => this.onChangeSearch(accessor, value)} {...this.props} />
							</div>
							<div label='Fields'>
							  <ChemEdit parent={this} columns={appFields} data={this.state.search} renderKey={this.state.renderKey} 
								onChange={(accessor, value) => this.onChangeSearch(accessor, value)} {...this.props} labelColWidth={10} valueColWidth={2} />
							</div>
						  </Tabs>}						
						</Col>
					  </Row>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={3} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/GradOffice/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/GradOffice/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={7}>
						  {this.state.showSearch ?
							<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button> :
							<Button id="expand" variant="warning" type="button" className="float-end" onClick={(event) => this.onExpandSearch(event)}>Modify Search</Button>
						  }
						  <Button id="newSearch" style={{ marginRight: '10px' }} variant="warning" type="button" className="float-end" onClick={(event) => this.onNewSearch(event)}>New Search</Button> 
						  {this.state.showSearch && this.state.activeTab === 'Fields' && <>
							  <Button id="deselectAll" style={{ marginRight: '10px' }} variant="warning" type="button" className="float-end" onClick={(event) => this.deselectAll(event)}>Deselect All</Button>
							  <Button id="selectAll" style={{ marginRight: '10px' }} variant="warning" type="button" className="float-end" onClick={(event) => this.selectAll(event)}>Select All</Button>
						  </>}
						</Col>
					  </Form.Group>
					</Container>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='GradOffice_Applications' table={this.state.applicationList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='140px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const defaultSearch = {
	LNAME: '',
	FNAME: '',
	EMAIL: '',
	APP_ID: '',
	ACTIVITY_STATUS: 'ALL',
	APP_STATUS: [],
	ACADEMIC_YEAR: '',
	ADMISSION_CODE: 'ALL',
	OFFER_ID: [],
	GENDER: [],
	STATE: [],
	CA_RESIDENT: 'ALL',
	HISPANIC: 'ALL',
	URM: 'ALL',
	ETHNICITY: [],
	CITIZENSHIP: [],
	UGRAD_GPA_FROM: '',
	UGRAD_GPA_TO: '',
	VISA: [],
	VISIT_DATE_FROM: null,
	VISIT_DATE_TO: null,
	DIVISION: [],
	CONTACT_PROF: [],
	VICE_CHAIR: [],
	VISIT_FACULTY: [],
	FLD_ACADEMIC_YEAR: true,
	FLD_ADDRESS_P: false,
	FLD_TEMP_ADDRESS: false,
	FLD_ADDRESS_END: false,
	FLD_AD_CODE: false,
	FLD_APP_NAME: true,
	FLD_STATUS: true,
	FLD_FACULTY_APPT: false,
	FLD_APPT_DATE: false,
	FLD_RESIDENCY: false,
	FLD_VISIT_DATE: false,
	FLD_CITIZEN: false,
	FLD_DEGREE_REVD: false,
	FLD_DEGREE_DATE: false,
	FLD_DIVISION_NAME: true,
	FLD_EMAIL: true,
	FLD_STUDENT_ETHNICITY: false,
	FLD_FELLOWSHIPS: false,
	FLD_STUDENT_GENDER: false,
	FLD_GRE_PCT: false,
	FLD_GRE_TOTAL: false,
	FLD_OFFER_CODE: false,
	FLD_RESEARCH_DIR: false,
	FLD_RES_INTERESTS: false,
	FLD_RES_STATE: false,
	FLD_RES_ZIP: false,
	FLD_SCHOOLS_APPLIED: false,
	FLD_SCORES: false,
	FLD_UG_INSTITUTION: false,
	FLD_UNGRAD_GPA: false,
	FLD_VICE_CHAIR: false,
	FLD_VISA: false,
	FLD_WILL_ATTEND: false
};


const appPersonal = [
	{ Header: 'Application ID', accessor: 'APP_ID', row: 0, col: 0 },
	{ Header: 'Last Name', accessor: 'LNAME', row: 0, col: 1 },
	{ Header: 'First Name', accessor: 'FNAME', row: 0, col: 2 },
	{ Header: 'Gender', accessor: 'GENDER', type: 'list', row: 1, col: 0,
		options: { value: 'GENDER_TYPE_ID', label: 'DESCRIPTION', entity: 'ADMISSIONS.GENDER_TYPE', order: 'DESCRIPTION' }},
	{ Header: 'Email', accessor: 'EMAIL', row: 1, col: 1 },
	{ Header: 'Resident State', accessor: 'STATE', type: 'list', row: 1, col: 2,
		options: { value: 'STATE', label: 'STATE', entity: 'ADMISSIONS.STATE_SELECT_VW', order: 'STATE' }},
	{ Header: 'Ethnicity', accessor: 'ETHNICITY', type: 'list', row: 2, col: 0,
		options: { value: 'ETHNICITY_ID', label: 'ETHNICITY_NAME', entity: 'ADMISSIONS.ETHNICITY_SELECT_VW', order: 'ETHNICITY_NAME' }},
	{ Header: 'URM', accessor: 'URM', type: 'select', row: 2, col: 1,
		options: [{ value: 'Y', label: 'Yes' }, { value: 'N', label: 'No' }, { value: 'ALL', label: 'All' }]},
	{ Header: 'Hispanic', accessor: 'HISPANIC', type: 'select', row: 2, col: 2,
		options: [{ value: 'Y', label: 'Yes' }, { value: 'N', label: 'No' }, { value: 'ALL', label: 'All' }]},
	{ Header: 'CA Resident', accessor: 'CA_RESIDENT', type: 'select', row: 3, col: 0,
		options: [{ value: 'Y', label: 'Yes' }, { value: 'N', label: 'No' }, { value: 'ALL', label: 'All' }]},
	{ Header: 'Citizenship', accessor: 'CITIZENSHIP', type: 'list', row: 3, col: 1,
		options: { value: 'CITIZENSHIP', label: 'CITIZENSHIP', entity: 'ADMISSIONS.CITIZENSHIP_SELECT_VW', order: 'CITIZENSHIP' }},
	{ Header: 'Visa', accessor: 'VISA', type: 'list', row: 3, col: 2,
		options: { value: 'VISA_NAME', label: 'VISA_NAME', entity: 'ADMISSIONS.VISA', order: 'VISA_NAME' }},
];

const appAcademic = [
	{ Header: 'Academic Year', accessor: 'ACADEMIC_YEAR', type: 'select', row: 0, col: 0,
		options: { value: 'ACADEMIC_YEAR', label: 'ACADEMIC_YEAR_DISPLAY', entity: 'ADMISSIONS.ACADEMIC_YEAR_SELECT_VW', order: 'ACADEMIC_YEAR desc' }},
	{ Header: 'Applicant Status', accessor: 'ACTIVITY_STATUS', type: 'select', row: 0, col: 1,
		options: [{ value: 'ALL', label: 'All' }, { value: '1', label: 'Active' }, { value: '0', label: 'Not Active' }]},
	{ Header: 'Application Status', accessor: 'APP_STATUS', type: 'list', row: 0, col: 2,
		options: { value: 'STATUS_ID', label: 'STATUS_NAME', entity: 'ADMISSIONS.APPLICATION_STATUS', order: 'STATUS_ID' }},
	{ Header: 'Division', accessor: 'DIVISION', type: 'list', row: 1, col: 0,
		options: { value: 'DIVISION', label: 'DIVISION', entity: 'ADMISSIONS.DIVISIONS', order: 'DIVISION' }},
	{ Header: 'Admitted', accessor: 'ADMISSION_CODE', type: 'select', row: 1, col: 1,
		options: [{ value: '1', label: 'Yes' }, { value: '0', label: 'No' }, { value: 'ALL', label: 'All' }]},
	{ Header: 'Offer Status', accessor: 'OFFER_ID', type: 'list', row: 1, col: 2,
		options: { value: 'OFFER_ID', label: 'OFFER_NAME', entity: 'ADMISSIONS.OFFERS', order: 'OFFER_NAME' }},
	{ Header: 'Faculty Visited', accessor: 'VISIT_FACULTY', type: 'list', row: 2, col: 0,
		options: { value: 'FACULTY', label: 'FACULTY', entity: 'ADMISSIONS.VISIT_FACULTY_SELECT_VW', order: 'FACULTY' }},
	{ Header: 'Undergrad GPA From', accessor: 'UGRAD_GPA_FROM', row: 2, col: 1 },
	{ Header: 'Undergrad GPA To', accessor: 'UGRAD_GPA_TO', row: 2, col: 2 },
	{ Header: 'Contact Professor', accessor: 'CONTACT_PROF', type: 'list', row: 3, col: 0,
		options: { value: 'CONTACT_PROF', label: 'CONTACT_PROF', entity: 'ADMISSIONS.APP_EVALUATION', distinct: true, order: 'CONTACT_PROF',
			search: { Attribute: 'CONTACT_PROF', Operator: 'is not null' }}},
	{ Header: 'Visit Date From', accessor: 'VISIT_DATE_FROM', type: 'date', row: 3, col: 1 },
	{ Header: 'Visit Date To', accessor: 'VISIT_DATE_TO', type: 'date', row: 3, col: 2 },	
	{ Header: 'Vice Chair', accessor: 'VICE_CHAIR', type: 'list', row: 4, col: 0,
		options: { value: 'VICE_CHAIR_NAME', label: 'VICE_CHAIR_NAME', entity: 'ADMISSIONS.VICE_CHAIRS', order: 'VICE_CHAIR_NAME' }},
];

const appFields = [
	{ Header: 'Academic Year', accessor: 'FLD_ACADEMIC_YEAR', type: 'checkbox', row: 0, col: 0 },
	{ Header: 'Address - Permanent', accessor: 'FLD_ADDRESS_P', type: 'checkbox', row: 1, col: 0 },
	{ Header: 'Address - Temporary', accessor: 'FLD_TEMP_ADDRESS', type: 'checkbox', row: 2, col: 0 },
	{ Header: 'Address - End Date', accessor: 'FLD_ADDRESS_END', type: 'checkbox', row: 3, col: 0 },
	{ Header: 'Admission Status', accessor: 'FLD_AD_CODE', type: 'checkbox', row: 4, col: 0 },
	{ Header: 'Applicant Name', accessor: 'FLD_APP_NAME', type: 'checkbox', row: 5, col: 0 },
	{ Header: 'Application Status', accessor: 'FLD_STATUS', type: 'checkbox', row: 0, col: 1 },
	{ Header: 'Appt with Faculty', accessor: 'FLD_FACULTY_APPT', type: 'checkbox', row: 1, col: 1 },
	{ Header: 'Appt Date and Time', accessor: 'FLD_APPT_DATE', type: 'checkbox', row: 2, col: 1 },
	{ Header: 'CA Residency', accessor: 'FLD_RESIDENCY', type: 'checkbox', row: 3, col: 1 },
	{ Header: 'Campus Visit Date', accessor: 'FLD_VISIT_DATE', type: 'checkbox', row: 4, col: 1 },
	{ Header: 'Citizenship', accessor: 'FLD_CITIZEN', type: 'checkbox', row: 5, col: 1 },
	{ Header: 'Degrees', accessor: 'FLD_DEGREE_REVD', type: 'checkbox', row: 0, col: 2 },
	{ Header: 'Degree Dates', accessor: 'FLD_DEGREE_DATE', type: 'checkbox', row: 1, col: 2 },
	{ Header: 'Division', accessor: 'FLD_DIVISION_NAME', type: 'checkbox', row: 2, col: 2 },
	{ Header: 'Email', accessor: 'FLD_EMAIL', type: 'checkbox', row: 3, col: 2 },
	{ Header: 'Ethnicity', accessor: 'FLD_STUDENT_ETHNICITY', type: 'checkbox', row: 4, col: 2 },
	{ Header: 'Fellowships', accessor: 'FLD_FELLOWSHIPS', type: 'checkbox', row: 5, col: 2 },
	{ Header: 'Gender', accessor: 'FLD_STUDENT_GENDER', type: 'checkbox', row: 0, col: 3 },
	{ Header: 'GRE %iles', accessor: 'FLD_GRE_PCT', type: 'checkbox', row: 1, col: 3 },
	{ Header: 'GRE All', accessor: 'FLD_GRE_TOTAL', type: 'checkbox', row: 2, col: 3 },
	{ Header: 'Offer Status', accessor: 'FLD_OFFER_CODE', type: 'checkbox', row: 3, col: 3 },
	{ Header: 'Research Director', accessor: 'FLD_RESEARCH_DIR', type: 'checkbox', row: 4, col: 3 },
	{ Header: 'Research Interests', accessor: 'FLD_RES_INTERESTS', type: 'checkbox', row: 0, col: 4 },
	{ Header: 'Resident State', accessor: 'FLD_RES_STATE', type: 'checkbox', row: 1, col: 4 },
	{ Header: 'Resident Zip', accessor: 'FLD_RES_ZIP', type: 'checkbox', row: 2, col: 4 },
	{ Header: 'Schools Applied to', accessor: 'FLD_SCHOOLS_APPLIED', type: 'checkbox', row: 3, col: 4 },
	{ Header: 'TOEFL/TSE Scores', accessor: 'FLD_SCORES', type: 'checkbox', row: 4, col: 4 },
	{ Header: 'UG/Grad Institution', accessor: 'FLD_UG_INSTITUTION', type: 'checkbox', row: 0, col: 5 },
	{ Header: 'Undergrad GPA', accessor: 'FLD_UNGRAD_GPA', type: 'checkbox', row: 1, col: 5 },
	{ Header: 'Vice Chair', accessor: 'FLD_VICE_CHAIR', type: 'checkbox', row: 2, col: 5 },
	{ Header: 'Visa', accessor: 'FLD_VISA', type: 'checkbox', row: 3, col: 5 },
	{ Header: 'Will Attend Other School', accessor: 'FLD_WILL_ATTEND', type: 'checkbox', row: 4, col: 5 }
];

const applicationList = {
	ajax: { 
		url: '/GradOffice/GetApplications',
		data: null,
		accessor: 'Data'
	},
	pageSize: 20,
	hold: true,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addApplication()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editApplication(props.row.values.APP_ID)} />, width: 25 },
		{ Header: 'App Status', accessor: 'STATUS_NAME', includedby: 'STATUS', width: 150 },
		{ Header: 'Year', accessor: 'ACADEMIC_YEAR', includedby: 'ACADEMIC_YEAR', width: 120 },
		{ Header: 'ID', accessor: 'APP_ID', width: 50 },
		{ Header: 'Name', accessor: 'APP_NAME', includedby: 'APP_NAME', width: 200, Cell: props => <HashLink onClick={() => props.parent.viewApplication(props.row.values.APP_ID)}>{props.value}</HashLink> },
		{ Header: 'Visa', accessor: 'VISA', includedby: 'VISA', width: 180 },
		{ Header: 'Institution', accessor: 'INSTITUTION', includedby: 'UG_INSTITUTION', width: 200 },
		{ Header: 'Degree', accessor: 'DEGREE_REVD', includedby: 'DEGREE_REVD', width: 200 },
		{ Header: 'Degree Date', accessor: 'DEGREE_DATE', includedby: 'DEGREE_DATE', width: 100 },
		{ Header: 'Ethnicity', accessor: 'ETHNICITY', includedby: 'STUDENT_ETHNICITY', width: 300 },
		{ Header: 'Gender', accessor: 'GENDER', includedby: 'STUDENT_GENDER', width: 70 },		
		{ Header: 'Email', accessor: 'EMAIL', includedby: 'EMAIL', width: 250 },
		{ Header: 'Contact Professor', accessor: 'CONTACT_PROF', includedby: 'RESEARCH_DIR', width: 150 },
		{ Header: 'Citizenship', accessor: 'CITIZENSHIP', includedby: 'CITIZEN', width: 150 },
		{ Header: 'Activity Status', accessor: 'ACTIVITY_STATUS_NAME', includedby: 'AD_CODE', width: 80 },
		{ Header: 'Res. Interest 1', accessor: 'RES_INTEREST1', includedby: 'RES_INTERESTS', width: 150 },
		{ Header: 'Res. Interest 2', accessor: 'RES_INTEREST2', includedby: 'RES_INTERESTS', width: 150 },
		{ Header: 'Res. Interest 3', accessor: 'RES_INTEREST3', includedby: 'RES_INTERESTS', width: 150 },
		{ Header: 'Division', accessor: 'DIVISION', includedby: 'DIVISION_NAME', width: 80 },
		{ Header: 'Fellowships', accessor: 'FELLOWSHIPS', includedby: 'FELLOWSHIPS', width: 100 },
		{ Header: 'CA Residency', accessor: 'CA_RESIDENT', includedby: 'RESIDENCY', width: 100 },
		{ Header: 'Permanent Street 1', accessor: 'STREET1', includedby: 'ADDRESS_P', width: 150 },
		{ Header: 'Permanent Street 2', accessor: 'STREET2', includedby: 'ADDRESS_P', width: 150 },
		{ Header: 'Permanent City', accessor: 'CITY', includedby: 'ADDRESS_P', width: 100 },
		{ Header: 'Permanent State', accessor: 'STATE', includedby: 'ADDRESS_P', width: 100 },
		{ Header: 'Permanent Zip', accessor: 'ZIP', includedby: 'ADDRESS_P', width: 100 },
		{ Header: 'Permanent Country', accessor: 'COUNTRY', includedby: 'ADDRESS_P', width: 125 },
		{ Header: 'Temp Street 1', accessor: 'TEMP_STREET1', includedby: 'TEMP_ADDRESS', width: 150 },
		{ Header: 'Temp Street 2', accessor: 'TEMP_STREET2', includedby: 'TEMP_ADDRESS', width: 150 },
		{ Header: 'Temp City', accessor: 'TEMP_CITY', includedby: 'TEMP_ADDRESS', width: 100 },
		{ Header: 'Temp State', accessor: 'TEMP_STATE', includedby: 'TEMP_ADDRESS', width: 100 },
		{ Header: 'Temp Zip', accessor: 'TEMP_ZIP', includedby: 'TEMP_ADDRESS', width: 100 },
		{ Header: 'Temp Country', accessor: 'TEMP_COUNTRY', includedby: 'TEMP_ADDRESS', width: 125 },
		{ Header: 'Temp Address End Date', accessor: 'ADDRESS_END', includedby: 'ADDRESS_END', width: 100 },
		{ Header: 'State Residency', accessor: 'RES_STATE', includedby: 'RES_STATE', width: 80 },
		{ Header: 'Zip Residency', accessor: 'RES_ZIP', includedby: 'RES_ZIP', width: 110 },
		{ Header: 'Admission Status', accessor: 'ADMISSION_CODE', includedby: 'AD_CODE', width: 90 },
		{ Header: 'Offer Status', accessor: 'OFFER_NAME', includedby: 'OFFER_CODE', width: 90 },
		{ Header: 'Undergrad GPA', accessor: 'UNGRAD_GPA', includedby: 'UNGRAD_GPA', width: 90 },
		{ Header: 'GRE 1 %', accessor: 'GRE_SCORE_PCT1', includedby: 'GRE_PCT', width: 120 },
		{ Header: 'TOEFL/TSE Scores', accessor: 'SCORES', includedby: 'SCORES', width: 90 },
		{ Header: 'TOEFL Speak', accessor: 'TOEFL_SPEAKING', includedby: 'SCORES', width: 60 },
		{ Header: 'Test Dates', accessor: 'GRE_DATE', includedby: 'GRE_TOTAL', width: 100 },
		{ Header: 'GRE V Score', accessor: 'V_SCORE', includedby: 'GRE_TOTAL', width: 60 },
		{ Header: 'GRE V %', accessor: 'V_PERCT', includedby: 'GRE_TOTAL', width: 60 },
		{ Header: 'GRE Q Score', accessor: 'Q_SCORE', includedby: 'GRE_TOTAL', width: 60 },
		{ Header: 'GRE Q %', accessor: 'Q_PERCT', includedby: 'GRE_TOTAL', width: 60 },
		{ Header: 'GRE A Score', accessor: 'A_SCORE', includedby: 'GRE_TOTAL', width: 60 },
		{ Header: 'GRE A %', accessor: 'A_PERCT', includedby: 'GRE_TOTAL', width: 60 },
		{ Header: 'GRE AW Score', accessor: 'AW_SCORE', includedby: 'GRE_TOTAL', width: 60 },
		{ Header: 'GRE AW %', accessor: 'AW_PERCT', includedby: 'GRE_TOTAL', width: 60 },
		{ Header: 'Subject', accessor: 'SUBJECT_NAME', includedby: 'GRE_TOTAL', width: 100 },
		{ Header: 'GRE Subj. Score', accessor: 'S_SCORE', includedby: 'GRE_TOTAL', width: 90 },
		{ Header: 'GRE Subj. %', accessor: 'S_PERCT', includedby: 'GRE_TOTAL', width: 90 },
		{ Header: 'Vice Chair', accessor: 'VICE_CHAIR', includedby: 'VICE_CHAIR', width: 125 },
		{ Header: 'Ranking', accessor: 'RANKING', includedby: 'RANKING', width: 85 },
		{ Header: 'Visit Date', accessor: 'VISIT_DATE', includedby: 'VISIT_DATE', width: 100 },
		{ Header: 'Faculty', accessor: 'FACULTY', includedby: 'FACULTY_APPT', width: 400 },
		{ Header: 'Location', accessor: 'ROOM_LOC', includedby: 'APPT_DATE', width: 400 },
		{ Header: 'Time', accessor: 'TIME', includedby: 'APPT_DATE', width: 400 },
		{ Header: 'Applied 1', accessor: 'SCHOOL_NAME1', includedby: 'SCHOOLS_APPLIED', width: 200 },
		{ Header: 'Applied 2', accessor: 'SCHOOL_NAME2', includedby: 'SCHOOLS_APPLIED', width: 200 },
		{ Header: 'Applied 3', accessor: 'SCHOOL_NAME3', includedby: 'SCHOOLS_APPLIED', width: 200 },
		{ Header: 'Applied 4', accessor: 'SCHOOL_NAME4', includedby: 'SCHOOLS_APPLIED', width: 200 },
		{ Header: 'Applied 5', accessor: 'SCHOOL_NAME5', includedby: 'SCHOOLS_APPLIED', width: 200 },
		{ Header: 'Will Attend', accessor: 'WILL_ATTEND', includedby: 'WILL_ATTEND', width: 150 }
	]
};

const exportColumns = [
	{ Header: 'App Status', accessor: 'STATUS_NAME', includedby: 'STATUS', width: 30 },
	{ Header: 'Year', accessor: 'ACADEMIC_YEAR', includedby: 'ACADEMIC_YEAR', width: 20 },
	{ Header: 'ID', accessor: 'APP_ID', width: 10 },
	{ Header: 'Name', accessor: 'APP_NAME', includedby: 'APP_NAME', width: 40 },
	{ Header: 'Visa', accessor: 'VISA', includedby: 'VISA', width: 36 },
	{ Header: 'Institution', accessor: 'INSTITUTION', includedby: 'UG_INSTITUTION', width: 40 },
	{ Header: 'Degree', accessor: 'DEGREE_REVD', includedby: 'DEGREE_REVD', width: 40 },
	{ Header: 'Degree Date', accessor: 'DEGREE_DATE', includedby: 'DEGREE_DATE', width: 20 },
	{ Header: 'Ethnicity', accessor: 'ETHNICITY', includedby: 'STUDENT_ETHNICITY', width: 60 },
	{ Header: 'Gender', accessor: 'GENDER', includedby: 'STUDENT_GENDER', width: 14 },		
	{ Header: 'Email', accessor: 'EMAIL', includedby: 'EMAIL', width: 50 },
	{ Header: 'Contact Professor', accessor: 'CONTACT_PROF', includedby: 'RESEARCH_DIR', width: 30 },
	{ Header: 'Citizenship', accessor: 'CITIZENSHIP', includedby: 'CITIZEN', width: 30 },
	{ Header: 'Activity Status', accessor: 'ACTIVITY_STATUS_NAME', includedby: 'AD_CODE', width: 16 },
	{ Header: 'Res. Interest 1', accessor: 'RES_INTEREST1', includedby: 'RES_INTERESTS', width: 30 },
	{ Header: 'Res. Interest 2', accessor: 'RES_INTEREST2', includedby: 'RES_INTERESTS', width: 30 },
	{ Header: 'Res. Interest 3', accessor: 'RES_INTEREST3', includedby: 'RES_INTERESTS', width: 30 },
	{ Header: 'Division', accessor: 'DIVISION', includedby: 'DIVISION_NAME', width: 16 },
	{ Header: 'Fellowships', accessor: 'FELLOWSHIPS', includedby: 'FELLOWSHIPS', width: 20 },
	{ Header: 'CA Residency', accessor: 'CA_RESIDENT', includedby: 'RESIDENCY', width: 20 },
	{ Header: 'Permanent Street 1', accessor: 'STREET1', includedby: 'ADDRESS_P', width: 30 },
	{ Header: 'Permanent Street 2', accessor: 'STREET2', includedby: 'ADDRESS_P', width: 30 },
	{ Header: 'Permanent City', accessor: 'CITY', includedby: 'ADDRESS_P', width: 20 },
	{ Header: 'Permanent State', accessor: 'STATE', includedby: 'ADDRESS_P', width: 20 },
	{ Header: 'Permanent Zip', accessor: 'ZIP', includedby: 'ADDRESS_P', width: 20 },
	{ Header: 'Permanent Country', accessor: 'COUNTRY', includedby: 'ADDRESS_P', width: 25 },
	{ Header: 'Temp Street 1', accessor: 'TEMP_STREET1', includedby: 'TEMP_ADDRESS', width: 30 },
	{ Header: 'Temp Street 2', accessor: 'TEMP_STREET2', includedby: 'TEMP_ADDRESS', width: 30 },
	{ Header: 'Temp City', accessor: 'TEMP_CITY', includedby: 'TEMP_ADDRESS', width: 20 },
	{ Header: 'Temp State', accessor: 'TEMP_STATE', includedby: 'TEMP_ADDRESS', width: 20 },
	{ Header: 'Temp Zip', accessor: 'TEMP_ZIP', includedby: 'TEMP_ADDRESS', width: 20 },
	{ Header: 'Temp Country', accessor: 'TEMP_COUNTRY', includedby: 'TEMP_ADDRESS', width: 25 },
	{ Header: 'Temp Address End Date', accessor: 'ADDRESS_END', includedby: 'ADDRESS_END', width: 20 },
	{ Header: 'State Residency', accessor: 'RES_STATE', includedby: 'RES_STATE', width: 16 },
	{ Header: 'Zip Residency', accessor: 'RES_ZIP', includedby: 'RES_ZIP', width: 22 },
	{ Header: 'Admission Status', accessor: 'ADMISSION_CODE', includedby: 'AD_CODE', width: 18 },
	{ Header: 'Offer Status', accessor: 'OFFER_NAME', includedby: 'OFFER_CODE', width: 18 },
	{ Header: 'Undergrad GPA', accessor: 'UNGRAD_GPA', includedby: 'UNGRAD_GPA', width: 18 },
	{ Header: 'GRE 1 %', accessor: 'GRE_SCORE_PCT1', includedby: 'GRE_PCT', width: 24 },
	{ Header: 'TOEFL/TSE Scores', accessor: 'SCORES', includedby: 'SCORES', width: 18 },
	{ Header: 'TOEFL Speak', accessor: 'TOEFL_SPEAKING', includedby: 'SCORES', width: 12 },
	{ Header: 'Test Dates', accessor: 'GRE_DATE', includedby: 'GRE_TOTAL', width: 20 },
	{ Header: 'GRE V Score', accessor: 'V_SCORE', includedby: 'GRE_TOTAL', width: 12 },
	{ Header: 'GRE V %', accessor: 'V_PERCT', includedby: 'GRE_TOTAL', width: 12 },
	{ Header: 'GRE Q Score', accessor: 'Q_SCORE', includedby: 'GRE_TOTAL', width: 12 },
	{ Header: 'GRE Q %', accessor: 'Q_PERCT', includedby: 'GRE_TOTAL', width: 12 },
	{ Header: 'GRE A Score', accessor: 'A_SCORE', includedby: 'GRE_TOTAL', width: 12 },
	{ Header: 'GRE A %', accessor: 'A_PERCT', includedby: 'GRE_TOTAL', width: 12 },
	{ Header: 'GRE AW Score', accessor: 'AW_SCORE', includedby: 'GRE_TOTAL', width: 12 },
	{ Header: 'GRE AW %', accessor: 'AW_PERCT', includedby: 'GRE_TOTAL', width: 12 },
	{ Header: 'Subject', accessor: 'SUBJECT_NAME', includedby: 'GRE_TOTAL', width: 20 },
	{ Header: 'GRE Subj. Score', accessor: 'S_SCORE', includedby: 'GRE_TOTAL', width: 18 },
	{ Header: 'GRE Subj. %', accessor: 'S_PERCT', includedby: 'GRE_TOTAL', width: 18 },
	{ Header: 'Vice Chair', accessor: 'VICE_CHAIR', includedby: 'VICE_CHAIR', width: 25 },
	{ Header: 'Ranking', accessor: 'RANKING', includedby: 'RANKING', width: 17 },
	{ Header: 'Visit Date', accessor: 'VISIT_DATE', includedby: 'VISIT_DATE', width: 20 },
	{ Header: 'Faculty', accessor: 'FACULTY', includedby: 'FACULTY_APPT', width: 80 },
	{ Header: 'Location', accessor: 'ROOM_LOC', includedby: 'APPT_DATE', width: 80 },
	{ Header: 'Time', accessor: 'TIME', includedby: 'APPT_DATE', width: 80 },
	{ Header: 'Applied 1', accessor: 'SCHOOL_NAME1', includedby: 'SCHOOLS_APPLIED', width: 40 },
	{ Header: 'Applied 2', accessor: 'SCHOOL_NAME2', includedby: 'SCHOOLS_APPLIED', width: 40 },
	{ Header: 'Applied 3', accessor: 'SCHOOL_NAME3', includedby: 'SCHOOLS_APPLIED', width: 40 },
	{ Header: 'Applied 4', accessor: 'SCHOOL_NAME4', includedby: 'SCHOOLS_APPLIED', width: 40 },
	{ Header: 'Applied 5', accessor: 'SCHOOL_NAME5', includedby: 'SCHOOLS_APPLIED', width: 40 },
	{ Header: 'Will Attend', accessor: 'WILL_ATTEND', includedby: 'WILL_ATTEND', width: 30 }
];

export default withRouter(Applications);