import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';

class Access extends ChemComponent {
	constructor(props) {
		super(props);
		
		var now = new Date();
		var oneYearAgo = new Date(now);
		oneYearAgo.setYear(now.getFullYear() - 1);

		var search = {
			ACCESS_DATE_FROM: this.dateTimeToMVC(oneYearAgo),
			ACCESS_DATE_TO: this.dateTimeToMVC(now)
		};
		
		var accessListInit = this.copyTable(accessList);
		accessListInit.query.search = this.constructSearch(search);

		this.state = {
			accessList: accessListInit,
			search: search,
			searchCount: null,
			renderKey: 0
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newAccessList = this.copyTable(accessList);
		newAccessList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			accessList: newAccessList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.ACCESS_DATE_FROM)) search.Children.push({ Attribute: 'ACCESS_DATE', Operator: '>=', DateValue: data.ACCESS_DATE_FROM });
		if (!this.isEmpty(data.ACCESS_DATE_TO)) search.Children.push({ Attribute: 'ACCESS_DATE', Operator: '<=', DateValue: data.ACCESS_DATE_TO });

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Card Access',
			columns: exportColumns,
			entity: 'DEMURRAGE.CARD_ACCESS_VW',
			search: this.constructSearch(this.state.search),
			order: 'CARD_ID'
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Card Access</span>
						<ChemEdit parent={this} columns={accessSearch} data={this.state.search} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} {...this.props} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='HeLAD_Access' table={this.state.accessList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='190px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const accessSearch = [
	{ Header: 'Access Date From', accessor: 'ACCESS_DATE_FROM', type: 'date', row: 0, col: 0 },
	{ Header: 'Access Date To', accessor: 'ACCESS_DATE_TO', type: 'date', row: 0, col: 1 }
];

const accessList = {
	query: { entity: 'DEMURRAGE.CARD_ACCESS_VW', search: null, order: 'CARD_ID' },
	pageSize: 20,
	columns: [
		{ Header: 'Card', accessor: 'CARD_ID', width: 100 },
		{ Header: 'User', accessor: 'USER_NAME', width: 200 },
		{ Header: 'Valid', accessor: 'CARD_VALIDATION', width: 70 },
		{ Header: 'Date', accessor: 'ACCESS_DATE_DISPLAY', width: 150 },
		{ Header: 'Door', accessor: 'DOOR_OPENED', width: 60 }
	]
};

const exportColumns = [
	{ Header: 'Card', accessor: 'CARD_ID', width: 20 },
	{ Header: 'User', accessor: 'USER_NAME', width: 40 },
	{ Header: 'Valid', accessor: 'CARD_VALIDATION', width: 14 },
	{ Header: 'Date', accessor: 'ACCESS_DATE_DISPLAY', width: 30 },
	{ Header: 'Door', accessor: 'DOOR_OPENED', width: 12 }
];

export default withRouter(Access);
