import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Application extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			application: {
				APPLICATION_ID: null,
				APPLICATION_NAME: '',
				APPLICATION_SCHEMA: '',
				APPLICATION_DETAIL: '',
				APPLICATION_ADMIN_PERSON_ID: null,
				APPLICATION_TECH_PERSON_ID: null,
				APPLICATION_PUBLIC_INDEX: '',
				APPLICATION_ADMIN_INDEX: '',
				DOMAIN: '',
				TECH_ID: null,
				OWNER_PERSON_ID: null,
				ROOTAPPDIR: '',
				DATASOURCE: '',
				DELETE_FLAG: null,
				COMMENTS: ''
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadApplication();
	}

	loadApplication(event) {
		var self = this;
		
		if (this.props.params.applicationId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'APPLICATION',
					search: { Attribute: 'APPLICATION_ID', Operator: '=', LongValue: this.props.params.applicationId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length === 1) {
						var application = data.Data[0];
																		
						self.mergeState({
							application: application,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Application Error', data.Data.length + ' applications found for ID ' + self.props.params.applicationId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				application: {
					APPLICATION_ID: '(new)'
				}
			});
		}
	}
		
	onChange(accessor, value) {
		var application = this.copyObject(this.state.application);
		this.setByAccessor(application, accessor, value);
		this.mergeState({ application: application, renderKey: this.state.renderKey + 1 });
	}
		
	deleteApplication(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Application', 'Delete Application ' + this.props.params.applicationId + '?', (okClicked) => {
			if (okClicked) {
				// prepare application for deletion
				var application = self.copyObject(self.state.application);
				application.DELETE_FLAG = 'Y';
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'APPLICATION', data: application }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Application {self.props.params.applicationId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
		
	onSubmit(event) {
		var self = this;		
		
		// make copy of data
		var application = this.copyObject(this.state.application);

		// if this is a new application, set ID to sequence name
		var url;
		if (this.props.params.applicationId) {
			url = this.getConfig().host + '/Home/Update';
		} else {
			url = this.getConfig().host + '/Home/Insert';
			application.APPLICATION_ID = 'application_id_seq.nextval';
			application.DELETE_FLAG = null;
		}			
		
		this.ajax({
			type: 'POST',
			url: url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'APPLICATION', data: application }
		}).done(function (data) {
			if (data.Success) {
				var applicationId = self.props.params.applicationId ? application.APPLICATION_ID : data.ID;
				var verb = self.props.params.applicationId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Application <Link to={'/BuildingAccess/Applications/' + applicationId}>{applicationId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Application {this.state.application.APPLICATION_ID}</div>
				<ChemEdit parent={this} columns={applicationColumns} data={this.state.application} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					{this.props.params.applicationId && <Button id="delete" variant="warning" type="button" className="float-end"
					  style={{ marginRight: '20px' }} onClick={(event) => this.deleteApplication(event)}>Delete</Button>} 
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const applicationColumns = [
	{ Header: 'Name', accessor: 'APPLICATION_NAME', required: true },
	{ Header: 'Datasource', accessor: 'APPLICATION_SCHEMA' },
	{ Header: 'Domain', accessor: 'DOMAIN', type: 'select',
		options: [{ value: 'dotnet', label: 'dotnet' }, { value: 'inet', label: 'inet' }, 
			{ value: 'main', label: 'main' }, { value: 'xnet', label: 'xnet' }]}, 
	{ Header: 'Detail', accessor: 'APPLICATION_DETAIL' },
	{ Header: 'Owner', accessor: 'OWNER_PERSON_ID', type: 'select',
		options: { value: 'PERSON_ID', label: 'PERSON_NAME', entity: 'PEOPLE_SELECT_VW', order: 'PERSON_NAME' }},
	{ Header: 'Admin', accessor: 'APPLICATION_ADMIN_PERSON_ID', type: 'select',
		options: { value: 'PERSON_ID', label: 'PERSON_NAME', entity: 'PEOPLE_SELECT_VW', order: 'PERSON_NAME' }},
	{ Header: 'Technical Contact', accessor: 'APPLICATION_TECH_PERSON_ID', type: 'select',
		options: { value: 'PERSON_ID', label: 'PERSON_NAME', entity: 'PEOPLE_SELECT_VW', order: 'PERSON_NAME' }},
	{ Header: 'Admin Index', accessor: 'APPLICATION_ADMIN_INDEX' },
	{ Header: 'Public Index', accessor: 'APPLICATION_PUBLIC_INDEX' },
	{ Header: 'Root Directory', accessor: 'ROOTAPPDIR' },
	{ Header: 'Comments', accessor: 'COMMENTS' }
];

export default withRouter(Application);
