import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class KeyMaker extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			keyMaker: {
				MAKER_ID: null,
				MAKER_NAME: '',
				COMMENTS: '',
				CREATE_DATE: null,
				CREATE_BY: '',
				MODIF_DATE: null,
				MODIF_BY: '',
				DELETE_FLAG: null,
				CREATED: '',
				MODIFIED: ''
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadKeyMaker();
	}

	loadKeyMaker(event) {
		var self = this;
		
		if (this.props.params.keyMakerId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'KEY_MAKER',
					search: { Attribute: 'MAKER_ID', Operator: '=', LongValue: this.props.params.keyMakerId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length === 1) {
						var keyMaker = data.Data[0];
						
						// convert flags from oracle style
						keyMaker.DELETE_FLAG = keyMaker.DELETE_FLAG === 'Y';
												
						// compose change info
						keyMaker.CREATED = self.composeChangeInfo(keyMaker.CREATE_BY, keyMaker.CREATE_DATE);
						keyMaker.MODIFIED = self.composeChangeInfo(keyMaker.MODIF_BY, keyMaker.MODIF_DATE);
						
						self.mergeState({
							keyMaker: keyMaker,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Key Maker Error', data.Data.length + ' key makers found for ID ' + self.props.params.keyMakerId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				keyMaker: {
					MAKER_ID: '(new)'
				}
			});
		}
	}
		
	onChange(accessor, value) {
		var keyMaker = this.copyObject(this.state.keyMaker);
		this.setByAccessor(keyMaker, accessor, value);
		this.mergeState({ keyMaker: keyMaker, renderKey: this.state.renderKey + 1 });
	}
		
	deleteKeyMaker(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Key Maker', 'Delete Key Maker ' + this.props.params.keyMakerId + '?', (okClicked) => {
			if (okClicked) {
				// prepare key maker for deletion
				var keyMaker = self.copyObject(self.state.keyMaker);
				keyMaker.DELETE_FLAG = 'Y';
				keyMaker.CREATE_DATE = self.dateTimeToMVC(self.getDate(keyMaker.CREATE_DATE));
				keyMaker.MODIF_DATE = self.dateTimeToMVC(new Date());
				keyMaker.MODIF_BY = self.props.user.name;
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'KEY_MAKER', data: keyMaker }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Key Maker {self.props.params.keyMakerId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
		
	onSubmit(event) {
		var self = this;		
		var dtNow = this.dateTimeToMVC(new Date());
		var currentUser = this.props.user.name;
		
		// make copy of data
		var keyMaker = this.copyObject(this.state.keyMaker);
		
		// if this is a new room, set ID to sequence name
		var url;
		if (this.props.params.keyMakerId) {
			url = this.getConfig().host + '/Home/Update';
			keyMaker.CREATE_DATE = this.dateTimeToMVC(this.getDate(keyMaker.CREATE_DATE));
			keyMaker.MODIF_DATE = dtNow;
			keyMaker.MODIF_BY = currentUser;
		} else {
			url = this.getConfig().host + '/Home/Insert';
			keyMaker.MAKER_ID = null;
			keyMaker.CREATE_DATE = dtNow;
			keyMaker.CREATE_BY = currentUser;
			keyMaker.MODIF_DATE = null;
			keyMaker.MODIF_BY = null;
		}			

		// convert flags to oracle style
		keyMaker.DELETE_FLAG = keyMaker.DELETE_FLAG ? 'Y' : null;
		
		this.ajax({
			type: 'POST',
			url: url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'KEY_MAKER', data: keyMaker }
		}).done(function (data) {
			if (data.Success) {
				var keyMakerId = self.props.params.keyMakerId ? keyMaker.MAKER_ID : data.ID;
				var verb = self.props.params.keyMakerId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Key Maker <Link to={'/BuildingAccess/KeyMakers/' + keyMakerId}>{keyMakerId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Key Maker {this.state.keyMaker.MAKER_ID}</div>
				<ChemEdit parent={this} columns={keyMakerColumns} data={this.state.keyMaker} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					{ this.props.params.keyMakerId && <Button id="delete" variant="warning" type="button" className="float-end"
					  style={{ marginRight: '20px' }} onClick={(event) => this.deleteKeyMaker(event)}>Delete</Button> } 
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const keyMakerColumns = [
	{ Header: 'Name', accessor: 'MAKER_NAME', required: true },
	{ Header: 'Comments', accessor: 'COMMENTS' }
];

export default withRouter(KeyMaker);
