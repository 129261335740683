import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row, OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import ChemComponent from '../../ChemComponent';
import QueryTable from '../../QueryTable';

class GradsPostdocs extends ChemComponent {
  	constructor(props) {
		super(props);
		
		this.state = {
			renderKey: 1,
			searchCount: null
		};
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
		
	render() {		
	
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Grads and Postdocs',
			columns: exportColumns,
			entity: 'GRADS_POSTDOCS_VW',
			order: ['LNAME','FNAME','EMAIL']
		});			

		var emailData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Grads and Postdocs',
			distinct: true,
			columns: ['EMAIL'],
			entity: 'GRADS_POSTDOCS_VW',
			order: ['EMAIL']
		});

		return (<>
			<Container fluid>
				<Row style={{ paddingTop: '20px', paddingBottom: '20px', marginBottom: '20px', border: '1px solid #ccc',
					borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<Col xs={2}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Grads and Postdocs</span>
					</Col>
					<Col xs={2} style={{ marginTop: 2}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					</Col>
					<Col xs={1}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					</Col>
					<Col xs={1}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					</Col>
					<Col xs={1}>
						<form action={this.getConfig().host + '/Home/MailingList'} method='POST'>
							{emailData.map((emailFormField, index) => {
								return (<input key={index} type='hidden' name={emailFormField.name} value={emailFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export Email List</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Email.png'} style={{ paddingLeft: '20px' }} alt="Export Email List" />
							</OverlayTrigger>
						</form>
					</Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='BuildingAccess_GradsPostdocs' table={gradsPostdocsList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='100px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const gradsPostdocsList = {
	query: { entity: 'GRADS_POSTDOCS_VW', order: ['LNAME','FNAME','EMAIL'] },
	pageSize: 10,
	columns: [
		{ Header: 'Last Name', accessor: 'LNAME' },
		{ Header: 'First Name', accessor: 'FNAME' },
		{ Header: 'Email', accessor: 'EMAIL', width: 250 },
	]
};

const exportColumns = [
	{ Header: 'Last Name', accessor: 'LNAME', width: 20 },
	{ Header: 'First Name', accessor: 'FNAME', width: 20 },
	{ Header: 'Email', accessor: 'EMAIL', width: 20 },
];

export default GradsPostdocs;