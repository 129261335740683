import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';
import Tabs from '../Tabs';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { PlusSquare, PencilSquare } from 'react-bootstrap-icons';

class GSIs extends ChemComponent {
	constructor(props) {
		super(props);

		var search = this.copyObject(defaultSearch);
		
		// get list of FLD_ keys since they will disappear when object is copied
		var fldKeys = [];
		Object.keys(search).forEach(function(key) {
			if (key.length > 4 && key.substring(0, 4) === 'FLD_') {
				fldKeys.push(key);
			}
		});	
		
		var initGSIList = this.copyTable(gsiList);
		var searchCount = 0;
		var showSearch = true;

		if (typeof(Storage) !== 'undefined') {
			var storedSearch = localStorage.getItem('GradOffice_GSIs_search');
			if (storedSearch) {
				storedSearch = JSON.parse(storedSearch);
				this.mergeObject(search, storedSearch);
				search = storedSearch;
				initGSIList.ajax.data = { search: this.copyObject(search) };
				this.quoteStringArrays(initGSIList.ajax.data.search);
				initGSIList.hold = false;
				this.removeUnusedColumns(initGSIList.ajax.data.search, initGSIList.columns);
				searchCount = null;
				showSearch = false;
			}
		}

		this.state = {
			gsiList: initGSIList,
			search: search,
			fldKeys: fldKeys,
			searchCount: searchCount,
			showSearch: showSearch,
			activeTab: 'Personal',
			renderKey: 0
		};
	}
	
	onClickTabItem(tab) {
		this.mergeState({
			activeTab: tab
		});
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newGSIList = this.copyTable(gsiList);
		newGSIList.ajax.data = {
			search: this.copyObject(this.state.search)
		};
		//this.quoteStringArrays(newGSIList.ajax.data.search);
		newGSIList.hold = false;
		this.removeUnusedColumns(newGSIList.ajax.data.search, newGSIList.columns);
		this.mergeState({ 
			gsiList: newGSIList,
			searchCount: null,
			renderKey: this.state.renderKey + 1,
			activeTab: 'GSI',
			showSearch: false
		});
		if (typeof(Storage) !== 'undefined') {
			localStorage.setItem('GradOffice_GSIs_search', JSON.stringify(this.state.search));
		}
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onNewSearch(event) {
		this.mergeState({
			search: this.copyObject(defaultSearch),
			showSearch: true,
			renderKey: this.state.renderKey + 1
		});
	}

	onExpandSearch(event) {
		this.mergeState({
			showSearch: true			
		});
	}
	
	getFieldList(search)
	{
		var fields = [];
		Object.keys(search).forEach(function(key) {
			if (key.length > 4 && key.substring(0, 4) === 'FLD_' && search[key]) {
				fields.push(key.substring(4));
			}
		});
		return fields;
	}
	
	removeUnusedColumns(search, columns) {
		var fields = this.getFieldList(search);
		
		for (var i = columns.length - 1; i >= 0; i--) {
			if (columns[i].accessor !== 'id' && columns[i].accessor !== 'ASSIGNMENT_ID') {
				if (columns[i].includedby) {
					if (!fields.includes(columns[i].includedby)) {
						columns.splice(i, 1);
					}
				} else if (!fields.includes(columns[i].accessor)) {
					columns.splice(i, 1);
				}
			}
		}
	}

	quoteStringArrays(search) {
		this.quoteStringArray(search['FELLOWSHIP_YEAR']);
		this.quoteStringArray(search['REGISTRATION']);
		this.quoteStringArray(search['ACADEMIC_YEAR']);
		this.quoteStringArray(search['DIVISION']);
		this.quoteStringArray(search['VISA_NAME']);
	}
	
	selectAll(event) {
		var search = this.copyObject(this.state.search);
		for (var i = 0; i < this.state.fldKeys.length; i++) {
			this.setByAccessor(search, this.state.fldKeys[i], true);
		}
		this.mergeState({
			search: search,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}

	deselectAll(event) {
		var search = this.copyObject(this.state.search);
		for (var i = 0; i < this.state.fldKeys.length; i++) {
			this.setByAccessor(search, this.state.fldKeys[i], false);
		}
		this.mergeState({
			search: search,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	addGSI() {
		this.props.navigate('/GradOffice/GSIs/new');
	}
	
	editGSI(assignmentId) {
		this.props.setGSIEditable(true);
		this.props.navigate('/GradOffice/GSIs/' + assignmentId);		
	}
	
	viewGSI(assignmentId) {
		this.props.setGSIEditable(false);
		this.props.navigate('/GradOffice/GSIs/' + assignmentId);		
	}	

	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		var exportColumnsInUse = this.copyColumns(exportColumns);
		this.removeUnusedColumns(this.state.search, exportColumnsInUse);
		var search = this.copyObject(this.state.search);
		//this.quoteStringArrays(search);

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			Action: 'GSIs',
			columns: exportColumnsInUse,
			search: search
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>GSIs</span>
						{this.state.showSearch && <Tabs onClickTabItem={(tab) => this.onClickTabItem(tab)}>
						  <div label='Search'>
							<ChemEdit parent={this} columns={gsiSearch} data={this.state.search} renderKey={this.state.renderKey} 
							  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} {...this.props} />
						  </div>
						  <div label='Fields'>
							<ChemEdit parent={this} columns={gsiFields} data={this.state.search} renderKey={this.state.renderKey} 
							  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} {...this.props} labelColWidth={10} valueColWidth={2} />
						  </div>
						</Tabs>}
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/GradOffice/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/GradOffice/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						  {this.state.showSearch ?
							<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button> :
							<Button id="expand" variant="warning" type="button" className="float-end" onClick={(event) => this.onExpandSearch(event)}>Modify Search</Button>
						  }
						  <Button id="newSearch" style={{ marginRight: '10px' }} variant="warning" type="button" className="float-end" onClick={(event) => this.onNewSearch(event)}>New Search</Button> 
						  {this.state.showSearch && this.state.activeTab === 'Fields' && <>
							  <Button id="deselectAll" style={{ marginRight: '10px' }} variant="warning" type="button" className="float-end" onClick={(event) => this.deselectAll(event)}>Deselect All</Button>
							  <Button id="selectAll" style={{ marginRight: '10px' }} variant="warning" type="button" className="float-end" onClick={(event) => this.selectAll(event)}>Select All</Button>
						  </>}
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='GradOffice_GSIs' table={this.state.gsiList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='140px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const defaultSearch = {
	COURSE_ID: [],
	COURSE_YEAR: [],
	COURSE_SEMESTER: null,
	ACADEMIC_YEAR: [],
	APPROVED_BY: null,
	REG_STATUS: null,
	TITLE_CODE: [],
	EXEMPT: 'ALL',
	HEAD_GSI: 'ALL',
	FLD_ACADEMIC_YEAR: undefined,
	FLD_COURSE_NAME: undefined,
	FLD_FEE_REMISSION: undefined,
	FLD_RES_DIRECTOR: undefined,
	FLD_STUDENT_CARD: undefined,
	FLD_APPROVED_BY: undefined,
	FLD_EMP_ID: undefined,
	FLD_HEAD_GSI: undefined,
	FLD_CA_RESIDENT: undefined,
	FLD_NAME: true,
	FLD_REG_STATUS: undefined,
	FLD_TITLE_CODE: undefined,
	FLD_CURRENT_PAY_PLAN: undefined,
	FLD_COURSE_SEMESTER: undefined,
	FLD_VISA: undefined,
	FLD_COMMENTS: undefined,
	FLD_FELLOWSHIPS: undefined,
	FLD_PAY_PERCENTAGE: undefined,
	FLD_EMAIL: undefined,
	FLD_COURSE_YEAR: undefined
};

const gsiSearch = [
	{ Header: 'Course', accessor: 'COURSE_ID', type: 'list', row: 0, col: 0,
		options: { value: 'COURSE_ID', label: 'COURSE_NAME', entity: 'ADMISSIONS.COURSES',
			search: { Operator: 'and', Children: [
				{ Attribute: 'DELETE_FLAG', Operator: 'is null' },
				{ Attribute: 'DEPT_CODE', Operator: '=', LongValue: 1 }]}}},
	{ Header: 'Assignment Year', accessor: 'COURSE_YEAR', type: 'list', row: 0, col: 1,
		options: { value: 'GSI_YEAR', label: 'GSI_YEAR', entity: 'ADMISSIONS.GSI_YEAR_SELECT_VW', order: ['YEAR_ORDER','GSI_YEAR desc'] }},
	{ Header: 'Assignment Semester', accessor: 'COURSE_SEMESTER', type: 'select', row: 0, col: 2,
		options: { value: 'SEMESTER', label: 'SEMESTER', entity: 'ADMISSIONS.GS_ASSIG', distinct: true,
			search: { Attribute: 'SEMESTER', Operator: 'is not null' }}},
	{ Header: 'Year Entered', accessor: 'ACADEMIC_YEAR', type: 'list', row: 1, col: 0,
		options: { value: 'ACADEMIC_YEAR', label: 'ACADEMIC_YEAR', entity: 'ADMISSIONS.APPLICATION', distinct: true, 
			search: { Attribute: 'ACADEMIC_YEAR', Operator: 'is not null' }, order: 'ACADEMIC_YEAR desc' }},
	{ Header: 'Approved By', accessor: 'APPROVED_BY', type: 'select', row: 1, col: 1,
		options: { value: 'APPROVED_BY', label: 'APPROVED_BY', entity: 'ADMISSIONS.APPROVED_BY_SELECT_VW' }},
	{ Header: 'Registration Status', accessor: 'REG_STATUS', type: 'select', row: 1, col: 2,
		options: { value: 'REG_STATUS_ID', label: 'REG_STATUS_NAME', entity: 'ADMISSIONS.REG_STATUS',
			search: { Attribute: 'REG_STATUS_ID', Operator: '!=', LongValue: 6 }}},
	{ Header: 'Title Code', accessor: 'TITLE_CODE', type: 'list', row: 2, col: 0,
		options: { label: 'TITLE_CODE', value: 'TITLE_CODE', entity: 'ADMISSIONS.GS_ASSIG', distinct: true,
			search: { Operator: 'and', Children: [
				{ Attribute: 'DELETE_FLAG', Operator: 'is null' },
				{ Attribute: 'TITLE_CODE', Operator: 'is not null' }]}}},
	{ Header: 'Exempt', accessor: 'EXEMPT', type: 'select', row: 2, col: 1,
		options: [{ value: 'Y', label: 'Yes' }, { value: 'N', label: 'No'}, { value: 'ALL', label: 'All' }]}, 
	{ Header: 'Head GSI', accessor: 'HEAD_GSI', type: 'select', row: 2, col: 2,
		options: [{ value: 'Y', label: 'Yes' }, { value: 'N', label: 'No'}, { value: 'ALL', label: 'All' }]}, 
];

const gsiFields = [
	{ Header: 'Academic Year', accessor: 'FLD_ACADEMIC_YEAR', type: 'checkbox', row: 0, col: 0 },
	{ Header: 'Course Name', accessor: 'FLD_COURSE_NAME', type: 'checkbox', row: 0, col: 1 },
	{ Header: 'Fee Remission', accessor: 'FLD_FEE_REMISSION', type: 'checkbox', row: 0, col: 2 },
	{ Header: 'Research Director', accessor: 'FLD_RES_DIRECTOR', type: 'checkbox', row: 0, col: 3 },
	{ Header: 'Student ID', accessor: 'FLD_STUDENT_CARD', type: 'checkbox', row: 1, col: 0 },
	{ Header: 'Approved By', accessor: 'FLD_APPROVED_BY', type: 'checkbox', row: 1, col: 1 },
	{ Header: 'Employee ID', accessor: 'FLD_EMP_ID', type: 'checkbox', row: 1, col: 2 },
	{ Header: 'Head GSI', accessor: 'FLD_HEAD_GSI', type: 'checkbox', row: 1, col: 3 },
	{ Header: 'Residency Status', accessor: 'FLD_CA_RESIDENT', type: 'checkbox', row: 2, col: 0 },
	{ Header: 'Student Name', accessor: 'FLD_NAME', type: 'checkbox', row: 2, col: 1 },
	{ Header: 'Registration Status', accessor: 'FLD_REG_STATUS', type: 'checkbox', row: 2, col: 2 },
	{ Header: 'Title Code', accessor: 'FLD_TITLE_CODE', type: 'checkbox', row: 2, col: 3 },
	{ Header: 'Pay Plan', accessor: 'FLD_CURRENT_PAY_PLAN', type: 'checkbox', row: 3, col: 0 },
	{ Header: 'Assign. Semester', accessor: 'FLD_COURSE_SEMESTER', type: 'checkbox', row: 3, col: 1 },
	{ Header: 'Visa', accessor: 'FLD_VISA', type: 'checkbox', row: 3, col: 2 },
	{ Header: 'Comments', accessor: 'FLD_COMMENTS', type: 'checkbox', row: 3, col: 3 },
	{ Header: 'Fellowships', accessor: 'FLD_FELLOWSHIPS', type: 'checkbox', row: 4, col: 0 },
	{ Header: 'Appt. Pct', accessor: 'FLD_PAY_PERCENTAGE', type: 'checkbox', row: 4, col: 1 },
	{ Header: 'Student Email', accessor: 'FLD_EMAIL', type: 'checkbox', row: 4, col: 2 },
	{ Header: 'Assig. Year', accessor: 'FLD_COURSE_YEAR', type: 'checkbox', row: 4, col: 3 }
];

const gsiList = {
	ajax: { 
		url: '/GradOffice/GetGSIs',
		data: null,
		accessor: 'Data'
	},
	pageSize: 20,
	hold: true,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addGSI()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editGSI(props.row.values.ASSIGNMENT_ID)} />, width: 25 },
		{ Header: 'ID', accessor: 'ASSIGNMENT_ID' , width: 125 },
		{ Header: 'Student ID', accessor: 'STUDENT_CARD', width: 125 },
		{ Header: 'Emp ID', accessor: 'EMP_ID', width: 125 },
		{ Header: 'Year', accessor: 'COURSE_YEAR', width: 125 },
		{ Header: 'Semester', accessor: 'COURSE_SEMESTER', width: 125 },
		{ Header: 'Course', accessor: 'COURSE_NAME', width: 125 },
		{ Header: 'Name', accessor: 'NAME', width: 200, Cell: props => <HashLink onClick={() => props.parent.viewGSI(props.row.values.ASSIGNMENT_ID)}>{props.value}</HashLink> },
		{ Header: 'Visa', accessor: 'VISA', width: 125 },
		{ Header: 'Fellowships', accessor: 'FUND', width: 125 },
		{ Header: 'Approved_by', accessor: 'APPROVED_BY', width: 200 },
		{ Header: 'Head GSI', accessor: 'HEAD_GSI', width: 125 },
		{ Header: 'Pay Plan', accessor: 'CURRENT_PAY_PLAN', width: 125 },
		{ Header: 'Appt Pct', accessor: 'PAY_PERCENTAGE', width: 125 },
		{ Header: 'Monthly Rate', accessor: 'MONTHLY_RATE', width: 125 },
		{ Header: 'Fee Rem.', accessor: 'FEE_REMISSION', width: 125 },
		{ Header: 'Title', accessor: 'TITLE_CODE', width: 125 },
		{ Header: 'CA Res.', accessor: 'CA_RESIDENT', width: 125 },
		{ Header: 'Year Started', accessor: 'ACADEMIC_YEAR', width: 125 },
		{ Header: 'Exempt', accessor: 'EXEMPT', width: 125 },
		{ Header: 'Email', accessor: 'EMAIL', width: 250 },
		{ Header: 'Citizenship', accessor: 'CITIZENSHIP', width: 125 },
		{ Header: 'Res. Director', accessor: 'RES_DIRECTOR', width: 200 },
		{ Header: 'Comments', accessor: 'COMMENTS', width: 250 }
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'ASSIGNMENT_ID', width: 25 },
	{ Header: 'Student ID', accessor: 'STUDENT_CARD', width: 25 },
	{ Header: 'Emp ID', accessor: 'EMP_ID', width: 25 },
	{ Header: 'Year', accessor: 'COURSE_YEAR', width: 25 },
	{ Header: 'Semester', accessor: 'COURSE_SEMESTER', width: 25 },
	{ Header: 'Course', accessor: 'COURSE_NAME', width: 25 },
	{ Header: 'Name', accessor: 'NAME', width: 40 },
	{ Header: 'Visa', accessor: 'VISA', width: 25 },
	{ Header: 'Fellowships', accessor: 'FUND', width: 25 },
	{ Header: 'Approved_by', accessor: 'APPROVED_BY', width: 40 },
	{ Header: 'Head GSI', accessor: 'HEAD_GSI', width: 25 },
	{ Header: 'Pay Plan', accessor: 'CURRENT_PAY_PLAN', width: 25 },
	{ Header: 'Appt Pct', accessor: 'PAY_PERCENTAGE', width: 25 },
	{ Header: 'Monthly Rate', accessor: 'MONTHLY_RATE', width: 25 },
	{ Header: 'Fee Rem.', accessor: 'FEE_REMISSION', width: 25 },
	{ Header: 'Title', accessor: 'TITLE_CODE', width: 25 },
	{ Header: 'CA Res.', accessor: 'CA_RESIDENT', width: 25 },
	{ Header: 'Year Started', accessor: 'ACADEMIC_YEAR', width: 25 },
	{ Header: 'Exempt', accessor: 'EXEMPT', width: 25 },
	{ Header: 'Email', accessor: 'EMAIL', width: 50 },
	{ Header: 'Citizenship', accessor: 'CITIZENSHIP', width: 25 },
	{ Header: 'Res. Director', accessor: 'RES_DIRECTOR', width: 40 },
	{ Header: 'Comments', accessor: 'COMMENTS', width: 50 }
];

export default withRouter(GSIs)
