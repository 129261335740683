import React from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class RechargeRate extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			rechargeRate: {
				RCH_ID: null,
				RCH_CODE: '',
				SERVICE_ID: null,
				COSTPERH: '',
				DISCOUNT: '',
				LABOR_LABEL: '',
				MATERIAL_LABEL: '',
				MATERIAL_RATE: '',
				CONVERSION: '',
				RCH_UNIT: '',
				CONVERSION_INSTRUCTION: '',
				START_VALID: null,
				END_VALID: null,
				CREATE_DATE: null,
				CREATE_BY: '',
				MODIF_DATE: null,
				MODIF_BY: '',
				COMMENTS: '',
				ACTIVE_FLAG: undefined,
				ALT_DISCOUNT: '',
				OUTSIDE_RATE: '',
				CREATED: '',
				MODIFIED: ''
			},
			rechargeRateColumns: this.copyColumns(rechargeRateColumns),
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadRechargeRate();
	}

	loadRechargeRate(event) {
		var self = this;
		
		if (this.props.params.rchId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/CRS/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'SHOPS_RCH_CODES2',
					search: { Attribute: 'RCH_ID', Operator: '=', LongValue: this.props.params.rchId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var rechargeRate = data.Data[0];
						
						// convert flags
						rechargeRate.ACTIVE_FLAG = rechargeRate.ACTIVE_FLAG === 'Y' ? true : false;
						
						// convert dates
						rechargeRate.START_VALID = self.dateTimeToMVC(self.getDate(rechargeRate.START_VALID));
						rechargeRate.END_VALID = self.dateTimeToMVC(self.getDate(rechargeRate.END_VALID));
						rechargeRate.CREATE_DATE = self.dateTimeToMVC(self.getDate(rechargeRate.CREATE_DATE));
						rechargeRate.MODIF_DATE = self.dateTimeToMVC(self.getDate(rechargeRate.MODIF_DATE));
						
						// compose created/modified
						rechargeRate.CREATED = self.composeChangeInfo(rechargeRate.CREATE_BY, rechargeRate.CREATE_DATE);
						rechargeRate.MODIFIED = self.composeChangeInfo(rechargeRate.MODIF_BY, rechargeRate.MODIF_DATE);
						
						// copy columns
						var newRechargeRateColumns = self.copyColumns(rechargeRateColumns);
						
						// once there is a start date, it can't be changed
						if (!self.isEmpty(rechargeRate.START_VALID)) {
							var startValidIdx = self.findColumnByAccessor(newRechargeRateColumns, 'START_VALID');
							newRechargeRateColumns[startValidIdx].editable = false;
						}
						
						// once there is an end date, it can't be changed
						if (!self.isEmpty(rechargeRate.END_VALID)) {
							var endValidIdx = self.findColumnByAccessor(newRechargeRateColumns, 'END_VALID');
							newRechargeRateColumns[endValidIdx].editable = false;
						}

						self.mergeState({
							rechargeRate: rechargeRate,
							rechargeRateColumns: newRechargeRateColumns,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Recharge Rate Error', data.Data.length + ' Recharge Rates found for ID ' + self.props.params.rchId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				rechargeRate: {
					RCH_ID: '(new)',
					OUTSIDE_RATE: 1,
					ACTIVE_FLAG: true
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var rechargeRate = this.copyObject(this.state.rechargeRate);
		this.setByAccessor(rechargeRate, accessor, value);
		this.mergeState({ rechargeRate: rechargeRate, renderKey: this.state.renderKey + 1 });
	}
		
	deleteRechargeRate(event) {
		var self = this;
		
		// assemble user object to update inactive flag
		var rechargeRate = {
			RCH_ID: this.props.params.rchId,
			ACTIVE_FLAG: 'N',
			MODIF_DATE: this.dateTimeToMVC(new Date()),
			MODIF_BY: this.props.user.name
		};
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/Home/Update',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'RECHARGES.SHOPS_RCH_CODES2', data: rechargeRate }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Recharge Rate {self.props.params.rchId} deleted.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	validateRechargeRate(event, columns, data) {
		return this.validate(columns, data);
	}
		
	onSubmit(event) {
		var self = this;		
		
		// make copy of data
		var rechargeRate = this.copyObject(this.state.rechargeRate);

		// if this is a new user
		var url;
		if (this.props.params.rchId) {
			url = this.getConfig().host + '/Home/Update';
			rechargeRate.MODIF_DATE = this.dateTimeToMVC(new Date());
			rechargeRate.MODIF_BY = this.props.user.name;
			rechargeRate.CREATE_DATE = this.dateTimeToMVC(this.getDate(rechargeRate.CREATE_DATE));
		} else {
			url = this.getConfig().host + '/Home/Insert';
			rechargeRate.CREATE_DATE = this.dateTimeToMVC(new Date());
			rechargeRate.CREATE_BY = this.props.user.name;
			// set ID sequence
			rechargeRate.RCH_ID = 'shops_rch_id_seq.nextval';
		}
		
		// convert flags
		rechargeRate.ACTIVE_FLAG = rechargeRate.ACTIVE_FLAG ? 'Y' : null;
		
		// format dates
		rechargeRate.START_VALID = this.dateTimeToMVC(this.getDate(rechargeRate.START_VALID));
		rechargeRate.END_VALID = this.dateTimeToMVC(this.getDate(rechargeRate.END_VALID));
		
		this.ajax({
			type: 'POST',
			url: url,
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'RECHARGES.SHOPS_RCH_CODES2', data: rechargeRate }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Recharge Rate <Link to={'/CRS/RechargeRates/' + (self.props.params.rchId || data.ID)}>{self.props.params.rchId || data.ID}</Link> updated.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Recharge Rate {this.state.rechargeRate.RCH_ID}</div>
				<ChemEdit parent={this} columns={this.state.rechargeRateColumns} data={this.state.rechargeRate} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}
				   validate={(event, columns, data) => this.validateRechargeRate(event, columns, data)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
					{this.props.params.rchId && <Button id="delete" variant="warning" type="button" className="float-end" style={{ marginRight: '10px' }} onClick={(event) => this.deleteRechargeRate(event)}>Delete</Button>}
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
		  </Container>
		</>);
	}
}

const rechargeRateColumns = [
	{ Header: 'Code', accessor: 'RCH_CODE', row: 0, col: 0, required: true },
	{ Header: 'Service', accessor: 'SERVICE_ID', type: 'select', row: 0, col: 1, required: true,
		options: { value: 'SERVICE_ID', label: 'SERVICE_NAME', entity: 'RECHARGES.SERVICE_LIST', order: 'SERVICE_NAME',
			search: { Operator: 'and', Children: [
				{ Attribute: 'DELETE_FLAG', Operator: 'is null' },
				{ Attribute: 'ACTIVE_FLAG', Operator: '=', StringValue: 'Y' }
			]}}},
	{ Header: 'Statement Label', accessor: 'LABOR_LABEL', row: 0, col: 2 },
	{ Header: '$/h', accessor: 'COSTPERH', row: 1, col: 0, required: true },
	{ Header: '% Disc.', accessor: 'DISCOUNT', row: 1, col: 1, required: true },
	{ Header: 'Alt. Disc.', accessor: 'ALT_DISCOUNT', row: 1, col: 2, required: true },
	{ Header: 'Mat. Rate', accessor: 'MATERIAL_RATE', row: 2, col: 0, required: true },
	{ Header: 'Outside Rate Multiplier', accessor: 'OUTSIDE_RATE', row: 2, col: 1, required: true },	
	{ Header: 'Unit', accessor: 'RCH_UNIT', row: 2, col: 2, required: true },
	{ Header: 'Conversion', accessor: 'CONVERSION', row: 3, col: 0, required: true },
	{ Header: 'Conversion Instruction', accessor: 'CONVERSION_INSTRUCTION', type: 'textarea', row: 3, col: 1 },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 3, col: 2 },
	{ Header: 'Beg Date', accessor: 'START_VALID', type: 'date', row: 4, col: 0, required: true },
	{ Header: 'End Date', accessor: 'END_VALID', type: 'date', row: 4, col: 1 },
	{ Header: 'Active', accessor: 'ACTIVE_FLAG', type: 'checkbox', row: 4, col: 2 },
	{ Header: 'Created', accessor: 'CREATED', row: 5, col: 0, editable: false },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 5, col: 1, editable: false }
];

export default withRouter(RechargeRate);
