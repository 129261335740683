import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Role extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			role: {
				RoleView: {
					ROLE_ID: ''
				}
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadRole();
	}

	loadRole(event) {
		var self = this;
		
		if (this.props.params.personId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'PEOPLE_VW',
					search: { Attribute: 'PERSON_ID', Operator: '=', LongValue: this.props.params.personId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data && data.Data.length > 0) {
						var person = data.Data[0];
						
						self.mergeState({
							role: {
								RoleView: {
									ROLE_ID: '(new)',
									PERSON_ID: person.PERSON_ID,
									PERSON_NAME: person.LNAME + ', ' + person.FNAME,
									TYPE_ID: null,
									TYPE_NAME: '',
									PI_ROLE_ID: null,
									PI_NAME: '',
									SUPERVISOR: null
								}
							},
							renderKey: self.state.renderKey + 1
						});
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/BuildingAccess/GetRole',
				data: { __RequestVerificationToken: this.props.user.antiForgeryToken, roleId: this.props.params.roleId }
			}).done(function (data) {
				if (data.Success) {
					var role = data.Roles[0];
					
					// convert flags from oracle style
					if (role.Attributes && role.Attributes.length > 0) {
						role.Attributes[0].DELEGATE = role.Attributes[0].DELEGATE === 'Y';
					} else {
						role.Attributes = [{ DELEGATE: false }];
					}
					if (role.WorkContacts && role.WorkContacts.length > 0) {
						role.WorkContacts[0].HIDE_EMAIL = role.WorkContacts[0].HIDE_EMAIL === 'Y';
					} else {
						role.WorkContacts = [{ HIDE_EMAIL: false }];
					}
					
					// compose change info
					role.CREATED = self.composeChangeInfo(role.RoleView.CREATE_BY, role.RoleView.CREATE_DATE);
					role.MODIFIED = self.composeChangeInfo(role.RoleView.MODIF_BY, role.RoleView.MODIF_DATE);
					
					self.mergeState({
						role: role,
						renderKey: self.state.renderKey + 1
					}, () => {
						if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
					});
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		}
	}
	
	onChange(accessor, value) {
		var role = this.copyObject(this.state.role);
		this.setByAccessor(role, accessor, value);
		this.mergeState({ role: role, renderKey: this.state.renderKey + 1 });
	}
		
	onSubmit(event) {
		var self = this;		
		var url = this.getConfig().host + (this.state.role.RoleView.ROLE_ID === '(new)' ? '/BuildingAccess/InsertRole' : '/BuildingAccess/UpdateRole');
		
		// make copy of data
		var role = this.copyObject(this.state.role);
		
		// convert dates
		role.RoleView.BEG_ROLE_DATE = this.dateTimeToMVC(this.getDate(role.RoleView.BEG_ROLE_DATE));
		role.RoleView.END_ROLE_DATE = this.dateTimeToMVC(this.getDate(role.RoleView.END_ROLE_DATE));

		// convert flags to oracle style
		if (role.Attributes && role.Attributes.length > 0) {
			role.Attributes[0].DELEGATE = role.Attributes[0].DELEGATE ? 'Y' : null;
		}
		if (role.WorkContacts && role.WorkContacts.length > 0) {
			role.WorkContacts[0].HIDE_EMAIL = role.WorkContacts[0].HIDE_EMAIL ? 'Y' : null;
		}
		
		this.ajax({
			type: 'POST',
			url: url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, role: role }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Role updated for <Link to={'/BuildingAccess/People/' + role.RoleView.PERSON_ID}>{role.RoleView.PERSON_NAME}</Link>
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
		
	renderSpeedtype(props) {
		if (this.isEmpty(this.state.role.RoleView.PI_ROLE_ID)) {
			return (<span style={{ fontStyle: 'italic' }}>(for storeroom and cylinder check out)</span>);
		} else {
			return props.editor.renderFromCellDefinition();
		}
	}

	render() {
		var personRoute = '/BuildingAccess/People/' + this.state.role.RoleView.PERSON_ID;
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col xs={12} style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Role {this.state.role.RoleView.ROLE_ID} for <Link to={personRoute}>{this.state.role.RoleView.PERSON_NAME}</Link></span>
			      <ChemEdit parent={this} columns={roleColumns} data={this.state.role} user={this.props.user} renderKey={this.state.renderKey} 
				     onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
				    <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={12}>
					      <Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
						</Col>
					</Form.Group>
				  </ChemEdit>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const roleColumns = [
	{ Header: 'Start Date', accessor: 'RoleView.BEG_ROLE_DATE', required: true, type: 'date', row: 0, col: 0 },
	{ Header: 'End Date', accessor: 'RoleView.END_ROLE_DATE', type: 'date', row: 0, col: 1,
      subscript: '<span style="font-size: 12px; font-style: italic"><u>NOTE</u>: Entering a date will request immediate end of appointment.</span>'	},
	{ Header: 'Appointment Type', accessor: 'RoleView.TYPE_ID', type: 'select', required: true, row: 0, col: 2,
		options: { value: 'TYPE_ID', label: 'TYPE_NAME', entity: 'SELECT_ROLE_TYPE_VW', order: 'TYPE_NAME' } },
	{ Header: 'PI', accessor: 'RoleView.PI_ROLE_ID', type: 'select', required: true, row: 1, col: 0,
		options: { value: 'ROLE_ID', label: 'PI_NAME', entity: 'PI_VW', order: 'PI_NAME' }},
	{ Header: 'Designated PI Delegate', accessor: 'Attributes[0].DELEGATE', type: 'checkbox', row: 1, col: 1 },
	{ Header: 'Supervisor', accessor: 'RoleView.SUPERVISOR', type: 'select', row: 1, col: 2,
		options: { value: 'ROLE_ID', label: 'SUP_NAME', entity: 'SUPERVISORS_VW', order: 'SUP_NAME' } },	
	{ Header: 'Payroll Title', accessor: 'RoleView.PAYROLL_TITLE', type: 'select', row: 2, col: 0,
		options: { value: 'TITLE_ID', label: 'TITLE_NAME', entity: 'PAYROLL_TITLE',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }, order: 'TITLE_NAME' } },
	{ Header: 'Work Title', accessor: 'RoleView.WORK_TITLE', type: 'select', row: 2, col: 1,
		subscript: '<span style="font-size: 12px; font-style: italic">(for staff and academic only)</span>',
		options: { value: 'WORK_TITLE_ID', label: 'WORK_TITLE_NAME', entity: 'WORK_TITLE',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }, order: 'WORK_TITLE_NAME' } },			
	{ Header: 'Unit', accessor: 'RoleView.UNIT_ID', type: 'select', row: 2, col: 2,
		subscript: '<span style="font-size: 12px; font-style: italic">(for staff and academic only)</span>',
		options: { value: 'UNIT_ID', label: 'UNIT_NAME', entity: 'ORG_UNIT',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }, order: 'UNIT_NAME' } },
	{ Header: 'Office Location', accessor: 'WorkContacts[0].ROOM_ID', type: 'select', row: 3, col: 0,
		options: { value: 'ROOM_ID', label: 'LOCATION', entity: 'ROOMS_VW', order: 'LOCATION' } },
	{ Header: 'Office Phone', accessor: 'WorkContacts[0].WORK_NO_PHONE1', required: true, row: 3, col: 1 },
	{ Header: 'Fax', accessor: 'WorkContacts[0].WORK_NO_FAX', row: 3, col: 2 },
	{ Header: 'Campus Email', accessor: 'WorkContacts[0].WORK_NO_EMAIL', required: true, row: 4, col: 0 },
	{ Header: 'Hide Email in Directory', accessor: 'WorkContacts[0].HIDE_EMAIL', type: 'checkbox', row: 4, col: 1 },
	{ Header: 'Lab Phone', accessor: 'WorkContacts[0].WORK_NO_PHONE2', row: 4, col: 2 },
	{ Header: 'Default Speedtype', accessor: 'RoleView.SPEEDTYPE', type: 'select', row: 5, col: 0, 
		Cell: props => props.parent.renderSpeedtype(props),
		options: { value: 'SPEEDTYPE', label: 'SPEEDTYPE_INFO', entity: 'ROLE_SPEEDTYPES_VW', order: 'SPEEDTYPE_INFO',
			search: { Attribute: 'ROLE_ID', Operator: '=', LongAccessor: 'RoleView.PI_ROLE_ID' }}},
	{ Header: 'Off Campus Location', accessor: 'WorkContacts[0].OUTSIDE_LOCATION', row: 5, col: 1,
		subscript: '<span style="font-size: 12px; font-style: italic">(room)</span>' },
	{ Header: 'Off Campus Location', accessor: 'WorkContacts[0].BUILDING_ID', row: 5, col: 2, type: 'select',
		subscript: '<span style="font-size: 12px; font-style: italic">(building)</span>', 
		options: { value: 'BUILDING_ID', label: 'BUILDING_NAME', entity: 'BUILDING', order: [ 'BUILDING_NAME' ],
			search: { Operator: 'and', children: [ { Attribute: 'CHEMISTRY_FLAG', Operator: 'is null' }, { Attribute: 'DELETE_FLAG', Operator: 'is null' } ] } } },
	{ Header: 'Group Website URL', accessor: 'WorkContacts[0].WORK_NO_URL', row: 6, col: 0 },
	{ Header: 'Campus Lab Location', accessor: 'WorkContacts[0].LAB_LOCATION', row: 6, col: 1,
		subscript: '<span style="font-size: 12px; font-style: italic">(room)</span>' },
	{ Header: 'Campus Lab Location', accessor: 'WorkContacts[0].LAB_BUILDING', row: 6, col: 2,
		subscript: '<span style="font-size: 12px; font-style: italic">(building)</span>',
		type: 'select', options: { value: 'BUILDING_ID', label: 'BUILDING_NAME', entity: 'BUILDING', order: 'BUILDING_NAME', 
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' } } },
	{ Header: 'Alternate Location', accessor: 'WorkContacts[0].ALT_LOCATION', row: 7, col: 1,
		subscript: '<span style="font-size: 12px; font-style: italic">(room)</span>' },
	{ Header: 'Alternate Location', accessor: 'WorkContacts[0].ALT_BUILDING', row: 7, col: 2,
		subscript: '<span style="font-size: 12px; font-style: italic">(building)</span>',
		type: 'select', options: { value: 'BUILDING_ID', label: 'BUILDING_NAME', entity: 'BUILDING', order: 'BUILDING_NAME', 
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' } } },
	{ Header: 'Created', accessor: 'CREATED', editable: false, row: 8, col: 0 },
	{ Header: 'Modified', accessor: 'MODIFIED', editable: false, row: 8, col: 1 },
	{ Header: 'Comments', accessor: 'RoleView.COMMENTS', type: 'textarea', row: 7, col: 2,
		subscript: '<span style="font-size: 12px; font-style: italic">Will show in confirmation email</span>' }	
];

export default withRouter(Role);
