import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import ChemTable from "../ChemTable";
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Tabs from '../Tabs';

class Key extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			key: {
				KEY_ID: null,
				KEY_NUMBER: '',
				KEY_NAME: '',
				KEY_DESC: '',
				KEY_TYPE: null,
				TYPE_NAME: null,
				KEY_MAKER: null,
				KEY_INVENTORY: null,
				KEY_TOTAL: null,
				KEY_STATUS: null,
				DATE_LOST: null,
				DELETE_FLAG: null,
				CHANGE_INFO: {
					COMMENTS: '',
					CREATE_DATE: null,
					CREATE_BY: null,
					MODIF_DATE: null,
					MODIF_BY: null
				},
				CREATED: '',
				MODIFIED: ''
			},
			checkout: {
				KEY_ID: null,
				KEY_NUMBER: '',
				TYPE_NAME: '',
				LOCATIONS: '',
				PERSON_ID: null,
				OUT_DATE: this.dateTimeToMVC(new Date()),
				RETURN_DATE: null,
				LETTER_SENT: null,
				RETURN_DEPOSIT: null,
				POLICE_RPT: null,
				AUTHORIZED_BY: '',
				DEPOSIT: '',
				CHANGE_INFO: {
					COMMENTS: '',
					CREATE_DATE: null,
					CREATE_BY: null,
					MODIF_DATE: null,
					MODIF_BY: null
				}
			},
			checkedOutTo: null,
			rooms: [],
			history: [],
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadKey();
	}

	loadKey(event) {
		var self = this;
		
		if (this.props.params.keyId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'KEY_INFO_VW',
					search: { Attribute: 'KEY_ID', Operator: '=', LongValue: this.props.params.keyId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length === 1) {
						var key = data.Data[0];
						
						// convert flags from oracle style
						key.DELETE_FLAG = key.DELETE_FLAG === 'Y';
												
						// compose change info
						key.CREATED = self.composeChangeInfo(key.CHANGE_INFO.CREATE_BY, key.CHANGE_INFO.CREATE_DATE);
						key.MODIFIED = self.composeChangeInfo(key.CHANGE_INFO.MODIF_BY, key.CHANGE_INFO.MODIF_DATE);
						
						self.mergeState({
							key: key,
							checkout: {
								KEY_ID: key.KEY_ID,
								KEY_NUMBER: key.KEY_NUMBER,
								TYPE_NAME: key.TYPE_NAME
							},
							renderKey: self.state.renderKey + 1
						}, () => {
							self.loadRooms(event);
						});
					} else {
						self.showAlert('Key Error', data.Data.length + ' keys found for ID ' + self.props.params.keyId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				key: {
					KEY_ID: '(new)'
				}
			});
		}
	}
	
	loadRooms(event) {
		var self = this;
		
		// make copy of rooms query
		var query = this.copyObject(rooms.query);
		
		// add key ID to search parameter
		query.search.LongValue = this.state.key.KEY_ID;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Home/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: query.entity,
				search: query.search,
				order: query.order,
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				// assign id's so table will display correctly
				var locations = '';
				for (var i = 0; i < data.Data.length; i++) {
					data.Data[i].id = i;
					if (i > 0) locations += ', ';
					locations += data.Data[i].LOCATION;
				}
				if (locations === '') locations = '(none)';
				self.mergeState({
					checkout: {
						LOCATION: locations
					},
					rooms: data.Data,
					renderKey: self.state.renderKey + 1
				}, () => {
					self.loadHistory(event);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	loadHistory(event) {
		var self = this;
		
		// make copy of history query
		var query = this.copyObject(history.query);
		
		// add key ID to search parameter
		query.search.LongValue = this.state.key.KEY_ID;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/Home/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: query.entity,
				search: query.search,
				order: query.order,
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				// assign id's so table will display correctly
				var checkedOutTo = null;
				for (var i = 0; i < data.Data.length; i++) {
					data.Data[i].id = i;
					if (data.Data[i].RETURN_DATE === null) checkedOutTo = self.copyObject(data.Data[i]);
				}
				self.mergeState({
					history: data.Data,
					checkedOutTo: checkedOutTo,
					renderKey: self.state.renderKey + 1
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}

	onChange(accessor, value) {
		var key = this.copyObject(this.state.key);
		this.setByAccessor(key, accessor, value);
		this.mergeState({ key: key, renderKey: this.state.renderKey + 1 });
	}
	
	onChangeCheckout(accessor, value) {
		var checkout = this.copyObject(this.state.checkout);
		this.setByAccessor(checkout, accessor, value);
		this.mergeState({ checkout: checkout, renderKey: this.state.renderKey + 1 });
	}

	deleteKey(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Key', 'Delete Key ' + this.props.params.keyId + '?', (okClicked) => {
			if (okClicked) {
				// prepare key for deletion
				var key = self.copyObject(self.state.key);
				key.DELETE_FLAG = 'Y';
				key.CHANGE_INFO.CREATE_DATE = self.dateTimeToMVC(self.getDate(key.CHANGE_INFO.CREATE_DATE));
				key.CHANGE_INFO.MODIF_DATE = self.dateTimeToMVC(new Date());
				key.CHANGE_INFO.MODIF_BY = self.props.user.name;
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'KEY_INFO', data: key }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Key {self.props.params.keyId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
		
	onSubmit(event) {
		var self = this;		
		var dtNow = this.dateTimeToMVC(new Date());
		var currentUser = this.props.user.name;
		
		// make copy of data
		var key = this.copyObject(this.state.key);
		var url;
		
		// if this is a new key, set ID to sequence name
		if (!this.props.params.keyId) {
			url = this.getConfig().host + '/Home/Insert';
			key.KEY_ID = 'key_id_seq.nextval';
			key.CHANGE_INFO.CREATE_DATE = dtNow;
			key.CHANGE_INFO.CREATE_BY = currentUser;
			key.CHANGE_INFO.MODIF_DATE = null;
			key.CHANGE_INFO.MODIF_BY = null;
		} else {
			url = this.getConfig().host + '/Home/Update';
			// convert dates to MVC
			key.CHANGE_INFO.CREATE_DATE = this.dateTimeToMVC(this.getDate(key.CHANGE_INFO.CREATE_DATE));
			key.CHANGE_INFO.MODIF_DATE = dtNow;
			key.CHANGE_INFO.MODIF_BY = currentUser;
		}			

		// convert flags to oracle style
		key.DELETE_FLAG = key.DELETE_FLAG ? 'Y' : null;
		
		this.ajax({
			type: 'POST',
			url: url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'KEY_INFO', data: key }
		}).done(function (data) {
			if (data.Success) {
				var keyId = self.props.params.keyId ? key.KEY_ID : data.ID;
				var verb = self.props.params.keyId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Key <Link to={'/BuildingAccess/Keys/' + keyId}>{keyId}</Link> {verb}. <Link to={'/BuildingAccess/Keys/new'}>Add another key</Link>
					</>); 
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	onSubmitCheckout(event) {
		var self = this;
		
		// prepare checkout record for insert
		var checkout = this.copyObject(this.state.checkout);
		checkout.KEY_ADMIN_ID = 'key_admin_id_seq.nextval';
		checkout.CHANGE_INFO.CREATE_DATE = this.dateTimeToMVC(new Date());
		checkout.CHANGE_INFO.CREATE_BY = this.props.user.name;
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/Home/Insert',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'KEY_ADMIN', data: checkout }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Key <Link to={'/BuildingAccess/Keys/' + checkout.KEY_ID}>{checkout.KEY_ID}</Link> checked out.
					</>); 
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col xs={12} style={{ paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Key {this.state.key.KEY_ID}</div>
				  <Tabs>
					<div label='General Info'>
				      <Container fluid>
					    <Row>
						  <Col style={{ marginBottom: '20px' }}>
						    <ChemEdit parent={this} columns={keyColumns} data={this.state.key} user={this.props.user} renderKey={this.state.renderKey} 
							   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
								<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
								{ this.props.params.keyId && <Button id="delete" variant="warning" type="button" className="float-end"
									style={{ marginRight: '20px' }} onClick={(event) => this.deleteKey(event)}>Delete</Button> }
						    </ChemEdit>
						  </Col>
					    </Row>
					  </Container>
					</div>
					<div label='Locations'>
					  <Container fluid>
					    <Row>
						  <Col style={{ marginBottom: '20px' }}>
						    <ul>
							  <li>Changes to series/type will not display here until all records are saved</li>
							  <li>Use <Link to='/BuildingAccess/KeyTypes'>Key Types</Link> to edit locations</li>
						    </ul>
						    <ChemTable renderKey={this.state.renderKey} parent={this} data={this.state.rooms} columns={rooms.columns} name='BuildingAccess_Key_Rooms' />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Checkout'>
					  <Container fluid>
					    <Row>
						{this.state.key.KEY_ID === '(new)' ?
						  <Col style={{ marginBottom: '20px' }}>
						    <ul>
							  <li>Key cannot be checked out until it has been created</li>
						    </ul>						    
						  </Col> :
						  <Col style={{ marginBottom: '20px' }}>
						    <ul>
							  <li>Save any changes to key before checking out</li>
							  <li>Current key information:
							    <ul>
									<li>Number: {this.state.checkout.KEY_NUMBER}</li>
									<li>Type: {this.state.checkout.TYPE_NAME}</li>
									<li>Location(s): {this.state.checkout.LOCATION}</li>
								</ul>
							  </li>
						    </ul>
							{ this.state.checkedOutTo ?
								<>This key must be <Link to={'/BuildingAccess/IssuedKeys/' + this.state.checkedOutTo.KEY_ADMIN_ID}>returned</Link> from <Link
									to={'/BuildingAccess/People/' + this.state.checkedOutTo.PERSON_ID}>{this.state.checkedOutTo.PERSON_NAME}</Link> before checkout.</> :
								<ChemEdit parent={this} columns={checkoutColumns} data={this.state.checkout} user={this.props.user} renderKey={this.state.renderKey} 
									onChange={(accessor, value) => this.onChangeCheckout(accessor, value)} onSubmit={(event) => this.onSubmitCheckout(event)}>
									<Button id="checkout" variant="warning" type="submit" className="float-end">Checkout</Button>
								</ChemEdit>
							}
							<div style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px', marginBottom: '10px' }}>History</div>
						    <ChemTable renderKey={this.state.renderKey} parent={this} data={this.state.history} columns={history.columns} name='BuildingAccess_Key_History' />
						  </Col>
						}
						</Row>
					  </Container>
					</div>
				  </Tabs>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const keyColumns = [
	{ Header: 'Number', accessor: 'KEY_NUMBER', required: true, row: 0, col: 0 },
	{ Header: 'Name', accessor: 'KEY_NAME', row: 0, col: 1 },
	{ Header: 'Series', accessor: 'KEY_TYPE', type: 'select', required: true, row: 0, col: 2,
		options: { value: 'TYPE_ID', label: 'TYPE_NAME', entity: 'KEY_TYPE', order: 'TYPE_NAME',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Description', accessor: 'KEY_DESC', row: 1, col: 0 },
	{ Header: 'Maker', accessor: 'KEY_MAKER', type: 'select', required: true, row: 1, col: 1,
		options: { value: 'MAKER_ID', label: 'MAKER_NAME', entity: 'KEY_MAKER', order: 'MAKER_NAME',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},	
	{ Header: 'Status', accessor: 'KEY_STATUS', type: 'select', row: 1, col: 2,
		options: { value: 'STATUS_ID', label: 'STATUS_NAME', entity: 'KEY_STATUS', order: 'STATUS_NAME' }},
	{ Header: 'Comments', accessor: 'COMMENTS', row: 2, col: 0 },
	{ Header: 'Created', accessor: 'CREATED', editable: false, row: 2, col: 1 },
	{ Header: 'Modified', accessor: 'MODIFIED', editable: false, row: 2, col: 2 }
];

const checkoutColumns = [
	{ Header: 'Person', accessor: 'PERSON_ID', type: 'select', row: 0, col: 0, required: true,
		options: { value: 'PERSON_ID', label: 'PERSON_NAME', entity: 'PEOPLE_SELECT_VW', order: 'PERSON_NAME' } },
	{ Header: 'Date', accessor: 'OUT_DATE', type: 'date', row: 0, col: 1, required: true, },
	{ Header: 'Authorized By', accessor: 'AUTHORIZED_BY', row: 0, col: 2 },
	{ Header: 'Deposit', accessor: 'DEPOSIT', row: 1, col: 0 },
	{ Header: 'Payment Type', accessor: 'PAYMENT_TYPE', type: 'select', row: 1, col: 1,
		options: [{ value: null, label: 'Select...' },{ value: 'Personal Check', label: 'Personal Check' },{ value: 'Money Order', label: 'Money Order' },{ value: 'Other', label: 'Other' }]},
	{ Header: 'Comments', accessor: 'CHANGE_INFO.COMMENTS', type: 'textarea', row: 1, col: 2 }
];

const rooms = {
	query: {
		entity: 'KEY_ROOM_VW',
		search: { Attribute: 'KEY_ID', Operator: '=' },
		order: ['KEY_TYPE', 'BUILDING']
	},
	columns: [
		{ Header: 'Key Type', accessor: 'KEY_TYPE', width: 70,
			Cell: props => <Link to={'/BuildingAccess/KeyTypes/' + props.value}>{props.value}</Link> },
		{ Header: 'Location', accessor: 'LOCATION', width: 150 },
		{ Header: 'Comments', accessor: 'COMMENTS', width: 200 },
		{ Header: 'Modified', accessor: 'MODIFIED', width: 200 }
	]
};

const history = {
	query: {
		entity: 'KEY_HISTORY_VW',
		search: { Attribute: 'KEY_ID', Operator: '=' },
		order: 'OUT_DATE desc'
	},
	columns: [
		{ Header: 'KEY_ADMIN_ID', accessor: 'KEY_ADMIN_ID', show: false },
		{ Header: 'PERSON_ID', accessor: 'PERSON_ID', show: false },
		{ Header: 'Name', accessor: 'PERSON_NAME', width: 200,
			Cell: props => <Link to={'/BuildingAccess/People/' + props.row.values.PERSON_ID}>{props.value}</Link> },
		{ Header: 'Person Status', accessor: 'PERSON_STATUS', width: 150 },
		{ Header: 'Date Out', accessor: 'OUT_DATE', width: 150 },
		{ Header: 'Returned', accessor: 'RETURN_DATE', width: 150,
			Cell: props => <>{props.value === null ? 
				<Link to={'/BuildingAccess/IssuedKeys/' + props.row.values.KEY_ADMIN_ID}>Return Key</Link> : 
				<>{props.value}</>
			}</> },
		{ Header: 'Police Rpt.', accessor: 'POLICE_RPT', width: 150 }
	]
};

export default withRouter(Key);
