import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { ArrowLeftRight, ExclamationTriangle, PencilSquare, PlusSquare, Upc } from 'react-bootstrap-icons';

class Cylinders extends ChemComponent {
	constructor(props) {
		super(props);

		var search = {
			CONTAINER_ID: null,
			SALE_STATUS: null,
			treeMenuClickCount: 0 // don't modify in this class
		};
		
		var cylinderListInit = this.copyTable(cylinderList);
		
		if (props.search) {
			var propsSearch = this.copyObject(props.search);
			this.mergeObject(search, propsSearch);
			search = propsSearch;
			cylinderListInit.query.search = this.constructSearch(search);
		}

		this.state = {
			cylinderList: cylinderListInit,
			search: search,
			searchCount: null,
			renderKey: 0
		};
	}

	componentDidUpdate(prevProps) {
		if (this.state.search.treeMenuClickCount !== this.props.search.treeMenuClickCount) {
			var newCylinderList = this.copyTable(cylinderList);
			newCylinderList.query.search = this.constructSearch(this.props.search);
			this.mergeState({
				cylinderList: newCylinderList,
				search: this.copyObject(this.props.search),
				searchCount: null,
				renderKey: this.state.renderKey + 1
			});
		}
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newCylinderList = this.copyTable(cylinderList);
		newCylinderList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			cylinderList: newCylinderList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}

	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.CONTAINER_ID)) search.Children.push({ Attribute: 'CONTAINER_ID', Operator: '=', LongValue: data.CONTAINER_ID });
		if (!this.isEmpty(data.SALE_STATUS)) search.Children.push({ Attribute: 'SALE_STATUS', Operator: '=', StringValue: data.SALE_STATUS });

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	addCylinder() {
		this.props.navigate('/HeLAD/Cylinders/new');
	}
	
	editCylinder(cylinderId) {
		this.props.setCylinderEditable(true);
		this.props.navigate('/HeLAD/Cylinders/' + cylinderId);		
	}

	viewCylinder(cylinderId) {
		this.props.setCylinderEditable(false);
		this.props.navigate('/HeLAD/Cylinders/' + cylinderId);		
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	renderBarcodeForm(cylinderId) {
		
		var barcodeData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			cylinderid: cylinderId
		});
		
		return (
			<form action={this.getConfig().host + '/HeLAD/CylinderBarcode'} method='POST'>
				{barcodeData.map((pdfFormField, index) => {
					return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
				})}
				<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Print Barcode</Popover.Header></Popover>}>
					<input type='image' src={this.getConfig().host + '/Content/Icons/upc.png'} alt="Print Barcode" />
				</OverlayTrigger>
			</form>
		);
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Cylinders',
			columns: exportColumns,
			entity: 'LIQUID_AIR.CONTAINERS_VW',
			search: this.constructSearch(this.state.search),
			order: 'CONTAINER_ID'
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Cylinders</span>
						<ChemEdit parent={this} user={this.props.user} columns={cylinderSearch} data={this.state.search}
						  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='HeLAD_Cylinders' table={this.state.cylinderList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='185px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const cylinderSearch = [
	{ Header: 'Container', accessor: 'CONTAINER_ID', type: 'select', row: 0, col: 0,
		options: { value: 'CONTAINER_ID', label: 'CONTAINER_DESC', entity: 'LIQUID_AIR.CONTAINER_SELECT_VW', order: 'CONTAINER_ID' }},
	{ Header: 'Sale Status', accessor: 'SALE_STATUS', type: 'select', row: 0, col: 1,
		options: [{ value: '', label: 'Select...' },{ value: 'OUT', label: 'Out' },{ value: 'IN', label: 'Returned' }]}
];

const cylinderList = {
	query: { entity: 'LIQUID_AIR.CONTAINERS_VW', search: null, order: 'CONTAINER_ID' },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addCylinder()} />, accessor: 'id', width: 25, 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editCylinder(props.row.values.CONTAINER_ID)} /> },
		{ Header: props => <Upc />, accessor: 'drag', width: 25,
			Cell: props => props.parent.renderBarcodeForm(props.row.values.CONTAINER_ID) },
		{ Header: 'ID', accessor: 'CONTAINER_ID', width: 50,
			Cell: props => <HashLink onClick={() => props.parent.viewCylinder(props.row.values.CONTAINER_ID)}>{props.value}</HashLink> },
		{ Header: 'MFR Code', accessor: 'MFR_CODE', width: 80, },
		{ Header: 'College ID', accessor: 'COLLEGE_ID', width: 175, },
		{ Header: 'Qty', accessor: 'UNIT_QTY', width: 50 },
		{ Header: 'Content', accessor: 'PRODUCT', width: 90 },
		{ Header: 'Status', accessor: 'CYL_STATE', width: 70 },
		{ Header: '', accessor: 'LINE_ITEM_CT', show: false },
		{ Header: props => <ArrowLeftRight />, accessor: 'SALE_STATUS', width: 80, Cell: props => props.row.values.LINE_ITEM_CT <= 1 ? props.value :
			<>{props.value} <ExclamationTriangle style={{ cursor: 'pointer' }} onClick={() => props.parent.viewDuplicateOuts(props.row.values.CONTAINER_ID)} /></> },
		{ Header: 'Sale ID', accessor: 'SALES_ID', width: 75 },
		{ Header: 'Out Date', accessor: 'OUT_DATE_DISPLAY', width: 100 },
		{ Header: 'Return Date', accessor: 'RETURN_DATE_DISPLAY', width: 100 },
		{ Header: 'Speedtype', accessor: 'COST_CENTER', width: 125 },
		{ Header: 'Customer', accessor: 'CUSTOMER_NAME', width: 200 },
		{ Header: 'PI', accessor: 'PI_NAME', width: 200 }
	]
};

const exportColumns = [
	{ Header: 'ID', accessor: 'CONTAINER_ID', width: 10 },
	{ Header: 'MFR Code', accessor: 'MFR_CODE', width: 16, },
	{ Header: 'College ID', accessor: 'COLLEGE_ID', width: 35, },
	{ Header: 'Qty', accessor: 'UNIT_QTY', width: 10 },
	{ Header: 'Content', accessor: 'PRODUCT', width: 18 },
	{ Header: 'Status', accessor: 'CYL_STATE', width: 14 },
	{ Header: 'In/Out', accessor: 'SALE_STATUS', width: 16 },
	{ Header: 'Sale ID', accessor: 'SALES_ID', width: 15 },
	{ Header: 'Out Date', accessor: 'OUT_DATE_DISPLAY', width: 20 },
	{ Header: 'Return Date', accessor: 'RETURN_DATE_DISPLAY', width: 20 },
	{ Header: 'Speedtype', accessor: 'COST_CENTER', width: 25 },
	{ Header: 'Customer', accessor: 'CUSTOMER_NAME', width: 40 },
	{ Header: 'PI', accessor: 'PI_NAME', width: 40 }
];

export default withRouter(Cylinders);
