import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class Vendor extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			editable: props.editable,
			vendor: {
				VENDOR_ID: '' 
			},
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadVendor();
	}

	loadVendor(event) {
		var self = this;
		
		if (this.props.params.vendorId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Stores/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'PO_VENDOR',
					search: { Attribute: 'VENDOR_ID', Operator: '=', LongValue: this.props.params.vendorId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var vendor = data.Data[0];
						self.mvc2js(vendor, vendorColumns);
						vendor.CREATED = self.composeChangeInfo(vendor.CREATED_BY, vendor.DATE_CREATED);
						vendor.MODIFIED = self.composeChangeInfo(vendor.MODIF_BY, vendor.MODIF_DATE);
							
						self.mergeState({
							vendor: vendor,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Vendor Error', data.Data.length + ' Vendors found for ID ' + self.props.params.vendorId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				vendor: {
					VENDOR_ID: '(new)',
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var vendor = this.copyObject(this.state.vendor);
		this.setByAccessor(vendor, accessor, value);
		this.mergeState({ vendor: vendor, renderKey: this.state.renderKey + 1 });
	}
	
	onDelete(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete', 'Delete Vendor ' + this.props.params.vendorId + '?', (okClicked) => {
			if (okClicked) {
				// prepare tax rate for deletion
				var vendor = {
					VENDOR_ID: self.props.params.vendorId,
					DELETE_FLAG: 'Y',
					MODIF_BY: self.props.user.name,
					MODIF_DATE: self.dateTimeToMVC(new Date())
				};
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'STORES.PO_VENDOR', data: vendor }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Vendor {self.props.params.vendorId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}	
		
	onSubmit(event) {
		var self = this;		
		var url = null;
		
		// make copy of data
		var vendor = this.copyObject(this.state.vendor);

		this.js2mvc(vendor, vendorColumns);
		
		// if this is a new tax rate
		if (!this.props.params.vendorId) {
			// set ID to null
			vendor.VENDOR_ID = 'vendor_id_seq.nextval';
			vendor.CREATED_BY = this.props.user.name;
			vendor.DATE_CREATED = this.dateTimeToMVC(new Date());
			url = '/Home/Insert';
		} else {
			vendor.MODIF_BY = this.props.user.name;
			vendor.MODIF_DATE = this.dateTimeToMVC(new Date());
			url = '/Home/Update';
		}
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'STORES.PO_VENDOR', data: vendor }
		}).done(function (data) {
			if (data.Success) {
				var vendorId = self.props.params.vendorId || data.ID;
				var verb = self.props.params.vendorId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Vendor <Link to={'/Stores/Vendors/' + vendorId}>{vendorId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Vendor {this.state.vendor.VENDOR_ID}&nbsp;&nbsp;
							{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
							  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
						  </div>
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={vendorColumns} data={this.state.vendor} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)} editable={this.state.editable}>
						  <Form.Group>
							{this.state.editable && <Button id="save" variant="warning" type="submit" className="float-end">Save</Button>}
							{this.state.editable && this.props.params.vendorId && <Button id="delete" variant="warning" type="button" className="float-end"
							  style={{ marginRight: '20px' }} onClick={(event) => this.onDelete(event)}>Delete</Button>} 
						  </Form.Group>
						</ChemEdit>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const vendorColumns = [
	{ Header: 'Vendor Name', accessor: 'VENDORNAME', row: 0, col: 0 },
	{ Header: 'Bear Buy Supplier ID', accessor: 'SUPPLIER_ID', row: 0, col: 1 },
	{ Header: 'BFS Code', accessor: 'BFS_SUP_NUM', row: 0, col: 2 },
	{ Header: 'Street Address', accessor: 'ADDRESS', row: 1, col: 0 },
	{ Header: 'City', accessor: 'CITY', row: 1, col: 1 },
	{ Header: 'State', accessor: 'STATE', type: 'select', row: 1, col: 2,
		options: [
			{ value: 'AL', label: 'ALABAMA' },
			{ value: 'AK', label: 'ALASKA' },
			{ value: 'AZ', label: 'ARIZONA' },
			{ value: 'AR', label: 'ARKANSAS' },
			{ value: 'CA', label: 'CALIFORNIA' },
			{ value: 'CO', label: 'COLORADO' },
			{ value: 'CT', label: 'CONNECTICUT' },
			{ value: 'DE', label: 'DELAWARE' },
			{ value: 'DC', label: 'DISTRICT OF COLUMBIA' },
			{ value: 'FL', label: 'FLORIDA' },
			{ value: 'GA', label: 'GEORGIA' },
			{ value: 'HI', label: 'HAWAII' },
			{ value: 'ID', label: 'IDAHO' },
			{ value: 'IL', label: 'ILLINOIS' },
			{ value: 'IN', label: 'INDIANA' },
			{ value: 'IA', label: 'IOWA' },
			{ value: 'KS', label: 'KANSAS' },
			{ value: 'KY', label: 'KENTUCKY' },
			{ value: 'LA', label: 'LOUISIANA' },
			{ value: 'ME', label: 'MAINE' },
			{ value: 'MD', label: 'MARYLAND' },
			{ value: 'MA', label: 'MASSACHUSETTS' },
			{ value: 'MI', label: 'MICHIGAN' },
			{ value: 'MN', label: 'MINNESOTA' },
			{ value: 'MS', label: 'MISSISSIPPI' },
			{ value: 'MO', label: 'MISSOURI' },
			{ value: 'MT', label: 'MONTANA' },
			{ value: 'NE', label: 'NEBRASKA' },
			{ value: 'NV', label: 'NEVADA' },
			{ value: 'NH', label: 'NEW HAMPSHIRE' },
			{ value: 'NJ', label: 'NEW JERSEY' },
			{ value: 'NM', label: 'NEW MEXICO' },
			{ value: 'NY', label: 'NEW YORK' },
			{ value: 'NC', label: 'NORTH CAROLINA' },
			{ value: 'ND', label: 'NORTH DAKOTA' },
			{ value: 'OH', label: 'OHIO' },
			{ value: 'OK', label: 'OKLAHOMA' },
			{ value: 'OR', label: 'OREGON' },
			{ value: 'PA', label: 'PENNSYLVANIA' },
			{ value: 'PR', label: 'PUERTO RICO' },
			{ value: 'RI', label: 'RHODE ISLAND' },
			{ value: 'SC', label: 'SOUTH CAROLINA' },
			{ value: 'SD', label: 'SOUTH DAKOTA' },
			{ value: 'TN', label: 'TENNESSEE' },
			{ value: 'TX', label: 'TEXAS' },
			{ value: 'UT', label: 'UTAH' },
			{ value: 'VT', label: 'VERMONT' },
			{ value: 'VA', label: 'VIRGINIA' },
			{ value: 'WA', label: 'WASHINGTON' },
			{ value: 'WV', label: 'WEST VIRGINIA' },
			{ value: 'WI', label: 'WISCONSIN' },
			{ value: 'WY', label: 'WYOMING' }
		]
	},
	{ Header: 'Zip', accessor: 'ZIP', row: 2, col: 0 },
	{ Header: 'Country', accessor: 'COUNTRY', row: 2, col: 1 },
	{ Header: 'Phone 1', accessor: 'PHONE1', row: 2, col: 2 },
	{ Header: 'Phone 2', accessor: 'PHONE2', row: 3, col: 0 },
	{ Header: 'Fax', accessor: 'FAX', row: 3, col: 1 },
	{ Header: 'URL', accessor: 'URL', row: 3, col: 2 },
	{ Header: 'Contact', accessor: 'CONTACT', row: 4, col: 0 },
	{ Header: 'E-mail', accessor: 'EMAIL', row: 4, col: 1 },
	{ Header: 'Stores Customer Account', accessor: 'STORES_ACCOUNT', row: 4, col: 2 },
	{ Header: 'Comments', accessor: 'COMMENTS', type: 'textarea', row: 5, col: 0 },
	{ Header: 'Created', accessor: 'CREATED', row: 5, col: 1, editable: false }, 
	{ Header: 'Modified', accessor: 'MODIFIED', row: 5, col: 2, editable: false }
];

export default withRouter(Vendor);
