import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class Dewar extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			editable: props.editable,
			dewar: {
				REQUEST_ID: '' 
			},
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadDewar();
	}

	loadDewar(event) {
		var self = this;
		
		if (this.props.params.requestId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/HeLAD/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'DEWARS_VW',
					search: { Attribute: 'REQUEST_ID', Operator: '=', LongValue: this.props.params.requestId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var dewar = data.Data[0];
						self.mvc2js(dewar, dewarColumns);
							
						self.mergeState({
							dewar: dewar,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Dewar Error', data.Data.length + ' Dewars found for ID ' + self.props.params.requestId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				dewar: {
					REQUEST_ID: '(new)',
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var dewar = this.copyObject(this.state.dewar);
		this.setByAccessor(dewar, accessor, value);
		this.mergeState({ dewar: dewar, renderKey: this.state.renderKey + 1 });
	}
		
	cancelDewar(event) {
		var self = this;
		
		this.showOKCancel('Confirm Cancel Dewar Request', 'Cancel Dewar Request ' + this.props.params.requestId + '?', (okClicked) => {
			if (okClicked) {
				// prepare dewar for cancellation		
				var dewar = {
					REQUEST_ID: self.props.params.requestId,
					CANCEL_FLAG: 1,
					REQ_MODIF_BY: self.props.user.name,
					LAST_MODIF: self.dateTimeToMVC(new Date())
				};
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'LIQUID_AIR.REQUESTS', data: dewar }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Dewar request {self.props.params.requestId} cancelled.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
		
	onSubmit(event) {
		var self = this;		
		var url = null;
		
		// make copy of data
		var dewar = this.copyObject(this.state.dewar);

		this.js2mvc(dewar, dewarColumns);
		dewar.LAST_MODIF = this.dateTimeToMVC(new Date());
		dewar.REQ_MODIF_BY = this.props.user.name;
		
		// if this is a new dewar
		if (!this.props.params.requestId) {
			// set ID to sequence name
			dewar.REQUEST_ID = 'seq_request_id.nextval';
			url = '/Home/Insert';
		} else {
			url = '/Home/Update';
		}
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'LIQUID_AIR.REQUESTS', data: dewar }
		}).done(function (data) {
			if (data.Success) {
				var requestId = self.props.params.requestId || data.ID;
				var verb = self.props.params.requestId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Dewar <Link to={'/HeLAD/Dewars/' + requestId}>{requestId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		
		var receiptData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			requestid: this.props.params.requestId
		});

		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col xs={6}>
						  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Dewar {this.state.dewar.REQUEST_ID}&nbsp;&nbsp;
							{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
							  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
						  </div>
					  </Col>
					  <Col xs={6}>
						<form action={this.getConfig().host + '/HeLAD/DewarReceipt'} method='POST'>
							{receiptData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Print Receipt</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="PrintReceipt" />
							</OverlayTrigger>
						</form>					  
					  </Col>
					</Row>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={dewarColumns} data={this.state.dewar} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)} editable={this.state.editable}>
						  <Form.Group>
							{this.state.editable && <Button id="save" variant="warning" type="submit" className="float-end">Save</Button>}
							{this.state.editable && this.props.params.requestId && <Button id="delete" variant="warning" type="button" className="float-end"
							  style={{ marginRight: '20px' }} onClick={(event) => this.deleteDewar(event)}>Delete</Button>} 
						  </Form.Group>
						</ChemEdit>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const dewarColumns = [
	{ Header: 'Request Placed On', accessor: 'DATE_REQUESTED', type: 'date', row: 0, col: 0, editable: false },
	{ Header: 'Pickup Date Requested', accessor: 'REQUESTED_DELIVERY_DATE', type: 'date', row: 0, col: 1 },
	{ Header: "Requestor's Name", accessor: 'CUSTOMER', row: 1, col: 0, editable: false },
	{ Header: "Requestor's PI", accessor: 'PI_NAME', row: 1, col: 1, editable: false },
	{ Header: 'Speedtype', accessor: 'COST_CENTER', type: 'select', row: 2, col: 0,
		options: { value: 'COST_CENTER', label: 'COST_CENTER', entity: 'RECHARGES.HELAD_SPEEDTYPE_SELECT_VW',
			search: { Attribute: 'PIID', Operator: '=', LongValue: -1 }, order: 'COST_CENTER' }},
	{ Header: 'Department', accessor: 'DEPARTMENT', row: 2, col: 1, editable: false },
	{ Header: 'Email', accessor: 'EMAIL', row: 3, col: 0, editable: false,
		Cell: props => <a href={props.value}>{props.value}</a> },
	{ Header: 'Phone', accessor: 'REQ_PHONE', row: 3, col: 1 },
	{ Header: 'Remark', accessor: 'REMARKS', type: 'textarea', row: 4, col: 0 },
	{ Header: 'Dewar Type', accessor: 'REQUESTED_CONT_TYPE_ID', type: 'select', row: 4, col: 1,
		options: { value: 'CONT_TYPE_ID', label: 'CONT_TYPE', entity: 'LIQUID_AIR.CONTAINER_TYPES', 
			search: { Attribute: 'CONT_DESC', Operator: 'like', StringValue: 'for Liq He' }}},
	{ Header: 'Volume To Bill', accessor: 'VOLUME', row: 5, col: 0 },
	{ Header: 'Date Filled', accessor: 'DATE_FILLED', type: 'date', row: 5, col: 1 },
	{ Header: 'Ready For Pickup', accessor: 'READY_FLAG', type: 'checkbox', format: '1/0', row: 6, col: 0 },
	{ Header: 'Type', accessor: 'COLD_FLAG', type: 'select', row: 6, col: 1,
		options: [{ value: 1, label: 'Cold' },{ value: 0, label: 'Warm' }]},
	{ Header: 'Dewar Number', accessor: 'CONTAINER_ID', row: 7, col: 0 },
	{ Header: 'Picked Up', accessor: 'PICKED_UP', type: 'checkbox', format: '1/0', row: 7, col: 1 },
	{ Header: 'Cancelled', accessor: 'CANCEL_FLAG', type: 'checkbox', format: '1/0', row: 8, col: 0 },
	{ Header: 'Billed', accessor: 'DATE_BILLED_DISPLAY', row: 8, col: 1, editable: false },
	{ Header: 'Modified', accessor: 'LAST_MODIF_DISPLAY', row: 9, col: 0, editable: false },
	{ Header: 'Modified By', accessor: 'REQ_MODIF_BY', row: 9, col: 1, editable: false }
];

export default withRouter(Dewar);
