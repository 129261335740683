import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemTable from '../ChemTable';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';


class ChangeSpeedtypes extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			OLD_SPEEDTYPE: '',
			NEW_SPEEDTYPE: '',
			USER_NAME: '',
			TRANS_ID: '',
			SERVICES: []
		};

		this.state = {
			search: search,
			result: [],
			renderKey: 1
		};
	}
	
	componentDidMount() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/Search',
			data: {
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'CURRENT_CYCLE_VW',
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				if (data.Data.length === 1) {
					self.mergeState({ 
						cycleBegDate: self.getDate(data.Data[0].BEG_DATE).toLocaleDateString('en-US', {year:'2-digit', month:'2-digit', day:'2-digit'}),
						cycleEndDate: self.getDate(data.Data[0].END_DATE).toLocaleDateString('en-US', {year:'2-digit', month:'2-digit', day:'2-digit'}),
						renderKey: self.state.renderKey + 1
					});
				} else {
					self.showAlert('Cycle Error', 'Expected 1 current billing cycle but found ' + data.Data.length);
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var self=this;
		
		// flatten service list
		var search = this.copyObject(this.state.search);
		if (this.state.search.SERVICES && this.state.search.SERVICES.length > 0) {
			search.SERVICES = this.state.search.SERVICES.join(',');
		} else {
			search.SERVICES = "";
		}
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/ChangeSpeedtypes',
			overlay: true,
			data: {
				data: search
			}
		}).done(function (data) {
			if (data.Success) {
				self.mergeState({ 
					result: data.Data,
					renderKey: self.state.renderKey + 1
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	

		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}

	render() {

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Change Speedtypes</span><br />
					For the Cycle: {this.state.cycleBegDate} - {this.state.cycleEndDate}<br />
					NOTE: This will only affect entries that are not marked as billed and are in the above time period.<br />
					<ChemEdit parent={this} columns={changeSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={12}>
						  <Button id="change" variant="warning" type="submit" className="float-end">Change</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
				  <Col>
				    <ChemTable renderKey={this.state.renderKey} parent={this} name='CRS_ChangeSpeedtypes' columns={changeColumns} data={this.state.result} />
				  </Col>
				</Row>
			</Container>
		</>);
	}
}

const servicesOptions = [
	{ value: 'C1', label: 'Industrial Gas/Demurrage' },
	{ value: 'C2', label: 'Dry Ice' },
	{ value: 'C3', label: 'Gas N2 Meters' },
	{ value: 'C4', label: 'Helium -Gas (Cylinder)' },
	{ value: 'C5', label: 'Helium Recovery Credit' },
	{ value: 'C6', label: 'Helium -Liquid (Dewars)' },
	{ value: 'C7', label: 'Liquid N2 (Dispenser)' },
	{ value: 'C8', label: 'Chem Storeroom' },
	{ value: 'C9', label: 'Glass Shop' },
	{ value: 'C10', label: 'Machine Shop' },
	{ value: 'C11', label: 'RSS/Carpentry/Electrical' },
	{ value: 'C12', label: 'Molecular Graphics' },
	{ value: 'C13', label: 'MicroAnalytical' },
	{ value: 'C14', label: 'NMR' },
	{ value: 'C15', label: 'Pitzer Research Computer Support' },
	{ value: 'C16', label: 'X-ray Crystallographic Facility' }
];

const changeSearch = [
	{ Header: 'From Speedtype', accessor: 'OLD_SPEEDTYPE', row: 0, col: 0, required: true },
	{ Header: 'To Speedtype', accessor: 'NEW_SPEEDTYPE', row: 0, col: 1, required: true },
	{ Header: 'User Name', accessor: 'USER_NAME', row: 0, col: 2, subscript: '(last, first) (use % for wild card)' },
	{ Header: 'Transaction ID<br /><i>Note: several services may share the same ID. Make sure to select the correct service.</i>',
		accessor: 'TRANS_ID', row: 1, col: 0, subscript: '(separate IDs with a comma)' },
	{ Header: 'Services', accessor: 'SERVICES', type: 'list', row: 1, col: 1, options: servicesOptions }
];

const changeColumns = [
	{ Header: 'Service Name', accessor: 'SERVICE_ID', width: 125 },
	{ Header: 'Former Speedtype', accessor: 'FORMER_S', width: 125 },
	{ Header: 'New Speedtype', accessor: 'NEW_S', width: 125 },
	{ Header: 'User Name', accessor: 'USER_NAME', width: 125 },
	{ Header: 'Line modified', accessor: 'LINES_TOTAL', width: 125 },
	{ Header: 'Trans ID', accessor: 'REC_ID', width: 125 },
	{ Header: '$ Amount', accessor: 'AMOUNT_TOTAL', width: 125 }
];

export default withRouter(ChangeSpeedtypes);