import { useEffect } from 'react';
import {
    useLocation,
    useNavigate,
    useParams
  } from "react-router-dom";

export const withGSIDefaultRoute = (Component) =>  {
    const WithGSIDefaultRouteWrapper = (props) => {
		const location = useLocation();
		const navigate = useNavigate();
		const params = useParams();
		const match = {
			path: location.pathname
		};
		
		useEffect(() => {
			// if the default page has been requested
			// /gradoffice/gsis/1234		specify assignment ID
			// /gradoffice/gsis/new			new assignment with outside GSI
			// /gradoffice/gsis/new/1234	new assignment with student ID specified
			var defaultRegex = /\/gradoffice\/gsis\/(([1-9][0-9]*)|(new(\/[1-9][0-9]*)?))$/i;
			if (defaultRegex.test(match.path)) {
				navigate(match.path + '/GSI', { replace: true });
			}
		});
		
		return (
			<Component
				location={location}
				navigate={navigate}
				params={params}
				match={match}
				{...props}
			/>
		);
    }
    return WithGSIDefaultRouteWrapper;
}
