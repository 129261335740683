import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';
import Tabs from '../Tabs';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { PencilSquare } from 'react-bootstrap-icons';

class QualExams extends ChemComponent {
	constructor(props) {
		super(props);

		var search = {
			ACAD_YEAR: [],
			REG_STATUS: null,
			DIVISION: null,
			EXAM_DATE_FROM: null,
			EXAM_DATE_TO: null,
			RES_DIRECTOR: null,
			SEMINAR_DATE_FROM: null,
			SEMINAR_DATE_TO: null,
			MEMBERS: [],
			QE_NUMBER: 'ALL',
			QE_TAKEN: 'ALL',
			RESULT: null,
			FLD_ACADEMIC_YEAR: false,
			FLD_ASSOCIATE_ADVISOR: true,
			FLD_LAB_ADDRESS: false,
			FLD_LAB_PHONE1: false,
			FLD_CANDIDACY: false,
			FLD_CITIZENSHIP: false,
			FLD_CHAIR: true,
			FLD_DEGREE: false,
			FLD_DIVISION: false,
			FLD_ETHNICITY: false,
			FLD_EXAM_DATE: true,
			FLD_EXAM_LOC: false,
			FLD_QE_NUMBER: false,
			FLD_RESULT: false,
			FLD_EXAM_TEMP_DATE: false,
			FLD_GENDER: false,
			FLD_OUTS_DEPT: false,
			FLD_OUTS_EMAIL: false,
			FLD_OUTSIDE_MEMBER: true,
			FLD_QE_COMMITTEE: true,
			FLD_RES_DIRECTOR: true,
			FLD_STUDY_AREAS: false,
			FLD_REG_STATUS: false,
			FLD_CA_RESIDENT: false,
			FLD_SEMINAR_DATE: true,
			FLD_STUDENT_CARD: false,
			FLD_THESIS_DATE: false,
			FLD_UNGRAD_GPA: false
		};
		
		// get list of FLD_ keys since they will disappear when object is copied
		var fldKeys = [];
		Object.keys(search).forEach(function(key) {
			if (key.length > 4 && key.substring(0, 4) === 'FLD_') {
				fldKeys.push(key);
			}
		});	
		
		var initQEList = this.copyTable(qeList);
		var searchCount = 0;
		var showSearch = true;

		if (typeof(Storage) !== 'undefined') {
			var storedSearch = localStorage.getItem('GradOffice_QualExams_search');
			if (storedSearch) {
				storedSearch = JSON.parse(storedSearch);
				this.mergeObject(search, storedSearch);
				search = storedSearch;
				initQEList.ajax.data = { search: this.copyObject(search) };
				this.quoteStringArrays(initQEList.ajax.data.search);
				initQEList.hold = false;
				this.removeUnusedColumns(initQEList.ajax.data.search, initQEList.columns);
				searchCount = null;
				showSearch = false;
			}
		}

		this.state = {
			qeList: initQEList,
			search: search,
			fldKeys: fldKeys,
			searchCount: searchCount,
			showSearch: showSearch,
			activeTab: 'Search',
			renderKey: 0
		};
	}
	
	onClickTabItem(tab) {
		this.mergeState({
			activeTab: tab
		});
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newQEList = this.copyTable(qeList);
		newQEList.ajax.data = {
			search: this.copyObject(this.state.search)
		};
		this.quoteStringArrays(newQEList.ajax.data.search);
		newQEList.hold = false;
		this.removeUnusedColumns(newQEList.ajax.data.search, newQEList.columns);
		this.mergeState({ 
			qeList: newQEList,
			searchCount: null,
			renderKey: this.state.renderKey + 1,
			activeTab: 'Search',
			showSearch: false
		});
		if (typeof(Storage) !== 'undefined') {
			localStorage.setItem('GradOffice_QualExams_search', JSON.stringify(this.state.search));
		}
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onExpandSearch(event) {
		this.mergeState({
			showSearch: true			
		});
	}
	
	getFieldList(search)
	{
		var fields = [];
		Object.keys(search).forEach(function(key) {
			if (key.length > 4 && key.substring(0, 4) === 'FLD_' && search[key]) {
				fields.push(key.substring(4));
			}
		});
		return fields;
	}
	
	removeUnusedColumns(search, columns) {
		var fields = this.getFieldList(search);
		
		for (var i = columns.length - 1; i >= 0; i--) {
			if (!['id', 'QE_ID', 'STUDENT_ID', 'STUDENT_NAME'].includes(columns[i].accessor)) {
				if (columns[i].includedby) {
					// Check both includedBy and accessor since some fields are included both ways
					if (!fields.includes(columns[i].includedby) && !fields.includes(columns[i].accessor)) {
						columns.splice(i, 1);
					}
				} else if (!fields.includes(columns[i].accessor)) {
					columns.splice(i, 1);
				}
			}
		}
	}
	
	quoteStringArrays(search) {
		this.quoteStringArray(search['ACAD_YEAR']);
		this.quoteStringArray(search['MEMBERS']);
	}

	selectAll(event) {
		var search = this.copyObject(this.state.search);
		for (var i = 0; i < this.state.fldKeys.length; i++) {
			this.setByAccessor(search, this.state.fldKeys[i], true);
		}
		this.mergeState({
			search: search,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}

	deselectAll(event) {
		var search = this.copyObject(this.state.search);
		for (var i = 0; i < this.state.fldKeys.length; i++) {
			this.setByAccessor(search, this.state.fldKeys[i], false);
		}
		this.mergeState({
			search: search,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	editQualExam(studentId) {
		this.props.setStudentEditable(true);
		this.props.navigate('/GradOffice/Students/' + studentId + '/Qual%20Exam');
	}
	
	viewQualExam(studentId) {
		this.props.setStudentEditable(false);
		this.props.navigate('/GradOffice/Students/' + studentId + '/Qual%20Exam');
	}		

	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		var exportColumnsInUse = this.copyColumns(exportColumns);
		this.removeUnusedColumns(this.state.search, exportColumnsInUse);
		var search = this.copyObject(this.state.search);
		this.quoteStringArrays(search);

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			Action: 'QualExams',
			columns: exportColumnsInUse,
			search: search
		});

		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Qual Exams</span>
						{this.state.showSearch && <Tabs onClickTabItem={(tab) => this.onClickTabItem(tab)}>
						  <div label='Search'>
							<ChemEdit parent={this} columns={qeSearch} data={this.state.search} renderKey={this.state.renderKey} 
							  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} {...this.props} />
						  </div>
						  <div label='Fields'>
							<ChemEdit parent={this} columns={qeFields} data={this.state.search} renderKey={this.state.renderKey} 
							  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} {...this.props} labelColWidth={10} valueColWidth={2} />
						  </div>
						</Tabs>}
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/GradOffice/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/GradOffice/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						  {this.state.showSearch ?
							<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button> :
							<Button id="expand" variant="warning" type="button" className="float-end" onClick={(event) => this.onExpandSearch(event)}>Expand</Button>
						  }
						  {this.state.showSearch && this.state.activeTab === 'Fields' && <>
							  <Button id="deselectAll" style={{ marginRight: '10px' }} variant="warning" type="button" className="float-end" onClick={(event) => this.deselectAll(event)}>Deselect All</Button>
							  <Button id="selectAll" style={{ marginRight: '10px' }} variant="warning" type="button" className="float-end" onClick={(event) => this.selectAll(event)}>Select All</Button>
						  </>}
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='GradOffice_QualExams' table={this.state.qeList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='140px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const qeSearch = [
	{ Header: 'Academic Year Started', accessor: 'ACAD_YEAR', type: 'list', row: 0, col: 0,
		options: { value: 'ACAD_YEAR', label: 'ACAD_YEAR', entity: 'ADMISSIONS.STUDENT_YEAR_SELECT_VW', order: ['YEAR_ORDER','ACAD_YEAR desc']}},
	{ Header: 'Registration Status', accessor: 'REG_STATUS', type: 'select', row: 0, col: 1,
		options: { value: 'REG_STATUS_NAME', label: 'REG_STATUS_NAME', entity: 'ADMISSIONS.REG_STATUS' }},
	{ Header: 'Division', accessor: 'DIVISION', type: 'select', row: 0, col: 2,
		options: { value: 'DIVISION', label: 'DIVISION', entity: 'ADMISSIONS.DIVISIONS' }},
	{ Header: 'QE Date From', accessor: 'EXAM_DATE_FROM', type: 'date', row: 1, col: 0 },
	{ Header: 'QE Date To', accessor: 'EXAM_DATE_TO', type: 'date', row: 1, col: 1 },
	{ Header: 'Research Director', accessor: 'RES_DIRECTOR', type: 'select', row: 1, col: 2,
		options: { value: 'RES_DIRECTOR', label: 'RES_DIRECTOR', entity: 'ADMISSIONS.STUDENTS', distinct: true, order: 'RES_DIRECTOR' }},
	{ Header: 'Seminar Date From', accessor: 'SEMINAR_DATE_FROM', type: 'date', row: 2, col: 0 },
	{ Header: 'Seminar Date To', accessor: 'SEMINAR_DATE_TO', type: 'date', row: 2, col: 1 },
	{ Header: 'Committee Members', accessor: 'MEMBERS', type: 'list', row: 2, col: 2,
		options: { value: 'PERSON_ID', label: 'NAME', entity: 'ADMISSIONS.QE_COMMITTEE_SELECT_VW', order: 'NAME' }},
	{ Header: 'Exam Number', accessor: 'QE_NUMBER', type: 'select', row: 3, col: 0,
		options: [{ value: '1', label: '1' },{ value: '2', label: '2' },{ value: 'ALL', label: 'All' }]},
	{ Header: 'Exam Taken', accessor: 'QE_TAKEN', type: 'select', row: 3, col: 1,
		options: [{ value: 'YES', label: 'Yes' },{ value: 'NO', label: 'No' },{ value: 'ALL', label: 'All' }]},
	{ Header: 'Result', accessor: 'RESULT', type: 'select', row: 3, col: 2,
		options: { value: 'RESULT', label: 'RESULT', entity: 'ADMISSIONS.QUAL_EXAM', distinct: true }}
];

const qeFields = [
	{ Header: 'Academic Senate Rep', accessor: 'FLD_OUTSIDE_MEMBER', type: 'checkbox', row: 0, col: 0 },
	{ Header: 'Academic Year', accessor: 'FLD_ACADEMIC_YEAR', type: 'checkbox', row: 1, col: 0 },
	{ Header: 'Associate Advisor', accessor: 'FLD_ASSOCIATE_ADVISOR', type: 'checkbox', row: 2, col: 0 },
	{ Header: 'Campus Address', accessor: 'FLD_LAB_ADDRESS', type: 'checkbox', row: 3, col: 0 },
	{ Header: 'Campus Phone', accessor: 'FLD_LAB_PHONE1', type: 'checkbox', row: 4, col: 0 },
	{ Header: 'Candidacy Date', accessor: 'FLD_CANDIDACY', type: 'checkbox', row: 5, col: 0 },
	{ Header: 'Citizenship', accessor: 'FLD_CITIZENSHIP', type: 'checkbox', row: 0, col: 1 },
	{ Header: 'Degree', accessor: 'FLD_DEGREE', type: 'checkbox', row: 1, col: 1 },
	{ Header: 'Division', accessor: 'FLD_DIVISION', type: 'checkbox', row: 2, col: 1 },
	{ Header: 'Ethnicity', accessor: 'FLD_ETHNICITY', type: 'checkbox', row: 3, col: 1 },
	{ Header: 'Exam Date/Time', accessor: 'FLD_EXAM_DATE', type: 'checkbox', row: 4, col: 1 },
	{ Header: 'Exam Location', accessor: 'FLD_EXAM_LOC', type: 'checkbox', row: 0, col: 2 },
	{ Header: 'Exam Number', accessor: 'FLD_QE_NUMBER', type: 'checkbox', row: 1, col: 2 },
	{ Header: 'Exam Result', accessor: 'FLD_RESULT', type: 'checkbox', row: 2, col: 2 },
	{ Header: 'Gender', accessor: 'FLD_GENDER', type: 'checkbox', row: 3, col: 2 },
	{ Header: 'QE ASR Dept', accessor: 'FLD_OUTS_DEPT', type: 'checkbox', row: 4, col: 2 },
	{ Header: 'QE ASR Email', accessor: 'FLD_OUTS_EMAIL', type: 'checkbox', row: 0, col: 3 },
	{ Header: 'QE Chair', accessor: 'FLD_CHAIR', type: 'checkbox', row: 1, col: 3 },
	{ Header: 'QE Committee', accessor: 'FLD_QE_COMMITTEE', type: 'checkbox', row: 2, col: 3 },
	{ Header: 'Research Director', accessor: 'FLD_RES_DIRECTOR', type: 'checkbox', row: 3, col: 3 },
	{ Header: 'Research Interests', accessor: 'FLD_STUDY_AREAS', type: 'checkbox', row: 4, col: 3 },
	{ Header: 'Registration Status', accessor: 'FLD_REG_STATUS', type: 'checkbox', row: 0, col: 4 },
	{ Header: 'Residency', accessor: 'FLD_CA_RESIDENT', type: 'checkbox', row: 1, col: 4 },
	{ Header: 'Seminar Date', accessor: 'FLD_SEMINAR_DATE', type: 'checkbox', row: 2, col: 4 },
	{ Header: 'Student ID', accessor: 'FLD_STUDENT_CARD', type: 'checkbox', row: 3, col: 4 },
	{ Header: 'Thesis Date', accessor: 'FLD_THESIS_DATE', type: 'checkbox', row: 4, col: 4 }
];

const qeList = {
	ajax: { 
		url: '/GradOffice/GetQualExams',
		data: null,
		accessor: 'Data'
	},
	pageSize: 20,
	hold: true,
	columns: [
		{ Header: '', accessor: 'id', Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editQualExam(props.row.values.STUDENT_ID)} />, width: 25 },
		{ Header: 'Student ID', accessor: 'STUDENT_ID', show: false },
		{ Header: 'Student ID', accessor: 'STUDENT_CARD', width: 100 },
		{ Header: 'Year', accessor: 'ACADEMIC_YEAR', width: 100 },
		{ Header: 'Name', accessor: 'STUDENT_NAME', width: 200, Cell: props => <HashLink onClick={() => props.parent.viewQualExam(props.row.values.STUDENT_ID)}>{props.value}</HashLink> },
		{ Header: 'Thesis Date', accessor: 'THESIS_DATE', width: 75 },
		{ Header: 'Candidacy Date', accessor: 'CANDIDACY', width: 100 },
		{ Header: 'Ethnicity', accessor: 'ETHNICITY', width: 225 },
		{ Header: 'Gender', accessor: 'GENDER', width: 70 },
		{ Header: 'Division', accessor: 'DIVISION', width: 75 },
		{ Header: 'Exam Result', accessor: 'RESULT', width: 60 },
		{ Header: 'Research Director', accessor: 'RES_DIRECTOR', width: 200 },
		{ Header: 'Associate Advisor', accessor: 'ASSOCIATE_ADVISOR', width: 200 },
		{ Header: 'Citizenship', accessor: 'CITIZENSHIP', width: 200 },
		{ Header: 'Seminar Date', accessor: 'SEMINAR_DATE', width: 90 },
		{ Header: 'Status', accessor: 'REG_STATUS', width: 125 },
		{ Header: 'Resident', accessor: 'CA_RESIDENT', width: 70 },
		{ Header: 'Degree', accessor: 'DEGREE', width: 60 },
		{ Header: 'Campus Address', accessor: 'LAB_ADDRESS', width: 150 },
		{ Header: 'Campus Phone', accessor: 'LAB_PHONE1', width: 100 },
		{ Header: 'Exam Location', accessor: 'EXAM_LOC', width: 125 },
		{ Header: 'Exam Date', accessor: 'EXAM_DATE', width: 80 },
		{ Header: 'Exam Time', accessor: 'EXAM_TIME', includedby: 'EXAM_DATE', width: 80 },
		{ Header: 'QE #', accessor: 'QE_NUMBER', width: 45 },
		{ Header: 'QE Chair', accessor: 'CHAIR', width: 200 },
		{ Header: 'Member 1', accessor: 'MEMBER1', includedby: 'QE_COMMITTEE', width: 200 },
		{ Header: 'Member 2', accessor: 'MEMBER2', includedby: 'QE_COMMITTEE', width: 200 },
		{ Header: 'Academic Senate Rep', accessor: 'OUTSIDE_MEMBER', width: 200 },
		{ Header: 'QE ASR Dept', accessor: 'OUTS_DEPT', width: 175 },
		{ Header: 'QE ASR Email', accessor: 'OUTS_EMAIL', width: 200 },
		{ Header: 'Research Interest 1', accessor: 'STUDY_AREA1', includedby: 'STUDY_AREAS', width: 250 },
		{ Header: 'Research Interest 2', accessor: 'STUDY_AREA2', includedby: 'STUDY_AREAS', width: 250 },
		{ Header: 'Research Interest 3', accessor: 'STUDY_AREA3', includedby: 'STUDY_AREAS', width: 250 }
	]
};

const exportColumns = [
	{ Header: 'Student ID', accessor: 'STUDENT_CARD', width: 20 },
	{ Header: 'Year', accessor: 'ACADEMIC_YEAR', width: 20 },
	{ Header: 'Name', accessor: 'STUDENT_NAME', width: 40 },
	{ Header: 'Thesis Date', accessor: 'THESIS_DATE', width: 15 },
	{ Header: 'Candidacy Date', accessor: 'CANDIDACY', width: 20 },
	{ Header: 'Ethnicity', accessor: 'ETHNICITY', width: 45 },
	{ Header: 'Gender', accessor: 'GENDER', width: 14 },
	{ Header: 'Division', accessor: 'DIVISION', width: 15 },
	{ Header: 'Exam Result', accessor: 'RESULT', width: 12 },
	{ Header: 'Research Director', accessor: 'RES_DIRECTOR', width: 40 },
	{ Header: 'Citizenship', accessor: 'CITIZENSHIP', width: 40 },
	{ Header: 'Seminar Date', accessor: 'SEMINAR_DATE', width: 18 },
	{ Header: 'Status', accessor: 'REG_STATUS', width: 25 },
	{ Header: 'Resident', accessor: 'CA_RESIDENT', width: 14 },
	{ Header: 'Degree', accessor: 'DEGREE', width: 12 },
	{ Header: 'Campus Address', accessor: 'LAB_ADDRESS', width: 30 },
	{ Header: 'Campus Phone', accessor: 'LAB_PHONE1', width: 20 },
	{ Header: 'Exam Location', accessor: 'EXAM_LOC', width: 25 },
	{ Header: 'Exam Date', accessor: 'EXAM_DATE', width: 16 },
	{ Header: 'Exam Time', accessor: 'EXAM_TIME', includedby: 'EXAM_DATE', width: 16 },
	{ Header: 'QE #', accessor: 'QE_NUMBER', width: 9 },
	{ Header: 'QE Chair', accessor: 'CHAIR', width: 40 },
	{ Header: 'Member 1', accessor: 'MEMBER1', includedby: 'QE_COMMITTEE', width: 40 },
	{ Header: 'Member 2', accessor: 'MEMBER2', includedby: 'QE_COMMITTEE', width: 40 },
	{ Header: 'Academic Senate Rep', accessor: 'OUTSIDE_MEMBER', width: 40 },
	{ Header: 'QE ASR Dept', accessor: 'OUTS_DEPT', width: 35 },
	{ Header: 'QE ASR Email', accessor: 'OUTS_EMAIL', width: 40 },
	{ Header: 'Research Interest 1', accessor: 'STUDY_AREA1', includedby: 'STUDY_AREAS', width: 50 },
	{ Header: 'Research Interest 2', accessor: 'STUDY_AREA2', includedby: 'STUDY_AREAS', width: 50 },
	{ Header: 'Research Interest 3', accessor: 'STUDY_AREA3', includedby: 'STUDY_AREAS', width: 50 }
];

export default withRouter(QualExams)
