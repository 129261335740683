import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class Schedule extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			editable: props.editable,
			schedule: {
				SCHEDULE_ID: '' 
			},
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadSchedule();
	}

	loadSchedule(event) {
		var self = this;
		
		if (this.props.params.scheduleId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/GradOffice/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'COURSE_SCHEDULE',
					search: { Attribute: 'SCHEDULE_ID', Operator: '=', LongValue: this.props.params.scheduleId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var schedule = data.Data[0];
						
						schedule.MODIFIED = self.composeChangeInfo(schedule.MODIF_BY, schedule.MODIF_DATE);
						var semester = [];
						var semUpperCase = schedule.SEMESTER ? schedule.SEMESTER.toUpperCase() : '';
						if (semUpperCase.indexOf('FA') >= 0) semester.push('Fa');
						if (semUpperCase.indexOf('SP') >= 0) semester.push('Sp');
						if (semUpperCase.indexOf('SU') >= 0) semester.push('Su');
						schedule.SEMESTER = semester;
							
						self.mergeState({
							schedule: schedule,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Schedule Error', data.Data.length + ' Schedules found for ID ' + self.props.params.scheduleId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				schedule: {
					SCHEDULE_ID: '(new)',
					DEPT_CODE: 1,
					SCHEDULE_NAME: '',
					SCHEDULE_DESC: '',
					SEMESTER: '',
					TA_FLAG: false,
					SCHEDULE_TYPE: false,
					MODULE: false
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var schedule = this.copyObject(this.state.schedule);
		this.setByAccessor(schedule, accessor, value);
		this.mergeState({ schedule: schedule, renderKey: this.state.renderKey + 1 });
	}
		
	deleteSchedule(event) {
		var self = this;
		
		this.showOKCancel('Confirm Delete Schedule', 'Delete Schedule ' + this.props.params.scheduleId + '?', (okClicked) => {
			if (okClicked) {
				// prepare schedule for deletion		
				var schedule = {
					SCHEDULE_ID: self.props.params.scheduleId,
					DELETE_FLAG: 'Y',
					MODIF_BY: self.props.user.name,
					MODIF_DATE: self.dateTimeToMVC(new Date())
				};
				
				this.ajax({
					type: 'POST',
					url: self.getConfig().host + '/Home/Update',
					data: { __RequestVerificationToken: self.props.user.antiForgeryToken, entity: 'ADMISSIONS.COURSE_SCHEDULE', data: schedule }
				}).done(function (data) {
					if (data.Success) {
						self.props.parent.showConfirmation(() => {
							return (<>
								Schedule {self.props.params.scheduleId} deleted.
							</>); 
						});
					} else {
						self.showAlert('Server Error', data.Message);
					}			
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
		
	onSubmit(event) {
		var self = this;		
		var url = null;
		
		// make copy of data
		var schedule = this.copyObject(this.state.schedule);

		// combine semesters into one mashup
		schedule.SEMESTER = Array.isArray(schedule.SEMESTER) ? schedule.SEMESTER.join('') : '';
		schedule.MODIF_BY = this.props.user.name;
		schedule.MODIF_DATE = this.dateTimeToMVC(new Date());
		
		// if this is a new schedule
		if (!this.props.params.scheduleId) {
			// set ID to sequence name
			schedule.SCHEDULE_ID = 'course_schedule_id_seq.nextval';
			url = '/Home/Insert';
		} else {
			url = '/Home/Update';
		}
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + url,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'ADMISSIONS.COURSE_SCHEDULE', data: schedule }
		}).done(function (data) {
			if (data.Success) {
				var scheduleId = self.props.params.scheduleId || data.ID;
				var verb = self.props.params.scheduleId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Schedule <Link to={'/GradOffice/Schedules/' + scheduleId}>{scheduleId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Schedule {this.state.schedule.SCHEDULE_ID}&nbsp;&nbsp;
					{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
					  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
				  </div>
				  <Container fluid>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={scheduleColumns} data={this.state.schedule} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)} editable={this.state.editable}>
						  <Form.Group>
						    {this.state.editable && <Button id="save" variant="warning" type="submit" className="float-end">Save</Button>}
							{this.state.editable && this.props.params.scheduleId && <Button id="delete" variant="warning" type="button" className="float-end"
							  style={{ marginRight: '20px' }} onClick={(event) => this.deleteSchedule(event)}>Delete</Button>} 
						  </Form.Group>
						</ChemEdit>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const scheduleColumns = [
	{ Header: 'Course', accessor: 'COURSE_ID', type: 'select', row: 0, col: 0,
		options: { value: 'COURSE_ID', label: 'COURSE_NAME', entity: 'ADMISSIONS.COURSES', order: 'COURSE_NAME',
			search: { Operator: 'and', Children: [
				{ Attribute: 'DELETE_FLAG', Operator: 'is null' },
				{ Attribute: 'DEPT_CODE', Operator: '=', StringValue: '1' }]}}},
	{ Header: 'Semester', accessor: 'SEMESTER', type: 'list', row: 0, col: 1,
		options: [{ value: 'Fa', label: 'Fall' },{ value: 'Sp', label: 'Spring' },{ value: 'Su', label: 'Summer' }]},
	{ Header: 'Year', accessor: 'CAL_YEAR', type: 'select', row: 0, col: 2,
		options: { value: 'CAL_YEAR', label: 'CAL_YEAR', entity: 'ADMISSIONS.COURSE_SCHEDULE', distinct: true, order: 'CAL_YEAR desc',
			search: { Attribute: 'CAL_YEAR', Operator: 'is not null' }}},
	{ Header: 'Instructor 1', accessor: 'FACULTY1', type: 'select', row: 1, col: 0,
		options: { value: 'PERSON_ID', label: 'FACULTY', entity: 'ADMISSIONS.SCHEDULE_FACULTY_SELECT_VW' }},
	{ Header: 'Instructor 2', accessor: 'FACULTY2', type: 'select', row: 1, col: 1,
		options: { value: 'PERSON_ID', label: 'FACULTY', entity: 'ADMISSIONS.SCHEDULE_FACULTY_SELECT_VW' }},
	{ Header: 'Other Instructor', accessor: 'OTHER_FACULTY', row: 1, col: 2 },
	{ Header: 'Comments', accessor: 'COMMENTS', row: 2, col: 0 },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 2, col: 1, editable: false }
];

export default withRouter(Schedule);
