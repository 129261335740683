import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { PencilSquare } from 'react-bootstrap-icons';

class Dewars extends ChemComponent {
	constructor(props) {
		super(props);

		var search = {
			REQUEST_ID: null,
			PIID: null,
			DATE_REQUESTED_FROM: null,
			DATE_REQUESTED_TO: null,
			CUSTOMER: null,
			COST_CENTER: null,
			DEPARTMENT: null,
			CANCEL_FLAG: null,
			READY_FLAG: null,
			PICKED_UP: null,
			DATE_FILLED_FROM: null,
			DATE_FILLED_TO: null,
			DATE_BILLED: [],
			FLD_CANCEL_FLAG_DISPLAY: true,
			FLD_DATE_BILLED_DISPLAY: true,
			FLD_DATE_FILLED_DISPLAY: false,
			FLD_DATE_REQUESTED_DISPLAY: true,
			FLD_REQUESTED_DELIVERY_DATE_DISPLAY: true,
			FLD_DEPARTMENT: true,
			FLD_CONTAINER_ID: false,
			FLD_CONT_TYPE: true,
			FLD_EMAIL: false,
			FLD_FILLED: true,
			FLD_PICKED_UP_DISPLAY: false,
			FLD_PI_NAME: true,
			FLD_REMARKS: false,
			FLD_REQUEST_ID: true,
			FLD_CUSTOMER: false,
			FLD_COST_CENTER: true,
			FLD_COLD_FLAG_DISPLAY: false,
			FLD_VOLUME: false,
			treeMenuClickCount: 0 // don't modify in this class
		};
		
		var dewarListInit = this.copyTable(dewarList);
		
		if (props.search) {
			var propsSearch = this.copyObject(props.search);
			this.mergeObject(search, propsSearch);
			search = propsSearch;
			dewarListInit.query.search = this.constructSearch(search);
		}
		
		this.removeUnusedColumns(search, dewarListInit.columns);

		this.state = {
			dewarList: dewarListInit,
			search: search,
			searchCount: null,
			renderKey: 0
		};
	}
	
	componentDidUpdate(prevProps) {
		if (this.state.search.treeMenuClickCount !== this.props.search.treeMenuClickCount) {
			var newDewarList = this.copyTable(dewarList);
			newDewarList.query.search = this.constructSearch(this.props.search);
			this.removeUnusedColumns(this.props.search, newDewarList.columns);
			this.mergeState({
				dewarList: newDewarList,
				search: this.copyObject(this.props.search),
				searchCount: null,
				renderKey: this.state.renderKey + 1
			});
		}
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newDewarList = this.copyTable(dewarList);
		newDewarList.query.search = this.constructSearch(this.state.search);
		this.removeUnusedColumns(this.state.search, newDewarList.columns);
		this.mergeState({ 
			dewarList: newDewarList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	getFieldList(search)
	{
		var fields = [];
		Object.keys(search).forEach(function(key) {
			if (key.length > 4 && key.substring(0, 4) === 'FLD_' && search[key]) {
				fields.push(key.substring(4));
			}
		});
		return fields;
	}
	
	removeUnusedColumns(search, columns) {
		var fields = this.getFieldList(search);
		
		for (var i = columns.length - 1; i >= 0; i--) {
			if (columns[i].accessor !== 'id') {
				if (columns[i].includedby) {
					if (!fields.includes(columns[i].includedby)) {
						columns.splice(i, 1);
					}
				} else if (!fields.includes(columns[i].accessor)) {
					columns.splice(i, 1);
				}
			}
		}
	}

	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.REQUEST_ID)) search.Children.push({ Attribute: 'REQUEST_ID', Operator: '=', LongValue: data.REQUEST_ID });
		if (!this.isEmpty(data.PIID)) search.Children.push({ Attribute: 'PIID', Operator: '=', LongValue: data.PIID });
		if (!this.isEmpty(data.DATE_REQUESTED_FROM)) search.Children.push({ Attribute: 'DATE_REQUESTED', Operator: '>=', DateValue: this.getDate(data.DATE_REQUESTED_FROM) });
		if (!this.isEmpty(data.DATE_REQUESTED_TO)) search.Children.push({ Attribute: 'DATE_REQUESTED', Operator: '<=', DateValue: this.getDate(data.DATE_REQUESTED_TO) });
		if (!this.isEmpty(data.CUSTOMER)) search.Children.push({ Attribute: 'CUSTOMER', Operator: '=', StringValue: data.CUSTOMER });
		if (!this.isEmpty(data.COST_CENTER)) search.Children.push({ Attribute: 'COST_CENTER', Operator: '=', StringValue: data.COST_CENTER });
		if (!this.isEmpty(data.DEPARTMENT)) search.Children.push({ Attribute: 'DEPARTMENT', Operator: '=', StringValue: data.DEPARTMENT });
		if (!this.isEmpty(data.CANCEL_FLAG)) search.Children.push({ Attribute: 'CANCEL_FLAG', Operator: '=', IntValue: data.CANCEL_FLAG });
		if (!this.isEmpty(data.READY_FLAG)) search.Children.push({ Attribute: 'READY_FLAG', Operator: '=', IntValue: data.READY_FLAG });
		if (data.PICKED_UP === '2') {
			// combine picked_up and date_filled for filled order search
			var filledOrderSearch = {
				Operator: 'or',
				Children: [{ Attribute: 'PICKED_UP', Operator: '=', IntValue: 0 }]
			};
			if (this.isEmpty(data.DATE_FILLED_FROM) && this.isEmpty(data.DATE_FILLED_TO)) {
				filledOrderSearch.Children.push({ Attribute: 'DATE_FILLED', Operator: 'is not null' });
			} else if (!this.isEmpty(data.DATE_FILLED_FROM) && !this.isEmpty(data.DATE_FILLED_TO)) {
				filledOrderSearch.Children.push({
					Operator: 'and',
					Children: [
						{ Attribute: 'DATE_FILLED', Operator: '>=', DateValue: data.DATE_FILLED_FROM },
						{ Attribute: 'DATE_FILLED', Operator: '<=', DateValue: data.DATE_FILLED_TO }
				]});
			} else if (!this.isEmpty(data.DATE_FILLED_FROM)) {
				filledOrderSearch.Children.push({ Attribute: 'DATE_FILLED', Operator: '>=', DateValue: data.DATE_FILLED_FROM });
			} else {
				filledOrderSearch.Children.push({ Attribute: 'DATE_FILLED', Operator: '<=', DateValue: data.DATE_FILLED_TO });
			}
			search.Children.push(filledOrderSearch);
		} else {
			// use picked_up and date_filled individually
			if (!this.isEmpty(data.PICKED_UP)) search.Children.push({ Attribute: 'PICKED_UP', Operator: '=', IntValue: data.PICKED_UP });
			if (!this.isEmpty(data.DATE_FILLED_FROM)) search.Children.push({ Attribute: 'DATE_FILLED', Operator: '>=', DateValue: this.getDate(data.DATE_FILLED_FROM) });
			if (!this.isEmpty(data.DATE_FILLED_TO)) search.Children.push({ Attribute: 'DATE_FILLED', Operator: '<=', DateValue: this.getDate(data.DATE_FILLED_TO) });
		}
		if (data.DATE_BILLED.length > 0) {
			var dbsearch = { Operator: 'or', Children: [] };
			for (let i = 0; i < data.DATE_BILLED.length; i++) {
				if (this.isEmpty(data.DATE_BILLED[i])) {
					dbsearch.Children.push({ Attribute: 'DATE_BILLED', Operator: 'is null' });
				} else {
					dbsearch.Children.push({ Attribute: 'DATE_BILLED', Operator: '=', DateValue: this.dateTimeToMVC(this.getDate(data.DATE_BILLED[i])) });
				}
			}
			search.Children.push(dbsearch);
		}

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	editDewar(requestId) {
		this.props.setDewarEditable(true);
		this.props.navigate('/HeLAD/Dewars/' + requestId);		
	}

	viewDewar(requestId) {
		this.props.setDewarEditable(false);
		this.props.navigate('/HeLAD/Dewars/' + requestId);		
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	renderCheckboxes(editor, checkboxes) {
	  return (
		<Container fluid style={{ paddingBottom: '8px' }}>
		  <Row>
			<Col>{this.renderCheckbox(editor, checkboxes[0])}</Col>
			<Col>{this.renderCheckbox(editor, checkboxes[3])}</Col>
		  </Row>
		  <Row>
			<Col>{this.renderCheckbox(editor, checkboxes[1])}</Col>
			<Col>{this.renderCheckbox(editor, checkboxes[4])}</Col>
		  </Row>
		  <Row>
			<Col>{this.renderCheckbox(editor, checkboxes[2])}</Col>
			<Col>{this.renderCheckbox(editor, checkboxes[5])}</Col>
		  </Row>
		</Container>
	  );
	}
	
	renderCheckbox(editor, column) {
		return (<Form.Check type="checkbox"
			checked={editor.getByAccessor(editor.props.data, column.accessor)} label={column.Header}
			onClick={(event) => editor.props.onChange(column.accessor, event.target.checked)} />);
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Dewars',
			columns: exportColumns,
			entity: 'LIQUID_AIR.DEWARS_VW',
			search: this.constructSearch(this.state.search),
			order: 'DATE_REQUESTED desc'
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Dewars</span>
						<ChemEdit parent={this} user={this.props.user} columns={dewarSearch} data={this.state.search}
						  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='HeLAD_Dewars' table={this.state.dewarList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='425px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const dewarSearch = [
	{ Header: 'Request ID', accessor: 'REQUEST_ID', type: 'select', row: 0, col: 0,
		options: { value: 'REQUEST_ID', label: 'REQUEST_ID', entity: 'LIQUID_AIR.REQUESTS', order: 'REQUEST_ID desc' }},
	{ Header: 'PI Name', accessor: 'PIID', type: 'select', row: 0, col: 1,
		options: { value: 'PIID', label: 'PI_NAME', entity: 'LIQUID_AIR.DEWARS_VW', order: ['LNAME','FNAME'], distinct: true }},
	{ Header: 'Request Date From', accessor: 'DATE_REQUESTED_FROM', type: 'date', row: 0, col: 2 },
	{ Header: 'Request Date To', accessor: 'DATE_REQUESTED_TO', type: 'date', row: 0, col: 3 },
	{ Header: "Requestor's Name", accessor: 'CUSTOMER', type: 'select', row: 1, col: 0,
		options: { value: 'CUSTOMER', label: 'CUSTOMER', entity: 'LIQUID_AIR.DEWARS_VW', order: ['LASTNAME','FIRSTNAME'], distinct: true }},
	{ Header: 'Speedtype', accessor: 'COST_CENTER', type: 'select', row: 1, col: 1,
		options: { value: 'COST_CENTER', label: 'COST_CENTER', entity: 'LIQUID_AIR.DEWARS_VW', order: 'COST_CENTER', distinct: true }},
	{ Header: 'Department', accessor: 'DEPARTMENT', type: 'select', row: 1, col: 2,
		options: { value: 'DEPARTMENT_NAME', label: 'DEPARTMENT_NAME', entity: 'LIQUID_AIR.DEPARTMENTS', order: 'DEPARTMENT_NAME', distinct: true }},
	{ Header: 'Canceled', accessor: 'CANCEL_FLAG', type: 'select', row: 1, col: 3,
		options: [{ value: '', label: 'Select...' },{ value: '0', label: 'No' },{ value: '1', label: 'Yes' }]},
	{ Header: 'Ready For Pickup', accessor: 'READY_FLAG', type: 'select', row: 2, col: 0,
		options: [{ value: '', label: 'Select...' },{ value: '0', label: 'No' },{ value: '1', label: 'Yes' }]},
	{ Header: 'Picked Up', accessor: 'PICKED_UP', type: 'select', row: 2, col: 1,
		options: [{ value: '', label: 'Select...' },{ value: '0', label: 'No' },{ value: '2', label: 'No or Filled Order' },{ value: '1', label: 'Yes' }]},
	{ Header: 'Date Filled From', accessor: 'DATE_FILLED_FROM', type: 'date', row: 2, col: 2 },
	{ Header: 'Date Filled To', accessor: 'DATE_FILLED_TO', type: 'date', row: 2, col: 3 },
	{ Header: 'Date Billed', accessor: 'DATE_BILLED', type: 'list', row: 3, col: 0,
		options: { value: 'DATE_BILLED', label: 'DATE_BILLED_DISPLAY', entity: 'LIQUID_AIR.DATE_BILLED_SELECT_VW', order: 'DATE_BILLED desc' }},
	{ Editor: props => props.parent.renderCheckboxes(props.editor, [
		{ Header: 'Cancelled', accessor: 'FLD_CANCEL_FLAG_DISPLAY' },
		{ Header: 'Date Billed', accessor: 'FLD_DATE_BILLED_DISPLAY' },
		{ Header: 'Date Filled', accessor: 'FLD_DATE_FILLED_DISPLAY' },
		{ Header: 'Date Placed', accessor: 'FLD_DATE_REQUESTED_DISPLAY' },
		{ Header: 'Delivery Date Requested', accessor: 'FLD_REQUESTED_DELIVERY_DATE_DISPLAY' },
		{ Header: 'Department', accessor: 'FLD_DEPARTMENT' }
	]), row: 3, col: 1 },
	{ Editor: props => props.parent.renderCheckboxes(props.editor, [
		{ Header: 'Dewar #', accessor: 'FLD_CONTAINER_ID' },
		{ Header: 'Dewar Size', accessor: 'FLD_CONT_TYPE' },
		{ Header: 'Email', accessor: 'FLD_EMAIL' },
		{ Header: 'Filled', accessor: 'FLD_FILLED' },
		{ Header: 'Picked Up', accessor: 'FLD_PICKED_UP_DISPLAY' },
		{ Header: 'PI Name', accessor: 'FLD_PI_NAME' }
	]), row: 3, col: 2 },
	{ Editor: props => props.parent.renderCheckboxes(props.editor, [
		{ Header: 'Remarks', accessor: 'FLD_REMARKS' },
		{ Header: 'Request ID', accessor: 'FLD_REQUEST_ID' },
		{ Header: 'Requestor Name', accessor: 'FLD_CUSTOMER' },
		{ Header: 'Speedtype', accessor: 'FLD_COST_CENTER' },
		{ Header: 'Temperature', accessor: 'FLD_COLD_FLAG_DISPLAY' },
		{ Header: 'Volume', accessor: 'FLD_VOLUME' }
	]), row: 3, col: 3 }
];

const dewarList = {
	query: { entity: 'LIQUID_AIR.DEWARS_VW', search: null, order: 'DATE_REQUESTED desc' },
	pageSize: 20,
	columns: [
		{ Header: '', accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editDewar(props.row.values.REQUEST_ID)} />, width: 25 },
		{ Header: 'Picked Up', accessor: 'PICKED_UP_DISPLAY', width: 80 },
		{ Header: 'Picked', accessor: 'REQUESTED_DELIVERY_DATE_DISPLAY', width: 80 },
		{ Header: 'Requested', accessor: 'DATE_REQUESTED_DISPLAY', width: 90 },
		{ Header: 'Requestor Name', accessor: 'CUSTOMER', width: 200 },
		{ Header: 'Email', accessor: 'EMAIL', width: 250 },
		{ Header: 'Dewar #', accessor: 'CONTAINER_ID', width: 70 },
		{ Header: 'Size', accessor: 'CONT_TYPE', width: 90 },
		{ Header: 'Volume', accessor: 'VOLUME', width: 60 },
		{ Header: 'Temp', accessor: 'COLD_FLAG_DISPLAY', width: 60 },
		{ Header: 'Filled', accessor: 'FILLED', width: 70 },
		{ Header: 'Date Filled', accessor: 'DATE_FILLED_DISPLAY', width: 85 },
		{ Header: 'Speedtype', accessor: 'COST_CENTER', width: 125 },
		{ Header: 'PI', accessor: 'PI_NAME', width: 200 },
		{ Header: 'Department', accessor: 'DEPARTMENT', width: 250 },
		{ Header: 'Canceled', accessor: 'CANCEL_FLAG_DISPLAY', width: 75 },
		{ Header: 'Req. ID', accessor: 'REQUEST_ID', width: 70,
			Cell: props => <HashLink onClick={() => props.parent.viewDewar(props.row.values.REQUEST_ID)}>{props.value}</HashLink> },
		{ Header: 'Date Billed', accessor: 'DATE_BILLED_DISPLAY', width: 90 },
		{ Header: 'Remarks', accessor: 'REMARKS', width: 400 }
	]
};

const exportColumns = [
	{ Header: 'Picked Up', accessor: 'PICKED_UP_DISPLAY', width: 16 },
	{ Header: 'Picked', accessor: 'REQUESTED_DELIVERY_DATE_DISPLAY', width: 16 },
	{ Header: 'Requested', accessor: 'DATE_REQUESTED_DISPLAY', width: 18 },
	{ Header: 'Requestor Name', accessor: 'CUSTOMER', width: 40 },
	{ Header: 'Email', accessor: 'EMAIL', width: 50 },
	{ Header: 'Dewar #', accessor: 'CONTAINER_ID', width: 14 },
	{ Header: 'Size', accessor: 'CONT_TYPE', width: 18 },
	{ Header: 'Volume', accessor: 'VOLUME', width: 12 },
	{ Header: 'Temp', accessor: 'COLD_FLAG_DISPLAY', width: 12 },
	{ Header: 'Filled', accessor: 'FILLED', width: 14 },
	{ Header: 'Date Filled', accessor: 'DATE_FILLED_DISPLAY', width: 17 },
	{ Header: 'Speedtype', accessor: 'COST_CENTER', width: 25 },
	{ Header: 'PI', accessor: 'PI_NAME', width: 40 },
	{ Header: 'Department', accessor: 'DEPARTMENT', width: 50 },
	{ Header: 'Canceled', accessor: 'CANCEL_FLAG_DISPLAY', width: 15 },
	{ Header: 'Req. ID', accessor: 'REQUEST_ID', width: 14 },
	{ Header: 'Date Billed', accessor: 'DATE_BILLED_DISPLAY', width: 18 },
	{ Header: 'Remarks', accessor: 'REMARKS', width: 80 }
];

export default withRouter(Dewars);
