import ChemComponent from '../ChemComponent';
import { withCCSDefaultRoute } from './withCCSDefaultRoute';
import Form from 'react-bootstrap/Form';
import ChemEdit from '../ChemEdit';
import ChemTable from '../ChemTable';
import Tabs from '../Tabs';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

class CCS extends ChemComponent {
	constructor(props) {
		super(props);
		
		// if dry ice add a space so tabs will work
		var activeTab = props.params.activeTab || 'Checkout';
		if (activeTab === 'DryIce') activeTab = 'Dry Ice';
		
		this.state = {
			activeTab: activeTab,
			screen: screens.swipe,
			swipe: {
				CARD_MAG: ''
			},
			checkout: {
				SWIPE_PERSON_ID: null,
				SWIPE_PI_ROLE_ID: null,
				PERSON_ID: null,
				PI_ROLE_IDS: [],
				PI_ROLE_ID: null,
				SPEEDTYPE: null,
				CYL_BARCODE: '',
				RET_BARCODE: '',
				DRY_ICE: '',
				CYL_CAGE: 1,
				RET_CAGE: 16,
				checkout_details: [],
				return_details: [],
				pi_map: {},
			},
			confirm: {
				SALE_ID: null,
				GRAND_TOTAL: null
			},
			timerStart: null,
			timerID: null,
			secondsRemaining: null,
			renderKey: 0
		}
	}
	
	updateTimer() {
		// get number of seconds elapsed since timerStart
		var secondsElapsed = (new Date().getTime() - this.state.timerStart.getTime())/1000;
		var secondsRemaining = timerSeconds - secondsElapsed;
		if (secondsRemaining <= 0) {
			this.clearTimerAndLogout();
		} else {
			this.mergeState({ secondsRemaining: secondsRemaining });
		}
	}
	
	resetTimer() {
		this.mergeState({
			timerStart: new Date(),
			secondsRemaining: timerSeconds
		});
	}
	
	clearTimerAndLogout() {
		if (this.state.timerID !== null) clearInterval(this.state.timerID);
		this.logout();
	}
	
	componentDidUpdate(prevProps) {
		var self = this;
		
		// this is necessary so browser back/forward buttons will work across tabs
		var newActiveTab = this.props.params.activeTab || 'Checkout';
		if (newActiveTab === 'DryIce') newActiveTab = 'Dry Ice';
		if (newActiveTab !== this.state.activeTab) {
			this.mergeState({
				timerStart: new Date(),
				secondsRemaining: timerSeconds,
				activeTab: newActiveTab,
				renderKey: this.state.renderKey + 1
			});
		}

		// if the user has just logged in
		if (prevProps.user.uid !== this.props.user.uid && this.props.user.uid > 0) {
			// check in to HeLAD
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/HeLAD/CheckIn',
				data: { __RequestVerificationToken: this.props.user.antiForgeryToken, cardmag: this.state.swipe.CARD_MAG }
			}).done(function (data) {
				if (data.Success) {
					var newState = data.Data;
					
					newState.screen = screens.checkout;					
					newState.checkout = {
						SWIPE_PERSON_ID: newState.swipe_user.PERSON_ID,
						SWIPE_PI_ROLE_ID: newState.swipe_user.PI_ROLE_ID,
						PERSON_ID: newState.swipe_user.PERSON_ID,
						PI_ROLE_IDS: newState.swipe_user.PI_ROLE_IDS,
						PI_ROLE_ID: newState.swipe_user.PI_ROLE_ID,
						SPEEDTYPE: newState.swipe_user.DEFAULT_CC,
						checkout_details: []
					};
					newState.timerStart = new Date();
					// stop any existing timer
					if (self.state.timerID !== null) clearInterval(self.state.timerID);
					newState.timerID = setInterval(() => self.updateTimer(), 1000);
					newState.secondsRemaining = timerSeconds;
					
					self.mergeState(newState);
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		}
	}
	
	openCageDoors(pins) {
		try {
			var io = new window.ActiveXObject('AWCGP3DLL.GP3DLL');
			io.commport = 1;
			io.portopen = true;
			if (pins === 2) io.led = 1;
			io.pins = [pins];
			if (pins === 2) io.led = 0;
			io.portopen = false;			
		} catch (e) {
			console.log(e);
		}
	}
	
	onChange(accessor, value) {
		var newState = {
			timerStart: new Date(),
			secondsRemaining: timerSeconds,
			checkout: this.copyObject(this.state.checkout),
			renderKey: this.state.renderKey + 1
		};
		this.setByAccessor(newState.checkout, accessor, value);
		if (accessor === 'checkout_details') {
			newState.editorsOpen = this.setEditorStatus(accessor, false);
		} else if (accessor === 'PERSON_ID') {
			var piInfo = this.state.pi_map['' + value] || [];
			newState.checkout.PI_ROLE_IDS = [];
			newState.checkout.PI_ROLE_ID = null;
			newState.checkout.SPEEDTYPE = null;
			for (var i = 0; i < piInfo.length; i++) {
				var pipeIdx = piInfo[i].indexOf('|');
				var pi_role_id = piInfo[i].substring(0, pipeIdx);
				var speedtype = piInfo[i].substring(pipeIdx + 1);
				if (i === 0) {
					newState.checkout.PI_ROLE_ID = pi_role_id;
					newState.checkout.SPEEDTYPE = speedtype;
				}
				newState.checkout.PI_ROLE_IDS.push(pi_role_id);
			}
		}
		this.mergeState(newState);
	}

	addCylinder(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/HeLAD/AddCheckoutCylinder',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, barcode: this.state.checkout.CYL_BARCODE }
		}).done(function (data) {
			if (data.Success) {
				var checkout_details = self.copyObject(self.state.checkout.checkout_details);
				data.Data.id = checkout_details.length;
				checkout_details.push(data.Data);
				
				self.mergeState({
					timerStart: new Date(),
					secondsRemaining: timerSeconds,
					checkout: {
						checkout_details: checkout_details,
						CYL_BARCODE: ''
					}
				}, () => {
					self.openCageDoors(self.state.checkout.CYL_CAGE);
				});
			} else {
				self.showAlert('Server Error', data.Message);
				self.mergeState({
					timerStart: new Date(),
					secondsRemaining: timerSeconds,
					checkout: {
						CYL_BARCODE: ''
					}
				});
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
	
	deleteCylinder(id) {
		var i;
		
		// remove cylinder with matching id
		var checkout_details = this.copyObject(this.state.checkout.checkout_details);
		for (i = 0; i < checkout_details.length; i++) {
			if (checkout_details[i].id === id) {
				checkout_details.splice(i, 1);
				break;
			}
		}
		
		// renumber id's
		for (i = 0; i < checkout_details.length; i++) {
			checkout_details[i].id = i;
		}
		
		this.mergeState({
			checkout: {
				timerStart: new Date(),
				secondsRemaining: timerSeconds,
				checkout_details: checkout_details
			}
		}, () => {
			// open cage that cylinder came from!
		});
	}
		
	returnCylinder(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/HeLAD/CCSReturnCylinder',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, barcode: this.state.checkout.RET_BARCODE, speedtype: this.state.checkout.SPEEDTYPE }
		}).done(function (data) {
			if (data.Success) {
				var return_details = self.copyObject(self.state.checkout.return_details);
				data.Data.id = return_details.length;
				data.Data.STATUS = 'Returned';
				return_details.push(data.Data);
				
				self.mergeState({
					timerStart: new Date(),
					secondsRemaining: timerSeconds,
					checkout: {
						return_details: return_details
					}
				}, () => {
					self.openCageDoors(self.state.RET_CAGE);
					if (!self.isEmpty(data.Message)) {
						self.showAlert('Server Message', data.Message);
					}
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
	
	returnToCheckout() {
		this.mergeState({
			timerStart: new Date(),
			secondsRemaining: timerSeconds,
			screen: screens.checkout,
			checkout: {
				CYL_BARCODE: '',
				RET_BARCODE: '',
				DRY_ICE: '',
				checkout_details: [],
				return_details: []
			}
		});
	}

	onClickTabItem(tab) {
		// take space out of 'Dry Ice' so there is not an ugly %20 in the URL
		this.props.navigate('/HeLAD/CCS/' + (tab === 'Dry Ice' ? 'DryIce' : tab));
	}

	onSubmit(event, checkoutType) {
		if (checkoutType === 'Cylinder' && this.state.checkout.checkout_details.length === 0) {
			this.showAlert('No Cylinders Entered', 'Please scan at least one cylinder barcode for purchase.');
		} else if (checkoutType === 'DryIce' && !this.isNumeric(this.state.checkout.DRY_ICE)) {
			this.showAlert('Invalid Quantity', 'Please enter weight in lbs of dry ice to purchase.');
		} else if (checkoutType === 'DryIce' && this.isEmpty(this.state.checkout.SPEEDTYPE)) {
			this.showAlert('No Speedtype', 'Please select a speedtype for purchase.');
		} else {
			if (checkoutType === 'DryIce') {
				var self = this;
				this.showOKCancel('Confirm Purchase', 'Confirm purchase of ' + this.state.checkout.DRY_ICE + ' lbs of Dry Ice.', (okClicked) => {
					if (okClicked) self.purchase(checkoutType);
				});
			} else {
				this.purchase(checkoutType);
			}
		}
	}
	
	purchase(checkoutType) {
		var self = this;
		var checkout = this.copyObject(this.state.checkout);
		checkout.CHECKOUT_TYPE = checkoutType;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/HeLAD/CheckOut',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, checkout: checkout }
		}).done(function (data) {
			if (data.Success) {
				self.mergeState({
					timerStart: new Date(),
					secondsRemaining: timerSeconds,
					screen: screens.confirm
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	onChangeSwipe(accessor, value) {
		var swipe = this.copyObject(this.state.swipe);
		this.setByAccessor(swipe, accessor, value);
		this.mergeState({ swipe: swipe });
	}

	onSubmitSwipe(event) {
		this.props.logIn(this.state.swipe.CARD_MAG);
	}

	render() {
		// calculate numbers for timer
		var minutesRemaining = Math.floor(this.state.secondsRemaining / 60);
		var secondsRemaining = Math.round(this.state.secondsRemaining % 60);
		if (secondsRemaining === 60) { // huh??
			minutesRemaining += 1;
			secondsRemaining = 0;
		}
		var timer = minutesRemaining + (secondsRemaining < 10 ? ':0' : ':') + secondsRemaining;
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <Container fluid>
					<Row>
					  <Col xs={3}>
						<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Cylinder Checkout System</div>
					  </Col>
					  <Col xs={2}>
						{this.state.screen !== screens.swipe && <table>
						  <tbody>
						    <tr>
							  <td>Time Remaining:&nbsp;&nbsp;</td>
							  <td align='right'>{timer}</td>
							</tr>
						  </tbody>
						</table>}
					  </Col>
					  <Col xs={7}>
					  	{this.state.screen !== screens.swipe && 
						  <Button style={{ marginTop: '-6px' }} id="reset_timer" variant="warning" type="button" onClick={(event) => this.resetTimer()}>Add Time</Button>}
					  </Col>
					</Row>
					{this.state.screen === screens.swipe &&
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={swipeColumns} data={this.state.swipe} user={this.props.user} renderKey={this.state.renderKey} autoSubmit={true}
						   onChange={(accessor, value) => this.onChangeSwipe(accessor, value)} onSubmit={(event) => this.onSubmitSwipe(event)} editable={true} />
					  </Col>
					</Row>}
					{this.state.screen === screens.checkout && <>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={checkoutColumns} data={this.state.checkout} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)} editable={this.state.editable} />
					  </Col>
					</Row>
					<Row>
					  <Col style={{ paddingTop: '10px', paddingBottom: '10px' }}>
					    <Tabs activeTab={this.state.activeTab} onClickTabItem={(tab) => this.onClickTabItem(tab)}>
						  <div label='Checkout'>
						    <ChemEdit parent={this} columns={cylinderColumns} data={this.state.checkout} user={this.props.user} renderKey={this.state.renderKey}
								onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.addCylinder(event)} autoSubmit={true} />
							<ChemTable renderKey={this.state.renderKey} parent={this} data={this.state.checkout.checkout_details} columns={detailColumns} name='HeLAD_CCS_Checkout' />
							<Container fluid>
								<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
									<Col>
										<Button id="cylinder_logout" variant="warning" type="button" className="float-end" onClick={(event) => this.clearTimerAndLogout()}>Log Out</Button>
										<Button id="save" variant="warning" type="button" className="float-end" style={{ marginRight: '10px' }}
											onClick={(event) => this.onSubmit(event, 'Cylinder')}>Purchase</Button>
									</Col>
								</Form.Group>
							</Container>
						  </div>
						  <div label='Return'>
						    <ChemEdit parent={this} columns={returnColumns} data={this.state.checkout} user={this.props.user} renderKey={this.state.renderKey}
								onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.returnCylinder(event)} autoSubmit={true} />
							<ChemTable renderKey={this.state.renderKey} parent={this} data={this.state.checkout.return_details} columns={returnDetailColumns} name='HeLAD_CCS_Return' />
							<Container fluid>
								<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
									<Col>
										<Button id="logout" variant="warning" type="button" className="float-end" onClick={(event) => this.clearTimerAndLogout()}>Log Out</Button>
									</Col>
								</Form.Group>
							</Container>
						  </div>
						  <div label='Dry Ice'>
						    <ChemEdit parent={this} columns={dryIceColumns} data={this.state.checkout} user={this.props.user} renderKey={this.state.renderKey}
								onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event, 'DryIce')} autoSubmit={true}>
								<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
									<Col>
										<Button id="dry_ice_logout" variant="warning" type="button" className="float-end" onClick={(event) => this.clearTimerAndLogout()}>Log Out</Button>
										<Button id="dry_ice" variant="warning" type="submit" style={{ marginRight: '10px' }} className="float-end">Purchase</Button>
									</Col>
								</Form.Group>
							</ChemEdit>
						  </div>
						  <div label='Admin'>
						  {/* { value: 255, label: 'Open All Doors' } */}
						  </div>
					    </Tabs>
					  </Col>
					</Row>
					<Row>
					  <Col>
					  </Col>
					</Row>
					</>}
					{this.state.screen === screens.confirm &&
					<Row>
					  <Col xs={9} style={{ paddingBottom: '20px', paddingTop: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
						Checkout completed. Please remember to log out when you are finished.
					  </Col>
					  <Col xs={3} style={{ marginTop: '10px' }}>
						<Button id="logout" variant="warning" type="button" className="float-end" onClick={(event) => this.clearTimerAndLogout()}>Log Out</Button>
						<Button id="checkout" variant="warning" type="button" className="float-end" style={{ marginRight: '10px' }}
							onClick={(event) => this.returnToCheckout()}>Return to Checkout</Button>
					  </Col>
				    </Row>}
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const timerSeconds = 600;

const screens = {
	swipe: 'Swipe Card',
	checkout: 'Checkout',
	confirm: 'Confirm'
};

const swipeColumns = [
	{ Header: 'Please Swipe Card to Start', accessor: 'CARD_MAG', autofocus: true, autocomplete: false }
];

const checkoutColumns = [
	{ Header: 'User', accessor: 'PERSON_ID', type: 'select', row: 0, col: 0,
		options: { value: 'PERSON_ID', label: 'USER_NAME', entity: 'STORES.CHECKOUT_USER_SELECT_VW', order: ['SWIPED_AT_TOP','SORT_USER_NAME'],
			search: { Operator: 'or', Children: [
				{ Operator: 'and', Children: [
					{ Attribute: 'PERSON_ID', Operator: '=', LongAccessor: 'SWIPE_PERSON_ID' },
					{ Attribute: 'SWIPED_AT_TOP', Operator: '=', IntValue: '0' }
				]},
				{ Operator: 'and', Children: [
					{ Attribute: 'PERSON_ID', Operator: '!=', LongAccessor: 'SWIPE_PERSON_ID' },
					{ Attribute: 'SWIPED_AT_TOP', Operator: '=', IntValue: '1' }
				]}
	]}}},
	{ Header: 'PI', accessor: 'PI_ROLE_ID', type: 'select', row: 0, col: 1,
		options: { value: 'ROLE_ID', label: 'PI_NAME', entity: 'STORES.CHECKOUT_PI_SELECT_VW', order: ['USER_PI_FIRST','SORT_PI_NAME'],
			search: { Operator: 'or', Children: [
				{ Operator: 'and', Children: [
					{ Attribute: 'ROLE_ID', Operator: 'in', LongListAccessor: 'PI_ROLE_IDS' },
					{ Attribute: 'USER_PI_FIRST', Operator: '=', IntValue: '0' }
				]},
				{ Operator: 'and', Children: [
					{ Attribute: 'ROLE_ID', Operator: 'not in', LongListAccessor: 'PI_ROLE_IDS' },
					{ Attribute: 'USER_PI_FIRST', Operator: '=', IntValue: '1' }
				]}
	]}}},
	{ Header: 'Speedtype', accessor: 'SPEEDTYPE', type: 'select', row: 0, col: 2,
		options: { value: 'COST_CENTER', label: 'COST_CENTER_DISPLAY', entity: 'STORES.CHECKOUT_SPEEDTYPE_SELECT_VW', order: 'COST_CENTER',
			search: { Attribute: 'ROLE_ID', Operator: '=', LongAccessor: 'PI_ROLE_ID'
	}}}
];

const cylinderColumns = [
	{ Header: 'Cage', accessor: 'CYL_CAGE', type: 'select', row: 0, col: 0, options: [
		{ value: 1, label: 'Misc' },
 		{ value: 2, label: 'Argon, Air, Oxygen' },
		{ value: 4, label: 'Nitrogen, Hydrogen, Acetyl.' },
		{ value: 8, label: 'Specialty Gases' }]},
	{ Header: 'Please scan the barcode', accessor: 'CYL_BARCODE', row: 0, col: 1 }
];

const returnColumns = [
	{ Header: 'Cage', accessor: 'RET_CAGE', type: 'select', row: 0, col: 0, options: [
		{ value: 16, label: 'All Gases' },
		{ value: 96, label: 'Other' },
		{ value: 128, label: 'Praxair' }]},
	{ Header: 'Please scan the barcode', accessor: 'RET_BARCODE', row: 0, col: 1 }
];

const dryIceColumns = [
	{ Header: 'Enter Weight (in lb)', accessor: 'DRY_ICE' },
	{ Header: '', accessor: 'DRY_ICE', type: 'numpad' }
];

const detailColumns = [
	{ Header: '', accessor: 'id', Cell: props => <Trash style={{ cursor: 'pointer' }} onClick={() => props.parent.deleteCylinder(props.value)} />, width: 25 },
	{ Header: 'Gas', accessor: 'GAS' },
	{ Header: 'Cyl ID', accessor: 'CYL_ID' },
	{ Header: 'Vendor', accessor: 'VENDOR' },
	{ Header: 'Cost', accessor: 'COST', align: 'right', Cell: props => props.parent.formatCurrency(props.value) }
];

const returnDetailColumns = [
	{ Header: 'No.', accessor: 'id', Cell: props => <font color="##FF0000">{props.value + 1}</font> },
	{ Header: 'Gas', accessor: 'GAS' },
	{ Header: 'Cyl ID', accessor: 'CYL_ID' },
	{ Header: 'Vendor', accessor: 'VENDOR' },
	{ Header: 'Cost', accessor: 'COST' },
	{ Header: 'Return Date', accessor: 'RETURN_DATE', width: 200 },
	{ Header: 'Status', accessor: 'STATUS' }
];

export default withCCSDefaultRoute(CCS);
