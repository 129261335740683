import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';

class COA extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			ACCOUNT: '',
			FUND_CODE: '',
			DEPARTMENT: '',
			PROGRAM: '',
			CHART_FIELD_1: '',
			CHART_FIELD_2: ''
		};
		
		// initialize coa list
		var initialCOAList = this.copyCOAList();
		initialCOAList.query.search = this.constructSearch(search);
		
		this.state = {
			renderKey: 1,
			coaList: initialCOAList,
			search: search,
			searchCount: null
		};
	}
	
	copyCOAList() {
		var coaListCopy = this.copyObject(coaList);
		for (var i = 0; i < coaList.columns.length; i++) {
			if (coaList.columns[i].Header) {
				coaListCopy.columns[i].Header = coaList.columns[i].Header;
			}
			if (coaList.columns[i].Cell) {
				coaListCopy.columns[i].Cell = coaList.columns[i].Cell;
			}
		}
		return coaListCopy;
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newCOAList = this.copyCOAList();
		newCOAList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			coaList: newCOAList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};		

		if (!this.isEmpty(data.ACCOUNT)) search.Children.push({ Attribute: 'ACCOUNT', Operator: '=', StringValue: data.ACCOUNT });
		if (!this.isEmpty(data.FUND_CODE)) search.Children.push({ Attribute: 'FUND_CODE', Operator: '=', StringValue: data.FUND_CODE });
		if (!this.isEmpty(data.DEPARTMENT)) search.Children.push({ Attribute: 'DEPARTMENT', Operator: '=', StringValue: data.DEPARTMENT });
		if (!this.isEmpty(data.PROGRAM)) search.Children.push({ Attribute: 'PROGRAM', Operator: '=', StringValue: data.PROGRAM });
		if (!this.isEmpty(data.CHART_FIELD_1)) search.Children.push({ Attribute: 'CHART_FIELD_1', Operator: '=', StringValue: data.CHART_FIELD_1 });
		if (!this.isEmpty(data.CHART_FIELD_2)) search.Children.push({ Attribute: 'CHART_FIELD_2', Operator: '=', StringValue: data.CHART_FIELD_2 });

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'COA',
			columns: exportColumns,
			entity: 'RECHARGES.COA_VW',
			search: this.constructSearch(this.state.search),
			order: ['DEPARTMENT', 'PROGRAM', 'CHART_FIELD_1']
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>COA</span>
					<ChemEdit parent={this} columns={coaSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={3} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/CSV'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to CSV</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/filetype-csv.png'} style={{ paddingLeft: '20px' }} alt="Export to CSV" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6 }}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							    </OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_COA' table={this.state.coaList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='250px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const coaSearch = [
	{ Header: 'Account', accessor: 'ACCOUNT', row: 0, col: 0 },
	{ Header: 'Fund Code', accessor: 'FUND_CODE', row: 0, col: 1 },
	{ Header: 'Department', accessor: 'DEPARTMENT', row: 0, col: 2 },
	{ Header: 'Program', accessor: 'PROGRAM', row: 1, col: 0 },
	{ Header: 'Chart Field 1', accessor: 'CHART_FIELD_1', row: 1, col: 1 },
	{ Header: 'Chart Field 2', accessor: 'CHART_FIELD_2', row: 1, col: 2 },
];

const coaList = {
	query: { entity: 'RECHARGES.COA_VW', search: null, order: ['DEPARTMENT', 'PROGRAM', 'CHART_FIELD_1'] },
	pageSize: 20,
	columns: [
		{ Header: 'Business Unit', accessor: 'BUSINESS_UNIT' },
		{ Header: 'Account', accessor: 'ACCOUNT' },
		{ Header: 'Fund Code', accessor: 'FUND_CODE' },
		{ Header: 'Department', accessor: 'DEPARTMENT' },
		{ Header: 'Program', accessor: 'PROGRAM' },
		{ Header: 'Chart Field 1', accessor: 'CHART_FIELD_1' },
		{ Header: 'Chart Field 2', accessor: 'CHART_FIELD_2' }
	]
};

const exportColumns = [
	{ Header: 'Business Unit', accessor: 'BUSINESS_UNIT', width: 15 },
	{ Header: 'Account', accessor: 'ACCOUNT', width: 15 },
	{ Header: 'Fund Code', accessor: 'FUND_CODE', width: 15 },
	{ Header: 'Department', accessor: 'DEPARTMENT', width: 15 },
	{ Header: 'Program', accessor: 'PROGRAM', width: 15 },
	{ Header: 'Chart Field 1', accessor: 'CHART_FIELD_1', width: 15 },
	{ Header: 'Chart Field 2', accessor: 'CHART_FIELD_2', width: 15 }
];

export default withRouter(COA);