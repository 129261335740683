import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row } from 'react-bootstrap';
import ChemComponent from './ChemComponent';
import ChemMenuBox from './ChemMenuBox';
import ChemListBox from './ChemListBox';
import Identity from './Identity';
import { withRouter } from './withRouter';

class Home extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var stateLeftMenuItems = this.copyObject(leftMenuItems);

		// if it's production environment and not me
		if (this.isProduction() && !props.user.isImpersonator)
		{
			// remove identity menu item
			for (var i = 0; i < stateLeftMenuItems.length; i++) {
				if (stateLeftMenuItems[i].route === 'Identity') {
					stateLeftMenuItems.splice(i--, 1);
				}
			}
		}
		
		// add the about menu item
		stateLeftMenuItems.push({
			text: 'About',
			onClick: () => this.showAbout()
		});
		
		this.state = {
			apps : [
			{
				text: "ChemNet Login",
				href: 'https://' + this.getAuthServer() + '/cas/login?service=' + this.getConfig().reactHost,
				comments: "Authenticate via CalNet Central Authentication Service (CAS) to access College of Chemistry web applications"
			}],
			leftMenuItems: stateLeftMenuItems,
			appsLoaded: false
		}
	}
		
	componentDidMount() {
		this.getUserApplications();
	}
	
	componentDidUpdate(prevProps) {
		if (prevProps.user.uid !== this.props.user.uid) {
			this.getUserApplications();
		}
	}
	
	getUserApplications() {
		var self = this;

		if (this.props.user.uid > 0 && !this.state.appsLoaded) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/Home/GetUserApplications',
				overlay: true,
				data: { __RequestVerificationToken: this.props.user.antiForgeryToken, uid: this.props.user.uid }
			}).done(function (data) {
				if (data.Success) {
					// convert to list that ChemListBox will understand
					var apps = [];
					if (data.UserApplications) {
						for (var i = 0; i < data.UserApplications.length; i++) {
							apps.push({
								text: data.UserApplications[i].ApplicationDetail,
								href: data.UserApplications[i].Url,
								comments: data.UserApplications[i].Comments,
								target: '_blank',
								rel: 'noreferrer'
							});
						}
					}

					self.mergeState({
						apps: apps,
						appsLoaded: true
					});
				} else {
					self.showAlert('Server Error', data.Message);
				}
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});
		}		
	}
	
	exitIdentity() {
		this.props.navigate(this.getParentMatchPath());
	}
	
	renderWebApps() {
		// to display identity, it has to either be not-production or me
		if (this.props.identity && (!this.isProduction() || this.props.user.isImpersonator)) {
			return (<Identity parent={this} user={this.props.user} exitIdentity={() => this.exitIdentity()} />);
		} else {
			return (<ChemListBox parent={this} title='Your College of Chemistry Web Applications' listItems={this.state.apps} float='right' loading={!this.state.appsLoaded} />);
		}
	}
	
	render() {
		var testSiteText = this.isTest() ? " - TEST SITE" : "";  
		
		return (<>
			<Container fluid style={style.container}>
				<Row style={{ marginLeft: 0, marginRight: 0 }}>
					<Col style={{ paddingLeft: 0, paddingRight: 0 }}>
						<div style={{ background: "url(images/header-abstract.png) no-repeat center top", height: "260px", backgroundSize: "cover"}}></div>
						<div style={{ fontSize: '.875em', lineHeight: '1.5em', marginTop: '-260px', marginLeft: 'auto', marginRight: 'auto', maxWidth: '1280px' }}>
							<header style={{ height: '260px', position: 'relative', paddingLeft: '10px', paddingRight: '10px', boxSizing: 'border-box', wordWrap: 'break-word' }} role="banner">
								<div style={{ marginLeft: '10%', marginTop: '70px', float: 'left'}}>
									<a href="http://chemistry.berkeley.edu/"><img alt="University of California, Berkeley" src="images/berkeley.png" height="70" width="204" /></a>
								</div>
								<div style={{ float: 'left' }}><img style={{ margin: '0 20px 0' }} alt="" src="images/divider-blue.png" height="122" width="5" /></div>
								<div style={{ fontSize: '0.875em', lineHeight: '1.71429em', float: 'left', margin: '108px 0 0 10px', position: 'relative' }} className="clearfix">
									<div style={{ marginBottom: '1.15em', marginTop: '1.15em', minWidth: '30em', textTransform: 'none', fontFamily: '"Helvetica Neue",Helvetica,Arial,FreeSans,"Liberation Sans",sans-serif', fontSize: '1.5em', lineHeight: '0px', color: '#003262', position: 'absolute', bottom: '10px' }}>Advancing society through education and research</div>
									<div style={{ margin: 0, fontSize: '2.3em', lineHeight: '0px' }}>
										<a style={{ color: '#003262', textDecoration: 'none' }} href="http://chemistry.berkeley.edu/" title="Home" rel="home">
											<span style={{ fontFamily: '"Helvetica Neue",Helvetica,Arial,FreeSans,"Liberation Sans",sans-serif' }}>College of Chemistry{testSiteText}</span>
										</a>
									</div>
								</div>
							</header>

							<div style={{ background: '#f1f1ee', margin: '-100px auto 0', zIndex: 5, width: '80%', boxShadow: 'rgb(0 0 0 / 40%) 0px 1px 2px', borderRadius: '4px', paddingTop: '3em', position: 'relative' }}>
								<div style={{ minHeight: '300px', paddingBottom: '10px', paddingLeft: '10px', paddingRight: '10px', boxSizing: 'border-box', wordWrap: 'break-word' }} role="main">
									<ChemMenuBox parent={this} menuItems={this.state.leftMenuItems} />
									<h1 style={style.bsrH1Override}>Welcome {this.props.user.name}&nbsp;&nbsp;&nbsp;&nbsp;
										<button title="Logout" rel="Logout" className="btn" style={style.logoutButton} onClick={(event) => this.logout(event)}>Logout</button>
									</h1>
									<br />
									<div className="clearfix">
										<div className="container-fluid">
											{this.renderWebApps()}
										</div>
									</div>
								</div>
								<div style={style.navigation}>
									<nav role="navigation" tabIndex="-1" />
									<ul style={style.mainMenu}>
										<li style={style.menuItem}>College of Chemistry Directory<ul style={{display: 'none', visibility: 'hidden'}}></ul></li>
									</ul>
								</div>	
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const leftMenuItems = [
	{
		href: 'http://chemistry.berkeley.edu/',
		title: 'Home',
		rel: 'home',
		text: 'CoC Home'
	},
	{
		href: 'http://www.berkeley.edu/directory',
		text: 'Campus Directory'
	},
	{
		href: 'https://chemapps.berkeley.edu/main/CF_College_reg/login.cfm',
		text: 'Update My Info'
	},
	{
		href: 'mailto:jborland@berkeley.edu',
		text: 'Contact Us'
	},
	{
		route: 'Identity',
		text: 'Identity'
	}
];

const style = {
	bsrH1Override: {
		marginLeft: 200, 
		fontWeight: 'bold', 
		fontSize: '1.57em',	
		lineHeight: '1em',
		marginTop: '0.5em',
		marginRight: 0,
		marginBottom: '0.5em',
		padding: 0,
		paddingBottom: 4, 
		borderBottom: '1px solid #ccc',
		fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif'
	},
	container: {
		background: '#e6e6e4',
		color: '#333',
		paddingLeft: 0, 
		paddingRight: 0,
		paddingBottom: 20
	},
	logoutButton: {
		border: 0, 
		borderRadius: '5px', 
		color: '#333', 
		fontSize: '10px', 
		fontWeight: 'bold', 
		padding: '0px 28px 0px 28px', 
		textDecoration: 'none',
		background: '#e6e6e4',
		backgroundImage: 'linear-gradient(to bottom, #e6e6e4, #d1d1d1)',
		fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif'
	},
	mainMenu: {
		float: 'left',
		border: 0,
		listStyle: 'none',
		padding: 0,
		margin: 0,
		textAlign: 'left'		
	},
	menuItem: {
		color: '#000',
		textTransform: 'uppercase',
		fontSize: '13.125px',
		lineHeight: '21px',
		padding: '10px 10px 8px',
		fontWeight: 600,
		float: 'left',
		background: '#fdb515',
		borderTop: 'none',
		border: 'none'
	},
	navigation: {
		background: '#fdb515',
		position: 'absolute',
		top: 0,
		maxHeight: '42px',
		width: '100%',
		paddingLeft: '10px',
		paddingRight: '10px',
		boxSizing: 'border-box',
		wordWrap: 'break-word',	
		fontSize: '.875em',
		lineHeight: '1.5em',
		fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif'
	}
};

export default withRouter(Home);