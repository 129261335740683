import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';
import ChemTable from '../ChemTable';
import EditTable from '../EditTable';
import Tabs from '../Tabs';
import { Col, Container, Row, Button, InputGroup, Form } from 'react-bootstrap';
import { Calculator, PlusSquare, LockFill, UnlockFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { withApplicationDefaultRoute } from './withApplicationDefaultRoute';

class Application extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			editable: props.editable,
			activeTab: props.params.activeTab || 'Details',
			application: {
				AddressPhone: {},
				ApplicationID: '',
			},
			editorsOpen: [],
			evalColumns: this.copyColumns(evalColumns),
			calculator: this.copyObject(emptyCalculator),
			emailMaterialRequest: false,
			materialRequest: this.copyObject(defaultMaterialRequest),
			recommendation: this.copyObject(emptyRecommendation),
			appStatus: null,
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadApplication();
	}

	componentDidUpdate(prevProps) {
		var newActiveTab = this.props.params.activeTab || 'Details';
		
		// this is necessary so browser back/forward buttons will work across tabs
		if (newActiveTab !== this.state.activeTab) {
			this.mergeState({
				activeTab: newActiveTab,
				renderKey: this.state.renderKey + 1
			});
		}
		
		// if this is a different applicant, reload the application
		if (this.props.params.applicationId !== prevProps.params.applicationId) {
			this.loadApplication();
		}
	}

	loadApplication(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/GradOffice/GetApplication',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				appid: this.props.params.applicationId
			}
		}).done(function (data) {
			if (data.Success) {
				var application = data.application;
				var newEvalColumns = self.copyColumns(evalColumns);
				
				// toefl flags are 1/0 instead of Y/null
				application.app_details.TOEFL1_OFFICIAL = application.app_details.TOEFL1_OFFICIAL === 1 ? 'Y' : null;
				application.app_details.TOEFL2_OFFICIAL = application.app_details.TOEFL2_OFFICIAL === 1 ? 'Y' : null;
				
				// convert dates, checkboxes
				self.mvc2js(application, detailColumns);
				self.mvc2js(application, contactTempColumns);
				self.mvc2js(application, gpaColumns);
				self.mvc2js(application, self.state.evalColumns);
				self.mvc2js(application, visitColumns);
				self.mvc2js(application, testColumns);
				self.mvc2js(application, toeflColumns);
				self.mvc2js(application, materialColumns);
				
				// convert residency checkboxes
				if (application.CA_RESIDENT === 'Y') {
					application.CA_RESIDENT = 'RESIDENT';
				} else if (application.CA_RES_EXP === 'Y') {
					application.CA_RESIDENT = 'EXPECTED';
				} else {
					application.CA_RESIDENT = 'NON-RESIDENT';
				}
				
				// convert previous submission info
				application.PREV_UCB_GRAD_APPL = application.app_details.PREV_UCB_GRAD_APPL_TERM + ' ' + application.app_details.PREV_UCB_GRAD_APPL_YR;
				
				// set up deferred cell if necessary
				self.setDeferredRecColumn(application, newEvalColumns);
				
				// set Modified info
				application.MODIFIED = self.combineChangeInfo(
					self.composeChangeInfo(application.CREATED_BY, application.CREATED),
					self.composeChangeInfo(application.ENTERED_BY, application.LAST_MODIF));
				
				self.mergeState({
					application: application,
					evalColumns: newEvalColumns,
					appStatus: data.status,
					renderKey: self.state.renderKey + 1
				}, () => {
					if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
	
	setDeferredRecColumn(application, newEvalColumns) {		
		// find the DEFERRED_REC column
		var col = this.findColumnByAccessor(newEvalColumns, 'DEFERRED_REC');
		
		// if the offer was deferred
		if (this.isNumeric(application.app_evaluation.OFFER_ID) && +application.app_evaluation.OFFER_ID === 3) {
			// if there is already a next cycle application
			if (!this.isEmpty(application.NEXT_CYCLE_APP_ID)) {
				// set DEFERRED_REC to the application ID
				application.DEFERRED_REC = application.NEXT_CYCLE_APP_ID;
				
				// set the cell function so it will draw a link to the new record
				newEvalColumns[col].Cell = props => <>New record created for next admission cycle: <Link to={'/GradOffice/Applications/' + props.value}>{props.value}</Link></>;
			} else {
				// checkbox to create a new app for next cycle
				// don't overwrite checkbox value if it's already a boolean
				if (application.DEFERRED_REC !== false && application.DEFERRED_REC !== true) application.DEFERRED_REC = true;
				delete newEvalColumns[col].Cell;
				newEvalColumns[col].type = 'checkbox';
				newEvalColumns[col].label = 'Create applicant record for ' + (+application.ACADEMIC_YEAR + 1);
			}
		} else if (!this.isEmpty(application.DEFER_REC)) {
			// set DEFERRED_REC to old app ID
			application.DEFERRED_REC = application.DEFER_REC;
			
			// draw link to old record
			newEvalColumns[col].Cell = props => <>Record deferred from <Link to={'/GradOffice/Applications/' + props.value}>{props.value}</Link></>;
		}
	}
	
	onEdit(accessor, editorIsOpen) {
		this.mergeState({
			editorsOpen: this.setEditorStatus(accessor, editorIsOpen),
			renderKey: this.state.renderKey + 1
		});
	}
	
	setEditorStatus(accessor, editorIsOpen) {
		var editorsOpen = this.copyObject(this.state.editorsOpen);
		if (editorIsOpen) {
			// add to list if it isn't already present
			if (!editorsOpen.includes(accessor)) editorsOpen.push(accessor);
		} else {			
			// remove from list if it is present
			if (editorsOpen.includes(accessor)) editorsOpen.splice(editorsOpen.indexOf(accessor), 1);
		}
		return editorsOpen;
	}		
		
	onChange(accessor, value) {
		var application = this.copyObject(this.state.application);
		var newEvalColumns = this.copyColumns(evalColumns);
		this.setByAccessor(application, accessor, value);
		this.setDeferredRecColumn(application, newEvalColumns);
		var newState = {
			application: application,
			evalColumns: newEvalColumns,
			renderKey: this.state.renderKey + 1
		};
		// if this accessor represents an EditTable
		if (['app_education','recommd','visit_schedule'].includes(accessor)) {
			// update editor status as well
			newState.editorsOpen = this.setEditorStatus(accessor, false);
		}
		this.mergeState(newState);
	}
	
	onChangeCalculator(accessor, value) {
		var calculator = this.copyObject(this.state.calculator);
		this.setByAccessor(calculator, accessor, value);
		
		// calculate GPA
		var AUnits = this.isNumeric(calculator.A) ? +calculator.A : 0;
		var AMinusUnits = this.isNumeric(calculator.AMinus) ? +calculator.AMinus : 0;
		var BPlusUnits = this.isNumeric(calculator.BPlus) ? +calculator.BPlus : 0;
		var BUnits = this.isNumeric(calculator.B) ? +calculator.B : 0;
		var BMinusUnits = this.isNumeric(calculator.BMinus) ? +calculator.BMinus : 0;
		var CPlusUnits = this.isNumeric(calculator.CPlus) ? +calculator.CPlus : 0;
		var CUnits = this.isNumeric(calculator.C) ? +calculator.C : 0;
		var CMinusUnits = this.isNumeric(calculator.CMinus) ? +calculator.CMinus : 0;
		var DPlusUnits = this.isNumeric(calculator.DPlus) ? +calculator.DPlus : 0;
		var DUnits = this.isNumeric(calculator.D) ? +calculator.D : 0;
		var DMinusUnits = this.isNumeric(calculator.DMinus) ? +calculator.DMinus : 0;
		var FUnits = this.isNumeric(calculator.F) ? +calculator.F : 0;
		var totalUnits = AUnits + AMinusUnits + 
			BPlusUnits + BUnits + BMinusUnits + 
			CPlusUnits + CUnits + CMinusUnits +
			DPlusUnits + DUnits + DMinusUnits + FUnits;
		var gpa = totalUnits === 0 ? 0 : ((AUnits * 4.0 + AMinusUnits * 3.7 +
			BPlusUnits * 3.3 + BUnits * 3.0 + BMinusUnits * 2.7 +
			CPlusUnits * 2.3 + CUnits * 2.0 + CMinusUnits * 1.7 +
			DPlusUnits * 1.3 + DUnits * 1.0 + DMinusUnits * 0.7)/totalUnits);
		var application = this.copyObject(this.state.application);
		this.setByAccessor(application, this.state.calculator.accessor, gpa.toLocaleString('en-US', {
			minimumFractionDigits: 2,      
			maximumFractionDigits: 2,
		}));
		this.mergeState({ 
			application: application,
			calculator: calculator, 
			renderKey: this.state.renderKey + 1 
		});
	}

	validateApplication(event, columns, data) {
		return this.validate(columns, data);
	}
		
	onSubmit(event) {
		var i;
		var self = this;		
		
		// make copy of data
		var application = this.copyObject(this.state.application);

		// convert dates, checkboxes
		this.js2mvc(application, detailColumns);
		this.js2mvc(application, contactTempColumns);
		this.js2mvc(application, gpaColumns);
		this.js2mvc(application, this.state.evalColumns);
		this.js2mvc(application, visitColumns);
		this.js2mvc(application, testColumns);
		this.js2mvc(application, toeflColumns);
		this.js2mvc(application, materialColumns);

		// convert residency checkboxes
		application.CA_RES_EXP = application.CA_RESIDENT === 'EXPECTED' ? 'Y' : null;
		application.CA_RESIDENT = application.CA_RESIDENT === 'RESIDENT' ? 'Y' : null;

		// convert degree dates to MVC
		for (i = 0; i < application.app_education.length; i++) {
			application.app_education[i].DEGREE_DATE = self.dateTimeToMVC(self.getDate(application.app_education[i].DEGREE_DATE));
		}
		
		// make sure GREs are numbered, in case any have been created
		if (Array.isArray(application.gre_test)) {
			for (i = 0; i < application.gre_test.length; i++) {
				if (application.gre_test[i] === null) application.gre_test[i] = {};
				application.gre_test[i].GRE_NUMBER = i + 1;
			}
		}
		
		// toefl flags are 1/0 instead of Y/null
		application.app_details.TOEFL1_OFFICIAL = application.app_details.TOEFL1_OFFICIAL === 'Y' ? 1 : 0;
		application.app_details.TOEFL2_OFFICIAL = application.app_details.TOEFL2_OFFICIAL === 'Y' ? 1 : 0;
					
		// if this is a new application, set ID to sequence name
		if (!this.props.params.applicationId) {
			application.APP_ID = 'application_id.nextval';
		}
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/GradOffice/UpdateApplication',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, application: application }
		}).done(function (data) {
			if (data.Success) {
				var applicationId = data.ID;
				var verb = self.props.params.applicationId ? 'updated' : 'inserted';
				self.props.parent.showConfirmation(() => {
					return (<>
						Application <Link to={'/GradOffice/Applications/' + applicationId}>{applicationId}</Link> {verb}.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	openCalculator(event, accessor, value) {
		this.mergeState({
			calculator: {
				accessor: accessor,
				revert: value,
				show: true
			},
			renderKey: this.state.renderKey + 1
		});
	}
	
	closeCalculator(event) {
		this.mergeState({
			calculator: emptyCalculator,
			renderKey: this.state.renderKey + 1
		});
	}
	
	cancelCalculator(event) {
		var application = this.copyObject(this.state.application);
		this.setByAccessor(application, this.state.calculator.accessor, this.state.calculator.revert);
		this.mergeState({ 
			application: application, 
			calculator: emptyCalculator,
			renderKey: this.state.renderKey + 1 
		});
	}

	onClickMaterialRequest(event) {			
		this.mergeState({
			emailMaterialRequest: true,
			materialRequest: {
				to: this.isEmpty(this.state.materialRequest.to) ? this.state.application.address_phone.EMAIL : this.state.materialRequest.to
			},
			renderKey: this.state.renderKey + 1
		});
	}
	
	onChangeMaterialRequest(accessor, value) {
		var materialRequest = this.copyObject(this.state.materialRequest);
		this.setByAccessor(materialRequest, accessor, value);
		this.mergeState({ materialRequest: materialRequest, renderKey: this.state.renderKey + 1 });
	}
	
	onCancelMaterialRequest(event) {
		this.mergeState({
			emailMaterialRequest: false,
			renderKey: this.state.renderKey + 1
		});
	}
	
	onSubmitMaterialRequest(event) {
		var self = this;
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/GradOffice/SendMaterialRequestEmail',
			overlay: true,
			data: {
				appid: this.state.application.APP_ID,
				to: this.state.materialRequest.to,
				cc: this.state.materialRequest.cc,
				subject: this.state.materialRequest.subject,
				body: this.state.materialRequest.body
			}
		}).done(function (data) {
			if (data.Success) {
				self.mergeState({
					emailMaterialRequest: false,
					renderKey: self.state.renderKey + 1
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}
	
	onClickAddDefaultAppt(rowId) {
		var i;
		
		// if there is a visit date
		if (this.state.application.app_visit.VISIT_DATE) {
			// find default appointment
			var default_appt = null;
			for (i = 0; i < this.state.application.default_visit_appt.length; i++) {
				if (this.state.application.default_visit_appt[i].id === rowId) {
					default_appt = this.state.application.default_visit_appt[i];
					break;
				}
			}
			
			// make copy of visit schedule
			var visit_schedule = this.copyObject(this.state.application.visit_schedule);

			// find the max id in the visit schedule
			var maxId = -1;
			for (i = 0; i < visit_schedule.length; i++) {
				if (visit_schedule[i].id > maxId) maxId = visit_schedule[i].id;
			}
			
			// add new appointment to visit schedule
			visit_schedule.push({
				id: maxId + 1,
				drag: maxId + 1,
				LINE_ID: visit_schedule.length + 1,
				FACULTY: default_appt.DEFAULT_NAME,
				ROOM_LOC: default_appt.DEFAULT_LOCATION,
				VISIT_DATE: this.state.application.app_visit.VISIT_DATE,
				START_TIME: default_appt.DEFAULT_BEG,
				END_TIME: default_appt.DEFAULT_END
			});
			
			// set new schedule
			this.mergeState({
				application: {
					visit_schedule: visit_schedule
				},
				renderKey: this.state.renderKey + 1
			});
		} else {
			this.showAlert('Visit Date Required', 'Please enter a visit date to add default appointments.');
		}
	}

	canClickTabItem(tab) {
		// prevent user from leaving a tab if an editor is open
		if (this.state.activeTab === 'Education' && this.state.editorsOpen.includes('app_education')) {
			this.showAlert('Editor Open', 'Please close the institution editor before leaving the Education tab.');
			return false;
		} else if (this.state.activeTab === 'Recommendations' && this.state.editorsOpen.includes('recommd')) {
			this.showAlert('Editor Open', 'Please close the recommendation editor before leaving the Recommendations tab.');
			return false;
		} else if (this.state.activeTab === 'Visit' && this.state.editorsOpen.includes('visit_schedule')) {
			this.showAlert('Editor Open', 'Please close the schedule editor before leaving the Visit tab.');
			return false;
		}
		return true;
	}
	
	onClickTabItem(tab) {
		this.props.navigate('/GradOffice/Applications/' + (this.props.params.applicationId || 'new') + '/' + tab);
	}

	renderWithCalculator(accessor, value) {
		var optionalDisabledProp = this.state.editable === false ? { disabled: true } : {};
		return (
			<InputGroup style={{ marginTop: '-8px' }}>
				<Form.Control type="text" value={value} {...optionalDisabledProp}
					onChange={(event) => this.onChange(accessor, event.target.value)} />
			{this.state.editable !== false && <Button onClick={(event) => this.openCalculator(event, accessor, value)}><Calculator style={{ marginTop: '-4px' }}/></Button>}
			</InputGroup>
		);	
	}
	
	renderGPA () {
		// build fake data table for GPA
		const gpaDisplayData = [
			{ id: 0, GPA_TYPE: 'Undergrad', CONFIRMED: this.state.application.app_details.UNGRAD_GPA_CONF ? 'Y' : 'N' },
			{ id: 1, GPA_TYPE: 'Grad', CONFIRMED: this.state.application.app_details.GRAD_GPA_CONF ? 'Y' : 'N' }
		];
		
		return (<ChemTable renderKey={this.state.renderKey} parent={this} data={gpaDisplayData} columns={gpaDisplayColumns} name='GradOffice_Application_Materials_GPA' />);
	}

	renderTests () {
		// build fake data table for tests
		const testDisplayData = [
			{ id: 0, TEST_TYPE: 'TOEFL 1 Official', RECEIVED: +this.state.application.app_details.TOEFL1_OFFICIAL > 0 ? 'Y' : 'N' },
			{ id: 1, TEST_TYPE: 'TOEFL 2 Official', RECEIVED: +this.state.application.app_details.TOEFL2_OFFICIAL > 0 ? 'Y' : 'N' },
			{ id: 2, TEST_TYPE: 'TSE Official', RECEIVED: +this.state.application.app_details.TSE_OFFICIAL > 0 ? 'Y' : 'N' }
		];
		
		return (<ChemTable renderKey={this.state.renderKey} parent={this} data={testDisplayData} columns={testDisplayColumns} name='GradOffice_Application_Materials_Tests' />);
	}
	
	renderMaterialReceived() {
		if (this.state.emailMaterialRequest) {
		  return (
		    <ChemEdit id='emailMaterialRequest' parent={this} columns={emailColumns} data={this.state.materialRequest} renderKey={this.state.renderKey} labelColWidth={1} valueColWidth={11}
			  onChange={(accessor, value) => this.onChangeMaterialRequest(accessor, value)} onSubmit={(event) => this.onSubmitMaterialRequest(event)} user={this.props.user} editable={this.state.editable}>
			  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
			    <Col xs={12}>
				  <Button id="sendMaterialRequest" variant="warning" type="submit" className="float-end">Send</Button>
				  <Button id="cancelMaterialRequest" style={{ marginRight: '6px' }} variant="warning" type="button" className="float-end" onClick={(event) => this.onCancelMaterialRequest(event)}>Cancel</Button>
			    </Col>
			  </Form.Group>
		    </ChemEdit>
		  );
		} else {		  
		  return (
			  <Container fluid>
				<Row>
				  <Col>
					<ChemEdit id='materialReceived' parent={this} columns={materialColumns} data={this.state.application} user={this.props.user}
					  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} editable={this.state.editable} />
				  </Col>
				</Row>
			  </Container>
		  );
		}
	}
	
	renderApplicationTitle() {
		var appHeader = 'Application';
		if (this.state.application.APP_ID) appHeader += ' ' + this.state.application.APP_ID;
		var name = this.join(', ', this.state.application.LNAME, this.join(' ', this.state.application.FNAME, this.state.application.MNAME));
		if (name.length > 0) appHeader += ' for ' + name;
		return appHeader;
	}
	
	renderApplicationHeader() {
		var division = '';
		if (this.state.application.app_details && !this.isEmpty(this.state.application.app_details.DIVISION)) {
			division = this.state.application.app_details.DIVISION;
		}
		var status = '';
		if (this.state.appStatus && !this.isEmpty(this.state.application.STATUS_ID)) {
			status = this.state.appStatus[this.state.application.STATUS_ID];
		}
		return(<div style={{ marginBottom: '10px' }}>
		   <span style={{ fontWeight: 'bold' }}>App Year</span> {this.state.application.ACADEMIC_YEAR}
		   &nbsp;&nbsp;&nbsp;
		   <span style={{ fontWeight: 'bold' }}>Visa</span> {this.state.application.VISA}
		   &nbsp;&nbsp;&nbsp;
		   <span style={{ fontWeight: 'bold' }}>Citizenship</span> {this.state.application.CITIZENSHIP}
		   &nbsp;&nbsp;&nbsp;
		   <span style={{ fontWeight: 'bold' }}>Division</span> {division}
		   &nbsp;&nbsp;&nbsp;
		   <span style={{ fontWeight: 'bold' }}>Status</span> {status}		   
		</div>);
	}

	render() {		
		return (<>
			<Container fluid style={{ border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '20px' }}>
				  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>{this.renderApplicationTitle()}&nbsp;&nbsp;
					{this.state.editable ? <UnlockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: false })} /> : 
					  <LockFill style={{ cursor: 'pointer', marginTop: '-4px' }} onClick={() => this.mergeState({ editable: true })} />}				  
				  </div>
				  {this.renderApplicationHeader()}
				  <Tabs activeTab={this.state.activeTab} canClickTabItem={(tab) => this.canClickTabItem(tab)} onClickTabItem={(tab) => this.onClickTabItem(tab)}>
					<div label='Details'>
					  <Container fluid>
						<Row>
						  <Col>
							<ChemEdit parent={this} columns={detailColumns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Contact'>
					  <Container fluid>
						<Row>
						  <Col>
							<h5>Permanent Address</h5>
							<ChemEdit parent={this} columns={contactPermColumns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />							
						  </Col>
						</Row>
						<Row>
						  <Col>
							<h5>Temporary Address</h5>
							<ChemEdit parent={this} columns={contactTempColumns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />							
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Education'>
					  <Container fluid>
						<Row>
						  <Col>
							<h5>GPA</h5>
							{this.state.calculator.show &&
								<ChemEdit parent={this} columns={calculatorColumns} data={this.state.calculator} user={this.props.user} editable={this.state.editable}
									renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeCalculator(accessor, value)} />							
							}								
							<ChemEdit parent={this} columns={gpaColumns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />							
						  </Col>
						</Row>
						<Row>
						  <Col style={{ marginTop: '10px' }}>
						    <h5>Institutions Attended</h5>
							<EditTable parent={this} user={this.props.user} renderKey={this.state.renderKey} newRecord={emptyDegree} data={this.state.application.app_education} editable={this.state.editable}
								tableColumns={degreeTableColumns} editColumns={degreeEditColumns} onEdit={(editorIsOpen) => this.onEdit('app_education', editorIsOpen)}
								onChange={(data, callback) => this.onChange('app_education', data)} tableName='GradOffice_Application_Education' order='INSTIT_NUMBER' />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Eval'>
					  <Container fluid>
						<Row>
						  <Col>
							<ChemEdit parent={this} columns={this.state.evalColumns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Form C'>
					  <Container fluid>
						<Row>
						  <Col>
							<ChemEdit parent={this} columns={formCColumns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Interests'>
					  <Container fluid>
						<Row>
						  <Col>
							<ChemEdit parent={this} columns={interestColumns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Material Received'>					  
					  {this.renderMaterialReceived()}
					</div>
					<div label='Recommendations'>					  
					  <Container fluid>
						<Row>
						  <Col>
							<EditTable parent={this} user={this.props.user} renderKey={this.state.renderKey} newRecord={emptyRecommendation} data={this.state.application.recommd} editable={this.state.editable}
								tableColumns={recommendColumns} editColumns={recommdEditColumns} onEdit={(editorIsOpen) => this.onEdit('recommd', editorIsOpen)}
								onChange={(data, callback) => this.onChange('recommd', data)} tableName='GradOffice_Application_Recommendations' order='RECOMMD_POSITION' />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Tests'>					  
					  <Container fluid>
						<Row>
						  <Col>
							<h5>GRE</h5>
							<ChemEdit parent={this} columns={testColumns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />
						  </Col>
						</Row>
						<Row>
						  <Col>
							<h5>TOEFL</h5>
							<ChemEdit parent={this} columns={toeflColumns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />
						  </Col>
						</Row>
					  </Container>
					</div>
					<div label='Visit'>					  
					  <Container fluid>
						<Row>
						  <Col>
							<h5>Campus Visit</h5>
							<ChemEdit parent={this} columns={visitColumns} data={this.state.application} user={this.props.user} editable={this.state.editable}
								renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)}
								labelColWidth={6} valueColWidth={6} />
						  </Col>
						</Row>
						<Row>
						  <Col style={{ marginTop: '20px' }}>
							<h5>Active Default Appointments</h5>
							<ChemTable renderKey={this.state.renderKey} parent={this} data={this.state.application.default_visit_appt} editable={this.state.editable}
								columns={defaultApptColumns} name='GradOffice_Application_DefaultAppt' offsetHeight='-100px' />
							</Col>
						</Row>
						<Row>
						  <Col style={{ marginTop: '20px' }}>
							<h5>Appointment Schedule</h5>
							<EditTable parent={this} user={this.props.user} renderKey={this.state.renderKey} newRecord={emptySchedule} data={this.state.application.visit_schedule} editable={this.state.editable}
								tableColumns={scheduleTableColumns} editColumns={scheduleEditColumns} onEdit={(editorIsOpen) => this.onEdit('visit_schedule', editorIsOpen)}
								onChange={(data, callback) => this.onChange('visit_schedule', data)} tableName='GradOffice_Application_VisitSchedule' order='LINE_ID' />
						  </Col>
						</Row>
					  </Container>
					</div>
				  </Tabs>
				</Col>
			  </Row>
			  <Row>
				<Col style={{ paddingTop: '20px', paddingBottom: '20px' }}>
				  {this.state.editable && <Button id="save" variant="warning" type="submit" className="float-end" onClick={(event) => this.onSubmit(event)}>Save</Button>}
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const emptyCalculator = {
	accessor: null,
	revert: null,
	A: '',
	AMinus: '',
	BPlus: '',
	B: '',
	BMinus: '',
	CPlus: '',
	C: '',
	CMinus: '',
	DPlus: '',
	D: '',
	DMinus: '',
	F: '',
	show: false
};

const emptyDegree = {
	drag: null,
	id: null,
	APP_ID: null,
	INSTIT_NUMBER: null,
	INSTITUTION: '',
	LOCATION: '',
	DEGREE: '',
	DEGREE_DATE: null,
	DEGREE_TYPE: '',
	MAJOR: '',
	SHOW_SCHOOL: null,
	LAST_MODIF: null,
	MODIF_BY: '',
	GPA_FLAG: null,
	TRANSCRIPT: null,
	PDF_ID: null,
	OFF_TRANSCRIPT: null,
	INSTIT_ID: 'request_id_seq.nextval'
};

const emptyRecommendation = {
	drag: null,
	id: null,
    RECOMMD_NAME: '',
	RECOMMD_POSITION: null,
	RECOMMD_RATING: '',
	RECOMMD_EMAIL_ADDR: '',
	WAIVER: null,
	RECOMMD_ID: 'recommd_id_seq.nextval',
	APP_ID: null,
	DOC_ID: null,
	LINE_NUM: null,
	RECOMMD_TITLE: '', 
	RECOMMD_INSTIT: '',
	RECOMMD_PHONE: '',
	RECOMMD_RATEYEARS: '',     	
	LETTER: 'No'
};

const defaultMaterialRequest = {
	to: '',
	cc: 'chem_webapps@berkeley.edu',
	subject: 'Your Application to UC Berkeley, Chemistry Department',
	body: 'Dear Applicant,\r\n' +
		'\r\n' +
		'Thank you for your application to the Department of Chemistry at the University of California, Berkeley. In order to complete the application process, additional information is required.\r\n' +
		'\r\n' +
		'Please go to https://chemapps.berkeley.edu/xnet/CF_app_check/index.cfm and create an account to submit a research summary. Please summarize the research projects in which you have participated. Include ' +
		'research carried out during regular semesters of study and summers, and indicate the research director and institution associated with each project. Briefly state the goals of each project, and summarize the ' +
		'work you personally accomplished.\r\n' +
		'\r\n' +
		'You may also upload an unofficial transcript in PDF format for each school at this site through the end of January. (Please carefully blackout your Social Security Number if it appears on your transcript.) ' +
		'One copy of an official transcript should also be mailed to the Department of Chemistry.\r\n' +
		'\r\n' +
		'You may also use this account to check the status of your supplementary materials. Please allow 7 to 10 business days for information to be updated.\r\n' +
		'\r\n' +
		'You can find the link to check the status of your supplementary materials at the Department of Chemistry website, http://chem.berkeley.edu, select ' +
		'Graduate Programs/Chemistry Graduate Info/Check Supplemental Materials Status (under Prospective Students).\r\n' +
		'\r\n' +
		'Thank you,\r\n' +
		'The Department of Chemistry'
};

const emptySchedule = {
	drag: null,
	id: null,
	APP_ID: null,
	VISIT_ID: 'visit_id_seq.nextval',
	LINE_ID: null,
	FACULTY: '',
	ROOM_LOC: '',
	VISIT_DATE: null,
	START_TIME: '',
	END_TIME: ''
};

const ethnicityColumns = [
	{ Header: 'Ethnicity', accessor: 'ETHNICITY_NAME', width: 200 },
	{ Header: '', accessor: 'DELETE_FLAG', show: false }
];

const detailColumns = [
	{ Header: 'Last Name', accessor: 'LNAME', row: 0, col: 0, required: true },
	{ Header: 'First Name', accessor: 'FNAME', row: 0, col: 1, required: true },
	{ Header: 'Middle Name', accessor: 'MNAME', row: 1, col: 0 },
	{ Header: 'Preferred Name', accessor: 'PREFERRED_NAME', row: 1, col: 1 },
	{ Header: 'Title', accessor: 'TITLE_ID', type: 'dropdown', row: 2, col: 0,
		options: { value: 'TITLE_NAME', label: 'TITLE_NAME', entity: 'ADMISSIONS.TITLES', order: 'TITLE_NAME' }},
	{ Header: 'Gender', accessor: 'GENDER_TYPE_ID', type: 'select', row: 2, col: 1,
		options: { value: 'GENDER_TYPE_ID', label: 'DESCRIPTION', entity: 'ADMISSIONS.GENDER_TYPE', order: 'DESCRIPTION' }},
	{ Header: 'Pronouns', accessor: 'PRONOUNS', row: 3, col: 1 },
	{ Header: 'Application Semester', accessor: 'SEMESTER', type: 'select', row: 4, col: 0,
		options: [{ value: null, label: 'Select...'}, { value: 'SPR', label: 'Spring' }, { value: 'FAL', label: 'Fall' }]},
	{ Header: 'Application Year', accessor: 'ACADEMIC_YEAR', row: 4, col: 1 },
	{ Header: 'Visa Type', accessor: 'VISA', type: 'dropdown', row: 5, col: 0,
		options: { value: 'VISA_NAME', label: 'VISA_NAME', entity: 'ADMISSIONS.VISA', order: 'VISA_NAME' }},
	{ Header: 'Citizenship', accessor: 'CITIZENSHIP', row: 5, col: 1 },
	{ Header: 'Birthdate', accessor: 'BIRTHDATE', type: 'date', row: 6, col: 0 },
	{ Header: 'Birth City', accessor: 'BIRTH_CITY', row: 6, col: 1 },
	{ Header: 'Birth State', accessor: 'BIRTH_STATE', row: 7, col: 0 },
	{ Header: 'Birth Country', accessor: 'BIRTH_COUNTRY', row: 7, col: 1 },
	{ Header: 'CA Residency', accessor: 'CA_RESIDENT', type: 'select', row: 8, col: 0,
		options: [{ value: null, label: 'Select...' }, { value: 'RESIDENT', label: 'Resident' }, 
			{ value: 'EXPECTED', label: 'Expected' }, { value: 'NON-RESIDENT', label: 'Non-Resident' }]},
	{ Header: 'Residency Date Expected', accessor: 'CA_RES_START', type: 'date', row: 8, col: 1 },
	{ Header: 'Contact Address', accessor: 'ALT_CTACT_ADD', row: 9, col: 0 },
	{ Header: 'Contact Name', accessor: 'ALT_CTACT_NAME', row: 9, col: 1 },
	{ Header: 'Fellowship 1', accessor: 'app_details.FELLOWSHIP1', row: 10, col: 0 },
	{ Header: 'Fellowship 2', accessor: 'app_details.FELLOWSHIP2', row: 10, col: 1 },
	{ Header: 'Ethnicity', accessor: 'ethnicity', type: 'table', name: 'GradOffice_Application_Ethnicity', columns: ethnicityColumns, row: 11, col: 0,
		options: { value: 'ETHNICITY_ID', label: 'ETHNICITY_NAME', entity: 'ADMISSIONS.APP_ETHNICITY_SELECT_VW', order: 'ETHNICITY_NAME' }},
	{ Header: 'McNair Program', accessor: 'financial_extract.MCNAIR_PROG_FLAG', type: 'checkbox', row: 11, col: 1 },
	{ Header: 'URM', accessor: 'URM', type: 'checkbox', row: 12, col: 0 },
	{ Header: 'Hispanic', accessor: 'HISPANIC_FLAG', type: 'checkbox', row: 12, col: 1 },
	{ Header: 'Previous Submission', accessor: 'PREV_UCB_GRAD_APPL', row: 13, col: 0, editable: false },
	{ Header: 'Date Submitted', accessor: 'DOC_RECEIVE_DATE', type: 'date', row: 13, col: 1 },
	{ Header: 'Internal Comments', accessor: 'COMMENTS', type: 'textarea', row: 14, col: 0 },
	{ Header: 'Applicant Correspondence', accessor: 'app_comment.APP_COMMENT', type: 'textarea', row: 14, col: 1 },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 15, col: 0, editable: false }
];

const contactPermColumns = [
	{ Header: 'Street Line 1', accessor: 'address_phone.STREET1', row: 0, col: 0 },
	{ Header: 'Street Line 2', accessor: 'address_phone.STREET2', row: 0, col: 1 },
	{ Header: 'City', accessor: 'address_phone.CITY', row: 0, col: 2 },
	{ Header: 'State', accessor: 'address_phone.STATE', row: 1, col: 0 },
	{ Header: 'Zip', accessor: 'address_phone.ZIP', row: 1, col: 1 },
	{ Header: 'Country', accessor: 'address_phone.COUNTRY', row: 1, col: 2 },
	{ Header: 'Phone Country Code', accessor: 'address_phone.TEL_CNTRY_CODE', row: 2, col: 0 },
	{ Header: 'Phone Area Code', accessor: 'address_phone.TEL_AREA_CODE', row: 2, col: 1 },
	{ Header: 'Phone Number', accessor: 'address_phone.TEL_NUM', row: 2, col: 2 },
	{ Header: 'Cell Country Code', accessor: 'address_phone.CELL_TEL_CNTRY_CD', row: 3, col: 0 },
	{ Header: 'Cell Area Code', accessor: 'address_phone.CELL_TEL_AREA_CD', row: 3, col: 1 },
	{ Header: 'Cell Number', accessor: 'address_phone.CELL_TEL_NUM', row: 3, col: 2 },
	{ Header: 'Fax Country Code', accessor: 'address_phone.FAX_CNTRY_CODE', row: 4, col: 0 },
	{ Header: 'Fax Area Code', accessor: 'address_phone.FAX_AREA_CODE', row: 4, col: 1 },
	{ Header: 'Fax Number', accessor: 'address_phone.FAX_NUM', row: 4, col: 2 },
	{ Header: 'Email Address', accessor: 'address_phone.EMAIL', row: 5, col: 1 }
];

const contactTempColumns = [
	{ Header: 'Street Line 1', accessor: 'address_phone.TEMP_STREET1', row: 0, col: 0 },
	{ Header: 'Street Line 2', accessor: 'address_phone.TEMP_STREET2', row: 0, col: 1 },
	{ Header: 'City', accessor: 'address_phone.TEMP_CITY', row: 0, col: 2 },
	{ Header: 'State', accessor: 'address_phone.TEMP_STATE', row: 1, col: 0 },
	{ Header: 'Zip', accessor: 'address_phone.TEMP_ZIP', row: 1, col: 1 },
	{ Header: 'Country', accessor: 'address_phone.TEMP_COUNTRY', row: 1, col: 2 },
	{ Header: 'Phone Country Code', accessor: 'address_phone.TEMP_PH_CODE', row: 2, col: 0 },
	{ Header: 'Phone Area Code', accessor: 'address_phone.TEMP_AREA_CODE', row: 2, col: 1 },
	{ Header: 'Phone Number', accessor: 'address_phone.TEMP_PHONE', row: 2, col: 2 },
	{ Header: 'Fax Country Code', accessor: 'address_phone.TEMP_FAX_CNTRY_CODE', row: 3, col: 0 },
	{ Header: 'Fax Area Code', accessor: 'address_phone.TEMP_FAX_AREA_CODE', row: 3, col: 1 },
	{ Header: 'Fax Number', accessor: 'address_phone.TEMP_FAX_NUM', row: 3, col: 2 },
	{ Header: 'Email Address', accessor: 'address_phone.TEMP_EMAIL_ADD', row: 4, col: 0 },
	{ Header: 'Address Comment', accessor: 'address_phone.ADDRESS_COMMENT', type: 'textarea', row: 4, col: 1 },
	{ Header: 'Good Until', accessor: 'address_phone.ADDRESS_END', type: 'date', row: 4, col: 2 }
];

const calculatorColumns = [
	{ Header: 'A-', accessor: 'AMinus', row: 0, col: 0 },
	{ Header: 'A', accessor: 'A', row: 0, col: 1 },
	{ Header: 'B-', accessor: 'BMinus', row: 1, col: 0 },
	{ Header: 'B', accessor: 'B', row: 1, col: 1 },
	{ Header: 'B+', accessor: 'BPlus', row: 1, col: 2 },
	{ Header: 'C-', accessor: 'CMinus', row: 2, col: 0 },
	{ Header: 'C', accessor: 'C', row: 2, col: 1 },
	{ Header: 'C+', accessor: 'CPlus', row: 2, col: 2 },
	{ Header: 'D-', accessor: 'DMinus', row: 3, col: 0 },
	{ Header: 'D', accessor: 'D', row: 3, col: 1 },
	{ Header: 'D+', accessor: 'DPlus', row: 3, col: 2 },
	{ Header: '', accessor: '', row: 4, col: 0, Cell: props => <></> },
	{ Header: 'F', accessor: 'F', row: 4, col: 1 },
	{ Header: '', accessor: '', row: 4, col: 2,
		Cell: props => <>
			<Button style={{ marginTop: '-4px', marginRight: '8px' }} id="cancelCalculator" variant="warning" type="button" onClick={(event) => props.parent.cancelCalculator(event)}>Cancel</Button>
			<Button style={{ marginTop: '-4px' }} id="closeCalculator" variant="warning" type="button" onClick={(event) => props.parent.closeCalculator(event)}>Close</Button>
		</> }
];

const gpaColumns = [
	{ Header: 'UG GPA', accessor: 'app_details.UNGRAD_GPA', row: 0, col: 0,
		Cell: props => props.parent.renderWithCalculator('app_details.UNGRAD_GPA', props.value) },
	{ Header: 'UG 2Y GPA', accessor: 'app_details.UNGRAD_AFTER_2YR_GPA', row: 0, col: 1,
		Cell: props => props.parent.renderWithCalculator('app_details.UNGRAD_AFTER_2YR_GPA', props.value) },
	{ Header: 'Confirmed', accessor: 'app_details.UNGRAD_GPA_CONF', row: 0, col: 2, type: 'checkbox' },
	{ Header: 'Grad GPA', accessor: 'app_details.GRAD_GPA', row: 1, col: 0,
		Cell: props => props.parent.renderWithCalculator('app_details.GRAD_GPA', props.value) },
	{ Header: '', accessor: '', row: 1, col: 1, Cell: props => <></> },
	{ Header: 'Confirmed', accessor: 'app_details.GRAD_GPA_CONF', row: 1, col: 2, type: 'checkbox' },	
];

const degreeTableColumns = [
	{ Header: 'Order', accessor: 'INSTIT_NUMBER', width: 60 },
	{ Header: 'School', accessor: 'INSTITUTION', width: 400 },
	{ Header: 'Location', accessor: 'LOCATION', width: 150 },
	{ Header: 'Major', accessor: 'MAJOR', width: 100 },
	{ Header: 'Degree', accessor: 'DEGREE', width: 100 },
	{ Header: 'Degree Date', accessor: 'DEGREE_DATE', width: 100,
		Cell: props => props.value ? props.parent.getDate(props.value).toLocaleString('en-US', { month: '2-digit', year: 'numeric'}) : '' },
	{ Header: 'UG/G', accessor: 'DEGREE_TYPE', width: 50 },
	{ Header: 'Transcript', accessor: 'TRANSCRIPT', width: 80 }
];

const degreeEditColumns = [
	{ Header: 'School', accessor: 'INSTITUTION', row: 0, col: 0 },
	{ Header: 'Location', accessor: 'LOCATION', row: 0, col: 1 },
	{ Header: 'Major', accessor: 'MAJOR', row: 0, col: 2 },
	{ Header: 'Degree', accessor: 'DEGREE', row: 1, col: 0 },
	{ Header: 'Degree Date', accessor: 'DEGREE_DATE', type: 'date', row: 1, col: 1 },
	{ Header: 'UG/G', accessor: 'DEGREE_TYPE', row: 1, col: 2 },
	{ Header: 'Transcript', accessor: 'TRANSCRIPT', type: 'checkbox', row: 2, col: 0 },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 2, col: 1, editable: false },
];

const evalColumns = [
	{ Header: 'Vice Chair', accessor: 'app_evaluation.VICE_CHAIR', type: 'select', row: 0, col: 0,
		options: { value: 'VICE_CHAIR_NAME', label: 'VICE_CHAIR_NAME', entity: 'ADMISSIONS.VICE_CHAIRS', order: 'VICE_CHAIR_NAME' }},
	{ Header: 'Contact Professor', accessor: 'app_evaluation.CONTACT_PROF', type: 'select', row: 0, col: 1,
		options: { value: 'RES_DIRECTOR_NAME', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.RES_DIRECTOR_SELECT_VW', order: 'RES_DIRECTOR_NAME' }},
	{ Header: 'Evaluation Comments', accessor: 'app_evaluation.COMMENTS', type: 'textarea', row: 0, col: 2 },
	{ Header: 'Ranking', accessor: 'app_evaluation.RANKING', row: 1, col: 0 },
	{ Header: 'Eval. Date', accessor: 'app_evaluation.EVAL_DATE', type: 'date', row: 1, col: 1 },
	{ Header: 'Activity Status', accessor: 'ACTIVITY_STATUS', type: 'select', row: 1, col: 2,
		options: { value: 'ACTIVITY_STATUS_ID', label: 'ACTIVITY_STATUS_NAME', entity: 'ADMISSIONS.ACTIVITY_STATUS', order: 'ACTIVITY_STATUS_NAME' }},
	{ Header: 'Admission', accessor: 'app_evaluation.ADMISSION_CODE', type: 'select', row: 2, col: 0,
		options: [{ value: null, label: 'Select...' }, { value: 1, label: 'Offered' }, { value: 0, label: 'Denied' }]},
	{ Header: 'Offer/Deny Date', accessor: 'app_evaluation.ADMISSION_DATE', type: 'date', row: 2, col: 1 },
	{ Header: '', accessor: 'DEFERRED_REC', Cell: props => <></>, row: 3, col: 0 },
	{ Header: 'Admit Letter Sent', accessor: 'app_evaluation.LETTER_SENT', type: 'date', row: 3, col: 1 },
	{ Header: 'Instead Will Attend', accessor: 'app_details.WILL_ATTEND', row: 3, col: 2 },
	{ Header: 'Offer Decision', accessor: 'app_evaluation.OFFER_ID', type: 'select', row: 4, col: 0,
		options: { value: 'OFFER_ID', label: 'OFFER_NAME', entity: 'ADMISSIONS.OFFERS', order: 'OFFER_NAME' }},
	{ Header: 'Decision Date', accessor: 'app_evaluation.OFFER_DATE', type: 'date', row: 4, col: 1 },
	{ Header: 'Application Status', accessor: 'STATUS_ID', type: 'select', row: 4, col: 2,
		options: { value: 'STATUS_ID', label: 'STATUS_NAME', entity: 'ADMISSIONS.APPLICATION_STATUS', order: 'STATUS_ID' }}
];

const formCColumns = [
	{ Header: 'McNair Program', accessor: 'financial_extract.MCNAIR_PROG_FLAG', type: 'checkbox', row: 0, col: 0 },
	{ Header: 'Spouse Name', accessor: 'financial_extract.SPOUSE_NAME', row: 0, col: 1 },
	{ Header: 'Primary Language', accessor: 'financial_extract.PRIM_HOME_LANG_NAME', row: 0, col: 2 },
	{ Header: 'Total Siblings', accessor: 'financial_extract.SIBLING_TOT', row: 1, col: 0 },
	{ Header: 'Siblings Attended College', accessor: 'financial_extract.SIBLING_ATTEND_COLL_TOT', row: 1, col: 1 },
	{ Header: 'Siblings Currently in College', accessor: 'financial_extract.SIBLING_CURR_COLL_TOT', row: 1, col: 2},
	{ Header: 'Parent Occupation', accessor: 'financial_extract.PARENT_OCCUP_TYPE', row: 2, col: 0 },
	{ Header: 'Parent Income $', accessor: 'financial_extract.PARENT_INCOME_AMT', row: 2, col: 1 },
	{ Header: 'Parent Education Level', accessor: 'financial_extract.PARENT_EDUC_LEVEL', row: 2, col: 2 },
	{ Header: 'Other Parent Occupation', accessor: 'financial_extract.OTHER_PARENT_OCCUP_TYPE', row: 3, col: 0 },
	{ Header: 'Other Parent Income $', accessor: 'financial_extract.OTHER_PARENT_INCOME_AMT', row: 3, col: 1 },
	{ Header: 'Other Parent Education Level', accessor: 'financial_extract.OTHER_PARENT_EDUC_LEVEL', row: 3, col: 2 }
];

const interestColumns = [
	{ Header: 'Faculty Interest 1', accessor: 'app_details.FACULT_NAME1', row: 0, col: 0 },
	{ Header: 'Research Interest 1', accessor: 'app_interest.RES_INTEREST1', type: 'select', row: 0, col: 1,
		options: { value: 'INTEREST_FIELD', label: 'INTEREST_FIELD', entity: 'ADMISSIONS.RESEARCH_INTEREST', order: 'INTEREST_ID',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Schools Applied To 1', accessor: 'app_details.SCHOOL_NAME1', row: 0, col: 2 },
	{ Header: 'Faculty Interest 2', accessor: 'app_details.FACULT_NAME2', row: 1, col: 0 },
	{ Header: 'Research Interest 2', accessor: 'app_interest.RES_INTEREST2', type: 'select', row: 1, col: 1,
		options: { value: 'INTEREST_FIELD', label: 'INTEREST_FIELD', entity: 'ADMISSIONS.RESEARCH_INTEREST', order: 'INTEREST_ID',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Schools Applied To 2', accessor: 'app_details.SCHOOL_NAME2', row: 1, col: 2 },
	{ Header: 'Faculty Interest 3', accessor: 'app_details.FACULT_NAME3', row: 2, col: 0 },
	{ Header: 'Research Interest 3', accessor: 'app_interest.RES_INTEREST3', type: 'select', row: 2, col: 1,
		options: { value: 'INTEREST_FIELD', label: 'INTEREST_FIELD', entity: 'ADMISSIONS.RESEARCH_INTEREST', order: 'INTEREST_ID',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Schools Applied To 3', accessor: 'app_details.SCHOOL_NAME3', row: 2, col: 2 },
	{ Header: 'Faculty Interest 4', accessor: 'app_details.FACULT_NAME4', row: 3, col: 0 },
	{ Header: 'Research Interest 4', accessor: 'app_interest.RES_INTEREST4', type: 'select', row: 3, col: 1,
		options: { value: 'INTEREST_FIELD', label: 'INTEREST_FIELD', entity: 'ADMISSIONS.RESEARCH_INTEREST', order: 'INTEREST_ID',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Schools Applied To 4', accessor: 'app_details.SCHOOL_NAME4', row: 3, col: 2 },
	{ Header: 'Faculty Interest 5', accessor: 'app_details.FACULT_NAME5', row: 4, col: 0 },
	{ Header: 'Research Interest 5', accessor: 'app_interest.RES_INTEREST5', type: 'select', row: 4, col: 1,
		options: { value: 'INTEREST_FIELD', label: 'INTEREST_FIELD', entity: 'ADMISSIONS.RESEARCH_INTEREST', order: 'INTEREST_ID',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Schools Applied To 5', accessor: 'app_details.SCHOOL_NAME5', row: 4, col: 2 },
	{ Header: 'Faculty Interest 6', accessor: 'app_details.FACULT_NAME6', row: 5, col: 0 },
	{ Header: 'Division', accessor: 'app_details.DIVISION', type: 'dropdown', row: 5, col: 1,
		options: { value: 'DIVISION', label: 'DIVISION', entity: 'ADMISSIONS.DIVISIONS' }},
	{ Header: 'Comments', accessor: 'app_details.COMMENTS', type: 'textbox', row: 5, col: 2 },
	{ Header: 'Faculty Interest 7', accessor: 'app_details.FACULT_NAME7', row: 6, col: 0 },
	{ Header: 'Research Summary', accessor: 'material_list.RES_SUMMARY', type: 'select', row: 6, col: 1,
		options: [{ value: 0, label: 'Not Received' }, { value: 2, label: 'Received' }, { value: 1, label: 'Processed' }]},
	{ Header: 'Fellowship 1', accessor: 'app_details.FELLOWSHIP1', row: 6, col: 2},
	{ Header: 'Faculty Interest 8', accessor: 'app_details.FACULT_NAME8', row: 7, col: 0 },
	{ Header: '', accessor: '', Cell: props => <></>, row: 7, col: 1 },
	{ Header: 'Fellowship 2', accessor: 'app_details.FELLOWSHIP2', row: 7, col: 2 },
	{ Header: 'Faculty Interest 9', accessor: 'app_details.FACULT_NAME9', row: 8, col: 0 }
];

const gpaDisplayColumns = [
	{ Header: '', accessor: 'GPA_TYPE', width: 100 },
	{ Header: 'Confirmed', accessor: 'CONFIRMED', width: 100 }
];
		
const testDisplayColumns = [
	{ Header: '', accessor: 'TEST_TYPE', width: 120 },
	{ Header: 'Received', accessor: 'RECEIVED', width: 80 }
];

const greDisplayColumns = [
	{ Header: '#', accessor: 'GRE_NUMBER', width: 25 },
	{ Header: 'General Official', accessor: 'OFFICIAL_GRE_GENERAL', width: 100 },
	{ Header: 'Subject Official', accessor: 'OFFICIAL_GRE_SUBJECT', width: 100 }
];

const letterDisplayColumns = [
	{ Header: 'Letter', accessor: 'RECOMMD_POSITION', width: 70 },
	{ Header: 'Received', accessor: 'LETTER', width: 80 }
];

const transcriptDisplayColumns = [
	{ Header: 'School', accessor: 'INSTIT_NUMBER', width: 60 },
	{ Header: 'Received', accessor: 'TRANSCRIPT', width: 80,
		Cell: props => <>{props.value ? 'Y' : ''}</> }
];

const materialColumns = [
	{ Header: 'Date Sup Info Email Sent', accessor: 'material_list.SUP_INFO_REQ', type: 'date', row: 0, col: 0 },
	{ Header: 'Password', accessor: 'material_list.PASSWD', row: 0, col: 1, editable: false },
	{ Header: 'Resend Request', accessor: '', row: 0, col: 2,
		Cell: props => <Button style={{ marginTop: '-8px' }} onClick={(event) => props.parent.onClickMaterialRequest(event)}>Email</Button> },
	{ Header: 'Sup Info Received', accessor: 'material_list.SUP_INFO_RECD', type: 'checkbox', row: 1, col: 0 },
	{ Header: 'Date Received', accessor: 'material_list.SUP_INFO_SENT', type: 'date', row: 1, col: 1 },
	{ Header: '', accessor: '', Cell: props => <></>, row: 1, col: 2 },
	{ Header: 'GPA', accessor: '', Cell: props => props.parent.renderGPA(), row: 2, col: 0 },
	{ Header: 'Tests', accessor: '', Cell: props => props.parent.renderTests(), row: 2, col: 1 },
	{ Header: 'GRE', accessor: 'gre_test', type: 'table', row: 2, col: 2, editable: false,
		name: 'GradOffice_Application_Materials_GRE', columns: greDisplayColumns },
	{ Header: 'Recommendations', accessor: 'recommd', type: 'table', row: 3, col: 0, editable: false,
		name: 'GradOffice_Application_Materials_Recommendations', columns: letterDisplayColumns },
	{ Header: 'Transcripts', accessor: 'app_education', type: 'table', row: 3, col: 1, editable: false,
		name: 'GradOffice_Application_Materials_Transcripts', columns: transcriptDisplayColumns }
];

const emailColumns = [
	{ Header: 'To', accessor: 'to', type: 'textarea', required: true, rows: 1 },
	{ Header: 'CC', accessor: 'cc' },
	{ Header: 'Subject', accessor: 'subject' },
	{ Header: 'Body', accessor: 'body', type: 'textarea', rows: 10 }
];

const recommendColumns = [
	{ Header: 'Name', accessor: 'RECOMMD_NAME', width: 150 },
	{ Header: 'Email', accessor: 'RECOMMD_EMAIL_ADDR', width: 250 },
	{ Header: 'Title', accessor: 'RECOMMD_TITLE', width: 150 },
	{ Header: 'Affiliation', accessor: 'RECOMMD_INSTIT', width: 150 },
	{ Header: 'Phone', accessor: 'RECOMMD_PHONE', width: 100 },
	{ Header: 'Rating', accessor: 'RECOMMD_RATING', width: 75 },
	{ Header: 'Add. Rating', accessor: 'RECOMMD_RATEYEARS', width: 100 },
	{ Header: 'Waiver', accessor: 'WAIVER', width: 60 },
	{ Header: 'Letter', accessor: 'LETTER', width: 50 }
];

const recommdEditColumns = [
	{ Header: 'Name', accessor: 'RECOMMD_NAME', row: 0, col: 0 },
	{ Header: 'Email', accessor: 'RECOMMD_EMAIL_ADDR', row: 0, col: 1 },
	{ Header: 'Title', accessor: 'RECOMMD_TITLE', row: 0, col: 2 },
	{ Header: 'Affiliation', accessor: 'RECOMMD_INSTIT', row: 1, col: 0 },
	{ Header: 'Phone', accessor: 'RECOMMD_PHONE', row: 1, col: 1 },
	{ Header: 'Rating', accessor: 'RECOMMD_RATING', row: 1, col: 2 },
	{ Header: 'Add. Rating', accessor: 'RECOMMD_RATEYEARS', row: 2, col: 0 },
	{ Header: 'Waiver', accessor: 'WAIVER', type: 'checkbox', row: 2, col: 1 },
	{ Header: 'Modified', accessor: 'MODIFIED', row: 2, col: 2, editable: false }
];

const testColumns = [
	{ Header: 'GRE 1 Reg. Number', accessor: 'gre_test[0].GRE_REG_NUM', row: 0, col: 0 },
	{ Header: 'GRE 2 Reg. Number', accessor: 'gre_test[1].GRE_REG_NUM', row: 0, col: 1 },
	{ Header: 'GRE 3 Reg. Number', accessor: 'gre_test[2].GRE_REG_NUM', row: 0, col: 2 },
	{ Header: 'Exam Date', accessor: 'gre_test[0].GRE_EXAM_DATE', type: 'date', row: 1, col: 0 },
	{ Header: 'Exam Date', accessor: 'gre_test[1].GRE_EXAM_DATE', type: 'date', row: 1, col: 1 },
	{ Header: 'Exam Date', accessor: 'gre_test[2].GRE_EXAM_DATE', type: 'date', row: 1, col: 2 },
	{ Header: 'Verbal', accessors: ['gre_test[0].GRE_VERB_SCORE','gre_test[0].GRE_VERB_PCT_NUM'], subscripts: ['score','%ile'], row: 2, col: 0 },
	{ Header: 'Verbal', accessors: ['gre_test[1].GRE_VERB_SCORE','gre_test[1].GRE_VERB_PCT_NUM'], subscripts: ['score','%ile'], row: 2, col: 1 },
	{ Header: 'Verbal', accessors: ['gre_test[2].GRE_VERB_SCORE','gre_test[2].GRE_VERB_PCT_NUM'], subscripts: ['score','%ile'], row: 2, col: 2 },
	{ Header: 'Quantitative', accessors: ['gre_test[0].GRE_QUANT_SCORE','gre_test[0].GRE_QUANT_PCT_NUM'], subscripts: ['score','%ile'], row: 3, col: 0 },
	{ Header: 'Quantitative', accessors: ['gre_test[1].GRE_QUANT_SCORE','gre_test[1].GRE_QUANT_PCT_NUM'], subscripts: ['score','%ile'], row: 3, col: 1 },
	{ Header: 'Quantitative', accessors: ['gre_test[2].GRE_QUANT_SCORE','gre_test[2].GRE_QUANT_PCT_NUM'], subscripts: ['score','%ile'], row: 3, col: 2 },
	{ Header: 'Analytical', accessors: ['gre_test[0].GRE_ANALYT_SCORE','gre_test[0].GRE_ANALYT_PCT_NUM'], subscripts: ['score','%ile'], row: 4, col: 0 },
	{ Header: 'Analytical', accessors: ['gre_test[1].GRE_ANALYT_SCORE','gre_test[1].GRE_ANALYT_PCT_NUM'], subscripts: ['score','%ile'], row: 4, col: 1 },
	{ Header: 'Analytical', accessors: ['gre_test[2].GRE_ANALYT_SCORE','gre_test[2].GRE_ANALYT_PCT_NUM'], subscripts: ['score','%ile'], row: 4, col: 2 },
	{ Header: 'Analytical Writing', accessors: ['gre_test[0].GRE_ANALYT_WRITE_SCORE','gre_test[0].GRE_ANALYT_WRITE_PCT_NUM'], subscripts: ['score','%ile'], row: 5, col: 0 },
	{ Header: 'Analytical Writing', accessors: ['gre_test[1].GRE_ANALYT_WRITE_SCORE','gre_test[1].GRE_ANALYT_WRITE_PCT_NUM'], subscripts: ['score','%ile'], row: 5, col: 1 },
	{ Header: 'Analytical Writing', accessors: ['gre_test[2].GRE_ANALYT_WRITE_SCORE','gre_test[2].GRE_ANALYT_WRITE_PCT_NUM'], subscripts: ['score','%ile'], row: 5, col: 2 },
	{ Header: 'GRE Subject', accessor: 'gre_test[0].GRE_SUBJ_TEST_NAME', row: 6, col: 0 },
	{ Header: 'GRE Subject', accessor: 'gre_test[1].GRE_SUBJ_TEST_NAME', row: 6, col: 1 },
	{ Header: 'GRE Subject', accessor: 'gre_test[2].GRE_SUBJ_TEST_NAME', row: 6, col: 2 },
	{ Header: 'Subject', accessors: ['gre_test[0].GRE_SUBJ_TEST_SCORE','gre_test[0].GRE_SUBJ_TEST_PCT_NUM'], subscripts: ['score','%ile'], row: 7, col: 0 },
	{ Header: 'Subject', accessors: ['gre_test[1].GRE_SUBJ_TEST_SCORE','gre_test[1].GRE_SUBJ_TEST_PCT_NUM'], subscripts: ['score','%ile'], row: 7, col: 1 },
	{ Header: 'Subject', accessors: ['gre_test[2].GRE_SUBJ_TEST_SCORE','gre_test[2].GRE_SUBJ_TEST_PCT_NUM'], subscripts: ['score','%ile'], row: 7, col: 2 },
	{ Header: 'Official', accessors: ['gre_test[0].OFFICIAL_GRE_SUBJECT','gre_test[0].OFFICIAL_GRE_GENERAL'], subscripts: ['subject','general'], type: 'checkbox', row: 8, col: 0 },
	{ Header: 'Official', accessors: ['gre_test[1].OFFICIAL_GRE_SUBJECT','gre_test[1].OFFICIAL_GRE_GENERAL'], subscripts: ['subject','general'], type: 'checkbox', row: 8, col: 1 },
	{ Header: 'Official', accessors: ['gre_test[2].OFFICIAL_GRE_SUBJECT','gre_test[2].OFFICIAL_GRE_GENERAL'], subscripts: ['subject','general'], type: 'checkbox', row: 8, col: 2 },
];

const toeflColumns = [
	{ Header: 'TOEFL 1 Reg. Number', accessor: 'app_details.TOEFL_REG_NUM1', row: 0, col: 0 },
	{ Header: 'Date', accessor: 'app_details.TOEFL_EXAM_DATE1', type: 'date', row: 0, col: 1 },
	{ Header: 'TOEFL 2 Reg. Number', accessor: 'app_details.TOEFL_REG_NUM2', row: 0, col: 2 },
	{ Header: 'Date', accessor: 'app_details.TOEFL_EXAM_DATE2', type: 'date', row: 0, col: 3 },
	{ Header: 'Type', accessor: 'app_details.TOEFL_EXAM_TYPE1', row: 1, col: 0 },
	{ Header: 'Speaking iBT Score', accessor: 'app_details.TOEFL_SEC4_SCORE1', row: 1, col: 1 },
	{ Header: 'Type', accessor: 'app_details.TOEFL_EXAM_TYPE2', row: 1, col: 2 },
	{ Header: 'Speaking iBT Score', accessor: 'app_details.TOEFL_SEC4_SCORE2', row: 1, col: 3 },
	{ Header: 'Total Score', accessor: 'app_details.TOEFL_SCORE_TOT1', row: 2, col: 0 },
	{ Header: 'Official', accessor: 'app_details.TOEFL1_OFFICIAL', type: 'checkbox', row: 2, col: 1 }, 
	{ Header: 'Total Score', accessor: 'app_details.TOEFL_SCORE_TOT2', row: 2, col: 2 },
	{ Header: 'Official', accessor: 'app_details.TOEFL2_OFFICIAL', type: 'checkbox', row: 2, col: 3 }, 
];

const visitColumns = [
	{ Header: 'Visit Date', accessor: 'app_visit.VISIT_DATE', type: 'date', row: 0, col: 0 },
	{ Header: 'Arrival Date', accessor: 'app_visit.ARRIVAL', type: 'date', row: 0, col: 1 },
	{ Header: 'Departure Date', accessor: 'app_visit.DEPARTURE', type: 'date', row: 0, col: 2 },
	{ Header: 'Other Visit', accessor: 'app_visit.STANFORD_VISIT', type: 'checkbox', label: 'Stanford', row: 0, col: 3 },
	{ Header: 'Hotel', accessor: 'app_visit.HOTEL_ID', type: 'select', row: 1, col: 0,
		options: { value: 'HOTEL_ID', label: 'HOTEL_NAME', entity: 'ADMISSIONS.HOTEL', order: 'HOTEL_NAME',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Hotel Confirmation', accessor: 'app_visit.HOTEL_CONFIRM', type: 'checkbox', label: 'Sent', row: 1, col: 1 },
	{ Header: 'Number of Guests', accessor: 'app_visit.GUEST_NUMBER', row: 1, col: 2 },
	{ Header: 'Room Reservation', accessor: 'app_visit.ROOM_CONFIRM', type: 'checkbox', label: 'Confirmed', row: 1, col: 3 },
	{ Header: 'Reimbursement Date', accessor: 'app_visit.REIMB_DATE', type: 'date', row: 2, col: 0 },
	{ Header: 'Reimbursement Amount', accessor: 'app_visit.REIMB_AMOUNT', row: 2, col: 1 },
	{ Header: 'Student Host', accessor: 'app_visit.HOST_ID', type: 'select', row: 2, col: 2,
		options: { value: 'HOST_ID', label: 'HOST_NAME', entity: 'ADMISSIONS.STUDENT_HOST_SELECT_VW', order: 'HOST_NAME' }},
	{ Header: 'or Host Name', accessor: 'app_visit.STUDENT_HOST', row: 2, col: 3 }	
];

const defaultApptColumns = [
	{ Header: '', accessor: 'id', Cell: props => props.parent.state.editable !== false &&
		<PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.onClickAddDefaultAppt(props.value)}></PlusSquare>, width: 25 },
	{ Header: 'Appointment Name', accessor: 'DEFAULT_NAME', width: 600 },
	{ Header: 'Location', accessor: 'DEFAULT_LOCATION', width: 300 },
	{ Header: 'Start Time', accessor: 'DEFAULT_BEG', width: 100 },
	{ Header: 'End Time', accessor: 'DEFAULT_END', width: 75 }
];

const scheduleEditColumns = [
	{ Header: 'Faculty/Description', accessor: 'FACULTY', row: 0, col: 0 },
	{ Header: 'Location', accessor: 'ROOM_LOC', row: 0, col: 1 },
	{ Header: 'Date', accessor: 'VISIT_DATE', type: 'date', row: 0, col: 2 },
	{ Header: 'Start Time', accessor: 'START_TIME', row: 1, col: 0 },
	{ Header: 'End Time', accessor: 'END_TIME', row: 1, col: 1 }
];

const scheduleTableColumns = [
	{ Header: 'Order', accessor: 'LINE_ID', width: 50 }, 
	{ Header: 'Faculty/Description', accessor: 'FACULTY', width: 600 },
	{ Header: 'Location', accessor: 'ROOM_LOC', width: 300 },
	{ Header: 'Date', accessor: 'VISIT_DATE', width: 150,
		Cell: props => <>{props.parent.dateToString(props.parent.getDate(props.value))}</> },
	{ Header: 'Start Time', accessor: 'START_TIME', width: 100 },
	{ Header: 'End Time', accessor: 'END_TIME', width: 75 }
];

export default withApplicationDefaultRoute(Application);
