import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { PencilSquare } from 'react-bootstrap-icons';

class Teaching extends ChemComponent {
	constructor(props) {
		super(props);

		var search = {
			YEAR_STARTED: [],
			GSI_NAME: '',
			APPT_COUNT: []
		};
		
		var initTeachingList = this.copyTable(teachingList);
		if (typeof(Storage) !== 'undefined') {
			var storedSearch = localStorage.getItem('GradOffice_Teaching_search');
			if (storedSearch) {
				storedSearch = JSON.parse(storedSearch);
				this.mergeObject(search, storedSearch);
				search = storedSearch;
				initTeachingList.query.search = this.constructSearch(search);
			}
		}

		this.state = {
			teachingList: initTeachingList,
			search: search,
			searchCount: null,
			renderKey: 0
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newTeachingList = this.copyTable(teachingList);
		newTeachingList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			teachingList: newTeachingList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (typeof(Storage) !== 'undefined') {
			localStorage.setItem('GradOffice_Teaching_search', JSON.stringify(this.state.search));
		}
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	constructSearch(data) {
		// construct search terms
		var i;
		var search = {
			Operator: 'and',
			Children: []
		};
		
		if (Array.isArray(data.YEAR_STARTED) && data.YEAR_STARTED.length > 0) {
			var yearSearch = { Operator: 'or', Children: [] };
			for (i = 0; i < data.YEAR_STARTED.length; i++) {
				yearSearch.Children.push({ Attribute: 'YEAR_STARTED', Operator: 'like', StringValue: '%' + data.YEAR_STARTED[i] + '%' });
			}
			search.Children.push(yearSearch);
		}
		if (!this.isEmpty(data.GSI_NAME)) search.Children.push({ Attribute: 'GSI_NAME', Operator: 'like', StringValue: '%' + data.GSI_NAME + '%' });
		if (Array.isArray(data.APPT_COUNT) && data.APPT_COUNT.length > 0) search.Children.push({ Attribute: 'APPT_COUNT', operator: 'in', LongList: data.APPT_COUNT });

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}

	editApplication(applicationId) {
		this.props.setApplicationEditable(true);
		this.props.navigate('/GradOffice/Applications/' + applicationId);		
	}

	viewApplication(applicationId) {
		this.props.setApplicationEditable(false);
		this.props.navigate('/GradOffice/Applications/' + applicationId);		
	}

	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'GSI Teaching Report',
			columns: exportColumns,
			entity: 'ADMISSIONS.GSI_TEACHING_REPORT_VW',
			search: this.constructSearch(this.state.search),
			order: 'GSI_NAME'
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>GSI Teaching Report</span>
						<ChemEdit parent={this} columns={teachingSearch} data={this.state.search} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} {...this.props} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='GradOffice_Teaching' table={this.state.teachingList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='250px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const teachingSearch = [
	{ Header: 'Year Started', accessor: 'YEAR_STARTED', type: 'list', row: 0, col: 0,
		options: { value: 'ACADEMIC_YEAR', label: 'ACADEMIC_YEAR', entity: 'ADMISSIONS.APPLICATION', distinct: true, order: 'ACADEMIC_YEAR desc' }},
	{ Header: 'GSI Name', accessor: 'GSI_NAME', row: 0, col: 1 },
	{ Header: 'Appt Count', accessor: 'APPT_COUNT', type: 'list', row: 0, col: 2,
		options: { value: 'APPT_COUNT', label: 'APPT_COUNT', entity: 'ADMISSIONS.GSI_TEACHING_REPORT_VW', distinct: true, order: 'APPT_COUNT desc' }}
];

const teachingList = {
	query: { entity: 'ADMISSIONS.GSI_TEACHING_REPORT_VW', search: null, order: 'GSI_NAME' },
	pageSize: 20,
	columns: [
		{ Header: '', accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editApplication(props.row.values.APP_ID)} />, width: 25 },
		{ Header: 'App ID', accessor: 'APP_ID', width: 85 },
		{ Header: 'SID', accessor: 'STUDENT_CARD', width: 120 },
		{ Header: 'Year Started', accessor: 'YEAR_STARTED', width: 100 },
		{ Header: 'Name', accessor: 'GSI_NAME', width: 200, Cell: props => <HashLink onClick={() => props.parent.viewApplication(props.row.values.APP_ID)}>{props.value}</HashLink> },
		{ Header: 'Email', accessor: 'GSI_EMAIL', width: 300 },
		{ Header: 'Res. Director', accessor: 'RES_DIRECTOR', width: 200 },
		{ Header: 'Appt. Count', accessor: 'APPT_COUNT', width: 100 }
	]
};

const exportColumns = [
	{ Header: 'App ID', accessor: 'APP_ID', width: 17 },
	{ Header: 'SID', accessor: 'STUDENT_CARD', width: 24 },
	{ Header: 'Year Started', accessor: 'YEAR_STARTED', width: 20 },
	{ Header: 'Name', accessor: 'GSI_NAME', width: 40 },
	{ Header: 'Email', accessor: 'GSI_EMAIL', width: 60 },
	{ Header: 'Res. Director', accessor: 'RES_DIRECTOR', width: 40 },
	{ Header: 'Appt. Count', accessor: 'APPT_COUNT', width: 20 }
];

export default withRouter(Teaching);
