import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { PencilSquare } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

class Future extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			TRANS_DATE_FROM: null,
			TRANS_DATE_TO: null,
			PI_NAME: '',
			USER_NAME: '',
			SPEEDTYPE: '',
			SERVICE_ID: []
		};
		
		// initialize future list
		var initialFutureList = this.copyTable(futureList);
		initialFutureList.query.search = this.constructSearch(search);
		
		// remove add/edit column if not authorized
		if (props.user.crs.userAccessID !== 1) {
			initialFutureList.columns.splice(0, 1);
		}

		this.state = {
			futureList: initialFutureList,
			search: search,
			searchCount: null,
			renderKey: 1
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newFutureList = this.copyTable(futureList);
		newFutureList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			futureList: newFutureList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.TRANS_DATE_FROM)) search.Children.push({ Attribute: 'TRANS_DATE', Operator: '>=', StringValue: this.formatDateForCompare(this.getDate(data.TRANS_DATE_FROM)) });
		if (!this.isEmpty(data.TRANS_DATE_TO)) search.Children.push({ Attribute: 'TRANS_DATE', Operator: '<=', StringValue: this.formatDateForCompare(this.getDate(data.TRANS_DATE_TO)) });
		if (!this.isEmpty(data.PI_NAME)) search.Children.push({ Operator: 'or', Children: [
			{ Attribute: 'PI_NAME', Operator: 'like', StringValue: data.PI_NAME },
			{ Attribute: 'SPEEDTYPE_PI', Operator: 'like', StringValue: data.PI_NAME }
		]});
		if (!this.isEmpty(data.USER_NAME)) search.Children.push({ Attribute: 'USER_NAME', Operator: 'like', StringValue: data.USER_NAME });
		if (!this.isEmpty(data.SPEEDTYPE)) search.Children.push({ Attribute: 'SPEEDTYPE', Operator: 'like', StringValue: data.SPEEDTYPE });
		if (data.SERVICE_ID.length > 0) {
			// combine all lists into one and then split
			search.Children.push({ Attribute: 'SERVICE_ID', Operator: 'in', LongList: data.SERVICE_ID.join(',').split(',') });
		}
		
		// if nothing was specified, forget it
		if (search.Children.length === 0) return null;
		return search;
	}
	
	formatDateForCompare(date) {
		var d = date.getDate();
		var m = date.getMonth() + 1; //Month from 0 to 11
		var y = date.getFullYear();
		return y.toString() + '/' + (m <= 9 ? ('0' + m) : m) + '/' + (d <= 9 ? ('0' + d) : d);
	}
	
	editTransaction(serviceId, jobId) {
		this.props.navigate('/CRS/Transaction/' + serviceId + '/' + jobId);
	}	

	render() {

		var exportFutureData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Future View Journal',
			columns: futureExportColumns,
			entity: 'RECHARGES.FUTURE_JOURNAL_DISPLAY_VW',
			search: this.constructSearch(this.state.search),
			order: ['SPEEDTYPE_PI', 'PI_NAME', 'SPEEDTYPE', 'SERVICE_ID', 'TRANS_DATE', 'USER_NAME']
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
					<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Future View Journal</span>
					<ChemEdit parent={this} columns={futureSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={4} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportFutureData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportFutureData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
				  <Col>
				    <QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_FutureViewJournal' table={this.state.futureList}
					  onResult={(data) => this.onSearchResult(data)} offsetHeight='300px' />
				  </Col>
				</Row>
			</Container>
		</>);
	}
}

const futureSearch = [
	{ Header: 'Date From', accessor: 'TRANS_DATE_FROM', type: 'date', row: 0, col: 0 },
	{ Header: 'Date To', accessor: 'TRANS_DATE_TO', type: 'date', row: 0, col: 1 },
	{ Header: 'PI Name', accessor: 'PI_NAME', type: 'dropdown', row: 0, col: 2,
		options: { value: 'PI_NAME', label: 'PI_NAME', entity: 'RECHARGES.PRELIM_PI_SELECT_VW', order: 'PI_NAME' }},
	{ Header: 'User Name', accessor: 'USER_NAME', row: 1, col: 0, subscript: '(last name, first name) Use % for wild card' },
	{ Header: 'Speedtype', accessor: 'SPEEDTYPE', row: 1, col: 1, subscript: 'Use % for wild card' },
	{ Header: 'Service(s)', accessor: 'SERVICE_ID', type: 'list', row: 1, col: 2,
		options: { value: 'SERVICE_ID_LIST', label: 'RCH_NAME', entity: 'RECHARGES.PRELIM_SERVICE_SELECT_VW', order: 'RCH_NAME' }}
];

const futureList = {
	query: { entity: 'RECHARGES.FUTURE_JOURNAL_DISPLAY_VW', search: null, order: ['SPEEDTYPE_PI', 'PI_NAME', 'SPEEDTYPE', 'SERVICE_ID', 'TRANS_DATE', 'USER_NAME'] },
	pageSize: 20,
	columns: [
		{ Header: '', accessor: 'id', 
			Cell: props => {
				var serviceId = props.row.values.SERVICE_ID;
				if (serviceId < 11 || (serviceId > 29 && serviceId < 61) || serviceId === 21 || serviceId === 24 || serviceId > 99) {
					return (<PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editTransaction(props.row.values.SERVICE_ID, props.row.values.ID)} />);
				} else {
					return null;
				}
			}, width: 25 },
		{ Header: '', accessor: 'SERVICE_ID', show: false },
		{ Header: 'Service', accessor: 'SERVICE_NAME', width: 250 },
		{ Header: 'Job ID', accessor: 'ID', width: 80,
			Cell: props => <Link to={'/CRS/Jobs/' + props.row.values.SERVICE_ID + '/' + props.value}>{props.value}</Link> },
		{ Header: 'Trans Date', accessor: 'TRANS_DATE', width: 100 },
		{ Header: 'Modif Date', accessor: 'MODIF_DATE', width: 100 },
		{ Header: 'Description', accessor: 'DESCRIPTION', width: 400 },
		{ Header: 'User', accessor: 'USER_NAME', width: 200 },
		{ Header: 'PI', accessor: 'PI_NAME', width: 200 },
		{ Header: 'Speedtype', accessor: 'SPEEDTYPE', width: 125 },
		{ Header: 'Outside', accessor: 'OUTSIDE_FLAG', width: 75 },
		{ Header: 'PO', accessor: 'PO_NUM', width: 60 },
		{ Header: 'Labor Hrs', accessor: 'LABOR_HOURS', width: 80 },
		{ Header: '(Disc.)', accessor: 'DISCOUNT_AMOUNT', width: 80 },
		{ Header: 'Total Labor', accessor: 'LABOR_TOTAL', width: 80 },
		{ Header: 'Material', accessor: 'MATERIAL_COST', width: 80 },
		{ Header: 'Total Material', accessor: 'MATERIAL_TOTAL', width: 80 },
		{ Header: 'Total', accessor: 'LINE_TOTAL', width: 80 }
	]
};

const futureExportColumns = [
	{ Header: 'Service', accessor: 'SERVICE_NAME', width: 50 },
	{ Header: 'Job ID', accessor: 'ID', width: 16 },
	{ Header: 'Trans Date', accessor: 'TRANS_DATE', width: 20, format: 'yyyy/mm/dd' },
	{ Header: 'Modif Date', accessor: 'MODIF_DATE', width: 20, format: 'yyyy/mm/dd' },
	{ Header: 'Description', accessor: 'DESCRIPTION', width: 80 },
	{ Header: 'User', accessor: 'USER_NAME', width: 40 },
	{ Header: 'PI', accessor: 'PI_NAME', width: 40 },
	{ Header: 'Speedtype', accessor: 'SPEEDTYPE', width: 25 },
	{ Header: 'Outside', accessor: 'OUTSIDE_FLAG', width: 15 },
	{ Header: 'PO', accessor: 'PO_NUM', width: 12 },
	{ Header: 'Labor Hrs', accessor: 'LABOR_HOURS', width: 16, format: '#,##0.00' },
	{ Header: '(Disc.)', accessor: 'DISCOUNT_AMOUNT', width: 16, format: '#,##0.00' },
	{ Header: 'Total Labor', accessor: 'LABOR_TOTAL', width: 16, format: '#,##0.00' },
	{ Header: 'Material', accessor: 'MATERIAL_COST', width: 16, format: '#,##0.00' },
	{ Header: 'Total Material', accessor: 'MATERIAL_TOTAL', width: 16, format: '#,##0.00' },
	{ Header: 'Total', accessor: 'LINE_TOTAL', width: 16, format: '#,##0.00' }
];

export default withRouter(Future);