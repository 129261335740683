import React from 'react';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from "../ChemEdit";
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Person extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			person: {
				PERSON_ID: null,
				ROLE_ID: null,
				LNAME: '',
				MNAME: '',
				FNAME: '',
				PHONE: '',
				DEPT_ID: null,
				DEPT2_ID: null,
				EMAIL: '',
				COMMENTS: '',
				TYPE_ID: null,
				PI_ROLE_ID: null,
				DELEGATE: '',
				RA: null,
				START_DATE: null,
				ACTIVE_FLAG: undefined, // this has to be undefined or it won't change when value is loaded,
				IS_RA: false
			},
			replace: {
				PERSON_ID: null
			},
			replaceColumns: replaceColumns,
			associate: {
				ROLE_ID: []
			},
			renderKey: 0
		}
	}

	componentDidMount() {
		this.loadPerson();
	}

	loadPerson(event) {
		var self = this;
		
		if (this.props.params.roleId) {
			this.ajax({
				type: 'post',
				url: this.getConfig().host + '/CRS/Search',
				data: { 
					__RequestVerificationToken: this.props.user.antiForgeryToken,
					entity: 'PERSON_EDIT_VW',
					search: { Attribute: 'ROLE_ID', Operator: '=', LongValue: this.props.params.roleId },
					pageNumber: -1,
					pageSize: -1
				}
			}).done(function (data) {
				if (data.Success) {
					if (data.Data.length > 0) {
						var person = data.Data[0];
						
						// convert start date
						//person.START_DATE = self.getDate(person.START_DATE);
						
						// convert flags
						person.ACTIVE_FLAG = person.ACTIVE_FLAG === 'Y' ? true : false;
						person.IS_RA = person.IS_RA === 'Y' ? true : false;
						
						// compose created/modified
						person.CREATED = self.composeChangeInfo(person.CREATE_BY, person.CREATE_DATE);
						person.MODIFIED = self.composeChangeInfo(person.MODIF_BY, person.MODIF_DATE);
						person.CREATED_MODIFIED = person.CREATED + '\r\n' + person.MODIFIED;
						
						// set to "self RA" if the RA is themselves
						if (person.PERSON_ID === person.RA) person.RA = 0;
						
						// exclude person ID from RA replace list
						var newReplaceColumns = self.copyObject(self.state.replaceColumns);
						newReplaceColumns[0].options.search.LongList.push(person.PERSON_ID);
																		
						self.mergeState({
							person: person,
							replaceColumns: newReplaceColumns,
							renderKey: self.state.renderKey + 1
						}, () => {
							if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
						});
					} else {
						self.showAlert('Person Error', data.Data.length + ' Persons found for ID ' + self.props.params.roleId);
					}
				} else {
					self.showAlert('Server Error', data.Message);
				}			
			}).fail(function (jqXHR, textStatus, errorThrown) {
				self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
			});	
		} else {
			this.mergeState({
				person: {
					ROLE_ID: '(new)',
					ACTIVE_FLAG: true
				},
				renderKey: this.state.renderKey + 1
			});
		}
	}
		
	onChange(accessor, value) {
		var person = this.copyObject(this.state.person);
		this.setByAccessor(person, accessor, value);
		this.mergeState({ person: person, renderKey: this.state.renderKey + 1 });
	}
	
	validatePerson(event, columns, data) {
		return this.validate(columns, data);
	}
		
	onSubmit(event) {
		var self = this;		
		
		// make copy of data
		var person = this.copyObject(this.state.person);

		// convert start date
		person.START_DATE = this.dateTimeToMVC(this.getDate(person.START_DATE));

		// convert flags
		person.ACTIVE_FLAG = person.ACTIVE_FLAG ? 'Y' : 'N';
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/CRS/UpdatePerson',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, person: person }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => self.getFragment(data.Message));
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	onChangeReplaceRA(accessor, value) {
		var replace = this.copyObject(this.state.replace);
		this.setByAccessor(replace, accessor, value);
		this.mergeState({ replace: replace, renderKey: this.state.renderKey + 1 });
	}
	
	onSubmitReplaceRA(event) {
		var self = this;
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/CRS/ReplaceRA',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, oldra: this.state.replace.PERSON_ID, newra: this.state.person.PERSON_ID }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Successfully updated <Link to={'/CRS/People/' + self.state.person.ROLE_ID}>{self.state.person.ROLE_ID}</Link>
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	onChangeAssociateRA(accessor, value) {
		var associate = this.copyObject(this.state.associate);
		this.setByAccessor(associate, accessor, value);
		this.mergeState({ associate: associate, renderKey: this.state.renderKey + 1 });
	}
	
	onSubmitAssociateRA(event) {
		var self = this;
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/CRS/AssociateRA',
			overlay: true,
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, ra: this.state.person.PERSON_ID, pis: this.state.associate.ROLE_ID }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						Successfully updated <Link to={'/CRS/People/' + self.state.person.ROLE_ID}>{self.state.person.ROLE_ID}</Link>
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
		  <Container fluid>
			<Row>
			  <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Person {this.state.person.PERSON_ID} Role {this.state.person.ROLE_ID}</div>
				<ChemEdit parent={this} columns={personColumns} data={this.state.person} user={this.props.user} renderKey={this.state.renderKey} 
				   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}
				   validate={(event, columns, data) => this.validatePerson(event, columns, data)}>
				  <Form.Group>
					<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
				  </Form.Group>
				</ChemEdit>
			  </Col>
			</Row>
			{this.state.person.IS_RA && <>
			  <Row>
			    <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Replace Current RA</div>
				    <ChemEdit parent={this} columns={this.state.replaceColumns} data={this.state.replace} user={this.props.user} renderKey={this.state.renderKey} 
				       onChange={(accessor, value) => this.onChangeReplaceRA(accessor, value)} onSubmit={(event) => this.onSubmitReplaceRA(event)}>
				      <Form.Group>
					    <Button id="replace" variant="warning" type="submit" className="float-end">Replace</Button>
				      </Form.Group>		
					</ChemEdit>
			    </Col>
			  </Row>
			  <Row>
			    <Col xs={12} style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Associate to PIs</div>
				    <ChemEdit parent={this} columns={associateColumns} data={this.state.associate} user={this.props.user} renderKey={this.state.renderKey} 
				       onChange={(accessor, value) => this.onChangeAssociateRA(accessor, value)} onSubmit={(event) => this.onSubmitAssociateRA(event)}>
				      <Form.Group>
					    <Button id="associate" variant="warning" type="submit" className="float-end">Associate</Button>
				      </Form.Group>		
					</ChemEdit>
			    </Col>
			  </Row>
			</>}
		  </Container>
		</>);
	}
}

const personColumns = [
	{ Header: 'Last Name', accessor: 'LNAME', row: 0, col: 0, required: true },
	{ Header: 'First Name', accessor: 'FNAME', row: 0, col: 1, required: true },
	{ Header: 'Middle Name', accessor: 'MNAME', row: 0, col: 2 },
	{ Header: 'Appt. Type', accessor: 'TYPE_ID', row: 1, col: 0, type: 'select', required: true,
		options: { value: 'TYPE_ID', label: 'TYPE_NAME', entity: 'ROLE_TYPES', order: 'TYPE_NAME',
			search: { Attribute: 'DELETE_FLAG', Operator: 'is null' }}},
	{ Header: 'Dept. 1', accessor: 'DEPT_ID', row: 1, col: 1, type: 'select', required: true,
		options: { value: 'DEPARTMENT_ID', label: 'DEPT_NAME', entity: 'DEPARTMENT', order: 'DEPT_NAME' }},
	{ Header: 'Dept. 2', accessor: 'DEPT2_ID', row: 1, col: 2, type: 'select',
		options: { value: 'DEPARTMENT_ID', label: 'DEPT_NAME', entity: 'DEPARTMENT', order: 'DEPT_NAME' }},
	{ Header: 'PI', accessor: 'PI_ROLE_ID', row: 2, col: 0, type: 'select', required: true,
		options: { value: 'ROLE_ID', label: 'PI_NAME', entity: 'RECHARGES.EDIT_PI_SELECT_VW', order: ['SELF_PI_FIRST', 'PI_NAME'] }},
	{ Header: 'FSA/Delegate', accessor: 'DELEGATE', row: 2, col: 1, type: 'listoptions', editable: false,
		options: { value: 'DELEGATE', label: 'DELEGATE', entity: 'RECHARGES.DELEGATE_VW', order: 'DELEGATE',
			search: { Attribute: 'PI_ROLE_ID', Operator: '=', LongAccessor: 'PI_ROLE_ID' }}},
	{ Header: 'RA', accessor: 'RA', row: 2, col: 2, type: 'select',
		options: { value: 'PERSON_ID', label: 'RA_NAME', entity: 'RECHARGES.RA_SELECT_VW', order: ['SELF_RA_FIRST', 'RA_NAME'] },
		Cell: props => {
			if (props.parent.numbersAreEqual(props.parent.state.person.PI_ROLE_ID, props.parent.state.person.ROLE_ID) ||
				props.parent.numbersAreEqual(props.parent.state.person.PI_ROLE_ID, 0)) {
				return props.editor.renderFromCellDefinition();
			} else {
				return (<>N/A</>);
			}}},
	{ Header: 'Phone', accessor: 'PHONE', row: 3, col: 0 },
	{ Header: 'Email', accessor: 'EMAIL', row: 3, col: 1, required: true },
	{ Header: 'Comments', accessor: 'COMMENTS', row: 3, col: 2 },
	{ Header: 'Start Date', accessor: 'START_DATE', type: 'date', row: 4, col: 0, required: true },
	{ Header: 'Active', accessor: 'ACTIVE_FLAG', row: 4, col: 1, type: 'checkbox', subscript: '(sets active status in all CoC systems)' },
	{ Header: 'Created<br />Modified', accessor: 'CREATED_MODIFIED', row: 4, col: 2, editable: false,
		Cell: props => props.parent.getCellFragment(props.value) }
];

const replaceColumns = [
	{ Header: 'RA', accessor: 'PERSON_ID', type: 'select', required: true,
		options: { value: 'PERSON_ID', label: 'RA_NAME', entity: 'RECHARGES.RA_SELECT_VW', order: 'RA_NAME',
			search: { Attribute: 'PERSON_ID', Operator: 'not in', LongList: [ 0 ] }}}
];

const associateColumns = [
	{ Header: 'PI', accessor: 'ROLE_ID', type: 'list', required: true,
		options: { value: 'ROLE_ID', label: 'PI_NAME', entity: 'RECHARGES.ASSOCIATE_PI_SELECT_VW', order: 'PI_NAME' }}
];

export default withRouter(Person);
