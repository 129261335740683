import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import { ArrowDownUp, GripHorizontal, PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class Columns extends ChemComponent {
  	constructor(props) {
		super(props);
		
		var search = {
			COLUMN_NAME: '',
			COLUMN_DATA: ''
		};
		
		// initialize columns list
		var initialColumnsList = this.copyTable(columnsList);
		initialColumnsList.query.search = this.constructSearch(search);
		
		// remove 'reorder' and 'add/edit' columns if not authorized
		if (props.user.crs.userAccessID !== 1) {
			initialColumnsList.columns.splice(0, 2);
		}
		
		this.state = {
			renderKey: 1,
			columnsList: initialColumnsList,
			search: search,
			searchCount: null,
			moveColumn: 0,
			moveSteps: 0
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}
	
	onSubmitSearch(event) {
		var newColumnsList = this.copyTable(columnsList);
		newColumnsList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			columnsList: newColumnsList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: [{ Attribute: 'HEADER_ID', Operator: '=', LongValue: this.props.params.headerId }]
		};

		if (!this.isEmpty(data.COLUMN_NAME)) search.Children.push({ Attribute: 'COLUMN_NAME', Operator: 'like', StringValue: '%' + data.COLUMN_NAME + '%' });
		if (!this.isEmpty(data.COLUMN_DATA)) search.Children.push({ Attribute: 'COLUMN_DATA', Operator: 'like', StringValue: '%' + data.COLUMN_DATA + '%' });

		return search;
	}
	
	addColumn() {
		this.props.navigate('/CRS/Headers/' + this.props.params.headerId + '/Columns/new');
	}
	
	editColumn(columnOrder) {
		this.props.navigate('/CRS/Headers/' + this.props.params.headerId + '/Columns/' + columnOrder);
	}
	
	moveRow(dragId, nSteps) {
		// since column ordering starts at 1, 
		// the column we are looking for will always be dragId + 1
		this.mergeState({ moveColumn: dragId + 1, moveSteps: this.state.moveSteps - nSteps });		
	}
	
	dropRow() {
		var self = this;
		var moveColumn = {
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			headerid: this.props.params.headerId,
			column: this.state.moveColumn,
			nsteps: this.state.moveSteps
		};
		this.mergeState({ moveColumn: 0, moveSteps: 0 }, () => {
			if (moveColumn.nsteps !== 0) {
				this.ajax({
					type: 'post',
					url: this.getConfig().host + '/CRS/MoveColumn',
					overlay: true,
					data: moveColumn
				}).done(function (data) {
					if (data.Success) {
						self.onSubmitSearch();
					} else {
						self.showAlert('Move Column Error', data.Message);
					}
				}).fail(function (jqXHR, textStatus, errorThrown) {
					self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
				});
			}
		});
	}
	
	render() {

		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Columns',
			columns: exportColumns,
			entity: 'RECHARGES.HEADER_COLUMN_VW',
			search: this.constructSearch(this.state.search),
			order: 'COLUMN_ORDER'
		});			

		return (<>
			<Container fluid>
				<Row>
				  <Col xs={12} style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Columns</span>
					<ChemEdit parent={this} columns={columnsSearch} data={this.state.search} renderKey={this.state.renderKey} 
					  onChange={(accessor, value) => this.onChangeSearch(accessor, value)} onSubmit={(event) => this.onSubmitSearch(event)} {...this.props}>
					  <Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
						<Col xs={4} style={{ marginTop: 8}}>
							{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
								{exportData.map((excelFormField, index) => {
									return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={1} style={{ marginTop: 6}}>
							<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
								{exportData.map((pdfFormField, index) => {
									return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
								})}
								<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
								  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
								</OverlayTrigger>
							</form>
						</Col>
						<Col xs={6}>
						  <Button id="update" variant="warning" type="submit" className="float-end">Search</Button>
						</Col>
					  </Form.Group>
					</ChemEdit>
				  </Col>
				</Row>
				<Row>
					<Col>
						<QueryTable renderKey={this.state.renderKey} parent={this} name='CRS_Columns' table={this.state.columnsList}
							onResult={(data) => this.onSearchResult(data)} offsetHeight='200px' />
					</Col>
				</Row>
			</Container>
		</>);
	}
}

const columnsSearch = [
	{ Header: 'Column', accessor: 'COLUMN_NAME', row: 0, col: 0 },
	{ Header: 'Data', accessor: 'COLUMN_DATA', row: 0, col: 1 }
];

const columnsList = {
	query: { entity: 'RECHARGES.HEADER_COLUMN_VW', search: null, order: 'COLUMN_ORDER' },
	pageSize: 20,
	columns: [
		{ Header: <ArrowDownUp />, accessor: 'drag', drag: true, width: 25,
			Cell: props => <GripHorizontal style={{ cursor: 'pointer' }} /> },
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addColumn()} />, accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editColumn(props.row.values.COLUMN_ORDER)} />, width: 25 },
		{ Header: 'Header', accessor: 'HEADER_NAME', width: 200 },
		{ Header: 'Column', accessor: 'COLUMN_NAME', width: 200 },
		{ Header: 'Data', accessor: 'COLUMN_DATA', width: 200 },
		{ Header: 'Order', accessor: 'COLUMN_ORDER', width: 60 },
	]
};

const exportColumns = [
	{ Header: 'Header', accessor: 'HEADER_NAME', width: 40 },
	{ Header: 'Column', accessor: 'COLUMN_NAME', width: 40 },
	{ Header: 'Data', accessor: 'COLUMN_DATA', width: 40 },
	{ Header: 'Order', accessor: 'COLUMN_ORDER', width: 12, format: '0' },
];

export default withRouter(Columns);