import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { PencilSquare } from 'react-bootstrap-icons';

class HeTransactions extends ChemComponent {
	constructor(props) {
		super(props);

		var search = {
			TRANS_DATE_FROM: null,
			TRANS_DATE_TO: null,
			PI_ID: null,
			COST_CENTER: '',
			METER_NAME: '',
			DATE_BILLED: [''],
			BUILDING: null,
			ROOM: ''
		};
		
		var transListInit = this.copyTable(transList);
		transListInit.query.search = this.constructSearch(search);

		this.state = {
			transList: transListInit,
			search: search,
			searchCount: null,
			renderKey: 0
		};
	}
	
	componentDidMount() {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/CRS/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'CURRENT_CYCLE_VW',
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				if (data.Data.length > 0) {
					var search = self.copyObject(self.state.search);
					search.TRANS_DATE_FROM = self.dateTimeToMVC(self.getDate(data.Data[0].BEG_DATE));	
					var newTransList = self.copyTable(transList);
					newTransList.query.search = self.constructSearch(search);
					newTransList.hold = false;
					self.mergeState({ 
						transList: newTransList,
						search: search,
						searchCount: null,
						renderKey: self.state.renderKey + 1
					});
				} else {
					self.showAlert('Current Cycle Error', data.Data.length + ' cycles marked as current.');
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});		
	}
																			
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newTransList = this.copyTable(transList);
		newTransList.query.search = this.constructSearch(this.state.search);
		newTransList.hold = false;
		this.mergeState({ 
			transList: newTransList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}
	
	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};
		
		if (!this.isEmpty(data.TRANS_DATE_FROM)) search.Children.push({ Attribute: 'TRANS_DATE', Operator: '>=', DateValue: this.dateTimeToMVC(this.getDate(data.TRANS_DATE_FROM)) });
		if (!this.isEmpty(data.TRANS_DATE_TO)) search.Children.push({ Attribute: 'TRANS_DATE', Operator: '<=', DateValue: this.dateTimeToMVC(this.getDate(data.TRANS_DATE_TO)) });
		if (!this.isEmpty(data.PI_ID)) search.Children.push({ Attribute: 'PI_ID', Operator: '=', LongValue: data.PI_ID });
		if (!this.isEmpty(data.COST_CENTER)) search.Children.push({ Attribute: 'COST_CENTER', Operator: '=', StringValue: data.COST_CENTER });
		if (!this.isEmpty(data.METER_NAME)) search.Children.push({ Attribute: 'METER_NAME', Operator: '=', StringValue: data.METER_NAME });
		if (data.DATE_BILLED.length > 0) {
			var dbsearch = { Operator: 'or', Children: [] };
			for (let i = 0; i < data.DATE_BILLED.length; i++) {
				if (this.isEmpty(data.DATE_BILLED[i])) {
					dbsearch.Children.push({ Attribute: 'DATE_BILLED', Operator: 'is null' });
				} else {
					dbsearch.Children.push({ Attribute: 'DATE_BILLED', Operator: '=', DateValue: this.dateTimeToMVC(this.getDate(data.DATE_BILLED[i])) });
				}
			}
			search.Children.push(dbsearch);
		}
		if (!this.isEmpty(data.BUILDING)) search.Children.push({ Attribute: 'BUILDING', Operator: '=', StringValue: data.BUILDING });
		if (!this.isEmpty(data.ROOM)) search.Children.push({ Attribute: 'ROOM', Operator: 'like', StringValue: data.ROOM + '%' });

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	editTransaction(transId) {
		this.props.setHeTransactionEditable(true);
		this.props.navigate('/HeLAD/HeTransactions/' + transId);		
	}

	viewTransaction(transId) {
		this.props.setHeTransactionEditable(false);
		this.props.navigate('/HeLAD/HeTransactions/' + transId);		
	}
	
	viewMeter(meterId) {
		this.props.setHeMeterEditable(false);
		this.props.navigate('/HeLAD/HeMeters/' + meterId);
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'Helium Recovery Transactions',
			columns: exportColumns,
			entity: 'LIQUID_AIR.HE_TRANSACTIONS_VW',
			search: this.constructSearch(this.state.search)
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Helium Recovery Transactions</span>
						<ChemEdit parent={this} user={this.props.user} columns={transSearch} data={this.state.search}
						  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)}/>
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='HeLAD_HeTransactions' table={this.state.transList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='300px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const transSearch = [
	{ Header: 'Date From', accessor: 'TRANS_DATE_FROM', type: 'date', row: 0, col: 0 },
	{ Header: 'Date To', accessor: 'TRANS_DATE_TO', type: 'date', row: 0, col: 1 },
	{ Header: 'PI', accessor: 'PI_ID', type: 'select', row: 0, col: 2,
		options: { value: 'PI_ID', label: 'PI_NAME', entity: 'LIQUID_AIR.HE_TRANS_PI_SELECT_VW', order: 'PI_NAME' }},
	{ Header: 'Speedtype', accessor: 'COST_CENTER', row: 0, col: 3 },
	{ Header: 'Meter', accessor: 'METER_NAME', row: 1, col: 0 },
	{ Header: 'Date Billed', accessor: 'DATE_BILLED', type: 'list', row: 1, col: 1,
		options: { value: 'DATE_BILLED', label: 'DATE_BILLED_DISPLAY', entity: 'LIQUID_AIR.HE_DATE_BILLED_SELECT_VW', order: 'DATE_BILLED desc' }},
	{ Header: 'Building', accessor: 'BUILDING', type: 'select', row: 1, col: 2,
		options: { value: 'BUILDING', label: 'BUILDING', entity: 'LIQUID_AIR.METER', distinct: true, order: 'BUILDING',
			search: { Attribute: 'BUILDING', Operator: 'is not null' }}},
	{ Header: 'Room', accessor: 'ROOM', row: 1, col: 3 }
];

const transList = {
	query: { entity: 'LIQUID_AIR.HE_TRANSACTIONS_VW', search: null },
	pageSize: 20,
	hold: true,
	columns: [
		{ Header: '', accessor: 'id', 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editTransaction(props.row.values.READING_ID)} />, width: 25 },
		{ Header: 'Trans ID', accessor: 'READING_ID', width: 65,
			Cell: props => <HashLink onClick={() => props.parent.viewTransaction(props.value)}>{props.value}</HashLink> },
		{ Header: 'Date', accessor: 'READING_DATE_DISPLAY', width: 125 },
		{ Header: '', accessor: 'METER_ID', show: false },
		{ Header: 'Meter', accessor: 'METER_NAME', width: 75,
			Cell: props => <HashLink onClick={() => props.parent.viewMeter(props.row.values.METER_ID)}>{props.value}</HashLink> },
		{ Header: 'Room', accessor: 'ROOM', width: 75 },
		{ Header: 'Building', accessor: 'BUILDING', width: 125 },
		{ Header: 'PI', accessor: 'PI_NAME', width: 250 },
		{ Header: 'Liters', accessor: 'LITERS', width: 75 },
		{ Header: 'Cost', accessor: 'AMOUNT_CHARGED', width: 80 },
		{ Header: 'Speedtype', accessor: 'COST_CENTER', width: 125 },
		{ Header: 'Billed', accessor: 'DATE_BILLED_DISPLAY', width: 125 }
	]
};

const exportColumns = [
	{ Header: 'Trans ID', accessor: 'READING_ID', width: 13 },
	{ Header: 'Date', accessor: 'READING_DATE_DISPLAY', width: 25 },
	{ Header: 'Meter', accessor: 'METER_NAME', width: 15 },
	{ Header: 'Room', accessor: 'ROOM', width: 15 },
	{ Header: 'Building', accessor: 'BUILDING', width: 25 },
	{ Header: 'PI', accessor: 'PI_NAME', width: 50 },
	{ Header: 'Liters', accessor: 'LITERS', width: 15 },
	{ Header: 'Cost', accessor: 'AMOUNT_CHARGED', width: 16 },
	{ Header: 'Speedtype', accessor: 'COST_CENTER', width: 25 },
	{ Header: 'Billed', accessor: 'DATE_BILLED_DISPLAY', width: 25 }
];

export default withRouter(HeTransactions);
