import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class GeneralAdmin extends ChemComponent {
	constructor(props) {
		super(props);
		
		this.state = {
			generalAdmin: {
				CHAIR: '',
				CHART_STRING: '',
				MANAGER_EMAIL: '',
				DEFAULT_GRC_TIME: '',
				SAO_EMAIL: '',
				DEFAULT_QE_TIME: '',
				ADMIN_NAME: '',
				ADMIN_EMAIL: '',
				OFFICE_EMAIL: ''
			},
			renderKey: 0
		}
	}	

	componentDidMount() {
		this.loadGeneralAdmin();
	}

	loadGeneralAdmin(event) {
		var self = this;
		
		this.ajax({
			type: 'post',
			url: this.getConfig().host + '/GradOffice/Search',
			data: { 
				__RequestVerificationToken: this.props.user.antiForgeryToken,
				entity: 'DEPT_VARIABLES',
				search: { Attribute: 'DEPT_CODE', Operator: '=', LongValue: 1 },
				pageNumber: -1,
				pageSize: -1
			}
		}).done(function (data) {
			if (data.Success) {
				if (data.Data.length > 0) {
					var generalAdmin = data.Data[0];
						
					self.mergeState({
						generalAdmin: generalAdmin,
						renderKey: self.state.renderKey + 1
					}, () => {
						if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
					});
				} else {
					self.showAlert('GeneralAdmin Error', data.Data.length + ' Department variables found for Chemistry department');
				}
			} else {
				self.showAlert('Server Error', data.Message);
			}			
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});	
	}
		
	onChange(accessor, value) {
		var generalAdmin = this.copyObject(this.state.generalAdmin);
		this.setByAccessor(generalAdmin, accessor, value);
		this.mergeState({ generalAdmin: generalAdmin, renderKey: this.state.renderKey + 1 });
	}
		
	onSubmit(event) {
		var self = this;
		
		// make copy of data
		var generalAdmin = this.copyObject(this.state.generalAdmin);
		
		this.ajax({
			type: 'POST',
			url: this.getConfig().host + '/Home/Update',
			data: { __RequestVerificationToken: this.props.user.antiForgeryToken, entity: 'ADMISSIONS.DEPT_VARIABLES', data: generalAdmin }
		}).done(function (data) {
			if (data.Success) {
				self.props.parent.showConfirmation(() => {
					return (<>
						General Admin updated for <Link to={'/GradOffice/GeneralAdmin/'}>Chemistry</Link>.
					</>);
				});
			} else {
				self.showAlert('Server Error', data.Message);
			}
		}).fail(function (jqXHR, textStatus, errorThrown) {
			self.showAlert('Server Error', 'Server returned a status of ' + jqXHR.status);
		});
	}

	render() {
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', paddingBottom: '10px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>General Administration</div>
				  <Container fluid>
					<Row>
					  <Col>
						<ChemEdit parent={this} columns={generalAdminColumns} data={this.state.generalAdmin} user={this.props.user} renderKey={this.state.renderKey} 
						   onChange={(accessor, value) => this.onChange(accessor, value)} onSubmit={(event) => this.onSubmit(event)}>
						  <Form.Group>
							<Button id="save" variant="warning" type="submit" className="float-end">Save</Button>
						  </Form.Group>
						</ChemEdit>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		</>);
	}
}

const generalAdminColumns = [
	{ Header: 'Department Chair', accessor: 'CHAIR', type: 'select', row: 0, col: 0,
		options: { value: 'NAME', label: 'NAME', entity: 'COLLEGE.PROFESSORS_VW' }},
	{ Header: 'Default Chart String for GSI Appt', accessor: 'DEFAULT_CHART_STRING', row: 0, col: 1 },
	{ Header: 'Student Affairs Manager Email', accessor: 'MANAGER_EMAIL', row: 1, col: 0 },
	{ Header: 'Default GRC Time', accessor: 'DEFAULT_GRC_TIME', row: 1, col: 1 },
	{ Header: 'SAO Email Address (for GSI Appts)', accessor: 'SAO_EMAIL', row: 2, col: 0 },
	{ Header: 'Default QE Time', accessor: 'DEFAULT_QE_TIME', row: 2, col: 1 },
	{ Header: 'Web Site Admin Name', accessor: 'ADMIN_NAME', row: 3, col: 0 },
	{ Header: 'Web Site Admin Email', accessor: 'ADMIN_EMAIL', row: 3, col: 1 },
	{ Header: 'Graduate Office Email', accessor: 'OFFICE_EMAIL', row: 4, col: 1 }
];

export default withRouter(GeneralAdmin);
