import React from 'react';
import ChemComponent from './ChemComponent';
import ChemEdit from './ChemEdit';
import ChemTable from './ChemTable';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { ArrowDownUp, GripHorizontal, PencilSquare, PlusSquare, InfoCircle } from 'react-bootstrap-icons';

class EditTable extends ChemComponent {
	constructor(props) {
		super(props);
		
		// add the edit controls (add, edit, drag) to the table columns
		var i;
		var tableColumns = [];
		for (i = 0; i < editControlColumns.length; i++) {
			// only add drag column if order field was specified and records are editable
			if (!editControlColumns[i].drag || (props.order && (this.isEmpty(props.editable) || props.editable))) tableColumns.push(editControlColumns[i]);
		}
		for (i = 0; i < props.tableColumns.length; i++) tableColumns.push(props.tableColumns[i]);

		this.state = {
			editor: this.mvc2js(this.copyObject(props.newRecord), props.editColumns),
			editable: this.isEmpty(props.editable) || props.editable,
			editorsOpen: [],
			tableColumns: tableColumns,
			showEditor: false,
			dragId: null,
			dragSteps: 0,
			renderKey: 1
		};
	}
	
	componentDidUpdate(prevProps) {
		var newEditable = this.isEmpty(this.props.editable) || this.props.editable;
		if (newEditable !== this.state.editable) {
			var i;
			var tableColumns = [];
			for (i = 0; i < editControlColumns.length; i++) {
				// only add drag column if order field was specified and records are editable
				if (!editControlColumns[i].drag || (this.props.order && (this.isEmpty(this.props.editable) || this.props.editable))) tableColumns.push(editControlColumns[i]);
			}
			for (i = 0; i < this.props.tableColumns.length; i++) tableColumns.push(this.props.tableColumns[i]);
			this.mergeState({ 
				editable: newEditable,
				tableColumns: tableColumns
			});
		}
	}
	
	addRecord() {
		var self = this;
		this.mergeState({
			showEditor: true
		}, () => {
			if (self.props.onEdit) self.props.onEdit(true);
		});
	}
	
	findRecord(id) {
		if (id !== null && id !== undefined) {
			for (var i = 0; i < this.props.data.length; i++) {
				if (this.props.data[i].id === id) return i;
			}
		}
		return -1;
	}
	
	editRecord(id) {
		// locate the rec in the data table
		var recIdx = this.findRecord(id);
		
		// if the rec was found
		if (recIdx >= 0) {			
			// merge rec into state
			var self = this;
			this.mergeState({
				editor: this.mvc2js(this.copyObject(this.props.data[recIdx]), this.props.editColumns),
				showEditor: true,
				renderKey: this.state.renderKey + 1
			}, () => {
				if (self.props.onEdit) self.props.onEdit(true);
			});
		}
	}
	
	deleteRecord(event) {
		if (this.state.editorsOpen.length > 0) {
			this.showAlert('Editors Open', 'All enclosed editors must be closed before closing this one.');
		} else {
			// make a copy of data array
			var data = this.copyObject(this.props.data);
			
			// locate rec in data table
			var recIdx = this.findRecord(this.state.editor.id);

			// remove the rec in question
			data.splice(recIdx, 1);
			
			// if there is an order field
			if (this.props.order) {
				// renumber the recs
				for (var i = 0; i < data.length; i++) {
					data[i][this.props.order] = i + 1;
				}
			}
			
			// update state and pass data to parent
			var self = this;
			this.mergeState({
				editor: this.mvc2js(this.copyObject(this.props.newRecord), this.props.editColumns),
				showEditor: false,
				renderKey: this.state.renderKey + 1
			}, () => {
				if (self.props.onChange) self.props.onChange(data);
			});
		}
	}
	
	closeEditor(event) {
		if (this.state.editorsOpen.length > 0) {
			this.showAlert('Editors Open', 'All enclosed editors must be closed before closing this one.');
		} else {
			// make a copy of data array, keeping files intact
			var data = this.copyDataWithFiles();
			
			// locate record in data table
			var recIdx = this.findRecord(this.state.editor.id);
			
			// if this is an existing record
			if (recIdx >= 0) {
				// copy edited record into data table
				data[recIdx] = this.copyEditorWithFiles();
			} else {
				// this is a new record; find the max id number
				var maxId = -1;
				for (var i = 0; i < data.length; i++) {
					if (data[i].id > maxId) maxId = data[i].id;
				}
				
				// copy new rec to end of table
				data.push(this.copyEditorWithFiles());
				
				// set id of new rec
				data[data.length - 1].id = maxId + 1;
				
				// if there is an order field
				if (this.props.order) {
					// set drag & order of new rec
					data[data.length - 1].drag = maxId + 1;
					data[data.length - 1][this.props.order] = data.length;
				}
			}
			
			// update state then send data to parent
			var self = this;
			this.mergeState({
				editor: this.mvc2js(this.copyObject(this.props.newRecord), this.props.editColumns),
				showEditor: false,
				renderKey: this.state.renderKey + 1
			}, () => {
				if (self.props.onChange) self.props.onChange(data);
			});
		}
	}
	
	copyDataWithFiles() {
		var dataCopy = this.copyObject(this.props.data);
		
		for (var d = 0; d < dataCopy.length; d++) {
			for (var c = 0; c < this.props.editColumns.length; c++) {
				if (this.props.editColumns[c].type && this.props.editColumns[c].type === 'file') {
					var file = this.getByAccessor(this.props.data[d], this.props.editColumns[c].accessor);
					this.setByAccessor(dataCopy[d], this.props.editColumns[c].accessor, file);
				}
			}
		}
		
		return dataCopy;
	}
	
	copyEditorWithFiles() {
		var editorCopy = this.js2mvc(this.copyObject(this.state.editor), this.props.editColumns);
		for (var i = 0; i < this.props.editColumns.length; i++) {
			if (this.props.editColumns[i].type && this.props.editColumns[i].type === 'file') {
				// copy the original file object from the editor
				var file = this.getByAccessor(this.state.editor, this.props.editColumns[i].accessor);
				this.setByAccessor(editorCopy, this.props.editColumns[i].accessor, file);
			}
		}
		
		return editorCopy;
	}

	cancelEdit(event) {
		var self = this;
		
		if (this.state.editorsOpen.length > 0) {
			this.showAlert('Editors Open', 'All enclosed editors must be closed before closing this one.');
		} else {
			this.mergeState({
				editor: this.mvc2js(this.copyObject(this.props.newRecord), this.props.editColumns),
				showEditor: false
			}, () => {
				if (self.props.onEdit) self.props.onEdit(false);
			});
		}
	}
	
	onChange(accessor, value) {
		var editor = this.copyObject(this.state.editor);
		if (Array.isArray(accessor)) {
			for (var i = 0; i < accessor.length; i++) {
				this.setByAccessor(editor, accessor[i], value[i]);
			}
		} else {
			this.setByAccessor(editor, accessor, value);
		}
		var newState = {
			editor: editor,
			renderKey: this.state.renderKey + 1
		};
		// update editor status as well
		newState.editorsOpen = this.setEditorStatus(accessor, false);
		if (this.props.onChangeInternal) {
			this.props.onChangeInternal(newState.editor, accessor, value);
		}
		this.mergeState(newState);
	}
	
	onEditChild(accessor, editorIsOpen) {
		this.mergeState({
			editorsOpen: this.setEditorStatus(accessor, editorIsOpen),
			renderKey: this.state.renderKey + 1
		});
	}
	
	setEditorStatus(accessor, editorIsOpen) {
		var editorsOpen = this.copyObject(this.state.editorsOpen);
		if (editorIsOpen) {
			// add to list if it isn't already present
			if (!editorsOpen.includes(accessor)) editorsOpen.push(accessor);
		} else {			
			// remove from list if it is present
			if (editorsOpen.includes(accessor)) editorsOpen.splice(editorsOpen.indexOf(accessor), 1);
		}
		return editorsOpen;
	}		

	moveRow(dragId, nSteps) {
		this.mergeState({
			dragId: dragId,
			dragSteps: this.state.dragSteps - nSteps
		});
	}
	
	dropRow() {
		if (this.state.dragDegreeSteps !== 0) {
			// make a copy of the data array
			var data = this.copyObject(this.props.data);

			// locate the dragId in the data
			var recIdx = -1;
			for (var i = 0; i < data.length; i++) {
				if (data[i].drag === this.state.dragId) {
					recIdx = i;
					break;
				}					
			}
			
			// if rec was found
			if (recIdx >= 0) {				
				// remove the rec
				var record = data.splice(recIdx, 1)[0];
				
				// insert it at the new index
				data.splice(recIdx + this.state.dragSteps, 0, record);
				
				// if there is an order field
				if (this.props.order) {
					// renumber the array
					for (var j = 0; j < data.length; j++) {
						data[j][this.props.order] = j + 1;
					}
				}
				
				// update the state, then the parent
				var self = this;
				this.mergeState({
					dragId: null,
					dragSteps: 0,
					renderKey: this.state.renderKey + 1
				}, () => {
					if (self.props.onChange) self.props.onChange(data);
				});
			}
		}
	}

	render() {
		return(<Container fluid>
			{this.props.title && <Row>
				<Col>
					<div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>{this.props.title}</div>
				</Col>
			</Row>}
			{this.state.showEditor ? <>
				<Row>
				  <Col>
					<ChemEdit parent={this} columns={this.props.editColumns} data={this.state.editor} user={this.props.user} editable={this.state.editable}
						renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChange(accessor, value)} />
				  </Col>
				</Row>
				{React.Children.map(this.props.children, (child, childKey) => {
					return (<Row key={childKey}>
						<Col>
						  {React.isValidElement(child) ? 
							this.props.onChangeInternal ? 
								React.cloneElement(child, { 
									data: this.getByAccessor(this.state.editor, child.props.accessor),
									onChange: (data) => this.onChange(child.props.accessor, data),
									onChangeInternal: (data, accessor, value) => this.props.onChangeInternal(data, accessor, value),
									onEdit: (editorIsOpen) => this.onEditChild(child.props.accessor, editorIsOpen)
								}) :
								React.cloneElement(child, { 
									data: this.getByAccessor(this.state.editor, child.props.accessor),
									onChange: (data) => this.onChange(child.props.accessor, data),
									onEdit: (editorIsOpen) => this.onEditChild(child.props.accessor, editorIsOpen)
								}) : 
							child}
						</Col>
					</Row>);
				})}
				<Row style={{ borderBottom: '1px solid #eee', paddingTop: 5, paddingBottom: 5 }}>
				  <Col>
					<Button id="close" variant="warning" type="button" className="float-end"
						onClick={(event) => this.state.editable ? this.closeEditor(event) : this.cancelEdit(event)}>Close</Button>
					{this.state.editable && this.isNumeric(this.state.editor.id) && <Button id="delete" 
						variant="warning" type="button" className="float-end" style={{ marginRight: '10px' }} 
						onClick={(event) => this.deleteRecord(event)}>Delete</Button>}
					{this.state.editable && <Button id="cancel" variant="warning" type="button" className="float-end" 
						style={{ marginRight: '10px' }} onClick={(event) => this.cancelEdit(event)}>Cancel</Button>}
				  </Col>
				</Row>
			  </> :
			  <ChemTable renderKey={this.state.renderKey} parent={this} data={this.props.data} columns={this.state.tableColumns} name={this.props.tableName} />
			}
		</Container>);
	}
}

const editControlColumns = [
	{ Header: <ArrowDownUp />, accessor: 'drag', drag: true, width: 25,
		Cell: props => <GripHorizontal style={{ cursor: 'pointer' }} /> },
	{ Header: props => props.parent.state.editable ? <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addRecord()} /> : <></>, accessor: 'id', 
		Cell: props => props.parent.state.editable ? 
			<PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editRecord(props.value)} /> :
			<InfoCircle style={{ cursor: 'pointer' }} onClick={() => props.parent.editRecord(props.value)} />, width: 25 }
];

export default EditTable;