import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import ChemEdit from '../ChemEdit';

class Home extends ChemComponent {
	constructor(props) {
		super(props);

		this.state = {
			student: {
				RESEARCH_DIRECTOR: null,
				STUDENT_CARD: '',
				LNAME: '',
				FNAME: '',
				REGISTRATION: null
			},
			application: {
				LNAME: '',
				FNAME: '',
				EMAIL: '',
				ACADEMIC_YEAR: null,
				APP_ID: ''
			}
		};
	}
	
	onChangeStudent(accessor, value) {
		var student = this.copyObject(this.state.student);
		this.setByAccessor(student, accessor, value);
		this.mergeState({ student: student });
	}
	
	onChangeApplication(accessor, value) {
		var application = this.copyObject(this.state.application);
		this.setByAccessor(application, accessor, value);
		this.mergeState({ application: application });
	}
	
	onSubmitStudent(event) {
		// convert RES_DIRECTOR and REGISTRATION to arrays since that's what student page is expecting
		var student = this.copyObject(this.state.student);
		student.RES_DIRECTOR = student.RES_DIRECTOR ? [student.RES_DIRECTOR] : [];
		student.REGISTRATION = this.isEmpty(student.REGISTRATION) ? [] : [student.REGISTRATION];
		this.props.onStudentSearch(student);
	}
	
	onSubmitApplication(event) {
		this.props.onApplicationSearch(this.state.application);
	}
	
	render() {
		return(
			<Container fluid>
			  <Row>
			    <Col md={12} lg={11} xl={10} style={{ paddingTop: '20px', paddingBottom: '20px', border: '1px solid #ccc', backgroundColor: '#FEFEFE' }}>
				  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Department Graduate Office</span><br />
			      <Container fluid>
				    <Row>
					  <Col style={{ paddingTop: '10px' }}>
						<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Student</span>
						<ChemEdit parent={this} columns={studentSearch} data={this.state.student} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChangeStudent(accessor, value)} onSubmit={(event) => this.onSubmitStudent(event)} {...this.props}>
						  <Form.Group>
							<Button id="findStudent" variant="warning" type="submit" className="float-end">Find Student</Button>
						  </Form.Group>						  
						</ChemEdit>
					  </Col>
					  <Col style={{ paddingTop: '10px' }}>
						<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Applicant</span>
						<ChemEdit parent={this} columns={applicationSearch} data={this.state.application} renderKey={this.state.renderKey} 
						  onChange={(accessor, value) => this.onChangeApplication(accessor, value)} onSubmit={(event) => this.onSubmitApplication(event)} {...this.props}>
						  <Form.Group>
							<Button id="findApplication" variant="warning" type="submit" className="float-end">Find Application</Button>
						  </Form.Group>						  
						</ChemEdit>
					  </Col>
					</Row>
				  </Container>
				</Col>
			  </Row>
			</Container>
		);
	}
}

const studentSearch = [
	{ Header: 'Research Director', accessor: 'RES_DIRECTOR', type: 'select', row: 0, col: 0,
		options: { value: 'RES_DIRECTOR_NAME', label: 'RES_DIRECTOR_NAME', entity: 'ADMISSIONS.RESEARCH_DIRECTOR_SELECT_VW', order: 'RES_DIRECTOR_NAME' }},
	{ Header: 'Student ID', accessor: 'STUDENT_CARD' },
	{ Header: 'Last Name', accessor: 'LNAME' },
	{ Header: 'First Name', accessor: 'FNAME' },
	{ Header: 'Registration Status', accessor: 'REGISTRATION', type: 'select',
		options: { value: 'REG_STATUS_NAME', label: 'REG_STATUS_NAME', entity: 'ADMISSIONS.REG_STATUS',
			search: { Attribute: 'REG_STATUS_ID', Operator: '!=', LongValue: 6 }}}
];

const applicationSearch = [
	{ Header: 'Last Name', accessor: 'LNAME' },
	{ Header: 'First Name', accessor: 'FNAME' },
	{ Header: 'E-mail', accessor: 'EMAIL' },
	{ Header: 'Year Applied', accessor: 'ACADEMIC_YEAR', type: 'select',
		options: { value: 'ACADEMIC_YEAR', label: 'ACADEMIC_YEAR_DISPLAY', entity: 'ADMISSIONS.ACADEMIC_YEAR_SELECT_VW', order: 'ACADEMIC_YEAR desc' }},
	{ Header: 'Applicant ID', accessor: 'APP_ID' }
];

export default Home;