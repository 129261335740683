import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { Col, Container, Row, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withRouter } from '../withRouter';
import ChemEdit from '../ChemEdit';
import QueryTable from '../QueryTable';
import HashLink from '../HashLink';
import { PencilSquare, PlusSquare } from 'react-bootstrap-icons';

class GN2Meters extends ChemComponent {
	constructor(props) {
		super(props);

		var search = {
			CONTAINER_ID: null,
			SALE_STATUS: null,
		};
		
		var meterListInit = this.copyTable(meterList);
		
		if (props.search) {
			var propsSearch = this.copyObject(props.search);
			this.mergeObject(search, propsSearch);
			search = propsSearch;
			meterListInit.query.search = this.constructSearch(search);
		}

		this.state = {
			meterList: meterListInit,
			search: search,
			searchCount: null,
			renderKey: 0
		};
	}
	
	onChangeSearch(accessor, value) {
		var search = this.copyObject(this.state.search);
		this.setByAccessor(search, accessor, value);
		this.mergeState({ search: search });
	}

	onSubmitSearch(event) {
		var newMeterList = this.copyTable(meterList);
		newMeterList.query.search = this.constructSearch(this.state.search);
		this.mergeState({ 
			meterList: newMeterList,
			searchCount: null,
			renderKey: this.state.renderKey + 1
		});
		if (event && event.nativeEvent && event.nativeEvent.submitter) event.nativeEvent.submitter.blur();
	}

	constructSearch(data) {
		// construct search terms
		var search = {
			Operator: 'and',
			Children: []
		};

		if (!this.isEmpty(data.METER_ID)) search.Children.push({ Attribute: 'METER_ID', Operator: '=', LongValue: data.METER_ID });
		if (!this.isEmpty(data.BUILDING)) search.Children.push({ Attribute: 'BUILDING', Operator: '=', StringValue: data.BUILDING });

		// if there are no terms, forget it
		if (search.Children.length === 0) search = null;
		return search;
	}
	
	addMeter() {
		this.props.navigate('/HeLAD/GN2Meters/new');
	}
	
	editMeter(meterId) {
		this.props.setGN2MeterEditable(true);
		this.props.navigate('/HeLAD/GN2Meters/' + meterId);		
	}

	viewMeter(meterId) {
		this.props.setGN2MeterEditable(false);
		this.props.navigate('/HeLAD/GN2Meters/' + meterId);		
	}
	
	onSearchResult(data) {
		this.mergeState({
			searchCount: data.ResultCount
		});
	}

	render() {
		
		var exportData = this.createHiddenFormData({
			__RequestVerificationToken: this.props.user.antiForgeryToken,
			title: 'GN2 Meters',
			columns: exportColumns,
			entity: 'LIQUID_AIR.GN2_METERS_VW',
			search: this.constructSearch(this.state.search),
			order: 'CONTAINER_ID'
		});
		
		return (<>
			<Container fluid>
			  <Row>
			    <Col style={{ paddingTop: '20px', marginBottom: '20px', border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#FEFEFE' }}>
				  <Container fluid>	
					<Row>
					  <Col xs={12}>
						<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Gaseous Nitrogen Meters</span>
						<ChemEdit parent={this} user={this.props.user} columns={meterSearch} data={this.state.search}
						  renderKey={this.state.renderKey} onChange={(accessor, value) => this.onChangeSearch(accessor, value)} />
					  </Col>
					</Row>
					<Form.Group as={Row} style={{ borderTop: '1px solid #eee', paddingTop: 10, marginBottom: 10 }}>
					  <Col xs={3} style={{ marginTop: 8}}>
						{this.isEmpty(this.state.searchCount) ? <Spinner animation='border' as='div' size='sm' style={{ marginRight: '4px' }} /> : this.state.searchCount} records found.
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Excel'} method='POST'>
							{exportData.map((excelFormField, index) => {
								return (<input key={index} type='hidden' name={excelFormField.name} value={excelFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to Excel</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Excel.png'} style={{ paddingLeft: '20px' }} alt="Export to Excel" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={1} style={{ marginTop: 6}}>
						<form action={this.getConfig().host + '/Home/Pdf'} method='POST'>
							{exportData.map((pdfFormField, index) => {
								return (<input key={index} type='hidden' name={pdfFormField.name} value={pdfFormField.value} />);
							})}
							<OverlayTrigger trigger="hover" overlay={<Popover><Popover.Header as="h3">Export to PDF</Popover.Header></Popover>}>
							  <input type='image' src={this.getConfig().host + '/Content/Icons/Pdf.png'} style={{ paddingLeft: '20px' }} alt="Export to PDF" />
							</OverlayTrigger>
						</form>
					  </Col>
					  <Col xs={7}>
						<Button id="update" variant="warning" type="button" className="float-end" onClick={(event) => this.onSubmitSearch(event)}>Search</Button>
					  </Col>
					</Form.Group>
				  </Container>
				</Col>
			  </Row>
			  <Row>
				<Col>
				  <QueryTable renderKey={this.state.renderKey} parent={this} name='HeLAD_GN2Meters' table={this.state.meterList}
				    onResult={(data) => this.onSearchResult(data)} offsetHeight='185px' />
				</Col>
			  </Row>
			</Container>
		</>);		
	}
}

const meterSearch = [
	{ Header: 'Meter', accessor: 'METER_ID', type: 'select', row: 0, col: 0,
		options: { value: 'METER_ID', label: 'METER_ID', entity: 'LIQUID_AIR.GN2_METERS_VW', order: 'METER_ID', distinct: true }},
	{ Header: 'Building', accessor: 'BUILDING', type: 'select', row: 0, col: 1,
		options: { value: 'BUILDING', label: 'BUILDING', entity: 'LIQUID_AIR.GN2_METERS_VW', order: 'BUILDING', distinct: true }}
];

const meterList = {
	query: { entity: 'LIQUID_AIR.GN2_METERS_VW', search: null, order: ['BUILDING','ROOM'] },
	pageSize: 20,
	columns: [
		{ Header: props => <PlusSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.addMeter()} />, accessor: 'id', width: 25, 
			Cell: props => <PencilSquare style={{ cursor: 'pointer' }} onClick={() => props.parent.editMeter(props.row.values.METER_ID)} /> },
		{ Header: '', accessor: 'METER_ID', show: false },
		{ Header: 'Meter', accessor: 'METER_NAME', width: 70,
			Cell: props => <HashLink onClick={() => props.parent.viewMeter(props.row.values.METER_ID)}>{props.value}</HashLink> },
		{ Header: 'Building', accessor: 'BUILDING', width: 125 },
		{ Header: 'Room', accessor: 'ROOM', width: 70 },
		{ Header: 'Room Served', accessor: 'ROOM_SERVED', width: 250 },
		{ Header: 'Active', accessor: 'ACTIVE_FLAG', width: 70 },
		{ Header: 'Last Read', accessor: 'READ_DATE_DISPLAY', width: 100 },
		{ Header: 'Last Read Value', accessor: 'PAST_AMOUNT', width: 150 },
		{ Header: 'Current Read Value', accessor: 'NEW_AMOUNT', width: 150 },
		{ Header: 'Usage Read Value', accessor: 'USAGE', width: 150 }
	]
};

const exportColumns = [
	{ Header: 'Meter ID', accessor: 'METER_ID', width: 14 },
	{ Header: 'Building', accessor: 'BUILDING', width: 25 },
	{ Header: 'Room', accessor: 'ROOM', width: 14 },
	{ Header: 'Room Served', accessor: 'ROOM_SERVED', width: 50 },
	{ Header: 'Active', accessor: 'ACTIVE_FLAG', width: 14 },
	{ Header: 'Last Read', accessor: 'READ_DATE_DISPLAY', width: 20 },
	{ Header: 'Last Read Value', accessor: 'PAST_AMOUNT', width: 30 },
	{ Header: 'Current Read Value', accessor: 'NEW_AMOUNT', width: 30 },
	{ Header: 'Usage Read Value', accessor: 'USAGE', width: 30 }
];

export default withRouter(GN2Meters);
