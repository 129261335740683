import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import ChemComponent from '../ChemComponent';
import { withCRSDefaultRoute } from './withCRSDefaultRoute';
import ChemHeader from '../ChemHeader';
import Confirm from '../Confirm';
import TreeMenu from '../TreeMenu';
import Restricted from '../Restricted';
import Home from './Home';
import Speedtypes from './Speedtypes';
import Speedtype from './Speedtype';
import PIs from './PIs';
import RAs from './RAs';
import Person from './Person';
import COA from './COA';
import CAF from './CAF';
import Funds from './Funds';
import Uploads from './Uploads';
import ServiceContracts from './ServiceContracts';
import ServiceContract from './ServiceContract';
import GraphicsUsers from './GraphicsUsers';
import GraphicsUser from './GraphicsUser';
import DryIceSales from './DryIceSales';
import DryIceSale from './DryIceSale';
import RecurringJobs from './RecurringJobs';
import RecurringJob from './RecurringJob';
import RechargeRates from './RechargeRates';
import RechargeRate from './RechargeRate';
import Services from './Services';
import Service from './Service';
import Headers from './Headers';
import Header from './Header';
import Columns from './Columns';
import Column from './Column';
import CycleDates from './CycleDates';
import CycleDate from './CycleDate';
import EmailContacts from './EmailContacts';
import EmailContact from './EmailContact';
import Invalid from './Invalid';
import Jobs from './Jobs';
import SearchPrelim from './SearchPrelim';
import Transaction from './Transaction';
import Future from './Future';
import ChangeSpeedtypes from './ChangeSpeedtypes';
import Recharges from './Recharges';
import MarkBilled from './MarkBilled';
import Statements from './Statements';
import YearToDate from './YearToDate';
import FundSourceData from './FundSourceData';
import EmployeeShop from './EmployeeShop';
import Preliminary from './Preliminary';
import Billing from './Billing';
import Assignments from './Assignments';
import Links from './Links';
import Contacts from './Contacts';
import Help from './Help';

class CRS extends ChemComponent {
  	constructor(props) {
		super(props);

		// copy menu items so we can edit
		var stateTreeMenuItems = JSON.parse(JSON.stringify(treeMenuItems));
		
		// remove unauthorized menu items
		if (props.user.crs.guestStatus) {
			// remove everything except Billing Statements past 5 yrs and Contacts
			this.removeRoute(stateTreeMenuItems, routes.speedtypes);
			this.removeRoute(stateTreeMenuItems, routes.pis);
			this.removeRoute(stateTreeMenuItems, routes.ras);
			this.removeRoute(stateTreeMenuItems, routes.coa);
			this.removeRoute(stateTreeMenuItems, routes.caf);
			this.removeRoute(stateTreeMenuItems, routes.funds);
			this.removeRoute(stateTreeMenuItems, routes.uploads);
			this.removeRoute(stateTreeMenuItems, routes.graphicsUsers);
			this.removeRoute(stateTreeMenuItems, routes.dryIceSales);
			this.removeRoute(stateTreeMenuItems, routes.recurringJobs);
			this.removeRoute(stateTreeMenuItems, routes.rechargeRates);
			this.removeRoute(stateTreeMenuItems, routes.services);
			this.removeRoute(stateTreeMenuItems, routes.headers);
			this.removeRoute(stateTreeMenuItems, routes.cycleDates);
			this.removeRoute(stateTreeMenuItems, routes.emailContacts);
			this.removeRoute(stateTreeMenuItems, routes.invalid);
			this.removeRoute(stateTreeMenuItems, routes.searchPrelim);
			this.removeRoute(stateTreeMenuItems, routes.future);
			this.removeRoute(stateTreeMenuItems, routes.changeSpeedtypes);
			this.removeRoute(stateTreeMenuItems, routes.recharges);
			this.removeRoute(stateTreeMenuItems, routes.markBilled);
			this.removeRoute(stateTreeMenuItems, routes.statements);
			this.removeRoute(stateTreeMenuItems, routes.yearToDate);
			this.removeRoute(stateTreeMenuItems, routes.fundSourceData);
			this.removeRoute(stateTreeMenuItems, routes.employeeShop);
			this.removeRoute(stateTreeMenuItems, routes.preliminary);
			this.removeRoute(stateTreeMenuItems, routes.assignments);
		}
		else
		{
			if (props.user.crs.userAccessID !== 1)
			{
				this.removeRoute(stateTreeMenuItems, routes.coa);
				this.removeRoute(stateTreeMenuItems, routes.caf);
				this.removeRoute(stateTreeMenuItems, routes.funds);
				this.removeRoute(stateTreeMenuItems, routes.recurringJobs);
				this.removeRoute(stateTreeMenuItems, routes.rechargeRates);
				this.removeRoute(stateTreeMenuItems, routes.services);
				this.removeRoute(stateTreeMenuItems, routes.headers);
				this.removeRoute(stateTreeMenuItems, routes.cycleDates);
				this.removeRoute(stateTreeMenuItems, routes.emailContacts);
				// remove only from recharge process, not from customer interface
				this.removeRoute(stateTreeMenuItems, routes.invalid, 'Invalid Recharges');
				this.removeRoute(stateTreeMenuItems, routes.changeSpeedtypes);
				this.removeRoute(stateTreeMenuItems, routes.recharges);
				this.removeRoute(stateTreeMenuItems, routes.markBilled);
				this.removeRoute(stateTreeMenuItems, routes.fundSourceData);
				this.removeRoute(stateTreeMenuItems, routes.employeeShop);
			}
			
			if (props.user.crs.userAccessID !== 1 && (!props.user.crs.serviceMgr || props.user.crs.serviceMgr.length === 0))
			{
				this.removeRoute(stateTreeMenuItems, routes.uploads);
				this.removeRoute(stateTreeMenuItems, routes.serviceContracts);
				this.removeRoute(stateTreeMenuItems, routes.graphicsUsers);
				this.removeRoute(stateTreeMenuItems, routes.dryIceSales);
				this.removeRoute(stateTreeMenuItems, routes.searchPrelim);
				this.removeRoute(stateTreeMenuItems, routes.future);				
				this.removeRoute(stateTreeMenuItems, routes.statements);
				this.removeRoute(stateTreeMenuItems, routes.yearToDate);
			}
			
			if (props.user.crs.userAccessID > 2)
			{
				this.removeRoute(stateTreeMenuItems, routes.pis);
				this.removeRoute(stateTreeMenuItems, routes.ras);
			}
		}
		
		this.state = {
			fullScreen: false,
			treeMenuItems: stateTreeMenuItems,
			confirm: ''
		};
	}
	
	removeRoute(menuItems, route, label) {
		for (var i = 0; i < menuItems.length; i++) {
			if (menuItems[i].nodes) {
				this.removeRoute(menuItems[i].nodes, route, label);
				if (menuItems[i].nodes.length === 0) {
					menuItems.splice(i--, 1);
				}
			} else {
				if (menuItems[i].route && menuItems[i].route.toUpperCase() === route.toUpperCase() && (this.isEmpty(label) || menuItems[i].label === label)) {
					menuItems.splice(i--, 1);
				}
			}
		}
	}
	
	showConfirmation(message) {		
		this.mergeState({
			confirm: message
		}, () => this.props.navigate('/CRS/Confirm'));
	}
	
	render() {
		return (<>
			<ChemHeader parent={this} user={this.props.user} title='Chemistry Recharge System' fullScreen={this.state.fullScreen} 
				toggleFullScreen={(fullScreen) => this.mergeState({ fullScreen: fullScreen })}
				showHelp={() => this.props.navigate('/CRS/Help', { helpPath: this.props.location.pathname })} />
			<Container fluid style={{ paddingTop: 20, paddingBottom: 20, backgroundColor: '#e6e6e4', height: 'calc(100vh - 64px)', overflow: 'auto' }}>
				<Row>
					{!this.state.fullScreen && <Col xs={2}>
						<TreeMenu parent={this} menuItems={this.state.treeMenuItems} selectedRoute={this.props.location.pathname} />
					</Col>}
					<Col xs={this.state.fullScreen ? 12 : 10}>
						<Routes>
							<Route path={'/speedtypes'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Speedtypes parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/speedtypes/new'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Speedtype parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/speedtypes/:speedtypeId'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Speedtype parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/pis'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<PIs parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/ras'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<RAs parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/people/new'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Person parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/people/:roleId'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Person parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/coa'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<COA parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/caf'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<CAF parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/funds'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Funds parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/uploads'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Uploads parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/servicecontracts'} element={
								<Restricted authorized={this.props.user.crs.userAccessID === 1 || (this.props.user.crs.serviceMgr && this.props.user.crs.serviceMgr.length > 0)}>
									<ServiceContracts parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/servicecontracts/new'} element={
								<Restricted authorized={this.props.user.crs.userAccessID === 1 || (this.props.user.crs.serviceMgr && this.props.user.crs.serviceMgr.length > 0)}>
									<ServiceContract parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/servicecontracts/:serviceContractId'} element={
								<Restricted authorized={this.props.user.crs.userAccessID === 1 || (this.props.user.crs.serviceMgr && this.props.user.crs.serviceMgr.length > 0)}>
									<ServiceContract parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/graphicsusers'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<GraphicsUsers parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/graphicsusers/new'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<GraphicsUser parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/graphicsusers/:userId'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<GraphicsUser parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/dryicesales'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<DryIceSales parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/dryicesales/new'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<DryIceSale parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/dryicesales/:transId'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<DryIceSale parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/recurringjobs'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<RecurringJobs parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/recurringjobs/new'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<RecurringJob parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/recurringjobs/:jobId'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<RecurringJob parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/rechargerates'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<RechargeRates parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/rechargerates/new'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<RechargeRate parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/rechargerates/:rchId'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<RechargeRate parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/services'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Services parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/services/new'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Service parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/services/:serviceId'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Service parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/headers'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Headers parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/headers/new'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Header parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/headers/:headerId'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Header parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/headers/:headerId/columns'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Columns parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/headers/:headerId/columns/new'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Column parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/headers/:headerId/columns/:columnOrder'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Column parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/cycledates'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<CycleDates parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/cycledates/new'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<CycleDate parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/cycledates/:billingId'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<CycleDate parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/emailcontacts'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<EmailContacts parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/emailcontacts/new'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<EmailContact parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/emailcontacts/:emailId'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<EmailContact parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/invalid'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Invalid parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/jobs/:serviceId/:jobId'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Jobs parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/searchprelim'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<SearchPrelim parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/searchprelim/:serviceId'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<SearchPrelim parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/transaction/:serviceId/:jobId'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Transaction parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/future'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Future parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/changespeedtypes'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<ChangeSpeedtypes parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/recharges'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Recharges parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/markbilled'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<MarkBilled parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/statements'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Statements parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/yeartodate'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<YearToDate parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/fundsourcedata'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<FundSourceData parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/employeeshop'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<EmployeeShop parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/preliminary'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Preliminary parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/assignments'} element={
								<Restricted authorized={!this.props.user.crs.guestStatus}>
									<Assignments parent={this} user={this.props.user} />
								</Restricted>
							} />
							<Route path={'/billing'} element={<Billing parent={this} user={this.props.user} />} />
							<Route path={'/links'} element={<Links parent={this} user={this.props.user} />} />
							<Route path={'/contacts'} element={<Contacts parent={this} user={this.props.user} />} />
							<Route path={'/confirm'} element={<Confirm parent={this} user={this.props.user} message={this.state.confirm} />} />
							<Route path={'/help'} element={<Help parent={this} user={this.props.user} />} />
							<Route path={'/home'} element={<Home parent={this} user={this.props.user} />} />
						</Routes>
					</Col>
				</Row>
			</Container>
		</>);
	}
}

// routes defined here so there are no accidents when removing them!
const routes = {
	assignments: '/CRS/Assignments',
	billing: '/CRS/Billing',
	caf: '/CRS/CAF',
	changeSpeedtypes: '/CRS/ChangeSpeedtypes',
	coa: '/CRS/COA',
	contacts: '/CRS/Contacts',
	cycleDates: '/CRS/CycleDates',
	dryIceSales: '/CRS/DryIceSales',
	emailContacts: '/CRS/EmailContacts',
	employeeShop: '/CRS/EmployeeShop',
	funds: '/CRS/Funds',
	fundSourceData: '/CRS/FundSourceData',
	future: '/CRS/Future',
	graphicsUsers: '/CRS/GraphicsUsers',
	headers: '/CRS/Headers',
	home: '/CRS/Home',
	invalid: '/CRS/Invalid',
	links: '/CRS/Links',
	markBilled: '/CRS/MarkBilled',
	pis: '/CRS/PIs',
	preliminary: '/CRS/Preliminary',
	ras: '/CRS/RAs',
	rechargeRates: '/CRS/RechargeRates',
	recharges: '/CRS/Recharges',
	recurringJobs: '/CRS/RecurringJobs',
	searchPrelim: '/CRS/SearchPrelim',
	serviceContracts: '/CRS/ServiceContracts',
	services: '/CRS/Services',
	speedtypes: '/CRS/Speedtypes',
	statements: '/CRS/Statements',
	uploads: '/CRS/Uploads',
	yearToDate: '/CRS/YearToDate'
};

const treeMenuItems = [
	{
		route: routes.home,
		label: 'Home'
	},
	{
		label: 'Speedtypes',
		nodes: [
			{
				route: routes.speedtypes,
				label: 'Speedtype Search'
			},
			{
				route: routes.pis,
				label: 'PI Profile List'
			},
			{
				route: routes.ras,
				label: 'Manage RA'
			},
			{
				route: routes.coa,
				label: 'COA Export'
			},
			{
				route: routes.caf,
				label: 'CAF Upload'
			},
			{
				route: routes.funds,
				label: 'Fund List by Fund Group'
			}
		]
	},
	{
		label: 'Recharge Uploads',
		nodes: [
			{
				route: routes.uploads,
				label: 'Upload Page'
			},
			{
				route: routes.serviceContracts,
				label: 'Service Contracts'
			},
			{
				route: routes.graphicsUsers,
				label: 'Graphics Users'
			},
			{
				route: routes.dryIceSales,
				label: 'Dry Ice Sales'
			},
			{
				route: routes.recurringJobs,
				label: 'Pitzer Center Support'
			},
			{
				route: routes.rechargeRates,
				label: 'Recharge Rates'
			},
			{
				route: routes.services,
				label: 'Services'
			},
			{
				route: routes.headers,
				label: 'Spreadsheet Headers'
			}
		]
	},
	{
		label: 'Recharge Process',
		nodes: [
			{
				route: routes.cycleDates,
				label: 'Cycle Dates'
			},
			{
				route: routes.emailContacts,
				label: 'Email Lists'
			},
			{
				route: routes.invalid,
				label: 'Invalid Recharges'
			},
			{
				route: routes.searchPrelim,
				label: 'Preliminary Journal'
			},
			{
				route: routes.future,
				label: 'Future View Journal'
			},
			{
				route: routes.changeSpeedtypes,
				label: 'Change Speedtypes'
			},
			{
				route: routes.recharges,
				label: 'Recharges Journals'
			},
			{
				route: routes.markBilled,
				label: 'Mark Billed'
			}
		]
	},
	{
		label: 'Reports',
		nodes: [
			{
				route: routes.statements,
				label: 'Search Past Statements'
			},
			{
				route: routes.yearToDate,
				label: 'Year To Date'
			},
			{
				route: routes.fundSourceData,
				label: 'Fund Source Data'
			},
			{
				route: routes.employeeShop,
				label: 'Employee Shop Report'
			}
		]
	},
	{
		label: 'Customer Interface',
		nodes: [
			{
				route: routes.preliminary,
				label: 'Preliminary Journal'
			},
			{
				route: routes.invalid,
				label: 'Invalid Speedtypes'
			},
			{
				route: routes.billing,
				label: 'Billing Statements (past 5 yrs.)'
			},
			{
				route: routes.assignments,
				label: 'PI Assignments'
			}
		]
	},
	{
		route: routes.links,
		label: 'Useful Links'
	},
	{
		route: routes.contacts,
		label: 'Contacts'
	}
];

export default withCRSDefaultRoute(CRS);